/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.theme')
  .factory('themeFactory', function($http, $q, $rootScope, THEME_CONFIG) {
    const theme = {};

    const getTheme = function getTheme() {
      $http({
        url: THEME_CONFIG.themePath,
        cache: true,
        headers: { 'Content-Type': 'application/json' }
      }).success(function(data) {
        angular.copy(data, theme);
      });
      return theme;
    };

    const setThemeProperty = function setThemeProperty(key, value) {
      // $rootScope.$apply(function(){
      theme[key] = value;

      // });
    };

    return {
      getTheme,
      setThemeProperty
    };
  });
