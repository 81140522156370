/* eslint-disable no-param-reassign */
/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.config', [])
  .constant('APP_CONFIG', {
    defaultState: 'section.campaigns.advertiserList',
    maxRequestsPerTab: 6,
    useBackendMocks: true,
    apiBase: '/cms/',

    modulesFallbackOrder: ['section', 'analytics', 'reporting'],
    // this parameter can be used in different modes
    // to send all requests to the mock server change this parameter to '*'
    // to mock specific servlets change parameter to array of this servlets names
    // (e.g., ['system', 'login']
    // to mock specific actions change parameter to following style ['<servletName>.<actionName>']
    requestsToMock: [
      // 'monitoring',
      // 'analyticsSite',
      // 'analyticsCampaign',
      // 'analyticsPreset',
      // 'reporting',
      // 'analyticsSystem.getQuantityOfRegisteredUsers',
      // 'analyticsSystem.getVacantSites',
      // 'analyticsSystem.getAllSystemSites',
      // 'analyticsSite.getDataByOtherVisitedSites',
      // 'analyticsSite.getDataByCampaignsViews',
      // 'analyticsSite.getDataByCitiesAndCountries',
      // 'analyticsCampaign.getGeneralInfo',
      // 'analyticsCampaign.getDataByCitiesAndCountries',
      // 'analyticsVisitor',
      // 'entities_was',
      // 'admin.getUserPermissions',
      // 'widgets.widgetGetForCampaign',
      // 'widgets.getAmountOfWidgets',
      // 'campaigns.getAmountOfCampaigns',
      // 'analyticsCampaign.getDataByComparison',
      // 'analyticsCampaign.getDataByWeek',
      // 'sites.getAllSites',
      // 'sites.getAmountOfSites',
      // 'entities.getAmountOfEntities',
      // 'sites.getVacantSites',
      // 'entities.getAllEntities',
      // 'campaigns.getDataByTags',
      // 'sites.getDataByTags'
      // 'system.getClientLocalizations',
      // 'system.getTermsForLocale',
      // 'common.canCreate',
      // 'system.logsApplyFilters',
      // 'system.logsGetList',
      // 'campaigns',
      // 'sites.siteGetData',
      // 'campaigns.campaignGetData',
      // 'sites.siteSave',
      // 'admin.userGetData'
      // 'campaigns.campaignGetAvailableValues',
      // 'campaigns.campaignGetOccupation',
      // 'campaigns.campaignGetInterests',
      // 'campaigns.campaignGetAllTags',
      // 'campaigns.campaignGetAllEntities',
      // 'campaigns.campaignGetAllCampaigns',
      // 'campaigns.campaignGetAllSites',
      // 'campaigns.campaignGetAllCities',
      // 'campaigns.campaignGetAllCountries',
      // 'campaigns.campaignGetLivePrognosis'
      // 'monitoring.collectionApplyFilters',
      // 'monitoring.collectionGetList'
    ],
    mockServer: 'http://private-ccc9aa-wcmapi.apiary-mock.com'
  })
  .config([
    '$httpProvider',
    function($httpProvider) {
      // initialize get if not there
      if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
      }

      $httpProvider.defaults.headers.get['If-Modified-Since'] =
        'Mon, 26 Jul 1997 05:00:00 GMT';
      $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
      $httpProvider.defaults.headers.get.Pragma = 'no-cache';

      $httpProvider.interceptors.push('wasRequestsAddParamsInterceptor');
    }
  ]);
