const { angular } = window;

angular
  .module('wcm.core')
  .controller('manageBlockViewOnlyController', function(
    $timeout,
    manageBlockFactory
  ) {
    const ctrl = this;

    ctrl.$onInit = function() {
      ctrl.noManageBtn = true;
      setViewValues();
    };

    ctrl.$onChanges = function(changesObj) {
      setViewValues();

      if (changesObj.isExport && ctrl.isExport) {
        setItemsForPrint();
      }
    };

    function setViewValues() {
      const items = prepareAvailableValues(ctrl.availableValues);
      if (ctrl.limitItems) {
        const limitedItems = manageBlockFactory.limitTo(ctrl.limitItems, items);
        ctrl.items = limitedItems.entries;
        ctrl.extraItems = limitedItems.extra;
      } else {
        ctrl.items = items;
        ctrl.extraItems = 0;
      }
    }

    function prepareAvailableValues(availableValues) {
      let finalValues = [];
      if (!Array.isArray(availableValues)) {
        Object.keys(availableValues).forEach(function(type) {
          finalValues = finalValues.concat(
            prepareSingleArr(availableValues[type], type)
          );
        });
      } else {
        finalValues = prepareSingleArr(availableValues);
      }
      return finalValues;
    }

    function prepareSingleArr(items, type) {
      const finalValues = [];
      items.forEach(function(itemName) {
        const obj = {};
        obj.name = itemName;
        obj.className = type || ctrl.title;
        finalValues.push(obj);
      });
      return finalValues;
    }

    function setItemsForPrint() {
      let itemsForPrint = '';
      ctrl.availableValues.forEach(function(item) {
        itemsForPrint += `<li class="manage-block_li-w">\
                <div class="manage-block_li ${ctrl.valuesType || ''}">\
                    <span>${item}</span>\
                </div>\
              </li>`;
      });
      $timeout(function() {
        window.$('#itemsForPrint').html(itemsForPrint);
      }, 0);
    }
  });
