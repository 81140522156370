/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('macExceptionsFactory', function(
    $rootScope,
    $modal,
    i18nFactory,
    requestFactory,
    ADMIN_CONFIG,
    helpers
  ) {
    const getList = function(filters, page, ipp, sortOrder) {
      const requestData = angular.extend(
        {},
        filters,
        helpers.getPaginationFilters(page, ipp, sortOrder)
      );
      return requestFactory
        .post(
          ADMIN_CONFIG.macExceptionsServlet,
          ADMIN_CONFIG.macExceptionsGetList,
          requestData
        )
        .then(function(res) {
          return res.data && res.data.macExceptions
            ? res.data.macExceptions
            : [];
        })
        .then(function(macExceptions) {
          return macExceptions.map(function(mac) {
            return window._.extend({}, mac, { id: `${mac.id}` });
          });
        })
        .then(function(macExceptions) {
          $rootScope.$broadcast('macExceptions:list-updated', macExceptions);
          return macExceptions;
        });
    };

    const getData = function(id, macAddress, siteId) {
      return requestFactory
        .post(
          ADMIN_CONFIG.macExceptionsServlet,
          ADMIN_CONFIG.macExceptionsGetData,
          {
            macAddress,
            siteId,
            id
          }
        )
        .then(function(res) {
          return {
            details:
              res.data && res.data.macException ? res.data.macException : ''
          };
        });
    };

    const macExceptionDelete = function(macAddress, siteId) {
      const _siteId = siteId * 1 || 0;
      return requestFactory.post(
        ADMIN_CONFIG.macExceptionsServlet,
        ADMIN_CONFIG.macExceptionDelete,
        {
          macAddress,
          siteId: _siteId
        }
      );
    };

    const macExceptionSave = function(macId, address, details, oldMac, siteId) {
      const _siteId = siteId * 1 || 0;
      return requestFactory.post(
        ADMIN_CONFIG.macExceptionsServlet,
        ADMIN_CONFIG.macExceptionSave,
        {
          macException: {
            id: macId || 0,
            macAddress: address,
            details: details || '',
            oldMacAddress: oldMac,
            siteId: _siteId
          }
        }
      );
    };

    const openMacExceptionModal = function(siteId, modalType, data) {
      const scope = $rootScope.$new(true);
      scope.state = {
        submitted: false
      };
      scope.forms = {};
      scope.modalType = modalType;
      scope.focused = {};
      scope.mac = data;
      scope.oldMacAddress = data.macAddress;

      const modalInstance = $modal.open({
        scope,
        templateUrl: 'app/admin/macExceptions/macExceptionModal.html',
        controller($scope, $modalInstance, helpers) {
          'ngInject';

          $scope.saveMac = function() {
            macExceptionSave(
              scope.mac.id,
              $scope.mac.macAddress,
              $scope.mac.details,
              $scope.oldMacAddress,
              siteId
            ).then(function() {
              $modalInstance.close();
            });
          };
          $scope.editMac = function() {
            $scope.modalType = 'edit';
          };
          $scope.cancel = function() {
            if (
              !($scope.modalType === 'view' || $scope.modalType === 'delete')
            ) {
              const message = i18nFactory.translate(
                `admin.macExceptions.modals.cancelConfirmation.texts.${
                  $scope.modalType === 'edit' ? 'edit' : 'create'
                }`
              );
              helpers.confirmationModal('', message).result.then(function() {
                $modalInstance.dismiss('cancel');
              });
            } else {
              $modalInstance.dismiss('cancel');
            }
          };
        },
        windowClass: 'modal_mac-exceptions'
      });
      return modalInstance.result;
    };

    return {
      getList,
      macExceptionDelete,
      getData,
      macExceptionSave,
      openMacExceptionModal
    };
  });
