const { moment, angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateCtrl', function(
    $scope,
    $state,
    $location,
    $q,
    $timeout,
    $modal,
    $controller,
    analyticsFactory2,
    reportingFactory,
    analyticsRequestsFactory,
    navigationPanel,
    breadcrumbs,
    helpers,
    REPORTING_CONFIG
  ) {
    const config = REPORTING_CONFIG;
    const routeParams = $location
      .path()
      .split('/')
      .reverse();
    const listType = reportingFactory.getReportsType(routeParams);
    const systemCreationMidnight = moment(
      $scope.currentUser.systemCreationDate
    ).startOf('day');

    const ctrlInherit = config.controllersCreateMap[listType];
    $controller(ctrlInherit, { $scope });

    // Set default startPeriod
    let startPeriod = moment()
      .startOf('day')
      .subtract(1, 'months')
      .subtract(1, 'day');
    if (startPeriod < systemCreationMidnight) {
      startPeriod = systemCreationMidnight;
    }

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      blocks: [],
      startPeriodDefault: startPeriod,
      maxPeriod: moment()
        .startOf('day')
        .subtract(1, 'day')
    }));
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      callbackRoute: config.callbackRoutes[listType],
      listType,
      report: {
        name: '',
        isExcel: false,
        isAllTime: false,
        blockList: []
      },
      routeParams,
      terminateType: listType,
      permissionType: 'viewAnalytics',
      editMode: true
    }));

    // Set default time filters
    state.filters = angular.extend(state.filters, {
      startPeriod,
      endPeriod: moment()
        .startOf('day')
        .subtract(1, 'day'),
      startTimeFrame: 0,
      endTimeFrame: 24 * 60 * 60 * 1000,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
      allTime: false
    });
    state.manageFilterSettings = {};
    state.noDataYetAvailable = false;
    state.noServerAvailable = false;

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      setTabs() {
        navigationPanel.setTabs(config.tabs.creates[state.listType]);
        breadcrumbs.set({
          path: `${config.breadcrumbs[state.listType]}>create`
            .split('>')
            .map(name => ({ name }))
        });
      },
      cancel(toState) {
        helpers
          .cancelConfirmModal({
            title: 'reporting.titles.cancelConfirmationCreate',
            message: 'reporting.messages.cancelConfirmationCreate'
          })
          .then(function() {
            state.editMode = false;
            $state.go(toState || state.callbackRoute);
          });
      },
      validateBlocks() {
        return window._.some(state.report.blockList, function(value) {
          return value;
        });
      },
      selectAllBlocks() {
        if (state.report.blockList.length === options.blocks.length) {
          state.report.blockList = [];
        } else {
          state.report.blockList = angular.copy(options.blocks);
        }
      },
      selectBlock(blockName) {
        const index = state.report.blockList.indexOf(blockName);
        if (~index) {
          state.report.blockList.splice(index, 1);
        } else {
          state.report.blockList.push(blockName);
        }
      },
      hasCreatePermission,
      setBeginPeriodDates(start) {
        // Use only for specific
        options.minPeriod =
          start <= state.filters.endPeriod ? start : state.filters.endPeriod;
        options.availableMinDate = moment(options.minPeriod)
          .startOf('day')
          .valueOf();
        if (
          state.filters.startPeriod < options.minPeriod ||
          state.filters.allTime
        ) {
          state.filters.startPeriod = options.minPeriod;
        } else if (options.minPeriod < options.startPeriodDefault) {
          state.filters.startPeriod = options.startPeriodDefault;
        }
      }
    }));

    $scope.init = function() {
      handlers.setTabs();
      state.isBusy = true;
      state.report.blockList = angular.copy(options.blocks);

      handlers.resetManageBlocks();
      handlers.getAvailableValues(state.type).then(function() {
        state.isBusy = false;
        handlers.initManageBlocks();
      });
    };

    // Show confirmation modal on state change
    $scope.$on('$stateChangeStart', function(event, toState) {
      if (state.editMode) {
        event.preventDefault();
        handlers.cancel(toState);
      }
    });

    // Redirect if no permissions on this tab
    $scope.$on('$stateChangeSuccess', function() {
      if (!handlers.hasCreatePermission()) {
        $state.go(state.callbackRoute);
        return false;
      }
      return undefined;
    });

    // Has create permission
    function hasCreatePermission() {
      return $scope.hasPermission('create', state.listType);
    }
  });
