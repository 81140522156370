/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .directive('mainView', function(navigationPanel) {
    const mainViewCtrl = function($scope, $state, $interpolate) {
      const BASE_TAB_COLOR = 'hsl(240, 5%, {{lightness}}%)';
      const START_LIGHTNESS = 40;
      const END_LIGHTNESS = 80;
      const colorBindFn = $interpolate(BASE_TAB_COLOR);

      $scope.currentUrlParams = $state.params;
      $scope.$on('$stateChangeStart', function(event, toState, toParams) {
        $scope.currentUrlParams = toParams;
      });

      $scope.currentPath = {};

      $scope.setTabs = function(tabs) {
        $scope.currentPath.tabs = tabs;
      };

      $scope.setActionButtons = function(buttons) {
        $scope.actionButtons = buttons;
      };

      // Check if tab should be active
      $scope.isTabActive = function(tab) {
        // eslint-disable-next-line max-len
        return (
          (!Array.isArray(tab.sref) &&
            $state.current.name === tab.sref &&
            (!tab.go || !tab.go.device)) ||
          // eslint-disable-next-line max-len
          (Array.isArray(tab.sref) &&
            tab.sref &&
            !!~tab.sref.indexOf($state.current.name) &&
            (!tab.go || !tab.go.device)) ||
          tab.isActive ||
          (tab.go &&
            $scope.currentUrlParams.device === tab.go.device &&
            tab.go.device)
        );
      };

      // Go to clicked tab
      $scope.goToTab = function(tab) {
        if (Array.isArray(tab.sref)) {
          $state.go(tab.sref[0], tab.go);
        } else {
          $state.go(tab.sref, tab.go);
        }
      };

      $scope.setCurrent = function(tab) {
        for (let i = 0; i < $scope.currentPath.tabs.length; i++) {
          $scope.currentPath.tabs[i].isActive = false;
          if (tab.name === $scope.currentPath.tabs[i].name) {
            $scope.currentPath.tabs[i].isActive = true;
          }
        }
      };

      $scope.getTabColor = function(idx) {
        const tabs = $scope.currentPath.tabs;
        const scale = (END_LIGHTNESS - START_LIGHTNESS) / 100;
        const progress = (idx / (tabs.length - 1)) * 100;
        const lightness = START_LIGHTNESS + progress * scale;
        return colorBindFn({ lightness });
      };

      $scope.setBusy = function(value) {
        $scope.isBusy = value;
      };
    };

    return {
      controller: mainViewCtrl,
      link(scope) {
        navigationPanel.register(scope);
        scope.$on('$destroy', function() {
          navigationPanel.unregister();
        });
      }
    };
  });
