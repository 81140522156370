/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.export').directive('exportAnalytics', function() {
  return {
    restrict: 'E',
    scope: {
      getExportOptions: '&?',
      hasExportPermissions: '<',
      onExportPreconditions: '&?',
      exportFilters: '=',
      exportSortOrder: '=',
      exportType: '=',
      exportFlag: '=?',
      exportBlocksState: '=?',
      isExportDisabled: '=?',
      exportItemIds: '=?'
    },
    controller(
      $scope,
      $modal,
      $filter,
      $timeout,
      exportFactory,
      navigationPanel,
      helpers
    ) {
      let currentExport = ''; // Save export type. Need to prevent export if user changed location
      let exportOptions = [];

      // Cut analytics data and Open print dialog ------->
      //
      const exportPdf = function() {
        if (currentExport !== $scope.exportType) {
          $scope.exportFlag = false;
          return false;
        }

        const elem = window.$('#init-analytics-container');
        const elements = [];
        elements.push(window.$('#printHeader').clone());
        elements.push(window.$('#timeFilters').clone());
        exportOptions.forEach(function(item) {
          if (item.value) {
            elements.push(window.$(`#${item.key}`).clone());
          }
        });
        elements.forEach(function(item, i) {
          elements[i].find('input').each(function() {
            if (!window.$(this).prop('checked')) {
              window
                .$(this)
                .parent()
                .parent()
                .remove();
            } else {
              window
                .$(this)
                .parent()
                .addClass('__for-print');
            }
          });
        });

        const allLinks = window
          .$('head')
          .clone()
          .find('script')
          .remove()
          .end()
          .html();
        // var scripts = '';
        // window.$('body').find('script').each(function() { scripts += ' '+this.outerHTML ; });
        const popupWin = window.open(
          '',
          '_blank',
          `width=${elem.width()},height=700,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no`
        );
        $timeout(function() {
          $scope.exportFlag = false;
          popupWin.window.focus();
          popupWin.document.write(
            `<!DOCTYPE html><html><head>${allLinks}<style type="text/css">` +
              'body { overflow-y: auto !important; }' +
              '.analytics-manage-block .ps-container { max-height: none !important; }' +
              '.analytics-manage-block .ps-scrollbar-y-rail { display: none; }' +
              '.analytics-manage-block .manage-no-items { display: none; }' +
              '.pie-show-more-handler .btn { display: none; }' +
              '.btn.settings-form_btn.__manage { display: none; }' +
              '.only-for-print { display: block !important; }' +
              '.only-not-for-print { display: none !important; }' +
              '.analytics-block_close-btn { display: none; }' +
              '.timeline_wrapper-t.__detailedView .timeline_viewport { overflow: hidden !important; padding-top: 20px !important; }' +
              '.ps-scrollbar-y-rail { display: none !important; }' +
              '</style>' +
              '<title>Print Analytics</title>' +
              '</head><body>' +
              `<div id="ready-for-print" class="page __analytics __ready-for-print">${elements
                .map(function(item) {
                  return window
                    .$('<div />')
                    .append(
                      window
                        .$('<div />')
                        .attr('id', `printed${item.attr('id')}`)
                        .addClass(item.attr('class'))
                        .css('width', '100%')
                        .html(item.html())
                    )
                    .html();
                })
                .join(' ')}</div>` +
              '<script type="text/javascript">function outerHeight(el){var height=el.offsetHeight;var style=getComputedStyle(el);height+=parseInt(style.marginTop)+parseInt(style.marginBottom);return height}var items=document.getElementsByClassName("timeline_li");var timeHeight=outerHeight(items[0])*items.length+40;var helpersNodes=document.getElementsByClassName("timeline_height-helper");var helpers=[].slice.call(helpersNodes);helpers.forEach(function(h){h.style.height=(timeHeight<350)?"350px":timeHeight+"px"});</script>' +
              '</body>' +
              '</html>'
          );
        }, 0);
        setTimeout(function() {
          if (helpers.checkBrowser() === 'Firefox') {
            popupWin.location.reload();
          }
          popupWin.print();
        }, 2000);
        return undefined;
      };

      // Export in excel ------->
      //
      const exportExcel = function() {
        if (currentExport !== $scope.exportType) {
          $scope.exportFlag = false;
          return false;
        }

        const filters = $scope.exportSortOrder
          ? angular.extend({}, $scope.exportFilters, {
              sortOrder: $scope.exportSortOrder
            })
          : $scope.exportFilters;

        return exportFactory
          .getExportExcel(
            filters,
            exportOptions,
            $scope.exportType,
            $scope.exportItemIds,
            $scope.exportBlocksState
          )
          .finally(function() {
            $scope.exportFlag = false;
          });
      };

      // Get export file ---------->
      const sendExport = function(fileFormat) {
        // If not pdf -> get xls file from backend
        if (
          fileFormat !== 'pdf' ||
          $scope.exportType === 'visitorsSite' ||
          $scope.exportType === 'viewersCampaign'
        ) {
          exportExcel();
          return;
        }

        const exportParams = [];
        exportOptions.forEach(function(item) {
          if (item.value) exportParams.push(item.key);
        });
        // If pdf -> slice analytic data and open print dialog
        $scope.onExportPreconditions({
          callback: exportPdf,
          options: exportParams
        });
      };

      // Check if at least one block checked
      const isExportOptions = function(options) {
        return !!~options
          .map(function(item) {
            if (item.options) return isExportOptions(item.options);
            return +item.value;
          })
          .indexOf(1);
      };

      // On press export btn ---------->
      //
      $scope.export = function() {
        $scope.exportFlag = true;

        const scope = $scope.$new();
        // eslint-disable-next-line no-multi-assign
        scope.exportOptions = exportOptions = $scope.getExportOptions();
        scope.exportType = $scope.exportType;
        $scope.isSmallModal = scope.exportOptions.length <= 11;

        const modalWindowClass = `export_modal${
          $scope.isSmallModal ? ' __small-export' : ''
        }`;

        $modal
          .open({
            templateUrl: 'app/core/export/exportAnalyticsModal.html',
            windowClass: modalWindowClass,
            controller($scope, $modalInstance) {
              // Options for dropdown export list
              $scope.fileFormat = { value: 'xls' };
              $scope.optionsFilesFormats =
                scope.exportType === 'visitorsSite' ||
                scope.exportType === 'viewersCampaign'
                  ? ['xls']
                  : ['xls', 'pdf'];

              // Check if some of blocks should be always disabled
              $scope.checkAlwaysDisabled = function(type) {
                $scope.exportOptions.forEach(function(item, i) {
                  if (
                    ~exportFactory.alwaysDisabledBlocks[type].indexOf(item.key)
                  ) {
                    $scope.exportOptions[i].value = false;
                    $scope.exportOptions[i].isAlwaysDisabled = true;
                  } else {
                    $scope.exportOptions[i].isAlwaysDisabled = false;
                  }
                });
              };
              $scope.checkAlwaysDisabled($scope.fileFormat.value);

              // On print confirm
              $scope.ok = function() {
                // If at least one export option exists -> export
                if (isExportOptions($scope.exportOptions)) {
                  currentExport = angular.copy($scope.exportType);
                  sendExport($scope.fileFormat.value);
                  $modalInstance.close();
                } else {
                  // Else -> show info popup dialog
                  const i18n = $filter('i18n');
                  scope.msg = i18n('components.export.labels.exportError');
                  scope.title = i18n('components.export.labels.exportTitle');
                  $modal.open({
                    templateUrl: 'app/core/views/infoModalGlobal.html',
                    windowClass: 'wcm-msg',
                    scope
                  });
                }
              };
            },

            scope
          })
          .result.catch(function() {
            $scope.exportFlag = false;
          });
      };

      $scope.$on('$stateChangeStart', function() {
        currentExport = '';
      });

      navigationPanel.setActionButtons([
        {
          title: () => 'campaigns.buttons.export',
          isDisabled: () => $scope.isExportDisabled,
          isBusy: () => $scope.exportFlag,
          type: () => 'action-1',
          isShown: () =>
            angular.isDefined($scope.hasExportPermissions)
              ? $scope.hasExportPermissions
              : !$scope.isEditMode,
          clickHandler: () => {
            $scope.export();
          }
        }
      ]);
    }
  };
});
