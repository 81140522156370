/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.widgets')
  .controller('widgetsListCtrl', function(
    $scope,
    $modal,
    $state,
    breadcrumbs,
    navigationPanel,
    filtersFactory,
    widgetsFactory,
    helpers
  ) {
    breadcrumbs.set({
      path: [
        {
          name: 'widgets'
        },
        {
          name: 'list'
        }
      ]
    });
    $scope.setTabs([]);

    const widgetsTypes = [
      'Static_dynamic',
      'Slider',
      'Video',
      'Poll',
      'Custom'
    ];
    // eslint-disable-next-line no-multi-assign
    const allowedWidgets = ($scope.allowedWidgets = $scope.currentUser.userPermissions.widgetTypes.map(
      function(value) {
        const widgetMap = {
          'Static/Dynamic': 'Static_dynamic'
        };
        return widgetMap[value] ? widgetMap[value] : value;
      }
    ));

    let sortOption;
    let appliedFilters;

    $scope.widgetLinkDomain = window.location.origin;

    $scope.filters = filtersFactory.initFilters(
      {
        label: 'Status',
        options: {
          Active: 'Active',
          Archive: 'Archive'
        }
      },
      {
        label: 'Action',
        options: {
          All: '',
          Approved: 'Approved',
          Awaiting_approval: 'Awaiting_approval'
        }
      },
      {
        label: 'Sort',
        options: {
          'date:desc': 'date:desc',
          'date:asc': 'date:asc',
          'alphabetically:asc': 'alphabetically:asc',
          'alphabetically:desc': 'alphabetically:desc'
        },
        type: 'sort'
      },
      {
        label: 'State',
        options: {
          All: '',
          Linked: 'Linked',
          Unlinked: 'Unlinked'
        }
      },
      {
        label: 'ContentType',
        options: widgetsTypes.reduce(
          function(acc, value) {
            if (~allowedWidgets.indexOf(value)) {
              // eslint-disable-next-line no-param-reassign
              acc[value] = value;
            }
            return acc;
          },
          { All: '' }
        )
      }
    );
    $scope.itemsOnPage = 16;
    $scope.itemsOnPageValues = [16, 32, 64, 128];

    $scope.goToView = function(id) {
      $state.go('section.widgets.view.default', { id });
    };

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(
      data,
      requestPayload
    ) {
      $scope.totalItems = data.itemsTotal;
      $scope.canCreate = data.canCreate;
      $scope.archiveState = requestPayload.filterByStatus === 'Active';
    };

    $scope.onListUpdate = function onListUpdate(sortOpt, filters) {
      if (filters.filterByType !== $scope.currentTabName) return false;
      sortOption = sortOpt;
      appliedFilters = filters;
      return $scope.getPage();
    };

    $scope.getPage = function getPage() {
      widgetsFactory
        .getList(
          appliedFilters,
          $scope.currentPage,
          $scope.itemsOnPage,
          sortOption
        )
        .then(function(widgets) {
          $scope.widgetsList = widgets;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    // move to archive and recover from archive actions
    $scope.performAction = function(action, widgetId, widgetName) {
      helpers
        .confirmationModal(
          '',
          `widgets.modals.performAction.texts.${action}`,
          widgetName
        )
        .result.then(function() {
          widgetsFactory[action](widgetId).success(function() {
            $scope.applyFilters(true);
          });
        });
    };

    $scope.addWidget = function() {
      if (allowedWidgets.length > 1) {
        $scope.addWidgetModal();
      } else {
        // if we have only one allowed widget type, go to create state immediately
        $state.go(`section.widgets.create.${allowedWidgets[0].toLowerCase()}`);
      }
    };

    // modal window for add widget and choose type of it
    $scope.addWidgetModal = function() {
      const modalInstance = $modal.open({
        templateUrl: 'addWidget',
        windowClass: 'modal_add-widget',
        scope: $scope.$new()
      });
      modalInstance.result.then(function(data) {
        $state.go(`section.widgets.create.${data}`);
      });
    };

    $scope.isWidgetTypeAllowed = function(widgetType) {
      return !!~allowedWidgets.indexOf(widgetType);
    };
    /* END MODAL INSTANCE LOGIC */

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => !$scope.archiveState,
        isShown: () => $scope.canCreate,
        clickHandler: () => {
          $scope.addWidget();
        }
      }
    ]);
  });
