import React from 'react';
import PropTypes from 'prop-types';
import styles from './MenuItem.module.css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledWrapper = styled.div``;
const StyledSubLink = styled(Link)`
  &.active {
    font-weight: 600;
  }
  &:hover,
  &.active {
    border-right: 5px solid ${props => props.theme.systemColorScheme};
    background-color: #464a59;
    color: #fff;
  }
`;
const StyledLink = styled(Link)`
  ${StyledWrapper}:hover &::before,
  &.active::before {
    background-color: ${props => props.theme.systemColorScheme};
  }
`;
const StyledDiv = styled.div`
  ${StyledWrapper}:hover &::before,
  &.active::before {
    background-color: ${props => props.theme.systemColorScheme};
  }
`;

const MenuItem = ({ item, location, openedItems, setOpenedItems }) => {
  const { t } = useTranslation();
  const isActive = activePath => {
    return location.pathname.startsWith(activePath);
  };

  const toggleOpened = () => {
    const itemIndex = openedItems.indexOf(item.name);
    if (itemIndex === -1) {
      setOpenedItems([...[item.name], ...openedItems]);
    } else {
      setOpenedItems(
        openedItems.filter((el, index) => {
          return index !== itemIndex;
        })
      );
    }
  };

  const isOpened = () => {
    return openedItems.indexOf(item.name) !== -1;
  };

  return item.subItems.length > 0 ? (
    <StyledWrapper className={styles.itemWrapper}>
      <StyledDiv
        onClick={toggleOpened}
        data-hook-id={'bar_' + item.id.toLowerCase()}
        className={classNames(styles.item, styles[item.id.toLowerCase()], {
          active: isActive(item.activePath)
        })}
      >
        <span className={styles.itemName}>{t(item.name)}</span>
      </StyledDiv>
      {
        <ul
          className={classNames(styles.subMenu, {
            [styles.opened]: isOpened() || isActive(item.activePath)
          })}
        >
          {item.subItems.map((elem, index) => (
            <li data-hook-id={'bar_' + elem.id.toLowerCase()} key={index}>
              <StyledSubLink
                onClick={() => {
                  setOpenedItems([]);
                }}
                to={elem.path}
                className={classNames(styles.subItem, {
                  active: isActive(elem.activePath)
                })}
              >
                <span className={styles.itemName}>{t(elem.name)}</span>
              </StyledSubLink>
            </li>
          ))}
        </ul>
      }
    </StyledWrapper>
  ) : (
    <StyledWrapper
      className={styles.itemWrapper}
      data-hook-id={'bar_' + item.id.toLowerCase()}
      onClick={() => {
        setOpenedItems([]);
      }}
    >
      <StyledLink
        to={item.path}
        className={classNames(
          styles.item,
          styles.link,
          styles[item.id.toLowerCase()],
          { active: isActive(item.activePath) }
        )}
      >
        <span className={styles.itemName}> {t(item.name)}</span>
      </StyledLink>
    </StyledWrapper>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.string,
    activePath: PropTypes.string,
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        path: PropTypes.string,
        activePath: PropTypes.string
      })
    )
  }),
  location: PropTypes.object.isRequired,
  openedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOpenedItems: PropTypes.func.isRequired
};

export default MenuItem;
