const { moment, angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateCampaignViewersCtrl', function(
    $scope,
    $state,
    $controller,
    analyticsFactory2,
    analyticsRequestsFactory,
    campaignsFactory,
    navigationPanel,
    REPORTING_CONFIG,
    ANALYTICS_CONFIG2
  ) {
    const A_CONFIG = ANALYTICS_CONFIG2;
    const state = $scope.state;
    const options = $scope.options;
    let handlers = $scope.handlers;

    angular.extend($scope.options, {
      blocks: A_CONFIG.blocks.viewers
    });

    angular.extend($scope.state, {
      campaignId: 0
    });

    angular.extend($scope.state.filters, {
      interactionType: ''
    });

    // eslint-disable-next-line no-multi-assign
    handlers = $scope.handlers = angular.extend({}, handlers, {
      save() {
        $scope.$parent.handlers.save(state);
      },
      getAvailableValues() {
        return $scope.$parent.handlers.getAvailableValues().then(function() {
          state.filters.interactionType = 'viewedAndClicked';
          state.sortType = 'last_visit';
          state.sortDirection = 'desc';
          state.isBusy = false;
        });
      },
      getCampaignData() {
        return campaignsFactory.campaignGetData(state.campaignId);
      },
      onSelectCampaign() {
        return handlers.getCampaignData().then(function(res) {
          const start = res.data.campaign
            ? moment(res.data.campaign.general.creationDate).startOf('day')
            : state.filters.endPeriod;
          handlers.setBeginPeriodDates(start);
        });
      }
    });

    $scope.init = function() {
      $scope.$parent.init();
      return analyticsRequestsFactory
        .getAllCampaigns('campaigns', state.permissionType)
        .then(
          function(data) {
            options.items = data.campaigns;
            handlers.getAvailableValues();
          },
          function() {
            state.noWas = true;
          }
        );
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.generate',
        isDisabled: () => $scope.state.noWas || $scope.state.isBusy,
        isShown: () => true,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.reportForm.$valid) {
            $scope.handlers.save();
          }
        }
      },
      {
        title: () => 'reporting.buttons.cancel',
        isDisabled: () => $scope.state.isBusy,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);
  });
