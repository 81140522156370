/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.sites')
  .factory('siteVisitorsFactory', function(
    $q,
    $rootScope,
    requestFactory,
    sharedRequestsFactory,
    helpers,
    SITES_CONFIG,
    ANALYTICS_REQUESTS_CONFIG,
    CAMPAIGNS_CONFIG
  ) {
    const config = SITES_CONFIG;
    const MILISECONDS_IN_MINUTE = 60000;

    const mapStateToRequest = function(state) {
      const filters = state.filters || {};
      const data = {
        filterBySiteId: +state.siteId || 0,
        filterByPeriod: [filters.startPeriod, filters.endPeriod].map(function(
          moment
        ) {
          const _moment = angular.copy(moment);
          // cast to utc midnight
          return _moment
            .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
            .startOf('day')
            .valueOf();
        }),
        filterByTimeframe: [
          filters.startTimeFrame || 0,
          filters.endTimeFrame || 0
        ],
        filterByDays: filters.daysOfWeek || [],
        filterBySearchString: filters.searchString || '',
        filterByGender: filters.genders || [],
        filterByAge: filters.ages || [],
        filterByDeviceType: filters.deviceTypes || [],
        filterByOs: filters.OS || [],
        filterByTop: +filters.top || 0,
        sortOrder: state.sortOrder
      };
      return data;
    };

    const getAvailableValues = function getAvailableValues() {
      return requestFactory
        .post(
          CAMPAIGNS_CONFIG.campaignsServlet,
          CAMPAIGNS_CONFIG.campaignGetAvailableValues,
          {
            type: ''
          }
        )
        .then(function(res) {
          return res.data ? res.data : {};
        })
        .then(function(values) {
          const _values = values.visitors;
          return {
            ages: _values.age,
            genders: _values.gender,
            deviceTypes: _values.deviceType,
            OS: _values.os,
            tops: [10, 50, 100]
          };
        });
    };

    const getList = function getList(state) {
      const offset = (state.currentPage - 1) * state.itemsPerPage;
      let filters = {
        sortOrder: state.sortOrder,
        filterOffset: offset,
        filterItemsNumber: state.itemsPerPage,
        terminateType: sharedRequestsFactory.terminateTypes[state.terminateType]
      };
      filters = angular.extend(filters, mapStateToRequest(state));
      return requestFactory
        .post(
          config.siteVisitorsServlet,
          config.siteVisitorsGetList,
          filters,
          {},
          { skipErrors: ANALYTICS_REQUESTS_CONFIG.skipErrorCodes }
        )
        .then(
          function(res) {
            if (
              res.status === 204 ||
              res.status === 244 ||
              !res.data.visitors ||
              !res.data.itemsTotal
            ) {
              return $q.reject(res);
            }
            return res.data || {};
          },
          function(res) {
            return $q.reject(res);
          }
        )
        .then(
          function(data) {
            // if empty set or full set -- return this set, or add empty entries to have full set
            // eslint-disable-next-line no-param-reassign
            data.visitors = helpers.fillListWithEmptyStrings(
              data.visitors,
              state.itemsPerPage
            );
            return data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    return {
      getAvailableValues,
      mapStateToRequest,
      getList
    };
  });
