/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('dictionariesFactory', function(
    requestFactory,
    DICTIONARIES_CONFIG
  ) {
    const getDictionaries = function() {
      return requestFactory
        .post(
          DICTIONARIES_CONFIG.dictionariesServlet,
          DICTIONARIES_CONFIG.getDictionaries,
          {}
        )
        .then(res =>
          res && res.data && res.data.dictionaries ? res.data.dictionaries : []
        )
        .then(dictionaries => dictionaries.sort((a, b) => a.name > b.name));
    };

    const saveDictionary = function(dictionary) {
      return requestFactory.post(
        DICTIONARIES_CONFIG.dictionariesServlet,
        DICTIONARIES_CONFIG.saveDictionary,
        dictionary
      );
    };

    return {
      getDictionaries,
      saveDictionary
    };
  });
