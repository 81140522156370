/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.campaigns')
  .factory('campaignsFactory', function(
    $q,
    $rootScope,
    requestFactory,
    CAMPAIGNS_CONFIG,
    helpers
  ) {
    let requestPrognosisObj = {};

    const preprocessSave = function(campaign) {
      const result = angular.copy(campaign);
      result.sites = window._.reduce(
        result.sites,
        function(memo, value, key) {
          if (value) {
            memo.push(parseInt(key, 10));
          }
          return memo;
        },
        []
      );

      return result;
    };

    const initSalt = function() {
      const max = 100000000000;
      const min = 999999999999;
      return `&${Math.floor(Math.random() * (max - min + 1)) + min}`;
    };

    const getList = function getList(filters, page, ipp, sortOrder) {
      const requestData = angular.extend(
        {},
        filters,
        helpers.getPaginationFilters(page, ipp, sortOrder)
      );
      return requestFactory
        .post(
          CAMPAIGNS_CONFIG.campaignsServlet,
          CAMPAIGNS_CONFIG.getList,
          requestData
        )
        .then(function(res) {
          return res.data && res.data.campaigns ? res.data.campaigns : [];
        })
        .then(function(values) {
          $rootScope.$broadcast('campaigns:list-updated', values);
          return values;
        });
    };

    const moveToArchive = function(id) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.moveToArchive,
        { id: id * 1 }
      );
    };

    const recoverFromArchive = function(id) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.recoverFromArchive,
        { id: id * 1 }
      );
    };
    const campaignRemove = function(id) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignRemove,
        { id: id * 1 }
      );
    };

    const campaignGetData = function(id) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignGetData,
        { id: id * 1 }
      );
    };

    const campaignPrognoze = function(data) {
      const payload = preprocessSave(data);
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignPrognoze,
        payload
      );
    };

    const campaignConfirmAndSave = function(id) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignConfirmAndSave,
        { id: id * 1 }
      );
    };

    const campaignSave = function(data) {
      const payload = preprocessSave(data);
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignSave,
        payload
      );
    };

    const campaignGetAvailableValues = function(type) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignGetAvailableValues,
        { type }
      );
    };
    const campaignGetAllCampaigns = function(type, id) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignGetAllCampaigns,
        { type, id }
      );
    };
    const campaignGetOccupation = function() {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.commonServlet,
        CAMPAIGNS_CONFIG.campaignGetOccupation,
        {}
      );
    };
    const campaignGetInterests = function() {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.commonServlet,
        CAMPAIGNS_CONFIG.campaignGetInterests,
        {}
      );
    };
    const campaignGetAllCities = function() {
      return requestFactory
        .post(
          CAMPAIGNS_CONFIG.analyticsCampaignServlet,
          CAMPAIGNS_CONFIG.campaignGetAllCities,
          {},
          {},
          { skipErrors: [502, 504, 244, 204] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            helpers.noServerInfoModal(res.status);
            return $q.reject(res);
          }
        );
    };
    const campaignGetAllCountries = function() {
      return requestFactory
        .post(
          CAMPAIGNS_CONFIG.analyticsCampaignServlet,
          CAMPAIGNS_CONFIG.campaignGetAllCountries,
          {},
          {},
          { skipErrors: [502, 504, 244, 204] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            helpers.noServerInfoModal(res.status);
            return $q.reject(res);
          }
        );
    };
    const getLivePrognosis = function(livePrognosisData) {
      const salt = initSalt();
      return requestFactory.post(
        CAMPAIGNS_CONFIG.analyticsCampaignServlet,
        CAMPAIGNS_CONFIG.getLivePrognosis + salt,
        livePrognosisData,
        {},
        { skipErrors: [502, 504, 0, -1] }
      );
    };
    const campaignConfirmAction = function(id) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignConfirmAction,
        { id: id * 1 }
      );
    };

    const generateDemoLink = function(chainId, pId, aId) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.generateDemoLink,
        {
          chainId,
          partnerId: pId,
          advertiserId: aId
        }
      );
    };
    const getCampaignsForDemo = function(type) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.getCampaignsForDemo,
        { type }
      );
    };
    const iconClassName = function(name) {
      return `__${name.toLowerCase()}`;
    };

    const campaignPerformAction = function(id, action) {
      return requestFactory.post(
        CAMPAIGNS_CONFIG.campaignsServlet,
        CAMPAIGNS_CONFIG.campaignPerformAction,
        {
          id: id * 1,
          action
        }
      );
    };
    const campaignGetSitesForImpact = function(data) {
      const sites = window._.reduce(
        data.sites,
        function(memo, value, key) {
          if (value) {
            memo.push(parseInt(key, 10));
          }
          return memo;
        },
        []
      );
      // eslint-disable-next-line no-param-reassign
      data.sites = sites;
      return requestFactory.post(
        CAMPAIGNS_CONFIG.analyticsCampaignServlet,
        CAMPAIGNS_CONFIG.campaignGetSitesForImpact,
        data
      );
    };

    const getProgressColor = function(healthSize) {
      let _healthSize;
      if (!healthSize) {
        _healthSize = 0;
      }
      if (healthSize > 100) {
        _healthSize = 100;
      }

      const progressColors = [
        '#d10000',
        '#ff0000',
        '#ff2600',
        '#ff8000',
        '#ffd500',
        '#add400',
        '#7ebd00',
        '#55ab00',
        '#399c00',
        '#158a00'
      ];

      let healthColorIndex = (Math.round(_healthSize / 10) * 10) / 10;
      healthColorIndex = healthColorIndex !== 0 ? healthColorIndex - 1 : 0;
      return healthColorIndex >= 0 && healthColorIndex <= 14
        ? progressColors[healthColorIndex]
        : progressColors[0];
    };

    const getTimeFrameValues = function() {
      const timeFrames = [];
      let timeframe;
      let hours;
      let minutes;
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 20) {
          if (`${i}`.length === 1) {
            hours = `0${i}`;
          } else {
            hours = `${i}`;
          }
          if (`${j}`.length === 1) {
            minutes = `0${j}`;
          } else {
            minutes = `${j}`;
          }

          timeframe = `${hours}:${minutes}`;

          timeFrames.push(timeframe);
        }
      }
      timeFrames.push('24:00');
      return timeFrames;
    };
    const getOptions = function() {
      const launchDate = new Date(); // current date
      launchDate.setMinutes(launchDate.getMinutes() + 20); // current + 20 minutes
      const endDate = new Date();
      endDate.setMonth(endDate.getMonth() + 3); // current date + 3 months
      endDate.setMinutes(endDate.getMinutes() + 20); // current date + 3 months + 20 minutes
      return {
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        defaultCheckedSites: {},
        campaignsForDemo: [],
        owners: [],
        timeFrames: getTimeFrameValues(),
        timeFrameHours: [
          {
            startTime: '00:00',
            endTime: '24:00'
          }
        ],
        launchDate,
        endDate,
        filteredSites: [],
        sitesPage: [],
        sitesItemsPerPage: 40,
        sitesCurrentPage: 1,
        sitesItemsOnPageValues: [40, 80, 120]
      };
    };

    const customValidators = {
      endDate(launchDate, endDate, flag) {
        return (
          !flag ||
          (flag &&
            (new Date(endDate) > new Date(launchDate) &&
              new Date(endDate) > new Date()))
        );
      },
      startDate(launchDate, flag) {
        return !flag || (flag && new Date(launchDate) > new Date());
      },
      days() {
        return false;
      }
    };

    // Live Prognosis functions
    //

    /**
     * Maps campaign object data and live prognosis request to the live prognosis data object
     * @param {object} campaign - campaign object
     * @param {object} lpResponse - live prognosis response object
     */
    const livePrognosisDefaultData = {
      specificity: 1.0,
      firstOrderImpacts: [],
      secondOrderImpacts: [],
      currentUniqueViews: -1,
      averageUniqueViews: -1,
      currentCTR: -1,
      averageCTR: -1,
      foundMatches: -1,
      expectedMatches: -1,
      estimatedFinish: -1
    };

    const composeLivePrognosisData = function(campaign, lpResponse) {
      const periodStartDate = Math.max(campaign.launchDate, Date.now());

      const lpData = angular.extend(
        {},
        livePrognosisDefaultData,
        lpResponse,
        {
          campaignId: campaign.id,
          endDate: campaign.endDate,
          launchDate: campaign.launchDate,
          periodStartDate
        }
      );

      const combinedImpacts = lpData.firstOrderImpacts.concat(
        lpData.secondOrderImpacts
      );
      const currentCampaign =
        getCampaignById(campaign.id, combinedImpacts) || {};

      angular.extend(currentCampaign, {
        isCurrent: true,
        campaignName: campaign.name || 'Default name',
        launchDate: campaign.launchDate,
        endDate: campaign.endDate
      });

      lpData.periodEndDate = campaign.endDate
        ? campaign.endDate
        : getMaxEndDate(combinedImpacts);

      return lpData;
    };

    // ----> I'm deeply sorry for that shit! <----
    const isSendLivePrognosis = function(obj, campaign) {
      return (
        campaign.status !== 'Archive' &&
        obj.general.chainId &&
        ((obj.sites && obj.dateTime && obj.sites[0] && obj.dateTime.days[0]) ||
          (!obj.dateTime && obj.sites && obj.sites[0]) ||
          (!obj.sites && obj.dateTime && obj.dateTime.days[0]) ||
          (!obj.sites && !obj.dateTime))
      );
    };
    // / --------------------

    const mapCampaignToLivePrognosisRequest = function(campaign) {
      const obj = helpers.mapCampaignRequest(campaign);
      return obj;
    };

    const initLivePrognosis = function(scope, opts) {
      const defaultOptions = {
        debounceTimeout: 500,
        refreshEvent: 'livePrognosis:refresh',
        onDataReceived: angular.noop,
        onRequestStart: angular.noop,
        onRequestFinish: angular.noop
      };

      const options = angular.extend({}, defaultOptions, opts);

      const processRequest = function() {
        // Sending Live-Prognosis by some conditions
        if (isSendLivePrognosis(requestPrognosisObj, scope.campaign)) {
          const stopRequest = options.onRequestStart();
          if (stopRequest) return;

          getLivePrognosis(requestPrognosisObj)
            .success(function(data, status) {
              if (status === 200) {
                options.onDataReceived(data);
              }
              options.onRequestFinish({ error: false });
            })
            .error(options.onRequestFinish.bind(null, { error: true }))
            .finally(function() {
              requestPrognosisObj = window._.pick(
                requestPrognosisObj,
                'general',
                'dateTime'
              );
            });
        } else {
          scope.$evalAsync(function() {
            options.onDataReceived(livePrognosisDefaultData);
          });
        }
      };

      const debouncedProcessRequest = window._.debounce(
        processRequest,
        options.debounceTimeout
      );

      const unwatches = helpers.unwatches(
        ['general', 'dateTime', 'device', 'sites', 'profile', 'retargeting'],
        function(block) {
          return scope.$watch(
            function() {
              // Add chainId to campaign
              const campaign = angular.copy(scope.campaign);
              campaign.chainId = scope.authChain ? scope.authChain.chainId : 0;
              return mapCampaignToLivePrognosisRequest(campaign)[block];
            },
            function(val, prev) {
              if (!prev) return null;
              requestPrognosisObj[block] = val;
              debouncedProcessRequest();
              return undefined;
            },
            true
          );
        }
      );
      unwatches.push($rootScope.$on(options.refreshEvent, processRequest));

      return function() {
        angular.forEach(unwatches, function(unwatch) {
          unwatch();
        });
      };
    };

    // helpers functions
    function getCampaignById(id, campaigns) {
      return campaigns
        .filter(function(cmp) {
          return cmp.campaignId === id;
        })
        .slice(-1)[0];
    }

    function endingDates(campaigns) {
      return campaigns.map(function(cmp) {
        return cmp.endDate || 0;
      });
    }

    function getMaxEndDate(impacts) {
      return Math.max.apply(null, endingDates(impacts));
    }

    return {
      getList,
      moveToArchive,
      recoverFromArchive,
      campaignRemove,
      campaignGetData,
      campaignPrognoze,
      campaignConfirmAndSave,
      campaignSave,
      getProgressColor,
      getTimeFrameValues,
      campaignGetSitesForImpact,
      campaignGetAvailableValues,
      campaignGetCampaigns: campaignGetAllCampaigns,
      campaignGetOccupation,
      campaignGetInterests,
      campaignGetCities: campaignGetAllCities,
      campaignGetCountries: campaignGetAllCountries,
      campaignPerformAction,
      campaignConfirmAction,
      getOptions,
      iconClassName,
      generateDemoLink,
      getCampaignsForDemo,
      customValidators,
      mapCampaignToLivePrognosisRequest,
      composeLivePrognosisData,
      getLivePrognosis,
      initLivePrognosis
    };
  });
