import React from 'react';
import PropTypes from 'prop-types';

const ValidationMessage = ({ message, hook }) => {
  return (
    <div className="authorizing__error-msg" data-hook-id={hook}>
      {message}
    </div>
  );
};

ValidationMessage.propTypes = {
  message: PropTypes.node,
  hook: PropTypes.string
};

export default ValidationMessage;
