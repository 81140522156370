/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.admin').factory('systemFactory', function() {
  function authorizeRoute(userObj, route) {
    if (~route.indexOf('monitoring')) {
      // allow monitoring only for super admin or ghosts
      return !!(
        ~['ghost'].indexOf(userObj.userRole.toLowerCase()) ||
        userObj.userId === 'Admin'
      );
    }
    // allow any section for admins and ghosts
    if (~['ghost', 'administrator'].indexOf(userObj.userRole.toLowerCase()))
      return true;
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  function getFallbackRoute(userObj, requestedRoute) {
    // system route doesn't have fallbacks since it's available for admins only
    return false;
  }

  return {
    authorizeRoute,
    getFallbackRoute
  };
});
