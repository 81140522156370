const { angular } = window;

angular.module('wcm.components').factory('dropdown', function() {
  function getMultiSelectLabel(i18nPrefix, filters, options) {
    let label = 'components.filters.labels.';
    if (!filters || !options) {
      return '';
    }
    switch (filters.length) {
      case 0:
        label += 'none';
        break;
      case 1:
        label = `${i18nPrefix}.${filters[0]}`;
        break;
      case options.length:
        label += 'all';
        break;
      default:
        label += 'mult';
    }
    return label;
  }

  return {
    getMultiSelectLabel
  };
});
