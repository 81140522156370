// New export! Used only in Reports at now.
// Should be implement in whole system instead of old "export directive"
const { angular } = window;

angular.module('wcm.exportButton').component('exportButton', {
  templateUrl: 'app/core/components/export/exportButton.html',
  controller: 'exportButtonController',
  bindings: {
    title: '@',
    setExportOptions: '&',
    onExportPreconditions: '&',
    preconditionParams: '<',
    permissionParams: '<',
    isExportDisabled: '<',
    exportFilters: '<',
    exportSortOrder: '<',
    exportType: '<',
    exportFlag: '=',
    exportBlocksState: '<',
    exportItemIds: '<',
    exportAlwaysDisabledBlocks: '<' // blocks, that must be always disabled (xls/pdf)
  }
});
