const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingViewViewersVisitorsCtrl', function(
    $scope,
    $q,
    $state,
    $location,
    $rootScope,
    $controller,
    analyticsFactory2,
    reportingFactory,
    reportingExport,
    campaignVisitorsFactory,
    breadcrumbs,
    navigationPanel,
    helpers,
    REPORTING_CONFIG,
    EXPORT_CONFIG
  ) {
    $scope.inReporting = true;

    const config = REPORTING_CONFIG;
    const routeParams = $location
      .path()
      .split('/')
      .reverse();
    const listType = reportingFactory.getReportsType(routeParams);

    const ctrlInherit = config.controllersViewMap[listType];
    $controller(ctrlInherit, { $scope });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      blocks: analyticsFactory2.exportPresets.visitors,
      items: [],
      itemsOnPageValues: config.itemsOnPageValues,
      sortDirections: config.sortDirections,
      sortTypes: config.sortTypes
    }));
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      callbackRoute: config.callbackRoutes[listType],
      currentPage: 1,
      campaignId: null,
      exportAlwaysDisabledBlocks: config.exportAlwaysDisabledBlocksVisitors,
      exportFlag: false,
      exportModalTitle: 'components.export.labels.exportDataTitle',
      exportPermissionParams: [],
      exportPreconditionParams: [],
      exportType: config.exportType,
      hardlinkTitle: config.hardlinkTitles[listType],
      itemName: null,
      itemNameLabel: null,
      itemsTotal: 0,
      isBusy: true,
      listType,
      report: {},
      reportBlocks: [],
      reportId: +routeParams[0],
      siteId: null,
      settings: {},
      sortOrder: null,
      visitors: null
    }));

    angular.extend($scope.state.filters, {
      interactionType: null,
      itemsPerPage: 0
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      setTabs(params) {
        navigationPanel.setTabs(config.tabs.views[state.listType]);
        breadcrumbs.set({
          path: `${config.breadcrumbs[state.listType]}>view`
            .split('>')
            .map(name => ({ name })),
          itemName: state.settings.name || ''
        });
        return params;
      },

      clearPage() {
        state.isBusy = true;
        state.isReady = false;
        state.visitors = [];
      },

      setList(res, firstInit) {
        state.report = res.data.report;
        state.settings = res.data.settings || {};
        state.hasOnlyExcelBlocks =
          state.listType === 'monitoring'
            ? false // has only excel block handling shouldn't affect monitoring
            : reportingFactory.hasOnlyExcelBlocks(state.settings.blockList);

        state.isReady = res.status === 200;

        state.itemName = res.data.campaignOrSiteName || null;
        switch (res.data.settings.type) {
          case 'siteSpecificVisitors':
            state.itemNameLabel = 'siteName';
            break;
          case 'campaignSpecificViewers':
            state.itemNameLabel = 'campaignName';
            break;
          default:
            state.itemNameLabel = null;
        }
        state.campaignId = state.settings.filterByCampaignId;
        state.siteId = state.settings.filterBySiteId;
        state.reportBlocks = reportingFactory.convertParamsToColumns(
          state.settings.blockList
        );

        if (firstInit) {
          handlers.setListFiltersAndParams();
        }

        if (res.data.progress >= 0) {
          state.settings = handlers.setProgress(res.data);
        }

        if (state.settings.isExcel) {
          state.exportPermissionParams.unshift(EXPORT_CONFIG.fileXls);
        }

        if (state.isReady) {
          state.visitors = res.data.report.visitors;
          state.visitors = campaignVisitorsFactory.prepareInteractionSites(
            state.visitors
          );
          state.itemsTotal = res.data.report.itemsTotal;
        }
      },

      getList() {
        if ($rootScope.isHardlink) {
          handlers.getPageHardlink(true);
        } else {
          handlers.getPage();
        }
      },

      getPage(firstInit) {
        if (!firstInit) {
          handlers.clearPage();
        }
        state.exportPermissionParams = [];
        state.exportPreconditionParams = [];
        return reportingFactory
          .getViewersVisitorsReport(state)
          .then(function(res) {
            handlers.setList(res, firstInit);
            return res;
          }, handlers.showNoWas)
          .then(function(res) {
            state.isBusy = false;
            return res;
          });
      },

      getPageHardlink(hardUpdate) {
        const params = `&filterOffset=${(state.currentPage - 1) *
          state.filters.itemsPerPage}&filterItemsNumber=${
          state.filters.itemsPerPage
        }`;
        return handlers.getReportByHardlink(
          $state.params.id,
          params,
          hardUpdate
        );
      },

      setListFiltersAndParams() {
        state.filters.startPeriod = state.settings.period[0];
        state.filters.endPeriod = state.settings.period[1];
        state.filters.startTimeFrame = state.settings.timeframe[0];
        state.filters.endTimeFrame = state.settings.timeframe[1];
        state.filters.daysOfWeek = state.settings.days;
        state.filters.allTime = state.settings.isAllTime;
        state.filters.OS = state.settings.filterByOs;
        state.filters.top = state.settings.filterByTop;
        state.filters.ages = state.settings.filterByAge;
        state.filters.genders = state.settings.filterByGender;
        state.filters.deviceTypes = state.settings.filterByDeviceType;
        state.filters.searchString = state.settings.filterBySearchString;
        state.filters.itemsPerPage = state.settings.filterItemsNumber;
        state.filters.interactionType = state.settings.filterByInteractionType;
        state.filters.itemsOnPageValues = [state.filters.itemsPerPage];
        state.sortOrder = state.settings.sortOrder;
      },

      setHardlinkListOptions() {
        $scope.options.deviceTypes = config.hardlinkVisitorsOptions.deviceTypes;
        $scope.options.OS = config.hardlinkVisitorsOptions.OS;
        $scope.options.tops = config.hardlinkVisitorsOptions.tops;
        $scope.options.genders = config.hardlinkVisitorsOptions.genders;
        $scope.options.ages = config.hardlinkVisitorsOptions.ages;
      },

      setProgress(progressData) {
        return reportingFactory.prepareReportsProgress(
          angular.extend({}, state.settings, {
            creationDate: progressData.creationDate,
            completedOn: progressData.completedOn,
            refreshDate: progressData.refreshDate,
            remainingTime: progressData.remainingTime,
            progress: progressData.progress,
            reportStatus: progressData.status
          })
        );
      },

      getAvailableValues: window._.memoize(function() {
        return campaignVisitorsFactory
          .getAvailableValues()
          .then(function(data) {
            $scope.options = angular.extend(
              $scope.options,
              angular.copy(data)
            );
            $scope.state.filters = angular.extend(
              {},
              $scope.state.filters,
              (function() {
                const d = {};
                Object.keys(data).forEach(function(key) {
                  d[key] = [];
                });
                return d;
              })()
            );
            state.filters.itemsPerPage = options.itemsOnPageValues[0];
          });
      }),

      setExportOptions() {
        const exportOptions = [];
        const allBlocks = state.reportBlocks;
        allBlocks.forEach(function(item) {
          // skip hidden mac addresses
          if (item === 'macAddress' && state.settings.hiddenMac) {
            return false;
          }
          const block = remapExportOptions(item);
          exportOptions.push({
            key: block,
            name: `sites.exportLabels.${block}`,
            isDisabled: false,
            isAlwaysDisabled: true,
            value: true
          });
          return undefined;
        });
        return window._.uniq(exportOptions, 'key');
      },
      refreshCurrentReport() {
        state.isBusy = true;
        state.exportPermissionParams = [];
        state.exportPreconditionParams = [];
        return reportingFactory.refreshReport(state).then(function() {
          handlers.clearPage();
          $scope.init();
        });
      },

      isColumnVisible(column) {
        // macAddress should be always available in monitoring reports, since it's being used
        // for visitor detailed view link
        // so we need a different check for its presence as a column in list
        if (column === 'macAddress') {
          return state.settings && !state.settings.hiddenMac;
        }
        return (
          !state.reportBlocks.length || ~state.reportBlocks.indexOf(column)
        );
      },

      showNoWas() {
        state.noWas = true;
        state.isBusy = false;
      },

      disableButtons() {
        return !state.isReady || !state.visitors.length;
      },

      isExportButton() {
        return state.settings.isExcel;
      },

      getReportByHardlink(id, params, hardUpdate) {
        if (
          !reportingFactory.hardlinkReport ||
          hardUpdate ||
          reportingFactory.hardlinkReport.status !== 200 ||
          reportingFactory.hardlinkReport.data.id !== id
        ) {
          return reportingFactory
            .getReportByHardlink(id, params)
            .then(function(res) {
              // eslint-disable-next-line no-param-reassign
              reportingFactory.hardlinkReport = res;
              // eslint-disable-next-line no-param-reassign
              reportingFactory.hardlinkReport.data.id = id;
              handlers.setHardlinkListOptions(); // Set list options for Hardlink
              handlers.setList(reportingFactory.hardlinkReport, true);
              return res;
            })
            .finally(function() {
              state.isBusy = false;
            });
        }
        handlers.setHardlinkListOptions(); // Set list options for Hardlink
        handlers.setList(reportingFactory.hardlinkReport, true);
        state.isBusy = false;
        return undefined;
      },

      getSortOrder() {
        return state.settings.sortOrder;
      },

      hasViewPermission,

      isAllSelected:
        $scope.handlers.isAllSelected || $scope.handlers.isAllFiltersSelected
    }));

    $scope.init = function() {
      handlers.setTabs();
      navigationPanel.setActionButtons([
        {
          title: () => 'reporting.buttons.refresh',
          isDisabled: () => handlers.disableButtons(),
          isShown: () =>
            !state.isBusy &&
            !state.noWas &&
            state.listType !== 'systemWideGeneral' &&
            state.report,
          clickHandler: () => {
            handlers.refreshCurrentReport();
          }
        },
        {
          title: () => 'reporting.buttons.export',
          isBusy: () => state.exportFlag,
          type: () => 'action-1',
          isDisabled: () => handlers.disableButtons(),
          isShown: () =>
            !state.isBusy &&
            !state.noWas &&
            state.report &&
            handlers.isExportButton(),
          clickHandler: () => {
            state.exportFlag = true;

            reportingExport
              .export({
                exportOptions: handlers.setExportOptions(),
                exportType: state.exportType,
                exportAlwaysDisabledBlocks: state.exportAlwaysDisabledBlocks,
                exportBlocksState: state.exportBlocksState,
                exportFilters: state,
                permissionParams: state.exportPermissionParams,
                title: state.exportModalTitle,
                preconditionParams: state.exportPreconditionParams
              })
              .finally(() => {
                state.exportFlag = false;
              });
          }
        },
        {
          title: () => 'reporting.buttons.close',
          type: () => 'reject',
          isDisabled: () => false,
          isShown: () => true,
          clickHandler: () => {
            $scope.closeReport(state.callbackRoute);
          }
        }
      ]);
      if (!$state.params.id) {
        return false;
      }
      return (function() {
        if ($rootScope.isHardlink) {
          return handlers.getPageHardlink();
        }

        if (!handlers.hasViewPermission()) {
          return false;
        }
        if (!$rootScope.isHardlink) {
          return handlers.getAvailableValues().then(function() {
            return handlers
              .getPage(true)
              .then(handlers.setTabs)
              .finally(function() {
                state.isBusy = false;
              });
          }, handlers.showNoWas);
        }
        return undefined;
      })().then(function(res) {
        $q.when(res).then(function(res) {
          if (
            res &&
            res.data &&
            angular.isDefined(res.data.status) &&
            res.data.status !== 'complete'
          ) {
            $scope.init.timeoutId = setTimeout(
              $scope.init,
              config.refreshViewTimeout
            );
          }
        });
      });
    };

    // Redirect if no permissions on this tab
    $scope.$on('$stateChangeSuccess', function() {
      if (!handlers.hasViewPermission()) {
        $scope.closeReport(state.callbackRoute);
        return false;
      }
      return undefined;
    });

    $scope.$on('$stateChangeStart', function() {
      clearTimeout($scope.init.timeoutId);
    });

    // Remap some export options for diff types of visitors list
    function remapExportOptions(item) {
      switch (item) {
        case 'macAddress':
          return state.campaignId || state.siteId
            ? 'macAddress_onlyExcel'
            : item;
        case 'clickedSites':
          return 'clickedSites_onlyExcel';
        case 'visitedSites':
          return 'visitedSites_onlyExcel';
        case 'device':
        case 'OS':
          return 'device_OS';
        case 'visitsQuantity':
          return state.campaignId ? 'interactionsQuantity' : item;
        case 'lastVisit':
          return state.campaignId ? 'lastInteraction' : item;
        default:
          return item;
      }
    }

    // Has view permission
    function hasViewPermission() {
      if (Number.isNaN(+$state.params.id)) return true; // check for hardlinks
      return $scope.hasPermission('view', state.listType);
    }
  });
