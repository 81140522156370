/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.reporting').constant('REPORTING_CONFIG', {
  servlet: 'reporting',
  reportsGetList: 'reportsGetList',
  reportsPerformAction: 'reportsPerformAction',
  createGeneralReport: 'createGeneralReport',
  getGeneralReport: 'getGeneralReport',
  createCampaignsReport: 'createCampaignsReport',
  getCampaignsReport: 'getCampaignsReport',
  createCampaignSpecificReport: 'createCampaignSpecificReport',
  getCampaignSpecificReport: 'getCampaignSpecificReport',
  createSitesReport: 'createSitesReport',
  getSitesReport: 'getSitesReport',
  createSiteSpecificReport: 'createSiteSpecificReport',
  getSiteSpecificReport: 'getSiteSpecificReport',
  createViewersVisitorsReport: 'createViewersVisitorsReport',
  getViewersVisitorsReport: 'getViewersVisitorsReport',
  exportReport: 'exportReport',
  refreshReport: 'refreshReport',
  searchInList: 'searchInList',
  refreshViewTimeout: 5000,
  refreshListTimeout: 5000,

  skipErrorsCodes: [0, -1, 204, 244, 245].concat(window._.range(300, 400, 1)),

  performActions: ['delete', 'set_public', 'set_private', 'refresh'],
  itemsOnPageValues: [10, 25, 50, 100],

  exportType: 'reporting',

  sortTypes: ['name', 'last_visit', 'birthday', 'visits_count'],
  sortDirections: ['asc', 'desc'],
  interactionTypes: [],

  // ---> 27.06.2017 <---
  // sortOrderTypes: [
  //   "name:asc",
  //   "name:desc",
  //   "created_on:asc",
  //   "created_on:desc",
  //   "last_visit:asc",
  //   "last_visit:desc",
  //   "birthday:asc",
  //   "birthday:desc",
  //   "visits_count:asc",
  //   "visits_count:desc"
  // ],

  listTypes: {
    systemWideGeneral: 'general',
    systemWideCampaigns: 'campaigns',
    systemWideSites: 'sites',
    campaignSpecificAnalytics: 'campaignSpecific',
    campaignSpecificViewers: 'campaignViewersList',
    siteSpecificAnalytics: 'siteSpecific',
    siteSpecificVisitors: 'siteVisitorsList',
    monitoring: 'monitoring'
  },

  permissionTypes: {
    systemWideGeneral: 'general',
    systemWideCampaigns: 'campaigns',
    systemWideSites: 'sites',
    campaignSpecificAnalytics: 'campaignSpecific',
    campaignSpecificViewers: 'campaignViewers',
    siteSpecificAnalytics: 'siteSpecific',
    siteSpecificVisitors: 'siteVisitors'
  },

  hardlinksStates: {
    general: 'reporting.systemWide.general.view',
    campaigns: 'reporting.systemWide.campaigns.view',
    sites: 'reporting.systemWide.sites.view',
    campaignSpecific: 'reporting.campaignSpecific.analytics.view',
    campaignViewersList: 'reporting.campaignSpecific.viewers.view',
    siteSpecific: 'reporting.siteSpecific.analytics.view',
    siteVisitorsList: 'reporting.siteSpecific.visitors.view',
    monitoring: 'reporting.monitoring.view'
  },

  hardlinkTitles: {
    systemWideGeneral: 'generalTitle',
    systemWideCampaigns: 'campaignsTitle',
    systemWideSites: 'sitesTitle',
    campaignSpecificAnalytics: 'campaignSpecificTitle',
    campaignSpecificViewers: 'campaignViewersListTitle',
    siteSpecificAnalytics: 'siteSpecificTitle',
    siteSpecificVisitors: 'siteVisitorsListTitle',
    monitoring: 'monitoringTitle'
  },

  controllersCreateMap: {
    systemWideGeneral: 'generalSystemAnalyticsCtrl',
    systemWideCampaigns: 'campaignsSystemAnalyticsCtrl',
    systemWideSites: 'sitesSystemAnalyticsCtrl',
    campaignSpecificAnalytics: 'campaignAnalyticsCtrl',
    siteSpecificAnalytics: 'siteAnalyticsCtrl',
    campaignSpecificViewers: 'campaignVisitorsCtrl',
    siteSpecificVisitors: 'siteVisitorsCtrl',
    monitoring: 'monitoringVisitorsListCtrl'
  },

  controllersViewMap: {
    systemWideGeneral: 'generalSystemAnalyticsCtrl',
    systemWideCampaigns: 'campaignsSystemAnalyticsCtrl',
    systemWideSites: 'sitesSystemAnalyticsCtrl',
    campaignSpecificAnalytics: 'campaignAnalyticsCtrl',
    siteSpecificAnalytics: 'siteAnalyticsCtrl',
    campaignSpecificViewers: 'campaignVisitorsCtrl',
    siteSpecificVisitors: 'siteVisitorsCtrl',
    monitoring: 'monitoringVisitorsListCtrl'
  },

  callbackRoutes: {
    systemWideGeneral: 'reporting.systemWide.general.list',
    systemWideCampaigns: 'reporting.systemWide.campaigns.list',
    systemWideSites: 'reporting.systemWide.sites.list',
    campaignSpecificAnalytics: 'reporting.campaignSpecific.analytics.list',
    siteSpecificAnalytics: 'reporting.siteSpecific.analytics.list',
    campaignSpecificViewers: 'reporting.campaignSpecific.viewers.list',
    siteSpecificVisitors: 'reporting.siteSpecific.visitors.list',
    monitoring: 'reporting.monitoring.list'
  },

  tabs: {
    lists: {
      systemWideGeneral: [
        { name: 'sitesReporting', sref: 'reporting.systemWide.sites.list' },
        {
          name: 'campaignsReporting',
          sref: 'reporting.systemWide.campaigns.list'
        },
        {
          name: 'generalReporting',
          sref: 'reporting.systemWide.general.list',
          current: true
        }
      ],
      systemWideCampaigns: [
        { name: 'sitesReporting', sref: 'reporting.systemWide.sites.list' },
        {
          name: 'campaignsReporting',
          sref: 'reporting.systemWide.campaigns.list',
          current: true
        },
        { name: 'generalReporting', sref: 'reporting.systemWide.general.list' }
      ],
      systemWideSites: [
        {
          name: 'sitesReporting',
          sref: 'reporting.systemWide.sites.list',
          current: true
        },
        {
          name: 'campaignsReporting',
          sref: 'reporting.systemWide.campaigns.list'
        },
        { name: 'generalReporting', sref: 'reporting.systemWide.general.list' }
      ],
      campaignSpecificAnalytics: [
        {
          name: 'campaignSpecificViewersReporting',
          sref: 'reporting.campaignSpecific.viewers.list'
        },
        {
          name: 'campaignSpecificReporting',
          sref: 'reporting.campaignSpecific.analytics.list',
          current: true
        }
      ],
      campaignSpecificViewers: [
        {
          name: 'campaignSpecificViewersReporting',
          sref: 'reporting.campaignSpecific.viewers.list',
          current: true
        },
        {
          name: 'campaignSpecificReporting',
          sref: 'reporting.campaignSpecific.analytics.list'
        }
      ],
      siteSpecificAnalytics: [
        {
          name: 'siteSpecificVisitorsReporting',
          sref: 'reporting.siteSpecific.visitors.list'
        },
        {
          name: 'siteSpecificReporting',
          sref: 'reporting.siteSpecific.analytics.list',
          current: true
        }
      ],
      siteSpecificVisitors: [
        {
          name: 'siteSpecificVisitorsReporting',
          sref: 'reporting.siteSpecific.visitors.list',
          current: true
        },
        {
          name: 'siteSpecificReporting',
          sref: 'reporting.siteSpecific.analytics.list'
        }
      ],
      monitoring: []
    },
    creates: {
      systemWideGeneral: [
        {
          name: 'generalReportingCreate',
          sref: 'reporting.systemWide.general.create',
          isDisabled: true
        }
      ],
      systemWideCampaigns: [
        {
          name: 'analyticsReportingCreate',
          sref: 'reporting.systemWide.campaigns.create',
          isDisabled: true
        }
      ],
      systemWideSites: [
        {
          name: 'analyticsReportingCreate',
          sref: 'reporting.systemWide.sites.create',
          isDisabled: true
        }
      ],
      campaignSpecificAnalytics: [
        {
          name: 'analyticsReportingCreate',
          sref: 'reporting.campaignSpecific.analytics.create',
          isDisabled: true
        }
      ],
      campaignSpecificViewers: [
        {
          name: 'viewersReportingCreate',
          sref: 'reporting.campaignSpecific.viewers.create',
          isDisabled: true
        }
      ],
      siteSpecificAnalytics: [
        {
          name: 'analyticsReportingCreate',
          sref: 'reporting.siteSpecific.analytics.create',
          isDisabled: true
        }
      ],
      siteSpecificVisitors: [
        {
          name: 'visitorsReportingCreate',
          sref: 'reporting.siteSpecific.visitors.create',
          isDisabled: true
        }
      ],
      monitoring: [
        {
          name: 'visitorsReportingCreate',
          sref: 'reporting.monitoring.create',
          isDisabled: true
        }
      ]
    },
    views: {
      systemWideGeneral: [
        {
          name: 'generalReportingView',
          sref: 'reporting.systemWide.general.view',
          isDisabled: true
        }
      ],
      systemWideCampaigns: [
        {
          name: 'campaignsReportingView',
          sref: 'reporting.systemWide.campaigns.view',
          isDisabled: true
        }
      ],
      systemWideSites: [
        {
          name: 'sitesReportingView',
          sref: 'reporting.systemWide.sites.view',
          isDisabled: true
        }
      ],
      campaignSpecificAnalytics: [
        {
          name: 'analyticsReportingView',
          sref: 'reporting.campaignSpecific.analytics.view',
          isDisabled: true
        }
      ],
      campaignSpecificViewers: [
        {
          name: 'viewersReportingView',
          sref: 'reporting.campaignSpecific.viewers.view',
          isDisabled: true
        }
      ],
      siteSpecificAnalytics: [
        {
          name: 'analyticsReportingView',
          sref: 'reporting.siteSpecific.analytics.view',
          isDisabled: true
        }
      ],
      siteSpecificVisitors: [
        {
          name: 'visitorsReportingView',
          sref: 'reporting.siteSpecific.visitors.view',
          isDisabled: true
        }
      ],
      monitoring: [
        {
          name: 'visitorsReportingView',
          sref: 'reporting.monitoring.view',
          isDisabled: true
        }
      ]
    }
  },

  breadcrumbs: {
    systemWideGeneral: 'reporting>systemWideAnalytics>general',
    systemWideCampaigns: 'reporting>systemWideAnalytics>campaigns',
    systemWideSites: 'reporting>systemWideAnalytics>sites',
    campaignSpecificAnalytics: 'reporting>campaignSpecific>analytics',
    campaignSpecificViewers: 'reporting>campaignSpecific>viewers',
    siteSpecificAnalytics: 'reporting>siteSpecific>analytics',
    siteSpecificVisitors: 'reporting>siteSpecific>visitors',
    monitoring: 'reporting>monitoring'
  },

  breadcrumbsLists: {
    systemWideGeneral: 'reporting>systemWideAnalytics',
    systemWideCampaigns: 'reporting>systemWideAnalytics',
    systemWideSites: 'reporting>systemWideAnalytics',
    campaignSpecificAnalytics: 'reporting>campaignSpecific',
    campaignSpecificViewers: 'reporting>campaignSpecific',
    siteSpecificAnalytics: 'reporting>siteSpecific',
    siteSpecificVisitors: 'reporting>siteSpecific',
    monitoring: 'reporting>monitoring'
  },

  createLinks: {
    systemWideGeneral: 'reporting.systemWide.general.create',
    systemWideCampaigns: 'reporting.systemWide.campaigns.create',
    systemWideSites: 'reporting.systemWide.sites.create',
    campaignSpecificAnalytics: 'reporting.campaignSpecific.analytics.create',
    campaignSpecificViewers: 'reporting.campaignSpecific.viewers.create',
    siteSpecificAnalytics: 'reporting.siteSpecific.analytics.create',
    siteSpecificVisitors: 'reporting.siteSpecific.visitors.create',
    monitoring: 'reporting.monitoring.create'
  },

  // Map request to blocks on the page
  blockMapOnReportView: {
    systemWideGeneral: {
      getGeneralInfo: 'generalInfoSystem',
      getDataTimeline: 'campaignsTimeline',
      getDataByTraffic: 'dataByTraffic',
      getAmountOfCampaigns: 'amountOfCampaigns',
      getAmountOfWidgets: 'amountOfWidgets',
      getAmountOfSites: 'amountOfSitesAndEntities',
      getAmountOfEntities: 'amountOfSitesAndEntities',
      getAmountOfViewsAndVisitors: 'amountOfViewsAndVisitors',
      getDataByTagsSites: 'dataByTags',
      getDataByTagsCampaigns: 'dataByTags',
      getQuantityOfRegisteredUsers: 'quantityOfRegisteredUsers',
      getVacantSites: 'vacantSites',
      getAllSystemSites: 'allSystemSites'
    },
    systemWideCampaigns: {
      getGeneralInfo: 'generalInfoCampaigns',
      getDataByComparison: 'dataByComparisonCampaigns',
      getDataByDays: 'dataByDays',
      getDataBySites: 'dataBySites',
      getDataByCitiesAndCountriesSiteLocation:
        'dataByCitiesAndCountriesSiteLocation',
      getDataByCitiesAndCountriesSocialNetworks:
        'dataByCitiesAndCountriesSocialNetworks',
      getDataByCitiesAndCountries: 'dataByCitiesAndCountries',
      getDataByDevices: 'dataByDevicesCampaigns',
      getDataByDemographicInfo: 'dataByDemographicInfoCampaigns',
      getDataByHours: 'dataByHoursCampaigns',
      getDataByWeek: 'dataByWeekCampaigns',
      getDataByMonths: 'dataByMonthsCampaigns',
      getDataByYears: 'dataByYearsCampaigns'
    },
    systemWideSites: {
      getGeneralInfo: 'generalInfoSitesAll',
      getDataByComparison: 'dataByComparisonSites',
      getDataByVisitors: 'dataByVisitors',
      getDataByTrafficUsage: 'dataByTrafficUsage',
      getDataByNumberOfVisits: 'dataByNumberOfVisits',
      getDataByVisitorsAgeAndGender: 'dataByVisitorsAgeAndGender',
      getDataByNewAndReturningVisitors: 'dataByNewAndReturningVisitors',
      getDataByNotReturningVisitors: 'dataByNotReturningVisitors',
      getDataByCampaigns: 'dataByCampaigns',
      getDataByCitiesAndCountriesSiteLocation:
        'dataByCitiesAndCountriesSitesSiteLocation',
      getDataByCitiesAndCountriesSocialNetworks:
        'dataByCitiesAndCountriesSitesSocialNetworks',
      getDataByCitiesAndCountries: 'dataByCitiesAndCountriesSites',
      getDataByDevices: 'dataByDevicesSites',
      getDataByDemographicInfo: 'dataByDemographicInfoSites',
      getDataByHours: 'dataByHoursSites',
      getDataByWeek: 'dataByWeekSites',
      getDataByMonths: 'dataByMonthsSites',
      getDataByYears: 'dataByYearsSites'
    },
    campaignSpecificAnalytics: {
      getGeneralInfo: 'generalInfoCampaigns',
      getDataByDaysAndSites: 'dataByDaysAndSites',
      getDataByDaysAndSitesAggregated: 'dataByDaysAndSitesAggregated',
      getDataByDaysAndSitesNotAggregated: 'dataByDaysAndSitesNotAggregated',
      getDataByNumberOfViews: 'dataByNumberOfViews',
      getDataBySites: 'dataBySites',
      getDataBySiteTags: 'dataBySiteTags',
      getDataByCitiesAndCountriesSiteLocation:
        'dataByCitiesAndCountriesSiteLocation',
      getDataByCitiesAndCountriesSocialNetworks:
        'dataByCitiesAndCountriesSocialNetworks',
      getDataByCitiesAndCountries: 'dataByCitiesAndCountries',
      getDataByViewersPortrait: 'dataByViewersPortrait',
      getDataByDevices: 'dataByDevicesCampaigns',
      getDataByDemographicInfo: 'dataByDemographicInfoCampaigns',
      getDataByHours: 'dataByHoursCampaigns',
      getDataByWeek: 'dataByWeekCampaigns',
      getDataByMonths: 'dataByMonthsCampaigns',
      getDataByYears: 'dataByYearsCampaigns',
      getDataByCurrentWidgets: 'dataByCurrentWidgets'
    },
    siteSpecificAnalytics: {
      getGeneralInfoFiltered: 'generalInfoSitesFiltered',
      getDataByVisitors: 'dataByVisitorsWithWeather',
      getDataByTrafficUsage: 'dataByTrafficUsage',
      getDataByNumberOfVisits: 'dataByNumberOfVisits',
      getDataByOtherVisitedSites: 'dataByOtherVisitedSites',
      getDataByVisitorsAgeAndGender: 'dataByVisitorsAgeAndGender',
      getDataByNewAndReturningVisitors: 'dataByNewAndReturningVisitors',
      getDataByNotReturningVisitors: 'dataByNotReturningVisitors',
      getDataByCampaignsViews: 'dataByCampaignsViews',
      getDataByCampaigns: 'dataByCampaigns',
      getDataByCitiesAndCountriesSiteLocation:
        'dataByCitiesAndCountriesSitesSiteLocation',
      getDataByCitiesAndCountriesSocialNetworks:
        'dataByCitiesAndCountriesSitesSocialNetworks',
      getDataByCitiesAndCountries: 'dataByCitiesAndCountriesSites',
      getDataByDevices: 'dataByDevicesSites',
      getDataByDemographicInfo: 'dataByDemographicInfoSites',
      getDataByHours: 'dataByHoursSites',
      getDataByWeek: 'dataByWeekSites',
      getDataByMonths: 'dataByMonthsSites',
      getDataByYears: 'dataByYearsSites'
    },
    campaignSpecificViewers: {},
    siteSpecificVisitors: {},
    monitoring: {}
  },

  mapBlocksStateNames: {
    dataByDaysAndSitesAggregated: 'dataByDaysAndSites',
    dataByDaysAndSitesNotAggregated: 'dataByDaysAndSites',
    dataByCitiesAndCountriesSiteLocation: 'dataByCitiesAndCountries',
    dataByCitiesAndCountriesSocialNetworks: 'dataByCitiesAndCountries',
    dataByCitiesAndCountriesSitesSiteLocation: 'dataByCitiesAndCountriesSites',
    dataByCitiesAndCountriesSitesSocialNetworks: 'dataByCitiesAndCountriesSites'
  },

  exportAlwaysDisabledBlocksAnalytics: {
    xls: ['all'],
    pdf: []
  },

  exportAlwaysDisabledBlocksVisitors: {
    xls: ['all']
  },

  hardlinkVisitorsOptions: {
    deviceTypes: ['Smartphone', 'Tablet', 'Console', 'Other'],
    OS: [
      'Windows',
      'Linux',
      'Android',
      'iOS',
      'Mac OS X',
      'Windows Phone',
      'Other'
    ],
    tops: [10, 50, 100],
    genders: ['GENDER_MALE', 'GENDER_FEMALE', 'Unknown'],
    ages: [
      'AGE_0_11',
      'AGE_12_17',
      'AGE_18_24',
      'AGE_25_34',
      'AGE_35_44',
      'AGE_45_54',
      'AGE_55_65',
      'AGE_66_PLUS',
      'Unknown'
    ]
  },

  excelOnlyBlockNames: ['getMacAddress', 'getVisitedSites', 'getClickedSites']
});
