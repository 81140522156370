/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('clientLogsCtrl', function($scope, logsFactory, breadcrumbs) {
    breadcrumbs.set({
      path: [{ name: 'admin' }, { name: 'clientLogs' }]
    });

    $scope.state = {
      macAddress: '',
      logEntries: [],
      noContent: false
    };

    $scope.submit = function(ev) {
      if (!ev || ev.charCode === 13) {
        $scope.setSubmitted(true);
        if ($scope.sectionForm.$invalid) return true;
        $scope.setBusy(true);
        logsFactory
          .clientLogsGetList($scope.state.macAddress)
          .then(function(res) {
            $scope.state.logEntries = res.data.logs || [];
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      }
      return undefined;
    };
  });
