/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const phoneValidators = {
  characters(value) {
    return /^\+?[\d()]*$/.test(value);
  },
  parentheses(value) {
    let counter = 0;
    for (let i = 0; i < value.length; i++) {
      switch (value[i]) {
        case '(':
          counter++;
          break;
        case ')':
          counter--;
          if (value[i - 1] === '(') {
            return false;
          } // if empty parentheses
          break;
        default:
          break;
      }
      if (counter > 1 || counter < 0) {
        return false;
      }
    }
    return counter === 0;
  }
};

angular.module('wcm.helpers').directive('wcmPhone', function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, el, attr, ngModel) {
      // eslint-disable-next-line no-param-reassign
      ngModel.$validators.wcmPhone = function(modelValue, viewValue) {
        const value = modelValue || viewValue;
        if (!value) return true;
        return Object.keys(phoneValidators).every(function(validatorName) {
          return phoneValidators[validatorName](value);
        });
      };
    }
  };
});
