/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.helpers').directive('widthRatio', function($window) {
  return function(scope, element, attrs) {
    const resetWidth = function(ratio) {
      element.width(element.height() * ratio);
      element.closest('.d-table').width(element.height() * ratio);
    };

    attrs.$observe('widthRatio', function(value) {
      resetWidth(parseFloat(value));
    });

    window.$($window).on('resize.widthRatio', function() {
      resetWidth(parseFloat(attrs.widthRatio));
    });

    scope.$on('$destroy', function() {
      window.$($window).off('.widthRatio');
    });
  };
});
