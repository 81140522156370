/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.login')
  .controller('changePasswordCtrl', function(
    $scope,
    $state,
    auth,
    APP_CONFIG,
    breadcrumbs,
    helpers,
    i18nFactory,
    navigationPanel
  ) {
    init();

    $scope.focused = {};

    $scope.submit = function(credentials) {
      auth
        .changePassword(credentials.oldPassword, credentials.newPassword)
        .then(function() {
          $state.go(APP_CONFIG.defaultState);
        })
        .catch(() => {
          $scope.changePassForm.oldPassword.$setValidity(
            'wrongPassword',
            false
          );
        });
    };

    $scope.cancel = function() {
      if ($scope.changePassForm.$dirty) {
        cancelConfirmModal().then(() => {
          $scope.changePassForm.$setPristine();
          $state.go(APP_CONFIG.defaultState);
        });
      } else {
        $state.go(APP_CONFIG.defaultState);
      }
    };

    $scope.save = function save() {
      $scope.submitted = true;
      $scope.changePassForm.oldPassword.$setValidity('wrongPassword', true);
      if ($scope.changePassForm.$valid) {
        $scope.submit($scope.credentials);
      }
    };

    $scope.$on('$stateChangeStart', function(event, toState, toParams) {
      if ($scope.changePassForm.$dirty) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.changePassForm.$setPristine();
          $state.go(toState, toParams);
        });
      }
    });

    function init() {
      breadcrumbs.set({
        path: [{ name: 'accountSettings' }, { name: 'changePassword' }]
      });
      navigationPanel.setTabs([
        { name: 'changePassword.changePassword', isActive: true }
      ]);
    }

    function cancelConfirmModal() {
      const title = i18nFactory.translate(
        'login.changePasswordCancelModalTitle'
      );
      const message = i18nFactory.translate(
        'login.changePasswordCancelModalText'
      );
      return helpers.confirmationModal(title, message).result;
    }

    $scope.actionButtons = [
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => true,
        clickHandler: () => {
          $scope.save();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ];
  });
