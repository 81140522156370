/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
require('sections/widgets/migrations/widgetMigration_1');

const contentTypeImages = require.context(
  'assets/images/widgets',
  false,
  /\.png$/
);

angular
  .module('wcm.widgets')
  .factory('widgetsFactory', function(
    widgetsConverter,
    requestFactory,
    localizationFactory,
    $http,
    $rootScope,
    $q,
    helpers,
    imageCompressor,
    WIDGETS_CONFIG
  ) {
    let previewData = null;
    const defaultWidgetStyles = WIDGETS_CONFIG.defaultWidgetStyles;
    const widgetTemplates = WIDGETS_CONFIG.widgetTemplates;
    let defaultLocale;

    /**
     * Fetches list of widgets from server
     *
     * @param filters
     * @param page
     * @param ipp
     * @param sortOrder
     * @returns {*}
     */
    const getList = function(filters, page, ipp, sortOrder) {
      const requestData = angular.extend(
        {},
        filters,
        helpers.getPaginationFilters(page, ipp, sortOrder)
      );
      return requestFactory
        .post(
          WIDGETS_CONFIG.widgetsServlet,
          WIDGETS_CONFIG.getList,
          requestData
        )
        .then(function(res) {
          return res.data && res.data.widgets ? res.data.widgets : [];
        })
        .then(function(widgets) {
          return widgets.map(getWidgetWithContentImage);
        })
        .then(function(widgets) {
          return widgets.map(function normalizeDemoLink(widget) {
            if (widget.contentType !== 'custom') {
              return widget;
            }
            return {
              ...widget,
              // `d=t` is a legacy marker for demo mode
              widgetPreviewLink: `/${widget.widgetPreviewLink}?d=t`
            };
          });
        })
        .then(function(widgets) {
          return widgets.map(function(widget) {
            if (!widget.approved) return widget;
            // eslint-disable-next-line no-param-reassign
            widget.approved = window._.extend({}, widget.approved, {
              contentType: widget.contentType
            });
            return widget;
          });
        })
        .then(function(widgets) {
          $rootScope.$broadcast('widgets:list-updated', widgets);
          return widgets;
        });
    };

    /**
     * Moves widget with given id to the archive
     *
     * @param id
     */
    const moveToArchive = function(id) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.moveToArchive,
        { id: id * 1 }
      );
    };

    /**
     * Restores widget with given id from the archive
     *
     * @param id
     */
    const recoverFromArchive = function(id) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.recoverFromArchive,
        { id: id * 1 }
      );
    };

    /**
     * Removes widget with given id
     *
     * @param id
     */
    const remove = function(id) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.remove,
        { id: id * 1 }
      );
    };

    /**
     * Approves widget with given id
     *
     * @param id
     */
    const approve = function(id) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.approve,
        {
          id: id * 1,
          action: 'approve'
        }
      );
    };

    /**
     * Disapproves widget with given id
     *
     * @param id
     */
    const disapprove = function(id) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.disapprove,
        {
          id: id * 1,
          action: 'disapprove'
        }
      );
    };

    /**
     * Fetches list of widgets for campaign with provided id
     *
     * @param {number} id - Campaign id
     */
    const getWidgetsForCampaign = function(id) {
      return requestFactory
        .post(
          WIDGETS_CONFIG.widgetsServlet,
          WIDGETS_CONFIG.getWidgetsForCampaign,
          { id: id * 1 },
          {},
          { skipErrors: [404, -1] }
        )
        .then(function(res) {
          return res.data && res.data.widgets ? res.data.widgets : [];
        })
        .then(function(widgets) {
          return widgets.map(getWidgetWithContentImage);
        });
    };

    /**
     * Gets extra data for widget with given id
     *
     * @param id
     * @returns {*}
     */
    function getWidget(id) {
      return requestFactory
        .post(WIDGETS_CONFIG.widgetsServlet, WIDGETS_CONFIG.widgetGetData, {
          id: +id
        })
        .then(function(res) {
          return res.data && res.data.widget ? res.data.widget : {};
        });
    }

    /**
     * Gets list of available widgets for campaign
     *
     * @param filters
     * @param page
     * @param ipp
     * @returns {*}
     */
    const getListForCampaign = function(filters, page, ipp) {
      const requestData = angular.extend(
        {},
        filters,
        helpers.getPaginationFilters(page, ipp, '')
      );
      return requestFactory
        .post(
          WIDGETS_CONFIG.widgetsServlet,
          WIDGETS_CONFIG.getListForCampaign,
          requestData,
          {},
          { skipErrors: -1 }
        )
        .then(function(res) {
          return res.data && res.data.widgets ? res.data.widgets : [];
        })
        .then(function(widgets) {
          return widgets.map(getWidgetWithContentImage);
        });
    };

    /**
     * Applies filters for widgets list for campaign
     *
     * @param searchString
     * @param searchStringMode
     * @param chainId
     * @param campaignId
     * @param campaignType
     * @param firstWidgetType
     * @param widgetOrder
     * @param linkedWidgetsIds
     * @param updateList
     */
    const applyFiltersForCampaign = function(
      searchString,
      searchStringMode,
      chainId,
      campaignId,
      campaignType,
      firstWidgetType,
      widgetOrder,
      linkedWidgetsIds,
      updateList
    ) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.applyFiltersForCampaign,
        {
          filterBySearchString: searchString,
          filterByType: campaignType,
          filterByFirstWidgetType: firstWidgetType,
          chainId,
          campaignId: +campaignId,
          widgetOrder,
          linkedWidgetsIds,
          updateList,
          searchStringMode
        }
      );
    };

    /**
     * Uploads widget file to server
     *
     * @param type
     * @param file
     * @returns {*}
     */
    const uploadFile = function(type, file) {
      const data = new FormData();
      let preprocessDfd = $q.resolve({
        file,
        mime: file.type
      });

      if (~['banner', 'slide', 'background', 'pollImage'].indexOf(type)) {
        preprocessDfd = helpers
          .readFileAsDataURL(file)
          .then(function(data) {
            const base64Src = data.target.result;
            const mimeType = base64MimeType(base64Src);
            if (type === 'background') {
              return getScaledBackgroundImage(base64Src).then(function(
                newImgSrc
              ) {
                return {
                  src: newImgSrc,
                  mime: 'image/jpeg'
                };
              });
            }
            return $q.resolve({
              src: base64Src,
              mime: mimeType
            });
          })
          .then(function(data) {
            // Here was an image compressor
            // leave this mapping to keep the contract
            return $q.resolve({
              file,
              mime: data.mime
            });
          });
      }

      return preprocessDfd
        .then(function(fileData) {
          const fileName =
            fileData.file.name || `${type}.${fileData.mime.split('/')[1]}`;

          data.append(fileName, fileData.file);
          return $http.post(`/cms/${WIDGETS_CONFIG.uploadServlet}`, data, {
            transformRequest: angular.identity,
            headers: {
              'Content-Type': undefined,
              'File-Type': type
            }
          });
        })
        .then(function(res) {
          return res.data ? res.data : {};
        });
    };

    /**
     * Returns previously saved widget data for preview
     *
     * @returns {*|{}}
     */
    const getPreviewData = function() {
      return previewData || {};
    };

    /**
     * Saves preview data for widget
     *
     * @param {object} previewParams - Preview params for temporarily
     * saved widget (returned by server)
     * @param widget
     */
    const setPreviewData = function(previewParams, widget) {
      if (previewParams != null) {
        const state = widget.meta.id !== 0 ? 'edit' : 'create';
        previewData = {
          widgetPreviewLink: previewParams.widgetPreviewLink,
          widgetHash: previewParams.widgetHash,
          type: widgetsConverter.getLegacyWidgetType(widget.widgetType),
          state,
          id: widget.meta.id,
          widget
        };
      } else {
        previewData = null;
      }
    };

    /**
     * Creates structure appropriate for server for widget saving process
     *
     * @param widget
     * @returns {{contentImage: string, contentType: *, id: (*|number), name: *, timeout: number,
     * widgetData: {client: *, meta: *, images: {img_00: *}}}}
     */
    function getSaveParamsFromWidget(widget) {
      const files = widget.meta.files;
      const backgroundImg =
        widget.backgroundType === 'image' ? files.background.url : '';
      const params = {
        contentImage: 'img_01', // hardcode for @o.dmytrov
        contentType: widgetsConverter.getLegacyWidgetType(widget.widgetType),
        id: widget.meta.id || 0,
        name: widget.meta.name,
        timeout: widget.progressbar.timeout,
        widgetData: {
          client: getClientMarkup(widget),
          meta: widget.meta,
          images: {
            img_00: backgroundImg
          }
        }
      };

      const widgetData = params.widgetData;

      switch (widget.widgetType) {
        case 'static':
          widgetData.images.img_01 = files.banner.url;
          break;
        case 'slider':
          files.slides.forEach(function(slide, idx) {
            widgetData.images[`img_0${idx + 1}`] = slide.url;
          });
          break;
        case 'video':
          widgetData.files = [files.video];
          break;
        case 'poll':
          widgetData.images.img_01 =
            files.pollImage && files.pollImage.url ? files.pollImage.url : '';
          widgetData.poll = getPollWidgetDataForSave(widget, defaultLocale.id);
          break;
        default:
          throw new Error(`Unsupported widget type ${widget.widgetType}`);
      }

      return params;
    }

    /**
     * Returns additional data for poll widget saving process
     *
     * @param widget
     * @param {string} localeId - Locale id to get info from (usually default locale)
     * @returns {{question: *, answers: *}}
     */
    function getPollWidgetDataForSave(widget, localeId) {
      const question = widget.poll.question.i18n[localeId]
        ? stripHtml(widget.poll.question.i18n[localeId]).replace(/\n$/, '')
        : null;
      const answers = widget.poll.answers.options.reduce(function(acc, answer) {
        let textSource;
        switch (answer.type) {
          case 'checkbox':
            textSource = answer.label.i18n;
            break;
          default:
            textSource = answer.legend.i18n;
        }
        // eslint-disable-next-line no-param-reassign
        acc[answer.id] = textSource[localeId];
        return acc;
      }, {});

      return { question, answers };

      function stripHtml(html) {
        const tmpEl = document.createElement('div');
        tmpEl.innerHTML = html;
        return tmpEl.textContent || tmpEl.innerText || '';
      }
    }

    /**
     * Performs saving temp data for widget preview on server
     *
     * @param widget
     * @returns {*}
     */
    const savePreview = function(widget) {
      const reqData = getSaveParamsFromWidget(widget);

      return requestFactory
        .post(WIDGETS_CONFIG.widgetsServlet, WIDGETS_CONFIG.widgetSavePreview, {
          widget: reqData
        })
        .then(function(res) {
          return res.data ? res.data : {};
        });
    };

    /**
     * Saves widget permanently on server
     *
     * @param previewData
     */
    const save = function(previewData) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.widgetSave,
        {
          id: +previewData.id,
          widgetHash: +previewData.widgetHash,
          widget: getSaveParamsFromWidget(previewData.widget)
        }
      );
    };

    /**
     * Saves custom widget on server
     *
     * @param widget
     */
    const saveCustomWidget = function(widget) {
      const params = {
        id: widget.meta.id,
        name: widget.meta.name,
        widgetData: {
          client: {}, // required for getEditData to be present
          meta: widget.meta,
          files: [widget.meta.files.zip]
        }
      };
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.widgetSaveCustom,
        { widget: params }
      );
    };

    /**
     * Fetches edit data for widget with given id
     *
     * @param id
     * @returns {*}
     */
    function getEditData(id) {
      return requestFactory
        .post(WIDGETS_CONFIG.widgetsServlet, WIDGETS_CONFIG.widgetGetEditData, {
          id: +id
        })
        .then(function(res) {
          return res.data && res.data.widget ? res.data.widget : {};
        })
        .then(function(widget) {
          return getEditorMarkup(widget);
        });
    }

    // / Validate files ------------>
    // /
    const validationConfig = {
      banner: {
        fileType: ['jpg', 'jpeg', 'png', 'gif'],
        fileSize: 5242880, // 5MB
        flexDimension: '100-4000x100-4000'
      },
      slide: {
        fileType: ['jpg', 'jpeg', 'png'],
        fileSize: 5242880,
        flexDimension: '100-4000x100-4000'
      },
      background: {
        fileType: ['jpg', 'jpeg', 'png'],
        fileSize: 5242880,
        flexDimension: '100-4000x100-4000'
      },
      video: {
        fileType: ['mp4'],
        fileSize: 256000000
      },
      zip: {
        fileType: ['zip', 'x-zip-compressed', undefined],
        fileSize: 256000000
      },
      pollImage: {
        fileType: ['jpg', 'jpeg', 'png', 'gif'],
        fileSize: 5242880,
        flexDimension: '100-4000x100-4000'
      }
    };

    const validationMethods = {
      fileType(contentType, file) {
        const dfd = $q.defer();
        const supportedTypes = validationConfig[contentType].fileType;
        const currentFileType = file.type.split('/')[1];
        if (
          supportedTypes === '*' ||
          supportedTypes.indexOf(currentFileType) > -1
        ) {
          dfd.resolve();
        } else {
          dfd.reject({
            validationType: 'fileType',
            expectedResult: supportedTypes,
            actualResult: currentFileType
          });
        }
        return dfd.promise;
      },

      fileSize(contentType, file) {
        const dfd = $q.defer();
        const supportedSize = validationConfig[contentType].fileSize;
        if (file.size > validationConfig[contentType].fileSize) {
          dfd.reject({
            validationType: 'fileSize',
            expectedResult: supportedSize,
            actualResult: file.size
          });
        } else {
          dfd.resolve();
        }
        return dfd.promise;
      },

      dimension(contentType, file) {
        const dfd = $q.defer();
        const supportedDimension = validationConfig[
          contentType
        ].dimension.split('x');
        const supportedWidth = +supportedDimension[0];
        const supportedHeight = +supportedDimension[1];

        const img = document.createElement('img');
        helpers.readFileAsDataURL(file).then(function(data) {
          img.src = data.target.result;
        });
        img.onload = function() {
          if (
            this.width !== supportedWidth &&
            this.height !== supportedHeight
          ) {
            dfd.reject({
              validationType: 'dimension:width,dimension:height',
              expectedResult: [supportedWidth, supportedHeight],
              actualResult: [this.width, this.height]
            });
            return false;
          }
          if (this.width !== supportedWidth) {
            dfd.reject({
              validationType: 'dimension:width',
              expectedResult: supportedWidth,
              actualResult: this.width
            });
            return false;
          }
          if (this.height !== supportedHeight) {
            dfd.reject({
              validationType: 'dimension:height',
              expectedResult: supportedHeight,
              actualResult: this.height
            });
            return false;
          }
          return dfd.resolve();
        };
        return dfd.promise;
      },
      flexDimension(contentType, file) {
        const dfd = $q.defer();
        const supportedDimensions = validationConfig[
          contentType
        ].flexDimension.split('x');
        const widthRange = supportedDimensions[0].split('-').map(Number);
        const heightRange = supportedDimensions[1].split('-').map(Number);

        const img = document.createElement('img');
        helpers.readFileAsDataURL(file).then(function(data) {
          img.src = data.target.result;
        });
        img.onload = function() {
          const width = this.width;
          const height = this.height;

          if (
            width < widthRange[0] ||
            width > widthRange[1] ||
            height < heightRange[0] ||
            height > heightRange[1]
          ) {
            dfd.reject({
              validationType: 'flexDimension',
              expectedResult: [
                widthRange[0],
                widthRange[1],
                heightRange[0],
                heightRange[1]
              ],
              actualResult: [width, height]
            });
            return false;
          }
          return dfd.resolve();
        };
        return dfd.promise;
      }
    };

    const validators = {
      /**
       * Checks whether text in HTML is not longer than maxLength
       *
       * @param htmlString
       * @param maxLength
       * @returns {boolean}
       */
      rawTextLength(htmlString, maxLength) {
        return helpers.getRawTextLength(htmlString) <= maxLength;
      }
    };

    const validateFile = function(type, file) {
      const validators = Object.keys(validationConfig[type]);

      return validators.reduce(function(prevVal, nextF) {
        return prevVal.then(function() {
          return validationMethods[nextF](type, file);
        });
      }, $q.when(true));
    };

    // / Cancel edit mode ------------>
    // /
    const cancelEditMode = function(fileTypes) {
      return requestFactory.post(
        WIDGETS_CONFIG.widgetsServlet,
        WIDGETS_CONFIG.cancelEditMode,
        { fileTypes }
      );
    };

    /**
     * Get client suitable widget markup from editor
     *
     * @param widget
     * @returns {*}
     */
    function getClientMarkup(widget) {
      const meta = widget.meta;
      let res = window._.omit(widget, 'meta');

      if (res.widgetType === 'poll') {
        res.poll.fallbackLocale = res.poll.fallbackLocale || defaultLocale.id;
        const answers = res.poll.answers;
        answers.options = answers.options.map(function(option) {
          // eslint-disable-next-line no-param-reassign
          option.style =
            option.type === 'checkbox'
              ? meta.checkboxItemsStyle
              : meta.inputItemsStyle;
          return option;
        });
      }

      if (res.buttons) {
        res.buttons = widget.buttons.map(function(btn) {
          return window._.omit(btn, 'state');
        });
      }

      res = convertWidgetColorsToRgba(res);
      return res;

      /**
       * Service function that transforms editor-like color storing format to client-like
       *
       * @param obj
       * @returns {*}
       */
      function convertWidgetColorsToRgba(obj) {
        if (!angular.isObject(obj)) {
          return obj;
        }
        if (angular.isDefined(obj.hex) && angular.isDefined(obj.a)) {
          return convertHexAlphaColorToRgba(obj.hex, obj.a);
        }
        return Object.keys(obj).reduce(function(acc, key) {
          const value = obj[key];
          if (angular.isObject(value) && !angular.isArray(value)) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = convertWidgetColorsToRgba(value);
          } else if (angular.isArray(value)) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = obj[key].map(convertWidgetColorsToRgba);
          } else {
            // eslint-disable-next-line no-param-reassign
            acc[key] = obj[key];
          }
          return acc;
        }, {});
      }
    }

    /**
     * Gets widget markup suitable for editor from client version
     *
     * @param rawWidget
     * @returns {*}
     */
    function getEditorMarkup(rawWidget) {
      const normalizedWidget = widgetsConverter.migrate(rawWidget);
      const widgetData = normalizedWidget.widgetData;
      const markup = widgetData.client || {};
      const rgbaRegexp = /rgba\s?\(.*\)/;
      const widget = convertWidgetColorsToHexAlpha(markup);

      if (widget.buttons) {
        widget.buttons.forEach(function(btn) {
          // eslint-disable-next-line no-param-reassign
          btn.state = 'normal';
        });
      }
      // TODO: [WCM-9894] Extend widget markup from base settings
      if (widget.widgetType === 'video') {
        widget.media = widget.media || { muted: false };
      }

      widget.meta = reconstructMetaInfo(normalizedWidget);
      return widget;

      function reconstructMetaInfo(widget) {
        const widgetData = widget.widgetData;
        const markup = widgetData.client || {};
        const widgetType = markup.widgetType;
        const images = widgetData.images;
        const meta = widgetData.meta || {};
        // eslint-disable-next-line no-multi-assign
        const files = (meta.files = {});

        meta.id = widget.id;
        meta.name = widget.name;

        // reconstruct background
        if (images && images.img_00) {
          files.background = {
            url: images.img_00
          };
        }

        // reconstruct banner for static widget
        if (widgetType === 'static') {
          files.banner = {
            url: images.img_01
          };
        }

        // reconstruct slides
        if (widgetType === 'slider') {
          const maxSlidesCount = 4;
          // eslint-disable-next-line no-multi-assign
          const slides = (files.slides = []);
          // eslint-disable-next-line no-multi-assign
          const smallPlaceForSlides = (meta.smallPlaceForSlides = []);

          Object.keys(images)
            .sort()
            .filter(function(key) {
              return key !== 'img_00';
            })
            .forEach(function(key) {
              slides.push({
                url: images[key]
              });
            });

          for (let i = 0; i < maxSlidesCount - slides.length; i++) {
            smallPlaceForSlides.push({});
          }
        }

        if (widgetType === 'video') {
          files.video = widgetData.files[0];
        }

        if (widget.contentType === 'custom') {
          files.zip = widgetData.files[0];
        }

        if (widgetType === 'poll') {
          meta.isPollEditable = normalizedWidget.isPollEditable;
          files.pollImage = {
            url: widgetData.images.img_01
          };
        }

        return meta;
      }

      /**
       * Service function for converting client-like colors to editor-like
       *
       * @param obj
       * @returns {*}
       */
      function convertWidgetColorsToHexAlpha(obj) {
        return Object.keys(obj).reduce(function(acc, key) {
          const value = obj[key];
          if (angular.isObject(value) && !angular.isArray(value)) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = convertWidgetColorsToHexAlpha(value);
          } else if (angular.isArray(value) && value.every(angular.isObject)) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = obj[key].map(convertWidgetColorsToHexAlpha);
          } else if (angular.isString(value) && rgbaRegexp.test(value)) {
            // eslint-disable-next-line no-param-reassign
            acc[key] = rgbaToHex(value);
          } else {
            // eslint-disable-next-line no-param-reassign
            acc[key] = value;
          }
          return acc;
        }, {});
      }
    }

    /**
     * Fetches available client localizations from server and loads localization files
     *
     * @returns {*}
     */
    function getClientLocalizations() {
      return localizationFactory
        .getClientLocalizations()
        .then(function(data) {
          return data.filter(function(locale) {
            return locale.isAvailable;
          });
        })
        .then(function(data) {
          const promises = data.map(function(locale) {
            const localeId = locale.id;
            return $http
              .get(
                `${
                  WIDGETS_CONFIG.clientI18nPath
                }/${localeId.toLowerCase()}/${localeId}.json`
              )
              .then(function(res) {
                // eslint-disable-next-line no-param-reassign
                locale.elements = res.data.strings;
              });
          });
          return $q.all(promises).then(function() {
            return data;
          });
        });
    }

    /**
     * Gets options for widget creation/editing
     *
     * @param widgetType
     * @returns {*}
     */
    function getOptions(widgetType) {
      const dfd = $q.defer();
      const buttonTypes = {
        static_dynamic: ['next', 'url', 'redirect'],
        slider: ['next', 'url', 'redirect'],
        video: ['next', 'url', 'redirect'],
        poll: ['next', 'url', 'submit', 'redirect'],
        custom: []
      };

      const borderRadiuses = (function() {
        const radiuses = [];
        for (let i = 0; i <= 30; i++) {
          radiuses.push(`${i}px`);
        }
        return radiuses;
      })();

      const fontSizes = (function() {
        const sizes = [];
        for (let i = 8; i <= 24; i += 2) {
          sizes.push(`${i}px`);
        }
        return sizes;
      })();

      const fontFamilies = [
        'Arial',
        'Open Sans',
        'Tahoma',
        'Georgia',
        'Times New Roman'
      ];

      const options = {
        buttonTypes: buttonTypes[widgetType],
        borderRadiuses,
        fontSizes,
        fontFamilies
      };

      const lDfd = getClientLocalizations().then(function(locales) {
        options.locales = locales;
        options.defaultLocale = window._.find(locales, function(locale) {
          return locale.isDefault;
        });

        // this is kinda dirty, but we need to store default locale in factory
        // in order to process widget poll
        defaultLocale = options.defaultLocale;
      });

      $q.all([lDfd]).then(function() {
        dfd.resolve(options);
      });

      return dfd.promise;
    }

    /**
     * Returns widget template for given widget type
     *
     * @param widgetType
     * @returns {void|*}
     */
    function getWidgetTemplate(widgetType) {
      return window.$.extend(
        true,
        {},
        widgetTemplates.base,
        widgetTemplates[widgetType]
      );
    }

    /**
     * Creates widget button, returns its object
     *
     * @param type
     * @returns {{type: *, i18n: {}, style: {normal: (void|*), hover: (void|*)}, state: string}}
     */
    function createWidgetButton(type) {
      const btn = {
        type,
        i18n: {},
        style: {
          normal: window.$.extend(true, {}, defaultWidgetStyles.btn.normal),
          hover: window.$.extend(true, {}, defaultWidgetStyles.btn.hover)
        },
        state: 'normal'
      };
      return btn;
    }

    /**
     * Creates poll answer and returns its object
     *
     * @param answers
     * @returns {{id: *, type: string, label: {i18n: {}},
     * style: {container: {}, legend: {}, input: {}}, placeholder: {i18n: {}},
     * legend: {i18n: {}}, utm: string}}
     */
    function createPollAnswer(answers) {
      const maxId = getMaxAnswersId(answers);
      const answer = {
        id: maxId + 1,
        type: 'checkbox',
        label: {
          i18n: {}
        },
        style: {
          container: {},
          legend: {},
          input: {}
        },
        placeholder: {
          i18n: {}
        },
        legend: {
          i18n: {}
        },
        utm: ''
      };
      return answer;

      function getMaxAnswersId(answers) {
        // eslint-disable-next-line no-param-reassign
        answers = answers || [];
        const answersIds = answers.map(function(answer) {
          return answer.id;
        });
        answersIds.push(-1);
        return Math.max.apply(null, answersIds);
      }
    }

    const _uppercasePattern = /([A-Z])/g;

    /**
     * Hyphenates string, converting it from camelCase to kebab-case
     *
     * @param string
     * @returns {string}
     */
    function hyphenate(string) {
      return string.replace(_uppercasePattern, '-$1').toLowerCase();
    }

    /**
     * Converts hex value to rgb
     *
     * @param hex
     * @returns {*}
     */
    function hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      const _hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    }

    /**
     * Converts rgba value to hex and alpha
     * @param rgba
     * @returns {{hex: string, a: number}}
     */
    function rgbaToHex(rgba) {
      const parts = rgba.substring(rgba.indexOf('(') + 1).split(',');
      const r = parseInt(parts[0].trim(), 10);
      const g = parseInt(parts[1].trim(), 10);
      const b = parseInt(parts[2].trim(), 10);
      const a = parseFloat(
        parts[3].substring(0, parts[3].length - 1).trim()
      ).toFixed(2);
      return {
        hex: `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`,
        a: a * 100
      };

      function componentToHex(c) {
        const hex = c.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      }
    }

    /**
     * Converts hex and alpha values to rgba
     *
     * @param hex
     * @param alpha
     * @returns {string}
     */
    function convertHexAlphaColorToRgba(hex, alpha) {
      const rgb = hexToRgb(hex);
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha / 100})`;
    }

    /**
     * Converts react-like style objects to angular-like
     * @param reactStylingObject
     * @returns {*}
     */
    function convertReactStylesToAngular(reactStylingObject) {
      return Object.keys(reactStylingObject).reduce(function(acc, key) {
        let value = reactStylingObject[key];
        if (
          angular.isObject(value) &&
          angular.isDefined(value.hex) &&
          angular.isDefined(value.a)
        ) {
          value = convertHexAlphaColorToRgba(value.hex, value.a);
        }
        // eslint-disable-next-line no-param-reassign
        acc[hyphenate(key)] = value;
        return acc;
      }, {});
    }

    /**
     * Returns css compatible string for relative font scaling
     * @param ratio
     * @returns {string}
     */
    function getFontScaleStyle(ratio) {
      return `${ratio}em`;
    }

    function validateTransparencyRange(value) {
      const val = value * 1;
      return !!(val <= 100 && val >= 0);
    }

    function getScaledBackgroundImage(src) {
      const dfd = $q.defer();

      const dstWidth = 2880;
      const dstHeight = 1800;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = src;

      canvas.width = dstWidth;
      canvas.height = dstHeight;

      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      img.onload = function() {
        const ratio = img.width / img.height;
        let newWidth = canvas.width;
        let newHeight = newWidth / ratio;

        if (newHeight > canvas.height) {
          newHeight = canvas.height;
          newWidth = newHeight * ratio;
        }

        const xOffset =
          newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
        const yOffset =
          newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;

        ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
        dfd.resolve(canvas.toDataURL('image/jpeg', 1));
      };

      return dfd.promise;
    }

    function base64MimeType(encoded) {
      let result = null;

      if (typeof encoded !== 'string') {
        return result;
      }

      const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

      if (mime && mime.length) {
        result = mime[1];
      }

      return result;
    }

    function getWidgetContentImageStub(type) {
      return contentTypeImages(`./wdg-big-bg-${type}.png`);
    }

    function getWidgetWithContentImage(widget) {
      const contentType = widget.contentType;
      if (~['poll', 'custom', 'video'].indexOf(contentType)) {
        return {
          ...widget,
          contentImage: getWidgetContentImageStub(contentType)
        };
      }
      return widget;
    }

    return {
      getList,
      moveToArchive,
      recoverFromArchive,
      remove,
      approve,
      disapprove,
      getWidget,
      getEditData,
      getWidgetsForCampaign,
      getListForCampaign,
      applyFiltersForCampaign,
      uploadFile,
      savePreview,
      validateFile,
      cancelEditMode,
      getPreviewData,
      setPreviewData,
      save,
      saveCustomWidget,
      getOptions,
      getWidgetTemplate,
      createWidgetButton,
      createPollAnswer,
      convertReactStylesToAngular,
      getFontScaleStyle,
      validateTransparencyRange,
      validators
    };
  });
