const { angular } = window;

angular
  .module('wcm.exportButton')
  .controller('exportButtonController', function(
    $scope,
    $modal,
    $filter,
    $timeout,
    exportFactory,
    helpers,
    EXPORT_CONFIG
  ) {
    const ctrl = this;
    const config = EXPORT_CONFIG;

    ctrl.currentExport = ''; // Save export type. Need to prevent export if user changed location
    ctrl.exportOptions = [];
    ctrl.isSmallModal = undefined;

    // eslint-disable-next-line no-multi-assign
    const handlers = (ctrl.handlers = {
      onExport() {
        ctrl.exportFlag = true;
        ctrl.exportOptions = ctrl.setExportOptions();
        ctrl.isSmallModal = ctrl.exportOptions.length <= 11;
        handlers.showExportModal().result.catch(function() {
          ctrl.exportFlag = false;
        });
      },
      showExportModal() {
        const modalScope = $scope.$new();
        modalScope.exportOptions = ctrl.exportOptions;
        modalScope.exportType = ctrl.exportType;
        modalScope.alwaysDisabledBlocks = ctrl.exportAlwaysDisabledBlocks;

        const modalWindowClass = `export_modal${
          ctrl.isSmallModal ? ' __small-export' : ''
        }`;

        return $modal.open({
          templateUrl: 'app/core/components/export/exportModal.html',
          windowClass: modalWindowClass,
          controller($scope, $modalInstance) {
            // Options for dropdown export list
            $scope.optionsFilesFormats = ctrl.permissionParams;
            $scope.fileFormat = { value: $scope.optionsFilesFormats[0] };
            $scope.exportTitle =
              ctrl.title || 'components.export.labels.exportAnalyticsTitle';

            // Check if some of blocks should be always disabled
            $scope.checkAlwaysDisabled = function(type) {
              $scope.exportOptions.forEach(function(item, i) {
                // Globaly always disabled blocks -> f.e. Timeline block if type == xls
                if (
                  ~exportFactory.alwaysDisabledBlocks[type].indexOf(item.key)
                ) {
                  $scope.exportOptions[i].value = false;
                  $scope.exportOptions[i].isAlwaysDisabled = true;
                } else {
                  $scope.exportOptions[i].isAlwaysDisabled = false;
                }

                // Always disabled blocks for custom reason ->
                // f.e. all blocks in Reports section if type == xls
                if (
                  $scope.alwaysDisabledBlocks &&
                  ($scope.alwaysDisabledBlocks[type][0] === 'all' ||
                    ~$scope.alwaysDisabledBlocks[type].indexOf(item.key))
                ) {
                  $scope.exportOptions[i].isAlwaysDisabled = true;
                }
              });
            };
            $scope.checkAlwaysDisabled($scope.fileFormat.value);

            // On print confirm
            $scope.ok = function() {
              // If at least one export option exists -> export
              if (isExportOptions($scope.exportOptions)) {
                ctrl.currentExport = angular.copy(ctrl.exportFlag);
                handlers.sendExport($scope.fileFormat.value);
                $modalInstance.close();
              } else {
                // Else -> show info popup dialog
                const i18n = $filter('i18n');
                const infoScope = $scope.$new();
                infoScope.msg = i18n('components.export.labels.exportError');
                infoScope.title = i18n('components.export.labels.exportTitle');
                $modal.open({
                  templateUrl: 'app/core/views/infoModalGlobal.html',
                  windowClass: 'wcm-msg',
                  scope: infoScope
                });
              }
            };
          },

          scope: modalScope
        });
      },

      // Slice analytics data and open Print dialog ------->
      exportPdf() {
        if (ctrl.currentExport !== ctrl.exportFlag) {
          ctrl.exportFlag = false;
          return false;
        }

        const elem = window.$('#init-analytics-container');
        const elements = [];
        elements.push(window.$('#printHeader').clone());
        elements.push(window.$('#timeFilters').clone());
        ctrl.exportOptions.forEach(function(item) {
          if (item.value) {
            elements.push(window.$(`#${item.key}`).clone());
          }
        });
        elements.forEach(function(item, i) {
          elements[i].find('input').each(function() {
            if (!window.$(this).prop('checked')) {
              window
                .$(this)
                .parent()
                .parent()
                .remove();
            } else {
              window
                .$(this)
                .parent()
                .addClass('__for-print');
            }
          });
        });

        const allLinks = window
          .$('head')
          .clone()
          .find('script')
          .remove()
          .end()
          .html();
        // var scripts = '';
        // window.$('body').find('script').each(function() { scripts += ' '+this.outerHTML ; });
        const popupWin = window.open(
          '',
          '_blank',
          `width=${elem.width()},height=700,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no`
        );
        $timeout(function() {
          ctrl.exportFlag = false;
          popupWin.window.focus();
          popupWin.document.write(
            `<!DOCTYPE html><html><head>${allLinks}<style type="text/css">` +
              'body { overflow-y: auto !important; }' +
              '.analytics-manage-block .ps-container { max-height: none !important; }' +
              '.analytics-manage-block .ps-scrollbar-y-rail { display: none; }' +
              '.analytics-manage-block .manage-no-items { display: none; }' +
              '.pie-show-more-handler .btn { display: none; }' +
              '.btn.settings-form_btn.__manage { display: none; }' +
              '.only-for-print { display: block !important; }' +
              '.only-not-for-print { display: none !important; }' +
              '.analytics-block_close-btn { display: none; }' +
              '.timeline_wrapper-t.__detailedView .timeline_viewport { overflow: hidden !important; padding-top: 20px !important; }' +
              '.ps-scrollbar-y-rail { display: none !important; }' +
              '</style>' +
              '<title>Print Analytics</title>' +
              '</head><body>' +
              `<div id="ready-for-print" class="page __analytics __ready-for-print">${elements
                .map(function(item) {
                  return window
                    .$('<div />')
                    .append(
                      window
                        .$('<div />')
                        .attr('id', `printed${item.attr('id')}`)
                        .addClass(item.attr('class'))
                        .css('width', '100%')
                        .html(item.html())
                    )
                    .html();
                })
                .join(' ')}</div>` +
              '<script type="text/javascript">function outerHeight(el){var height=el.offsetHeight;var style=getComputedStyle(el);height+=parseInt(style.marginTop)+parseInt(style.marginBottom);return height}var items=document.getElementsByClassName("timeline_li");var timeHeight=outerHeight(items[0])*items.length+40;var helpersNodes=document.getElementsByClassName("timeline_height-helper");var helpers=[].slice.call(helpersNodes);helpers.forEach(function(h){h.style.height=(timeHeight<350)?"350px":timeHeight+"px"});</script>' +
              '</body>' +
              '</html>'
          );
        }, 0);
        setTimeout(function() {
          if (helpers.checkBrowser() === 'Firefox') {
            popupWin.location.reload();
          }
          popupWin.print();
        }, 2000);
        return true;
      },

      // Export in excel ------->
      exportXls() {
        let filters = null;
        if (ctrl.currentExport !== ctrl.exportFlag) {
          ctrl.exportFlag = false;
          return false;
        }

        // Analytics and visitors export
        if (
          ~config.analyticsTypes.indexOf(ctrl.exportType) ||
          ~config.visitorsTypes.indexOf(ctrl.exportType)
        ) {
          filters = ctrl.exportSortOrder
            ? angular.extend({}, ctrl.exportFilters, {
                sortOrder: ctrl.exportSortOrder
              })
            : ctrl.exportFilters;
          return exportFactory
            .getExportExcel(
              filters,
              ctrl.exportOptions,
              ctrl.exportFlag,
              ctrl.exportItemIds,
              ctrl.exportBlocksState
            )
            .finally(function() {
              ctrl.exportFlag = false;
            });
        } else if (~config.reportingTypes.indexOf(ctrl.exportType)) {
          // Reporting export
          filters = ctrl.exportFilters;
          return exportFactory
            .getExportXls(filters, ctrl.exportOptions, ctrl.exportType)
            .finally(function() {
              ctrl.exportFlag = false;
            });
        }
        return true;
      },

      // Get export file ---------->
      sendExport(fileFormat) {
        const exportParams = [];
        let exportHandler = null;
        ctrl.exportOptions.forEach(function(item) {
          if (item.value) exportParams.push(item.key);
        });

        switch (fileFormat) {
          case config.filePdf:
            exportHandler = handlers.exportPdf;
            break;
          default:
            exportHandler = handlers.exportXls;
        }

        // If it need preconditions -> get preconditions firstly
        if (~ctrl.preconditionParams.indexOf(fileFormat)) {
          // eslint-disable-next-line no-undef
          сtrl.onExportPreconditions({
            callback: exportHandler,
            options: exportParams
          });
        } else {
          // If it not need preconditions -> send export request
          exportHandler();
        }
      }
    });

    // Check if at least one block checked
    function isExportOptions(options) {
      return !!~options
        .map(function(item) {
          if (item.options) return isExportOptions(item.options);
          return +item.value;
        })
        .indexOf(1);
    }
  });
