/**
 * The following features are still outstanding: animation as a
 * function, placement as a function, inside, support for more triggers than
 * just mouse enter/leave, html tooltips, and selector delegation.
 */

const { angular } = window;

angular
  .module('wcm.components')

  /**
   * The $tooltip service creates tooltip- and popover-like directives as well as
   * houses global options for them.
   */
  .provider('$tooltip', function() {
    // The default options tooltip and popover.
    const defaultOptions = {
      placement: 'top',
      animation: true,
      popupDelay: 0
    };

    // Default hide triggers for each show trigger
    const triggerMap = {
      mouseenter: 'mouseleave',
      click: 'click',
      focus: 'blur'
    };

    // The options specified to the provider globally.
    const globalOptions = {};

    /**
     * `options({})` allows global configuration of all tooltips in the
     * application.
     *
     *   var app = angular.module( 'App', ['ui.bootstrap.tooltip'], function( $tooltipProvider ) {
     *     // place tooltips left instead of top by default
     *     $tooltipProvider.options( { placement: 'left' } );
     *   });
     */
    this.options = function(value) {
      angular.extend(globalOptions, value);
    };

    /**
     * This allows you to extend the set of trigger mappings available. E.g.:
     *
     *   $tooltipProvider.setTriggers( 'openTrigger': 'closeTrigger' );
     */
    this.setTriggers = function setTriggers(triggers) {
      angular.extend(triggerMap, triggers);
    };

    /**
     * This is a helper function for translating camel-case to snake-case.
     */
    // eslint-disable-next-line camelcase
    function snake_case(name) {
      const regexp = /[A-Z]/g;
      const separator = '-';
      return name.replace(regexp, function(letter, pos) {
        return (pos ? separator : '') + letter.toLowerCase();
      });
    }

    /**
     * Returns the actual instance of the $tooltip service.
     * TODO support multiple triggers
     */
    this.$get = [
      '$window',
      '$compile',
      '$timeout',
      '$document',
      '$position',
      '$interpolate',
      function(
        $window,
        $compile,
        $timeout,
        $document,
        $position,
        $interpolate
      ) {
        return function $tooltip(type, prefix, defaultTriggerShow) {
          const options = angular.extend(
            {},
            defaultOptions,
            globalOptions
          );

          /**
           * Returns an object of show and hide triggers.
           *
           * If a trigger is supplied,
           * it is used to show the tooltip; otherwise, it will use the `trigger`
           * option passed to the `$tooltipProvider.options` method; else it will
           * default to the trigger supplied to this directive factory.
           *
           * The hide trigger is based on the show trigger. If the `trigger` option
           * was passed to the `$tooltipProvider.options` method, it will use the
           * mapped trigger from `triggerMap` or the passed trigger if the map is
           * undefined; otherwise, it uses the `triggerMap` value of the show
           * trigger; else it will just use the show trigger.
           */
          function getTriggers(trigger) {
            const show = trigger || options.trigger || defaultTriggerShow;
            const hide = triggerMap[show] || show;
            return {
              show,
              hide
            };
          }

          const directiveName = snake_case(type);

          const startSym = $interpolate.startSymbol();
          const endSym = $interpolate.endSymbol();
          const template =
            `<div ${directiveName}-popup ` +
            `title="${startSym}title${endSym}" ` +
            'content="content" ' +
            `placement="${startSym}placement${endSym}" ` +
            `class="${startSym}class${endSym}"` +
            'animation="animation" ' +
            '$close="$close()"' +
            'is-open="isOpen"' +
            '>' +
            '</div>';

          return {
            restrict: 'EA',
            scope: {
              content: `=?${prefix}Content`,
              showOn: `=?${prefix}ShowOn`
            },
            compile() {
              const tooltipLinker = $compile(template);

              return function link(scope, element, attrs) {
                let tooltip;
                let tooltipLinkedScope;
                let transitionTimeout;
                let popupTimeout;
                let appendToBody = angular.isDefined(
                  options.appendToBody
                )
                  ? options.appendToBody
                  : false;
                let triggers = getTriggers(undefined);
                const hasEnableExp = angular.isDefined(
                  attrs[`${prefix}Enable`]
                );
                let ttScope = scope;

                const positionTooltip = function() {
                  const ttPosition = $position.positionElements(
                    element,
                    tooltip,
                    ttScope.placement,
                    appendToBody
                  );
                  ttPosition.top += 'px';
                  ttPosition.left += 'px';

                  // Now set the calculated positioning.
                  tooltip.css(ttPosition);
                };

                // By default, the tooltip is not open.
                // TODO add ability to start tooltip opened
                ttScope.isOpen = false;
                ttScope.$close = hide;

                ttScope.$watch('showOn', function(val) {
                  if (val === false && ttScope.isOpen) {
                    hide();
                  }
                  if (val === true && !ttScope.isOpen) {
                    $timeout(function() {
                      prepareTooltip();
                      show()();
                    });
                  }
                });

                function toggleTooltipBind() {
                  if (!ttScope.isOpen) {
                    showTooltipBind();
                  } else {
                    hideTooltipBind();
                  }
                }

                // Show the tooltip with delay if specified, otherwise show it immediately
                function showTooltipBind() {
                  if (hasEnableExp && !scope.$eval(attrs[`${prefix}Enable`])) {
                    return;
                  }

                  prepareTooltip();

                  if (ttScope.popupDelay) {
                    // Do nothing if the tooltip was already scheduled to pop-up.
                    // This happens if show is triggered multiple times before any hide is triggered.
                    if (!popupTimeout) {
                      popupTimeout = $timeout(show, ttScope.popupDelay, false);
                      popupTimeout.then(function(reposition) {
                        reposition();
                      });
                    }
                  } else {
                    show()();
                  }
                }

                function hideTooltipBind() {
                  scope.$apply(function() {
                    hide();
                  });
                }

                // Show the tooltip popup element.
                function show() {
                  popupTimeout = null;

                  // If there is a pending remove transition, we must cancel it, lest the
                  // tooltip be mysteriously removed.
                  if (transitionTimeout) {
                    $timeout.cancel(transitionTimeout);
                    transitionTimeout = null;
                  }

                  // Don't show empty tooltips.
                  if (!ttScope.content) {
                    return angular.noop;
                  }

                  createTooltip();

                  // Set the initial positioning.
                  tooltip.css({ top: 0, left: 0, display: 'block' });
                  if (!ttScope.$root.$$phase) {
                    ttScope.$digest();
                  }

                  positionTooltip();

                  // And show the tooltip.
                  ttScope.isOpen = true;
                  ttScope.showOn = true;
                  if (!ttScope.$root.$$phase) {
                    ttScope.$digest();
                  }

                  // Return positioning function as promise callback for correct
                  // positioning after draw.
                  return positionTooltip;
                }

                // Hide the tooltip popup element.
                function hide() {
                  // First things first: we don't show it anymore.
                  ttScope.isOpen = false;
                  ttScope.showOn = false;

                  // if tooltip is going to be shown after delay, we must cancel this
                  $timeout.cancel(popupTimeout);
                  popupTimeout = null;

                  // And now we remove it from the DOM. However, if we have animation, we
                  // need to wait for it to expire beforehand.
                  // FIXME: this is a placeholder for a port of the transitions library.
                  if (ttScope.animation) {
                    if (!transitionTimeout) {
                      transitionTimeout = $timeout(removeTooltip, 0);
                    }
                  } else {
                    removeTooltip();
                  }
                }

                function createTooltip() {
                  // There can only be one tooltip element per directive shown at once.
                  if (tooltip) {
                    removeTooltip();
                  }
                  tooltipLinkedScope = ttScope.$new();
                  tooltip = tooltipLinker(tooltipLinkedScope, function(
                    tooltip
                  ) {
                    if (appendToBody) {
                      $document.find('body').append(tooltip);
                    } else {
                      element.after(tooltip);
                    }
                  });
                }

                function removeTooltip() {
                  transitionTimeout = null;
                  if (tooltip) {
                    tooltip.remove();
                    tooltip = null;
                  }
                  if (tooltipLinkedScope) {
                    tooltipLinkedScope.$destroy();
                    tooltipLinkedScope = null;
                  }
                }

                function prepareTooltip() {
                  prepPlacement();
                  prepPopupDelay();
                }

                /**
                 * Observe the relevant attributes.
                 */
                if (!attrs[`${type}Content`]) {
                  attrs.$observe(type, function(val) {
                    ttScope.content = val;

                    if (!val && ttScope.isOpen) {
                      hide();
                    }
                  });
                }

                attrs.$observe('disabled', function(val) {
                  if (val && ttScope.isOpen) {
                    hide();
                  }
                });

                attrs.$observe(`${prefix}Title`, function(val) {
                  ttScope.title = val;
                });

                attrs.$observe(`${prefix}Class`, function(val) {
                  ttScope.class = val;
                });

                function prepPlacement() {
                  const val = attrs[`${prefix}Placement`];
                  ttScope.placement = angular.isDefined(val)
                    ? val
                    : options.placement;
                }

                function prepPopupDelay() {
                  const val = attrs[`${prefix}PopupDelay`];
                  const delay = parseInt(val, 10);
                  ttScope.popupDelay = !Number.isNaN(delay)
                    ? delay
                    : options.popupDelay;
                }

                const unregisterTriggers = function() {
                  element.unbind(triggers.show, showTooltipBind);
                  element.unbind(triggers.hide, hideTooltipBind);
                };

                function prepTriggers() {
                  const val = attrs[`${prefix}Trigger`];
                  unregisterTriggers();

                  triggers = getTriggers(val);

                  if (triggers.show === triggers.hide) {
                    element.bind(triggers.show, toggleTooltipBind);
                  } else {
                    element.bind(triggers.show, showTooltipBind);
                    element.bind(triggers.hide, hideTooltipBind);
                  }
                }
                prepTriggers();

                const animation = scope.$eval(attrs[`${prefix}Animation`]);
                ttScope.animation = angular.isDefined(animation)
                  ? !!animation
                  : options.animation;

                const appendToBodyVal = scope.$eval(
                  attrs[`${prefix}AppendToBody`]
                );
                appendToBody = angular.isDefined(appendToBodyVal)
                  ? appendToBodyVal
                  : appendToBody;

                // if a tooltip is attached to <body> we need to remove it on
                // location change as its parent scope will probably not be destroyed
                // by the change.
                if (appendToBody) {
                  scope.$on(
                    '$locationChangeSuccess',
                    function closeTooltipOnLocationChangeSuccess() {
                      if (ttScope.isOpen) {
                        hide();
                      }
                    }
                  );
                }

                // Make sure tooltip is destroyed and removed.
                scope.$on('$destroy', function onDestroyTooltip() {
                  $timeout.cancel(transitionTimeout);
                  $timeout.cancel(popupTimeout);
                  unregisterTriggers();
                  removeTooltip();
                  ttScope = null;
                });
              };
            }
          };
        };
      }
    ];
  })

  .directive('tooltipPopup', function() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        content: '=',
        placement: '@',
        class: '@',
        animation: '&',
        isOpen: '&'
      },
      templateUrl: 'app/core/components/tooltip/tooltipPopup.html'
    };
  })

  .directive('tooltip', [
    '$tooltip',
    function($tooltip) {
      return $tooltip('tooltip', 'tooltip', 'mouseenter');
    }
  ])

  .directive('tooltipHtmlUnsafePopup', function() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        content: '=',
        placement: '@',
        class: '@',
        animation: '&',
        isOpen: '&'
      },
      templateUrl: 'app/core/components/tooltip/tooltipPopupHtmlUnsafe.html'
    };
  })

  .directive('tooltipHtmlUnsafe', [
    '$tooltip',
    function($tooltip) {
      return $tooltip('tooltipHtmlUnsafe', 'tooltip', 'mouseenter');
    }
  ]);
