import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'core/theme';
import { useSystemInfo } from 'core/systemInfo';
import { withRoot } from 'core/components/Root';
import Menu from './Menu';
import styles from './Sidebar.module.css';
import styled from 'styled-components';

const SystemName = styled.h1`
  font-size: ${props => props.theme.systemNameFontOptions};
`;

const Sidebar = ({ currentUser }) => {
  const { theme } = useTheme();
  const systemInfo = useSystemInfo();

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo_container}>
        <div className={styles.build_name}>{systemInfo.buildName}</div>
        <img
          className={styles.logo}
          alt="system logo"
          src="/static/theme/admin/images/systemLogoImage.png"
        />
        <SystemName className={styles.label} data-hook-id="systemName">
          {theme.systemName}
        </SystemName>
      </div>
      <Menu currentUser={currentUser} theme={theme} />
    </div>
  );
};
Sidebar.propTypes = {
  currentUser: PropTypes.object
};
export default withRoot(Sidebar);
