const { moment, angular } = window;
angular
  .module('wcm.reporting')
  .controller('reportingCreateCampaignSpecificCtrl', function(
    $scope,
    $state,
    $q,
    $controller,
    $modal,
    $filter,
    sitesFactory,
    reportingFactory,
    analyticsRequestsFactory,
    campaignsFactory,
    analyticsFactory2,
    navigationPanel,
    ANALYTICS_CONFIG2
  ) {
    $controller('reportingCreateCtrl', { $scope });

    const A_CONFIG = ANALYTICS_CONFIG2;

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      blocks: A_CONFIG.blocks.analyticsCampaign,
      campaigns: []
    }));
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      type: 'analyticsCampaign',
      currentCampaignId: 0
    }));
    state.filters.campaignIds = [+state.currentCampaignId];

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      save() {
        const manageBlocks = analyticsRequestsFactory.getBlocksData();
        state.isBusy = true;
        reportingFactory
          .createCampaignSpecificReport(
            state.report,
            state.filters,
            manageBlocks
          )
          .then(function() {
            state.editMode = false;
            $state.go(state.callbackRoute);
          })
          .finally(function() {
            state.isBusy = false;
          });
      },
      updateAvailableValues() {
        state.isBusy = true;
        handlers.resetManageBlocks();
        return handlers
          .getAvailableValues(state.type, state.permissionType)
          .then(function() {
            state.isBusy = false;
            handlers.initManageBlocks();
          })
          .finally(function() {
            state.filters.campaignIds = [+state.currentCampaignId]; // Set current campaign id
          });
      },
      getCampaignData() {
        return campaignsFactory.campaignGetData(state.currentCampaignId);
      },
      onSelectCampaign() {
        state.filters.campaignIds = [+state.currentCampaignId]; // Set current campaign id
        handlers.getCampaignData().then(function(res) {
          const start = res.data.campaign
            ? moment(res.data.campaign.general.creationDate).startOf('day')
            : state.filters.endPeriod;
          handlers.setBeginPeriodDates(start);
        });
        handlers.updateAvailableValues();
      }
    }));

    $scope.init = function() {
      handlers.setTabs();
      state.report.blockList = angular.copy(options.blocks);
      analyticsRequestsFactory
        .getAllCampaigns('campaigns', state.permissionType)
        .then(
          function(res) {
            options.campaigns = res.campaigns;
          },
          function() {
            state.noWas = true;
          }
        )
        .finally(function() {
          state.isBusy = false;
        });
    };

    $scope.downloadSelectedSites = function() {
      const sites = {};
      state.manageBlocks.sitesFilter.sites.forEach(item => {
        if (item.checked) {
          sites[item.id] = true;
        }
      });
      sitesFactory.downloadSitesListForCampaign(sites);
    };

    // Check files from file
    $scope.uploadSitesForSelect = function(type, file) {
      sitesFactory.uploadSitesListForCampaign(type, file).then(
        function(res) {
          if (!res.siteIds) {
            return;
          }
          let newItem;
          state.manageBlocks.sitesFilter.sites = state.manageBlocks.sitesFilter.sites.map(
            function(item) {
              newItem = angular.copy(item);
              if (~res.siteIds.indexOf(item.id)) {
                newItem.checked = true;
              } else {
                newItem.checked = false;
              }
              return newItem;
            }
          );
          state.filters.siteIds = handlers.filterBy(
            state.manageBlocks.sitesFilter.sites,
            'id'
          );
        },
        function(res) {
          if (res === 'wrongExe') {
            const labels = {
              message: 'campaigns.modals.xls.errorXlsMsg',
              title: 'campaigns.modals.xls.errorXlsTitle'
            };
            infoModal(labels);
          }
        }
      );
    };

    function infoModal(labels) {
      const i18n = $filter('i18n');
      const scope = $scope.$new();
      scope.msg = i18n(labels.message);
      scope.title = i18n(labels.title);
      $modal.open({
        templateUrl: 'app/core/views/infoErrorGlobal.html',
        windowClass: 'wcm-msg',
        scope
      });
    }

    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.generate',
        isDisabled: () => $scope.state.noWas || $scope.state.isBusy,
        isShown: () => true,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.reportForm.$valid) {
            $scope.handlers.save();
          }
        }
      },
      {
        title: () => 'reporting.buttons.cancel',
        isDisabled: () => $scope.state.isBusy,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);
  });
