import React from 'react';
import capitalize from 'lodash/capitalize';
import { withRoot } from 'core/components/Root';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import LanguageSelector from 'ui/LanguageSelector';
import Breadcrumbs from 'ui/Breadcrumbs';
import { useTranslation } from 'react-i18next';
const styles = require('./Topbar.module.css');

interface User {
  avatarImage: string;
  userId: string;
  userRole: string;
}
interface Props {
  availableLocales: Array<object>;
  currentLocale: object;
  onLocaleChange(locale: object): void;
  onLogout(): void;
  currentUser: User;
}

export const Topbar = ({
  availableLocales,
  currentLocale,
  onLocaleChange,
  onLogout,
  currentUser
}: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.breadcrumbsContainer}>
        <Breadcrumbs />
      </div>
      <div className={styles.userMenu}>
        <div
          className={styles.avatar}
          data-hook-id="img_avatarImage"
          style={{ backgroundImage: `url(${currentUser.avatarImage}` }}
        ></div>

        <div className={styles.userName}>
          <span style={{ fontWeight: 300 }} data-hook-id="str_userGreeting">
            {t('sidebar.user.greeting')},
          </span>
          <span data-hook-id="str_userId"> {currentUser.userId}</span>
        </div>

        <div className={styles.userRole} data-hook-id="str_userRole">
          {capitalize(currentUser.userRole)}
        </div>
        <div className={styles.languageSelectorContainer}>
          <LanguageSelector
            className={styles.languageSelector}
            locales={availableLocales}
            selectedLocale={currentLocale}
            onSelectedLocaleChanged={locale => {
              i18n.changeLanguage(locale.id);
              onLocaleChange(locale.id);
            }}
          />
        </div>

        <div className={styles.buttonsContainer}>
          <Link
            className={classNames(styles.btn, styles.profile)}
            data-hook-id="b_goToProfile"
            to="/profile"
          >
            Go to user profile
          </Link>
          <Link
            className={classNames(styles.btn, styles.changePassword)}
            data-hook-id="b_changePassword"
            to="/changePassword"
          >
            Change password
          </Link>
          <button
            className={classNames(styles.btn, styles.logout)}
            onClick={onLogout}
            data-hook-id="b_logout"
            title="logout"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

Topbar.defaultProps = {
  availableLocales: [],
  currentLocale: {},
  onLocaleChange: () => {},
  onLogout: () => {},
  currentUser: {}
};

export default withRoot(Topbar);
