/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .controller('tmpCtrl', function(
    $scope,
    $timeout,
    analyticsPresetsFactory,
    analyticsFactory2
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isPresetChanged: false,
      blocks: {
        test: {}
      }
    });
    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {});

    state.filters = {
      startPeriod: '2016-07-12T21:00:00.000Z',
      endPeriod: '2016-08-12T21:00:00.000Z',
      startTimeFrame: 20 * 60 * 1000,
      daysOfWeek: [1, 3, 5]
    };

    // Handlers ------------------
    //
    $scope.handlers = {
      onPresetChange(preset) {
        state.currentPreset = preset;
      },
      onPresetManage() {
        analyticsPresetsFactory
          .openPresetManager(options.presets)
          .result.then(function(preset) {
            console.log(preset);
          });
      },
      onPresetReset: impactsPreset(function() {
        analyticsPresetsFactory
          .getPreset('campaign', state.currentPreset.name)
          .then(function() {
            // apply preset here
          });
      }, true),
      onRemoveBlock: impactsPreset(function(blockName) {
        delete state.blocks[blockName];
      }),
      savePreset(name) {
        analyticsPresetsFactory.savePreset('campaigns', name, state.blocks);
      },
      onVisibilityChange(visibility) {
        console.log('visibility changed to %s', visibility);
      }
    };

    // Remove block handler
    $scope.removeBlock = function(blockName) {
      $scope.blocks[blockName] = false;
    };

    // Show more Btn handler
    $scope.showMoreHandler = function(blockName, typeName) {
      const cutData = analyticsFactory2.getShowMoreData(
        $scope.chartsData[blockName].requested,
        typeName
      );
      $scope.chartsData[blockName].view.data[cutData.index] = cutData.item;
    };

    $scope.showMoreHandlerByFilters = function(blockName, typeName) {
      $scope.chartsData[
        blockName
      ].view = analyticsFactory2.getShowMoreDataByFilters(
        $scope.chartsData[blockName].requested.data[0],
        typeName,
        // eslint-disable-next-line no-use-before-define
        getCurrentFilter($scope.blocks[blockName])
      );
    };
    //
    // <-----------------------

    // Presets code ---------
    //
    analyticsPresetsFactory.getPresets().then(function(presets) {
      options.presets = presets;
    });

    analyticsPresetsFactory.getDefaultPreset().then(function(preset) {
      state.currentPreset = preset;
    });

    function impactsPreset(fn, reset) {
      return function(...args) {
        state.isPresetChanged = !reset;
        return fn.apply(this, args);
      };
    }
    //
    // <------------

    // Blocks from presets
    $scope.blocks = {
      getDataByDevices: {
        showNumbers: false
      },
      getDataByCampaigns: {
        showNumbers: false,
        filters: [
          {
            classA: false,
            classB: true
          },
          {
            views: true,
            uniqueViews: false,
            clicks: false,
            ctr: false
          }
        ]
      },
      getDataByVisitorsAndViews: {
        showNumbers: false
      }
    };

    // Data by all charts
    // *requested - data wich was request from server
    // *view - data after prepare function
    $scope.chartsData = {};

    // Duplicate stuff from factory
    $scope.chartsOptions = analyticsFactory2.chartsOptions;
    // eslint-disable-next-line no-multi-assign
    const getCurrentFilter = ($scope.getCurrentFilter =
      analyticsFactory2.getCurrentFilter);

    // Change filter in analyticsBlock handler
    $scope.changeFilter = function(blockName, chartId) {
      $scope.chartsData[blockName].view = analyticsFactory2.preparePieData(
        $scope.chartsData[blockName].requested.data[0],
        chartId,
        getCurrentFilter($scope.blocks[blockName])
      );
    };

    // Test data from requests ---------->
    //
    const initAfterRequest = function() {
      $scope.chartsData.getDataByDevices = { requested: {}, view: {} };
      $scope.chartsData.getDataByDevices.requested = {
        data: [
          {
            name: 'byOS',
            data: {
              columns: [
                ['ios', 12],
                ['android', 120],
                ['mac', 50],
                ['windows', 150],
                ['linux', 100],
                ['windowsPhone', 80],
                ['other', 100]
              ],
              type: 'pie'
            },
            options: {
              total: 612,
              partData: true
            }
          },
          {
            name: 'byType',
            data: {
              columns: [
                ['smartphones', 12],
                ['tablets', 30],
                ['desktops', 100],
                ['other', 70]
              ],
              type: 'pie'
            },
            options: {
              total: 212,
              partData: true
            }
          },
          {
            name: 'byPrice',
            data: {
              columns: [
                ['expensive', 12],
                ['medium', 100],
                ['cheap', 150],
                ['other', 50]
              ],
              type: 'pie'
            },
            options: {
              total: 312
            }
          }
        ]
      };

      // Prepare data for show
      $scope.chartsData.getDataByDevices.view = analyticsFactory2.preparePieData(
        $scope.chartsData.getDataByDevices.requested
      );
    };

    const initAfterRequest3 = function() {
      $scope.chartsData.getDataByVisitorsAndViews = { requested: {}, view: {} };
      $scope.chartsData.getDataByVisitorsAndViews.requested = {
        data: {
          columns: [
            ['visitors', 50, 12, 20, 45, 12, 21, 120, 80, 115, 99],
            ['views', 5, 120, 30, 15, 38, 12, 3, 45, 50, 55]
          ]
        },
        options: {
          maxY: 200,
          ticksX: [
            1415252800000,
            1417339200000,
            1419425600000,
            1421512000000,
            1423598400000,
            1425684800000,
            1427771200000,
            1429857600000,
            1431944000000,
            1434030400000
          ],
          ticksX2: [
            {
              temperature: 20,
              weatherType: 'good'
            },
            {
              temperature: 10,
              weatherType: 'bad'
            },
            {
              temperature: 5,
              weatherType: 'good'
            },
            {
              temperature: -20,
              weatherType: 'bad'
            },
            {
              temperature: 20,
              weatherType: 'good'
            },
            {
              temperature: 13,
              weatherType: 'good'
            },
            {
              temperature: 10,
              weatherType: 'bad'
            },
            {
              temperature: 15,
              weatherType: 'good'
            },
            {
              temperature: -10,
              weatherType: 'bad'
            },
            {
              temperature: 20,
              weatherType: 'good'
            }
          ]
        }
      };

      // Prepare data for show
      const options = {};
      options.colorPattern = ['#aec7e8', '#ff7f0e'];
      options.xAxis = {
        type: 'timeseries',
        tick: { format: '%d.%m.%Y' }
      };
      options.yAxis = {
        tick: { format: window.d3.format('.0f'), count: 5 }
      };
      $scope.chartsData.getDataByVisitorsAndViews.view = analyticsFactory2.prepareLineData(
        $scope.chartsData.getDataByVisitorsAndViews.requested,
        options
      );
    };

    const initAfterRequest2 = function() {
      $scope.chartsData.getDataByCampaigns = { requested: {}, view: {} };
      $scope.chartsData.getDataByCampaigns.requested = {
        data: [
          {
            classA__views: {
              data: {
                columns: [
                  ['item1', 12],
                  ['item2', 90],
                  ['item3', 110],
                  ['item4', 80],
                  ['item5', 120]
                ],
                type: 'pie'
              },
              options: {
                total: 412,
                partData: true
              }
            },
            classB__views: {
              data: {
                columns: [['item1', 12], ['item2', 100]],
                type: 'pie'
              },
              options: {
                total: 112,
                partData: true
              }
            },
            classA__uniqueViews: {
              data: {
                columns: [['item1', 12], ['item2', 100]],
                type: 'pie'
              },
              options: {
                total: 500,
                partData: true
              }
            },
            classB__uniqueViews: {
              data: {
                columns: [['item1', 12], ['item2', 100]],
                type: 'pie'
              },
              options: {
                total: 500,
                partData: true
              }
            },
            classA__clicks: {
              data: {
                columns: [['item1', 12], ['item2', 100]],
                type: 'pie'
              },
              options: {
                total: 500,
                partData: true
              }
            },
            classB__clicks: {
              data: {
                columns: [['item1', 12], ['item2', 100]],
                type: 'pie'
              },
              options: {
                total: 500,
                partData: true
              }
            },
            classA__ctr: {
              data: {
                columns: [['item1', 0.12], ['item2', 0.1]],
                type: 'pie'
              },
              options: {
                total: 1,
                partData: true
              }
            },
            classB__ctr: {
              data: {
                columns: [['item1', 0.12], ['item2', 0.1]],
                type: 'pie'
              },
              options: {
                total: 1,
                partData: true
              }
            }
          }
        ]
      };

      // Prepare data for show
      $scope.chartsData.getDataByCampaigns.view = analyticsFactory2.preparePieData(
        $scope.chartsData.getDataByCampaigns.requested.data[0],
        'byCampaigns',
        getCurrentFilter($scope.blocks.getDataByCampaigns)
      );
    };

    initAfterRequest();
    initAfterRequest2();
    initAfterRequest3();
    //
    // <----------------------
  });
