/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.pagination')
  .directive('wcmPagination', function($timeout, PAGINATION_CONFIG) {
    const controller = function controller($scope) {
      $scope.maxSize = $scope.maxSize || 5;

      $scope.itemsPerPageValues =
        $scope.itemsPerPageValues || PAGINATION_CONFIG.itemsPerPageValues;

      $scope.proxyChange = function() {
        $timeout($scope.onChange);
      };

      $scope.changeItemsPerPage = function(val) {
        $scope.itemsPerPage = val;
        $scope.proxyChange();
      };

      $scope.$watch('totalItems', function(newVal) {
        if ($scope.itemsPerPage <= newVal) return;
        // adjust items per page value to be no greater than total items
        $scope.itemsPerPage =
          $scope.itemsPerPageValues
            .filter(function(val) {
              return val <= newVal;
            })
            .pop() || $scope.itemsPerPageValues[0];
      });

      $scope.setTotalPages = function() {
        return Math.ceil($scope.totalItems / $scope.itemsPerPage);
      };
    };

    return {
      restrict: 'E',
      replace: true,
      scope: {
        currentPage: '=',
        onChange: '&',
        totalItems: '=',
        itemsPerPage: '=',
        maxSize: '@',
        pagInfoShort: '@',
        itemsPerPageValues: '=?'
      },
      controller,
      templateUrl: 'app/core/pagination/pagination.html'
    };
  });
