/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.sites')
  .controller('siteViewEditCtrl', function(
    $scope,
    $state,
    $q,
    sitesFactory,
    entitiesFactory,
    modificationHistory,
    contactPersonaeFactory,
    navigationPanel,
    $modal,
    helpers,
    countriesFactory,
    i18nFactory,
    $rootScope,
    $timeout,
    sharedRequestsFactory
  ) {
    const id = $state.params.id || 0;

    // $scope.editMode = (id == 0);
    $scope.options = {
      countries: [],
      cities: [],
      siteCoords: {},
      snAuth: ['disabled', 'enabled', 'required']
    };
    $scope.submitted = false;
    $scope.mapCheckerOnSave = false;
    $scope.validation = {};
    $scope.focused = {};
    $scope.slidedownBlocks = {
      apMacs: false, // obsolete
      chains: false,
      history: false,
      tagsTypes: false, // obsolete
      tagsClasses: false // obsolete
    };

    $scope.map = {
      expanded: false,
      addressSearchInProgress: false,
      generateSiteAddress: ''
    };

    countriesFactory.getCountries().then(function(data) {
      angular.copy(data, $scope.options.countries);
    });

    $scope.$watch('site.country', function(newVal, oldVal) {
      if (!newVal) {
        return false;
      }
      if (oldVal) {
        $scope.site.city = '';
      }

      $scope.options.cities = [];
      return countriesFactory.getCitiesForCountry(newVal).then(function(data) {
        angular.copy(data, $scope.options.cities);
      });
    });

    /** *
     * Get site data
     * ** */
    const getData = function() {
      $scope.setBusy(true);
      return $scope
        .siteDataReady()
        .then(getAvailableValues)
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    /** *
     * Get site values
     * ** */
    function getAvailableValues() {
      let hData;
      let eData;
      let sData;
      let cData;

      $scope.setBusy(true);
      // eslint-disable-next-line prefer-const
      eData = entitiesFactory.getOwnersList('Partner').success(function(data) {
        $scope.options.owners = data.owners;

        if (
          $scope.site.partnerId &&
          !window._.find($scope.options.owners, function(item) {
            return item.id === $scope.site.partnerId;
          })
        ) {
          $scope.options.owners.unshift({
            id: $scope.site.partnerId,
            name: $scope.site.partnerName
          });
        }
      });

      // eslint-disable-next-line prefer-const
      sData = sitesFactory.getAvailableValues().success(function(data) {
        angular.extend($scope.options, data);
      });

      // eslint-disable-next-line prefer-const
      cData = contactPersonaeFactory
        .getList('Site', id)
        .success(function(data) {
          $scope.site.contactPersonae = data.contactPersonae;
        });
      if (id) {
        hData = modificationHistory.get('Site', id).then(function(entries) {
          $scope.history = entries;
        });
      } else {
        hData = $q.when(true);
      }

      $q.all([eData, sData, cData, hData]).finally(function() {
        $scope.setBusy(false);
      });
    }

    /** *
     * Modals handlers
     * ** */
    const cancelConfirmModal = function() {
      const title = i18nFactory.translate(
        'sites.modals.cancelConfirmation.title'
      );
      const message = i18nFactory.translate(
        `sites.modals.cancelConfirmation.texts.${id === 0 ? 'create' : 'edit'}`
      );

      return helpers.confirmationModal(title, message).result;
    };

    /** *
     * Confirmation of changes
     * ** */
    $scope.confirmChange = function(type, event) {
      event.preventDefault();

      let title;
      let message;

      // eslint-disable-next-line prefer-const
      title = i18nFactory.translate(`sites.modals.${type}.title`);
      // eslint-disable-next-line prefer-const
      message = i18nFactory.translate(
        `sites.modals.${type}.texts.${$scope.site[type] ? 'off' : 'on'}`
      );

      // console.log(type, $scope.site[type] );

      helpers.confirmationModal(title, message).result.then(function() {
        $scope.site[type] = !$scope.site[type];
      });
    };

    /** *
     * Init() main point
     * ** */
    const init = function() {
      $scope.setBusy(true);
      if (id) {
        getData(id).then(() => {
          // $scope.setBreadcrumbs("sites>details", site.id + '. ' + site.name);
          $scope.setBreadcrumbs('details', $scope.site.id, $scope.site.name);
        });
      } else {
        sharedRequestsFactory.canCreate('site', 'siteFalse').then(function() {
          $scope.editMode = id === 0;
          getAvailableValues();
          $scope.setBreadcrumbs('create');
          $scope.site = {
            id: 0,
            // country: '',
            // city: '',
            sessionTimeout: 30,
            snDeliveryInterval: 0,
            snAuth: 'disabled',
            smsAuth: false,
            contentDelivery: true,
            badInternet: false,
            apMacs: [],
            apStrings: [],
            tagsTypes: [],
            tagsClasses: []
          };
          $scope.setBusy(false);
        });
      }
    };

    const isCanSearchByAddress = function(site) {
      return (
        ($scope.site.coords &&
          $scope.site.coords.latitude &&
          $scope.site.coords.longitude) ||
        (site.country && site.city && (site.address || site.zipCode))
      );
    };

    /** *
     * Search coords on map by address
     * ** */
    $scope.mapSearchAddress = function(site, expand) {
      const city = site.city && site.city === 'Other*' ? '' : `${site.city}, `;
      $scope.mapCheckerOnSave = false;
      $scope.map.generateSiteAddress = [];
      $scope.map.generateSiteAddress[0] = city + site.country;
      $scope.map.generateSiteAddress[1] = site.address;
      $scope.map.generateSiteAddress[2] = site.zipCode;
      if (isCanSearchByAddress(site) && !expand) {
        $scope.map.mapExpanded = true;
        $scope.map.addressSearchInProgress = true;
        $scope.site.coords = {};
        $timeout(function() {
          $scope.$broadcast(
            'geoLocation.searchByAddress',
            $scope.map.generateSiteAddress
          );
        }, 500);
      } else if (expand === 'toggle') {
        $scope.map.mapExpanded = !$scope.map.mapExpanded;
      }
    };

    /** *
     * Save, edit and cancel handlers
     * ** */
    $scope.edit = function edit() {
      $scope.editMode = true;
      $scope.showSlidedownBlock = true;
      $scope.showSlidedownBlock2 = true;
      $scope.showSlidedownTags = true;

      $scope.setBreadcrumbs('modify', $scope.site.id, $scope.site.name);
    };

    $scope.cancel = function cancel() {
      $scope.mapCheckerOnSave = false;
      if ($scope.editMode && id) {
        cancelConfirmModal().then(function() {
          $scope.invalidateSiteData();
          init();
          $scope.editMode = false;
        });
      } else {
        $state.go('section.sites.sitesList');
      }
    };

    $scope.save = function save() {
      $scope.submitted = true;
      $scope.mapCheckerOnSave = true;

      if ($scope.siteForm.$invalid) return true;

      if (!$scope.site.localName || $scope.site.localName === '')
        $scope.site.localName = $scope.site.name;

      // $scope.site.coords = $scope.options.siteCoords;

      $scope.setBusy(true);
      return sitesFactory
        .save($scope.site)
        .success(function() {
          $scope.editMode = false;
          $state.go('section.sites.sitesList');
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.addContact = function() {
      contactPersonaeFactory
        .openAddContactModal()
        .then(contact => $scope.site.contactPersonae.push(contact));
    };

    $scope.editContact = function(contact) {
      const contactCopy = angular.copy(contact);
      contactPersonaeFactory
        .openEditContactModal(contactCopy)
        .then(editedContact => {
          const contacts = $scope.site.contactPersonae;
          contacts[contacts.indexOf(contact)] = editedContact;
        });
    };

    $scope.removeContact = function(index) {
      const contacts = $scope.site.contactPersonae;
      contacts.splice(index, 1);
    };

    /** *
     * Watchers
     * ** */
    $scope.$on('$stateChangeStart', function(event, toState) {
      if ($scope.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          $state.go(toState);
        });
      }
    });

    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'sites.buttons.edit',
        isDisabled: () => false,
        isShown: () =>
          !$scope.editMode && $scope.site && $scope.site.isEditable,
        clickHandler: () => {
          $scope.edit();
        }
      },
      {
        title: () => 'sites.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.save();
        }
      },
      {
        title: () => `sites.buttons.${$scope.editMode ? 'cancel' : 'close'}`,
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);
  });
