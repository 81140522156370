/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.widgets')
  .factory('widgetsConverter', function(WIDGETS_CONFIG, $injector) {
    const widgetTemplates = WIDGETS_CONFIG.widgetTemplates;

    function getLegacyWidgetType(widgetType) {
      return widgetType === 'static' ? 'static_dynamic' : widgetType;
    }

    function extendDefaultTemplate(widgetType, widget) {
      return window.$.extend(
        true,
        {},
        widgetTemplates.base,
        widgetTemplates[widgetType],
        widget
      );
    }

    function migrate(widget) {
      const migratorsNamePattern = 'widgetMigration_';
      const migrators = [];
      let schemaVersion;
      let normalizedWidget;

      try {
        schemaVersion = widget.widgetData.client.schemaVersion || 0;
      } catch (ex) {
        schemaVersion = 0;
      }

      // don't need to migrate
      if (schemaVersion === WIDGETS_CONFIG.currentSchemaVersion) return widget;

      for (
        let i = schemaVersion;
        i < WIDGETS_CONFIG.currentSchemaVersion;
        i++
      ) {
        migrators.push($injector.get(migratorsNamePattern + (i + 1)));
      }

      // eslint-disable-next-line prefer-const
      normalizedWidget = migrators.reduce(function migrationHandler(
        widget,
        migrator
      ) {
        return migrator.migrate(widget);
      },
      widget);

      const markup = normalizedWidget.widgetData.client;
      normalizedWidget.widgetData.client = extendDefaultTemplate(
        markup.widgetType,
        markup
      );

      return normalizedWidget;
    }

    return {
      getLegacyWidgetType,
      migrate
    };
  });
