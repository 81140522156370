/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { saveAs } = window;

angular
  .module('wcm.sites')
  .factory('sitesFactory', function(
    $q,
    $rootScope,
    $http,
    requestFactory,
    SITES_CONFIG,
    helpers
  ) {
    let requestSiteLoadingObj = {};

    const getList = function(filters, page, ipp, sortOrder) {
      const requestData = angular.extend(
        {},
        filters,
        helpers.getPaginationFilters(page, ipp, sortOrder)
      );
      return requestFactory
        .post(SITES_CONFIG.sitesServlet, SITES_CONFIG.getList, requestData)
        .then(function(res) {
          return res.data && res.data.sites ? res.data.sites : [];
        })
        .then(function(sites) {
          $rootScope.$broadcast('sites:list-updated', sites);
          return sites;
        });
    };

    const moveToArchive = function(siteId) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.moveToArchive,
        { id: siteId * 1 }
      );
    };

    const getData = function(siteId) {
      return requestFactory
        .post(SITES_CONFIG.sitesServlet, SITES_CONFIG.getData, {
          id: siteId * 1
        })
        .then(res => {
          const site = res.data.site;
          site.analytics = site.analytics || [];
          site.apStrings = site.apStrings || [];
          site.apMacs = site.apMacs || [];
          return site;
        });
    };

    const save = function(site) {
      return requestFactory.post(SITES_CONFIG.sitesServlet, SITES_CONFIG.save, {
        site
      });
    };

    const getAvailableValues = function() {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.getAvailableValues,
        {}
      );
    };

    const recoverFromArchive = function(siteId) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.recoverFromArchive,
        { id: siteId * 1 }
      );
    };

    const siteGetListForCampaign = function siteGetListForCampaign(id) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.siteGetListForCampaign,
        { id: id * 1 },
        {},
        { skipErrors: [502, 504, 404, 0, -1] }
      );
    };

    const getSitesListByGeoFilter = function getSitesListByGeoFilter(
      campaignData
    ) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.getSitesListByGeoFilter,
        campaignData
      );
    };

    const getSitesListSimple = function getSitesListSimple(campaignData) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.getSitesListSimple,
        campaignData
      );
    };

    const getSitesLoading = function(siteLodingData) {
      return requestFactory.post(
        SITES_CONFIG.analyticsSiteServlet,
        SITES_CONFIG.getSitesLoading,
        siteLodingData,
        {},
        { skipErrors: [502, 504] }
      );
    };

    const getSettingsOverrides = function getSettingsOverrides(id) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.getSettingsOverrides,
        { id }
      );
    };

    const saveSettingsOverrides = function saveSettingsOverrides(settings) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.saveSettingsOverrides,
        settings
      );
    };

    const removeSite = function settingsOverrides(id) {
      return requestFactory.post(
        SITES_CONFIG.sitesServlet,
        SITES_CONFIG.removeSite,
        { id },
        {},
        { skipErrors: [409] }
      );
    };

    const checkMacDuplication = function checkMacDuplication(
      siteId,
      macAddress
    ) {
      const dfd = $q.defer();

      requestFactory
        .post(SITES_CONFIG.sitesServlet, SITES_CONFIG.checkMacDuplication, {
          id: +siteId,
          apMac: macAddress
        })
        .success(function(data) {
          if (data.duplicate) {
            dfd.reject(data.name);
          } else {
            dfd.resolve();
          }
        });

      return dfd.promise;
    };

    // // Site loading --------->

    const isSendSiteLoading = function(obj, scope) {
      return (
        scope.editMode &&
        ((obj.dateTime &&
          obj.dateTime.days[0] &&
          obj.dateTime.timeFrames.length) ||
          !obj.dateTime)
      );
    };

    const mapCampaignToSiteLoadingRequest = function(campaign) {
      const obj = helpers.mapCampaignRequest(campaign);
      delete obj.sites;
      return obj;
    };

    const initSiteLoading = function(scope, opts) {
      const defaultOptions = {
        debounceTimeout: 500,
        refreshEvent: 'siteLoading:refresh',
        onDataReceived: angular.noop,
        onRequestStart: angular.noop,
        onRequestFinish: angular.noop
      };

      const options = angular.extend({}, defaultOptions, opts);

      const processRequest = function() {
        // Sending Live-Prognosis by some conditions
        if (isSendSiteLoading(requestSiteLoadingObj, scope)) {
          // TODO: options.onRequestStart must be rewritten due to its
          // current poor implementation. Concurrency run problem!
          const needReturn = options.onRequestStart();
          if (needReturn) return;

          getSitesLoading(requestSiteLoadingObj)
            .success(function(data) {
              options.onRequestFinish({ error: false });
              options.onDataReceived(data);
            })
            .error(options.onRequestFinish.bind(null, { error: true }))
            .finally(function() {
              requestSiteLoadingObj = window._.pick(
                requestSiteLoadingObj,
                'general',
                'dateTime'
              );
            });
        }
      };

      const debouncedProcessRequest = window._.debounce(
        processRequest,
        options.debounceTimeout
      );

      const unwatches = helpers.unwatches(
        ['general', 'dateTime', 'device', 'profile', 'retargeting'],
        function(block) {
          return scope.$watch(
            function() {
              // Add chainId to campaign
              const campaign = angular.copy(scope.campaign);
              campaign.chainId = scope.authChain ? scope.authChain.chainId : 0;
              return mapCampaignToSiteLoadingRequest(campaign)[block];
            },
            function(val, prev) {
              if (!prev) return null;
              requestSiteLoadingObj[block] = val;
              debouncedProcessRequest();
              return undefined;
            },
            true
          );
        }
      );
      unwatches.push(
        $rootScope.$on(options.refreshEvent, function(fullUpdate, status) {
          if (status === true) {
            requestSiteLoadingObj = mapCampaignToSiteLoadingRequest(
              scope.campaign
            );
          }
          processRequest();
        })
      );

      return function() {
        angular.forEach(unwatches, function(unwatch) {
          unwatch();
        });
      };
    };

    // Export selected sites list
    const downloadSitesListForCampaign = function(sites) {
      const siteIds = window._.reduce(
        sites,
        function(memo, value, key) {
          if (value) {
            memo.push(parseInt(key, 10));
          }
          return memo;
        },
        []
      );

      return requestFactory
        .post(
          SITES_CONFIG.sitesServlet,
          SITES_CONFIG.downloadSitesListForCampaign,
          { siteIds },
          {},
          { skipErrors: [502, 504] }
        )
        .then(
          function(res) {
            if (res.status === 201) {
              return $http
                .get(
                  `${window.location.origin}/${
                    SITES_CONFIG.sitesServlet
                  }?action=${SITES_CONFIG.downloadSitesListForCampaign}`,
                  { responseType: 'arraybuffer' }
                )
                .then(function(res) {
                  const name = 'selected_sites.xls';
                  const blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel'
                  });
                  saveAs(blob, name);
                });
            }
            return undefined;
          },
          function(res) {
            helpers.noServerInfoModal(res.status);
          }
        );
    };

    // Upload xls file with sites data
    const uploadSitesListForCampaign = function(type, file) {
      const data = new FormData();
      data.append(file.name, file);
      const exe = file.name.split('.').pop();
      if (exe !== 'xls' && exe !== 'xlsx') {
        return $q.reject('wrongExe');
      }
      return $http
        .post(SITES_CONFIG.uploadSitesServlet, data, {
          transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
            'File-Type': type
          }
        })
        .then(function(res) {
          return res.data ? res.data : {};
        });
    };

    return {
      getList,
      getData,
      save,
      getAvailableValues,
      moveToArchive,
      recoverFromArchive,
      siteGetListForCampaign,
      getSitesListSimple,
      getSitesListByGeoFilter,
      getSettingsOverrides,
      saveSettingsOverrides,
      removeSite,
      checkMacDuplication,
      getSitesLoading,
      mapCampaignToSiteLoadingRequest,
      initSiteLoading,
      downloadSitesListForCampaign,
      uploadSitesListForCampaign
    };
  });
