import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import classNames from 'classnames';
import styles from './LanguageSelector.module.css';

export const LanguageSelector = ({
  selectedLocale,
  onSelectedLocaleChanged,
  locales,
  className
}) => {
  return (
    <Downshift
      selectedItem={selectedLocale}
      onChange={onSelectedLocaleChanged}
      itemToString={item => item.title}
    >
      {({
        selectedItem,
        isOpen,
        getToggleButtonProps,
        getItemProps,
        getMenuProps
      }) => {
        return (
          <div
            className={classNames(styles.root, className)}
            data-hook-id="d_langSwitcher"
          >
            <button
              className={styles.button}
              data-hook-id="d__toggleButton"
              {...getToggleButtonProps()}
            >
              <img
                src={selectedItem ? selectedItem.icon : ''}
                className={styles.icon}
                alt={`current language ${selectedItem.id}`}
                data-hook-id={`d__current_${selectedItem.id}`}
              />
            </button>
            {isOpen ? (
              <div className={styles.menu} {...getMenuProps()}>
                <ul className={styles.list} data-hook-id="d__list">
                  {locales.map(locale => (
                    <li
                      key={locale.id}
                      className={styles.listItem}
                      data-hook-id="d__item"
                      {...getItemProps({ item: locale })}
                    >
                      <img
                        alt={`${locale.id} language`}
                        className={styles.icon}
                        src={locale.icon}
                      />
                      <span
                        className={styles.label}
                        data-hook-id={`d__itemTitle_${locale.id}`}
                      >
                        {locale.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

LanguageSelector.propTypes = {
  locales: PropTypes.array.isRequired,
  onSelectedLocaleChanged: PropTypes.func,
  selectedLocale: PropTypes.object,
  className: PropTypes.string
};

LanguageSelector.defaultProps = {
  locales: [],
  onSelectedLocaleChanged: () => ({}),
  selectedLocale: {},
  className: undefined
};

export default LanguageSelector;
