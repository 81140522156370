/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.helpers')
  .directive('slidedownBlock', function($timeout) {
    return {
      restrict: 'AE',
      scope: {
        editMode: '<',
        isDisabled: '<disabled'
      },
      replace: true,
      transclude: {
        body: 'slidedownBody',
        headerButton: '?slidedownHeaderButton',
        headerSelect: '?slidedownHeaderSelect'
      },
      templateUrl: 'app/core/components/slidedownBlock/slidedownBlock.html',
      link(scope, el, attr) {
        const minimalContentHeight = 60;

        attr.$observe('name', function(name) {
          scope.name = name;
        });

        scope.state = {
          isExpanded: false
        };

        $timeout(function() {
          scope.$apply();
        }, 100);

        scope.$watch(
          function() {
            return el.find('.js-slidedown-block-transclude').outerHeight();
          },
          function(contentHeight) {
            scope.state.isContentOverflow =
              contentHeight >= minimalContentHeight;
          },
          true
        );
      }
    };
  });
