/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.afkDetection')
  .factory('afkFactory', function(
    $rootScope,
    $timeout,
    $modal,
    $state,
    $modalStack,
    requestFactory,
    session,
    AFK_DETECTION_CONFIG
  ) {
    let halfAfkTimerPromise;
    let fullAfkTimerPromise;
    let sessionTimeout;
    let halfTimeModalInstance;

    const resetTimeout = function() {
      requestFactory.post('login', 'sessionResetTimeout', {});
    };
    const cancelAfkTimers = function() {
      if (!halfAfkTimerPromise || !fullAfkTimerPromise) {
        return false;
      }
      $timeout.cancel(halfAfkTimerPromise);
      $timeout.cancel(fullAfkTimerPromise);
      halfAfkTimerPromise = undefined;
      fullAfkTimerPromise = undefined;
      resetTimeout();
      return true;
    };

    const setAfkTimers = function(timeout) {
      const _timeout =
        timeout || sessionTimeout || AFK_DETECTION_CONFIG.sessionTimeout * 1000;

      cancelAfkTimers(); // cancel afk timers if any

      halfAfkTimerPromise = $timeout(halfTimeHandler, _timeout / 2);

      fullAfkTimerPromise = $timeout(fullTimeHandler, _timeout);
    };

    function halfTimeHandler() {
      const scope = $rootScope.$new();
      const timeout =
        sessionTimeout || AFK_DETECTION_CONFIG.sessionTimeout * 1000;
      scope.afkTime = Math.floor(timeout / 1000 / 60 / 2);
      halfTimeModalInstance = $modal.open({
        templateUrl: 'app/core/afk_detection/halfTimeModal.html',
        scope
      });
      halfTimeModalInstance.result.then(function() {
        resetTimeout();
      });
    }

    function fullTimeHandler() {
      const scope = $rootScope.$new();
      const timeout =
        sessionTimeout || AFK_DETECTION_CONFIG.sessionTimeout * 1000;
      scope.afkTime = Math.floor(timeout / 1000 / 60);
      $modalStack.dismissAll();
      $modal.open({
        templateUrl: 'app/core/afk_detection/fullTimeModal.html',
        scope
      });
      session.destroy();
      $state.go('login.signIn');
    }

    const updateActivity = function() {
      if (cancelAfkTimers()) {
        // update timers only if they already had been set
        setAfkTimers();
      }
    };

    const init = function(options) {
      const opts = angular.extend({}, AFK_DETECTION_CONFIG, options);

      $rootScope.$on(opts.startAfkDetectionEvent, function(event, data) {
        sessionTimeout = data.sessionTimeout * 1000;
        setAfkTimers(sessionTimeout);
      });
      $rootScope.$on(opts.stopAfkDetectionEvent, cancelAfkTimers);
      $rootScope.$on(
        opts.updateAfkTimersEvent,
        window._.debounce(updateActivity, 10000)
      );
    };

    return {
      init
    };
  });
