/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.helpers').directive('fileDnd', function($rootScope) {
  return {
    scope: {
      onFileDropped: '&'
    },
    link(scope, el) {
      el.on('dragover', function(event) {
        event.preventDefault();
      });

      el.on('dragend', function(event) {
        event.preventDefault();
      });

      el.on('drop', function(event) {
        event.preventDefault();
        event.stopPropagation();
        const file = event.originalEvent.dataTransfer.files[0];
        if (!file) {
          return false;
        }

        scope.onFileDropped({ file });
        $rootScope.$apply();
        return undefined;
      });
    }
  };
});
