/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('adminSectionCtrl', function($scope, $state) {
    $scope.focused = {};

    $scope.setSubmitted = function(value) {
      $scope.submitted = angular.isDefined(value) ? value : true;
    };
    // Go to clicked tab
    $scope.goToTab = function(tab) {
      return Array.isArray(tab.sref)
        ? $state.go(tab.sref[0], tab.go)
        : $state.go(tab.sref, tab.go);
    };
  });
