/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { CKEDITOR, angular, _ } = window;

angular
  .module('wcm.sites')
  .controller('siteSystemOverridesCtrl', function(
    $scope,
    $rootScope,
    $timeout,
    $state,
    $modal,
    sitesFactory,
    session,
    localizationFactory,
    navigationPanel,
    breadcrumbs,
    i18nFactory,
    helpers
  ) {
    $scope.settings = { overrides: {}, isEnabled: false };
    $scope.session = session;
    $scope.settings.overrides = {};
    $scope.settings.overrides.termsOverrides = false;
    $scope.defaultCampaigns = ['A', 'B', 'AB'];
    $scope.editMode = false;

    const id = +$state.params.id || 0;
    let settingsCancel;
    let site;
    $scope.siteId = id;
    $scope.options = {
      headerFonts: [
        'Open Sans Light',
        'Open Sans Regular',
        'Open Sans Semibold'
      ]
    };
    $scope.editorOptions = {
      toolbar: 'terms',
      toolbar_terms: [
        {
          name: 'basicstyles',
          items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']
        },
        {
          name: 'paragraph',
          items: [
            'NumberedList',
            'BulletedList',
            '-',
            'Outdent',
            'Indent',
            '-',
            'Blockquote'
          ]
        },
        { name: 'styles', items: ['Styles', 'Format'] }
      ],
      disableNativeSpellChecker: false,
      uiColor: '#DFE0DF',
      height: '100%',
      width: '495px', // increased size to fit ckeditor's toolbar on one line
      resize_enabled: false,
      customConfig: ''
    };

    $scope.siteDataReady().then(function() {
      site = $scope.site;
      $scope.setBreadcrumbs('details', site.id, site.name);
      $scope.getDefaultOverrides();
    });

    $scope.getDefaultOverrides = function() {
      sitesFactory
        .getSettingsOverrides(id)
        .success(function(data) {
          if ($scope.settings.isEnabled) {
            // eslint-disable-next-line no-param-reassign
            data.isEnabled = true;
          }
          $scope.settings = data;

          localizationFactory.getClientLocalizations().then(function(data) {
            $scope.options.langs = data.filter(function(item) {
              return item.isAvailable;
            });
            $scope.settings.overrides.defaultClientLanguageName = _.find(
              $scope.options.langs,
              lng => lng.id === $scope.settings.overrides.defaultSystemLanguage
            ).title;
            settingsCancel = angular.copy($scope.settings);
          });
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.save = function(settings) {
      if ($scope.overridesForm.$invalid) {
        return true;
      }
      if (!settings.isEnabled) {
        // return old settings and disable overrides
        $scope.settings = angular.copy(settingsCancel);
        $scope.settings.isEnabled = false;
        // eslint-disable-next-line no-param-reassign
        settings = angular.copy($scope.settings);
      }
      // eslint-disable-next-line no-param-reassign
      settings.id = id;
      return sitesFactory
        .saveSettingsOverrides(settings)
        .success(function() {
          $scope.editMode = false;
          settingsCancel = angular.copy(settings);
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.setNewLang = function() {
      $scope.settings.overrides.defaultClientLanguageName = _.find(
        $scope.options.langs,
        lng => lng.id === $scope.settings.overrides.defaultSystemLanguage
      ).title;
    };

    $scope.edit = function() {
      $scope.editMode = true;
      $scope.readOnlyHandler();
      $scope.setBreadcrumbs('modify', $scope.site.id, $scope.site.name);
    };

    $scope.$on('$stateChangeStart', function(event, toState) {
      if ($scope.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          $scope.readOnlyHandler();
          $state.go(toState);
        });
      }
    });

    function cancelConfirmModal() {
      const title = i18nFactory.translate(
        'sites.modals.cancelConfirmation.title'
      );
      const message = i18nFactory.translate(
        'sites.modals.cancelConfirmation.texts.editOverrides'
      );

      return helpers.confirmationModal(title, message).result;
    }

    $scope.cancel = function() {
      $scope.readOnlyHandler();

      if ($scope.editMode) {
        cancelConfirmModal().then(function() {
          $scope.settings = angular.copy(settingsCancel);
          $scope.editMode = false;
          $scope.setBreadcrumbs('details', $scope.site.id, $scope.site.name);
        });
      } else {
        $state.go('section.sites.sitesList');
      }
    };
    $scope.readOnlyHandler = function() {
      if (!angular.isDefined(CKEDITOR.instances.terms)) return;
      let readOnly = true;
      if ($scope.editMode && $scope.settings.overrides.termsOverrides)
        readOnly = false;
      CKEDITOR.instances.terms.setReadOnly(readOnly);
    };
    $scope.$watch('settings.overrides.termsOverrides', $scope.readOnlyHandler);

    navigationPanel.setActionButtons([
      {
        title: () => 'sites.buttons.edit',
        isDisabled: () => false,
        isShown: () =>
          !$scope.editMode && $scope.site && $scope.site.isEditable,
        clickHandler: () => {
          $scope.edit();
        }
      },
      {
        title: () => 'sites.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.submitted = true;
          $scope.save($scope.settings);
        }
      },
      {
        title: () => `sites.buttons.${$scope.editMode ? 'cancel' : 'close'}`,
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);
  });
