/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.admin')
  .controller('clientListCtrl', function(
    $scope,
    breadcrumbs,
    navigationPanel,
    filtersFactory,
    clientManagementFactory,
    helpers,
    i18nFactory
  ) {
    breadcrumbs.set({
      path: [
        {
          name: 'admin'
        },
        {
          name: 'clientList'
        }
      ]
    });
    $scope.setTabs([]);

    $scope.filters = filtersFactory.initFilters({
      label: 'AccessType',
      options: {
        All: '',
        FULL: 'FULL',
        LIMITED: 'LIMITED',
        WINDOW_LIMITED: 'WINDOW_LIMITED',
        SOCIAL_NETWORKS: 'SOCIAL_NETWORKS'
      }
    });

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(data) {
      $scope.totalItems = data.itemsTotal;
    };

    $scope.getPage = function getPage() {
      $scope.setBusy(true);
      const requestData = {
        filterOffset: ($scope.currentPage - 1) * $scope.itemsOnPage,
        filterItemsNumber: $scope.itemsOnPage
      };
      clientManagementFactory
        .getList(requestData)
        .success(function(data) {
          $scope.visitorsList = data.visitors || [];
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.onListUpdate = $scope.getPage;

    $scope.goToView = function(id, title) {
      $scope.search.value = title;
      $scope.applyFilters(true);
    };
    $scope.refresh = function() {
      clientManagementFactory.visitorGetCurrent().success(function() {
        $scope.applyFilters(true);
      });
    };
    $scope.kickVisitor = function(visitorMac) {
      const modalTitle = i18nFactory.translate(
        'admin.modals.clientManagement.title'
      );
      const modalText = `${i18nFactory.translate(
        'admin.modals.clientManagement.text'
      )} ${visitorMac}?`;
      helpers.confirmationModal(modalTitle, modalText).result.then(function() {
        clientManagementFactory.kickVisitor(visitorMac).success(function() {
          $scope.applyFilters(true);
        });
      });
    };

    function init() {
      return $scope.refresh();
    }

    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.refresh',
        isShown: () => true,
        clickHandler: () => {
          $scope.refresh();
        }
      }
    ]);
  });
