/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.helpers')
  .directive('recalculateMenu', function($timeout) {
    return {
      restrict: 'A',
      link(scope, el) {
        const ul = el.find('ul')[0];
        scope.$watch(
          function() {
            return el.hasClass('active') && ul;
          },
          function(active) {
            $timeout(function() {
              ul.style.height = active ? '' : '0px';
            }, 0);
          }
        );
      }
    };
  });
