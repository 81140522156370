/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const DAY_IN_MS = 24 * 3600 * 1000;
const NUMBER_OF_RANGES = 6;
const DETAILED_VIEW_THRESHOLD = 42; // maximum number of days to show in detailed view
const SMALL_BAR_SIZE = 3; // bar line size in % to consider as small

angular
  .module('wcm.analytics2')
  .directive('analyticsTimeline', function($filter, $state, $window) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        startDate: '=?',
        endDate: '=?',
        items: '=?'
      },
      templateUrl: 'app/core/analytics2/timeline.html',
      link(scope, el) {
        // eslint-disable-next-line no-multi-assign
        const state = (scope.state = {});

        scope.$watchGroup(['startDate', 'endDate'], function(newVals) {
          const startDate = newVals[0];
          const endDate = newVals[1];
          if (!startDate || !endDate) return false;
          let daysCount = diffInDays(startDate, endDate);
          let viewEndDate =
            daysCount >= DETAILED_VIEW_THRESHOLD ||
            daysCount + 14 < DETAILED_VIEW_THRESHOLD
              ? endDate
              : endDate + DAY_IN_MS * (DETAILED_VIEW_THRESHOLD - daysCount);
          // add one full day - 1 ms since we actually get end date as start of the day
          // eslint-disable-next-line no-multi-assign
          viewEndDate = scope.viewEndDate = viewEndDate + DAY_IN_MS - 1;
          daysCount = diffInDays(startDate, viewEndDate);
          state.isDetailedView = daysCount <= DETAILED_VIEW_THRESHOLD;
          state.ranges = state.isDetailedView
            ? splitByWeeks(startDate, viewEndDate)
            : splitByEqualRanges(NUMBER_OF_RANGES, startDate, viewEndDate);

          state.cellWidth = getCellWidth(el.innerWidth(), daysCount);
          return undefined;
        });

        scope.handlers = {
          positionImpactItem(periodStart, periodEnd, itemStart, itemEnd) {
            const periodLength = periodEnd - periodStart;
            const left = ((itemStart - periodStart) * 100) / periodLength;
            const width = itemEnd
              ? ((itemEnd - itemStart) * 100) / periodLength
              : 100;
            const normalizedWidth = left < 0 && itemEnd ? width + left : width;
            let styles = {
              left: `${left > 0 ? left : 0}%`,
              width: `${normalizedWidth}%`
            };
            if (normalizedWidth < SMALL_BAR_SIZE) {
              styles = angular.extend(styles, {
                width: 'auto',
                background: 'transparent',
                'border-width': '1px',
                'border-style': 'dashed',
                paddingRight: '5px'
              });
            }

            return styles;
          },
          goToView(item) {
            const url = $state.href('section.campaigns.view.details', {
              id: item.id
            });
            $window.open(url, '_blank');
          },
          getTitle(item) {
            const dateFilter = $filter('date');
            let period = `Period: ${dateFilter(
              item.startDate,
              'dd.MM.yyyy'
            )} - `;
            period += item.endDate
              ? dateFilter(item.endDate, 'dd.MM.yyyy')
              : '∞';
            const numberOfViews = `Number of views: ${
              angular.isDefined(item.numberOfViews)
                ? item.numberOfViews
                : 'N/A'
            }`;

            return [item.name, period, numberOfViews].join('\n');
          }
        };
      }
    };
  });

function diffInDays(d1, d2) {
  return parseInt((d2 - d1) / DAY_IN_MS, 10);
}

function getCellWidth(containerWidth, cellCount) {
  return containerWidth / cellCount;
}

function splitByWeeks(d1, d2) {
  const weeks = [];
  let week = [];
  while (d1 <= d2) {
    week.push(d1);
    // eslint-disable-next-line no-param-reassign
    d1 += DAY_IN_MS;
    if (new Date(d1).getDay() === 1) {
      weeks.push(week);
      week = [];
    }
  }
  if (week.length) {
    // if last week isn't empty, add it to the list
    weeks.push(week);
  }
  const lastWeek = weeks[weeks.length - 1];
  lastWeek[lastWeek.length - 1] = d2;
  return weeks;
}

function splitByEqualRanges(numberOfRanges, d1, d2) {
  const daysInRange = Math.ceil(diffInDays(d1, d2) / numberOfRanges);
  const ranges = [];
  for (let i = 0; i < numberOfRanges; i++) {
    ranges.push([
      d1 + i * daysInRange * DAY_IN_MS,
      d1 + (i * daysInRange + daysInRange - 1) * DAY_IN_MS
    ]);
  }
  ranges[ranges.length - 1][1] = d2;
  return ranges;
}
