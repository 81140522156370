/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;
const DAY_IN_MS = 24 * 60 * 60 * 1000;

angular
  .module('wcm.analytics2')
  .directive('analyticsTimeFilters', function(analyticsFactory2) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        minPeriod: '=',
        maxPeriod: '=',
        periodLimit: '=',
        isErrorInPreset: '=?',
        isDisabled: '=?',
        isAllTime: '@',
        maxPeriodIsLast: '@',
        availableMinDate: '=',
        interactionTypes: '=?',
        interactionFilters: '=?',
        noApply: '=?'
      },
      require: 'ngModel',
      templateUrl: 'app/core/analytics2/analyticsTimeFilters.html',
      controller($scope) {
        $scope.syncProxy = function() {
          $scope.sync();
        };
      },
      link(scope, el, attrs, ngCtrl) {
        scope.state = analyticsFactory2.defaultTimeFilters;
        // eslint-disable-next-line no-param-reassign
        ngCtrl.$render = function() {
          scope.state = angular.extend(
            {},
            scope.state,
            ngCtrl.$viewValue
          );
        };

        scope.$watch(
          function() {
            return ngCtrl.$modelValue;
          },
          ngCtrl.$render,
          true
        );

        let prevState;
        scope.allTimeHandler = function() {
          const state = scope.state;
          if (state.allTime) {
            prevState = angular.extend(
              {},
              window._.pick(state, [
                'startPeriod',
                'endPeriod',
                'startTimeFrame',
                'endTimeFrame',
                'daysOfWeek',
                'interactionType'
              ])
            );
            angular.extend(state, {
              startPeriod: moment(scope.minPeriod).startOf('day'),
              endPeriod: (scope.maxPeriod
                ? moment(scope.maxPeriod)
                : moment()
              ).startOf('day'),
              startTimeFrame: 0,
              endTimeFrame: DAY_IN_MS,
              daysOfWeek: [1, 2, 3, 4, 5, 6, 7]
            });
          } else {
            scope.state = angular.extend(scope.state, prevState);
            scope.state.allTime = false;
          }
          scope.sync();
        };

        scope.selectMaxHandler = function() {
          const state = scope.state;
          if (state.selectMax) {
            prevState = angular.extend(
              {},
              window._.pick(state, ['startPeriod', 'endPeriod'])
            );
            angular.extend(state, {
              startPeriod: analyticsFactory2.getAnalyticsMinPeriod(
                moment(scope.availableMinDate).startOf('day'),
                moment().startOf('day'),
                scope.periodLimit,
                true
              ),
              endPeriod: (scope.maxPeriod
                ? moment(scope.maxPeriod)
                : moment()
              ).startOf('day')
            });
          } else {
            scope.state = angular.extend(scope.state, prevState);
            scope.state.selectMax = false;
          }
          scope.sync();
        };

        scope.validators = {
          startEnd(start, end) {
            return start <= end;
          },
          periodLimit(start, end, limit) {
            return (
              !limit ||
              (limit &&
                start >=
                  analyticsFactory2.getAnalyticsMinPeriod(
                    start,
                    end,
                    limit,
                    scope.state.selectMax
                  ))
            );
          },
          currentDay(end, max, maxIsLast) {
            return (
              !maxIsLast ||
              moment(end).startOf('day') <= moment(max).startOf('day')
            );
          },
          availableMinDate(start) {
            return (
              !scope.availableMinDate ||
              moment(start) >= moment(scope.availableMinDate).startOf('day')
            );
          },
          timeFrames(start, end) {
            return start < end;
          }
        };

        scope.checkFilterChange = () =>
          isFiltersChanged(scope.state, ngCtrl.$viewValue) &&
          scope.validators.timeFrames(
            scope.state.startTimeFrame,
            scope.state.endTimeFrame
          );

        scope.runApplyFilters = () => {
          if (scope.checkFilterChange()) {
            return ngCtrl.$setViewValue(angular.copy(scope.state));
          }
          return false;
        };

        scope.sync = function() {
          if (scope.checkFilterChange() && scope.noApply) {
            return ngCtrl.$setViewValue(angular.copy(scope.state));
          }
          return scope.checkFilterChange();
        };

        scope.options = {
          timeFramesValues: analyticsFactory2.getTimeFrameValues()
        };

        scope.$on('hidePicker', function() {
          el.find('.analytics-tf_period-it').blur();
          scope.sync();
        });

        scope.$watch('state.startPeriod', function() {
          scope.sync();
        });

        scope.$watch('timeFiltersForm.$invalid', function(invalid) {
          scope.isErrorInPreset = invalid;
        });

        // Check if time filters really changed
        function isFiltersChanged(val, old) {
          return analyticsFactory2.isTimeFiltersChanged(
            val,
            old,
            scope.isAllTime
          );
        }
      }
    };
  });
