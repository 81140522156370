const { angular } = window;

angular.module('wcm.core').factory('hardlinks', function() {
  let registeredHandlers = [];

  function registerHardlinkHandler(testFn, handler) {
    const entry = {
      testFn,
      handlerFn: handler || window._.noop
    };

    registeredHandlers = registeredHandlers.concat(entry);

    return function unregisterHardlinkHandler() {
      registeredHandlers = registeredHandlers.filter(function(item) {
        return item !== entry;
      });
    };
  }

  function isHardlinkState(state, params) {
    return registeredHandlers.some(function(entry) {
      return entry.testFn(state, params);
    });
  }

  function handleHardlink(state, params) {
    if (!isHardlinkState(state, params)) {
      return console.warn('Provided state is not a hardlink route');
    }
    const handler = window._.find(registeredHandlers, function(entry) {
      return entry.testFn(state, params);
    });
    return handler.handlerFn(state, params);
  }

  return {
    registerHardlinkHandler,
    isHardlinkState,
    handleHardlink
  };
});
