const { angular } = window;

angular
  .module('wcm.analytics2')
  .factory('wasRequestsErrorHandlingInterceptor', function(
    $q,
    $rootScope,
    errorHelpers,
    errorHandlingInterceptor,
    REPORTING_CONFIG,
    MONITORING_CONFIG,
    ANALYTICS_PRESETS_CONFIG,
    ANALYTICS_REQUESTS_CONFIG
  ) {
    const analyticsRequests = window._.values(ANALYTICS_PRESETS_CONFIG).concat(
      window._.values(ANALYTICS_REQUESTS_CONFIG)
    );

    const reportsRequests = window._.values(REPORTING_CONFIG);

    const monitoringRequests = window._.values(MONITORING_CONFIG);

    // response function in case of error occurred
    const responseError = function(rejection) {
      const checkedStatus = rejection.status >= 400 && rejection.status <= 599;
      if (checkedStatus) {
        if (
          errorHelpers.isContainAction(rejection.config.url, analyticsRequests)
        ) {
          // increment analytics errors counter
          // eslint-disable-next-line no-param-reassign
          errorHandlingInterceptor.errorCounters.analytics++;
        } else if (
          errorHelpers.isContainAction(rejection.config.url, reportsRequests)
        ) {
          // increment reporting errors counter
          // eslint-disable-next-line no-param-reassign
          errorHandlingInterceptor.errorCounters.reporting++;
        } else if (
          errorHelpers.isContainAction(rejection.config.url, monitoringRequests)
        ) {
          // increment monitoring errors counter
          // eslint-disable-next-line no-param-reassign
          errorHandlingInterceptor.errorCounters.monitoring++;
        }
      }
      return $q.reject(rejection);
    };

    // reset all error counters on change route
    $rootScope.$on('$stateChangeStart', function() {
      Object.keys(errorHandlingInterceptor.errorCounters).forEach(function(
        key
      ) {
        // eslint-disable-next-line no-param-reassign
        errorHandlingInterceptor.errorCounters[key] = 0;
      });
    });

    return {
      responseError
    };
  });
