const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingViewSitesCtrl', function(
    $q,
    $scope,
    $state,
    $rootScope,
    $controller,
    reportingFactory,
    analyticsFactory2,
    analyticsRequestsFactory,
    analyticsPresetsFactory
  ) {
    $controller('reportingViewAnalyticsCtrl', { $scope });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      sites: []
    }));
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      handlersType: 'sites',
      manageBlocks: {
        sitesFilter: [],
        dataByComparisonSites: {}
      }
    }));
    state.blocksState = angular.copy(
      analyticsPresetsFactory.getSystemPreset(state.handlersType).data
        .analyticBlocks
    );

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      prepareDataForBlock(name, data) {
        let filters = null;
        let handler;
        if (name === 'dataByCitiesAndCountriesSitesSiteLocation') {
          handler = 'getDataByCitiesAndCountriesSites';
          filters = { dataFrom: 'siteLocation' };
        } else if (name === 'dataByCitiesAndCountriesSitesSocialNetworks') {
          handler = 'getDataByCitiesAndCountriesSites';
          filters = { dataFrom: 'socialNetworks' };
          state.chartsData.dataByCitiesAndCountriesSitesSocialNetworks = data;
        } else {
          handler = handlers.getRequestHandlerByBlockName(name);
        }
        return handlers
          .getDataForBlock(handler, filters, name, data)
          .then(function(data) {
            if (name === 'dataByCitiesAndCountriesSitesSocialNetworks') {
              state.chartsData.dataByCitiesAndCountriesSites = data;
            }
          });
      },
      prepareChartsData(report) {
        $scope.handlers.prepareChartsDataDefault(report);
        state.manageBlocks.dataByComparisonSites = {
          values1: state.settings.collapsedValues.values1 || {},
          values2: state.settings.collapsedValues.values2 || {}
        };
        state.manageBlocks.sitesFilter = options.sites.map(function(cam) {
          return cam.name;
        });
      },
      refreshCurrentReport() {
        return $scope.handlers.refreshReport(state).then(function() {
          $scope.init();
        });
      },
      init: function init() {
        const noId = $scope.parentInit();
        if (!noId) {
          return false;
        }

        if ($rootScope.isHardlink) {
          return handlers.getReportByHardlink($state.params.id);
        }

        if (!handlers.hasViewPermission()) {
          return false;
        }
        if (!$rootScope.isHardlink) {
          return reportingFactory.getSitesReport(state).then(function(res) {
            options.sites = res.data.settings.siteData;
            return res;
          }, handlers.showNoWas);
        }
        return undefined;
      }
    }));
  });
