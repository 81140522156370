/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.admin').constant('MONITORING_CONFIG', {
  servlet: 'monitoring',
  getAvailableValues: 'getAvailableValues',
  visitorApplyFilters: 'visitorApplyFilters',
  getVisitorDetails: 'getVisitorDetails',
  saveVisitorDetails: 'saveVisitorDetails',
  visitorGetList: 'visitorGetList',
  visitorGetInterests: 'getInterests',
  placesApplyFilters: 'placesApplyFilters',
  placesGetList: 'placesGetList',
  trendsGetList: 'trendsGetList',
  trendsApplyFilters: 'trendsApplyFilters',
  getVisitorModificationHistory: 'getVisitorModificationHistory',
  addToNewCollection: 'addToNewCollection',
  addToCollections: 'addToCollections',

  exportVisitorsList: 'exportVisitorsList',
  exportCollectionVisitorsList: 'exportVisitorsList',
  exportVisitorsGeneral: 'exportVisitorsGeneral',
  exportVisitorsPlaces: 'exportVisitorsPlaces',
  exportVisitorsUrls: 'exportVisitorsUrls',
  exportVisitorsKeywords: 'exportVisitorsKeywords',
  exportVisitorsInterests: 'exportVisitorsInterests',

  collectionGetList: 'collectionGetList',
  collectionGetListForVisitor: 'collectionGetListForVisitor',
  collectionGetViewData: 'collectionGetViewData',
  collectionsPerformAction: 'collectionsPerformAction',
  removeFromCollection: 'removeFromCollection',
  collectionAddNotifications: 'collectionAddNotifications',
  getCollectionProps: 'getCollectionProps',
  saveCollectionProps: 'saveCollectionProps',

  commonServlet: 'common',
  analyticsCampaignServlet: 'analyticsCampaign',
  siteServlet: 'sites',
  getOptionsOccupationAll: 'campaignGetOccupation',
  getOptionsInterestsAll: 'campaignGetInterests',
  getOptionsCityAll: 'campaignGetAllCities',
  getOptionsSitesAll: 'getSitesListSimple'
});
