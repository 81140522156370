/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.core').directive('scrollBlock', function($timeout) {
  return {
    restrict: 'AE',
    replace: false,
    link(scope, el) {
      if (el.attr('fixed')) {
        const HEADER_WELCOME_HEIGHT = 52;
        const HEADER_BREADCRUMBS_HEIGHT = 50;
        const HEADER_ANALYTICS_HEIGHT = 65;
        const HEADER_MARGIN_CONTENT_HEIGHT = 30;

        scope.scrollBlockRefresh = [];

        $timeout(function() {
          const w = el.parent().width() - 10; // calculate fixed site bar block width
          const h =
            window.$(window).height() -
            HEADER_WELCOME_HEIGHT -
            HEADER_BREADCRUMBS_HEIGHT -
            HEADER_ANALYTICS_HEIGHT -
            HEADER_MARGIN_CONTENT_HEIGHT * 2; // calculate fixed site bar block height
          // where constants: margins and paddings of menu blocks
          el.width(w);
          el.height(h);

          scope.scrollBlockRefresh = [el.width()];
        }, 1000);
      }
      scope.$watch(
        function() {
          return el.parent().width();
        },
        function(newVal, oldVal) {
          if (newVal !== oldVal) el.width(newVal - 10);
        }
      );
    }
  };
});
