/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const defaultAvatar = require('./defaultAvatar.svg');

angular
  .module('wcm.admin')
  .controller('userDetailsCtrl', function(
    $scope,
    $rootScope,
    $q,
    $modal,
    $state,
    $timeout,
    usersFactory,
    analyticsRequestsFactory,
    countriesFactory,
    helpers,
    APP_CONFIG,
    i18nFactory,
    localStorage,
    breadcrumbs,
    modificationHistory
  ) {
    const baseUserRoles = ['admins', 'clients', 'cms'];

    $scope.options = {
      userRoles: [],
      countries: [],
      cities: []
    };

    $scope.focused = {};
    $scope.forms = {};

    $scope.currentUserId = $state.params.id;

    $scope.defaultAvatarImage = defaultAvatar;
    $scope.state = {
      user: {
        userId: '',
        userRole: '',
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        city: '',
        address: '',
        zipCode: '',
        siteLocalName: false,
        additionalInfo: [],
        avatarImage: $scope.defaultAvatarImage,
        notifications: true
      },
      isProfile: false,
      isNewUser: false,
      noWas: true,
      lastRole: ''
    };
    $scope.relatesToOptions = {
      type: 'campaign',
      parseType: {
        partnercampaign: 'campaign',
        advertisercampaign: 'campaign',
        site: 'site',
        partner: 'partner',
        advertiser: 'advertiser'
      },
      currentObj: {},
      availableValues: {
        campaign: [],
        site: [],
        partner: [],
        advertiser: []
      }
    };

    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        $scope.setBusy(queue.length);
      }, 100);
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getUser: busyImpactor(usersFactory.getUser),

      getAvailableValues() {
        const entitiesPromise = analyticsRequestsFactory
          .getAllEntities('users')
          .then(
            function(data) {
              $scope.relatesToOptions.availableValues.partner = data.partners;
              $scope.relatesToOptions.availableValues.advertiser =
                data.advertisers;
              return true;
            },
            function() {
              return false;
            }
          );

        const campaignsPromise = analyticsRequestsFactory
          .getAllCampaigns('users')
          .then(
            function(data) {
              $scope.relatesToOptions.availableValues.campaign = data.campaigns;
              return true;
            },
            function() {
              return false;
            }
          );

        const sitesPromise = analyticsRequestsFactory.getAllSites('users').then(
          function(data) {
            $scope.relatesToOptions.availableValues.site = data.sites;
            return true;
          },
          function() {
            return false;
          }
        );

        return $q
          .all([campaignsPromise, sitesPromise, entitiesPromise])
          .then(function(res) {
            handlers.setCurrentObj();
            return res;
          });
      },

      setCurrentObj() {
        if ($scope.state.user.relatesTo && $scope.state.user.relatesTo.id) {
          $scope.relatesToOptions.availableValues[
            $scope.relatesToOptions.type
          ].forEach(function(item) {
            if (item.id === $scope.state.user.relatesTo.id) {
              $scope.relatesToOptions.currentObj = item;
            }
          });
        }
      },

      setRelatesType(type) {
        $scope.relatesToOptions.type = type;
      }
    });

    $scope.init = function(userId, isProfilePage) {
      $scope.state.isProfilePage = isProfilePage;
      $scope.options.userRoles = baseUserRoles.slice();
      if (
        shouldGhostsBeAvailableForUser($rootScope.currentUser, isProfilePage)
      ) {
        $scope.options.userRoles = $scope.options.userRoles.concat(['ghosts']);
      }

      if (!userId) {
        // userCreation mode
        $scope.state.isNewUser = true;
        $scope.uiState.editMode = true;
        $scope.setBreadcrumbs(isProfilePage, true);
        $scope.setTabs($scope.state.user, true);
      } else {
        handlers.getUser(userId).then(user => {
          $scope.state.user = user;
          $scope.state.lastRole = user.userRole;

          if (user.relatesTo && user.relatesTo.type) {
            $scope.relatesToOptions.type =
              $scope.relatesToOptions.parseType[user.relatesTo.type];
          }
          $scope.relatesToOptions.currentObj =
            user.relatesTo || $scope.relatesToOptions.currentObj;

          $scope.setBreadcrumbs(isProfilePage, false, userId);
          $scope.setTabs($scope.state.user, false);
          return user;
        });
        if (!isProfilePage) {
          modificationHistory.get('person', userId).then(function(history) {
            $scope.history = history;
          });
        }
      }
      if (!isProfilePage) {
        handlers.getAvailableValues().then(function(res) {
          $scope.state.noWas = res.some(function(i) {
            return !i;
          });
        });
      }

      countriesFactory.getCountries().then(function(data) {
        angular.copy(data, $scope.options.countries);
      });
    };

    $scope.hasAdminPermissions = function(role) {
      const adminRoles = ['administrator', 'ghost'];
      return !!~adminRoles.indexOf(role.toLowerCase());
    };

    $scope.$watch('state.user.country', function(newVal, oldVal) {
      if (!newVal) {
        return false;
      }
      if (oldVal) {
        $scope.state.user.city = '';
      }

      $scope.options.cities = [];
      return countriesFactory.getCitiesForCountry(newVal).then(function(data) {
        angular.copy(data, $scope.options.cities);
      });
    });

    /**
     * This is a bit tricky getter for group select input.
     * As we don't always have 'ghosts' as an option we need to
     * simulate chosen value as a placeholder instead.
     *
     * @param  {string} userRole - User role of user being edited
     * @return {string}          - Placeholder value
     */
    $scope.getGroupPlaceholder = function(userRole) {
      return i18nFactory.translate(
        userRole === 'ghosts'
          ? 'admin.userManagement.groups.ghosts'
          : 'admin.userManagement.labels.groupPlaceholder'
      );
    };

    const isGroupDowngraded = function(currentGroup, nextGroup) {
      const groupsToCheck = ['admins', 'ghosts'];

      return ~groupsToCheck.indexOf(currentGroup) && nextGroup !== currentGroup;
    };

    const saveUser = busyImpactor(function saveUser(user) {
      /* eslint-disable no-param-reassign */
      user.additionalInfo = user.additionalInfo || [];
      for (let i = 0; i < user.additionalInfo.length; i++) {
        if (
          !user.additionalInfo[i].key ||
          user.additionalInfo[i].key === '' ||
          !user.additionalInfo[i].value
        ) {
          user.additionalInfo.splice(i, 1);
        }
      }
      user.isNew = $scope.state.isNewUser;

      // Set Relates To Object
      user.relatesTo = {};
      if ($scope.relatesToOptions && $scope.relatesToOptions.currentObj.id) {
        user.relatesTo.id = $scope.relatesToOptions.currentObj.id;
        user.relatesTo.name = $scope.relatesToOptions.currentObj.name;
        user.relatesTo.type = $scope.relatesToOptions.type;
        if ($scope.relatesToOptions.type === 'campaign') {
          user.relatesTo.type = `${$scope.relatesToOptions.currentObj.type.toLowerCase()}campaign`;
        }
      }
      return usersFactory.saveUser(user).success(function() {
        $scope.sectionForm.$setPristine();
        if (!$scope.state.isNewUser) {
          $scope.uiState.editMode = false;
          if (user.userId === $scope.currentUser.userId) {
            $scope.currentUser.avatarImage = user.avatarImage;
            localStorage.set('avatarImage', user.avatarImage);
          }
        } else {
          $scope.uiState.editMode = false;
          $scope.state.isNewUser = false;
          $state.go('section.admin.users.userList');
        }
      });
    });

    $scope.submit = function(user) {
      $scope.submitted = true;
      if ($scope.sectionForm.$invalid && !onlyAvatarError()) return true;

      if (
        $scope.sectionForm.$error.dimension ||
        $scope.sectionForm.$error.extension
      ) {
        user.avatarImage = $scope.defaultAvatarImage;
        $scope.forms.userDataForm.userAvatar.$setPristine();
      }

      // eslint-disable-next-line no-param-reassign
      if (!user.siteLocalName) user.siteLocalName = false;

      if (
        $scope.currentUser.userId === user.userId &&
        isGroupDowngraded($scope.state.lastRole, user.userRole)
      ) {
        const localizedOldGroupName = i18nFactory.translate(
          `admin.userManagement.groups.${$scope.state.lastRole}`
        );
        const localizedNewGroupName = i18nFactory.translate(
          `admin.userManagement.groups.${user.userRole}`
        );
        helpers
          .confirmationModal(
            '',
            'admin.modals.userActions.texts.changePermissions',
            true,
            [localizedOldGroupName, localizedNewGroupName]
          )
          .result.then(saveUser.bind(null, user));
      } else {
        saveUser(user);
      }
      return undefined;
    };

    $scope.addAdditionalField = function() {
      $scope.state.user.additionalInfo.push({});
    };

    const cancelConfirmModal = function() {
      const title = i18nFactory.translate(
        'admin.userManagement.modals.cancelConfirmation.title'
      );
      const message = i18nFactory.translate(
        `admin.userManagement.modals.cancelConfirmation.texts.${
          $scope.newUser === true ? 'create' : 'edit'
        }`
      );
      return helpers.confirmationModal(title, message).result;
    };

    function onlyAvatarError() {
      const errors = Object.keys($scope.sectionForm.$error);
      return (
        (errors[0] === 'dimension' || errors[0] === 'extension') &&
        errors.length === 1
      );
    }

    $scope.actionHandlers = $scope.actionHandlers || {};
    $scope.actionHandlers.cancel = function cancel() {
      if ($scope.uiState.editMode && !$scope.state.isNewUser) {
        cancelConfirmModal().then(function() {
          $scope.init(
            $scope.state.user.userId,
            !!~$state.current.name.indexOf('profile')
          );
          $scope.uiState.editMode = false;
        });
      } else if ($state.current.name === 'profile') {
        $state.go(APP_CONFIG.defaultState);
      } else {
        $state.go('section.admin.users.userList');
      }
    };

    $scope.actionHandlers.save = function() {
      return $scope.submit($scope.state.user);
    };

    $scope.$on('$stateChangeStart', function(event, toState) {
      if ($scope.uiState.editMode && toState.name !== 'login.signIn') {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.uiState.editMode = false;
          $state.go(toState);
        });
      }
    });

    $scope.$watch('uiState.editMode', function(val) {
      if ($scope.state.user && $scope.state.user.userId) {
        $scope.setTabs($scope.state.user, val);
      }
    });

    function shouldGhostsBeAvailableForUser(userObj = {}, isProfilePage) {
      return (
        userObj.userId === 'Admin' ||
        (isProfilePage && userObj.userRole === 'GHOST')
      );
    }

    $scope.init(
      ~$state.current.name.indexOf('profile')
        ? $scope.currentUser.userId
        : $state.params.id,
      !!~$state.current.name.indexOf('profile')
    );
  });
