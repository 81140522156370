/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('vendorSettingsCtrl', function(
    $scope,
    $state,
    vendorSettingsFactory,
    helpers,
    breadcrumbs,
    navigationPanel
  ) {
    $scope.state = {
      editMode: false
    };

    navigationPanel.setTabs([]);
    $scope.save = function() {
      $scope.setBusy(true);
      vendorSettingsFactory
        .saveVendorSettings($scope.vendors)
        .success(function() {
          $scope.state.editMode = false;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.cancel = function() {
      cancelConfirmModal().then(init);
    };

    function cancelConfirmModal() {
      return helpers.confirmationModal(
        '',
        'admin.vendorSettings.labels.cancelConfirmation',
        true
      ).result;
    }

    function init() {
      breadcrumbs.set({
        path: [{ name: 'admin' }, { name: 'vendorSettings' }]
      });
      $scope.state.editMode = false;
      $scope.setBusy(true);
      vendorSettingsFactory
        .getVendorSettings()
        .success(function(data) {
          $scope.vendors = data.vendors;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    }

    $scope.$on('$stateChangeStart', function(event, toState) {
      if ($scope.state.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.state.editMode = false;
          $state.go(toState);
        });
      }
    });
    // init on state load
    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => !$scope.state.editMode,
        clickHandler: () => {
          $scope.state.editMode = true;
        }
      },
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.state.editMode,
        clickHandler: () => {
          $scope.save();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.state.editMode,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);
  });
