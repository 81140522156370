const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingViewGeneralCtrl', function(
    $q,
    $scope,
    $state,
    $controller,
    $rootScope,
    reportingFactory,
    analyticsFactory2,
    navigationPanel,
    analyticsPresetsFactory
  ) {
    $controller('reportingViewAnalyticsCtrl', { $scope });

    $scope.options = angular.extend($scope.options, {
      blocks: analyticsFactory2.exportPresets.general
    });
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      handlersType: 'general',
      manageBlocks: {
        amountOfViewsAndVisitors: {}
      }
    }));
    state.blocksState = angular.copy(
      analyticsPresetsFactory.getSystemPreset(state.handlersType).data
        .analyticBlocks
    );

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      prepareChartsData(report) {
        $scope.handlers.prepareChartsDataDefault(report, state.analyticsType);
        state.manageBlocks.amountOfViewsAndVisitors = {
          sites: state.settings.collapsedValues.sites || {},
          cities: state.settings.cities || []
        };
      },
      refreshCurrentReport() {
        return $scope.handlers.refreshReport(state).then(function() {
          $scope.init();
        });
      },
      init: function init() {
        const noId = $scope.parentInit();
        if (!noId) {
          return false;
        }

        if ($rootScope.isHardlink) {
          return handlers.getReportByHardlink($state.params.id);
        }

        if (!handlers.hasViewPermission()) {
          return false;
        }
        if (!$rootScope.isHardlink) {
          return reportingFactory.getGeneralReport(state);
        }
        return undefined;
      }
    }));
  });
