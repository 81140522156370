import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import * as themeApi from './theme.api';

const ThemeContext = createContext();

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState({});

  useEffect(() => {
    themeApi.getTheme().then(res => {
      setTheme(res.theme);
    });
  }, []);

  const themeValue = useMemo(
    () => ({
      theme,
      updateTheme(themeOverride) {
        return setTheme({ ...theme, ...themeOverride });
      }
    }),
    [theme, setTheme]
  );

  return (
    <ThemeContext.Provider value={themeValue}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node
};

function useTheme() {
  return useContext(ThemeContext);
}

export { ThemeProvider, useTheme };
