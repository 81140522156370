/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.widgets')
  .controller('WidgetEditorController', WidgetEditorController);

function WidgetEditorController(
  $scope,
  $state,
  $sce,
  $modal,
  $timeout,
  $q,
  i18nFactory,
  widgetsFactory,
  helpers,
  breadcrumbs,
  navigationPanel
) {
  let changeStateConfirmed = false;
  const ctrl = this;
  let widgetType;
  let editorState;

  const buttonForms = ['buttonForm0', 'buttonForm1'];

  ctrl.getFontScaleStyle = widgetsFactory.getFontScaleStyle;

  ctrl.widgetIcons = require.context('assets/images/widgets', false, /\.png$/);
  /**
   * Selects given item menu
   *
   * @param {string} selectedItem
   */
  ctrl.selectMenuItem = function(selectedItem) {
    ctrl.selectedMenuItem = selectedItem;
  };

  /**
   * Selects given widget item (e.g. header, button)
   *
   * @param {string} selectedItem
   * @param {event} event - Event that caused selection. Used to stop event propagation
   * @returns {*}
   */
  ctrl.selectWidgetItem = function(selectedItem, event) {
    if (!event || event.originalEvent.widgetItemSelected) return false;
    ctrl.selectMenuItem(selectedItem);

    // eslint-disable-next-line no-param-reassign
    event.originalEvent.widgetItemSelected = true;
    return undefined;
  };

  /**
   * Removes given item from widget markup
   *
   * @param {string} itemType - item type to delete (button, image, etc.)
   * @param {object} item - item itself
   */
  ctrl.removeWidgetItem = function(itemType, item) {
    const widget = ctrl.widget;
    switch (itemType) {
      case 'button':
        widget.buttons = widget.buttons.filter(function(btn) {
          return btn !== item;
        });
        break;
      case 'pollImage':
        delete widget.meta.files.pollImage;
        break;

      case 'pollAnswer':
        widget.poll.answers.options = widget.poll.answers.options.filter(
          function(option) {
            return option !== item;
          }
        );
        break;

      case 'question':
        widget.poll.question.i18n = {};
        break;
      default:
        throw new Error(`Unsupported widget item type ${itemType}`);
    }
    const currentSelectedPath = ctrl.selectedMenuItem.split('.');
    const nextSelectedPath = currentSelectedPath.slice(0, -1);
    ctrl.selectMenuItem(nextSelectedPath.join('.'));
  };

  /**
   * Selects current locale
   *
   * @param {object} locale - locale object to select
   */
  ctrl.selectLocale = function(locale) {
    ctrl.selectedLocale = locale;
  };

  /**
   * Adds widget button
   *
   * @param buttonType
   * @returns {boolean}
   */
  ctrl.addButton = function(buttonType) {
    if (ctrl.widget.buttons.length >= 2) return false;
    ctrl.widget.buttons.push(widgetsFactory.createWidgetButton(buttonType));
    return true;
  };

  /**
   * Converts react-like style object to angular-like
   *
   * @param {object} reactStyle
   * @returns {*}
   */
  ctrl.getAngularStyle = function(reactStyle) {
    if (!angular.isObject(reactStyle)) return reactStyle;
    return widgetsFactory.convertReactStylesToAngular(reactStyle);
  };

  /**
   * Uploads widget file (banner, slide, background image, etc.) to server, performs its
   * validation and store it in widget markup.
   *
   * @param {string} type - File type
   * @param {file} file - File BLOB
   * @param {number} [index] - Index of slide being uploaded
   */
  ctrl.addFile = function(type, file, index) {
    const files = ctrl.widget.meta.files;
    const uploadMarker = type + (type === 'slide' ? index : '');
    ctrl.filesErrors[uploadMarker] = {};
    widgetsFactory.validateFile(type, file).then(
      function() {
        ctrl.upload[uploadMarker] = true;
        widgetsFactory
          .uploadFile(type, file)
          .then(function(data) {
            switch (type) {
              case 'slide':
                files.slides = files.slides || [];
                files.slides[index] = {
                  name: file.name,
                  url: data.url
                };
                break;
              default:
                files[type] = {
                  name: file.name,
                  url: data.url,
                  type
                };
                break;
            }
            if (type === 'background') {
              ctrl.widget.backgroundType = 'image';
            }
          })
          .finally(function() {
            ctrl.upload[uploadMarker] = false;
          });
      },
      function(data) {
        ctrl.filesErrors[uploadMarker] = ctrl.filesErrors[type] || {};
        ctrl.filesErrors[uploadMarker][data.validationType] = true;
        // console.log($scope.filesErrors);
      }
    );
  };

  /**
   * Returns text for widget button based on currently selected locale. Fallbacks to default value
   * for the selected locale if no value available
   *
   * @param {object} btn - Button object
   * @returns {string} - Text for button
   */
  ctrl.getTextForButton = function(btn) {
    if (!ctrl.selectedLocale) return '';
    return (
      btn.i18n[ctrl.selectedLocale.id] || ctrl.selectedLocale.elements[btn.type]
    );
  };

  /**
   * Returns background CSS param for widget. Can be either background image or color
   *
   * @returns {string} - CSS string suitable for background CSS property
   */
  ctrl.getWidgetBackground = function() {
    const widget = ctrl.widget;
    if (!widget || !widget.meta) return false;
    const files = ctrl.widget.meta.files;

    if (widget.backgroundType === 'image' && files.background) {
      return `url(${files.background.url})`;
    }
    if (widget.backgroundType === 'color' && widget.backgroundColor) {
      return widget.backgroundColor;
    }
    return '';
  };

  ctrl.buttonDragListener = function(event) {
    if (ctrl.selectedMenuItem === `buttons.${event.source.index}`) {
      ctrl.selectMenuItem(`buttons.${event.dest.index}`);
    } else {
      ctrl.selectMenuItem(`buttons.${event.source.index}`);
    }
  };

  ctrl.dndListeners = {
    accept(sourceItemHandleScope, destSortableScope) {
      return (
        sourceItemHandleScope.itemScope.sortableScope.$id ===
        destSortableScope.$id
      );
    }
  };
  ctrl.dndButtonsListeners = angular.extend({}, ctrl.dndListeners, {
    orderChanged(event) {
      ctrl.buttonDragListener(event);
    }
  });

  /**
   * Saves widget preview info and navigates to preview page
   */
  ctrl.preview = function() {
    ctrl.submitted = true;
    if (ctrl.widgetForm.$invalid) return false;

    const widget = ctrl.widget;

    return widgetsFactory.savePreview(widget).then(function(previewParams) {
      widgetsFactory.setPreviewData(previewParams, widget);
      $state.go('section.widgets.preview.default', {
        id: 0,
        lang: ctrl.selectedLocale.id
      });
    });
  };

  /**
   * Saves custom widget on server and navigates to widget list
   *
   * @returns {boolean}
   */
  ctrl.saveCustomWidget = function() {
    ctrl.submitted = true;
    changeStateConfirmed = true;
    if (ctrl.widgetForm.$invalid) return false;
    return widgetsFactory.saveCustomWidget(ctrl.widget).then(function() {
      $state.go('section.widgets.widgetsList');
    });
  };

  ctrl.cancel = function() {
    if (editorState === 'edit') {
      $state.go('section.widgets.view.default', { id: $state.params.id });
    } else {
      $state.go('section.widgets.widgetsList');
    }
  };

  /** * slider specific methods ** */
  /**
   * Adds slide placeholder to widget markup
   */
  ctrl.addSlide = function() {
    const files = ctrl.widget.meta.files;
    files.slides.push({});
    ctrl.widget.meta.smallPlaceForSlides.pop();
  };

  /**
   * Removes widget by given index
   * @param {number} index
   */
  ctrl.removeSlide = function(index) {
    const files = ctrl.widget.meta.files;
    if (files.slides.length > 2) {
      ctrl.widget.meta.smallPlaceForSlides.push({});
      files.slides.splice(index, 1);
    }
  };
  /** * END: slider specific methods ** */

  ctrl.validation = {
    isValidBackground() {
      const widget = ctrl.widget;
      if (!widget) return false;
      const files = widget.meta.files || {};

      return (
        (widget.backgroundType === 'image' &&
          files.background &&
          files.background.url) ||
        (widget.backgroundType === 'color' && widget.backgroundColor)
      );
    },
    shouldButtonOptionBeAvailable(btnType) {
      switch (btnType) {
        case 'next':
        case 'submit':
          return !ctrl.isButtonPresent(btnType);
        default:
          return true;
      }
    }
  };

  /**
   * Determines whether button of given type is available in current widget
   *
   * @param {string} btnType
   * @returns {boolean}
   */
  ctrl.isButtonPresent = function(btnType) {
    const widget = ctrl.widget;
    if (!widget) return false;
    const buttons = widget.buttons;
    return buttons.some(function(btn) {
      return btn.type === btnType;
    });
  };

  /**
   * Checks if invalid button present
   *
   */
  ctrl.isInvalidButton = function() {
    return buttonForms.some(function(item) {
      return ctrl.widgetForm[item] && ctrl.widgetForm[item].$invalid;
    });
  };

  /** * poll specific methods ** */
  /**
   * @namespace poll
   */
  ctrl.poll = {
    /**
     * Returns text for poll's question bases on currently selected locale
     *
     * @param {object} question - Question object
     * @returns {string} - Question html string
     */
    getQuestionText(question) {
      if (!ctrl.selectedLocale || !ctrl.options.defaultLocale || !ctrl.widget)
        return '';
      const fallbackLocaleId =
        ctrl.widget.poll.fallbackLocale || ctrl.options.defaultLocale.id;
      return $sce.trustAsHtml(
        question.i18n[ctrl.selectedLocale.id] || question.i18n[fallbackLocaleId]
      );
    },
    /**
     * Opens question editor modal
     */
    showQuestionEditor() {
      const scope = $scope.$new(true);
      // copy only i18n object to avoid deep copy
      const questionCopy = angular.copy(ctrl.widget.poll.question.i18n);
      angular.extend(scope, {
        question: ctrl.widget.poll.question,
        availableLocales: ctrl.options.locales,
        defaultLocale: ctrl.options.defaultLocale
      });

      const modalOptions = {
        windowClass: 'widget-question-modal modal_poll-question',
        templateUrl:
          'app/sections/widgets/editor/pollWidget/widgetQuestionModal.html',
        scope,
        controller($scope) {
          'ngInject';

          $scope.forms = {};

          $scope.submitForm = function() {
            $scope.isSubmitted = true;
            if ($scope.forms.questionForm.$valid) $scope.$close();
          };
        }
      };
      $modal.open(modalOptions).result.catch(function() {
        ctrl.widget.poll.question.i18n = questionCopy;
      });
    },

    /**
     * Adds new answer option, opens answer editor and store resulting answer to widget markup
     */
    addAnswer() {
      const answer = widgetsFactory.createPollAnswer(
        ctrl.widget.poll.answers.options
      );
      ctrl.poll.showAnswerEditor(answer, true).then(function() {
        ctrl.widget.poll.answers.options.push(answer);
      });
    },

    /**
     * Determines whether new answer can be added to widget
     *
     * @returns {boolean}
     */
    canAddAnswer() {
      const widget = ctrl.widget;
      if (!widget) return false;
      return ctrl.widget.poll.answers.options.length < 10;
    },

    /**
     * Opens answer editor for given answer
     *
     * @param {object} answer - Answer object to edit
     * @param {boolean} isNewAnswer - Is the answer is newly created
     * @returns {Object}
     */
    showAnswerEditor(answer, isNewAnswer) {
      const scope = $scope.$new(true);
      const answerCopy = angular.copy(answer);

      angular.extend(scope, {
        answer,
        isNewAnswer,
        availableLocales: ctrl.options.locales,
        defaultLocale: ctrl.options.defaultLocale
      });

      const modalOptions = {
        windowClass: 'widget-answer-modal modal_poll-answer',
        templateUrl:
          'app/sections/widgets/editor/pollWidget/widgetAnswerModal.html',
        scope,
        controller($scope) {
          'ngInject';

          $scope.forms = {};

          $scope.submitForm = function() {
            $scope.isSubmitted = true;
            if (answer.type !== 'checkbox') {
              // eslint-disable-next-line no-param-reassign
              answer.label.i18n = {};
            } else {
              // eslint-disable-next-line no-param-reassign
              answer.legend.i18n = {};
              // eslint-disable-next-line no-param-reassign
              answer.placeholder.i18n = {};
            }
            if ($scope.forms.answerForm.$valid) $scope.$close();
          };
        }
      };
      const result = $modal.open(modalOptions).result;

      result.catch(function() {
        // eslint-disable-next-line no-param-reassign
        answer = answerCopy;
      });

      return result;
    },

    isElementInView(info) {
      const viewportRect = info.containerRect;
      const elementRect = info.elementRect;
      if (!(viewportRect && elementRect)) return false;
      const elementRelativePosition = elementRect.top - viewportRect.top;
      const elementCenterRelativePosition =
        elementRelativePosition + elementRect.height / 2;
      return (
        elementCenterRelativePosition > 0 &&
        elementCenterRelativePosition < viewportRect.height
      );
    },

    /**
     * Determines whether given answer is checkbox
     *
     * @param {object} answer - Answer object
     * @returns {boolean}
     */
    isCheckboxAnswer(answer) {
      if (!ctrl.widget) return false;
      return answer.type === 'checkbox';
    },

    /**
     * Determines whether at least one checkbox answer option is present in widget
     *
     * @returns {boolean}
     */
    isCheckboxAnswerPresent() {
      if (!ctrl.widget) return false;
      if (!ctrl.widget.poll.answers.options.length) return false;
      return ctrl.widget.poll.answers.options.some(ctrl.poll.isCheckboxAnswer);
    },

    /**
     * Determines whether at least one input answer option is present in widget
     *
     * @returns {boolean}
     */
    isInputAnswerPresent() {
      if (!ctrl.widget) return false;
      if (!ctrl.widget.poll.answers.options.length) return false;
      return !ctrl.widget.poll.answers.options.every(
        ctrl.poll.isCheckboxAnswer
      );
    },

    /**
     * Determines whether submit button is present in widget
     *
     * @returns {boolean}
     */
    isSubmitButtonPresent() {
      return ctrl.isButtonPresent('submit');
    }
  };

  function setBreadCrumbs(state, widgetId, widgetType, widgetName) {
    const path = ['widgets'];
    const tabName = ['widgets', widgetType];
    let name;

    if (state === 'create') {
      path.push('create');
      tabName.push('create');
    } else {
      path.push('modify');
      tabName.push('modify');
      name = `${widgetId}. ${widgetName}`;
    }
    breadcrumbs.set({
      path: path.map(name => ({ name })),
      itemName: name
    });
    navigationPanel.setTabs([{ name: tabName.join('.'), isActive: true }]);
  }

  function stateChangeListener(event, toState, toParams) {
    if (!changeStateConfirmed && toState.name.indexOf('preview') === -1) {
      event.preventDefault();
      openCancelConfirmModal().then(function() {
        changeStateConfirmed = true;
        widgetsFactory.setPreviewData(null);
        // $scope.cancelEditMode($scope.files);
        $state.go(toState, toParams);
      });
    }
  }

  function openCancelConfirmModal() {
    const title = i18nFactory.translate(
      'widgets.modals.cancelConfirmation.title'
    );
    const message = i18nFactory.translate(
      `widgets.modals.cancelConfirmation.texts.${
        $state.params.id ? 'edit' : 'create'
      }`
    );
    return helpers.confirmationModal(title, message).result;
  }
  /** * END: poll specific methods ** */

  /**
   * Angular lifecycle callback for initing controller
   */
  ctrl.$onInit = function() {
    const currentStatePath = $state.current.name.split('.');
    const initDfd = $q.defer();
    editorState = currentStatePath[2]; // section.widgets.<editor state>.<widget type>
    widgetType = currentStatePath[3]; // section.widgets.<editor state>.<widget type>
    widgetsFactory.getOptions(widgetType).then(function(options) {
      ctrl.options = options;
      ctrl.selectedLocale = options.defaultLocale;
      ctrl.selectedButtonType = options.buttonTypes[0];
    });
    ctrl.submitted = false;
    ctrl.upload = {};
    ctrl.filesErrors = {};
    if (editorState === 'create') {
      ctrl.widget =
        widgetsFactory.getPreviewData().widget ||
        widgetsFactory.getWidgetTemplate(widgetType);
      initDfd.resolve(ctrl.widget);
    }
    if (editorState === 'edit') {
      const previewWidgetData = widgetsFactory.getPreviewData().widget;
      if (previewWidgetData) {
        ctrl.widget = previewWidgetData;
        initDfd.resolve(ctrl.widget);
      } else {
        widgetsFactory
          .getEditData($state.params.id)
          .then(function(widget) {
            ctrl.widget = widget;
            return widget;
          })
          .then(function(widget) {
            initDfd.resolve(widget);
          });
      }
    }

    initDfd.promise.then(function(widget) {
      setBreadCrumbs(
        editorState,
        widget.meta.id,
        widget.widgetType,
        widget.meta.name
      );
      navigationPanel.setActionButtons([
        {
          title: () =>
            `widgets.buttons.${
              widget.widgetType === 'custom' ? 'save' : 'preview'
            }`,
          isDisabled: () => false,
          type: () => 'confirm',
          isShown: () => true,
          // eslint-disable-next-line arrow-body-style
          clickHandler: () => {
            return widget.widgetType === 'custom'
              ? ctrl.saveCustomWidget()
              : ctrl.preview();
          }
        },
        {
          title: () => 'widgets.buttons.cancel',
          isDisabled: () => false,
          type: () => 'reject',
          isShown: () => true,
          clickHandler: () => {
            ctrl.cancel();
          }
        }
      ]);
      $timeout(function() {
        return $scope.resetEditorLayout && $scope.resetEditorLayout();
      }, 5000);
    });

    $scope.$on('$stateChangeStart', stateChangeListener);
  };
}
