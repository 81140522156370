/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */

import { injectNgDeps } from 'ngDeps';
const { angular } = window;

angular
  .module('wcm.auth')
  .factory('auth', function(
    $http,
    $rootScope,
    $q,
    requestFactory,
    helpers,
    session,
    sectionsFactory,
    AUTH_CONFIG,
    AUTH_EVENTS
  ) {
    let authChecked = $q.defer();

    // this function has been autorefactored to become async
    const createSession = async user => {
      try {
        const _user = angular.copy(user);
        _user.userPermissions = user.userPermissions || {
          sectionsAvailability: {},
          analytics: {}
        };
        _user.userPermissions.sectionsAvailability =
          normalizeSections(user.userPermissions.sectionsAvailability) || {};
        const user_1 = _user;
        const _user_1 = angular.copy(user_1);
        angular.extend(_user_1, { userId: user_1.userId });
        // eslint-disable-next-line no-param-reassign
        $rootScope.currentUser = session.create(_user_1);
        generateToken($rootScope.currentUser.userId);
        // eslint-disable-next-line no-param-reassign
        $http.defaults.headers.common.sessionId = _user_1.sessionId;
        authChecked.resolve();
        $rootScope.$broadcast(AUTH_EVENTS.loginSuccess, _user_1);
        return _user_1;
      } catch (res) {
        $rootScope.$broadcast(AUTH_EVENTS.loginFailed, res);
        authChecked.reject();
        throw res;
      }
    };

    const login = function login(credentials) {
      authChecked = $q.defer();
      return requestFactory
        .post(
          AUTH_CONFIG.loginServlet,
          AUTH_CONFIG.loginAction,
          credentials,
          {},
          { skipErrors: [400, 403, 404, 495] }
        )
        .then(function(res) {
          return res.data ? res.data : {};
        })
        .then(createSession);
    };

    // Generate unique browser tab token
    function generateToken(userId) {
      const mask = 'xxxxxxxx-xxxx-zxxx-yxxx-xxxxxxxxxxxx';
      // eslint-disable-next-line no-param-reassign
      $rootScope.uniqueBrowserTabToken = mask.replace(/[xyz]/g, function(c) {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise, no-nested-ternary, no-mixed-operators
        const v =
          c === 'x' ? r : c === 'z' ? userId[0].toLowerCase() : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }

    const logout = function logout() {
      return requestFactory
        .post(AUTH_CONFIG.loginServlet, AUTH_CONFIG.logoutAction, {})
        .success(function(res) {
          // eslint-disable-next-line no-param-reassign
          $rootScope.currentUser = session.destroy();
          // eslint-disable-next-line no-param-reassign
          $http.defaults.headers.common.sessionId = '';
          $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess, res);
          authChecked = $q.defer();
          authChecked.reject();
        })
        .error(function(res) {
          $rootScope.$broadcast(AUTH_EVENTS.logoutFailed, res);
        });
    };

    const isAuthenticated = function isAuthenticated() {
      return authChecked.promise;
    };

    const changePassword = function changePassword(oldPassword, newPassword) {
      return requestFactory.post(
        AUTH_CONFIG.loginServlet,
        AUTH_CONFIG.changePassword,
        {
          oldPassword,
          newPassword
        },
        {},
        { skipErrors: [403] }
      );
    };

    const forgotPassword = function forgotPassword(userId) {
      return requestFactory.post(
        AUTH_CONFIG.loginServlet,
        AUTH_CONFIG.forgotPasswordAction,
        { userId },
        {},
        { skipErrors: [403, 404] }
      );
    };

    const resetPassword = function forgotPassword(token, newPassword) {
      return requestFactory.post(
        AUTH_CONFIG.loginServlet,
        AUTH_CONFIG.resetPasswordAction,
        {
          newPassword
        },
        {
          'Content-Type': 'application/json',
          sessionId: token
        }
      );
    };

    const restoreSession = function restoreSession() {
      session.restoreSession().then(
        function(user) {
          // eslint-disable-next-line no-param-reassign
          $rootScope.currentUser = user;
          generateToken($rootScope.currentUser.userId);
          authChecked.resolve();
          $rootScope.$broadcast(
            AUTH_EVENTS.sessionRestored,
            $rootScope.currentUser
          );
        },
        function() {
          $rootScope.$broadcast(AUTH_EVENTS.sessionNotFound);
          authChecked.reject();
        }
      );
    };

    const isSameRole = function isSameRole(r1, r2) {
      return r1.toLowerCase() === r2.toLowerCase();
    };

    const authorize = function authorize(requiredRole) {
      const _requiredRole = angular.isArray(requiredRole)
        ? requiredRole
        : [requiredRole];
      const currentRole = session.currentUser().userRole
        ? session.currentUser().userRole.toLowerCase()
        : '';
      return _requiredRole.some(isSameRole.bind(null, currentRole));
    };

    /**
     * @deprecated Use sectionsFactory.hasPermissionForSection instead
     * This function exists only for backwards compatibility
     * @param user
     * @param sectionPath
     * @returns {*}
     */
    const hasPermissionsForSection = function(user, sectionPath) {
      return sectionsFactory.hasPermissionForSection(user, sectionPath);
    };

    /**
     * @deprecated
     * This function exists only for backwards compatibility
     * @returns {*}
     */
    const hasPermissionsForAnalytics = function() {
      const user = session.currentUser();
      return (
        user &&
        user.userPermissions &&
        user.userPermissions.analytics &&
        ['sites', 'campaigns'].some(function(type) {
          return (
            user.userPermissions.analytics[type] &&
            user.userPermissions.analytics[type].view
          );
        })
      );
    };

    const hasPermissionsForReports = function(tab) {
      const user = session.currentUser();
      if (!user || !user.userPermissions || !user.userPermissions.reporting) {
        return false;
      }
      switch (tab) {
        case null || undefined:
          return Object.keys(user.userPermissions.reporting).some(function(
            type
          ) {
            return user.userPermissions.reporting[type].view;
          });
        case 'systemWide':
          return ['general', 'campaigns', 'sites'].some(function(type) {
            return user.userPermissions.reporting[type].view;
          });
        case 'campaignSpecificMenu':
          return ['campaignSpecific', 'campaignViewers'].some(function(type) {
            return user.userPermissions.reporting[type].view;
          });
        case 'siteSpecificMenu':
          return ['siteSpecific', 'siteVisitors'].some(function(type) {
            return user.userPermissions.reporting[type].view;
          });
        default:
          return [tab].some(function(type) {
            return user.userPermissions.reporting[type].view;
          });
      }
    };

    function normalizeSections(sectionObj) {
      return Object.keys(sectionObj).reduce(function(acc, name) {
        const value = sectionObj[name];
        // eslint-disable-next-line no-param-reassign
        acc[mapBe2FeSectionName(name)] = angular.isObject(value)
          ? normalizeSections(value)
          : value;
        return acc;
      }, {});
    }

    function mapBe2FeSectionName(sectionName) {
      const map = {
        advertisercampaign: 'advertiserList',
        partnercampaign: 'partnerList',
        advertiser: 'advertiserList',
        partner: 'partnerList'
      };

      return map[sectionName] ? map[sectionName] : sectionName;
    }

    injectNgDeps({ auth: { createSession } });

    return {
      login,
      logout,
      restoreSession,
      isAuthenticated,
      forgotPassword,
      resetPassword,
      changePassword,
      authorize,
      generateToken,
      hasPermissionsForSection,
      hasPermissionsForAnalytics,
      hasPermissionsForReports
    };
  });
