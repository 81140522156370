/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.widgets')
  .controller('widgetsViewCtrl', function(
    $scope,
    $state,
    widgetsFactory,
    modificationHistory,
    breadcrumbs,
    i18nFactory,
    helpers,
    navigationPanel
  ) {
    const id = $state.params.id;
    $scope.lang = $state.params.lang;
    $scope.widgetLinkDomain = window.location.origin;
    const listState = 'section.widgets.widgetsList';
    let changeStateConfirmed = false;
    let previewData;

    $scope.activeDeviceView = 'console';

    // eslint-disable-next-line no-param-reassign
    if (!$state.params.device) $state.params.device = 'console';

    if ($state.current.name.indexOf('preview') !== -1)
      $scope.widgetPreview = true;

    const getWidgetData = function getWidgetData() {
      if ($scope.widgetPreview) {
        $scope.widget = {};
        previewData = widgetsFactory.getPreviewData();
        if (!previewData.widget) $state.go(listState);
        $scope.widget.widgetPreviewLink = previewData.widgetPreviewLink;

        if ($scope.lang) {
          $scope.widget.widgetPreviewLink = `${
            $scope.widget.widgetPreviewLink.split('lang=')[0]
          }lang=${$scope.lang}`;
        }

        breadcrumbs.set({
          path: [{ name: 'widgets' }, { name: 'preview' }],
          itemName:
            previewData.id !== 0
              ? `${previewData.id}. ${previewData.widget.meta.name}`
              : ''
        });

        navigationPanel.setTabs([
          {
            name: 'widgets.preview.tablet',
            sref: 'section.widgets.preview.device',
            go: { device: 'tablet' }
          },
          {
            name: 'widgets.preview.smartphone',
            sref: 'section.widgets.preview.device',
            go: { device: 'smartphone' }
          },
          {
            name: 'widgets.preview.console',
            sref: 'section.widgets.preview.device',
            go: { device: 'console' }
          }
        ]);
      } else {
        $scope.setBusy(true);
        widgetsFactory
          .getWidget(id)
          .then(function(widget) {
            $scope.widget = widget;

            breadcrumbs.set({
              path: [{ name: 'widgets' }, { name: 'details' }],
              itemName: `${$scope.widget.id}. ${$scope.widget.name}`
            });

            navigationPanel.setTabs([
              {
                name: 'widgets.preview.tablet',
                sref: 'section.widgets.view.device',
                go: { device: 'tablet' }
              },
              {
                name: 'widgets.preview.smartphone',
                sref: 'section.widgets.view.device',
                go: { device: 'smartphone' }
              },
              {
                name: 'widgets.preview.console',
                sref: 'section.widgets.view.device',
                go: { device: 'console' }
              }
            ]);

            modificationHistory.get('Widget', id).then(function(history) {
              $scope.history = history;
            });
          })
          .catch(function() {
            $state.go('section.widgets.widgetsList');
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      }
    };

    $scope.isActiveTab = function isActiveTab(tab) {
      if (tab === $scope.activeDeviceView) return 'is-active';
      return '';
    };

    $scope.setActiveTab = function setActiveTab(tab) {
      $scope.activeDeviceView = tab;
    };

    $scope.getActiveDeviceView = function activeDeviceView() {
      return $scope.activeDeviceView;
    };

    $scope.edit = function() {
      $state.go(`section.widgets.edit.${$scope.widget.contentType}`, {
        id: $scope.widget.id
      });
    };

    $scope.cancel = function() {
      if ($scope.widgetPreview) {
        $state.go(`section.widgets.${previewData.state}.${previewData.type}`, {
          id: previewData.id
        });
      } else {
        $state.go(listState);
      }
    };

    $scope.save = function() {
      $scope.setBusy(true);
      widgetsFactory
        .save(previewData)
        .then(function() {
          widgetsFactory.setPreviewData(null); // clear previewData
          changeStateConfirmed = true;
          $state.go(listState);
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    /** * Video preview methods: startVideoWidget, uploadDone ** */
    const startVideoWidget = function startVideoWidget() {
      const iframeWidgetModule = window.document.getElementById('widget-view')
        .contentWindow.widgetModule;
      if (iframeWidgetModule && iframeWidgetModule.previewVideo) {
        setTimeout(function() {
          iframeWidgetModule.previewVideo();
        }, 2000);
      }
    };
    // waits till iframe was loaded
    window.uploadDone = function() {
      startVideoWidget();
    };

    getWidgetData();

    $scope.$on('$stateChangeStart', (event, next, toParams) => {
      if (changeStateConfirmed || !$scope.widgetPreview) return undefined;
      const stateName = next.name;

      // if we go out from widgets section or to the widgets list
      if (
        !~stateName.indexOf('section.widgets') ||
        ~stateName.indexOf(listState)
      ) {
        event.preventDefault();
        openCancelConfirmModal().then(() => {
          changeStateConfirmed = true;
          widgetsFactory.setPreviewData(null);
          // $scope.cancelEditMode($scope.files);
          $state.go(next, toParams);
        });
      }
      return undefined;
    });

    function openCancelConfirmModal() {
      const title = i18nFactory.translate(
        'widgets.modals.cancelConfirmation.title'
      );
      const message = i18nFactory.translate(
        `widgets.modals.cancelConfirmation.texts.${
          $state.params.id ? 'edit' : 'create'
        }`
      );
      return helpers.confirmationModal(title, message).result;
    }

    navigationPanel.setActionButtons([
      {
        title: () => 'widgets.buttons.edit',
        isShown: () => !$scope.widgetPreview && $scope.widget.isEditable,
        clickHandler: () => {
          $scope.edit();
        }
      },
      {
        title: () => 'widgets.buttons.save',
        type: () => 'confirm',
        isShown: () => $scope.widgetPreview,
        clickHandler: () => {
          $scope.save();
        }
      },
      {
        title: () =>
          $scope.widgetPreview
            ? 'widgets.buttons.cancel'
            : 'widgets.buttons.close',
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);
  });
