import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ValidationMessage from './ValidationMessage';
import * as Login from './login.data';
import { ValidationError } from 'core/api';
import useInterval from 'core/helpers/useInterval';

const SECOND_IN_MS = 1000;

export const SignIn = ({ history, location }) => {
  const { t } = useTranslation();
  const [cooldownTimer, setCooldownTimer] = useState(0);
  useInterval(
    () => {
      setCooldownTimer(timer => Math.max(0, timer - SECOND_IN_MS));
    },
    cooldownTimer > 0 ? SECOND_IN_MS : null
  );

  return (
    <Formik
      initialValues={Login.signInFormInitialValues}
      validationSchema={Login.signInFormSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        Login.signIn(values, { history, location })
          .catch(err => {
            if (err instanceof ValidationError) {
              if (err.data.cooldownTimer) {
                setCooldownTimer(err.data.cooldownTimer);
                return setStatus({ serverValidation: false });
              }
              setStatus({ serverValidation: true });
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched, status, submitCount, isSubmitting }) => (
        <Form className="t-center" noValidate data-hook-id="form_login">
          <div className="authorizing__inputs-group">
            <Field
              className={classNames(
                'authorizing__input',
                'authorizing__input_user',
                {
                  authorizing__input_error: errors.userId && touched.userId
                }
              )}
              data-hook-id="t_username"
              type="text"
              name="userId"
              placeholder={t('login.usernamePlaceholder')}
              autoFocus
            />
            <Field
              className={classNames(
                'authorizing__input',
                'authorizing__input_password',
                {
                  authorizing__input_error: errors.password && touched.password
                }
              )}
              data-hook-id="t_password"
              type="password"
              name="password"
              placeholder={t('login.passwordPlaceholder')}
            />
            <div className="authorizing__msg-cnt">
              {status && status.serverValidation && !isSubmitting && (
                <ValidationMessage
                  message={t('login.errorMessage')}
                  hook="str_validation"
                />
              )}
              {(errors.userId || errors.password) && submitCount > 0 && (
                <ValidationMessage
                  message={t('validation.required')}
                  hook="str_validation"
                />
              )}
              {cooldownTimer > 0 && (
                <ValidationMessage
                  message={`${t('login.timeoutMessage')} ${Math.floor(
                    cooldownTimer / 1000
                  )} ${t('login.timeoutMeasureUnit')}`}
                  hook="str_validation"
                />
              )}
            </div>
          </div>
          <div className="authorizing__controls-group">
            <button
              disabled={isSubmitting || cooldownTimer > 0}
              className="button authorizing__button"
              type="submit"
              data-hook-id="b_login"
            >
              {t('login.loginButtonLabel')}
            </button>
          </div>
          <div className="authorizing__notes">
            <Link
              className="authorizing__link"
              to="/forgotPassword"
              data-hook-id="a_forgotPassword"
            >
              {t('login.forgotPassword')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SignIn.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default SignIn;
