/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.helpers').factory('countriesFactory', function($q) {
  const countries = {
    Afghanistan: ['Kabul'],
    Algeria: ['Algiers', 'Oran'],
    Angola: ['Luanda'],
    Argentina: ['Buenos Aires', 'Cordoba', 'La Matanza', 'Rosario', 'La Plata'],
    Armenia: ['Yerevan'],
    Australia: ['Sydney', 'Melbourne', 'Perth', 'Brisbane', 'Adelaide'],
    Austria: ['Vienna'],
    Azerbaijan: ['Baku'],
    Bangladesh: ['Dhaka', 'Chittagong', 'Khulna'],
    Belarus: ['Minsk'],
    Belgium: ['Brussels'],
    Bolivia: ['Santa Cruz', 'La Paz', 'Cochabamba'],
    Brazil: [
      'Sao Paulo',
      'Rio De Janeiro',
      'Salvador Da Bahia',
      'Fortaleza',
      'Belo Horizonte',
      'Brasilia',
      'Curitiba',
      'Manaus',
      'Recife',
      'Belem',
      'Porto Alegre',
      'Guarulhos',
      'Goiania',
      'Campinas',
      'Sao Luis',
      'Sao Goncalo',
      'Maceio',
      'Teresina',
      'Duque De Caxias',
      'Nova Iguacu',
      'Natal',
      'Sao Bernardo Do Campo',
      'Joao Pessoa',
      'Sao Jose Dos Campos',
      'Ribeirao Preto'
    ],
    Bulgaria: ['Sofia'],
    'Burkina Faso': ['Ouagadougou'],
    Cambodia: ['Phnom Penh'],
    Cameroon: ['Yaound?', 'Douala'],
    Canada: [
      'Toronto',
      'Montreal',
      'Calgary',
      'Ottawa',
      'Edmonton',
      'Vancouver'
    ],
    'Central African Republic': ['Bangui'],
    Chile: ['Santiago'],
    China: [
      'Shanghai',
      'Beijing',
      'Tianjin',
      'Hong Kong',
      'Guangzhou',
      'Dongguan',
      'Shenyang',
      'Chongqing',
      'Harbin',
      'Wuhan',
      'Chengdu',
      'Shenzhen',
      'Nanjing',
      'Changchun',
      'Guiyang',
      'Hangzhou',
      'Kunming',
      'Zibo',
      'Huludao',
      'Qingdao',
      'Changsha',
      'Fuzhou (Fujian)',
      'Xian',
      'Shijiazhuang',
      'Zhengzhou',
      'Taiyuan',
      'Baoshan',
      'Zhongshan',
      'Xiamen',
      'Chaoyang',
      'Nanning',
      'Suzhou',
      'Linyi',
      'Dalian',
      'Ningbo',
      'Lanzhou',
      'Changzhou',
      'Kowloon',
      'Tangshan',
      'Jilin',
      'Nanchong',
      'Jinan',
      'Macheng',
      'Nanchang',
      'Xuzhou',
      'Huzhou',
      'Suzhou Anhui',
      'Urumqi',
      'Yantai',
      'Tianmen',
      'Shantou',
      'Hefei',
      'Tengzhou',
      'Wuxi',
      'Fuyang',
      'Suizhou',
      'Gaozhou',
      'Taian',
      'Tianshui',
      'Shangqiu',
      'Neijiang',
      'Hechuan',
      'Taizhou',
      'Guigang',
      'Luoyang',
      'Quanzhou',
      'Xintai',
      'Nanan',
      'Xinyang',
      'Rugao',
      'Anyang',
      'Weifang',
      'Zhanjiang',
      'Fushun',
      'Qiqihaer',
      'Jianyang',
      'Guiping',
      'Huazhou',
      'Changde',
      'Tongzhou',
      'Handan',
      'Suining',
      'Liuyang',
      'Luzhou',
      'Taixing',
      'Bozhou',
      'Jinjiang',
      'Lufeng',
      'Yongcheng',
      'Guilin',
      'Pingdu',
      'Baotou',
      'Lianjiang',
      'Mianyang',
      'Yiyang',
      'Anshan',
      'Rizhao',
      'Heze',
      'Datong',
      'Fengcheng',
      'Ruian',
      'Laiwu',
      'Pingdingshan',
      'Yuzhou',
      'Cixi',
      'Huainan',
      'Anqiu',
      'Fuqing',
      'Qianjiang',
      'Bazhong',
      'Leqing',
      'Dongtai',
      'Guangyuan',
      'Qidong',
      'Bijie',
      'Haicheng',
      'Leshan',
      'Jimo',
      'Jining',
      'Wafangdian',
      'Shouguang',
      'Taishan',
      'Ezhou',
      'Jiangdu',
      'Beiliu',
      'Gongzhuling',
      'Changshu',
      'Fuzhou (Jiangxi)',
      'Yichun',
      'Mudanjiang',
      'Baoding',
      'Hezhou',
      'Wujiang',
      'Feicheng',
      'Haimen',
      'Weinan',
      'Songzi',
      'Laizhou',
      'Danyang',
      'Hengyang',
      'Honghu',
      'Daye',
      'Benxi',
      'Haikou',
      'Hohhot',
      'Liuzhou',
      'Bengbu',
      'Daqing',
      'Nanyang',
      'Jixi',
      'Ankang',
      'Xining',
      'Fuxin',
      'Jinzhou',
      'Zhangjiakou',
      'Kaifeng',
      'Hengyang'
    ],
    Colombia: [
      'Bogota',
      'Cali',
      'Medellin',
      'Barranquilla',
      'Cartagena',
      'Bucaramanga'
    ],
    'Congo Br': ['Brazzaville'],
    'Congo D.R.': ['Kinshasa', 'Lubumbashi', 'Mbuji-Mayi', 'Kolwezi'],
    Croatia: ['Zagreb'],
    Cuba: ['Havana'],
    'Czech Republic': ['Prague'],
    Denmark: ['Copenhagen'],
    'Dominican Republic': ['Santo Domingo', 'Santiago De Los Caballeros'],
    Ecuador: ['Guayaquil', 'Quito'],
    Egypt: ['Cairo', 'Alexandria', 'Giza', 'Subra Al-Haymah'],
    Estonia: ['Tallinn', 'Pärnu', 'Narva', 'Kohtla-Järve', 'Tartu'],
    Ethiopia: ['Addis Ababa'],
    Finland: ['Helsinki'],
    France: ['Paris', 'Marseille'],
    Georgia: ['Tbilisi'],
    Germany: [
      'Berlin',
      'Hamburg',
      'Munich',
      'Cologne',
      'Frankfurt',
      'Stuttgart',
      'Dortmund',
      'Essen',
      'Bremen'
    ],
    Ghana: ['Accra', 'Kumasi'],
    Greece: ['Athens'],
    Guatemala: ['Guatemala City'],
    Guinea: ['Conakry'],
    Haiti: ['Port-Au-Prince'],
    Honduras: ['Tegucigalpa'],
    Hungary: ['Budapest'],
    India: [
      'Mumbai (Bombay)',
      'Delhi',
      'Bangalore',
      'Surat',
      'Kolkata',
      'Chennai',
      'Ahmadabad',
      'Hyderabad',
      'Pune',
      'Kanpur',
      'Jaipur',
      'Durg',
      'Nagpur',
      'Lucknow',
      'Indore',
      'Patna',
      'Agra',
      'Nashik',
      'Pimpri Chinchwad',
      'Vadodara',
      'Bhopal',
      'Ludhiana',
      'Thane',
      'Varanasi',
      'Rajkot',
      'Ranchi',
      'Meerut',
      'Allahabad',
      'Amritsar',
      'Aurangabad',
      'Solapur',
      'Madurai',
      'Jabalpur',
      'Mirat',
      'Dhanbad',
      'Faridabad',
      'Haora',
      'Haora',
      'Jodhpur',
      'Ghaziabad',
      'Visakhapatnam',
      'Vijayawada',
      'Coimbatore',
      'Srinagar',
      'Chandigarh',
      'Sholapur',
      'Thiruvananthapuram',
      'Guwahati',
      'Hubli',
      'Mysore',
      'Tiruchchirappalli',
      'Jalandhar',
      'Gwalior',
      'Aligarh',
      'Amravati',
      'Bhubaneswar',
      'Jamshedpur',
      'Bhilai'
    ],
    Indonesia: [
      'Jakarta',
      'Surabaya',
      'Bandung',
      'Medan',
      'Palembang',
      'Tangerang',
      'Semarang',
      'Ujung Pandang',
      'Makasar',
      'Bogor',
      'Padang',
      'Bandar Lampung',
      'Malang',
      'Surakarta'
    ],
    Iran: [
      'Tehran',
      'Mashhad',
      'Esfahan',
      'Tabriz',
      'Karaj',
      'Ahwaz',
      'Shiraz',
      'Qom',
      'Kermanshah'
    ],
    Iraq: ['Baghdad', 'Basrah', 'Irbil', 'Mosul'],
    Israel: ['Jerusalem'],
    Italy: ['Rome', 'Milan', 'Naples', 'Turin', 'Palermo'],
    'Ivory Coast': ['Abidjan'],
    Jamaica: ['Kingston'],
    Japan: [
      'Tokyo',
      'Yokohama',
      'Osaka',
      'Nagoya',
      'Sapporo',
      'Kobe',
      'Kyoto',
      'Fukuoka',
      'Kawasaki',
      'Saitama',
      'Hiroshima',
      'Sendai',
      'Kitakyushu',
      'Chiba',
      'Sakai',
      'Hamamatsu',
      'Niigata',
      'Shizuoka',
      'Okayama'
    ],
    Jordan: ['Amman'],
    Kazakhstan: ['Almaty', 'Astana'],
    Kenia: ['Mombasa'],
    Kenya: ['Nairobi'],
    Kyrgyzstan: ['Bishkek'],
    Latvia: ['Riga'],
    Lebanon: ['Beirut'],
    Liberia: ['Monrovia'],
    Libya: ['Tripoli', 'Benghazi'],
    Lithuania: ['Vilnius'],
    Madagascar: ['Antananarivo'],
    Malawi: ['Lilongwe'],
    Malaysia: ['Kuala Lumpur'],
    Mali: ['Bamako'],
    Mexico: [
      'Mexico City',
      'Monterrey',
      'Puebla',
      'Ecatepec',
      'Guadalajara',
      'Tijuana',
      'Ciudad Juarez',
      'Zapopan',
      'Nezahualcoyotl',
      'Leon',
      'Torreon',
      'Mexicali',
      'Naucalpan',
      'Santiago De Queretaro',
      'Toluca',
      'Merida',
      'Acapulco',
      'Chihuahua',
      'San Luis Potosi',
      'Tlalnepantla De Baz',
      'Aguascalientes',
      'Culiacan'
    ],
    Moldova: ['Chisinau'],
    Mongolia: ['Ulaanbaatar'],
    Morocco: [
      'Casablanca',
      'Marrakech',
      'Fes',
      'Safi',
      'Agadir',
      'Sal?',
      'Tangier',
      'Rabat'
    ],
    Mozambique: ['Maputo'],
    'Myanmar (Burma)': ['Yangon', 'Mandalay'],
    Nepal: ['Kathmandu'],
    Netherlands: ['Amsterdam', 'Rotterdam'],
    Nicaragua: ['Managua'],
    Nigeria: [
      'Lagos',
      'Kano',
      'Port Harcourt',
      'Ibadan',
      'Kaduna',
      'Maiduguri',
      'Benin',
      'Zaria',
      'Abuja',
      'Ogbomosho'
    ],
    'North Korea': ['Pyongyang', 'Hamhung'],
    Norway: ['Oslo'],
    Oman: ['Masqat-Matrah'],
    Pakistan: [
      'Karachi',
      'Lahore',
      'Faisalabad',
      'Rawalpindi',
      'Hyderabad',
      'Multan',
      'Gujranwala',
      'Peshawar'
    ],
    Panama: ['Panama City'],
    Paraguay: ['Asuncion'],
    Peru: ['Lima', 'Arequipa'],
    Philippines: [
      'Manila Metro',
      'Quezon City',
      'Caloocan',
      'Davao City',
      'Cebu City'
    ],
    Poland: ['Warsaw', 'Lodz', 'Krakow'],
    Portugal: ['Lisbon'],
    Romania: ['Bucharest'],
    Russia: [
      'Moscow',
      'St Petersburg',
      'Novosibirsk',
      'Ekaterinburg',
      'Nizhniy Novgorod',
      'Samara',
      'Omsk',
      'Kazan',
      'Chelyabinsk',
      'Rostov On Don',
      'Ufa',
      'Volgograd',
      'Perm',
      'Krasnoyarsk',
      'Voronezh',
      'Saratov',
      'Krasnodar'
    ],
    'Saudi Arabia': [
      'Riyadh',
      'Jeddah',
      'Mecca',
      'Medina',
      'Ad-Dammam',
      'Dammam'
    ],
    Senegal: ['Pikine-Guediawaye', 'Dakar'],
    Serbia: ['Belgrade'],
    'Sierra Leone': ['Freetown'],
    Singapore: ['Singapore'],
    Somalia: ['Mogadishu'],
    'South Africa': [
      'Johannesburg',
      'Cape Town',
      'Tshwane (Pretoria)',
      'Soweto',
      'Port Elizabeth',
      'Durban'
    ],
    'South Korea': [
      'Seoul',
      'Busan',
      'Incheon',
      'Daegu',
      'Daejeon',
      'Gwangju',
      'Ulsan',
      'Suwon',
      'Goyang',
      'Seongnam',
      'Bucheon'
    ],
    Spain: ['Madrid', 'Barcelona', 'Valencia', 'Sevilla'],
    'Sri Lanka': ['Colombo'],
    Sudan: ['Omdurman', 'Khartoum'],
    Sweden: ['Stockholm'],
    Syria: ['Aleppo', 'Damascus', 'Hims'],
    Taiwan: ['Taipei', 'Kaohsiung', 'Taichung', 'Tainan'],
    Tajikistan: ['Dushanbe'],
    Tanzania: ['Dar Es Salaam'],
    Thailand: ['Bangkok'],
    Tunisia: ['Tunis'],
    Turkey: ['Istanbul', 'Ankara', 'Izmir', 'Bursa', 'Adana', 'Gaziantep'],
    UAE: ['Dubai', 'Abu Dhabi'],
    Uganda: ['Kampala'],
    UK: ['London', 'Birmingham', 'Leeds', 'Glasgow', 'Sheffield'],
    Ukraine: [
      'Kiev',
      'Kharkiv',
      'Donetsk',
      'Odessa',
      'Dnipropetrovsk',
      'Zaporizhzhya',
      'Lviv',
      'Zhytomyr',
      'Ivano-Frankivsk',
      'Kropivnitskii',
      'Luhansk',
      'Lutsk',
      'Mykolaiv',
      'Poltava',
      'Rivne',
      'Sumy',
      'Ternopil',
      'Uzhhorod',
      'Kherson',
      'Khmelnytskyi',
      'Cherkasy',
      'Chernivtsi',
      'Chernihiv'
    ],
    Uruguay: ['Montevideo'],
    USA: [
      'New York City',
      'Los Angeles',
      'Chicago',
      'Houston',
      'Phoenix',
      'Philadelphia',
      'San Antonio',
      'Dallas',
      'San Diego',
      'San Jose',
      'Detroit',
      'Norfolk',
      'San Francisco',
      'Jacksonville',
      'Indianapolis',
      'Columbus',
      'Austin',
      'Memphis',
      'Baltimore',
      'Nashville',
      'Boston',
      'Milwaukee',
      'Washington Dc',
      'Denver',
      'Seattle',
      'Charlotte',
      'Las Vegas',
      'Oklahoma City',
      'Portland',
      'Tucson',
      'Atlanta'
    ],
    Uzbekistan: [
      'Tashkent',
      'Andijan',
      'Bukhara',
      'Fergana',
      'Gulistan',
      'Jizzakh',
      'Namangan',
      'Navoi',
      'Nukus',
      'Qarshi',
      'Samarkand',
      'Termez',
      'Urgench'
    ],
    Venezuela: [
      'Caracas',
      'Maracaibo',
      'Barquisimeto',
      'Valencia',
      'Ciudad Guayana'
    ],
    Vietnam: ['Ho Chi Minh City', 'Haiphong', 'Hanoi'],
    Yemen: ['Sanaa'],
    Zambia: ['Lusaka', 'Kitwe'],
    Zimbabwe: ['Harare', 'Bulawayo']
  };

  const getCountries = function getCountries() {
    return $q.when(Object.keys(countries));
  };

  const getCitiesForCountry = function getCitiesForCountry(country) {
    return $q.when(countries[country].sort().concat(['Other*']));
  };

  return {
    getCountries,
    getCitiesForCountry
  };
});
