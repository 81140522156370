const { angular } = window;

angular
  .module('wcm.core')
  .controller('manageBlockCheckboxTableController', function(
    $scope,
    manageBlockFactory,
    helpers
  ) {
    const ctrl = this;

    // eslint-disable-next-line no-multi-assign
    const state = (ctrl.state = {
      currentPage: 1,
      itemsPerPage: 20,
      itemsOnPageValues: [20, 40, 60, 80],
      searchQuery: ''
    });

    // eslint-disable-next-line no-multi-assign
    const selectInfo = (ctrl.selectInfo = {
      pageSelected: false,
      allSelected: false
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = (ctrl.handlers = {
      handleCheckboxClick() {
        state.isValid = true;
        return true;
      },

      onSearchQueryChange(searchQuery) {
        state.filteredItems = getFilteredItems(ctrl.items, searchQuery);
      },
      getPage(items, ipp, page) {
        state.itemsPage = helpers.getPage(items, ipp, page);
        handlers.updateSelectInfo();
      },
      onFilteredItemsChange(items) {
        handlers.getPage(items, state.itemsPerPage, (state.currentPage = 1));
        handlers.updateSelectInfo();
      },
      onValuesSelected(values) {
        if (!values) return;
        const itemsForCheck = onFiltersChanged(values);
        ctrl.items.forEach(function(item) {
          // eslint-disable-next-line no-param-reassign
          if (itemsForCheck.indexOf(item.id) !== -1) item.checked = true;
        });
        state.filteredItems = sortItems(ctrl.items, '');
        handlers.updateSelectInfo();
      },
      selectCheckbox() {
        if (ctrl.selectInfo.allSelected) {
          handlers.changeItemsState(state.filteredItems, false);
        } else {
          ctrl.selectInfo.pageSelected
            ? handlers.changeItemsState(state.itemsPage, false)
            : handlers.changeItemsState(state.itemsPage, true);
        }
      },
      selectPage() {
        handlers.changeItemsState(state.filteredItems, false);
        handlers.changeItemsState(state.itemsPage, true);
      },
      changeItemsState(values, bool) {
        values.forEach(function(item) {
          // eslint-disable-next-line no-param-reassign
          item.checked = bool;
        });
        handlers.updateSelectInfo();
      },
      updateSelectInfo() {
        ctrl.selectInfo.allSelected = isAllChecked(state.filteredItems);
        ctrl.selectInfo.pageSelected = ctrl.selectInfo.allSelected
          ? true
          : isAllChecked(state.itemsPage);
        ctrl.selectInfo.selectedItems = state.filteredItems.filter(function(
          item
        ) {
          return item.checked;
        });
        ctrl.onSelectionChange({ selectInfo: ctrl.selectInfo });
      },
      filterByType() {
        state.filteredItems = getFilteredItems(ctrl.items, state.searchQuery);
      }
    });
    $scope.$watch(function() {
      return state.filteredItems;
    }, handlers.onFilteredItemsChange);

    // eslint-disable-next-line no-multi-assign
    ctrl.$onInit = ctrl.$onChanges = function(changeObj) {
      if (changeObj && changeObj.items) {
        if (!ctrl.items) {
          ctrl.items = [];
        }

        state.filteredItems = getFilteredItems(ctrl.items, '');
        selectInfo.selectedItems = getCheckedItems(ctrl.items);
      }
    };

    function sortItems(arr) {
      const checkedItems = [];
      const uncheckedItems = [];
      const alphSort = function(a, b) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      };

      arr.forEach(function(i) {
        i.checked ? checkedItems.push(i) : uncheckedItems.push(i);
      });

      const res = checkedItems
        .sort(alphSort)
        .concat(uncheckedItems.sort(alphSort));

      return res;
    }

    function getFilteredItems(items, searchQuery) {
      let _items = filterBySearchQuery(items, searchQuery);

      if (ctrl.typeFilter) {
        _items = _items.filter(function(item) {
          return item.type === ctrl.typeFilter.type;
        });
      }

      return sortItems(_items);
    }

    function searchQueryFilterFn(searchString, item) {
      if (!searchString) return true;
      return ~item.name.toLowerCase().indexOf(searchString.toLowerCase());
    }

    function filterBySearchQuery(items, searchQuery) {
      return items.filter(searchQueryFilterFn.bind(null, searchQuery));
    }

    function getCheckedItems(items, trackBy) {
      const filter = {};
      items.forEach(function(item) {
        if (item.checked) {
          filter[item[trackBy]] = item;
        }
      });
      return filter;
    }

    function isAllChecked(values) {
      if (!values.length) return false;
      return values.every(function(item) {
        return item.checked;
      });
    }

    function onFiltersChanged(filter) {
      if (!filter) return [];
      const _filter = filter.filter(function(item) {
        return item.checked;
      });
      let itemsForCheck = [];
      _filter.forEach(function(item) {
        itemsForCheck = itemsForCheck.concat(item[ctrl.collapseType]);
      });
      return window._.uniq(itemsForCheck);
    }
  });
