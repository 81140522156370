/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('analyticsChartLegend', function(
    analyticsFactory2,
    ANALYTICS_CONFIG2,
    $timeout,
    $filter
  ) {
    return {
      require: ['?^analyticsBlock2'],
      restrict: 'AE',
      replace: true,
      scope: {
        chartId: '@',
        chartLegendId: '@',
        chartColorPattern: '@',
        chartData: '=?',
        chartIsOnlyPercents: '=',
        chartDataViewType: '@',
        chartLegendOptions: '=?',
        legendType: '@',
        onChartLegendChange: '&',
        isNeedParseTitles: '@',
        isNeedLocalizeTitles: '@',
        parseTitleSeparator: '@',
        multiColumns: '=',
        isNeedHandleNoData: '@',
        isNeedLegendOverflow: '@'
      },
      templateUrl(tEl, tAttrs) {
        if (tAttrs.legendType === 'pie' || tAttrs.legendType === 'onlyBlocks') {
          return 'app/core/analytics2/legendPie.html';
        } else if (tAttrs.legendType === 'multiPie') {
          return 'app/core/analytics2/legendPieMulti.html';
        } else if (tAttrs.legendType === 'notchart') {
          return 'app/core/analytics2/legendNochart.html';
        }
        return 'app/core/analytics2/legend.html';
      },
      link(scope, el, attrs, ctrls) {
        const analyticsBlockCtrl = ctrls[0];

        // eslint-disable-next-line no-multi-assign
        const data = (scope.data = []);
        const colorPattern =
          analyticsFactory2.defaultColorPatterns[scope.chartColorPattern || 0];
        let chart;
        let legendLock = false;
        const FAKE_DATA_IDS = ['Unknown', 'Other'];
        const FAKE_DATA_COLOR = '#ECEBF1';
        const i18n = $filter('i18n');

        scope.tableTitle = '';

        scope.getLocalizedTitle = window._.memoize(function(title) {
          const MARKER = 'DOT_REPLACEMENT';
          const dotReplacedTranslate = i18n(
            `DAConstants.${title.replace(/\./g, MARKER)}`,
            true
          );
          return dotReplacedTranslate.replace(new RegExp(MARKER, 'g'), '.');
        });

        // clear memoize cache on locale change
        scope.$on('i18n:localeChanged', function() {
          scope.getLocalizedTitle.cache = {};
        });

        // Set legend to Chart
        scope.setLegendToChart = function() {
          if (!analyticsBlockCtrl) return false;
          return window.d3
            .select(el[0])
            .selectAll('.legend-graph')
            .data(
              (function() {
                return scope.chartData.data.columns
                  .map(function(item) {
                    return item[0];
                  })
                  .filter(function(item) {
                    return item !== ANALYTICS_CONFIG2.axisXName;
                  });
              })()
            )
            .attr('data-id', function(id) {
              return id;
            })
            .on('mouseover', function(id) {
              chart.focus(id);
            })
            .on('mouseout', function() {
              chart.revert();
            })
            .on('click', function(id) {
              if (
                attrs.legendType === 'pie' ||
                attrs.legendType === 'multiPie' ||
                legendLock
              )
                return false;
              legendLock = true;
              $timeout(function() {
                legendLock = false;
              }, 500);
              chart.toggle(id);
              const index = data
                .map(function(item) {
                  return item.title;
                })
                .indexOf(id);
              data[index].show = !data[index].show;
              scope.onChartLegendChange({
                title: data[index].title,
                value: data[index].show
              });
              return scope.$apply();
            });
        };

        // Set some vars if chartLegendOptions is available
        const setTableName = function() {
          if (scope.chartLegendOptions) {
            scope.tableTitle = scope.chartLegendOptions.tableTitle.split(
              '__'
            )[0];
          }
        };

        // Build Array of Chart legend
        const buildChart = function() {
          const total = scope.chartData.options.total;

          data.splice(0, data.length);

          const legendItems = scope.chartData.data.columns.filter(function(
            item
          ) {
            return item[0] !== ANALYTICS_CONFIG2.axisXName;
          });
          legendItems.forEach(function(item, i) {
            data[i] = {};
            data[i].color = scope.chartData.options.colorPattern
              ? scope.chartData.options.colorPattern[i]
              : colorPattern[i % colorPattern.length];
            data[i].title = item[0];
            data[i].value = item[1];
            data[i].type = scope.chartData.data.types
              ? scope.chartData.data.types[item[0]]
              : 'area';
            data[i].show = scope.chartData.options.legend
              ? scope.chartData.options.legend[item[0]]
              : true;

            // Show grey color if data[i] = Other for By Sites
            if (scope.chartId === 'pieChartBySites') {
              if (total > 0 && data[i].title.match(/^\d+\.\sOther$/g)) {
                data[i].color = FAKE_DATA_COLOR;
              } else if (total === 0) {
                data[i].color = FAKE_DATA_COLOR;
              }
            } else if (total <= 0 && ~FAKE_DATA_IDS.indexOf(data[i].title)) {
              // Show grey color if data[i] = Unknown or Other for in all cases
              data[i].color = FAKE_DATA_COLOR;
            }

            if (!data[i].show) {
              chart.toggle(item[0]);
            }

            // Count percentage
            data[i].valuePercents = ((item[1] / total) * 100).toFixed(2);
            if (
              Number.isNaN(+data[i].valuePercents) ||
              !Number.isFinite(+data[i].valuePercents)
            ) {
              if (total === 0 && ~FAKE_DATA_IDS.indexOf(item[0])) {
                data[i].valuePercents = 100;
              } else {
                data[i].valuePercents = 0;
              }
            }

            data[i].width = data[i].valuePercents;
          });
        };

        const buildNotChart = function() {
          return Object.keys(scope.chartData).map(function(key) {
            return {
              title: key,
              color: key,
              type: 'bar',
              show: scope.chartData[key]
            };
          });
        };

        // Build Array of Not chart legend
        if (scope.legendType === 'notchart') {
          scope.data = buildNotChart();
          scope.$watch(
            'chartData',
            function() {
              scope.data = buildNotChart();
            },
            true
          );
        }

        // Build legend when chart is ready
        if (analyticsBlockCtrl) {
          scope.$watchCollection(
            analyticsBlockCtrl.getChartObj.bind(null, scope.chartId),
            function(val) {
              if (val) {
                chart = val;
                buildChart();
              }
            }
          );
          // Build only legend without chart
        } else {
          scope.$watchCollection('chartData.data.columns', function(val) {
            if (val) {
              buildChart();
            }
          });
        }

        scope.$watchCollection(
          'chartLegendOptions',
          function(val) {
            if (val && val.tableTitle) {
              setTableName();
            }
          },
          true
        );
      }
    };
  });
