/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('sitesSystemAnalyticsCtrl', function(
    $scope,
    $q,
    $state,
    analyticsRequestsFactory,
    analyticsFactory2,
    sitesFactory,
    helpers,
    $timeout,
    $modal,
    $filter,
    navigationPanel,
    breadcrumbs,
    APP_CONFIG
  ) {
    const state = $scope.state;
    const options = $scope.options;
    const handlers = $scope.handlers;
    const user = $scope.currentUser;
    state.filters.siteIds = [];
    state.analyticsType = 'sites';

    options.printTitle = 'System-wide site analytics';

    const setManageBlocksConfig = function() {
      state.manageFilterSettings.sitesFilter = [
        {
          title: 'classAEntities',
          name: 'entity',
          values: state.manageBlocks.sitesFilter.entities,
          trackBy: 'id'
        },
        {
          title: 'tagsSites',
          name: 'tags',
          values: state.manageBlocks.sitesFilter.tags,
          trackBy: 'name',
          filterTypes: ['tagsClasses', 'tagsTypes']
        }
      ];

      state.manageFilterSettings.dataByComparisonSites = {
        firstFilter: [
          {
            title: 'classAEntities',
            name: 'entity',
            values:
              state.manageBlocks.dataByComparisonSites.firstValues.entities,
            trackBy: 'id'
          },
          {
            title: 'tagsSites',
            name: 'tags',
            values: state.manageBlocks.dataByComparisonSites.firstValues.tags,
            trackBy: 'name',
            filterTypes: ['tagsClasses', 'tagsTypes']
          }
        ],
        secondFilter: [
          {
            title: 'classAEntities',
            name: 'entity',
            values:
              state.manageBlocks.dataByComparisonSites.secondValues.entities,
            trackBy: 'id'
          },
          {
            title: 'tagsSites',
            name: 'tags',
            values: state.manageBlocks.dataByComparisonSites.secondValues.tags,
            trackBy: 'name',
            filterTypes: ['tagsClasses', 'tagsTypes']
          }
        ]
      };
    };

    handlers.setTabs = function() {
      // use timeout here to delay controller initialization to the moment when `main view`
      // directive sets its tabs handlers to the scope
      $timeout(function() {
        navigationPanel.setTabs(
          [
            {
              name: 'analytics.sites',
              sref: 'analytics.sites',
              current: true
            },
            {
              name: 'analytics.campaigns',
              sref: 'analytics.campaigns'
            }
          ].filter(function(tab) {
            return analyticsFactory2.hasPermissionsForType(
              user,
              tab.name.split('.')[1]
            );
          })
        );
        // go to the first available tab, if we don't have access to current one or go to default
        const tabs = $scope.currentPath.tabs;
        if (
          !window._.find(tabs, function(tab) {
            return tab.current === true;
          })
        ) {
          $state.go(tabs[tabs.length - 1].sref);
        }
      });
      breadcrumbs.set({
        path: [{ name: 'analytics' }]
      });
    };

    handlers.initManageBlocks = function(manageBlockFilters) {
      state.isBusy = false;

      if (state.noWas) return false;

      const sitesFilter = {
        sites: angular.copy(options.availableValues.sites),
        entities: angular.copy(options.availableValues.partners),
        tags: angular.copy(options.availableValues.sitesTags),
        checkedItems: manageBlockFilters
          ? manageBlockFilters.sitesFilter.checkedItems
          : []
      };
      const dataByComparisonSites = {
        firstValues: {
          sites: angular.copy(options.availableValues.sites),
          entities: angular.copy(options.availableValues.partners),
          tags: angular.copy(options.availableValues.sitesTags),
          checkedItems: manageBlockFilters
            ? manageBlockFilters.dataByComparisonSites.firstValues.checkedItems
            : []
        },
        secondValues: {
          sites: angular.copy(options.availableValues.sites),
          entities: angular.copy(options.availableValues.partners),
          tags: angular.copy(options.availableValues.sitesTags),
          checkedItems: manageBlockFilters
            ? manageBlockFilters.dataByComparisonSites.secondValues.checkedItems
            : []
        }
      };

      if (!manageBlockFilters) {
        if (options.availableValues.sites.length > 1) {
          // predefine filters for manage blocks
          sitesFilter.sites[0].checked = true;
          dataByComparisonSites.firstValues.sites[0].checked = true;
          dataByComparisonSites.secondValues.sites[1].checked = true;
        } else if (options.availableValues.sites.length > 0) {
          sitesFilter.sites[0].checked = true;
        }
      } else {
        sitesFilter.sites.forEach(function(site) {
          if (~manageBlockFilters.sitesFilter.checkedItems.indexOf(site.id)) {
            // eslint-disable-next-line no-param-reassign
            site.checked = true;
          }
        });
        dataByComparisonSites.firstValues.sites.forEach(function(site) {
          if (
            ~manageBlockFilters.dataByComparisonSites.firstValues.checkedItems.indexOf(
              site.id
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            site.checked = true;
          }
        });
        dataByComparisonSites.secondValues.sites.forEach(function(site) {
          if (
            ~manageBlockFilters.dataByComparisonSites.secondValues.checkedItems.indexOf(
              site.id
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            site.checked = true;
          }
        });
      }

      state.filters.siteIds = handlers.filterBy(sitesFilter.sites, 'id');

      const filtersData = {};
      filtersData.values1 = handlers.filterBy(
        dataByComparisonSites.firstValues.sites,
        'id'
      );
      filtersData.values2 = handlers.filterBy(
        dataByComparisonSites.secondValues.sites,
        'id'
      );
      filtersData.collapsedValues = {};
      filtersData.collapsedValues.values1 = handlers.getCollapseItems(
        'sites',
        [],
        dataByComparisonSites.firstValues.sites
      );
      filtersData.collapsedValues.values2 = handlers.getCollapseItems(
        'sites',
        [],
        dataByComparisonSites.secondValues.sites
      );
      analyticsRequestsFactory.setManageBlocksData(
        'dataByComparisonSites',
        filtersData
      );

      handlers.registerManageBlock('sitesFilter', sitesFilter);
      handlers.registerManageBlock(
        'dataByComparisonSites',
        dataByComparisonSites
      );

      setManageBlocksConfig();
      return undefined;
    };

    $scope.init = function() {
      if (!analyticsFactory2.isAnyPermissions(user)) {
        $state.go(APP_CONFIG.defaultState);
        return false;
      }
      handlers.setTabs();
      handlers.resetManageBlocks();
      if (!analyticsFactory2.hasPermissionsForType(user, state.analyticsType)) {
        return false;
      }
      return handlers.getAvailableValues().then(function() {
        $scope.$parent.init();
        handlers.initManageBlocks();
      });
    };

    $scope.downloadSelectedSites = function() {
      const sites = {};
      state.manageBlocks.sitesFilter.sites.forEach(item => {
        if (item.checked) {
          sites[item.id] = true;
        }
      });
      sitesFactory.downloadSitesListForCampaign(sites);
    };

    // Check files from file
    $scope.uploadSitesForSelect = function(type, file) {
      sitesFactory.uploadSitesListForCampaign(type, file).then(
        function(res) {
          if (!res.siteIds) {
            return;
          }
          let newItem;
          state.manageBlocks.sitesFilter.sites = state.manageBlocks.sitesFilter.sites.map(
            function(item) {
              newItem = angular.copy(item);
              if (~res.siteIds.indexOf(item.id)) {
                newItem.checked = true;
              } else {
                newItem.checked = false;
              }
              return newItem;
            }
          );
          state.filters.siteIds = handlers.filterBy(
            state.manageBlocks.sitesFilter.sites,
            'id'
          );
        },
        function(res) {
          if (res === 'wrongExe') {
            const labels = {
              message: 'campaigns.modals.xls.errorXlsMsg',
              title: 'campaigns.modals.xls.errorXlsTitle'
            };
            infoModal(labels);
          }
        }
      );
    };

    function infoModal(labels) {
      const i18n = $filter('i18n');
      const scope = $scope.$new();
      scope.msg = i18n(labels.message);
      scope.title = i18n(labels.title);
      $modal.open({
        templateUrl: 'app/core/views/infoErrorGlobal.html',
        windowClass: 'wcm-msg',
        scope
      });
    }

    handlers.onValuesSelected = function(
      blockName,
      availableValues,
      checkedIds,
      disableRequests
    ) {
      state.isPresetChanged = true;

      if (blockName === 'sitesFilter') {
        state.manageBlocks.sitesFilter.sites = availableValues;
        state.manageBlocks.sitesFilter.checkedItems = checkedIds;
        state.filters.siteIds = handlers.filterBy(
          state.manageBlocks.sitesFilter.sites,
          'id'
        );
        if (!disableRequests) handlers.onFiltersChange();
        return true;
      }

      if (blockName === 'dataByComparisonSites:firstValues') {
        state.manageBlocks.dataByComparisonSites.firstValues.sites = availableValues;
        state.manageBlocks.dataByComparisonSites.firstValues.checkedItems = checkedIds;
      }
      if (blockName === 'dataByComparisonSites:secondValues') {
        state.manageBlocks.dataByComparisonSites.secondValues.sites = availableValues;
        state.manageBlocks.dataByComparisonSites.secondValues.checkedItems = checkedIds;
      }

      if (~blockName.indexOf('dataByComparisonSites')) {
        const filtersData = {};
        filtersData.values1 = handlers.filterBy(
          state.manageBlocks.dataByComparisonSites.firstValues.sites,
          'id'
        );
        filtersData.values2 = handlers.filterBy(
          state.manageBlocks.dataByComparisonSites.secondValues.sites,
          'id'
        );
        filtersData.collapsedValues = {};
        filtersData.collapsedValues.values1 = handlers.getCollapseItems(
          'sites',
          state.manageFilterSettings.dataByComparisonSites.firstFilter,
          state.manageBlocks.dataByComparisonSites.firstValues.sites
        );
        filtersData.collapsedValues.values2 = handlers.getCollapseItems(
          'sites',
          state.manageFilterSettings.dataByComparisonSites.secondFilter,
          state.manageBlocks.dataByComparisonSites.secondValues.sites
        );
        analyticsRequestsFactory.setManageBlocksData(
          'dataByComparisonSites',
          filtersData
        );

        if (!disableRequests) {
          state.chartsData.dataByComparisonSites.requested = 'pending';
          analyticsRequestsFactory
            .getDataByComparisonSites(
              state.filters,
              state.blocksState.dataByComparisonSites,
              state.analyticsType
            )
            .then(function(data) {
              state.chartsData.dataByComparisonSites = data;
            })
            .catch(function(res) {
              handlers.setEmptyDataForBlock(
                res.status,
                'dataByComparisonSites'
              );
            });
        }
      }
      return undefined;
    };
  });
