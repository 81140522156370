/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.auth')
  .directive('access', function(auth, AUTH_EVENTS) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const makeVisible = function() {
          element.removeClass('hidden');
        };
        const makeHidden = function() {
          element.addClass('hidden');
        };
        const roles = scope.$eval(attrs.access);
        const determineVisibility = function(resetFirst) {
          if (resetFirst) {
            makeVisible();
          }

          if (auth.authorize(roles)) {
            makeVisible();
          } else {
            makeHidden();
          }
        };
        if (roles.length > 0) {
          determineVisibility(true);
        }

        scope.$on(AUTH_EVENTS.sessionCreated, function() {
          determineVisibility(true);
        });
      }
    };
  });
