import axios from 'axios';
import config from 'config';

let requestInstance;

// Instance of axios helper with helpers, base url and other params
export const getRequestInstance = () => {
  if (!requestInstance) {
    requestInstance = axios.create({
      baseURL: config.apiPath
    });
  }

  return requestInstance;
};
