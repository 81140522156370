/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').component('widgetEditorProgressBar', {
  templateUrl:
    'app/sections/widgets/editor/common/widgetEditorProgressBar.html',
  controller: widgetEditorProgressBarController,
  bindings: {
    wrapperStyle: '<',
    barStyle: '<',
    trackStyle: '<'
  }
});

function widgetEditorProgressBarController(widgetsFactory) {
  const ctrl = this;
  ctrl.getAngularStyle = function(reactStyle) {
    if (!angular.isObject(reactStyle)) return reactStyle;
    return widgetsFactory.convertReactStylesToAngular(reactStyle);
  };
}
