/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const { CKEDITOR } = window;
angular
  .module('wcm.admin')
  .controller('termsCtrl', function(
    $scope,
    helpers,
    $timeout,
    i18nFactory,
    localizationFactory
  ) {
    const state = $scope.state;
    const handlers = $scope.handlers;
    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        $scope.setBusy(!!queue.length);
      }, 100);
    });
    const setCurrentLocale = () => {
      const state = i18nFactory.getState();
      let currentLocaleId = state.currentLocale.id;
      if (!currentLocaleId) {
        setInterval(() => {
          setCurrentLocale();
        }, 200);
      } else {
        currentLocaleId = currentLocaleId.split('-')[0];
        currentLocaleId = currentLocaleId === 'ua' ? 'uk' : currentLocaleId;
        $scope.editorOptions = {
          language: currentLocaleId,
          toolbar: 'question',
          toolbar_question: [
            {
              name: 'basicstyles',
              items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']
            },
            {
              name: 'paragraph',
              items: [
                'NumberedList',
                'BulletedList',
                '-',
                'Outdent',
                'Indent',
                '-',
                'Blockquote'
              ]
            },
            { name: 'styles', items: ['Styles', 'Format'] }
          ],
          disableNativeSpellChecker: false,
          uiColor: '#DFE0DF',
          height: '100%',
          width: '495px', // increased size to fit ckeditor's toolbar on one line
          resize_enabled: false,
          customConfig: '',
          extraPlugins: 'pastefromword'
        };
      }
    };

    setCurrentLocale();

    handlers.selectLocale = function selectLocale(idx) {
      if (!state.locales[idx]) return;
      state.selectedLocaleIdx = idx;
      getTermsForLocale(state.locales[idx].id);
    };

    handlers.init = busyImpactor(function init() {
      state.locales = []; // empty locales if they were previously gotten

      $scope.$watch('state.editMode', function(editMode) {
        handlers.readOnlyHandler(editMode);
      });

      return localizationFactory
        .getClientLocalizations()
        .then(function(locales) {
          state.locales = locales;
        })
        .then(function() {
          handlers.selectLocale(0);
        });
    });

    handlers.save = function() {
      save(state.locales[state.selectedLocaleIdx].id, state.terms);
    };

    function getTermsForLocale(localeId) {
      localizationFactory.getTermsForLocale(localeId).then(function(terms) {
        state.terms = terms;
      });
    }

    function save(localeId, terms) {
      return localizationFactory
        .saveTermsForLocale(localeId, terms)
        .then(function() {
          $scope.state.editMode = false;
        });
    }

    handlers.readOnlyHandler = function(isEditMode) {
      if (!angular.isDefined(CKEDITOR.instances.terms)) return false;
      return CKEDITOR.instances.terms.setReadOnly(!isEditMode);
    };
  });
