import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ValidationMessage from './ValidationMessage';
import * as Login from './login.data';

export const ResetPassword = ({ history, location }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={Login.resetPasswordFormInitialValues}
      validationSchema={Login.resetPasswordFormSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        Login.resetPassword(values, { history, location }).finally(() => {
          setSubmitting(false);
        });
      }}
    >
      {({ errors, touched, isSubmitting, isValid }) => (
        <Form noValidate autoComplete="off" data-hook-id="form_resetPassword">
          <div className="authorizing__inputs-group">
            <Field
              type="password"
              name="password"
              className={classNames(
                'authorizing__input',
                'authorizing__input_password',
                {
                  authorizing__input_error: errors.password && touched.password
                }
              )}
              data-hook-id="t_password"
              placeholder={t('login.passwordPlaceholder')}
              autoFocus
            />
            <Field
              type="password"
              name="passwordConfirmation"
              className={classNames(
                'authorizing__input',
                'authorizing__input_password',
                {
                  authorizing__input_error:
                    errors.passwordConfirmation && touched.passwordConfirmation
                }
              )}
              data-hook-id="t_passwordConfirmation"
              placeholder={t('login.passwordConfirmationPlaceholder')}
            />
            <div className="authorizing__msg-cnt">
              {errors.password && (
                <ValidationMessage
                  message={t(errors.password)}
                  hook="str_validation"
                />
              )}
              {errors.passwordConfirmation && (
                <ValidationMessage
                  message={t(errors.passwordConfirmation)}
                  hook="str_validation"
                />
              )}
            </div>
          </div>
          <div className="authorizing__controls-group">
            <button
              className="button button_confirm authorizing__button"
              disabled={isSubmitting || !isValid}
              type="submit"
              data-hook-id="b_resetPassword"
            >
              {t('login.resetPasswordButtonLabel')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default ResetPassword;
