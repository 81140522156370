/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.labels')
  .controller('labelsCtrl', function($scope, $modal, $filter, helpers) {
    $scope.addLabel = function() {
      const scope = $scope.$new();
      scope.availbaleLabels = $scope.availableLabels;
      scope.availableTags = $scope.availableTags || [];
      scope.tagValidation = $scope.tagValidation || false;
      $modal
        .open({
          templateUrl: 'app/core/labels/addLabelModal.html',
          windowClass: 'add-label_modal',
          controller($scope, $modalInstance) {
            $scope.newLabel = {
              name: ''
            };
            $scope.forms = {};
            $scope.state = { submitted: false };
            $scope.ok = function() {
              const inCurrentLabels = ~$scope.currentLabels
                .map(toLowerCase)
                .indexOf($scope.newLabel.name.toLowerCase());
              const inAvailableLabels = ~$scope.availbaleLabels
                .map(toLowerCase)
                .indexOf($scope.newLabel.name.toLowerCase());
              const inAvailableTags = ~$scope.availableTags
                .map(toLowerCase)
                .indexOf($scope.newLabel.name.toLowerCase());
              if (scope.tagValidation && inAvailableTags) {
                const labelsInfo = {
                  title: $scope.labelsTitle,
                  message: $scope.modalMessageTagDuplicate
                };
                helpers.infoModal(labelsInfo);
              } else if (!inCurrentLabels) {
                if (!inAvailableLabels) {
                  helpers
                    .confirmationModal(
                      $scope.titleAdd,
                      $scope.modalMessageAdd,
                      $scope.newLabel.name
                    )
                    .result.then(function() {
                      scope.availbaleLabels.push($scope.newLabel.name);
                      $modalInstance.close($scope.newLabel.name);
                    });
                } else {
                  $modalInstance.close($scope.newLabel.name);
                }
              } else {
                const labelsInfo = {
                  title: $scope.labelsTitle,
                  message: $scope.modalMessageDuplicate
                };
                helpers.infoModal(labelsInfo);
              }
            };
            $scope.selectLabel = function(labelName) {
              $scope.isInputFocused = false;
              $scope.newLabel.name = labelName;
            };
            $scope.focus = function() {
              $scope.isInputFocused = true;
            };
          },
          scope
        })
        .result.then(function(label) {
          $scope.currentLabels.push(label);
        });
    };

    $scope.removeLabel = function(label) {
      helpers
        .confirmationModal(
          $scope.titleRemove,
          $scope.modalMessageRemove,
          label,
          null,
          { className: 'remove-label_modal' }
        )
        .result.then(function() {
          const index = $scope.currentLabels.indexOf(label);
          $scope.currentLabels.splice(index, 1);
        });
    };

    function toLowerCase(value) {
      if (typeof value !== 'string') return value;
      return value.toLowerCase();
    }
  });
