/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('analyticsQueryBlock', function() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        pending: '=?',
        noDataAvailability: '=?',
        title: '@',
        headerDisabled: '=',
        onRemoveQuery: '&?',
        isRemovable: '@',
        queryData: '=?',
        queryIndex: '='
      },
      templateUrl: 'app/core/analytics2/analyticsQueryBlock.html',
      controller($scope) {
        $scope.state = {
          answerFilter: 'all',
          answerFiltersData: []
        };
        $scope.filterByAnswer = function() {
          const columns = $scope.queryData.data.columns.filter(function(item) {
            return (
              $scope.state.answerFilter === 'all' ||
              item[0] === $scope.state.answerFilter
            );
          });
          $scope.resultData.data.columns = columns;
        };
      },
      link(scope, el) {
        el.attr('id', scope.title); // set id of block

        scope.resultData = angular.copy(scope.queryData);

        const renderChart = function(val) {
          if (val.columns) {
            scope.resultData = angular.copy(scope.queryData);
            scope.state.answerFiltersData = [];
            val.columns.forEach(function(item) {
              scope.state.answerFiltersData.push(item[0]);
            });
          }
        };

        scope.$watchCollection(
          function() {
            return {
              columns:
                scope.queryData && typeof scope.queryData !== 'string'
                  ? scope.queryData.data.columns
                  : null
            };
          },
          renderChart,
          true
        );
      }
    };
  });
