/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('logsFactory', function($q, requestFactory, ADMIN_CONFIG) {
    const clientLogsGetList = function(searchMacQuery) {
      return requestFactory.post(
        ADMIN_CONFIG.logsServlet,
        ADMIN_CONFIG.clientLogsGetList,
        {
          filterByMacAddress: searchMacQuery
        }
      );
    };

    const logsGetList = function(logsType) {
      return requestFactory
        .post(
          ADMIN_CONFIG.logsServlet,
          ADMIN_CONFIG.logsGetList,
          { type: logsType },
          {},
          { skipErrors: [502, 504, 244, 204] }
        )
        .then(
          function(res) {
            if (res.status === 204 || res.status === 244) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const getAvailableLogsTypes = function() {
      return requestFactory.post(
        ADMIN_CONFIG.logsServlet,
        ADMIN_CONFIG.getAvailableLogsTypes,
        {}
      );
    };

    return {
      clientLogsGetList,
      logsGetList,
      getAvailableLogsTypes
    };
  });
