import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SystemInfoContext = createContext({});

function SystemInfoProvider({ children }) {
  const [systemInfo] = useState({
    buildName: process.env.REACT_APP_CMS_FE_VERSION || 'dev'
  });

  return (
    <SystemInfoContext.Provider value={systemInfo}>
      {children}
    </SystemInfoContext.Provider>
  );
}

SystemInfoProvider.propTypes = {
  children: PropTypes.node
};

function useSystemInfo() {
  return useContext(SystemInfoContext);
}

export { SystemInfoProvider, useSystemInfo };
