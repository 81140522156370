/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.entities').constant('ENTITIES_CONFIG', {
  entitiesServlet: 'entities',
  entitiesWasServlet: 'entities_was',
  entityGetOwnersList: 'entityGetOwnersList',
  getList: 'entityGetList',
  getData: 'entityGetData',
  saveData: 'entitySave',
  moveToArchive: 'entityMoveToArchive',
  recoverFromArchive: 'entityRecoverFromArchive',
  remove: 'entityRemove',
  getAgencies: 'entityGetAgencies',
  getAllEntities: 'getAllEntities'
});
