/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('vendorSettingsFactory', function(
    requestFactory,
    VENDOR_SETTINGS_CONFIG
  ) {
    const getVendorSettings = function() {
      return requestFactory.post(
        VENDOR_SETTINGS_CONFIG.servlet,
        VENDOR_SETTINGS_CONFIG.getVendorSettings,
        {}
      );
    };

    const saveVendorSettings = function(data) {
      return requestFactory.post(
        VENDOR_SETTINGS_CONFIG.servlet,
        VENDOR_SETTINGS_CONFIG.saveVendorSettings,
        {
          vendors: data
        }
      );
    };

    return {
      getVendorSettings,
      saveVendorSettings
    };
  });
