/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const L = require('leaflet');

angular
  .module('wcm.geoLocation')
  .directive('wcmGeoMap', function(geoLocationFactory, $timeout) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        geoMapList: '=?', // Data to show (can be empty when we need to find coords by address)
        geoMapClass: '@', // css class for map
        geoMapUpdateOnChange: '=?', // When var was change - update map without reloading (only clear and set new data)
        geoMapShowNumbers: '=?', // TODO! Change on showLabels. Show icon of marker like icon + text label
        geoMapIsBusy: '=?', // is data for map preparing (loading) at that moment
        geoMapSearchInProgress: '=?', // Use when we need find coords by address (show that searching by geoCoords in progress now)
        geoMapNeedSingleMarker: '@', // Use when we need find coords by address (identificator = true || null)
        geoMapConfirmedCoords: '=?', // Use when we need find coords by address (object with confirmed lat and lng of place/site)
        geoMapEditMode: '=?', // Is marker can be draggable
        geoMapAdditionalInfo: '<?'
      },
      transclude: true,
      templateUrl: 'app/core/geo_location/map.html',
      link(scope, el, attrs, ctrl, transclude) {
        // Tranclude
        transclude(scope, function(clone) {
          el.find('[geo-map-transclude]').append(clone);
        });

        // Create MapID -----
        const MAP_ID = 'wcm-geo-map';
        const geoMapEl = el.find('[geo-map]');
        geoMapEl.attr('id', MAP_ID);
        geoMapEl.addClass(scope.geoMapClass);

        // Init vars -----
        scope.markers = [];
        scope.locationFind = {};
        scope.markersLength = 0;
        const markerIcons = geoLocationFactory.markerIcons;
        let map;
        let currentAddress;
        let markersGroup;

        // Init scope vars -----
        scope.dataLoading = true;
        scope.noDataCoords = false;

        // Init map -----
        map = L.map(MAP_ID, {
          // eslint-disable-line prefer-const
          zoomControl: false,
          center: [58.303889, 22.278889], // geo center of Europe
          zoom: 3
        });

        L.control
          .zoom({
            position: 'bottomright'
          })
          .addTo(map);

        L.tileLayer(
          'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoianNoZXBhcmQiLCJhIjoiY2lvdjdlaHZlMDA1eHc5a2plMzJ5MjNreCJ9.4PeBqnAssuDVn2vC0lD5kA',
          {
            attribution:
              'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18,
            minZoom: 3,
            id: 'mapbox.streets'
          }
        ).addTo(map);

        map
          .on('popupopen', function() {
            geoLocationFactory.setMarkersIcons(
              scope.markers,
              scope.handlers.whichIcon('inactive'),
              scope.geoMapShowNumbers
            );
          })
          .on('popupclose', function() {
            geoLocationFactory.setMarkersIcons(
              scope.markers,
              scope.handlers.whichIcon('default'),
              scope.geoMapShowNumbers
            );
          });

        // Create marker layers Group -----
        markersGroup = new L.FeatureGroup().addTo(map); // eslint-disable-line prefer-const

        // Map handlers
        scope.handlers = {
          // Set data of confirmed markers
          setConfirmedMarker() {
            scope.markers.forEach(function(item) {
              item.setIcon(markerIcons.confirmed);
              const popup = initMarkersPopup(item.options.data, true);
              item.bindPopup(popup.element, popup.options);
              addClickListener(item);
            });
          },
          whichIcon(type) {
            if (!type || type === 'default') {
              return !scope.geoMapConfirmedCoords ||
                !scope.geoMapConfirmedCoords.latitude
                ? markerIcons.default
                : markerIcons.confirmed;
            }
            if (type === 'inactive') {
              return !scope.geoMapConfirmedCoords ||
                !scope.geoMapConfirmedCoords.latitude
                ? markerIcons.defaultInactive
                : markerIcons.confirmedInactive;
            } else if (type === 'active') {
              return !scope.geoMapConfirmedCoords ||
                !scope.geoMapConfirmedCoords.latitude
                ? markerIcons.defaultActive
                : markerIcons.confirmedActive;
            }
            return undefined;
          }
        };
        // Set map center
        const setMapCenter = function() {
          map.fitBounds(markersGroup.getBounds());
          $timeout(function() {
            scope.dataLoading = false;
          }, 250);
        };
        const debouncedSetMapCenter = window._.debounce(setMapCenter, 1000);

        // Create markers from MapObjects and add it on map -----
        const addMarkerOnMapMain = function(site) {
          let coords;
          let marker;
          let icon;

          // Set coords array
          if (Array.isArray(site)) {
            coords = angular.copy(site);
          } else if (typeof site === 'object' && site.coords) {
            coords = [+site.coords.latitude, +site.coords.longitude];
          } else if (typeof site === 'object' && site.lat && site.lon) {
            coords = [+site.lat, +site.lon];

            // FIXME: try to make this function to be pure
            // eslint-disable-next-line no-param-reassign
            site = geoLocationFactory.setSiteInfoByAddress(site);
          }

          // If no coords -> return null
          if (!coords) {
            return null;
          }

          // Set marker default icon
          icon = angular.copy(markerIcons.default); // eslint-disable-line prefer-const

          // Set markers icons
          if (scope.geoMapShowNumbers) {
            icon.options.html = geoLocationFactory.setMarkerLabel(
              site.visitsOrder || 0
            );
          }

          // eslint-disable-next-line prefer-const
          marker = L.marker(coords, { icon, isActive: false, data: site });
          marker.addTo(markersGroup);

          debouncedSetMapCenter();

          return marker;
        };

        // Add markers on map when we need get several markers -----
        const addMarkerOnMapForMultiData = function(site, index) {
          const marker = addMarkerOnMapMain(site, index);
          if (!marker) {
            return null;
          }

          const popup = initMarkersPopup(site);
          marker.bindPopup(popup.element, popup.options);

          addClickListener(marker);
          return marker;
        };

        // Add markers on map when we need only single marker -----
        const addMarkerOnMapForSingleData = function(site, index) {
          let icon;
          const marker = addMarkerOnMapMain(site, index);

          if (!marker) {
            return null;
          }

          if (scope.markers.length > 1) {
            icon = markerIcons.errorInactive;
            marker.options.draggable = false;
            addClickListener(marker, removeOtherMarkers);
          } else if (scope.markers.length <= 1) {
            icon = scope.handlers.whichIcon('default');
            if (scope.geoMapEditMode) marker.options.draggable = true;
            addDragListener(marker);

            if (
              scope.geoMapConfirmedCoords &&
              scope.geoMapConfirmedCoords.latitude &&
              scope.geoMapConfirmedCoords.longitude
            ) {
              const popup = initMarkersPopup(site);
              marker.bindPopup(popup.element, popup.options);
              addClickListener(marker);
            }

            const position = marker.getLatLng();
            scope.locationFind = {
              latitude: +position.lat,
              longitude: +position.lng
            };
          }

          marker.setIcon(icon);
          return marker;
        };

        // Init popup element for marker
        function initMarkersPopup(site, isConfirmed) {
          const markerScope = scope.$new();
          if (isConfirmed) {
            markerScope.marker = {
              address:
                currentAddress[1] ||
                `${currentAddress[0]}, ${currentAddress[2]}`
            };
          } else {
            markerScope.marker = site;
          }
          const popupOptions = {
            offset: new L.Point(0, 0),
            className: scope.geoMapNeedSingleMarker
              ? 'site_marker-popup'
              : 'monitoring_marker-popup'
          };
          const domPopupElement = document.createElement('div');
          window
            .$(domPopupElement)
            .append(
              geoLocationFactory.generateMarkerPopup(
                markerScope,
                scope.geoMapNeedSingleMarker ? 'siteSearch' : null
              )
            );
          return { element: domPopupElement, options: popupOptions };
        }

        // Set marker click event
        function addClickListener(marker, action) {
          marker.on('click', function(e) {
            if (!action) {
              if (e.target.getPopup() && !e.target.getPopup()._isOpen) {
                e.target.setIcon(scope.handlers.whichIcon('default'));
              } else {
                e.target.setIcon(scope.handlers.whichIcon('active'));
              }
            } else {
              action(marker);
            }
          });
          return marker;
        }

        // Set marker drag event
        function addDragListener(marker, action) {
          marker
            .on('dragstart', function() {
              marker.unbindPopup();
              marker.setIcon(markerIcons.default);
              marker.off('click');
            })
            .on('dragend', function(e) {
              if (!action) {
                const position = e.target.getLatLng();
                scope.locationFind = {
                  latitude: +position.lat,
                  longitude: +position.lng
                };
                scope.$apply((scope.geoMapConfirmedCoords = {}));
              } else {
                action(marker);
              }
            });
          return marker;
        }

        // Remove all markers except current
        function removeOtherMarkers(marker) {
          scope.markers = [];
          markersGroup.clearLayers();
          scope.markers.push(addMarkerOnMapForSingleData(marker.options.data));
          const position = marker.getLatLng();
          scope.locationFind = {
            latitude: +position.lat,
            longitude: +position.lng
          };
        }

        // Main Init function -----
        const init = function(mapList) {
          scope.dataLoading = true;
          scope.noDataCoords = false;
          if (markersGroup) {
            map.closePopup();
            markersGroup.clearLayers();
          }
          scope.markers = mapList
            .filter(window._.identity)
            .filter(function(item) {
              return (
                (!!item.coords &&
                  !!item.coords.latitude &&
                  !!item.coords.longitude) ||
                (!!item.lat && !!item.lon)
              );
            });

          if (scope.geoMapNeedSingleMarker) {
            scope.markers = scope.markers.map(addMarkerOnMapForSingleData);
          } else {
            scope.markers = scope.markers.map(addMarkerOnMapForMultiData);
          }

          if (!scope.markers.length) {
            scope.dataLoading = false;
            scope.noDataCoords = true;
          }
        };

        // Start Init function only when data is not empty -----
        scope.$watchCollection('geoMapList', function(newValue) {
          scope.markers = [];
          if (newValue) {
            if (!newValue.length) {
              map.setView(new L.LatLng(58.303889, 22.278889), 3);
              scope.dataLoading = false;
              scope.noDataCoords = false;
            } else {
              init(newValue);
            }
          }
        });

        // Find markers by geo_location searching
        scope.$on('geoLocation.searchByAddress', function(e, address) {
          if (
            !scope.geoMapConfirmedCoords.latitude ||
            !scope.geoMapConfirmedCoords.longitude
          ) {
            scope.locationFind = {};
            geoLocationFactory
              .getCoordsByAddress(address[0], address[1], address[2])
              .then(function(data) {
                init(data || []);
                currentAddress = address;
                scope.geoMapSearchInProgress = false;
              });
          }
        });

        // Update map on change some variable -----
        scope.$watch('geoMapUpdateOnChange', function() {
          map.closePopup();
          geoLocationFactory.setMarkersIcons(
            scope.markers,
            scope.handlers.whichIcon('default'),
            scope.geoMapShowNumbers
          );
        });

        // Update draggable marker -----
        scope.$watch('geoMapEditMode', function(enable) {
          scope.markers.forEach(function(marker) {
            if (enable) {
              marker.dragging.enable();
            }
          });
          if (!enable) {
            scope.locationFind = {};
          }
        });
      }
    };
  })
  .directive('wcmGeoMapConfirm', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '?^wcmGeoMap',
      scope: {
        searchInProgress: '=?',
        locationFind: '=?',
        locationConfirm: '=?',
        markersLength: '=?',
        callbackFunc: '&',
        mapEditMode: '=?'
      },
      templateUrl: 'app/core/geo_location/geoMarkerConfirmation.html',
      link(scope) {
        scope.setSiteCoords = function() {
          scope.locationConfirm = angular.copy(scope.locationFind);
          scope.callbackFunc();
        };
      }
    };
  });
