/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;
angular
  .module('wcm.admin')
  .controller('monitoringCollectionPropsCtrl', function(
    $scope,
    $state,
    $stateParams,
    $location,
    $q,
    $timeout,
    $filter,
    $modal,
    monitoringFactory,
    breadcrumbs,
    navigationPanel,
    monitoringCollectionFactory,
    conversionFactory,
    i18nFactory,
    helpers
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isBusy: false,
      isBusyCollection: false,
      collection: {},
      collectionName: '',
      collectionType: $stateParams.type || 'filtered',
      collectionId: +$stateParams.id || 0,
      sitesAll: [],
      occupationAll: [],
      interestsAll: [],
      cityAll: []
    });

    const collectionDefault = {
      id: 0,
      name: '',
      macAddress: [],
      visitorNames: [],
      gender: [],
      age: [],
      deviceType: [],
      deviceOS: [],
      timeframe: [],
      occupation: [],
      interests: [],
      keywords: [],
      urls: [],
      sites: [],
      city: []
    };

    const itemSelectedAttrs = {
      sites: 'id'
    };

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      gender: ['GENDER_MALE', 'GENDER_FEMALE', 'Unknown'],
      deviceType: ['Smartphone', 'Tablet', 'Console', 'Unknown'],
      deviceOS: [
        'Mac OS X',
        'iOS',
        'Windows Phone',
        'Windows',
        'Linux',
        'Android',
        'Unknown'
      ],
      age: [
        'AGE_0_11',
        'AGE_25_34',
        'AGE_55_65',
        'AGE_12_17',
        'AGE_35_44',
        'AGE_66_PLUS',
        'AGE_18_24',
        'AGE_45_54',
        'Unknown'
      ]
    });

    $scope.setTabs([
      {
        name: `${state.collectionType}CollectionsProps`,
        sref: state.collectionId
          ? 'monitoring.collections.props'
          : 'monitoring.collections.create'
      }
    ]);

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getCollection(id) {
        return monitoringCollectionFactory.getCollectionProps(id).then(
          function(data) {
            state.collection = angular.copy(data.collection);
            state.collectionName = angular.copy(data.collection.name);
          },
          function(res) {
            $scope.showErrorModal(res.status);
            $state.go(
              `monitoring.collections.${state.collectionType}List`
            );
          }
        );
      },
      saveCollection() {
        if (handlers.validatePropsFilters()) {
          state.isBusyCollection = true;
          return monitoringCollectionFactory
            .saveCollectionProps(state.collection)
            .then(
              function() {
                $state.go(
                  `monitoring.collections.${state.collectionType}List`
                );
              },
              function(res) {
                if (res.status === 409) {
                  const conflictLabels = {
                    title:
                      'admin.monitoring.collections.titles.collectionNameConflict',
                    message:
                      'admin.monitoring.collections.messages.collectionNameConflict'
                  };
                  helpers.infoModal(conflictLabels);
                } else {
                  $scope.showErrorModal(res.status);
                }
              }
            )
            .finally(function() {
              state.isBusyCollection = false;
            });
        }
        const labels = {
          title: 'admin.monitoring.collections.titles.validatePropsFilters',
          message: 'admin.monitoring.collections.messages.validatePropsFilters'
        };
        return helpers.infoModal(labels);
      },
      isAllSelected(nodeName) {
        return (
          options[nodeName] &&
          options[nodeName].length &&
          state.collection[nodeName] &&
          options[nodeName].length === state.collection[nodeName].length
        );
      },
      selectAll(nodeName) {
        if (handlers.isAllSelected(nodeName)) {
          state.collection[nodeName] = [];
        } else {
          state.collection = angular.extend(
            {},
            state.collection,
            window._.pick(angular.copy(options), nodeName)
          );
        }
      },
      getCurrentSelectedItems(data, param) {
        return !param
          ? data
          : data.map(function(item) {
              return item[param];
            });
      },
      addTimeframeItem() {
        const stateModal = {};
        const handlersModal = {};
        stateModal.startPeriod = moment().startOf('day');
        stateModal.endPeriod = moment().startOf('day');
        handlersModal.validators = {
          startEnd(start, end) {
            return start <= end;
          },
          alreadyAdded() {
            return true;
          },
          toSmall() {
            return true;
          }
        };

        const labels = {
          title: 'admin.monitoring.collections.titles.addTimeframe'
        };
        helpers
          .addTimeframeModal(stateModal, handlersModal, labels)
          .result.then(function(timeframe) {
            state.collection.timeframe.push(timeframe);
          });
      },
      addItem(nodeName, labelType) {
        const stateModal = {};
        const labels = {
          title: `admin.monitoring.collections.titles.add${labelType}`,
          fieldLabel: `admin.monitoring.collections.labels.${labelType}`,
          validationPattern: `admin.monitoring.collections.messages.validation${labelType}`
        };
        const regexpStr = helpers.validationPatterns[nodeName];
        helpers
          .addStringModal(stateModal, labels, regexpStr)
          .result.then(function(data) {
            state.collection[nodeName].push(data.name);
            state.collection[nodeName] = window._.uniq(
              state.collection[nodeName]
            );
          });
      },
      addUrl() {
        const stateModal = {};
        const labels = {
          title: 'admin.monitoring.collections.titles.addurl',
          fieldLabel: 'admin.monitoring.collections.labels.url',
          fieldMsg: 'admin.monitoring.collections.labels.urlMsg',
          validationPattern:
            'admin.monitoring.collections.messages.validationUrl'
        };
        const regexpStr = helpers.validationPatterns.urls;
        helpers
          .addStringModal(stateModal, labels, regexpStr)
          .result.then(function(data) {
            state.collection.urls.push(data.name);
            state.collection.urls = window._.uniq(state.collection.urls);
          });
      },
      addMultiItem(nodeName) {
        let selectedItems = [];
        const optionNodeName = `${nodeName}All`;
        const requestName = `getOptions${conversionFactory.capitalize(
          optionNodeName
        )}`;
        const trackbyParam = itemSelectedAttrs[nodeName];
        if (!state[optionNodeName].length) {
          monitoringCollectionFactory[requestName]().then(function(data) {
            state[optionNodeName] = data;
            selectedItems = handlers.getCurrentSelectedItems(
              state.collection[nodeName],
              trackbyParam
            );
            handlers.showListModal(
              nodeName,
              state[optionNodeName],
              selectedItems
            );
          });
        } else {
          selectedItems = handlers.getCurrentSelectedItems(
            state.collection[nodeName],
            trackbyParam
          );
          handlers.showListModal(
            nodeName,
            state[optionNodeName],
            selectedItems
          );
        }
      },
      showListModal(nodeName, optionsList, selectedList, trackByParam) {
        const options = {
          title: `add${conversionFactory.capitalize(nodeName)}`,
          localization: !!~['occupation', 'interests'].indexOf(nodeName)
        };
        if (nodeName === 'sites') {
          options.searchParam = 'name';
        }
        // eslint-disable-next-line no-param-reassign
        trackByParam = itemSelectedAttrs[nodeName] || null;
        helpers
          .listModal(optionsList, selectedList, trackByParam, options)
          .result.then(
            function(checkedData) {
              if (trackByParam) {
                state.collection[nodeName] = helpers.filterBy(checkedData, [
                  trackByParam,
                  'name'
                ]);
              } else {
                state.collection[nodeName] = checkedData;
              }
            },
            function() {}
          );
      },
      removeItem(node, index) {
        const text = {
          title: `admin.monitoring.collections.titles.remove${conversionFactory.capitalize(
            node
          )}`,
          message: `admin.monitoring.collections.messages.remove${conversionFactory.capitalize(
            node
          )}`,
          param:
            state.collection[node][index].name || state.collection[node][index]
        };
        cancelConfirmModal(text).then(function() {
          state.collection[node].splice(index, 1);
        });
      },
      setBreadcrumbs() {
        breadcrumbs.set({
          path: `monitoring>collectionsList>${state.collectionType}Collection${
            state.collectionId ? '' : '>create'
          }`
            .split('>')
            .map(name => ({ name })),
          itemName: state.collectionName
        });
      },
      cancel: function cancel() {
        if ($scope.editMode) {
          const text = {
            title: `admin.monitoring.collections.titles.${
              state.collectionId
                ? 'cancelConfirmationEdit'
                : 'cancelConfirmationCreate'
            }`,
            message: `admin.monitoring.collections.messages.${
              state.collectionId
                ? 'cancelConfirmationEdit'
                : 'cancelConfirmationCreate'
            }`
          };
          cancelConfirmModal(text).then(function() {
            if (!state.collectionId) {
              $state.go(
                `monitoring.collections.${state.collectionType}List`
              );
            }
            $scope.editMode = false;
            init();
          });
        } else {
          $state.go(
            `monitoring.collections.${state.collectionType}List`
          );
        }
      },
      validatePropsFilters() {
        const keys = [
          'macAddress',
          'visitorNames',
          'gender',
          'age',
          'deviceType',
          'deviceOS',
          'timeframe',
          'occupation',
          'interests',
          'keywords',
          'urls',
          'sites',
          'city'
        ];
        return window._.some(
          keys.map(function(key) {
            return !!state.collection[key].length;
          }),
          function(value) {
            return value;
          }
        );
      }
    });

    function init() {
      state.collection = {};

      handlers.setBreadcrumbs();

      if (state.collectionId) {
        $scope.editMode = false;
        state.isBusy = true;
        handlers.getCollection(state.collectionId).then(function() {
          handlers.setBreadcrumbs();
          state.isBusy = false;
        });
      } else {
        $scope.editMode = true;
        state.collection = angular.copy(collectionDefault);
        state.isBusy = false;
      }
    }

    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => state.isBusyCollection,
        type: () => 'confirm',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.collectionNewForm.$valid) {
            handlers.saveCollection();
          }
        }
      },
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => !$scope.editMode,
        clickHandler: () => {
          $scope.editMode = true;
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      },
      {
        title: () => 'admin.buttons.close',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => !$scope.editMode,
        clickHandler: () => {
          $location.path(
            `/admin/monitoring/collections/${state.collectionType}/${
              state.collectionId
            }`
          );
        }
      }
    ]);

    // Helpers >>>
    function cancelConfirmModal(text) {
      let i18n;
      if (!text.param) {
        i18n = $filter('i18n');
        // eslint-disable-next-line no-param-reassign
        text.title = i18n(text.title);
        // eslint-disable-next-line no-param-reassign
        text.message = i18n(text.message);
      }
      return helpers.confirmationModal(text.title, text.message, text.param)
        .result;
    }
    // / Helpers <<<
  });
