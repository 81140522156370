const { angular } = window;

angular
  .module('wcm.core')

  .directive('bindHtmlUnsafe', function() {
    return function(scope, element, attr) {
      element.addClass('ng-binding').data('$binding', attr.bindHtmlUnsafe);
      scope.$watch(attr.bindHtmlUnsafe, function bindHtmlUnsafeWatchAction(
        value
      ) {
        element.html(value || '');
      });
    };
  });
