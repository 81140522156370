const { angular } = window;

angular
  .module('match', [])
  .directive('match', ['$parse', matchDirective]);

/**
 * Match directive.
 *
 * @example
 * <input type="password" ng-match="password">
 */

function matchDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ctrl) {
      scope.$watch(
        function() {
          return [scope.$eval(attrs.match), ctrl.$viewValue];
        },
        function(values) {
          ctrl.$setValidity('match', values[0] === values[1]);
        },
        true
      );
    }
  };
}
