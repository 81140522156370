/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.searchBar')
  .directive('wcmSearchBar', function($timeout) {
    return {
      restrict: 'AE',
      scope: {
        onChange: '&',
        onGo: '&',
        dropDownItems: '=?',
        searchQuery: '=?',
        mode: '=?',
        searchSources: '=?'
      },
      controller: 'searchBarCtrl',
      templateUrl: 'app/core/search/searchBar.html',
      link(scope, el) {
        let scrollContainer;

        scope.$watch('dropDownItems', function() {
          $timeout(setScrollContainer);
        });
        scope.$watch('focused', function() {
          $timeout(setScrollContainer);
        });

        const moveSelection = function(dir) {
          const combinedArray = scope.searchSources.reduce(function(acc, curr) {
            const items = scope.dropDownItems[curr].items;
            return acc.concat(items, items.length ? [`showAll:${curr}`] : []);
          }, []);

          let selectedItemIdx = combinedArray.indexOf(scope.selectedItem);

          switch (dir) {
            case 'up':
              selectedItemIdx =
                selectedItemIdx <= 0
                  ? combinedArray.length - 1
                  : selectedItemIdx - 1;
              break;
            case 'down':
              selectedItemIdx =
                selectedItemIdx === -1 ||
                selectedItemIdx >= combinedArray.length - 1
                  ? 0
                  : selectedItemIdx + 1;
              break;
            default:
              break;
          }
          scope.selectedItem = combinedArray[selectedItemIdx];

          if (selectedItemIdx > 10 && scrollContainer) {
            scrollContainer.scrollTop = 10000;
          }
          if (selectedItemIdx < 10 && scrollContainer) {
            scrollContainer.scrollTop = 0;
          }

          scope.$applyAsync();
        };

        el.keydown(function(event) {
          if (
            !(event.which === 40 || event.which === 38 || event.which === 13)
          ) {
            return true;
          }
          switch (event.which) {
            case 13:
              if (angular.isDefined(scope.selectedItem)) {
                if (angular.isObject(scope.selectedItem)) {
                  scope.onGo({
                    id: scope.selectedItem.id,
                    title: scope.selectedItem.title
                  });
                } else {
                  // show all
                  scope.updateView(scope.selectedItem.split(':')[1]);
                }

                el.find('input').blur();
                return false;
              }
              return true;
            case 40:
              moveSelection('down');
              return false;
            case 38:
              moveSelection('up');
              return false;
            default:
              return true;
          }
        });

        function setScrollContainer() {
          scrollContainer = el.find('.dropdown__menu')[0];
          if (scrollContainer) {
            scrollContainer.scrollTop = 0;
          }
        }

        el.find('form').submit(function() {
          el.find('input').blur();
        });
      }
    };
  });
