import ngDeps from 'ngDeps';
const { angular } = window;

angular.module('wcm.components').factory('breadcrumbs', function($state) {
  function set(breadcrumbs) {
    const fullPath = breadcrumbs.path || [];
    const itemName = breadcrumbs.itemName;
    const breadcrumbsObj = {};
    breadcrumbsObj.section = fullPath[0];
    breadcrumbsObj.subsections = fullPath.slice(1).map(sub => {
      // slice removes leading # sign
      return sub.sref ? { ...sub, href: $state.href(sub.sref).slice(1) } : sub;
    });
    breadcrumbsObj.itemName = itemName;

    // Currently, as we have multiroot app during refactoring, there is a moment after login
    // where new root is not mounted yet and thus setBreadcrumbs is not registered in ngDeps object.
    // on the next event loop tick everything should be ready. I hope so
    ngDeps.breadcrumbs && ngDeps.breadcrumbs.setBreadcrumbs
      ? ngDeps.breadcrumbs.setBreadcrumbs(breadcrumbsObj)
      : setTimeout(() => {
          try {
            ngDeps.breadcrumbs.setBreadcrumbs(breadcrumbsObj);
          } catch (ex) {
            // swallow. At least we have tried
          }
        });
    return breadcrumbsObj;
  }

  return {
    set
  };
});
