/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const { moment } = window;

angular
  .module('wcm.admin')
  .controller('monitoringVisitorPlacesCtrl', function(
    $scope,
    $state,
    $q,
    $timeout,
    analyticsFactory2,
    monitoringFactory,
    navigationPanel,
    helpers
  ) {
    let state = $scope.state;
    let exportFlag = false;
    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        state.isBusy = !!queue.length;
      }, 100);
    });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {});

    state = angular.extend(state, {
      isBusy: true,
      noDataAvailable: false,
      id: $state.params.id,
      currentPage: 1,
      itemsPerPage: 10,
      itemsOnPageValues: [10, 25, 50, 100],
      sortOrder: 'last_timestamp:desc',
      exportType: 'visitors',
      filters: {
        startPeriod: moment()
          .startOf('day')
          .subtract(30, 'day'),
        endPeriod: moment().startOf('day'),
        searchString: ''
      },
      viewType: $state.current.url.split('/').pop(),
      places: [],
      visitsOrder: false
    });

    options.minPeriod = moment($scope.currentUser.systemCreationDate).startOf(
      'day'
    );
    options.viewTypes = ['list', 'map'];
    options.analyticsPeriodLimit = $scope.currentUser.analyticsPeriodLimit;
    options.availableMinDate = $scope.currentUser.systemCreationDate;

    const reinitMinMaxPeriod = function() {
      // calculate Min Period
      options.minPeriod = analyticsFactory2.getAnalyticsMinPeriod(
        moment($scope.currentUser.systemCreationDate).startOf('day'),
        moment(state.filters.endPeriod).startOf('day'),
        options.analyticsPeriodLimit
      );
      // calculate Max Period
      options.maxPeriod = state.filters.endPeriod;
    };

    reinitMinMaxPeriod();
    state.filters.startPeriod = options.minPeriod;

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getPage: busyImpactor(function() {
        if (!isValidTimeFilters(state.filters)) return false;
        state.noDataAvailable = false;
        return monitoringFactory.placesGetList(state).then(
          function(data) {
            state.places = data.places;
            state.itemsTotal = data.itemsTotal;
          },
          function(res) {
            state.noDataAvailable = helpers.isDataNotAvailable(res.status);
            handlers.noServerRedirect(res.status);
            state.itemsTotal = 0;
            state.places = [];
            return $q.reject('no data available');
          }
        );
      }),
      onFiltersChange: window._.debounce(function() {
        state.currentPage = 1;
        handlers.getPage();
      }, 500),

      isAllSelected(blockName) {
        return (
          options[blockName] &&
          options[blockName].length &&
          options[blockName].length === state.filters[blockName].length
        );
      },
      selectAll(blockName) {
        if (handlers.isAllSelected(blockName)) {
          state.filters[blockName] = [];
        } else {
          state.filters = angular.extend(
            {},
            state.filters,
            window._.pick(angular.copy(options), blockName)
          );
        }
        handlers.onFiltersChange(state);
      },
      setSortOrder(sortOrder) {
        state.sortOrder = sortOrder;
        handlers.getPage(
          state.itemsPerPage,
          (state.currentPage = 1),
          state.sortOrder
        );
      },
      getSortOrder() {
        return state.sortOrder;
      },
      export() {
        exportFlag = true;
        return monitoringFactory
          .exportList(state, 'exportVisitorsPlaces')
          .finally(() => {
            exportFlag = false;
          });
      },
      noServerRedirect: $scope.rootHandlers.noServerRedirect
    });

    function isValidTimeFilters(timeFilters) {
      return analyticsFactory2.isValidTimeFilters(
        timeFilters,
        undefined,
        options.availableMinDate
      );
    }

    function init() {
      state.noDataAvailable = false;
      handlers.onFiltersChange(state);
    }

    $scope.$on('$locationChangeStart', function(e, next) {
      state.viewType = next.split('/').pop();
    });

    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.export',
        isBusy: () => exportFlag,
        isDisabled: () =>
          state.filters.startPeriod > state.filters.endPeriod ||
          !state.places.length,
        type: () => 'action-1',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.export();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.state.isEditMode,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      },
      {
        title: () => 'admin.buttons.close',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => !$scope.state.isEditMode,
        clickHandler: () => {
          $scope.close();
        }
      }
    ]);
  });
