/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.admin').constant('ADMIN_CONFIG', {
  usersServlet: 'admin',
  usersGetList: 'userGetList',
  userGetData: 'userGetData',
  userSave: 'userSave',
  userGetPermissions: 'getUserPermissions',
  userSavePermissions: 'saveUserPermissions',
  userMoveToArchive: 'userMoveToArchive',
  userRecoverFromArchive: 'userRecoverFromArchive',
  userRemove: 'userRemove',
  userGetSystemWideAnalyticsPermissions:
    'getUserSystemWideAnalyticsPermissions',
  userSaveSystemWideAnalyticsPermissions:
    'saveUserSystemWideAnalyticsPermissions',
  userGetReportsPermissions: 'getUserSystemWideReportsPermissions',
  userSaveReportsPermissions: 'saveUserSystemWideReportsPermissions',

  macExceptionsServlet: 'macexception',
  macExceptionsGetData: 'macExceptionGetData',
  macExceptionsGetList: 'macExceptionGetList',
  macExceptionDelete: 'macExceptionDelete',
  macExceptionSave: 'macExceptionSave',

  // system logs
  logsServlet: 'system',
  logsGetList: 'logsGetList',
  clientLogsGetList: 'clientLogsGetList',
  getAvailableLogsTypes: 'getAvailableLogsTypes'
});
