const { angular } = window;

angular
  .module('wcm.components')
  .factory('contactPersonaeFactory', function(
    requestFactory,
    $modal,
    $rootScope,
    COMMON_CONFIG
  ) {
    const defaultPerson = {
      id: 0,
      position: '',
      phone: '',
      email: '',
      additionalInfo: []
    };

    const getList = function(type, id) {
      return requestFactory.post(
        COMMON_CONFIG.commonServlet,
        COMMON_CONFIG.getPersonae,
        {
          objectType: type,
          objectId: +id
        }
      );
    };

    const openAddContactModal = function() {
      return openContactModal('create');
    };

    const openEditContactModal = function(contactPerson) {
      return openContactModal('edit', contactPerson);
    };

    function openContactModal(
      mode,
      contactPerson = window.$.extend(true, {}, defaultPerson)
    ) {
      const scope = $rootScope.$new(true);
      scope.mode = mode;
      scope.contactPerson = contactPerson;

      return $modal.open({
        templateUrl: 'app/core/components/contacts/contactModal.html',
        windowClass: 'contact_modal',
        scope,
        controller: 'contactModalCtrl'
      }).result;
    }

    return {
      getList,
      openAddContactModal,
      openEditContactModal
    };
  });
