/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.components')
  .directive('wcmSelect', function($parse) {
    return {
      restrict: 'AE',
      scope: true,
      replace: true,
      require: ['wcmSelect', 'ngModel'],
      templateUrl(tElement, tAttr) {
        return angular.isDefined(tAttr.searchable)
          ? 'app/core/components/dropdown/searchableDropdown.html'
          : 'app/core/components/dropdown/dropdownSelect.html';
      },
      transclude: true,
      controller($scope, $attrs) {
        // eslint-disable-next-line no-multi-assign
        const options = ($scope.options = this.options = []);

        this.addOption = function(value, text) {
          options.push({
            value,
            text
          });
        };

        $scope.$watchCollection(
          'options',
          function() {
            // eslint-disable-next-line no-multi-assign
            this.ngModelCtrl.$viewValue = this.ngModelCtrl.$$lastCommittedViewValue = this.ngModelCtrl.$formatters.reduceRight(
              function(prev, fn) {
                return fn(prev);
              },
              this.ngModelCtrl.$modelValue
            );
            this.ngModelCtrl.$render();
            this.ngModelCtrl.$$runValidators(
              this.ngModelCtrl.$modelValue,
              this.ngModelCtrl.$viewValue,
              angular.noop
            );
          }.bind(this)
        );

        this.removeOption = function(value) {
          const position = options.indexOf(this.findOptionByValue(value));
          return !!~position && options.splice(position, 1);
        }.bind(this);

        this.findOptionByValue = function(value) {
          if (!angular.isDefined(value)) return undefined;
          return this.options
            .filter(function(option) {
              return option.value === value;
            })
            .pop();
        }.bind(this);

        this.select = function(value) {
          const option = this.findOptionByValue(value);
          this.ngModelCtrl.$setViewValue(option);
          this.ngModelCtrl.$render();
          if (angular.isDefined($attrs.onSelect)) {
            $scope.$eval($attrs.onSelect, {
              value: option.value,
              text: option.text
            });
          }
        }.bind(this);
      },
      link(scope, el, attrs, ctrls) {
        const selectCtrl = ctrls[0];
        const ngModelCtrl = ctrls[1];
        selectCtrl.ngModelCtrl = ctrls[1];

        // transclude(scope, function(clone) {
        //  el.find('[wcm-transclude]').append(clone);
        // });

        ngModelCtrl.$render = function() {
          scope.$evalAsync(function() {
            // eslint-disable-next-line no-param-reassign
            scope.selectedItem = {
              text:
                ngModelCtrl.$viewValue && ngModelCtrl.$viewValue.text
                  ? ngModelCtrl.$viewValue.text
                  : '',
              value: ngModelCtrl.$modelValue
            };
            // eslint-disable-next-line no-param-reassign
            scope.placeholder = attrs.placeholder;
          });
        };

        ngModelCtrl.$parsers.push(function(value) {
          return value.value;
        });

        ngModelCtrl.$formatters.push(function(value) {
          const res = selectCtrl.findOptionByValue(value);
          return res || '';
        });

        const disabledParser = $parse(attrs.disabled || attrs.ngDisabled);
        scope.$watch(
          function() {
            return disabledParser(scope.$parent);
          },
          function(val) {
            // eslint-disable-next-line no-param-reassign
            scope.disabled = !!val;
          }
        );

        scope.$watch(function() {
          return attrs.placeholder;
        }, ngModelCtrl.$render);
      }
    };
  })
  .directive('wcmSelectOption', function($interpolate) {
    return {
      restrict: 'AE',
      require: '^wcmSelect',
      templateUrl: 'app/core/components/dropdown/dropdownSelectOption.html',
      transclude: true,
      link(scope, el, attrs, wcmSelect) {
        const interpolateFn = function() {
          return $interpolate(el.text().trim())(scope);
        };

        // eslint-disable-next-line no-param-reassign
        scope.disabled = false;
        scope.$watch(
          function() {
            return attrs.disabled;
          },
          function(val) {
            // eslint-disable-next-line no-param-reassign
            scope.disabled = val;
          },
          true
        );

        scope.$watch(interpolateFn, function(val) {
          const optionValue = scope.$eval(attrs.value);
          wcmSelect.removeOption(optionValue);
          wcmSelect.addOption(optionValue, val);
        });

        // eslint-disable-next-line no-param-reassign
        scope.select = function(e) {
          if (!scope.disabled) {
            wcmSelect.select(scope.$eval(attrs.value));
          } else {
            e.stopPropagation();
          }
        };

        // eslint-disable-next-line no-param-reassign
        scope.isActive = function() {
          return scope.$eval(attrs.value) === wcmSelect.ngModelCtrl.$modelValue;
        };

        scope.$on('$destroy', function() {
          wcmSelect.removeOption(scope.$eval(attrs.value));
        });
      }
    };
  })
  .directive('searchable', function($timeout, $sce, $parse, dropdownService) {
    return {
      require: '^wcmSelect',
      link(scope, el, attrs, wcmSelect) {
        const searchInput = el.find('.js-input');
        scope.isOpen = false;
        scope.filterQuery = '';

        scope.select = wcmSelect.select;

        scope.highlight = function(text, search) {
          if (!search) {
            return $sce.trustAsHtml(text);
          }
          return $sce.trustAsHtml(
            text.replace(new RegExp(search, 'gi'), '<b class="t-bold">$&</b>')
          );
        };

        scope.openDropdown = function() {
          scope.isOpen = !scope.isOpen;
          scope.filterQuery = '';
          $timeout(function() {
            searchInput.focus();
          });
          dropdownService.open(scope);
        };

        scope.getToggleElement = function() {
          return el.find('.dropdown__toggle');
        };

        searchInput.keyup(function(e) {
          return e.which === '32' && e.preventDefault(); // disable toggling dropdown on spacebar
        });
        if (angular.isDefined(attrs.prerender)) {
          scope.$parent.$watch($parse(attrs.prerender), function(val) {
            scope.prerender = !!val;
          });
        }
      }
    };
  });
