const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateSitesCtrl', function(
    $scope,
    $state,
    $q,
    $controller,
    $filter,
    $modal,
    reportingFactory,
    analyticsRequestsFactory,
    navigationPanel,
    analyticsFactory2,
    sitesFactory,
    helpers,
    ANALYTICS_CONFIG2
  ) {
    $controller('reportingCreateCtrl', { $scope });

    const A_CONFIG = ANALYTICS_CONFIG2;

    $scope.options = angular.extend($scope.options, {
      blocks: A_CONFIG.blocks.sites,
      minPeriod: $scope.currentUser.systemCreationDate
    });
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      type: 'sites'
    }));

    $scope.handlers = angular.extend($scope.handlers, {
      save() {
        const manageBlocks = analyticsRequestsFactory.getBlocksData();
        state.isBusy = true;
        reportingFactory
          .createSitesReport(state.report, state.filters, manageBlocks)
          .then(
            function() {
              state.editMode = false;
              $state.go(state.callbackRoute);
            },
            function() {
              state.noWas = true;
            }
          )
          .finally(function() {
            state.isBusy = false;
          });
      }
    });
    $scope.downloadSelectedSites = function() {
      const sites = {};
      state.manageBlocks.sitesFilter.sites.forEach(item => {
        if (item.checked) {
          sites[item.id] = true;
        }
      });
      sitesFactory.downloadSitesListForCampaign(sites);
    };

    // Check files from file
    $scope.uploadSitesForSelect = function(type, file) {
      sitesFactory.uploadSitesListForCampaign(type, file).then(
        function(res) {
          if (!res.siteIds) {
            return;
          }
          let newItem;
          state.manageBlocks.sitesFilter.sites = state.manageBlocks.sitesFilter.sites.map(
            function(item) {
              newItem = angular.copy(item);
              if (~res.siteIds.indexOf(item.id)) {
                newItem.checked = true;
              } else {
                newItem.checked = false;
              }
              return newItem;
            }
          );
          state.filters.siteIds = helpers.filterBy(
            state.manageBlocks.sitesFilter.sites,
            'id'
          );
        },
        function(res) {
          if (res === 'wrongExe') {
            const labels = {
              message: 'campaigns.modals.xls.errorXlsMsg',
              title: 'campaigns.modals.xls.errorXlsTitle'
            };
            infoModal(labels);
          }
        }
      );
    };

    function infoModal(labels) {
      const i18n = $filter('i18n');
      const scope = $scope.$new();
      scope.msg = i18n(labels.message);
      scope.title = i18n(labels.title);
      $modal.open({
        templateUrl: 'app/core/views/infoErrorGlobal.html',
        windowClass: 'wcm-msg',
        scope
      });
    }

    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.generate',
        isDisabled: () => $scope.state.noWas || $scope.state.isBusy,
        isShown: () => true,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.reportForm.$valid) {
            $scope.handlers.save();
          }
        }
      },
      {
        title: () => 'reporting.buttons.cancel',
        isDisabled: () => $scope.state.isBusy,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);
  });
