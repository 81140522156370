/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.reporting')
  .factory('reportingFactory', function(
    $q,
    $http,
    $filter,
    $rootScope,
    $timeout,
    $modal,
    helpers,
    localStorage,
    requestFactory,
    analyticsRequestsFactory,
    campaignVisitorsFactory,
    siteVisitorsFactory,
    monitoringFactory,
    sharedRequestsFactory,
    exportFactory,
    REPORTING_CONFIG
  ) {
    const config = REPORTING_CONFIG;
    const MILISECONDS_IN_MINUTE = 60000;

    let hardlinkReport;

    const getAvailableValues = function() {};

    const snakeToCamel = function(s) {
      return s.replace(/(-\w)/g, function(m) {
        return m[1].toUpperCase();
      });
    };

    /**
     * Only for backwards compatibility
     * @deprecated
     * @param report
     * @returns {*}
     */
    const prepareReportsProgress = function(report) {
      return report;
    };

    const prepareReportData = function(report, filters) {
      const r = angular.copy(report);
      r.blockList = exportFactory.prepareExportParams(r.blockList);
      r.isAllTime = filters.allTime;
      return r;
    };

    const preparePeriod = function(startPeriod, endPeriod) {
      return [startPeriod, endPeriod].map(function(moment) {
        const d = angular.copy(moment);
        // cast to utc midnight
        return d
          .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
          .startOf('day')
          .valueOf();
      });
    };

    const getBlocksStateName = function(name) {
      return config.mapBlocksStateNames[name] || name;
    };

    // Prepare helpers
    const prepareGetListParams = function(params) {
      return {
        filterByPeriod: preparePeriod(
          params.filters.startPeriod,
          params.filters.endPeriod
        ),
        filterBySearchString: params.filters.searchString,
        filterByType: config.listTypes[params.listType],
        filterItemsNumber: params.itemsPerPage,
        filterOffset: (params.currentPage - 1) * params.itemsPerPage,
        sortOrder: params.sortOrder
      };
    };
    const prepareAnalyticsCreateParams =
      analyticsRequestsFactory.mapFiltersToRequest;
    const prepareViewersCreateParams =
      campaignVisitorsFactory.mapStateToRequest;
    const prepareVisitorsCreateParams = siteVisitorsFactory.mapStateToRequest;
    const prepareMonitoringCreateParams = monitoringFactory.mapStateToRequest;

    const reportsGetList = function(params, mode) {
      const requestParams = prepareGetListParams(params);
      requestParams.terminateType =
        sharedRequestsFactory.reportsTerminateTypes.lists[params.listType];
      requestParams.searchStringMode = mode || '';
      return requestFactory
        .post(config.servlet, config.reportsGetList, requestParams)
        .then(
          function(res) {
            if (res.status === 244) {
              return $q.reject(res);
            }
            return res.data ? res.data : { itemsTotal: 0 };
          },
          function(res) {
            return $q.reject(res);
          }
        )
        .then(function(data) {
          // eslint-disable-next-line no-param-reassign
          data.reports = data.reports || [];
          // eslint-disable-next-line no-param-reassign
          data.reports = data.reports
            .map(prepareReportsProgress)
            .map(function(report) {
              return window._.extend({}, report, {
                shareLink: `${window.location.protocol}//${
                  window.location.host
                }/#/hardlink/${report.hash}`
              });
            });
          return data;
        });
    };

    const reportsPerformAction = function(state, items, action) {
      const requestParams = {
        action,
        reports: items,
        isAllReports: state.isAllReports,
        filterByType: config.listTypes[state.listType],
        filterByPeriod: preparePeriod(
          state.filters.startPeriod,
          state.filters.endPeriod
        ),
        terminateType:
          sharedRequestsFactory.reportsTerminateTypes.lists[state.listType]
      };
      return requestFactory
        .post(config.servlet, config.reportsPerformAction, requestParams)
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const createGeneralReport = function(report, filters, manageBlocks) {
      let requestParams = prepareReportData(report, filters);
      requestParams = angular.extend(
        requestParams,
        manageBlocks.amountOfViewsAndVisitors,
        prepareAnalyticsCreateParams(filters)
      );
      requestParams.terminateType = 'reportingGeneral';
      requestParams.intervalsCount = 12;
      return requestFactory
        .post(config.servlet, config.createGeneralReport, requestParams)
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getGeneralReport = function(state) {
      const requestParams = {
        id: state.reportId,
        terminateType: 'reportingGeneral'
      };
      return requestFactory
        .post(
          config.servlet,
          config.getGeneralReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };

    const createCampaignsReport = function(report, filters, manageBlocks) {
      let requestParams = prepareReportData(report, filters);
      requestParams = angular.extend(
        requestParams,
        manageBlocks.dataByComparisonCampaigns,
        prepareAnalyticsCreateParams(filters)
      );
      requestParams.terminateType = 'reportingCampaigns';
      requestParams.intervalsCount = 12;
      return requestFactory
        .post(
          config.servlet,
          config.createCampaignsReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };
    const getCampaignsReport = function(state) {
      const requestParams = {
        id: state.reportId,
        terminateType: 'reportingCampaigns'
      };
      return requestFactory
        .post(
          config.servlet,
          config.getCampaignsReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };
    const createCampaignSpecificReport = function(report, filters) {
      let requestParams = prepareReportData(report, filters);
      requestParams = angular.extend(
        requestParams,
        prepareAnalyticsCreateParams(filters)
      );
      requestParams.terminateType = 'reportingCampaignSpecific';
      requestParams.intervalsCount = 12;
      return requestFactory
        .post(
          config.servlet,
          config.createCampaignSpecificReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };
    const getCampaignSpecificReport = function(state) {
      const requestParams = {
        id: state.reportId,
        terminateType: 'reportingCampaignSpecific'
      };
      return requestFactory
        .post(
          config.servlet,
          config.getCampaignSpecificReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };

    const createSitesReport = function(report, filters, manageBlocks) {
      let requestParams = prepareReportData(report, filters);
      requestParams = angular.extend(
        requestParams,
        manageBlocks.dataByComparisonSites,
        prepareAnalyticsCreateParams(filters)
      );
      requestParams.terminateType = 'reportingSites';
      requestParams.isSystemWide = true;
      requestParams.intervalsCount = 12;
      return requestFactory
        .post(
          config.servlet,
          config.createSitesReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };
    const getSitesReport = function(state) {
      const requestParams = {
        id: state.reportId,
        terminateType: 'reportingSites'
      };
      return requestFactory
        .post(
          config.servlet,
          config.getSitesReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };
    const createSiteSpecificReport = function(report, filters) {
      let requestParams = prepareReportData(report, filters);
      requestParams = angular.extend(
        requestParams,
        prepareAnalyticsCreateParams(filters)
      );
      requestParams.terminateType = 'reportingSiteSpecific';
      requestParams.isSystemWide = false;
      requestParams.intervalsCount = 12;
      return requestFactory
        .post(
          config.servlet,
          config.createSiteSpecificReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };
    const getSiteSpecificReport = function(state) {
      const requestParams = {
        id: state.reportId,
        terminateType: 'reportingSiteSpecific'
      };
      return requestFactory
        .post(
          config.servlet,
          config.getSiteSpecificReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };

    const createViewersVisitorsReport = function(state) {
      let requestParams = prepareReportData(state.report, state.filters);
      // eslint-disable-next-line no-nested-ternary
      const prepareFunc =
        state.type === 'campaignSpecificViewers'
          ? prepareViewersCreateParams
          : state.type === 'siteSpecificVisitors'
          ? prepareVisitorsCreateParams
          : prepareMonitoringCreateParams;

      requestParams = angular.extend(requestParams, prepareFunc(state));
      requestParams.filterItemsNumber = state.filters.itemsPerPage;
      requestParams.filterByTop = +state.filters.top;
      requestParams.terminateType =
        sharedRequestsFactory.reportsTerminateTypes.createView[state.type];

      // map time filters for BE
      requestParams.type = state.type;
      requestParams.period = requestParams.filterByPeriod;
      requestParams.timeframe = requestParams.filterByTimeframe;
      requestParams.days = requestParams.filterByDays;
      delete requestParams.filterByPeriod;
      delete requestParams.filterByTimeframe;
      delete requestParams.filterByDays;

      return requestFactory
        .post(
          config.servlet,
          config.createViewersVisitorsReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };
    const getViewersVisitorsReport = function(state) {
      const requestParams = {
        id: state.reportId,
        filterOffset: (state.currentPage - 1) * state.filters.itemsPerPage,
        filterItemsNumber: state.filters.itemsPerPage,
        terminateType:
          sharedRequestsFactory.reportsTerminateTypes.createView[state.listType]
      };
      return requestFactory
        .post(
          config.servlet,
          config.getViewersVisitorsReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };

    const exportReport = function(state) {
      const requestParams = {
        id: state.reportId,
        terminateType:
          sharedRequestsFactory.reportsTerminateTypes.createView[state.listType]
      };
      return requestFactory
        .post(config.servlet, config.exportReport, requestParams)
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const refreshReport = function(state) {
      const requestParams = {
        id: state.reportId,
        terminateType:
          sharedRequestsFactory.reportsTerminateTypes.createView[state.listType]
      };
      return requestFactory
        .post(
          config.servlet,
          config.refreshReport,
          requestParams,
          {},
          { skipErrors: [404] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            if (res.status === 404) {
              helpers.redirectTo404Page();
              return $q.reject(res);
            }
            return $q.reject(res);
          }
        );
    };

    const searchInList = function(state) {
      const requestParams = {
        filterByPeriod: preparePeriod(
          state.filters.startPeriod,
          state.filters.endPeriod
        ),
        filterBySearchString: state.filters.searchString,
        filterByType: config.listTypes[state.listType],
        sortOrder: state.sortOrder,
        terminateType:
          sharedRequestsFactory.reportsTerminateTypes.createView[state.listType]
      };
      return requestFactory
        .post(config.servlet, config.searchInList, requestParams)
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const getReportByHardlink = function(hash, params = '') {
      return requestFactory
        .get(config.servlet, `hash=${hash}${params}`, {}, { skipErrors: [404] })
        .then(
          function(res) {
            return res;
          },
          function(res) {
            helpers.redirectTo404Page();
            return $q.reject(res);
          }
        );
    };

    const getReportsType = function(params) {
      return params[1] === 'monitoring'
        ? params[1]
        : snakeToCamel(`${params[2]}-${params[1]}`);
    };

    const convertParamsToColumns = function(params) {
      return params.map(function(item) {
        const p = item.slice(3);
        return (
          (p.length < 3 ? p.charAt(0) : p.charAt(0).toLowerCase()) + p.slice(1)
        );
      });
    };

    const getReportLink = function(params) {
      let str = '/#/reporting/';
      if (params[1] === 'monitoring') {
        str += 'monitoring';
      } else {
        str += `${params[2]}/${params[1]}`;
      }
      return str;
    };

    const getReportRoute = function(params) {
      let route = 'reporting.';
      if (params[1] === 'monitoring') {
        route += 'monitoring.view';
      } else {
        const p = params[2].split('-');
        route += `${p[0] + (p[1].charAt(0).toUpperCase() + p[1].slice(1))}.${
          params[1]
        }.view`;
      }
      return route;
    };

    const mapTabNameToReportType = function(tabName) {
      const map = {
        sitesReporting: 'sites',
        campaignsReporting: 'campaigns',
        generalReporting: 'general',
        campaignSpecificViewersReporting: 'campaignViewers',
        campaignSpecificReporting: 'campaignSpecific',
        siteSpecificVisitorsReporting: 'siteVisitors',
        siteSpecificReporting: 'siteSpecific'
      };
      return map[tabName] ? map[tabName] : tabName;
    };

    const hasPermissionsForType = function(userObj, tabName) {
      if (!userObj || !userObj.userId) return true; // no user object for hardlinks
      if (!userObj.userPermissions || !userObj.userPermissions.reporting) {
        throw new Error('Corrupted user object provided');
      }
      return userObj.userPermissions.reporting[mapTabNameToReportType(tabName)]
        .view;
    };

    function authorizeRoute(userObj, route) {
      if (!userObj || !userObj.userId) return true; // no user object for hardlinks
      if (!userObj.userPermissions || !userObj.userPermissions.reporting) {
        throw new Error('Corrupted user object provided');
      }
      // allow any section for admins and ghosts
      if (~['administrator', 'ghost'].indexOf(userObj.userRole.toLowerCase()))
        return true;
      const reportingSectionPermissions =
        userObj.userPermissions.reporting[mapRouteToPermissionsKey(route)];
      const permissionType = ~route.indexOf('create') ? 'create' : 'view';
      return reportingSectionPermissions[permissionType];
    }

    function getFallbackRoute(userObj, requestedRoute) {
      const subsection = requestedRoute.split('.')[1];
      const listsPriority = [
        'reporting.systemWide.general.list',
        'reporting.systemWide.campaigns.list',
        'reporting.systemWide.sites.list',
        'reporting.campaignSpecific.analytics.list',
        'reporting.campaignSpecific.viewers.list',
        'reporting.siteSpecific.analytics.list',
        'reporting.siteSpecific.visitors.list'
      ];

      return listsPriority
        .sort(function(a, b) {
          if (~a.indexOf(subsection)) return -1;
          if (~b.indexOf(subsection)) return 1;
          return 0;
        })
        .filter(function(route) {
          return authorizeRoute(userObj, route);
        })[0];
    }

    function mapRouteToPermissionsKey(route) {
      const routePath = route.split('.');
      switch (routePath[1]) {
        case 'systemWide':
          return routePath[2];
        case 'campaignSpecific':
          return routePath[2] === 'analytics'
            ? 'campaignSpecific'
            : 'campaignViewers';
        case 'siteSpecific':
          return routePath[2] === 'analytics' ? 'siteSpecific' : 'siteVisitors';
        default:
          return undefined;
      }
    }

    /**
     * Determines whether list of blocks has only excel blocks
     * Used for visitors view visitors/viewers lists
     *
     * @param blockList
     * @returns {boolean}
     */
    function hasOnlyExcelBlocks(blockList) {
      return !window._.difference(blockList, config.excelOnlyBlockNames).length;
    }

    return {
      getAvailableValues,

      reportsGetList,

      reportsPerformAction,

      createGeneralReport,
      getGeneralReport,

      createCampaignsReport,
      getCampaignsReport,
      createCampaignSpecificReport,
      getCampaignSpecificReport,

      createSitesReport,
      getSitesReport,
      createSiteSpecificReport,
      getSiteSpecificReport,

      createViewersVisitorsReport,
      getViewersVisitorsReport,

      exportReport,
      searchInList,
      refreshReport,

      prepareReportsProgress,
      getReportsType,
      getReportLink,
      getReportRoute,

      getBlocksStateName,
      convertParamsToColumns,

      getReportByHardlink,
      hardlinkReport,
      hasPermissionsForType,
      authorizeRoute,
      getFallbackRoute,
      hasOnlyExcelBlocks
    };
  });
