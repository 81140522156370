/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const L = require('leaflet');
require('leaflet/dist/leaflet.css');

angular
  .module('wcm.geoLocation')
  .factory('geoLocationFactory', function(
    GEO_LOCATION_CONFIG,
    requestFactory,
    conversionFactory,
    $http,
    $compile
  ) {
    const NEEDED_MARKER_CLASS = 'building';
    const SAVE_COORDS_TIMEOUT = 1000;
    const MAP_CENTER = [58.303889, 22.278889];

    const markerIcons = {
      radius: L.divIcon({
        className: 'marker-default-icon __radius',
        html: '',
        iconSize: [15, 15]
      }),
      default: L.divIcon({
        className: 'marker-default-icon',
        html: '',
        iconSize: [15, 15]
      }),
      defaultInactive: L.divIcon({
        className: 'marker-default-icon __small',
        html: '',
        iconSize: [15, 15]
      }),
      defaultActive: L.divIcon({
        className: 'marker-default-icon',
        iconSize: [18, 18]
      }),
      errorInactive: L.divIcon({
        className: 'marker-default-icon __error __small',
        iconSize: [15, 15]
      }),
      errorActive: L.divIcon({
        className: 'marker-default-icon __error',
        iconSize: [18, 18]
      }),
      confirmed: L.divIcon({
        className: 'marker-default-icon __confirmed',
        iconSize: [15, 15]
      }),
      confirmedInactive: L.divIcon({
        className: 'marker-default-icon __confirmed __small',
        iconSize: [15, 15]
      }),
      confirmedActive: L.divIcon({
        className: 'marker-default-icon __confirmed',
        iconSize: [18, 18]
      }),
      grey: L.divIcon({
        className: 'marker-default-icon __grey',
        iconSize: [15, 15]
      }),
      load0: L.divIcon({
        className: 'marker-default-icon __load0',
        iconSize: [15, 15]
      }),
      load1: L.divIcon({
        className: 'marker-default-icon __load1',
        iconSize: [15, 15]
      }),
      load2: L.divIcon({
        className: 'marker-default-icon __load2',
        iconSize: [15, 15]
      }),
      load3: L.divIcon({
        className: 'marker-default-icon __load3',
        iconSize: [15, 15]
      }),
      load4: L.divIcon({
        className: 'marker-default-icon __load4',
        iconSize: [15, 15]
      }),
      load5: L.divIcon({
        className: 'marker-default-icon __load5',
        iconSize: [15, 15]
      }),
      load6: L.divIcon({
        className: 'marker-default-icon __load6',
        iconSize: [15, 15]
      }),
      load7: L.divIcon({
        className: 'marker-default-icon __load7',
        iconSize: [15, 15]
      }),
      load8: L.divIcon({
        className: 'marker-default-icon __load8',
        iconSize: [15, 15]
      }),
      load9: L.divIcon({
        className: 'marker-default-icon __load9',
        iconSize: [15, 15]
      }),
      load10: L.divIcon({
        className: 'marker-default-icon __load10',
        iconSize: [15, 15]
      }),
      green: L.divIcon({
        className: 'marker-default-icon __green',
        iconSize: [15, 15]
      }),
      yellow: L.divIcon({
        className: 'marker-default-icon __yellow',
        iconSize: [15, 15]
      }),
      red: L.divIcon({
        className: 'marker-default-icon __red',
        iconSize: [15, 15]
      })
    };

    const setMarkerLabel = function(text) {
      return `<div class="marker_label">${text}</div>`;
    };

    const setMarkersIcons = function(markers, defaultIcon, showNumbers) {
      const icon = angular.copy(defaultIcon || markerIcons.default);
      markers.forEach(function(marker) {
        if (showNumbers) {
          icon.options.html = setMarkerLabel(marker.options.data.visitsOrder);
        }
        marker.setIcon(
          marker.options.data.checked
            ? markerIcons.defaultActive
            : markerIcons[marker.options.data.className] || icon
        );
      });
    };

    const updateMarkers = function(text) {
      return `<div class="marker_label">${text}</div>`;
    };

    const generateMarkerPopup = function(scope, popupType) {
      // console.log(scope.action())
      return $compile(
        angular.element(
          `<wcm-geo-marker-popup popup-type="${popupType}"></wcm-geo-marker-popup>`
        )
      )(scope);
    };

    // If address is present -> use address instead of zip
    // If only zip is present -> use only zip
    // If address and zip are present -> use address in first query, zip in second query and
    // merge results.
    const getCoordsByAddress = function(main, address, zip) {
      return $http
        .get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${address ||
            zip}, ${main}`
        )
        .then(function(res) {
          let dataToReturn = res.data.filter(function(item) {
            return item.class === NEEDED_MARKER_CLASS;
          });
          dataToReturn = dataToReturn.length ? dataToReturn : res.data;
          if (address && zip && zip !== 'n/a') {
            return getCoordsByAddress(main, null, zip).then(function(res) {
              return dataToReturn.concat(res);
            });
          }
          return dataToReturn;
        });
    };

    const saveSitesCoords = function(sites) {
      return requestFactory.post(
        GEO_LOCATION_CONFIG.sitesServlet,
        GEO_LOCATION_CONFIG.saveSitesCoords,
        { sites }
      );
    };

    const setSiteInfoByAddress = function(site) {
      return {
        coords: {
          latitude: +site.lat,
          longitude: +site.lon
        },
        address: site.display_name
      };
    };

    const debouncedSaveCoords = window._.debounce(
      saveSitesCoords,
      SAVE_COORDS_TIMEOUT
    );

    return {
      getCoordsByAddress,
      markerIcons,
      setMarkersIcons,
      generateMarkerPopup,
      setMarkerLabel,
      updateMarkers,
      debouncedSaveCoords,
      setSiteInfoByAddress,
      mapCenter: MAP_CENTER
    };
  });
