/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('monitoringCollectionCtrl', function(
    $scope,
    $state,
    $stateParams,
    $location,
    $q,
    $filter,
    $timeout,
    $modal,
    monitoringFactory,
    breadcrumbs,
    navigationPanel,
    monitoringCollectionFactory,
    sharedRequestsFactory,
    helpers
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isBusy: true,
      isBusyCollection: false,
      noDataAvailable: false,
      currentPage: 1,
      itemsPerPage: 10,
      itemsOnPageValues: [10, 25, 50, 100],
      sortOrder: 'last_visit:desc',
      visitors: [],
      visitorsRealData: [],
      collection: {},
      terminateType: 'visitorsCollection',
      collectionType: $location
        .path()
        .split('/')
        .reverse()[1],
      collectionId: +$stateParams.id,
      selectedVisitors: [],
      isSelectAllVisitors: false,
      filters: {
        searchString: ''
      }
    });

    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        state.isBusy = !!queue.length;
      }, 100);
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getPage: busyImpactor(function() {
        state.noDataAvailable = false;
        return monitoringCollectionFactory
          .collectionVisitorsGetList(state)
          .then(
            function(data) {
              state.itemsTotal = data.itemsTotal;
              state.visitors = data.visitors;
              state.visitorsRealData = data.visitors
                ? data.visitors.filter(function(item) {
                    return item;
                  })
                : data.visitors;
            },
            function(res) {
              state.noDataAvailable = helpers.isDataNotAvailable(res.status);
              state.visitors = [];
              state.itemsTotal = 0;
              return $q.reject('no data yet available');
            }
          );
      }),
      onFiltersChange: window._.debounce(function() {
        state.currentPage = 1;
        handlers.getPage();
      }, 500),
      setSortOrder(sortOrder) {
        state.sortOrder = sortOrder;
        state.currentPage = 1;
        handlers.getPage();
      },
      getCollectionViewData(collectionId) {
        return monitoringCollectionFactory
          .collectionGetViewData({ collectionId })
          .then(
            function(data) {
              state.collection = data.collection;
              handlers.setBreadcrumbs();
            },
            function(res) {
              state.noDataAvailable = helpers.isDataNotAvailable(res.status);
              $state.go(
                `monitoring.collections.${state.collectionType}List`
              );
            }
          );
      },
      removeVisitor(visitor) {
        const modalLabels = {
          title: 'admin.monitoring.collections.titles.removeVisitors',
          message: 'admin.monitoring.collections.messages.removeVisitors',
          param: state.collection ? state.collection.name : ''
        };
        cancelConfirmModal(modalLabels).then(function() {
          state.isBusy = true;
          monitoringCollectionFactory
            .removeFromCollection(state.collection.id, visitor.macAddress)
            .then(
              function() {
                return true;
              },
              function(res) {
                $scope.showErrorModal(res.status);
              }
            )
            .finally(handlers.onFiltersChange);
        });
      },
      setBreadcrumbs() {
        const path = `monitoring>collectionsList>${
          state.collectionType
        }Collection`.split('>');
        breadcrumbs.set({
          path: path.map(name => ({ name })),
          itemName: state.collection.name
        });
      },
      renameCollection() {
        const scope = $scope.$new(true);
        scope.state = {
          newStr: {
            name: state.collection.name
          }
        };
        scope.labels = {
          title: 'admin.monitoring.collections.titles.renameCollection',
          fieldLabel: 'admin.monitoring.collections.labels.collectionName'
        };

        $modal.open({
          scope,
          openedClass: 'isEdit',
          windowClass: 'add-string_modal',
          templateUrl: 'app/core/views/stringModal.html',
          controller($scope, $modalInstance) {
            $scope.state.isSubmit = false;
            $scope.forms = {};

            $scope.submit = function() {
              $scope.state.isSubmit = true;

              const collection = {
                id: state.collectionId,
                name: $scope.state.newStr.name
              };
              monitoringCollectionFactory.saveCollectionProps(collection).then(
                function() {
                  $modalInstance.close();
                  handlers.getCollectionViewData(state.collectionId);
                  state.collection.name = collection.name;
                  handlers.setBreadcrumbs();
                },
                function(res) {
                  if (res.status === 409) {
                    const conflictLabels = {
                      title:
                        'admin.monitoring.collections.titles.collectionNameConflict',
                      message:
                        'admin.monitoring.collections.messages.collectionNameConflict'
                    };
                    helpers
                      .infoModal(conflictLabels)
                      .result.finally(function() {
                        $scope.state.isSubmit = false;
                      });
                  }
                }
              );
            };
          }
        });
      },
      export() {
        return monitoringFactory.exportList(
          state,
          'exportCollectionVisitorsList'
        );
      }
    });

    // Send current state to each rootHandler function
    Object.keys($scope.rootHandlers).forEach(function(key) {
      $scope.handlers[key] = function(...args) {
        return $scope.rootHandlers[key](state, args[0]);
      };
    });

    // Init function
    function init() {
      state.noDataAvailable = false;
      state.isBusy = true;

      $scope.setTabs([
        {
          name: `${state.collectionType}CollectionsView`,
          sref: 'monitoring.collections.view'
        }
      ]);
      handlers.setBreadcrumbs();
      handlers.onFiltersChange();
      handlers.getCollectionViewData(state.collectionId);
    }

    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.monitoring.buttons.editCollection',
        isDisabled: () => state.isBusy || state.collection.isArchive,
        type: () => '',
        isShown: () => state.collectionType === 'filtered',
        clickHandler: () => {
          $location.path(
            `/admin/monitoring/collections/${state.collectionType}/${
              state.collectionId
            }/props`
          );
        }
      },
      {
        title: () => 'admin.monitoring.buttons.editCollectionName',
        isDisabled: () => state.isBusy || state.collection.isArchive,
        type: () => '',
        isShown: () => state.collectionType === 'manual',
        clickHandler: () => {
          handlers.renameCollection();
        }
      },
      {
        title: () => 'admin.buttons.export',
        isDisabled: () =>
          state.isBusy || !state.visitors.length || state.collection.isArchive,
        type: () => 'action-1',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.export();
        }
      },
      {
        title: () => 'admin.buttons.close',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => !$scope.state.isEditMode,
        clickHandler: () => {
          $location.path(
            `/admin/monitoring/collections/${state.collectionType}`
          );
        }
      }
    ]);

    $scope.$on('$stateChangeStart', function() {
      sharedRequestsFactory.terminateDA(
        sharedRequestsFactory.terminateTypes[state.terminateType]
      );
    });

    // Helpers >>>
    function cancelConfirmModal(text) {
      let i18n;
      if (!text.param) {
        i18n = $filter('i18n');
        // eslint-disable-next-line no-param-reassign
        text.title = i18n(text.title);
        // eslint-disable-next-line no-param-reassign
        text.message = i18n(text.message);
      }
      return helpers.confirmationModal(text.title, text.message, text.param)
        .result;
    }
  });
