/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.core')
  .directive('settingsBlockWithTabs', function() {
    return {
      restrict: 'AE',
      scope: true,
      replace: true,
      controller: 'settingsBlockWithTabsCtrl',
      transclude: true,
      templateUrl: 'app/core/views/settingsBlockWithTabs.html',
      link(scope, el, attr, ctrl, transclude) {
        transclude(scope, function(clone) {
          el.find('[settings-block-transclude]').append(clone);
        });
      }
    };
  })
  .directive('editable', function($parse) {
    return {
      require: '?settingsBlockWithTabs',
      link(scope, el, attrs, settingsBlockCtrl) {
        if (!settingsBlockCtrl) return true;
        const parser = $parse(attrs.editable);
        scope.$watch(
          function() {
            return parser(scope);
          },
          function(newVal) {
            settingsBlockCtrl.setEditable(newVal);
          }
        );
        return undefined;
      }
    };
  })
  .directive('canEdit', function() {
    return {
      require: '?settingsBlockWithTabs',
      link(scope, el, attrs, settingsBlockCtrl) {
        if (!settingsBlockCtrl) return true;
        attrs.$observe('canEdit', function(newVal) {
          settingsBlockCtrl.setCanEdit(scope.$eval(newVal));
        });
        return undefined;
      }
    };
  });
