/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
import Topbar from 'ui/Topbar';
import Sidebar from 'ui/Sidebar';

const { angular } = window;

angular
  .module('wcm.core', [
    'ui.router',
    // 'ui.utils',
    'ui.validate',
    'ui.slider',
    'checklist-model',
    'ngMessages',
    'angular-content-editable',
    'colorpicker.module',
    'match',
    // 'infinite-scroll',
    'wcm.i18n',
    // 'wcm.ui',
    'wcm.config',
    'wcm.auth',
    'wcm.helpers',
    'wcm.pagination',
    'wcm.theme',
    'wcm.errorHandling',
    'wcm.searchBar',
    'wcm.afkDetection',
    'wcm.components',
    'wcm.storage',
    'wcm.analytics2',
    'wcm.export',
    'wcm.exportButton',
    'wcm.geoLocation'
  ])
  .run(function($rootScope, afkFactory, auth, $state, $document, AUTH_EVENTS) {
    $document.on(
      'mousemove',
      window._.throttle(function() {
        $rootScope.$emit('afkDetection:reset');
      }, 5000)
    );

    afkFactory.init({
      updateAfkTimersEvent: 'afkDetection:reset',
      stopAfkDetectionEvent: AUTH_EVENTS.sessionDestroyed,
      startAfkDetectionEvent: AUTH_EVENTS.sessionCreated
    });
    auth.restoreSession();
  })
  .directive('reactTopbar', reactDirective => reactDirective(Topbar))
  .directive('reactSidebar', reactDirective => reactDirective(Sidebar));;
