/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.core')
  .controller('settingsBlockWithTabsCtrl', function(
    $scope,
    $modal,
    helpers,
    $parse,
    $timeout
  ) {
    // eslint-disable-next-line no-multi-assign
    const panes = ($scope.panes = []);
    $scope.selectedPane = {};

    $scope.select = function(pane) {
      angular.forEach(panes, function(pane) {
        // eslint-disable-next-line no-param-reassign
        pane.selected = false;
      });
      // eslint-disable-next-line no-param-reassign
      pane.selected = true;
      $scope.selectedPane = pane;
      $timeout(() => {
        $scope.$applyAsync();
      });
    };

    this.addPane = function(pane) {
      if (panes.length === 0) {
        $scope.select(pane);
      }
      panes.push(pane);
    };
  });
