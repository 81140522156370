/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('generalSystemAnalyticsCtrl', function(
    $scope,
    $q,
    $state,
    analyticsFactory2,
    analyticsRequestsFactory,
    helpers,
    $timeout,
    APP_CONFIG
  ) {
    const state = $scope.state;
    const options = $scope.options;
    const handlers = $scope.handlers;
    const user = $scope.currentUser;
    state.analyticsType = 'general';

    options.printTitle = 'System-wide general analytics';

    const setManageBlocksConfig = function() {
      state.manageFilterSettings.amountOfViewsAndVisitors = [
        {
          title: 'classAEntities',
          name: 'entity',
          values: state.manageBlocks.amountOfViewsAndVisitors.entities,
          trackBy: 'id'
        },
        {
          title: 'tagsSites',
          name: 'tags',
          values: state.manageBlocks.amountOfViewsAndVisitors.tags,
          trackBy: 'name',
          filterTypes: ['tagsClasses', 'tagsTypes']
        }
      ];
    };

    handlers.onTimelineFiltersChange = function() {
      state.isPresetChanged = true;
      const timeline = state.chartsData.campaignsTimeline;
      if (!timeline) return false;
      const filters = state.blocksState.campaignsTimeline.filters || [];
      const campaignTypes = helpers.getTruthyKeys(filters[0]);
      const campaignStates = helpers.getTruthyKeys(filters[1]);
      timeline.view = analyticsFactory2.filterTimelineData(
        timeline.requested,
        campaignTypes,
        campaignStates,
        filters[0].default
      );
      return undefined;
    };

    handlers.onValuesSelected = function(
      blockName,
      availableValues,
      checkedIds,
      disableRequests
    ) {
      state.isPresetChanged = true;

      if (blockName === 'amountOfViewsAndVisitors:sites') {
        state.manageBlocks.amountOfViewsAndVisitors.sites = availableValues;
        state.manageBlocks.amountOfViewsAndVisitors.checkedSites = checkedIds;
      }
      if (blockName === 'amountOfViewsAndVisitors:cities') {
        state.manageBlocks.amountOfViewsAndVisitors.cities = availableValues;
        state.manageBlocks.amountOfViewsAndVisitors.checkedCities = checkedIds;
      }

      if (~blockName.indexOf('amountOfViewsAndVisitors')) {
        const filtersData = {};
        filtersData.sites = handlers.filterBy(
          state.manageBlocks.amountOfViewsAndVisitors.sites,
          'id'
        );
        filtersData.cities = handlers.filterBy(
          state.manageBlocks.amountOfViewsAndVisitors.cities,
          'name'
        );
        filtersData.collapsedValues = {};
        filtersData.collapsedValues.sites = handlers.getCollapseItems(
          'sites',
          state.manageFilterSettings.amountOfViewsAndVisitors,
          state.manageBlocks.amountOfViewsAndVisitors.sites
        );
        analyticsRequestsFactory.setManageBlocksData(
          'amountOfViewsAndVisitors',
          filtersData
        );

        if (!disableRequests) {
          state.chartsData.amountOfViewsAndVisitors.requested = 'pending';
          analyticsRequestsFactory
            .getAmountOfViewsAndVisitors(state.filters, {}, state.analyticsType)
            .then(function(data) {
              state.chartsData.amountOfViewsAndVisitors = data;
            })
            .catch(function(res) {
              handlers.setEmptyDataForBlock(
                res.status,
                'amountOfViewsAndVisitors'
              );
            });
        }
      }
    };

    handlers.setTabs = function() {
      // use timeout here to delay controller initialization to the moment when `main view`
      // directive sets its tabs handlers to the scope
      $timeout(function() {
        $scope.setTabs(
          [
            {
              name: 'analytics.sites',
              sref: 'analytics.sites'
            },
            {
              name: 'analytics.campaigns',
              sref: 'analytics.campaigns',
              current: true
            }
          ].filter(function(tab) {
            return analyticsFactory2.hasPermissionsForType(
              user,
              tab.name.split('.')[1]
            );
          })
        );
        // go to the first available tab, if we don't have access to current one
        const tabs = $scope.currentPath.tabs;
        if (
          !window._.find(tabs, function(tab) {
            return tab.current === true;
          })
        ) {
          $state.go(tabs[tabs.length - 1].sref);
        }
      });
    };

    handlers.initManageBlocks = function(manageBlockFilters) {
      state.isBusy = false;

      if (state.noWas) return false;

      const amountOfViewsAndVisitors = {
        sites: angular.copy(options.availableValues.sites),
        cities: angular.copy(options.availableValues.sitesCities),
        entities: angular.copy(options.availableValues.partners),
        tags: angular.copy(options.availableValues.sitesTags),
        checkedSites:
          manageBlockFilters && manageBlockFilters
            ? manageBlockFilters.checkedSites
            : [],
        checkedCities:
          manageBlockFilters && manageBlockFilters
            ? manageBlockFilters.checkedCities
            : []
      };

      if (!manageBlockFilters) {
        amountOfViewsAndVisitors.sites.forEach(function(site) {
          // eslint-disable-next-line no-param-reassign
          site.checked = false;
        });
        amountOfViewsAndVisitors.cities.forEach(function(city) {
          // eslint-disable-next-line no-param-reassign
          city.checked = false;
        });
      } else {
        amountOfViewsAndVisitors.sites.forEach(function(site) {
          if (
            ~manageBlockFilters.amountOfViewsAndVisitors.checkedSites.indexOf(
              site.id
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            site.checked = true;
          }
        });
        amountOfViewsAndVisitors.cities.forEach(function(city) {
          if (
            ~manageBlockFilters.amountOfViewsAndVisitors.checkedCities.indexOf(
              city.name
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            city.checked = true;
          }
        });
      }

      const filtersData = {};
      filtersData.collapsedValues = {};
      filtersData.collapsedValues.sites = handlers.getCollapseItems(
        'sites',
        [],
        amountOfViewsAndVisitors.sites
      );
      filtersData.sites = handlers.filterBy(
        amountOfViewsAndVisitors.sites,
        'id'
      );
      filtersData.cities = handlers.filterBy(
        amountOfViewsAndVisitors.cities,
        'name'
      );
      analyticsRequestsFactory.setManageBlocksData(
        'amountOfViewsAndVisitors',
        filtersData
      );

      handlers.registerManageBlock(
        'amountOfViewsAndVisitors',
        amountOfViewsAndVisitors
      );

      setManageBlocksConfig();
      return undefined;
    };

    $scope.init = function() {
      if (!analyticsFactory2.isAnyPermissions(user)) {
        $state.go(APP_CONFIG.defaultState);
        return false;
      }
      handlers.setTabs();
      handlers.resetManageBlocks();
      return handlers.getAvailableValues().then(function() {
        $scope.$parent.init();
        handlers.initManageBlocks();
      });
    };
  });
