import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useBreadcrumbs } from './BreadcrumbsContext';
import { useTranslation } from 'react-i18next';
import styles from './Breadcrumbs.module.css';
import classNames from 'classnames';

export const Breadcrumbs = props => {
  const [breadcrumbs] = useBreadcrumbs();
  const { t } = useTranslation();
  if (!breadcrumbs || !breadcrumbs.section) return null;
  return (
    <div data-hook-id="breadcrumbs" className={styles.container}>
      <span
        data-hook-id="t_breadcrumbs-section-name"
        className={classNames(styles.item, styles.sectionItem)}
      >
        {t(`breadcrumbs.sections.${breadcrumbs.section.name}`)}
      </span>
      {breadcrumbs.subsections.map(subSection => {
        const text = t(
          `breadcrumbs.subsections.${breadcrumbs.section.name}.${subSection.name}`
        );
        return (
          <Fragment key={subSection.name}>
            <span className={styles.arrow}></span>
            <span
              className={styles.item}
              data-hook-id="t_breadcrumbs-subsection-name"
              ng-bind="'breadcrumbs.subsections.' + $ctrl.section.name + '.' + subsection.name | i18n"
            >
              {subSection.href ? (
                <Link
                  to={subSection.href}
                  data-hook-id="t_breadcrumbs-subsection-link"
                >
                  {text}
                </Link>
              ) : (
                text
              )}
            </span>
          </Fragment>
        );
      })}
      {breadcrumbs.itemName ? (
        <span
          data-hook-id="t_breadcrumbs-item-name"
          className={classNames(styles.item, styles.itemName)}
        >
          {breadcrumbs.itemName}
        </span>
      ) : null}
    </div>
  );
};

export default Breadcrumbs;
