/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('monitoringFactory', function(
    $q,
    $http,
    $filter,
    $rootScope,
    $timeout,
    $modal,
    helpers,
    modificationHistory,
    localStorage,
    MONITORING_CONFIG,
    CAMPAIGNS_CONFIG,
    requestFactory,
    sharedRequestsFactory,
    analyticsFactory2
  ) {
    const visitorDetailsPrototype = {
      OS: '',
      birthDate: {},
      city: '',
      comment: '',
      country: '',
      device: '',
      email: '',
      firstName: '',
      gender: '',
      images: [],
      lastName: '',
      macAddress: '',
      occupation: [],
      phoneNumber: '',
      facebookProfile: '',
      vkontakteProfile: ''
    };

    const MILISECONDS_IN_MINUTE = 60000;

    const mapStateToRequest = function(state) {
      const filters = state.filters || {};
      return {
        filterByPeriod: [filters.startPeriod, filters.endPeriod].map(function(
          moment
        ) {
          const _moment = angular.copy(moment);
          // cast to utc midnight
          return _moment
            .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
            .startOf('day')
            .valueOf();
        }),
        filterByTimeframe: [
          filters.startTimeFrame || 0,
          filters.endTimeFrame || 0
        ],
        filterByDays: filters.daysOfWeek || [],
        filterBySearchString: filters.searchString || '',
        filterByGender: filters.genders || [],
        filterByAge: filters.ages || [],
        filterByDeviceType: filters.deviceTypes || [],
        filterByOs: filters.OS || [],
        sortOrder: state.sortOrder
      };
    };

    const mapTrendsPlacesStateToRequest = function(state) {
      const filters = state.filters || {};
      return {
        id: state.id,
        filterItemsNumber: state.itemsPerPage,
        filterByPeriod: [filters.startPeriod, filters.endPeriod].map(function(
          moment
        ) {
          const _moment = angular.copy(moment);
          // cast to utc midnight
          return _moment
            .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
            .startOf('day')
            .valueOf();
        }),
        filterBySearchString: filters.searchString || '',
        sortOrder: filters.sortOrder || state.sortOrder
      };
    };

    const mapCollectionStateToRequest = function(state) {
      return {
        collectionId: state.collectionId,
        filterBySearchString: state.filters.searchString || ''
      };
    };

    const mapExportFiltersToRequest = function(state, params) {
      const filters = state.filters;
      if (!filters) return null;

      const returnFilters = {
        period: [filters.startPeriod, filters.endPeriod].map(function(moment) {
          const _moment = angular.copy(moment);
          // cast to utc midnight
          return _moment
            .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
            .startOf('day')
            .valueOf();
        }),
        filterBySearchString: filters.searchString || '',
        genders: filters.genders || [],
        ages: filters.ages || [],
        deviceTypes: filters.deviceTypes || [],
        OS: filters.OS || [],
        sortOrder: state.sortOrder
      };

      if (params) {
        Object.keys(returnFilters).forEach(function(item) {
          if (!~params.indexOf(item)) {
            delete returnFilters[item];
          }
        });
      }

      return returnFilters;
    };

    const getAvailableValues = function() {
      return requestFactory
        .post(
          CAMPAIGNS_CONFIG.campaignsServlet,
          CAMPAIGNS_CONFIG.campaignGetAvailableValues,
          {
            type: ''
          }
        )
        .then(function(res) {
          return res.data ? res.data : {};
        })
        .then(function(values) {
          const _values = values.visitors;
          return {
            ages: _values.age,
            genders: _values.gender,
            deviceTypes: _values.deviceType,
            OS: _values.os,
            tops: [10, 50, 100]
          };
        });
    };

    const visitorGetList = function(state) {
      const offset = (state.currentPage - 1) * state.itemsPerPage;
      let filters = {
        sortOrder: state.sortOrder,
        filterOffset: offset,
        filterItemsNumber: state.itemsPerPage,
        uniquePageId: $rootScope.uniqueBrowserTabToken,
        terminateType: sharedRequestsFactory.terminateTypes[state.terminateType]
      };
      if (state.collectionId) {
        filters = angular.extend(
          filters,
          mapCollectionStateToRequest(state)
        );
        filters.collectionId = state.collectionId;
      } else {
        filters = angular.extend(filters, mapStateToRequest(state));
      }
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.visitorGetList,
          filters
        )
        .then(
          function(res) {
            if (
              res.status === 204 ||
              res.status === 244 ||
              !res.data.visitors ||
              !res.data.itemsTotal
            ) {
              return $q.reject(res);
            }
            return res.data || {};
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const trendsGetList = function(state) {
      const offset = (state.currentPage - 1) * state.itemsPerPage;
      let filters = {
        filterOffset: offset,
        filterByType: state.type
      };
      filters = angular.extend(
        filters,
        mapTrendsPlacesStateToRequest(state)
      );
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.trendsGetList,
          filters,
          {},
          { skipErrors: [502, 504, 204, 244] }
        )
        .then(
          function(res) {
            if (
              res.status === 204 ||
              res.status === 244 ||
              !res.data.trends ||
              !res.data.itemsTotal
            ) {
              return $q.reject(res);
            }
            return res.data || {};
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    // >>>>> DEPRECATED! <<<<<
    // var placesApplyFilters = function (state){
    //   var filters = mapStateToRequest(state);
    //   var requestFilters = {};
    //   requestFilters.id = state.id;
    //   requestFilters.filterByPeriod = filters.filterByPeriod;
    //   requestFilters.filterBySearchString = filters.filterBySearchString;
    //   requestFilters.sortOrder = filters.sortOrder;
    //   return requestFactory
    //       .post(
    //         MONITORING_CONFIG.servlet,
    //         MONITORING_CONFIG.placesApplyFilters,
    //         requestFilters,
    //         {},
    //         {skipErrors: [502, 504, 204, 244]}
    //       )
    //       .then(function(res) {
    //         if (res.status === 244 || res.status === 204) {
    //           return $q.reject(res)
    //         }
    //         return res.data && res.data.itemsTotal ? res.data.itemsTotal : 0
    //       }, function(res) {
    //         helpers.noServerInfoModal(res.status);
    //         return $q.reject(res);
    //       })
    // };

    const placesGetList = function(state) {
      const offset = (state.currentPage - 1) * state.itemsPerPage;
      let filters = {
        filterOffset: offset
      };
      filters = angular.extend(
        filters,
        mapTrendsPlacesStateToRequest(state)
      );
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.placesGetList,
          filters,
          {},
          { skipErrors: [502, 504, 204, 244] }
        )
        .then(
          function(res) {
            if (
              res.status === 204 ||
              res.status === 244 ||
              !res.data.places ||
              !res.data.itemsTotal
            ) {
              return $q.reject(res);
            }
            return res.data || {};
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const visitorGetInterests = function(state) {
      const filters = {};
      filters.id = state.id;
      filters.filterBySearchString = state.filters.searchString;
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.visitorGetInterests,
          filters,
          {},
          { skipErrors: [502, 504, 204, 244] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        )
        .then(function(data) {
          const final = { requested: data, view: {} };
          final.view = analyticsFactory2.preparePieData(
            final.requested.data[0],
            'monitoring_visitorInterests'
          );
          return final;
        });
    };

    const getVisitorDetails = function(visitorId) {
      const filters = {};
      filters.id = visitorId;
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.getVisitorDetails,
          filters,
          {},
          { skipErrors: [502, 504, 244] }
        )
        .then(
          function(res) {
            if (res.status === 244) {
              return $q.reject(res);
            }
            // eslint-disable-next-line no-param-reassign
            res.data = res.data || {};
            // eslint-disable-next-line no-param-reassign
            res.data.visitor = angular.extend(
              visitorDetailsPrototype,
              { macAddress: visitorId },
              res.data.visitor
            );
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const saveVisitorDetails = function(visitor, state) {
      const details = window._.omit(visitor, 'images', 'device', 'OS');

      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.saveVisitorDetails,
          { visitor: details, overrides: state.shouldBeOverride },
          {},
          { skipErrors: [502, 504, 244] }
        )
        .then(
          function(res) {
            if (res.status === 244) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    // Export
    const exportList = function(state, action) {
      const params = ~[
        'exportVisitorsUrls',
        'exportVisitorsKeywords',
        'exportVisitorsPlaces'
      ].indexOf(action)
        ? ['period', 'sortOrder', 'filterBySearchString']
        : null;
      let filters = {};

      if (
        action !== 'exportVisitorsInterests' &&
        action !== 'exportCollectionVisitorsList'
      ) {
        filters = mapExportFiltersToRequest(state, params) || {};
      } else if (action === 'exportCollectionVisitorsList') {
        filters.sortOrder = state.sortOrder;
        filters.collectionId = state.collectionId;
        filters.filterBySearchString = state.filters.searchString;
      }
      const id = +state.siteId || state.id;
      if (id) {
        filters.id = id;
      }

      filters.uniquePageId = $rootScope.uniqueBrowserTabToken;

      return requestFactory
        .post(MONITORING_CONFIG.servlet, MONITORING_CONFIG[action], filters)
        .then(function(res) {
          if (res.data.file) {
            window.open(res.data.file, '_blank');
          }
        });
    };

    const getVisitorModificationHistory = function(id) {
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.getVisitorModificationHistory,
          { id }
        )
        .then(function(res) {
          return res.data && res.data.items ? res.data.items.reverse() : [];
        })
        .then(function(items) {
          return modificationHistory.mapResponseToViewModel(items);
        });
    };

    return {
      getAvailableValues,
      visitorGetList,
      placesGetList,
      visitorGetInterests,
      getVisitorDetails,
      saveVisitorDetails,
      trendsGetList,
      exportList,
      getVisitorModificationHistory,
      mapStateToRequest
    };
  });
