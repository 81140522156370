/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.campaigns')
  .directive('livePrognosisImpact', function() {
    return {
      restrict: 'E',
      scope: {
        startDate: '=?',
        endDate: '=?',
        sites: '=',
        impacts: '=?'
      },
      templateUrl: 'app/sections/campaigns/impact.html',
      link(scope) {
        scope.positionImpactItem = function(
          periodStart,
          periodEnd,
          itemStart,
          itemEnd
        ) {
          const periodLength = periodEnd - periodStart;
          const left = ((itemStart - periodStart) * 100) / periodLength;
          const width = itemEnd
            ? ((itemEnd - itemStart) * 100) / periodLength
            : 100;
          return {
            left: `${left > 0 ? left : 0}%`,
            width: `${left > 0 ? width : width + left}%`
          };
        };
      }
    };
  })
  .directive('impactTooltip', function($tooltip) {
    return $tooltip('impactTooltip', 'impactTooltip', 'click');
  })
  .directive('impactTooltipPopup', function(campaignsFactory, $timeout) {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        title: '@',
        content: '=',
        placement: '@',
        animation: '&',
        isOpen: '&',
        $close: '&'
      },
      templateUrl: 'app/sections/campaigns/impactTooltip.html',
      link(scope, el) {
        const $tooltip = el.find('.impact-tooltip_cnt');
        const $livePrognosisCnt = el.closest('.live-prognosis_scroll-cnt');
        const newY =
          el.offset().top -
          $livePrognosisCnt.offset().top +
          $livePrognosisCnt.scrollTop();
        $tooltip.css('top', newY);
        $livePrognosisCnt.append($tooltip);

        $timeout(function() {
          window.$(document).on('click.impactTooltip', function() {
            scope.$close();
          });
        });

        el.on('$destroy', function() {
          window.$(document).off('.impactTooltip');
          $tooltip.remove();
        });
      },
      controller($scope) {
        $scope.options = {
          itemsCurrentPage: 1,
          itemsPerPage: 10,
          itemsOnPageValues: [10]
        };

        campaignsFactory
          .campaignGetSitesForImpact({
            id: $scope.content.impact.campaignId,
            sites: $scope.content.sites
          })
          .success(function(data) {
            $scope.sites = data.sites;
          });

        // Pagination in Impact Sites!
        $scope.getItemsPage = function getItemsPage() {
          const filteredItems = $scope.sites || [];
          const currentPage = $scope.options.itemsCurrentPage || 1;
          const startIndex = (currentPage - 1) * $scope.options.itemsPerPage;
          $scope.itemsPage = filteredItems.slice(
            startIndex,
            startIndex + $scope.options.itemsPerPage
          );
        };
        $scope.$watch('sites', function() {
          $scope.getItemsPage();
        });
      }
    };
  });
