/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.campaigns').constant('CAMPAIGNS_CONFIG', {
  campaignsServlet: 'campaigns',
  analyticsCampaignServlet: 'analyticsCampaign',
  commonServlet: 'common',
  getList: 'campaignGetList',
  moveToArchive: 'campaignMoveToArchive',
  recoverFromArchive: 'campaignRecoverFromArchive',
  campaignGetData: 'campaignGetData',
  campaignConfirmAndSave: 'campaignConfirmAndSave',
  campaignPrognoze: 'campaignPrognoze',
  campaignSave: 'campaignSave',
  campaignGetAvailableValues: 'campaignGetAvailableValues',
  campaignGetAllCampaigns: 'campaignGetAllCampaigns',
  campaignGetOccupation: 'campaignGetOccupation',
  campaignGetInterests: 'campaignGetInterests',
  campaignGetAllCities: 'campaignGetAllCities',
  campaignGetAllCountries: 'campaignGetAllCountries',
  campaignPerformAction: 'campaignPerformAction',
  campaignConfirmAction: 'campaignConfirmAction',
  campaignRemove: 'campaignRemove',
  generateDemoLink: 'generateDemoLink',
  getCampaignsForDemo: 'getCampaignsForDemo',
  getLivePrognosis: 'campaignGetLivePrognosis',
  campaignGetSitesForImpact: 'getSitesForImpactCampaign',
  getAllCampaigns: 'getAllCampaigns',

  campaignVisitorsServlet: 'analyticsVisitor',
  // campaignVisitorsApplyFilters: 'visitorApplyFilters',
  campaignVisitorsGetList: 'visitorGetList'
});
