/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.tags')
  .controller('tagsCtrl', function($scope, $modal, $filter, helpers) {
    $scope.addTag = function() {
      const scope = $scope.$new();
      scope.availbaleTags = $scope.availableTags;
      $modal
        .open({
          templateUrl: 'app/core/tags/addTagModal.html',
          windowClass: 'add-tag_modal',
          controller($scope, $modalInstance) {
            $scope.newTag = {
              name: ''
            };
            $scope.forms = {};
            $scope.state = { submitted: false };
            $scope.ok = function() {
              if (
                !~$scope.currentTags
                  .map(toLowerCase)
                  .indexOf($scope.newTag.name.toLowerCase())
              ) {
                if (
                  !~$scope.availbaleTags
                    .map(toLowerCase)
                    .indexOf($scope.newTag.name.toLowerCase())
                ) {
                  const i18n = $filter('i18n');
                  const tagTitle = i18n('modals.tags.titleAdd');
                  const tagMessage = i18n('modals.tags.add');
                  helpers
                    .confirmationModal(tagTitle, tagMessage, $scope.newTag.name)
                    .result.then(function() {
                      scope.availbaleTags.push($scope.newTag.name);
                      $modalInstance.close($scope.newTag.name);
                    });
                } else {
                  $modalInstance.close($scope.newTag.name);
                }
              } else {
                const labelsInfo = {
                  title: 'components.tags.tagsTitle',
                  message: 'components.tags.stringDuplicationMessage'
                };
                helpers.infoModal(labelsInfo);
              }
            };
            $scope.selectTag = function(tagName) {
              $scope.isInputFocused = false;
              $scope.newTag.name = tagName;
            };
            $scope.focus = function() {
              $scope.isInputFocused = true;
            };
          },
          scope
        })
        .result.then(function(tag) {
          $scope.currentTags.push(tag);
        });
    };
    $scope.removeTag = function(tag) {
      helpers
        .confirmationModal(
          $scope.titleRemove,
          $scope.modalMessageRemove,
          tag,
          null,
          { className: 'remove-tag_modal' }
        )
        .result.then(function() {
          const index = $scope.currentTags.indexOf(tag);
          $scope.currentTags.splice(index, 1);
        });
    };

    function toLowerCase(value) {
      if (typeof value !== 'string') return value;
      return value.toLowerCase();
    }
  });
