/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.auth')
  .factory('session', function(
    $rootScope,
    $http,
    $q,
    localStorage,
    requestFactory,
    AUTH_CONFIG,
    AUTH_EVENTS
  ) {
    let user = {};

    const create = function(options) {
      angular.forEach(AUTH_CONFIG.sessionParams, function(param) {
        localStorage.set(param, options[param]);
        user[param] = options[param];
      });
      $rootScope.$broadcast(AUTH_EVENTS.sessionCreated, user);
      return currentUser();
    };

    const destroy = function() {
      user = {};
      angular.forEach(AUTH_CONFIG.sessionParams, function(param) {
        localStorage.remove(param);
      });
      $rootScope.$broadcast(AUTH_EVENTS.sessionDestroyed);
      return '';
    };

    function currentUser() {
      return angular.copy(user);
    }

    const restoreSession = function restoreSession() {
      const dfd = $q.defer();
      const sessionId = localStorage.get('sessionId');
      if (!sessionId) {
        dfd.reject('session not found');
        return dfd.promise;
      }
      requestFactory
        .post('login', 'sessionResetTimeout', {}, { sessionId })
        .then(
          function() {
            angular.forEach(AUTH_CONFIG.sessionParams, function(param) {
              user[param] = localStorage.get(param);
            });
            $rootScope.$broadcast(AUTH_EVENTS.sessionCreated, user);
            // eslint-disable-next-line no-param-reassign
            $http.defaults.headers.common.sessionId = user.sessionId;
            dfd.resolve(user);
          },
          function() {
            dfd.reject('session expired');
          }
        );
      return dfd.promise;
    };

    const isDemoMode = function isDemoMode() {
      return user.launchMode === 'demo';
    };

    const setSessionParam = function(key, value) {
      localStorage.set(key, value);
      user[key] = value;
      return currentUser();
    };

    return {
      isDemoMode,
      create,
      destroy,
      currentUser,
      restoreSession,
      setSessionParam
    };
  });
