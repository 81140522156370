import { parse } from 'qs';
import { object, string, ref } from 'yup';
import * as api from './login.api';
import ngDeps from 'ngDeps';
import config from 'config';

export const signInFormInitialValues = {
  userId: '',
  password: ''
};

export const signInFormSchema = object().shape({
  userId: string().required(),
  password: string().required()
});

export const signIn = ({ userId, password }, { history, location }) => {
  return api.signIn({ userId, password }).then(({ userInfo }) => {
    // angular ui router has custom encoding for slashes, so need to normalize query string
    const { redirect } = parse(location.search.replace(/~2F/g, '%2F'), {
      ignoreQueryPrefix: true
    });
    ngDeps.auth.createSession({ ...userInfo, userId });
    history.replace(redirect || config.defaultPath);
    return userInfo;
  });
};

export const forgotPasswordFormInitialValues = {
  userId: ''
};

export const forgotPasswordFormSchema = object().shape({
  userId: string()
    .required('validation.required')
    .min(6, 'validation.loginMinLength')
    .max(255, 'validation.maxLength')
    .email('validation.invalidEmail')
});

export const forgotPassword = ({ userId }) => {
  return api.forgotPassword({ userId });
};

export const resetPasswordFormInitialValues = {
  password: '',
  passwordConfirmation: ''
};

export const resetPasswordFormSchema = object().shape({
  password: string()
    .required('validation.required')
    .min(8, 'validation.password')
    .max(32, 'validation.password')
    .test('passwordCheck', 'validation.password', val => {
      return /[A-Z]/.test(val) && /\d/.test(val);
    }),
  passwordConfirmation: string()
    .required('validation.required')
    .oneOf([ref('password'), null], 'validation.passwordMatch')
});

export const resetPassword = ({ password }, { location, history }) => {
  const { token } = parse(location.search, {
    ignoreQueryPrefix: true
  });
  return api.resetPassword({ password, token }).then(() => {
    history.replace('/login');
  });
};
