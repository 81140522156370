/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('analyticsChartAxesLabels', function(
    analyticsFactory2,
    ANALYTICS_CONFIG2,
    $timeout
  ) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        axesOptions: '&'
      },
      templateUrl: 'app/core/analytics2/axes.html',
      link(scope, el) {
        const build = function() {
          const height = el.parent().height();
          const width = el.parent().width();
          el.height(`${height}px`);
          el.width(`${width}px`);
        };

        $timeout(build);

        // Set Height when parent.height will change
        scope.$watch(
          function() {
            return {
              height: el.parent().height(),
              width: el.parent().width()
            };
          },
          function() {
            build();
          },
          true
        );
      }
    };
  });
