/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').component('widgetEditorButtonSettings', {
  templateUrl:
    'app/sections/widgets/editor/common/widgetEditorButtonSettings.html',
  controller: widgetEditorButtonSettingsController,
  bindings: {
    text: '=',
    defaultText: '@',
    type: '@',
    formIndex: '@',
    link: '=?',
    style: '<',
    state: '=',
    options: '<'
  }
});

function widgetEditorButtonSettingsController(widgetsFactory) {
  const ctrl = this;
  ctrl.formName = `buttonForm${ctrl.formIndex}`;

  ctrl.validateTransparencyRange = function(val) {
    return widgetsFactory.validateTransparencyRange(val);
  };

  ctrl.onStyleChange = function() {
    if (ctrl.state === 'normal') {
      ctrl.style.hover = window.$.extend(
        true,
        ctrl.style.hover,
        ctrl.style.normal
      );
    }
  };
}
