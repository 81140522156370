/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.sites')
  .controller('apMacsCtrl', function($scope, $modal, helpers) {
    $scope.modalOptions = { type: 'mac' };
    $scope.newMac = {};

    $scope.addMac = function() {
      const scope = $scope.$new();
      scope.addMacFocus = false;
      scope.siteId = $scope.siteId;
      scope.forms = {};
      scope.state = {
        submitted: false
      };

      $modal
        .open({
          templateUrl: 'app/sections/sites/addMacModal.html',
          windowClass: 'add-mac_modal',
          scope
        })
        .result.then(function(identifier) {
          if ($scope.modalOptions.type === 'mac') {
            $scope.macs.push(identifier);
          } else if ($scope.modalOptions.type === 'string') {
            $scope.strings.push(identifier);
          }
          $scope.newMac = {};
        });
    };

    $scope.removeMac = function(identifier, type) {
      const text = {
        title: 'sites.labels.apMacs',
        message: 'sites.modals.deleteMac.texts.deletePrompt',
        param: identifier
      };
      helpers.cancelConfirmModal(text).then(function() {
        let index;
        if (type === 'mac') {
          index = $scope.macs.indexOf(identifier);
          $scope.macs.splice(index, 1);
        } else if (type === 'string') {
          index = $scope.strings.indexOf(identifier);
          $scope.strings.splice(index, 1);
        }
      });
    };
  });
