/* eslint-disable */
// eslint is disabled for this file, since this is 3d party code, taken from bootstrap
angular
  .module('wcm.components')

  /**
   * A helper, internal data structure that acts as a map but also allows getting / removing
   * elements in the LIFO order
   */
  .factory('$$stackedMap', function() {
    return {
      createNew() {
        const stack = [];

        return {
          add(key, value) {
            stack.push({
              key,
              value
            });
          },
          get(key) {
            for (let i = 0; i < stack.length; i++) {
              if (key == stack[i].key) {
                return stack[i];
              }
            }
          },
          keys() {
            const keys = [];
            for (let i = 0; i < stack.length; i++) {
              keys.push(stack[i].key);
            }
            return keys;
          },
          top() {
            return stack[stack.length - 1];
          },
          remove(key) {
            let idx = -1;
            for (let i = 0; i < stack.length; i++) {
              if (key == stack[i].key) {
                idx = i;
                break;
              }
            }
            return stack.splice(idx, 1)[0];
          },
          removeTop() {
            return stack.splice(stack.length - 1, 1)[0];
          },
          length() {
            return stack.length;
          }
        };
      }
    };
  })

  /**
   * A helper directive for the $modal service. It creates a backdrop element.
   */
  .directive('modalBackdrop', [
    '$timeout',
    function($timeout) {
      return {
        restrict: 'EA',
        replace: true,
        templateUrl: 'app/core/components/modal/backdrop.html',
        link(scope, element, attrs) {
          scope.backdropClass = attrs.backdropClass || '';

          scope.animate = false;

          // trigger CSS transitions
          $timeout(function() {
            scope.animate = true;
          });
        }
      };
    }
  ])

  .directive('modalWindow', [
    '$modalStack',
    '$timeout',
    function($modalStack, $timeout) {
      return {
        restrict: 'EA',
        scope: {
          index: '@',
          animate: '='
        },
        replace: true,
        transclude: true,
        templateUrl(tElement, tAttrs) {
          return tAttrs.templateUrl || 'app/core/components/modal/window.html';
        },
        link(scope, element, attrs) {
          element.addClass(attrs.windowClass || '');
          scope.size = attrs.size;

          // moved from template to fix issue #2280
          element.on('click', function(evt) {
            scope.close(evt);
          });

          $timeout(function() {
            // trigger CSS transitions
            scope.animate = true;

            /**
             * Auto-focusing of a freshly-opened modal element causes any child elements
             * with the autofocus attribute to lose focus. This is an issue on touch
             * based devices which will show and then hide the onscreen keyboard.
             * Attempts to refocus the autofocus element via JavaScript will not reopen
             * the onscreen keyboard. Fixed by updated the focusing logic to only autofocus
             * the modal element if the modal does not contain an autofocus element.
             */
            if (!element[0].querySelectorAll('[autofocus]').length) {
              element[0].focus();
            }
          });

          scope.close = function(evt) {
            const modal = $modalStack.getTop();
            if (
              modal &&
              modal.value.backdrop &&
              modal.value.backdrop != 'static' &&
              evt.target === evt.currentTarget
            ) {
              evt.preventDefault();
              evt.stopPropagation();
              $modalStack.dismiss(modal.key, 'backdrop click');
            }
          };
        }
      };
    }
  ])

  .directive('modalTransclude', function() {
    return {
      link($scope, $element, $attrs, controller, $transclude) {
        $transclude($scope.$parent, function(clone) {
          $element.empty();
          $element.append(clone);
        });
      }
    };
  })

  .factory('$modalStack', [
    '$transition',
    '$timeout',
    '$document',
    '$compile',
    '$rootScope',
    '$$stackedMap',
    function(
      $transition,
      $timeout,
      $document,
      $compile,
      $rootScope,
      $$stackedMap
    ) {
      const OPENED_MODAL_CLASS = 'modal-open';

      let backdropDomEl, backdropScope;
      const openedWindows = $$stackedMap.createNew();
      const $modalStack = {};

      function backdropIndex() {
        let topBackdropIndex = -1;
        const opened = openedWindows.keys();
        for (let i = 0; i < opened.length; i++) {
          if (openedWindows.get(opened[i]).value.backdrop) {
            topBackdropIndex = i;
          }
        }
        return topBackdropIndex;
      }

      $rootScope.$watch(backdropIndex, function(newBackdropIndex) {
        if (backdropScope) {
          backdropScope.index = newBackdropIndex;
        }
      });

      function removeModalWindow(modalInstance) {
        const body = $document.find('body').eq(0);
        const modalWindow = openedWindows.get(modalInstance).value;

        // clean up the stack
        openedWindows.remove(modalInstance);

        // remove window DOM element
        removeAfterAnimate(
          modalWindow.modalDomEl,
          modalWindow.modalScope,
          300,
          function() {
            modalWindow.modalScope.$destroy();
            body.toggleClass(OPENED_MODAL_CLASS, openedWindows.length() > 0);
            checkRemoveBackdrop();
          }
        );
      }

      function checkRemoveBackdrop() {
        // remove backdrop if no longer needed
        if (backdropDomEl && backdropIndex() == -1) {
          let backdropScopeRef = backdropScope;
          removeAfterAnimate(backdropDomEl, backdropScope, 150, function() {
            backdropScopeRef.$destroy();
            backdropScopeRef = null;
          });
          backdropDomEl = undefined;
          backdropScope = undefined;
        }
      }

      function removeAfterAnimate(domEl, scope, emulateTime, done) {
        // Closing animation
        scope.animate = false;

        const transitionEndEventName = $transition.transitionEndEventName;
        if (transitionEndEventName) {
          // transition out
          const timeout = $timeout(afterAnimating, emulateTime);

          domEl.bind(transitionEndEventName, function() {
            $timeout.cancel(timeout);
            afterAnimating();
            scope.$apply();
          });
        } else {
          // Ensure this call is async
          $timeout(afterAnimating);
        }

        function afterAnimating() {
          if (afterAnimating.done) {
            return;
          }
          afterAnimating.done = true;

          domEl.remove();
          if (done) {
            done();
          }
        }
      }

      $document.bind('keydown', function(evt) {
        let modal;

        if (evt.which === 27) {
          modal = openedWindows.top();
          if (modal && modal.value.keyboard) {
            evt.preventDefault();
            $rootScope.$apply(function() {
              $modalStack.dismiss(modal.key, 'escape key press');
            });
          }
        }
      });

      $modalStack.open = function(modalInstance, modal) {
        openedWindows.add(modalInstance, {
          deferred: modal.deferred,
          modalScope: modal.scope,
          backdrop: modal.backdrop,
          keyboard: modal.keyboard
        });

        let body = $document.find('body').eq(0),
          currBackdropIndex = backdropIndex();

        if (currBackdropIndex >= 0 && !backdropDomEl) {
          backdropScope = $rootScope.$new(true);
          backdropScope.index = currBackdropIndex;
          const angularBackgroundDomEl = angular.element(
            '<div modal-backdrop></div>'
          );
          angularBackgroundDomEl.attr('backdrop-class', modal.backdropClass);
          backdropDomEl = $compile(angularBackgroundDomEl)(backdropScope);
          body.append(backdropDomEl);
        }

        const angularDomEl = angular.element('<div modal-window></div>');
        angularDomEl
          .attr({
            'template-url': modal.windowTemplateUrl,
            'window-class': modal.windowClass,
            size: modal.size,
            index: openedWindows.length() - 1,
            animate: 'animate'
          })
          .html(modal.content);

        const modalDomEl = $compile(angularDomEl)(modal.scope);
        openedWindows.top().value.modalDomEl = modalDomEl;
        body.append(modalDomEl);
        body.addClass(OPENED_MODAL_CLASS);
      };

      $modalStack.close = function(modalInstance, result) {
        const modalWindow = openedWindows.get(modalInstance);
        if (modalWindow) {
          modalWindow.value.deferred.resolve(result);
          removeModalWindow(modalInstance);
        }
      };

      $modalStack.dismiss = function(modalInstance, reason) {
        const modalWindow = openedWindows.get(modalInstance);
        if (modalWindow) {
          modalWindow.value.deferred.reject(reason);
          removeModalWindow(modalInstance);
        }
      };

      $modalStack.dismissAll = function(reason) {
        let topModal = this.getTop();
        while (topModal) {
          this.dismiss(topModal.key, reason);
          topModal = this.getTop();
        }
      };

      $modalStack.getTop = function() {
        return openedWindows.top();
      };

      return $modalStack;
    }
  ])

  .provider('$modal', function() {
    var $modalProvider = {
      options: {
        backdrop: 'static', // can be also false or 'static'
        keyboard: true
      },
      $get: [
        '$injector',
        '$rootScope',
        '$q',
        '$http',
        '$templateCache',
        '$controller',
        '$modalStack',
        function(
          $injector,
          $rootScope,
          $q,
          $http,
          $templateCache,
          $controller,
          $modalStack
        ) {
          const $modal = {};

          function getTemplatePromise(options) {
            return options.template
              ? $q.when(options.template)
              : $http
                  .get(
                    angular.isFunction(options.templateUrl)
                      ? options.templateUrl()
                      : options.templateUrl,
                    { cache: $templateCache }
                  )
                  .then(function(result) {
                    return result.data;
                  });
          }

          function getResolvePromises(resolves) {
            const promisesArr = [];
            angular.forEach(resolves, function(value) {
              if (
                angular.isFunction(value) ||
                angular.isArray(value)
              ) {
                promisesArr.push($q.when($injector.invoke(value)));
              }
            });
            return promisesArr;
          }

          $modal.open = function(modalOptions) {
            const modalResultDeferred = $q.defer();
            const modalOpenedDeferred = $q.defer();

            // prepare an instance of a modal to be injected into controllers and returned to a caller
            var modalInstance = {
              result: modalResultDeferred.promise,
              opened: modalOpenedDeferred.promise,
              close(result) {
                $modalStack.close(modalInstance, result);
              },
              dismiss(reason) {
                $modalStack.dismiss(modalInstance, reason);
              }
            };

            // merge and clean up options
            modalOptions = angular.extend(
              {},
              $modalProvider.options,
              modalOptions
            );
            modalOptions.resolve = modalOptions.resolve || {};

            // verify options
            if (!modalOptions.template && !modalOptions.templateUrl) {
              throw new Error(
                'One of template or templateUrl options is required.'
              );
            }

            const templateAndResolvePromise = $q.all(
              [getTemplatePromise(modalOptions)].concat(
                getResolvePromises(modalOptions.resolve)
              )
            );

            templateAndResolvePromise.then(
              function resolveSuccess(tplAndVars) {
                const modalScope = (modalOptions.scope || $rootScope).$new();
                modalScope.$close = modalInstance.close;
                modalScope.$dismiss = modalInstance.dismiss;

                let ctrlInstance,
                  ctrlLocals = {};
                let resolveIter = 1;

                // controllers
                if (modalOptions.controller) {
                  ctrlLocals.$scope = modalScope;
                  ctrlLocals.$modalInstance = modalInstance;
                  angular.forEach(modalOptions.resolve, function(
                    value,
                    key
                  ) {
                    ctrlLocals[key] = tplAndVars[resolveIter++];
                  });

                  ctrlInstance = $controller(
                    modalOptions.controller,
                    ctrlLocals
                  );
                  if (modalOptions.controllerAs) {
                    modalScope[modalOptions.controllerAs] = ctrlInstance;
                  }
                }

                $modalStack.open(modalInstance, {
                  scope: modalScope,
                  deferred: modalResultDeferred,
                  content: tplAndVars[0],
                  backdrop: modalOptions.backdrop,
                  keyboard: modalOptions.keyboard,
                  backdropClass: modalOptions.backdropClass,
                  windowClass: modalOptions.windowClass,
                  windowTemplateUrl: modalOptions.windowTemplateUrl,
                  size: modalOptions.size
                });
              },
              function resolveError(reason) {
                modalResultDeferred.reject(reason);
              }
            );

            templateAndResolvePromise.then(
              function() {
                modalOpenedDeferred.resolve(true);
              },
              function() {
                modalOpenedDeferred.reject(false);
              }
            );

            return modalInstance;
          };

          return $modal;
        }
      ]
    };

    return $modalProvider;
  });
