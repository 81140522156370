/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('monitoringCollectionFactory', function(
    $q,
    requestFactory,
    monitoringFactory,
    $http,
    MONITORING_CONFIG,
    $filter,
    helpers,
    $rootScope,
    campaignsFactory
  ) {
    const MILISECONDS_IN_MINUTE = 60000;

    const collectionGetList = function(filters) {
      const requestFilters = {
        filterByPeriod: [filters.startPeriod, filters.endPeriod].map(function(
          moment
        ) {
          const _moment = angular.copy(moment);
          // cast to utc midnight
          return _moment
            .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
            .startOf('day')
            .valueOf();
        }),
        filterBySearchString: filters.searchString,
        filterByState: filters.collectionStatus,
        collectionType: filters.collectionType
      };

      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.collectionGetList,
          requestFilters
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const collectionGetListForVisitor = function(data) {
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.collectionGetListForVisitor,
          data
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const collectionGetViewData = function(data) {
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.collectionGetViewData,
          data
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    // var collectionVisitorsApplyFilters = function (state) {
    //   return monitoringFactory.visitorGetList(state, 'collection')
    // };

    const collectionVisitorsGetList = function(state) {
      return monitoringFactory.visitorGetList(state);
    };

    const collectionsPerformAction = function(
      collectionsIds,
      action,
      mergeParams
    ) {
      let requestData = {
        collections: collectionsIds,
        action
      };
      requestData = angular.extend({}, requestData, mergeParams || {});
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.collectionsPerformAction,
          requestData,
          {},
          { skipErrors: [409] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const removeFromCollection = function(collectionId, visitors) {
      const requestData = {
        collectionId,
        visitorId: visitors
      };
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.removeFromCollection,
          requestData,
          {},
          { skipErrors: [409] }
        )
        .then(function(res) {
          if (res.status === 244 || res.status === 204) {
            return $q.reject(res);
          }
          return res.data;
        });
    };

    const addToNewCollection = function(data, state) {
      let filters = {};
      if (state.filters && !state.collectionId) {
        filters = monitoringFactory.mapStateToRequest(state);
      } else if (state.filters && state.collectionId) {
        filters.searchString = state.filters.searchString;
        filters.collectionId = state.collectionId;
      }
      filters = angular.extend(filters, data);
      filters.uniquePageId = $rootScope.uniqueBrowserTabToken;
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.addToNewCollection,
          filters,
          {},
          { skipErrors: [409] }
        )
        .then(
          function(res) {
            if (res.status === 244) {
              return $q.reject(res);
            }
            return true;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const addToCollections = function(data, state) {
      let filters = {};
      if (state.filters && !state.collectionId) {
        filters = monitoringFactory.mapStateToRequest(state);
      } else if (state.filters && state.collectionId) {
        filters.filterBySearchString = state.filters.searchString;
        filters.collectionId = state.collectionId;
      }
      filters = angular.extend(filters, data);
      filters.uniquePageId = $rootScope.uniqueBrowserTabToken;
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.addToCollections,
          filters,
          {},
          { skipErrors: [409] }
        )
        .then(
          function(res) {
            if (res.status === 244) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const collectionAddNotifications = function(data) {
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.collectionAddNotifications,
          data
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const getCollectionProps = function(id) {
      const requestData = {
        collectionId: id
      };
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.getCollectionProps,
          requestData
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const saveCollectionProps = function(data) {
      const collection = angular.copy(data);
      if (collection.sites) {
        collection.sites = collection.sites.map(function(item) {
          return item.id;
        });
      }
      return requestFactory
        .post(
          MONITORING_CONFIG.servlet,
          MONITORING_CONFIG.saveCollectionProps,
          { collection },
          {},
          { skipErrors: [409] }
        )
        .then(
          function(res) {
            if (res.status === 244 || res.status === 204) {
              return $q.reject(res);
            }
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const getOptionsOccupationAll = function() {
      return requestFactory
        .post(
          MONITORING_CONFIG.commonServlet,
          MONITORING_CONFIG.getOptionsOccupationAll,
          {}
        )
        .then(function(res) {
          return res.data.items;
        });
    };

    const getOptionsInterestsAll = function() {
      return requestFactory
        .post(
          MONITORING_CONFIG.commonServlet,
          MONITORING_CONFIG.getOptionsInterestsAll,
          {}
        )
        .then(function(res) {
          return res.data.items;
        });
    };

    const getOptionsCityAll = function() {
      return campaignsFactory.campaignGetCities().then(
        function(res) {
          return res.data.items;
        },
        function() {
          return [];
        }
      );
    };

    const getOptionsSitesAll = function() {
      return requestFactory
        .post(
          MONITORING_CONFIG.siteServlet,
          MONITORING_CONFIG.getOptionsSitesAll,
          {}
        )
        .then(function(res) {
          return res.data.sites;
        });
    };

    return {
      collectionGetList,
      collectionGetListForVisitor,
      collectionGetViewData,
      collectionVisitorsGetList,
      collectionsPerformAction,
      removeFromCollection,
      addToNewCollection,
      addToCollections,
      collectionAddNotifications,
      getCollectionProps,
      saveCollectionProps,
      getOptionsOccupationAll,
      getOptionsInterestsAll,
      getOptionsCityAll,
      getOptionsSitesAll
    };
  });
