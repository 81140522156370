const { angular } = window;

angular.module('wcm.core').component('stepsPicker', {
  templateUrl: 'app/core/components/stepsPicker/stepsPicker.html',
  controller: 'stepsPickerController',
  bindings: {
    filterSteps: '<',
    filters: '<',
    onValuesChange: '&'
  }
});
