/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('systemSettingsFactory', function(
    requestFactory,
    SYSTEM_SETTINGS_CONFIG
  ) {
    const getList = function() {
      return requestFactory.post(
        SYSTEM_SETTINGS_CONFIG.sitesServlet,
        SYSTEM_SETTINGS_CONFIG.getList,
        {}
      );
    };

    const saveList = function(settings) {
      // eslint-disable-next-line no-param-reassign
      settings.systemEmailSettings.smtpPort = parseInt(
        settings.systemEmailSettings.smtpPort,
        10
      );
      return requestFactory.post(
        SYSTEM_SETTINGS_CONFIG.sitesServlet,
        SYSTEM_SETTINGS_CONFIG.saveList,
        settings
      );
    };

    const sendTestEmail = function(settings) {
      if (settings.systemEmailSettings) {
        // eslint-disable-next-line no-param-reassign
        settings.systemEmailSettings.smtpPort = parseInt(
          settings.systemEmailSettings.smtpPort,
          10
        );
      }
      return requestFactory.post(
        SYSTEM_SETTINGS_CONFIG.sitesServlet,
        SYSTEM_SETTINGS_CONFIG.sendEmail,
        { systemEmailSettings: settings.systemEmailSettings }
      );
    };

    const restartServer = function() {
      return requestFactory.post(
        SYSTEM_SETTINGS_CONFIG.sitesServlet,
        SYSTEM_SETTINGS_CONFIG.restartServer,
        {}
      );
    };

    return {
      getList,
      saveList,
      restartServer,
      sendTestEmail
    };
  });
