const { angular } = window;

angular
  .module('wcm.sites')
  .controller('siteMacExceptionsCtrl', function(
    $scope,
    $modal,
    $state,
    requestFactory,
    macExceptionsFactory,
    navigationPanel,
    filtersFactory,
    modificationHistory,
    sitesFactory,
    helpers
  ) {
    const siteId = +$state.params.id;
    let sortOption;
    let appliedFilters;
    let site;

    $scope.siteDataReady().then(function() {
      site = $scope.site;
      $scope.setBreadcrumbs('details', site.id, site.name);
      applyFilters(true);
    });

    $scope.search = {
      value: ''
    };

    $scope.filters = filtersFactory.initFilters(
      {
        label: 'Sort',
        options: {
          'date:desc': 'date:desc',
          'date:asc': 'date:asc'
        },
        type: 'sort'
      },
      {
        label: 'SiteId',
        isHidden: true,
        options: {
          siteId: siteId * 1
        }
      }
    );

    $scope.onFilterUpdate = function onFilterUpdate(filterValue, filterType) {
      if (filterType === 'sort') {
        $scope.onListUpdate(filterValue);
        return true;
      }
      if (filterType === 'filter') {
        applyFilters(true);
        return true;
      }
      if (filterType.indexOf('search') > -1) {
        applyFilters(filterType.indexOf('updateList') > -1);
        return true;
      }
      return undefined;
    };

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(data) {
      $scope.totalItems = data.itemsTotal;
      $scope.canCreate = data.canCreate;
    };

    $scope.onListUpdate = function onListUpdate(sortOpt) {
      sortOption = sortOpt;
      $scope.getPage();
    };

    function applyFilters(updateList) {
      let sortOption;
      const requestPayload = {
        filterBySearchString: $scope.search.value || '',
        updateList: !!updateList,
        searchStringMode: ''
      };
      const applyFiltersPrefix = 'macException';
      const servlet = 'macexception';

      angular.forEach($scope.filters, function(filter) {
        if (filter.type === 'filter') {
          requestPayload[
            `filterBy${filter.label}`
          ] = filter.activeOption().value;
        }
        if (filter.type === 'sort') {
          sortOption = filter.activeOption().value;
        }
      });
      if (updateList) {
        $scope.setBusy(true);
      }
      appliedFilters = requestPayload;
      requestFactory
        .post(servlet, `${applyFiltersPrefix}ApplyFilters`, requestPayload)
        .success(function(data) {
          $scope.dropDownItems = data.searchDropDown;

          $scope.onAfterFiltersApplied(data, requestPayload);

          if (updateList) {
            $scope.onListUpdate(sortOption);
          }
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    }

    $scope.getPage = function getPage() {
      macExceptionsFactory
        .getList(
          appliedFilters,
          $scope.currentPage,
          $scope.itemsOnPage,
          sortOption
        )
        .then(function(macExceptions) {
          $scope.macList = macExceptions;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.addMacException = function() {
      macExceptionsFactory
        .openMacExceptionModal(siteId, 'add', {})
        .then(function() {
          applyFilters(true);
        });
    };

    $scope.viewMacException = function(mac) {
      modificationHistory
        .get('MacException', mac.id)
        .then(function(history) {
          return macExceptionsFactory.openMacExceptionModal(
            siteId,
            'view',
            window._.extend({}, mac, { history })
          );
        })
        .then(function() {
          applyFilters(true);
        });
    };

    $scope.goToView = function(id, title) {
      macExceptionsFactory.getData(id, title, siteId).then(function(mac) {
        $scope.viewMacException({
          id,
          details: mac.details,
          macAddress: title
        });
      });
    };

    $scope.deleteMacException = function(mac) {
      helpers
        .confirmationModal(
          '',
          'admin.macExceptions.modals.deleteMac.texts.prompt',
          mac,
          null,
          { className: 'modal_delete-mac' }
        )
        .result.then(function() {
          macExceptionsFactory.macExceptionDelete(mac, siteId).then(function() {
            applyFilters(true);
          });
        });
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => false,
        isShown: () => $scope.canCreate,
        clickHandler: () => {
          $scope.addMacException();
        }
      }
    ]);
  });
