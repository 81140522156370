/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.entities')
  .controller('entitiesListCtrl', function(
    $scope,
    $modal,
    $state,
    auth,
    breadcrumbs,
    navigationPanel,
    entitiesFactory,
    filtersFactory,
    conversionFactory,
    helpers
  ) {
    const entityType = $state.current.name.split('.').pop();
    const availableTabs = [
      { name: 'entities.partner', sref: 'section.entities.partnerList' },
      { name: 'entities.advertiser', sref: 'section.entities.advertiserList' }
    ];

    const allowedTabs = availableTabs.filter(function(tab) {
      return auth.hasPermissionsForSection(
        $scope.currentUser,
        tab.sref
          .split('.')
          .slice(1)
          .join('.')
      );
    });

    navigationPanel.setTabs(allowedTabs);
    breadcrumbs.set({
      path: [
        {
          name: 'entities'
        },
        {
          name: 'list'
        },
        {
          name: entityType === 'partnerList' ? 'classA' : 'classB',
          sref: `section.entities.${entityType}`
        }
      ]
    });

    let sortOption;
    let appliedFilters;
    const baseFilters = [
      {
        label: 'Status',
        options: {
          Active: 'Active',
          Archive: 'Archive'
        }
      },
      {
        label: 'Sort',
        options: {
          'date:desc': 'date:desc',
          'date:asc': 'date:asc',
          'alphabetically:asc': 'alphabetically:asc',
          'alphabetically:desc': 'alphabetically:desc'
        },
        type: 'sort'
      },
      {
        label: 'Agency',
        searchable: true,
        isHidden: $state.current.name === 'section.entities.partnerList',
        options: {
          All: ''
        }
      }
    ];

    $scope.filters = filtersFactory.initFilters(baseFilters);

    const prepareFilters = function() {
      if ($state.current.name === 'section.entities.advertiserList') {
        return entitiesFactory.getAgencies().success(function(data) {
          $scope.filters[2].options = $scope.filters[2].options.slice(0, 1);
          $scope.filters[2].activeOption(0);
          angular.forEach(data.agencies, function(v) {
            if (v !== '') {
              $scope.filters[2].addOption(
                v.replace(/\./g, 'DOT_REPLACEMENT'),
                v
              );
            }
          });
        });
      }
      return undefined;
    };

    $scope.init = prepareFilters;

    $scope.goToView = function(id) {
      $state.go('section.entities.view', {
        id,
        type: entityType === 'partnerList' ? 'classA' : 'classB'
      });
    };

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(
      data,
      requestPayload
    ) {
      $scope.totalItems = data.itemsTotal;
      $scope.canCreate = data.canCreate;
      $scope.archiveState = requestPayload.filterByStatus === 'Active';
    };

    $scope.onListUpdate = function onListUpdate(sortOpt, filters) {
      if (filters.filterByType !== $scope.currentTabName) return false;
      sortOption = sortOpt;
      appliedFilters = filters;
      $scope.getPage();
      return undefined;
    };

    $scope.getPage = function getPage() {
      entitiesFactory
        .getList(
          appliedFilters,
          $scope.currentPage,
          $scope.itemsOnPage,
          sortOption
        )
        .then(function(entities) {
          $scope.entitiesList = entities;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.createEntity = function() {
      $state.transitionTo('entities.entityCreate', { type: $scope.title });
    };

    $scope.remove = function archiveAction(id, type, name) {
      const _type = conversionFactory.capitalize(type);
      helpers
        .confirmationModal('', 'entities.modals.actions.texts.remove', name)
        .result.then(function() {
          entitiesFactory.remove(id, _type).success(function() {
            $scope.applyFilters(true);
            prepareFilters();
          });
        });
    };

    $scope.performAction = function(action, entityId, entityName) {
      helpers
        .confirmationModal(
          '',
          `entities.modals.actions.texts.${action}`,
          entityName
        )
        .result.then(function() {
          entitiesFactory[action](entityId, $scope.currentTabName).then(
            function() {
              $scope.applyFilters(true);
              prepareFilters();
            }
          );
        });
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => !$scope.archiveState,
        isShown: () => $scope.canCreate,
        clickHandler: () => {
          $state.go('section.entities.create', {
            type: entityType === 'partnerList' ? 'classA' : 'classB'
          });
        }
      }
    ]);
  });
