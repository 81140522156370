/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.campaigns')
  .controller('campaignCtrl', function(
    $scope,
    $state,
    campaignsFactory,
    navigationPanel,
    APP_CONFIG
  ) {
    $scope.setTabs = navigationPanel.setTabs;
    const id = +$state.params.id;
    let campaign;

    const isAnyPermissions = function(currentState, permissions) {
      if (currentState === 'analyticsCampaign') {
        return permissions.viewAnalytics;
      } else if (currentState === 'visitors') {
        return permissions.viewAnalyticsVisitorList;
      }
      return undefined;
    };

    const isTabPermissionsDenied = function(permissions) {
      let currentState = $state.current.name.split('.');
      currentState = currentState[currentState.length - 1];
      return (
        (!isAnyPermissions(currentState, permissions) &&
          currentState === 'analyticsCampaign') ||
        (!isAnyPermissions(currentState, permissions) &&
          currentState === 'visitors')
      );
    };

    if (id) {
      campaignsFactory.campaignGetData(id).then(function(res) {
        if (isTabPermissionsDenied(res.data.campaign.permissions)) {
          $state.go(APP_CONFIG.defaultState);
          return false;
        }

        // eslint-disable-next-line no-multi-assign
        campaign = $scope.campaign = res.data.campaign.general;
        campaign.calendarMode = 'period';
        campaign.state = res.data.campaign.status.state;
        campaign.permissions = res.data.campaign.permissions || {};

        const tabs = [
          { name: 'campaigns.details', sref: 'section.campaigns.view.details' }
        ];

        if (campaign.permissions.viewAnalytics) {
          tabs.unshift({
            name: 'campaigns.analytics',
            sref: 'section.campaigns.view.analyticsCampaign'
          });
        }

        if (campaign.permissions.viewAnalyticsVisitorList) {
          tabs.unshift({
            name: 'campaigns.visitors',
            sref: 'section.campaigns.view.visitors'
          });
        }

        $scope.setTabs(tabs);
        return undefined;
      });
    }

    // Redirect if no permissions on this tab
    $scope.$on('$stateChangeSuccess', function() {
      if (
        $scope.campaign &&
        isTabPermissionsDenied($scope.campaign.permissions)
      ) {
        $state.go(APP_CONFIG.defaultState);
        return false;
      }
      return undefined;
    });

    // Optimistic set tabs before response arrives
    $scope.setTabs([
      { name: 'campaigns.details', sref: 'section.campaigns.view.details' }
    ]);
  });
