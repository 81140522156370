/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.core')
  .constant('CORE_CONFIG', {
    versionFilePath: '/version'
  })
  .constant('CONSTANTS_ICONS', {
    GENDER_MALE: 'male',
    GENDER_FEMALE: 'female',
    Other: 'ellipsis-h',
    Tablet: 'tablet',
    Smartphone: 'mobile-phone',
    Console: 'laptop',
    Android: 'android',
    Mac_OS_X: 'apple',
    iOS: 'apple',
    Windows: 'windows',
    Windows_Phone: 'windows',
    Linux: 'linux'
  });
