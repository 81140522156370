/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.campaigns')
  .directive('linkWidget', function($window) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        campaignId: '@',
        isEditMode: '=',
        campaignType: '@',
        linkedWidgets: '=?',
        currentAuthChain: '=',
        availableAuthChains: '=',
        campaignState: '@'
      },
      controller: 'linkWidgetCtrl',
      templateUrl: 'app/sections/campaigns/linkWidget.html',
      link(scope, el) {
        // el.width(el.width());
        const $thumbs = el.find('.linked-widgets__previews-container-inner');
        const $thumbsCnt = el.find('.linked-widgets__previews-container');
        let thumbsHeight;
        let thumbsCntHeight;
        let itemHeight;
        let currentSliderOffset = 0;

        const shouldShowSliderArrows = function() {
          // console.log(thumbsCntHeight, thumbsHeight);
          return thumbsCntHeight < thumbsHeight;
        };

        // function should be called whenever slider data must be recalculated
        const resetSlider = function() {
          const $item = $thumbs.find('.linked-widgets__preview');
          itemHeight = $item.outerHeight(true);
          thumbsCntHeight = el
            .find('.linked-widgets__previews-container')
            .height();
          $thumbsCnt.height(thumbsCntHeight);
          thumbsHeight = el
            .find('.linked-widgets__previews-container-inner')
            .height();
          scope.isSliderArrowsShown = shouldShowSliderArrows();
        };

        scope.slide = function(direction) {
          if (direction === 'left') {
            currentSliderOffset += itemHeight;
          }
          if (direction === 'right') {
            currentSliderOffset -= itemHeight;
          }

          if (currentSliderOffset < -(thumbsHeight - thumbsCntHeight)) {
            currentSliderOffset = -(thumbsHeight - thumbsCntHeight);
          }
          if (currentSliderOffset > 0) {
            currentSliderOffset = 0;
          }
          $thumbs.css({
            transform: `translateY(${currentSliderOffset}px)`,
            '-webkit-transform': `translateY(${currentSliderOffset}px)`,
            '-moz-transform': `translateY(${currentSliderOffset}px)`,
            '-ms-transform': `translateY(${currentSliderOffset}px)`
          });
        };

        scope.resetSliderPosition = function() {
          $thumbs.css('transform', '');
        };

        // scope.$watchCollection('linkedWidgets', resetSlider);
        // scope.$watch('isEditMode', function() {
        //  $timeout(resetSlider, 500);
        // });

        // scope.$watch(function() {console.log(el.width()); return el.width();}, resetSlider);
        scope.$watch(function() {
          return $thumbs.find('.linked-widgets__preview:visible').length;
        }, resetSlider);
        scope.$watch(function() {
          return el.find('.linked-widgets__previews-container').height();
        }, resetSlider);

        // scope.$watch(function() { return el.width()}, resetSlider);

        window.$($window).bind('resize.linkedWidget', function() {
          resetSlider();
          scope.$apply();
        });

        scope.$on('$destroy', function() {
          window.$($window).unbind('.linkedWidget');
        });
      }
    };
  });
