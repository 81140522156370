/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.analytics2')
  .directive('analyticsChartFilters', function(analyticsFactory2) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        filtersData: '=?',
        filtersLabel: '@',
        filtersId: '@',
        filtersSortBy: '=?',
        filtersAlwaysDisabled: '=',
        onChangeFilter: '&?'
      },
      templateUrl: 'app/core/analytics2/filters.html',
      link(scope) {
        scope.getKeys = function(items) {
          const filters = analyticsFactory2.getKeys(items);
          if (scope.filtersSortBy && Array.isArray(scope.filtersSortBy)) {
            return filters.map(function(item) {
              return filters[scope.filtersSortBy.indexOf(item)];
            });
          } else if (scope.filtersSortBy) {
            return filters.sort();
          }
          return filters;
        };

        scope.setNewFilter = function(key) {
          Object.keys(scope.filtersData).forEach(function(iKey) {
            scope.filtersData[iKey] = false;
            if (key === iKey) scope.filtersData[key] = true;
          });
          scope.onChangeFilter();
        };

        scope.filters = scope.getKeys(scope.filtersData);
        scope.$watchCollection('filtersData', function() {
          scope.filters = scope.getKeys(scope.filtersData);
        });
      }
    };
  });
