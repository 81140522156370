/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('campaignsSystemAnalyticsCtrl', function(
    $scope,
    $q,
    $state,
    analyticsFactory2,
    analyticsRequestsFactory,
    helpers,
    $timeout,
    navigationPanel,
    breadcrumbs,
    APP_CONFIG
  ) {
    const state = $scope.state;
    const options = $scope.options;
    const handlers = $scope.handlers;
    const user = $scope.currentUser;
    state.filters.campaignIds = [];
    state.analyticsType = 'campaigns';

    options.printTitle = 'System-wide campaign analytics';

    const setManageBlocksConfig = function() {
      state.manageFilterSettings.campaignsFilter = [
        {
          title: 'entities',
          name: 'entity',
          values: state.manageBlocks.campaignsFilter.entities,
          trackBy: 'id',
          filterTypes: ['Partner', 'Advertiser']
        },
        {
          title: 'tagsCampaigns',
          name: 'tags',
          values: state.manageBlocks.campaignsFilter.tags,
          trackBy: 'name'
        },
        {
          title: 'labelsCampaigns',
          name: 'labels',
          values: state.manageBlocks.campaignsFilter.labels,
          trackBy: 'name'
        }
      ];

      state.manageFilterSettings.dataByComparisonCampaigns = {
        firstFilter: [
          {
            title: 'entities',
            name: 'entity',
            values:
              state.manageBlocks.dataByComparisonCampaigns.firstValues.entities,
            trackBy: 'id',
            filterTypes: ['Partner', 'Advertiser']
          },
          {
            title: 'tagsCampaigns',
            name: 'tags',
            values:
              state.manageBlocks.dataByComparisonCampaigns.firstValues.tags,
            trackBy: 'name'
          },
          {
            title: 'labelsCampaigns',
            name: 'labels',
            values:
              state.manageBlocks.dataByComparisonCampaigns.firstValues.labels,
            trackBy: 'name'
          }
        ],
        secondFilter: [
          {
            title: 'entities',
            name: 'entity',
            values:
              state.manageBlocks.dataByComparisonCampaigns.secondValues
                .entities,
            trackBy: 'id',
            filterTypes: ['Partner', 'Advertiser']
          },
          {
            title: 'tagsCampaigns',
            name: 'tags',
            values:
              state.manageBlocks.dataByComparisonCampaigns.secondValues.tags,
            trackBy: 'name'
          },
          {
            title: 'labelsCampaigns',
            name: 'labels',
            values:
              state.manageBlocks.dataByComparisonCampaigns.secondValues.labels,
            trackBy: 'name'
          }
        ]
      };
    };

    handlers.setTabs = function() {
      // use timeout here to delay controller initialization to the moment when `main view`
      // directive sets its tabs handlers to the scope
      $timeout(function() {
        navigationPanel.setTabs(
          [
            {
              name: 'analytics.sites',
              sref: 'analytics.sites'
            },
            {
              name: 'analytics.campaigns',
              sref: 'analytics.campaigns',
              current: true
            }
          ].filter(function(tab) {
            return analyticsFactory2.hasPermissionsForType(
              user,
              tab.name.split('.')[1]
            );
          })
        );
        // go to the first available tab, if we don't have access to current one
        const tabs = $scope.currentPath.tabs;
        if (
          !window._.find(tabs, function(tab) {
            return tab.current === true;
          })
        ) {
          $state.go(tabs[tabs.length - 1].sref);
        }
        breadcrumbs.set({
          path: [{ name: 'analytics' }]
        });
      });
    };

    handlers.initManageBlocks = function(manageBlockFilters) {
      state.isBusy = false;

      if (state.noWas) return false;

      const entities = options.availableValues.partners.concat(
        options.availableValues.advertisers
      );

      const campaignsFilter = {
        campaigns: angular.copy(options.availableValues.campaigns),
        entities: angular.copy(entities),
        tags: angular.copy(options.availableValues.campaignsTags),
        labels: angular.copy(options.availableValues.campaignsLabels),
        checkedItems: manageBlockFilters
          ? manageBlockFilters.campaignsFilter.checkedItems
          : []
      };

      const dataByComparisonCampaigns = {
        firstValues: {
          campaigns: angular.copy(options.availableValues.campaigns),
          entities: angular.copy(entities),
          tags: angular.copy(options.availableValues.campaignsTags),
          labels: angular.copy(options.availableValues.campaignsLabels),
          checkedItems: manageBlockFilters
            ? manageBlockFilters.dataByComparisonCampaigns.firstValues
                .checkedItems
            : []
        },
        secondValues: {
          campaigns: angular.copy(options.availableValues.campaigns),
          entities: angular.copy(entities),
          tags: angular.copy(options.availableValues.campaignsTags),
          labels: angular.copy(options.availableValues.campaignsLabels),
          checkedItems: manageBlockFilters
            ? manageBlockFilters.dataByComparisonCampaigns.secondValues
                .checkedItems
            : []
        }
      };

      if (!manageBlockFilters) {
        if (options.availableValues.campaigns.length > 1) {
          // predefine filters for manage blocks
          campaignsFilter.campaigns[0].checked = true;
          campaignsFilter.campaigns[1].checked = true;
          dataByComparisonCampaigns.firstValues.campaigns[0].checked = true;
          dataByComparisonCampaigns.secondValues.campaigns[1].checked = true;
        } else if (options.availableValues.campaigns.length > 0) {
          campaignsFilter.campaigns[0].checked = true;
        }
      } else {
        campaignsFilter.campaigns.forEach(function(campaign) {
          if (
            ~manageBlockFilters.campaignsFilter.checkedItems.indexOf(
              campaign.id
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            campaign.checked = true;
          }
        });
        dataByComparisonCampaigns.firstValues.campaigns.forEach(function(
          campaign
        ) {
          if (
            ~manageBlockFilters.dataByComparisonCampaigns.firstValues.checkedItems.indexOf(
              campaign.id
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            campaign.checked = true;
          }
        });
        dataByComparisonCampaigns.secondValues.campaigns.forEach(function(
          campaign
        ) {
          if (
            ~manageBlockFilters.dataByComparisonCampaigns.secondValues.checkedItems.indexOf(
              campaign.id
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            campaign.checked = true;
          }
        });
      }

      state.filters.campaignIds = handlers.filterBy(
        campaignsFilter.campaigns,
        'id'
      );

      const filtersData = {};
      filtersData.values1 = handlers.filterBy(
        dataByComparisonCampaigns.firstValues.campaigns,
        'id'
      );
      filtersData.values2 = handlers.filterBy(
        dataByComparisonCampaigns.secondValues.campaigns,
        'id'
      );
      filtersData.collapsedValues = {};
      filtersData.collapsedValues.values1 = handlers.getCollapseItems(
        'campaigns',
        [],
        dataByComparisonCampaigns.firstValues.campaigns
      );
      filtersData.collapsedValues.values2 = handlers.getCollapseItems(
        'campaigns',
        [],
        dataByComparisonCampaigns.secondValues.campaigns
      );
      analyticsRequestsFactory.setManageBlocksData(
        'dataByComparisonCampaigns',
        filtersData
      );

      handlers.registerManageBlock('campaignsFilter', campaignsFilter);
      handlers.registerManageBlock(
        'dataByComparisonCampaigns',
        dataByComparisonCampaigns
      );

      setManageBlocksConfig();
      return undefined;
    };

    $scope.init = function() {
      if (!analyticsFactory2.isAnyPermissions(user)) {
        $state.go(APP_CONFIG.defaultState);
        return false;
      }
      handlers.setTabs();
      handlers.resetManageBlocks();
      if (!analyticsFactory2.hasPermissionsForType(user, state.analyticsType)) {
        return false;
      }
      return handlers.getAvailableValues().then(function() {
        $scope.$parent.init();
        handlers.initManageBlocks();
      });
    };

    handlers.onValuesSelected = function(
      blockName,
      availableValues,
      checkedIds,
      disableRequests
    ) {
      state.isPresetChanged = true;

      if (blockName === 'campaignsFilter') {
        state.manageBlocks.campaignsFilter.campaigns = availableValues;
        state.manageBlocks.campaignsFilter.checkedItems = checkedIds;
        state.filters.campaignIds = handlers.filterBy(
          state.manageBlocks.campaignsFilter.campaigns,
          'id'
        );
        if (!disableRequests) handlers.onFiltersChange();
        return true;
      }

      if (blockName === 'dataByComparisonCampaigns:firstValues') {
        state.manageBlocks.dataByComparisonCampaigns.firstValues.campaigns = availableValues;
        state.manageBlocks.dataByComparisonCampaigns.firstValues.checkedItems = checkedIds;
      }
      if (blockName === 'dataByComparisonCampaigns:secondValues') {
        state.manageBlocks.dataByComparisonCampaigns.secondValues.campaigns = availableValues;
        state.manageBlocks.dataByComparisonCampaigns.secondValues.checkedItems = checkedIds;
      }

      if (~blockName.indexOf('dataByComparisonCampaigns')) {
        const filtersData = {};
        filtersData.values1 = handlers.filterBy(
          state.manageBlocks.dataByComparisonCampaigns.firstValues.campaigns,
          'id'
        );
        filtersData.values2 = handlers.filterBy(
          state.manageBlocks.dataByComparisonCampaigns.secondValues.campaigns,
          'id'
        );
        filtersData.collapsedValues = {};
        filtersData.collapsedValues.values1 = handlers.getCollapseItems(
          'campaigns',
          state.manageFilterSettings.dataByComparisonCampaigns.firstFilter,
          state.manageBlocks.dataByComparisonCampaigns.firstValues.campaigns
        );
        filtersData.collapsedValues.values2 = handlers.getCollapseItems(
          'campaigns',
          state.manageFilterSettings.dataByComparisonCampaigns.secondFilter,
          state.manageBlocks.dataByComparisonCampaigns.secondValues.campaigns
        );
        analyticsRequestsFactory.setManageBlocksData(
          'dataByComparisonCampaigns',
          filtersData
        );

        if (!disableRequests) {
          state.chartsData.dataByComparisonCampaigns.requested = 'pending';
          analyticsRequestsFactory
            .getDataByComparisonCampaigns(
              state.filters,
              state.blocksState.dataByComparisonCampaigns,
              state.analyticsType
            )
            .then(function(data) {
              state.chartsData.dataByComparisonCampaigns = data;
            })
            .catch(function(res) {
              handlers.setEmptyDataForBlock(res.status, blockName);
            });
        }
      }
      return undefined;
    };
  });
