/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('generalInfo2', function(analyticsFactory2) {
    return {
      require: ['analyticsChart2', '^analyticsBlock2'],
      restrict: 'A',
      replace: true,
      scope: {
        chartData: '=?'
      },
      templateUrl: 'app/core/analytics2/generalInfo.html',
      link(scope) {
        scope.getKeys = analyticsFactory2.getKeys;
      }
    };
  });
