const { angular } = window;

angular.module('wcm.admin').factory('profileFactory', function() {
  function authorizeRoute() {
    return true;
  }
  function getFallbackRoute() {
    return undefined;
  }
  return {
    authorizeRoute,
    getFallbackRoute
  };
});
