/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2.presets')
  .directive('analyticsPresets', function(analyticsPresetsFactory) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        presets: '=',
        currentPreset: '=',
        isChanged: '=',
        onSave: '&',
        onReset: '&',
        onManage: '&',
        onPresetChange: '&',
        isErrorInPreset: '=?'
      },
      templateUrl: 'app/core/analytics2/analyticsPresets.html',
      windowClass: 'modal_presets',

      link(scope) {
        scope.handlers = {
          onSave(presets) {
            analyticsPresetsFactory
              .openSaveDialog(presets)
              .result.then(function(name) {
                scope.onSave({ name });
              });
          }
        };
      }
    };
  });
