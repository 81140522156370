/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;
angular
  .module('wcm.admin')
  .controller('monitoringVisitorsListCtrl', function(
    $q,
    $scope,
    $state,
    $timeout,
    monitoringFactory,
    monitoringCollectionFactory,
    sharedRequestsFactory,
    analyticsFactory2,
    breadcrumbs,
    dropdown,
    helpers
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isBusy: true,
      noDataAvailable: false,
      siteId: $state.params.id || 0,
      currentPage: 1,
      itemsPerPage: 10,
      itemsOnPageValues: [10, 25, 50, 100],
      sortOrder: 'last_visit:desc',
      exportType: 'visitors',
      isOnlyVisitors: true,
      selectedVisitors: [],
      isSelectAllVisitors: false,
      terminateType: 'visitors',
      filters: {
        startPeriod: moment()
          .startOf('day')
          .subtract(30, 'day'),
        endPeriod: moment().startOf('day'),
        startTimeFrame: 0,
        endTimeFrame: 24 * 60 * 60 * 1000,
        daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
        top: ''
      },
      visitors: [],
      visitorsRealData: [],
      isFiltersChanged: false
    });
    let debounceGetList;

    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        state.isBusy = !!queue.length;
      }, 100);
    });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      analyticsPeriodLimit: $scope.currentUser.analyticsPeriodLimit,
      availableMinDate: $scope.currentUser.systemCreationDate,
      minPeriod: null,
      maxPeriod: null
    });

    const reinitMinMaxPeriod = function() {
      // calculate Min Period
      options.minPeriod = analyticsFactory2.getAnalyticsMinPeriod(
        moment($scope.currentUser.systemCreationDate).startOf('day'),
        moment(state.filters.endPeriod).startOf('day'),
        options.analyticsPeriodLimit
      );
      // calculate Max Period
      options.maxPeriod = state.filters.endPeriod;
    };

    reinitMinMaxPeriod();
    state.filters.startPeriod = options.minPeriod;

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getPage: busyImpactor(function() {
        if (!isValidTimeFilters(state.filters)) return false;
        state.noDataAvailable = false;
        state.isFiltersChanged = true;
        return monitoringFactory.visitorGetList(state).then(
          function(data) {
            state.itemsTotal = data.itemsTotal;
            state.isFiltersChanged = false;
            state.visitors = data.visitors;
            state.visitorsRealData = data.visitors
              ? data.visitors.filter(function(item) {
                  return item;
                })
              : data.visitors;
          },
          function(res) {
            state.noDataAvailable = helpers.isDataNotAvailable(res.status);
            state.visitors = [];
            state.itemsTotal = 0;
            return $q.reject('no data available');
          }
        );
      }),
      onFiltersChange() {
        $timeout.cancel(debounceGetList);
        debounceGetList = $timeout(function() {
          state.currentPage = 1;
          reinitMinMaxPeriod();
          handlers.getPage();
        }, 500);
      },
      setSortOrder(sortOrder) {
        state.sortOrder = sortOrder;
        state.currentPage = 1;
        handlers.getPage();
      },
      getFilterLabel: dropdown.getMultiSelectLabel,
      isAllFiltersSelected(blockName) {
        return (
          options[blockName] &&
          options[blockName].length &&
          options[blockName].length === state.filters[blockName].length
        );
      },
      filtersSelectAll(blockName, disableRequest) {
        if (handlers.isAllFiltersSelected(blockName)) {
          state.filters[blockName] = [];
        } else {
          state.filters = angular.extend(
            {},
            state.filters,
            window._.pick(angular.copy(options), blockName)
          );
        }

        if (!disableRequest) handlers.onFiltersChange(state);
      },
      getExportOptions() {
        const exportOptions = [];
        const allBlocks = [
          'name',
          'lastVisit',
          'macAddress',
          'email',
          'birthDate',
          'gender',
          'phoneNumber',
          'visitsQuantity',
          'device',
          'OS',
          'socialNetworks'
        ];
        allBlocks.forEach(function(item) {
          exportOptions.push({
            key: item,
            name: `sites.exportLabels.${item}`,
            isDisabled: false,
            value: true
          });
        });
        return exportOptions;
      },
      getDeviceClass(device) {
        return helpers.getIconClass(device);
      },
      getOSClass(os) {
        return helpers.getOSIcon(os);
      },
      export() {
        return monitoringFactory.exportList(state, 'exportVisitorsList');
      }
    });

    // Send current state to each rootHandler function
    if (!$scope.inReporting) {
      Object.keys($scope.rootHandlers).forEach(function(key) {
        $scope.handlers[key] = function(...args) {
          return $scope.rootHandlers[key](state, args[0]);
        };
      });
    }

    // Init function
    function init() {
      state.noDataAvailable = false;
      breadcrumbs.set({
        path: 'monitoring>visitorsList'.split('>').map(name => ({ name }))
      });
      $scope.setTabs([]);
      monitoringFactory
        .getAvailableValues()
        .then(function(data) {
          angular.extend(options, angular.copy(data));
          state.filters = angular.extend(
            {},
            state.filters,
            window._.omit(data, 'tops')
          );
        })
        .then(handlers.onFiltersChange);
    }

    // Call init if not in Reports
    if (!$scope.inReporting) {
      init();
    }

    $scope.$on('$stateChangeStart', function() {
      sharedRequestsFactory.terminateDA(
        sharedRequestsFactory.terminateTypes[state.terminateType]
      );
      $timeout.cancel(debounceGetList);
    });

    // >>>> Helpers <<<<
    // Check if time filters are valid
    function isValidTimeFilters(timeFilters) {
      return analyticsFactory2.isValidTimeFilters(
        timeFilters,
        options.analyticsPeriodLimit,
        options.availableMinDate
      );
    }
    // >>>><<<<
  });
