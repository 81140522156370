/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('monitoringVisitorInterestsCtrl', function(
    $scope,
    $state,
    $q,
    $timeout,
    monitoringFactory,
    analyticsFactory2,
    navigationPanel,
    helpers
  ) {
    let state = $scope.state;
    let exportFlag = false;
    const handlers = $scope.handlers;

    state = angular.extend(state, {
      filters: {
        searchString: ''
      }
    });

    handlers.showMoreSinglePie = function(blockName, collapseValue) {
      const curFilter = state.blocksState[blockName].currentFilter;
      state.chartsData[blockName].view = analyticsFactory2.getShowMoreSinglePie(
        curFilter
          ? state.chartsData[blockName].requested.data[0][curFilter]
          : state.chartsData[blockName].requested.data[0],
        blockName,
        collapseValue
      );
    };

    handlers.isPending = function(blockName) {
      return (
        !state.chartsData[blockName] ||
        !state.chartsData[blockName].requested ||
        state.chartsData[blockName].requested === 'pending'
      );
    };

    handlers.dataAvailabilityForBlock = function(blockName) {
      let availability = '';
      if (
        state.chartsData[blockName] &&
        state.chartsData[blockName].requested
      ) {
        if (state.chartsData[blockName].requested === 'noDataYetAvailable') {
          availability = 'noDataYetAvailable';
        } else if (state.chartsData[blockName].requested === 'noDataInSystem') {
          availability = 'noDataInSystem';
        }
      }
      return availability;
    };

    handlers.export = function() {
      exportFlag = true;
      return monitoringFactory
        .exportList(state, 'exportVisitorsInterests')
        .finally(() => {
          exportFlag = false;
        });
    };

    handlers.onFiltersChange = function(state) {
      // eslint-disable-next-line no-param-reassign
      state.chartsData.monitoring_visitorInterests.requested = 'pending';
      handlers.getInterests(state);
    };

    handlers.getInterests = function(state) {
      monitoringFactory.visitorGetInterests(state).then(
        function(data) {
          // eslint-disable-next-line no-param-reassign
          state.chartsData.monitoring_visitorInterests = data;
          if (
            typeof state.chartsData.monitoring_visitorInterests.requested ===
              'object' &&
            !state.chartsData.monitoring_visitorInterests.requested.data[0].data
              .columns.length
          ) {
            // eslint-disable-next-line no-param-reassign
            state.chartsData.monitoring_visitorInterests.requested =
              'noDataInSystem';
          }
        },
        function(res) {
          // eslint-disable-next-line no-param-reassign
          state.chartsData.monitoring_visitorInterests.requested =
            helpers.isDataNotAvailable(res.status) || 'noDataInSystem';
        }
      );
    };

    function init() {
      state.noDataYetAvailable = false;
      state.blocksState.monitoring_visitorInterests = {};

      state.chartsData.monitoring_visitorInterests =
        state.chartsData.monitoring_visitorInterests || {};
      state.chartsData.monitoring_visitorInterests.requested = 'pending';
      handlers.getInterests(state);
    }

    init();
    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.export',
        isBusy: () => exportFlag,
        isDisabled: () =>
          [
            'pending',
            'noDataAvailable',
            'noDataInSystem',
            'noDataYetAvailable',
            'noServerAvailable'
          ].indexOf(state.chartsData.monitoring_visitorInterests.requested) !==
          -1,
        type: () => 'action-1',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.export();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.state.isEditMode,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      },
      {
        title: () => 'admin.buttons.close',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => !$scope.state.isEditMode,
        clickHandler: () => {
          $scope.close();
        }
      }
    ]);

    $scope.$on('$destroy', function() {
      analyticsFactory2.resetChartsOptions();
    });
  });
