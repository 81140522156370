/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const contentTypeImages = require.context(
  'assets/images/widgets',
  false,
  /\.png$/
);

function getWidgetPlaceholderImage(contentType) {
  return contentType
    ? contentTypeImages(`./wdg-big-bg-${contentType}.png`)
    : '';
}

angular
  .module('wcm.sites')
  .directive('wcmCampaignsPreview', function() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        title: '@',
        campaignsType: '@',
        campaigns: '=?'
      },
      templateUrl: 'app/sections/sites/campaignsPreview.html'
    };
  })
  .directive('campaignsPreviewSlider', function($timeout) {
    return {
      link(scope, el) {
        let $items;
        let $list;
        let $wrapper;
        let itemsNumber;
        let itemWidth;
        let listWidth;
        let currentSliderOffset = 0;

        const init = function() {
          $items = el.find('.camp-prev_widget-i');
          $list = el.find('.camp-prev_widgets-list');
          $wrapper = el.find('.camp-prew_widgets-overflow');
          itemsNumber = $items.length;
          itemWidth = $items.outerWidth(true);
          listWidth = itemsNumber * itemWidth;
          $list.width(listWidth);
          scope.showNavigationBtns = listWidth > $wrapper.width();
          // console.log(itemsNumber, itemWidth, listWidth, scope.showNavigationBtns);
        };

        scope.slide = function(direction) {
          if (direction === 'left') {
            currentSliderOffset += itemWidth;
          }
          if (direction === 'right') {
            currentSliderOffset -= itemWidth;
          }
          if (currentSliderOffset > 0) {
            currentSliderOffset = 0;
          }
          if (currentSliderOffset < -listWidth + itemWidth) {
            currentSliderOffset = -listWidth + itemWidth;
          }
          $list.css('transform', `translate(${currentSliderOffset}px`);
        };

        scope.getWidgetPlaceholderImage = getWidgetPlaceholderImage;

        scope.campaignState = {
          'always running': '__always-running',
          running: '__running',
          draft: '__draft',
          stopped: '__stopped',
          'stopped (tr)': '__stopped',
          scheduled: '__scheduled',
          finished: '__finished'
        };

        scope.campaignStatus = {
          archive: '__archive',
          active: '__active'
        };

        $timeout(init);
      }
    };
  })
  .directive('campaignPreviewPopover', function($tooltip) {
    return $tooltip(
      'campaignPreviewPopover',
      'campaignPreviewPopover',
      'mouseenter'
    );
  })
  .directive('campaignPreviewPopoverPopup', function() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        title: '@',
        content: '=',
        placement: '@',
        animation: '&',
        isOpen: '&',
        $close: '&'
      },
      controller($scope) {
        $scope.getWidgetPlaceholderImage = getWidgetPlaceholderImage;
      },
      templateUrl: 'app/sections/sites/campaignPreviewPopover.html'
    };
  });
