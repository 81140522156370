/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

// scroll offset in pixels to treat element as visible before it actually is
const SCROLL_OFFSET = 50;

function isScrolledIntoView(el) {
  const boundingRect = el.getBoundingClientRect();
  return (
    boundingRect.bottom + SCROLL_OFFSET >= 0 &&
    boundingRect.top - SCROLL_OFFSET <= window.innerHeight
  );
}

angular
  .module('wcm.analytics2')
  .directive('analyticsBlock2', function() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        pending: '=?',
        noDataAvailability: '=?',
        title: '@',
        onRemove: '&?',
        isRemovable: '@',
        onVisibilityChange: '&?',
        headerDisabled: '=',
        hasTooltip: '@',
        filtersIconType: '@',
        tooltipKey: '@'
      },
      transclude: true,
      templateUrl: 'app/core/analytics2/analyticsBlock.html',
      controller() {
        const charts = {};
        this.registerChart = function(chartId, chartObj) {
          charts[chartId] = chartObj;
        };
        this.unregisterChart = function(chartId) {
          charts[chartId] = null;
        };
        this.getChartObj = function(chartId) {
          return charts[chartId];
        };
      },
      link(scope, el, attrs, ctrl, transclude) {
        el.attr('id', scope.title); // set id of block
        el.attr('data-hook-id', scope.title); // set hook id for testing
        transclude(function(clone) {
          angular.forEach(clone, function(cloneEl) {
            if (cloneEl.attributes) {
              const destinationId = cloneEl.attributes['transclude-to'].value;
              const destination = el.find(
                `[transclude-from="${destinationId}"]`
              );
              if (destination.length) {
                destination.append(cloneEl);
              } else {
                cloneEl.remove();
              }
            }
          });
        });

        let lastVisibilityStatus = isScrolledIntoView(el[0]);
        scope.onVisibilityChange({ visibility: lastVisibilityStatus });

        const scrollHandler = window._.debounce(function scrollHandler() {
          const newVisibilityStatus = isScrolledIntoView(el[0]);
          if (newVisibilityStatus !== lastVisibilityStatus) {
            scope.onVisibilityChange({ visibility: newVisibilityStatus });
          }
          lastVisibilityStatus = newVisibilityStatus;
          return true;
        }, 200);

        scope.$on('analytics:scroll', scrollHandler);
        scope.$on('$destroy', function() {
          scope.onVisibilityChange({ visibility: false });
        });
      }
    };
  });
