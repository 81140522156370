/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2015 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

// todo: cra-change
if (true) {
  // eslint-disable-line no-undef
  const hotkeys = require('hotkeys-js').noConflict(true); // eslint-disable-line global-require

  angular.module('wcm.debug').directive('debugHook', function() {
    return {
      restrict: 'A',
      scope: {
        hookAttr: '@',
        hookEl: '@'
      },
      link(scope) {
        let debugMode = false;

        // Get xpath on click element with hook
        function getHierarchyOnClick(e) {
          const xpathData = getHierarchy(e.target).join('');
          e.preventDefault();
          e.stopPropagation();
          console.log(xpathData);
        }

        // Get xpath for all elements with hook
        // Leave this function for future usage
        // eslint-disable-next-line no-unused-vars
        function getHierarchyAll() {
          const nodeFinds = window.$(document).find(`[${scope.hookAttr}]`);
          const arrayFinds = Array.prototype.slice.call(nodeFinds);
          let finals = arrayFinds.map(getHierarchy);
          finals = finals.filter(function(item, key) {
            return arContainsAnotherAr(item, finals, key);
          });
          finals = finals.map(function(item) {
            return item.join('/');
          });
          console.log(finals);
        }

        // Create xpath for each element with hook
        function getHierarchy(t) {
          const hierarchy = [];
          let isFirstEl = false;
          while (t !== document.body) {
            // If hookEl is present inside current tag ->
            // add this tag with it hookAttr to the begin of hierarchy
            if (t.getElementsByTagName(scope.hookEl)[0] && !isFirstEl) {
              const hookEl = t.getElementsByTagName(scope.hookEl)[0];
              hierarchy.unshift(
                `//${scope.hookEl}[@${scope.hookAttr}='${hookEl.getAttribute(
                  scope.hookAttr
                )}']`
              );
            }
            isFirstEl = true;

            // If tag has hookAttr -> add value of hookAttr to the begin of the hierarchy
            if (t.hasAttribute(scope.hookAttr)) {
              hierarchy.unshift(
                `//*[@${scope.hookAttr}='${t.getAttribute(scope.hookAttr)}']`
              );
            }

            // If :text postfix presents -> search by text
            // eslint-disable-next-line no-param-reassign
            t = t.parentElement;
          }
          return hierarchy;
        }

        // Filter duplication arrays
        function arContainsAnotherAr(one, two, startKey) {
          for (let i = startKey + 1; i < two.length; i++) {
            const dif = window._.difference(one, two[i]).length === 0;
            if (dif) return false;
          }
          return true;
        }

        function switchOf() {
          window.$('body').removeClass('debug-hook');
          window.$(`[${scope.hookAttr}]`).off('click', getHierarchyOnClick);
        }

        function switchOn() {
          window.$('body').addClass('debug-hook');
          window.$(`[${scope.hookAttr}]`).on('click', getHierarchyOnClick);
        }

        hotkeys('ctrl+shift+d', () => {
          if (debugMode) {
            switchOf();
          } else {
            switchOn();
          }
          debugMode = !debugMode;
        });

        // TODO: add shortcut for getHierarchyAll()
      }
    };
  });
}
