/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').component('widgetQuestionEditor', {
  templateUrl:
    'app/sections/widgets/editor/pollWidget/widgetQuestionEditor.html',
  controller: widgetQuestionEditorController,
  bindings: {
    question: '=',
    availableLocales: '<',
    defaultLocale: '<'
  }
});

function widgetQuestionEditorController(widgetsFactory, i18nFactory) {
  const ctrl = this;

  let currentLocaleId = i18nFactory.getState().currentLocale.id.split('-')[0];
  currentLocaleId = currentLocaleId === 'ua' ? 'uk' : currentLocaleId;

  ctrl.editorOptions = {
    language: currentLocaleId,
    toolbar: 'terms',
    toolbar_terms: [
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat']
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote'
        ]
      },
      {
        name: 'alignment',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
      },
      { name: 'styles', items: ['Styles', 'Format'] },
      { name: 'colors', items: ['TextColor'] }
    ],
    disableNativeSpellChecker: false,
    extraPlugins: 'colorbutton,colordialog,justify,pastefromword',
    uiColor: '#DFE0DF',
    height: '200px',
    width: '100%', // increased size to fit ckeditor's toolbar on one line
    resize_enabled: false,
    customConfig: ''
  };

  ctrl.selectLocale = function(locale) {
    ctrl.selectedLocale = locale;
  };
  ctrl.$onInit = function() {
    ctrl.selectedLocale = ctrl.defaultLocale;
  };

  ctrl.validators = widgetsFactory.validators;
}
