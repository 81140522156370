/* eslint-disable no-multi-str */
/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.core')

  .directive('printDiv', function($timeout) {
    return {
      restrict: 'A',
      scope: {
        preparePrint: '&',
        printAll: '=',
        printExpects: '@'
      },
      link(scope, element, attrs) {
        let needPrint = false;
        element.bind('click', function(evt) {
          evt.preventDefault();
          needPrint = true;
          if (scope.printAll.length === +scope.printExpects) {
            PrintElem(attrs.printDiv);
            needPrint = false;
          } else {
            scope.preparePrint();
          }
        });

        scope.$watchCollection('printAll', function() {
          if (
            scope.printAll.length === +scope.printExpects &&
            scope.printAll.length !== 0 &&
            needPrint
          ) {
            $timeout(function() {
              PrintElem(attrs.printDiv);
            }, 1000);
            needPrint = false;
          }
        });

        function PrintElem() {
          const printContents = document.getElementById('printThisElement')
            .innerHTML;
          let contentWidth = document.getElementById('printThisElement')
            .clientWidth;
          const svgs = window.$('#printThisElement').find('svg');
          const contentHeight = svgs && svgs[0] ? svgs[0].clientHeight : 450;
          const deltaContent = contentHeight / contentWidth;
          if (deltaContent > 0.41) {
            contentWidth = contentHeight / 0.41;
          }
          let styles = '';
          const scripts = '';
          window
            .$('head')
            .find('link')
            .each(function() {
              styles += ` ${this.outerHTML}`;
            });
          const popupWin = window.open(
            '',
            '_blank',
            `width=${contentWidth},height=700,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no`
          );
          if (popupWin == null || typeof popupWin === 'undefined') {
            alert(
              'Turn off your pop-up blocker!\n\nWe try to open the following print window'
            );
            return false;
          }
          popupWin.window.focus();
          popupWin.document.write(
            `<!DOCTYPE html><html><head>${styles}<style type="text/css">` +
              `body { background-color: #fff; overflow-y: auto; line-height: 2; size: landscape; width: ${contentWidth}px;} ` +
              '.analytics-block_graph { background-color: #fafafa; border: 1px solid #ccc; }' +
              '[class^="col-lg"] { float: left !important; overflow: hidden; }' +
              '.col-lg-4 { width: 33% }' +
              '.col-lg-8 { width: 66% }' +
              '.analytics-block_parameter.__right {\
                      position: absolute;\
                      right: 75px;\
                      top: 50%;\
                      transform: translateY(-50%);\
                    }\
                    .nvd3 {\
                    display: block;\
                    overflow: visible;\
                    width:100%;\
                    }' +
              '.onPrint {\
                      display: block;\
                    }' +
              '.axis text, .nv-axis text {\
                      font-size: 12px;\
                    }\
                    #expandSitesBtn {\
                      display: none;\
                    }' +
              '.analytics-block_close { display: none; } ' +
              '.filter-aggregated { display: none; } ' +
              '.filter-numbers { display: none; } ' +
              `</style>${scripts}<title>Print Campaign Analytics</title>` +
              `</head><body onload="readyForPrint()"><div class="page __campaigns __analytics __ready-for-print">${printContents}</div>` +
              '<script type="text/javascript">function readyForPrint() { setTimeout(function(){ window.print(); }, 2000); }</script>' +
              '</body>' +
              '</html>'
          );

          popupWin.onbeforeunload = function() {
            popupWin.close();
            return '.\n';
          };

          popupWin.onabort = function() {
            popupWin.document.close();
            popupWin.close();
          };

          popupWin.document.close();

          return true;
        }
      }
    };
  });
