/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;

angular
  .module('wcm.sites')
  .controller('siteAnalyticsCtrl', function(
    $scope,
    $state,
    analyticsFactory2,
    navigationPanel
  ) {
    const state = $scope.state;
    const options = $scope.options;
    const handlers = $scope.handlers;

    options.printTitle = 'Site-specific analytics';
    options.analyticsPeriodLimit = $scope.currentUser.analyticsPeriodLimit;

    handlers.isVisible = function(blockName) {
      return state.blocksState && state.blocksState[blockName];
    };

    handlers.initManageBlocks = function() {
      state.filters.siteIds = [+$state.params.id];
    };

    handlers.hasPermission = function(type) {
      switch (type) {
        case 'export':
          return $scope.site && $scope.site.permissions.exportAnalytics;
        default:
          return false;
      }
    };

    handlers.reinitMinMaxPeriod = function() {
      // set available Min Date
      options.availableMinDate = moment($scope.currentUser.systemCreationDate)
        .startOf('day')
        .valueOf();
      // set default Min Period
      options.minPeriod = moment($scope.currentUser.systemCreationDate);

      // >>>>> DEPRECATE period by siteCreationDate <<<<<
      // options.availableMinDate = moment($scope.site.creationDate).startOf('day').valueOf();
      // >>>>>><<<<<<

      // calculate Min Period
      options.minPeriod = analyticsFactory2.getAnalyticsMinPeriod(
        moment(options.minPeriod).startOf('day'),
        moment(state.filters.endPeriod).startOf('day'),
        options.analyticsPeriodLimit
      );
      // calculate Max Period
      options.maxPeriod = state.filters.endPeriod;
      // calculate Start Period
      options.startPeriod =
        state.filters.startPeriod < options.minPeriod
          ? options.minPeriod
          : state.filters.startPeriod;
    };

    // Init analytics
    $scope.init = function() {
      navigationPanel.setActionButtons([]);
      if (state.noWas) {
        return false;
      }
      state.itemName = $scope.site.name;
      $scope.setBreadcrumbs('details', $scope.site.id, $scope.site.name);

      $scope.$parent.init(options);
      state.filters.siteIds = [+$state.params.id];
      return undefined;
    };

    // Init when get data by site
    $scope.$watch('site', function(val) {
      if (val) {
        $scope.init();
      }
    });
  });
