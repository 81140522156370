/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.export').directive('exportMonitoring', function() {
  return {
    restrict: 'E',
    scope: {
      exportHandler: '&?',
      isExportDisabled: '=?'
    },
    controller($scope, navigationPanel) {
      $scope.exportFlag = false;

      // On press export btn -----
      $scope.export = function() {
        $scope.exportFlag = true;
        $scope.exportHandler().finally(function() {
          $scope.exportFlag = false;
        });
      };

      navigationPanel.setActionButtons([
        {
          title: () => 'campaigns.buttons.export',
          isDisabled: () => $scope.isExportDisabled,
          isBusy: () => $scope.exportFlag,
          type: () => 'action-1',
          isShown: () => true,
          clickHandler: () => {
            $scope.export();
          }
        }
      ]);
    }
  };
});
