/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('historyFactory', function(requestFactory, COMMON_CONFIG) {
    const getModificationHistory = function(type, id) {
      return requestFactory.post(
        COMMON_CONFIG.commonServlet,
        COMMON_CONFIG.getModificationHistory,
        { objectType: type, objectId: id }
      );
    };

    return {
      getModificationHistory
    };
  });
