/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.helpers').directive('resizer', function($window) {
  return {
    restrict: 'A',
    scope: {
      resizeClasses: '@',
      resizeWSize: '@',
      resizeType: '@',
      resizeClassesTrigger: '@'
    },
    link(scope, elem) {
      angular.element($window).on('resize', function() {
        console.log(scope.resizeClasses);
        console.log(scope.resizeWSize);
        console.log(scope.resizeType);
        if (scope.resizeType === 'lower') {
          if ($window.innerWidth <= +scope.resizeWSize) {
            elem
              .addClass(scope.resizeClasses)
              .removeClass(scope.resizeClassesTrigger);
          } else {
            elem
              .removeClass(scope.resizeClasses)
              .addClass(scope.resizeClassesTrigger);
          }
        } else if (scope.resizeType === 'greater') {
          if ($window.innerWidth > +scope.resizeWSize) {
            elem
              .addClass(scope.resizeClasses)
              .removeClass(scope.resizeClassesTrigger);
          } else {
            elem
              .removeClass(scope.resizeClasses)
              .addClass(scope.resizeClassesTrigger);
          }
        }
      });
    }
  };
});
