const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateMonitoringVisitorsCtrl', function(
    $scope,
    $state,
    $controller,
    analyticsFactory2,
    REPORTING_CONFIG,
    navigationPanel,
    ANALYTICS_CONFIG2
  ) {
    const A_CONFIG = ANALYTICS_CONFIG2;
    const state = $scope.state;
    let handlers = $scope.handlers;

    angular.extend($scope.options, {
      blocks: A_CONFIG.blocks.monitoring,
      minPeriod: $scope.currentUser.systemCreationDate
    });
    // eslint-disable-next-line no-multi-assign
    handlers = $scope.handlers = angular.extend({}, handlers, {
      save() {
        $scope.$parent.handlers.save(state);
      },
      getAvailableValues() {
        return $scope.$parent.handlers.getAvailableValues().then(function() {
          state.sortType = 'last_visit';
          state.sortDirection = 'desc';
          state.isBusy = false;
        });
      },
      hasCreatePermission
    });

    $scope.init = function() {
      $scope.$parent.init();
      handlers.getAvailableValues();
    };

    // Has create permission
    function hasCreatePermission() {
      return true;
    }
    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.generate',
        isDisabled: () => $scope.state.noWas || $scope.state.isBusy,
        isShown: () => true,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.reportForm.$valid) {
            $scope.handlers.save();
          }
        }
      },
      {
        title: () => 'reporting.buttons.cancel',
        isDisabled: () => $scope.state.isBusy,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);
  });
