/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .controller('tmpLineCtrl', function($scope) {
    $scope.blocks = {
      test: true
    };
    $scope.removeBlock = function(blockName) {
      $scope.blocks[blockName] = false;
    };

    // $scope.testData = {
    //   data: [
    //     {
    //       name: 'Visitors',
    //       data: {
    //         x: 'ticksX',
    //         columns: [
    //           [
    //             'visitors',
    //             50,
    //             12,
    //             20,
    //             45,
    //             12,
    //             21,
    //             120,
    //             80,
    //             115,
    //             99
    //           ],
    //           [
    //             'views',
    //             5,
    //             120,
    //             30,
    //             15,
    //             38,
    //             12,
    //             3,
    //             45,
    //             50,
    //             55
    //           ],
    //           [
    //             'ticksX',
    //             1432252800000,
    //             1432339200000,
    //             1432425600000,
    //             1432512000000,
    //             1432598400000,
    //             1432684800000,
    //             1432771200000,
    //             1432857600000,
    //             1432944000000,
    //             1433030400000
    //           ]
    //         ],
    //         type: 'spline'
    //       },
    //
    //
    //       options: {
    //         maxY: 200,
    //         ticksX: [
    //           1432252800000,
    //           1432339200000,
    //           1432425600000,
    //           1432512000000,
    //           1432598400000,
    //           1432684800000,
    //           1432771200000,
    //           1432857600000,
    //           1432944000000,
    //           1433030400000
    //         ],
    //         ticksX2: [
    //           {
    //             temperature: 20,
    //             weatherType: 'good'
    //           },
    //           {
    //             temperature: 10,
    //             weatherType: 'bad'
    //           },
    //           {
    //             temperature: 5,
    //             weatherType: 'good'
    //           },
    //           {
    //             temperature: -20,
    //             weatherType: 'bad'
    //           },
    //           {
    //             temperature: 20,
    //             weatherType: 'good'
    //           },
    //           {
    //             temperature: 13,
    //             weatherType: 'good'
    //           },
    //           {
    //             temperature: 10,
    //             weatherType: 'bad'
    //           },
    //           {
    //             temperature: 15,
    //             weatherType: 'good'
    //           },
    //           {
    //             temperature: -10,
    //             weatherType: 'bad'
    //           },
    //           {
    //             temperature: 20,
    //             weatherType: 'good'
    //           }
    //         ]
    //       }
    //     }
    //   ]
    // };
  });
