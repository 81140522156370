/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('lineBarChart', function($filter, $timeout, analyticsFactory2) {
    return {
      require: ['analyticsChart2', '^analyticsBlock2'],
      restrict: 'A',
      replace: true,
      scope: {
        chartId: '@',
        chartData: '=?',
        chartNumbers: '=?',
        chartRegions: '@',
        chartHasNoTranslation: '=?',
        chartIsRebuildOnLocaleChange: '@'
      },
      templateUrl: 'app/core/analytics2/chart.html',
      link(scope, el, attrs, ctrls) {
        scope.chart = null;

        const analyticsBlockCtrl = ctrls[1];
        const i18n = $filter('i18n');

        // Default tooltip
        const defaultTooltip = {
          contents(d, defaultTitleFormat, defaultValueFormat, color) {
            // Use default rendering with different title
            if (!scope.chartHasNoTranslation) {
              // eslint-disable-next-line no-param-reassign
              d = d.map(function(item) {
                // eslint-disable-next-line no-param-reassign
                item.name = i18n(`analytics.filters.${item.name}`);
                return item;
              });
            }
            return this.getTooltipContent(
              d,
              defaultTitleFormat,
              defaultValueFormat,
              color
            );
          },
          format: {
            value(value, ratio, id) {
              return id === 'ctr' && value > 0
                ? window.d3.format('.2%')(value)
                : window.d3.format('.0f')(value);
            }
          }
        };

        // trigger to show number
        const changeShowNumbers = function(val) {
          window
            .$(`#${scope.chartId}`)
            .find('.c3-chart-texts')
            .css('display', val ? 'block' : 'none');
        };

        // Build and register chart (in first time)
        const buildChart = function() {
          scope.chart = window.c3.generate(
            angular.extend({}, analyticsFactory2.defaultChartConfig, {
              bindto: `#${scope.chartId}`,
              size: {
                height: 470
              },
              data: scope.chartData
                ? scope.chartData.data
                : { columns: [[], [], []] },
              tooltip: scope.chartData.options.tooltip || defaultTooltip,
              axis: {
                x: scope.chartData.options.xAxis,
                y: scope.chartData.options.yAxis,
                y2: scope.chartData.options.y2Axis
              },
              color: {
                pattern: scope.chartData.options.colorPattern
              },
              grid: scope.chartData.options.grid || {
                y: {
                  show: true,
                  count: 5
                }
              },
              regions:
                scope.chartData && scope.chartRegions
                  ? analyticsFactory2.setBarRegions(
                      scope.chartData.options.ticksX
                    )
                  : []
            })
          );
          analyticsBlockCtrl.registerChart(scope.chartId, scope.chart);
          changeShowNumbers(scope.chartNumbers);
        };

        // Update chart (after first build)
        const rebuildChart = function(resizeFlag) {
          // FIXME: currently implemented logic always sets opacity to 0 but returns it to 1
          // only when resizeFlag is true
          window.$(`#${scope.chartId}`).css({ opacity: 0 });
          scope.chart.internal.config.axis_x_categories =
            scope.chartData.options.xAxis.categories;
          scope.chart.internal.config.axis_y_tick_format =
            scope.chartData.options.yAxis.tick.format;
          scope.chart.internal.config.axis_y_max =
            scope.chartData.options.yAxis.max || null;
          scope.chart.internal.config.axis_y_padding =
            scope.chartData.options.yAxis.padding;
          scope.chart.internal.config.color_pattern =
            scope.chartData.options.colorPattern;
          scope.chart.internal.config.tooltip_format_value = scope.chartData
            .options.tooltip
            ? scope.chartData.options.tooltip.format.value
            : defaultTooltip.format.value;
          // Flush chart to prevent bad rendering
          scope.chart.flush();

          // Resize chart if it need
          if (resizeFlag) {
            $timeout(function() {
              scope.chart.resize();
              window.$(`#${scope.chartId}`).css({ opacity: 1 });
            }, 500);
          }
        };

        // Render chart
        const renderChart = function(val) {
          // If data is present -> build chart
          if (val.columns) {
            // If no 'aggregated' present -> build chart differently
            if (val.aggregated === null || val.aggregated === undefined) {
              // Build NEW chart if no chart present
              if (!scope.chart) {
                scope.$evalAsync(buildChart);
                // Update chart data, update chart config and make flush in other cases
              } else {
                scope.chart.load(scope.chartData.data);
                rebuildChart(true);
              }
            }
            // If 'aggregated' is present -> build NEW chart
            if (val.aggregated === true || val.aggregated === false) {
              scope.$evalAsync(buildChart);
            }
          }
        };

        // Render chart on data or filters changes
        scope.$watchCollection(
          function() {
            return {
              columns: scope.chartData ? scope.chartData.data.columns : null,
              filter: scope.chartData ? scope.chartData.options.filter : null,
              aggregated: scope.chartData
                ? scope.chartData.options.aggregated
                : null
            };
          },
          renderChart,
          true
        );

        // Show or hide data labels
        scope.$watch('chartNumbers', function(val) {
          changeShowNumbers(val);
        });

        // Unregister chart on destroy
        scope.$on(
          '$destroy',
          analyticsBlockCtrl.unregisterChart.bind(null, scope.chartId)
        );

        // If chart ticks relative to locale -> Create 'change locale' watcher ->
        // update chart with new locale
        if (scope.chartIsRebuildOnLocaleChange) {
          scope.$on('i18n:localeChanged', function() {
            if (
              scope.chart &&
              scope.chartData &&
              scope.chartData.options.categories
            ) {
              scope.chartData.options.xAxis.categories = scope.chartData.options.categories.map(
                function(item) {
                  return (
                    i18n(`analytics.ticks.${item[0]}`) +
                    (item[1] ? `, ${item[1]}` : '')
                  );
                }
              );
              rebuildChart(true);
            }
          });
        }
      }
    };
  });
