/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const L = require('leaflet');

angular
  .module('wcm.geoLocation')
  .directive('wcmGeoMapMultiMarkers', function(geoLocationFactory, $timeout) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        geoMapList: '=?', // Data to show
        geoMapClass: '@', // css class for map
        geoMapUpdateOnChange: '=?', // When var was change -> update map without reloading (only clear and set new data)
        geoMapMarkerSelectAction: '&', // Select marker action
        geoMapShowLabels: '=?', // Show icon of marker like icon + text label
        geoMapIsBusy: '=?', // is data for map preparing (loading) at that moment
        geoMapIsEditMode: '=?', // is in edit mode
        geoMapPopupType: '@' // popup type
      },
      transclude: true,
      templateUrl: 'app/core/geo_location/map.html',
      link(scope, el, attrs, ctrl, transclude) {
        // Tranclude
        transclude(scope, function(clone) {
          el.find('[geo-map-transclude]').append(clone);
        });

        // Create MapID -----
        const MAP_ID = 'wcm-geo-map';
        const geoCenter = geoLocationFactory.mapCenter;
        const geoMapEl = el.find('[geo-map]');
        geoMapEl.attr('id', MAP_ID);
        geoMapEl.addClass(scope.geoMapClass);

        // Init vars -----
        scope.markers = [];
        scope.locationFind = {};
        scope.markersLength = 0;
        const markerIcons = geoLocationFactory.markerIcons;
        let map;
        let currentArea;
        let markersGroup;

        // Init scope vars -----
        scope.dataLoading = true;
        scope.noDataCoords = false;

        // Map handlers
        // eslint-disable-next-line no-multi-assign
        const handlers = (scope.handlers = {
          createCurrentLocationBtn: L.Control.extend({
            options: {
              position: 'bottomright'
            },
            onAdd(map) {
              const container = L.DomUtil.create(
                'div',
                'leaflet-bar leaflet-control leaflet-control-custom leaflet-current-area fa fa-crosshairs'
              );
              container.onclick = function() {
                let locationError = 0;
                map
                  .locate({ setView: true, watch: true })
                  .on('locationfound', function(e) {
                    currentArea = L.marker([e.latitude, e.longitude]);
                    map.addLayer(currentArea);
                  })
                  .on('locationerror', function() {
                    if (!locationError) {
                      locationError++;
                      alert('Location access denied.');
                    }
                  });
              };
              return container;
            }
          }),

          whichIcon() {},

          setDefaultIcons() {},

          setInactiveIcons() {},

          // Set map center
          setMapCenter() {
            map.fitBounds(markersGroup.getBounds());
            $timeout(function() {
              scope.dataLoading = false;
            }, 250);
          },

          // Create markers from MapObjects and add it on map -----
          addMarkerOnMapMain(site) {
            let coords;
            let marker;
            let icon;

            // Set coords array
            if (Array.isArray(site)) {
              coords = angular.copy(site);
            } else if (typeof site === 'object' && site.coords) {
              coords = [+site.coords.latitude, +site.coords.longitude];
            } else if (typeof site === 'object' && site.lat && site.lon) {
              coords = [+site.lat, +site.lon];

              // FIXME: try to make this function to be pure
              // eslint-disable-next-line no-param-reassign
              site = geoLocationFactory.setSiteInfoByAddress(site);
            }

            // If no coords -> don't add current site to map -> return null
            if (!coords) {
              return null;
            }

            // Set marker default icon
            // eslint-disable-next-line prefer-const
            icon = angular.copy(
              site.checked
                ? markerIcons.defaultActive
                : markerIcons[site.className] || markerIcons.grey
            );

            // Set markers icons
            if (scope.geoMapShowLabels) {
              icon.options.html = geoLocationFactory.setMarkerLabel(
                site.visitsOrder || 0
              );
            }

            // eslint-disable-next-line prefer-const
            marker = L.marker(coords, { icon, isActive: false, data: site });
            marker.addTo(markersGroup);

            // eslint-disable-next-line no-use-before-define
            debouncedSetMapCenter();

            return marker;
          },

          // Init popup element for marker
          initMarkersPopup(site) {
            const popupOptions = {
              offset: new L.Point(0, 0),
              className: 'monitoring_marker-popup'
            };
            const domPopupElement = document.createElement('div');
            const markerScope = scope.$new();
            markerScope.marker = site;
            markerScope.isEditMode = scope.geoMapIsEditMode;
            markerScope.action = scope.geoMapMarkerSelectAction;
            window
              .$(domPopupElement)
              .append(
                geoLocationFactory.generateMarkerPopup(
                  markerScope,
                  scope.geoMapPopupType || 'campaignSites'
                )
              );
            return { element: domPopupElement, options: popupOptions };
          },

          // Set marker click event
          addOnClickEvent(marker) {
            marker.on('click', function() {
              // Some stuff on click
              return false;
            });
            return marker;
          },

          // Add markers on map when we need to get several markers
          addMarkerOnMap(site, index) {
            const marker = handlers.addMarkerOnMapMain(site, index);
            if (!marker) {
              return null;
            }

            const popup = handlers.initMarkersPopup(site);
            marker.bindPopup(popup.element, popup.options);

            // handlers.addOnClickEvent(marker);
            return marker;
          },

          updateMap() {
            $timeout(function() {
              map.invalidateSize();
            }, 500);
            geoLocationFactory.setMarkersIcons(scope.markers, markerIcons.grey);
          },

          updateMapWithNewData(newList) {
            if (newList) {
              if (!newList.length) {
                map.setView(new L.LatLng(geoCenter[0], geoCenter[1]), 3);
                scope.dataLoading = false;
                scope.noDataCoords = true;
                markersGroup.clearLayers();
              } else {
                init(newList);
              }
            }
          },

          removeCurrentAreaMarker() {
            if (currentArea) map.removeLayer(currentArea);
          }
        });

        // Set map center with some debounce
        const debouncedSetMapCenter = window._.debounce(
          handlers.setMapCenter,
          1000
        );

        // Update map with some debounce
        const debouncedUpdateMap = window._.debounce(handlers.updateMap, 300);

        // Update with new marker list
        const debouncedNewMarkerList = window._.debounce(
          handlers.updateMapWithNewData,
          300
        );

        // Main Init function -----
        function init(mapList) {
          scope.dataLoading = true;
          scope.noDataCoords = false;
          if (markersGroup) {
            map.closePopup();
            markersGroup.clearLayers();
          }
          scope.markers = mapList
            .filter(window._.identity)
            .filter(function(item) {
              return (
                (!!item.coords &&
                  !!item.coords.latitude &&
                  !!item.coords.longitude) ||
                (!!item.lat && !!item.lon)
              );
            });

          scope.markers = scope.markers.map(handlers.addMarkerOnMap);

          if (!scope.markers.length) {
            scope.dataLoading = false;
            scope.noDataCoords = true;
          }
        }

        // Init map -----
        map = L.map(MAP_ID, {
          zoomControl: false,
          center: geoCenter, // geo center of Europe
          zoom: 3
        });

        L.control
          .zoom({
            position: 'bottomright'
          })
          .addTo(map);

        L.tileLayer(
          'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoianNoZXBhcmQiLCJhIjoiY2lvdjdlaHZlMDA1eHc5a2plMzJ5MjNreCJ9.4PeBqnAssuDVn2vC0lD5kA',
          {
            attribution:
              'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18,
            minZoom: 3,
            id: 'mapbox.streets'
          }
        ).addTo(map);

        map
          .on('click', handlers.removeCurrentAreaMarker)
          .on('dragstart', handlers.removeCurrentAreaMarker)
          .on('dragend', handlers.removeCurrentAreaMarker)
          .on('popupopen', function() {})
          .on('popupclose', function() {});

        // Add custom control for current location search
        // map.addControl(new handlers.createCurrentLocationBtn());

        // Create marker layers Group -----
        markersGroup = new L.FeatureGroup().addTo(map);

        // Start Init function only when data is not empty -----
        scope.$watchCollection('geoMapList', function(newList) {
          scope.markers = [];
          debouncedNewMarkerList(newList);
        });

        // Update map on change some variable -----
        scope.$watch('geoMapUpdateOnChange', function() {
          map.closePopup();
          debouncedUpdateMap();
        });
      }
    };
  });
