const { angular } = window;

angular.module('wcm.components').component('wcmBreadcrumbs', {
  templateUrl: 'app/core/components/breadcrumbs/breadcrumbs.html',
  controller: breadcrumbsController
});

function breadcrumbsController($scope, $state, breadcrumbs) {
  const ctrl = this;

  $scope.$watch(breadcrumbs.get, function(_breadcrumbs) {
    const fullPath = _breadcrumbs.path || [];
    const itemName = _breadcrumbs.itemName;
    ctrl.section = fullPath[0];
    ctrl.subsections = fullPath.slice(1);
    ctrl.itemName = itemName;
    ctrl.href = $state.href;
  });
}
