/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.core')
  .directive('inViewContainer', function() {
    return {
      restrict: 'A',
      controller($element) {
        this.getElement = function() {
          return $element;
        };
      }
    };
  })

  .directive('inView', function($parse, $window, $timeout) {
    return {
      restrict: 'A',
      require: '^^inViewContainer',
      link(scope, el, attrs, container) {
        let options = { throttle: 0 };
        const expression = $parse(attrs.inView);
        const containerEl = container.getElement();
        const containerEvents = [
          'click',
          'wheel',
          'mousewheel',
          'DomMouseScroll',
          'MozMousePixelScroll',
          'scroll',
          'touchmove',
          'mouseup',
          'keydown'
        ];
        const windowEvents = ['ready', 'resize'];

        if (attrs.inViewOptions) {
          options = angular.extend(
            options,
            scope.$eval(attrs.inViewOptions)
          );
        }
        const throttledWorker = window._.throttle(worker, options.throttle);

        init();

        function getInfo(event) {
          const containerRect = containerEl[0].getBoundingClientRect();
          const elRect = el[0].getBoundingClientRect();
          const isVisible = !!(
            el[0].offsetWidth ||
            el[0].offsetHeight ||
            el[0].getClientRects().length
          );
          return {
            inView: isVisible && intersectRect(elRect, containerRect),
            event,
            element: el,
            elementRect: elRect,
            containerRect
          };
        }

        function worker(event) {
          const info = getInfo(event);
          scope.$applyAsync(function() {
            expression(scope, { $inview: info.inview, $inviewInfo: info });
          });
        }

        function init() {
          containerEl.on(containerEvents.join(' '), throttledWorker);
          window.$(window).on(windowEvents.join(' '), throttledWorker);
          $timeout(function() {
            throttledWorker({ type: 'initial' });
          });
        }

        el.on('$destroy', function() {
          containerEl.off(containerEvents.join(' '), throttledWorker);
          window.$(window).off(windowEvents.join(' '), throttledWorker);
        });

        function intersectRect(r1, r2) {
          return !(
            r2.left > r1.right ||
            r2.right < r1.left ||
            r2.top > r1.bottom ||
            r2.bottom < r1.top
          );
        }
      }
    };
  });
