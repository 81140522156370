/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.analytics2')
  .directive('blockDescriptionTooltip', function($tooltip) {
    return $tooltip(
      'blockDescriptionTooltip',
      'blockDescriptionTooltip',
      'click'
    );
  })
  .directive('blockDescriptionTooltipPopup', function($timeout, $window) {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        title: '@',
        content: '=',
        placement: '@',
        animation: '&',
        $close: '&'
      },
      templateUrl: 'app/core/analytics2/blockDescriptionTooltipPopup.html',
      link(scope, el) {
        const clickHandler = function() {
          scope.$close();
        };

        $window.addEventListener('scroll', scrollHandler, true);
        $window.addEventListener('resize', scrollHandler, true);
        scope.$on('$destroy', () =>
          $window.removeEventListener('scroll', scrollHandler)
        );
        scope.$on('$destroy', () =>
          $window.removeEventListener('resize', scrollHandler)
        );

        function scrollHandler() {
          scope.$close();
        }

        $timeout(function() {
          window
            .$(document)
            .on('click.blockDescriptionTooltip', el, clickHandler);
        });

        el.on('$destroy', function() {
          window.$(document).off('click.blockDescriptionTooltip', clickHandler);
        });
      }
    };
  });
