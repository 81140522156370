const { angular } = window;

angular
  .module('wcm.components')
  .controller('contactModalCtrl', function($scope, $modalInstance) {
    $scope.state = {
      submitted: false
    };

    $scope.forms = {};

    $scope.addAdditionalField = function() {
      $scope.contactPerson.additionalInfo.push({ key: '', value: '' });
    };

    $scope.removeAdditionalField = function(pIndex, index) {
      $scope.contactPerson.additionalInfo.splice(index, 1);
    };

    $scope.save = function() {
      $scope.state.submitted = true;
      if ($scope.forms.contactsForm.$invalid) return false;
      $modalInstance.close($scope.contactPerson);
      return undefined;
    };

    $scope.cancel = function() {
      $modalInstance.dismiss();
    };
  });
