/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.login')
  .controller('resetPasswordCtrl', function($scope, $state, auth) {
    $scope.resetPassword = function resetPassword(credentials) {
      $scope.blockUI = true;
      auth
        .resetPassword($state.params.token, credentials.password)
        .success(function() {
          $state.go('login.signIn');
        })
        .error(function() {
          // $scope.resetPasswordForm.password.$setValidity('serverValidation', false);
          // $scope.resetPasswordForm.passwordConfirmation.$setValidity('serverValidation', false)
        })
        .finally(function() {
          $scope.blockUI = false;
        });
    };
  });
