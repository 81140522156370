/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.helpers')
  .factory('helpers', function(
    $q,
    $rootScope,
    $modal,
    $http,
    $filter,
    CORE_CONFIG,
    i18nFactory
  ) {
    const validationPatterns = {
      macAddress: /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/i
    };

    const readFileAsDataURL = function(file) {
      const dfd = $q.defer();
      const fileReader = new FileReader();
      fileReader.onload = dfd.resolve;
      fileReader.onerror = dfd.reject;
      fileReader.readAsDataURL(file);
      // $rootScope.$apply();
      return dfd.promise;
    };

    const interpolateAndWrapString = function(string, params) {
      let i = 0;
      return string.replace(/\*\*/g, function() {
        return params && params[i]
          ? `<span class="modal__text-highlighted __${i}">${params[i++]}</span>`
          : '??';
      });
    };

    const showModal = function(
      modalType,
      title,
      message,
      i18n,
      messageParams,
      options
    ) {
      const scope = $rootScope.$new(true);
      let defaultTitleI18nPath;
      switch (modalType) {
        case 'confirmation':
          defaultTitleI18nPath = 'components.confirmationModal.defaultTitle';
          break;
        case 'error':
          defaultTitleI18nPath = 'components.errorModal.defaultTitle';
          break;
        default:
          throw new Error(`Unsupported modal type ${modalType}`);
      }

      // eslint-disable-next-line no-param-reassign
      title =
        title ||
        (i18n
          ? defaultTitleI18nPath
          : i18nFactory.translate(defaultTitleI18nPath));
      // eslint-disable-next-line no-param-reassign
      messageParams = typeof i18n === 'boolean' ? messageParams : i18n;
      // eslint-disable-next-line no-param-reassign
      messageParams = angular.isArray(messageParams)
        ? messageParams
        : [messageParams];
      const data = {
        title: i18n ? i18nFactory.translate(title) : title,
        message: i18n
          ? interpolateAndWrapString(
              i18nFactory.translate(message),
              messageParams
            )
          : message
      };
      let modalOptions;
      switch (modalType) {
        case 'confirmation':
          modalOptions = {
            windowClass: `confirmation-modal ${
              options ? options.className : ''
            }`,
            templateUrl: 'app/core/views/generalConfirmationModal.html',
            scope: angular.extend(scope, { data })
          };
          break;
        case 'error':
          modalOptions = {
            windowClass: `error-modal ${options ? options.className : ''}`,
            templateUrl: 'app/core/views/errorModal.html',
            scope: angular.extend(scope, {
              message: data.message,
              title: data.title
            })
          };
          break;
        default:
          throw new Error(`Unsupported modal type ${modalType}`);
      }

      return $modal.open(modalOptions);
    };

    const confirmationModal = function confirmationModal(
      title,
      message,
      i18n,
      messageParams,
      options
    ) {
      return showModal(
        'confirmation',
        title,
        message,
        i18n,
        messageParams,
        options
      );
    };

    const errorModal = function errorModal(
      title,
      message,
      i18n,
      messageParams
    ) {
      return showModal('error', title, message, i18n, messageParams);
    };

    const cancelConfirmModal = function cancelConfirmModal(text) {
      let i18n;
      const labels = angular.copy(text);
      if (!labels.param) {
        i18n = $filter('i18n');
        labels.title = i18n(labels.title);
        labels.message = i18n(labels.message);
      }
      return confirmationModal(labels.title, labels.message, labels.param)
        .result;
    };

    const htmlEscape = function htmlEscape(input) {
      return typeof input === 'string'
        ? input
            .replace(/&/g, '&amp;')
            .replace(/>/g, '&gt;')
            .replace(/</g, '&lt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&apos;')
        : input;
    };

    const getVersionInfo = function getVersionInfo() {
      return $q.when(process.env.REACT_APP_CMS_FE_VERSION || 'dev');
    };

    const searchInProps = function(props, obj, searchQuery) {
      return props.reduce(function(accum, currentProp) {
        if (!angular.isDefined(obj[currentProp])) return accum;
        return ~obj[currentProp].toLowerCase().indexOf(searchQuery)
          ? accum.concat([currentProp])
          : accum;
      }, []);
    };

    const searchByString = function(obj, searchQuery, props) {
      if (props[0]) {
        return searchInProps(props, obj, searchQuery);
      }
      const res = [];
      if (obj.toLowerCase().indexOf(searchQuery) !== -1) res.push(obj);
      return res;
    };

    // Unwatches with callback for Live-Prognosis and Site Loading
    const unwatches = function(ar, callback) {
      return ar.map(callback);
    };

    // Prepare Live-Prognosis request
    const mapCampaignRequest = function(campaign) {
      return {
        general: {
          campaignId: campaign.id || 0,
          type: campaign.type,
          chainId: campaign.chainId,
          targetUniqueViews: Number.isNaN(
            parseInt(campaign.targetUniqueViews, 10)
          )
            ? 0
            : campaign.targetUniqueViews,
          deliveryLimitPerMAC: campaign.deliveryLimitPerMAC || 0,
          exclusive: campaign.exclusive || 0
        },
        dateTime: {
          startDate: campaign.launchDate,
          endDate: campaign.endDate,
          timeFrames: campaign.timeFrameHours,
          days: campaign.timeFrameDays,
          calendarMode: campaign.calendarMode
        },
        device: campaign.targeting.device,
        sites: window._.reduce(
          campaign.sites,
          function(memo, value, key) {
            if (value) {
              memo.push(parseInt(key, 10));
            }
            return memo;
          },
          []
        ),
        profile: campaign.targeting.profile,
        retargeting: campaign.targeting.retargeting
      };
    };
    function equals(a, b, compare) {
      const aIsArray = Array.isArray(a);
      const bIsArray = Array.isArray(b);

      if (aIsArray !== bIsArray) return false;

      const aTypeof = typeof a;
      const bTypeof = typeof b;

      if (aTypeof !== bTypeof) return false;
      if (flat(aTypeof)) {
        return compare ? compare(a, b) : a === b;
      }

      return aIsArray
        ? shallowArray(a, b, compare)
        : shallowObject(a, b, compare);
    }

    function shallowArray(a, b, compare) {
      const l = a.length;
      if (l !== b.length) return false;

      if (compare) {
        for (let i = 0; i < l; i++) {
          if (!compare(a[i], b[i])) return false;
        }
      } else {
        for (let i = 0; i < l; i++) {
          if (a[i] !== b[i]) return false;
        }
      }

      return true;
    }

    /* eslint-disable */
    function shallowObject(a, b, compare) {
      let ka = 0;
      let kb = 0;

      if (compare) {
        for (var key in a) {
          if (a.hasOwnProperty(key) && !compare(a[key], b[key])) return false;

          ka++;
        }
      } else {
        for (var key in a) {
          if (a.hasOwnProperty(key) && a[key] !== b[key]) return false;

          ka++;
        }
      }

      for (var key in b) {
        if (b.hasOwnProperty(key)) kb++;
      }

      return ka === kb;
    }
    /* eslint-enable */

    function flat(type) {
      return type !== 'function' && type !== 'object';
    }

    // frontend pagination logic related stuff

    function hasher(...args) {
      return JSON.stringify(args);
    }

    function makeFilter(itemsList, filterFn) {
      function filterHandler(searchСriteria) {
        return itemsList.filter(filterFn.bind(null, searchСriteria));
      }
      return window._.memoize(filterHandler, hasher);
    }

    function getPage(itemsList, ipp, pageNumber) {
      const startIndex = (pageNumber - 1) * ipp;
      return itemsList.slice(startIndex, startIndex + ipp);
    }

    /**
     * Calls <tt>cb</tt> when fn called with arbitrary params got resolved
     * @param cb {Function} function to be called
     * @returns {Function}
     */
    function cbAfter(cb) {
      return function(fn, ctx) {
        return function(...args) {
          return $q.when(fn.apply(ctx, args)).then(cb);
        };
      };
    }

    function getTruthyKeys(obj) {
      return window._.keys(window._.pick(obj, window._.identity));
    }

    /**
     * Calls <tt>cb</tt> every time new promise is being added to queue and after its resolving
     */
    function promiseQueue(cb) {
      const promiseQueue = [];
      return function(fn) {
        return function(...args) {
          const returnVal = fn(...args);
          if (angular.isFunction(returnVal.then)) {
            promiseQueue.push(returnVal);
            cb(promiseQueue);
            returnVal.finally(function() {
              promiseQueue.splice(promiseQueue.indexOf(returnVal), 1);
              cb(promiseQueue);
            });
          }
          return returnVal;
        };
      };
    }

    // Function which count watchers
    const watcherCounter = function() {
      const root = window.$(document.getElementsByTagName('body'));
      let watchers = 0;

      const f = function(element) {
        // eslint-disable-next-line no-prototype-builtins
        if (element.data().hasOwnProperty('$scope')) {
          watchers += (element.data().$scope.$$watchers || []).length;
        }

        angular.forEach(element.children(), function(childElement) {
          f(window.$(childElement));
        });
      };

      f(root);

      console.log(watchers);

      return watchers;
    };

    const isServerNotAvailable = function(status) {
      return status > 500 && status <= 504;
    };

    const isDataNotAvailable = function(status) {
      let msg = false;
      if (status === 204) {
        msg = 'noDataInSystem';
      } else if (status === 244) {
        msg = 'noDataYetAvailable';
      } else if (
        status >= 300 &&
        status <= 399 &&
        status !== 302 &&
        status !== 304
      ) {
        msg = 'noDataYetAvailable';
      } else if (status === 245 || (status >= 400 && status <= 599)) {
        msg = 'noServerAvailable';
      } else if (status <= 0) {
        msg = 'canceled';
      }
      return msg;
    };

    const filterBy = function(items, type) {
      return items.reduce(function(prev, curr) {
        if (curr.checked) {
          if (Array.isArray(type)) {
            const newObj = {};
            type.forEach(function(t) {
              newObj[t] = curr[t];
            });
            prev.push(newObj);
          } else {
            prev.push(curr[type]);
          }
        }
        return prev;
      }, []);
    };

    const getPeriodTimestamps = function(period) {
      return period.map(function(timestamp) {
        const _timestamp = angular.copy(window.moment(timestamp));
        // cast to utc midnight
        return _timestamp.utc().valueOf();
      });
    };

    const listModal = function listModal(
      allItems,
      checkedItems,
      trackBy,
      options,
      confirmationProps,
      filters,
      filterTypes,
      collapseType
    ) {
      const scope = $rootScope.$new(true);
      const i18n = $filter('i18n');
      scope.allItems = angular.copy(allItems);
      scope.checkedItems = angular.copy(checkedItems);
      scope.options = options;
      scope.filters = filters;
      scope.collapseType = collapseType;
      scope.trackBy = trackBy;
      scope.confirmationProps = confirmationProps;

      if (filterTypes) scope.filterTypes = filterTypes;

      return $modal.open({
        scope,
        openedClass: 'isEdit',
        windowClass: 'modal-select manage-modal modal_multilist',
        templateUrl: 'app/core/views/listModal.html',

        controller($scope, $modalInstance, conversionFactory) {
          if ($scope.options.localization) {
            $scope.searchItems = {};
            $scope.allItems.forEach(function(item) {
              const search = item[options.searchParam] || item;
              $scope.searchItems[search] = i18n(`DAConstants.${search}`);
            });
          }

          if ($scope.filterTypes) {
            $scope.typeFilter = {
              type: $scope.filterTypes[0]
            };
          }

          const initChekedObjItems = function(items) {
            if (typeof items[0] === 'object') {
              items.forEach(function(item) {
                if (~$scope.checkedItems.indexOf(item[$scope.trackBy])) {
                  // eslint-disable-next-line no-param-reassign
                  item.checked = true;
                }
              });
            }
          };

          // eslint-disable-next-line no-multi-assign
          const state = ($scope.state = {
            selectedPreset: null,
            currentPage: 1,
            itemsPerPage: 20,
            itemsOnPageValues: [20, 40, 60, 80],
            searchQuery: '',
            filteredItems: getFilteredItems($scope.allItems, '')
          });

          // eslint-disable-next-line no-multi-assign
          const selectInfo = ($scope.selectInfo = {
            pageSelected: false,
            allSelected: false,
            selectedItems: []
          });

          function getFilteredItems(items, searchQuery) {
            // eslint-disable-next-line no-param-reassign
            items = filterBySearchQuery(items, searchQuery);
            initChekedObjItems(items);
            return items;
          }

          function searchQueryFilterFn(searchString, item) {
            if (!searchString) return true;
            const param = item[options.searchParam] || item;
            const searchItem = $scope.options.localization
              ? $scope.searchItems[param]
              : param;
            return ~searchItem
              .toLowerCase()
              .indexOf(searchString.toLowerCase());
          }

          function filterBySearchQuery(items, searchQuery) {
            return items.filter(searchQueryFilterFn.bind(null, searchQuery));
          }

          const getCheckedItems = function(items) {
            if (typeof state.filteredItems[0] === 'object') {
              return items.filter(function(item) {
                return item.checked;
              });
            }
            return items.filter(function(item) {
              return ~$scope.checkedItems.indexOf(item);
            });
          };

          const onFiltersChanged = function(filter) {
            if (!filters) return [];
            // eslint-disable-next-line no-param-reassign
            filter = filter.filter(function(item) {
              return item.checked;
            });
            let itemsForCheck = [];
            filter.forEach(function(item) {
              itemsForCheck = itemsForCheck.concat(item[$scope.collapseType]);
            });
            return window._.uniq(itemsForCheck);
          };

          const isAllChecked = function(values) {
            if (!values.length) return false;
            if (typeof values[0] === 'object') {
              return values.every(function(item) {
                return item.checked;
              });
            }
            return values.every(function(item) {
              return !!~$scope.checkedItems.indexOf(item);
            });
          };

          // eslint-disable-next-line no-multi-assign
          const handlers = ($scope.handlers = {
            onSearchQueryChange(searchQuery) {
              state.filteredItems = getFilteredItems(
                $scope.allItems,
                searchQuery
              );
            },
            getPage(items, ipp, page) {
              state.itemsPage = getPage(items, ipp, page);
            },
            selectCheckbox() {
              if (selectInfo.allSelected) {
                handlers.changeItemsState(state.filteredItems, false);
              } else {
                selectInfo.pageSelected
                  ? handlers.changeItemsState(state.itemsPage, false)
                  : handlers.changeItemsState(state.itemsPage, true);
              }
            },
            selectPage() {
              handlers.changeItemsState(state.filteredItems, false);
              handlers.changeItemsState(state.itemsPage, true);
            },
            changeItemsState(values, bool) {
              if (typeof values[0] === 'object') {
                values.forEach(function(item) {
                  // eslint-disable-next-line no-param-reassign
                  item.checked = bool;
                });
              } else {
                values.forEach(function(item) {
                  const index = $scope.checkedItems.indexOf(item);
                  if (bool === true && !~index) {
                    $scope.checkedItems.push(item);
                  } else if (bool === false && index >= 0) {
                    $scope.checkedItems.splice(index, 1);
                  }
                });
              }

              handlers.updateSelectInfo();
            },
            triggerItemCheck(item) {
              if (typeof item === 'object') {
                // eslint-disable-next-line no-param-reassign
                item.checked = !item.checked;
              } else {
                const index = $scope.checkedItems.indexOf(item);
                if (!~index) {
                  $scope.checkedItems.push(item);
                } else {
                  $scope.checkedItems.splice(index, 1);
                }
              }
            },
            updateSelectInfo(item) {
              if (item) {
                handlers.triggerItemCheck(item);
              }
              selectInfo.allSelected = isAllChecked(state.filteredItems);
              selectInfo.pageSelected = selectInfo.allSelected
                ? true
                : isAllChecked(state.itemsPage);
              selectInfo.selectedItems = getCheckedItems(state.filteredItems);
            },
            getIsChecked(item) {
              return typeof item === 'object'
                ? item.checked
                : !!~$scope.checkedItems.indexOf(item);
            },

            onFilteredItemsChange(items) {
              handlers.getPage(
                items,
                state.itemsPerPage,
                (state.currentPage = 1)
              );
              handlers.updateSelectInfo();
            },
            onValuesSelected(values) {
              if (!values) return;
              const itemsForCheck = onFiltersChanged(values);
              $scope.allItems.forEach(function(item) {
                // eslint-disable-next-line no-param-reassign
                if (itemsForCheck.indexOf(item.id) !== -1) item.checked = true;
              });
              handlers.updateSelectInfo();
            },
            filterByType() {
              state.filteredItems = getFilteredItems(
                $scope.allItems,
                state.searchQuery
              );
            }
          });

          $scope.save = function() {
            if ($scope.confirmationProps) {
              cancelConfirmModal($scope.confirmationProps.text).then(
                function() {
                  $scope.submitted = true;
                  $modalInstance.close(
                    $scope.trackBy ? $scope.allItems : $scope.checkedItems
                  );
                }
              );
            } else {
              $scope.submitted = true;
              $modalInstance.close(
                $scope.trackBy ? $scope.allItems : $scope.checkedItems
              );
            }
          };

          $scope.cancel = function() {
            $scope.submitted = false;
            $modalInstance.dismiss();
          };

          $scope.capitalize = function(title) {
            return conversionFactory.capitalize(title);
          };

          $scope.$watch('state.filteredItems', handlers.onFilteredItemsChange);
          $scope.$watch('state.itemsPage', handlers.updateSelectInfo);
        }
      });
    };

    const addStringModal = function addStringModal(
      state,
      labels,
      pattern,
      initialString
    ) {
      const scope = $rootScope.$new(true);
      scope.state = state;
      scope.forms = {};
      scope.labels = labels;
      scope.pattern = pattern;

      if (initialString) {
        scope.state.newStr = {
          name: angular.copy(initialString)
        };
      }

      return $modal.open({
        scope,
        openedClass: 'isEdit',
        windowClass: 'add-string_modal',
        templateUrl: 'app/core/views/stringModal.html',
        controller($scope, $modalInstance) {
          $scope.submit = function() {
            $modalInstance.close($scope.state.newStr);
          };
        }
      });
    };

    const addTimeframeModal = function addTimeframeModal(
      state,
      handlers,
      labels
    ) {
      const scope = $rootScope.$new(true);
      scope.state = state;
      scope.handlers = handlers;
      scope.labels = labels;

      return $modal.open({
        scope,
        openedClass: 'isEdit',
        windowClass: 'add-string_modal',
        templateUrl: 'app/core/views/timeframeModal.html',
        controller($scope, $modalInstance) {
          $scope.state.startPeriod = window
            .moment($scope.state.startPeriod)
            .valueOf();
          $scope.state.submitted = false;
          $scope.state.currentDay = angular.copy($scope.state.startPeriod);
          $scope.forms = {};
          $scope.submit = function() {
            if (!$scope.state.isSingleDate) {
              const timestamp = getPeriodTimestamps([
                $scope.state.startPeriod,
                $scope.state.endPeriod
              ]);
              $modalInstance.close({
                start: timestamp[0],
                end: timestamp[1]
              });
            } else {
              const timestamp = getPeriodTimestamps([$scope.state.startPeriod]);
              $modalInstance.close(timestamp[0]);
            }
          };
        }
      });
    };

    const infoModal = function infoModal(labels, className, messageParams) {
      const i18n = $filter('i18n');
      const scope = $rootScope.$new();
      scope.title = i18n(labels.title);
      scope.msg = i18n(labels.message);
      if (messageParams) {
        scope.msg = interpolateAndWrapString(
          i18nFactory.translate(labels.message),
          messageParams
        );
      }
      return $modal.open({
        templateUrl: 'app/core/views/infoModalGlobal.html',
        windowClass: `wcm-msg ${className || ''}`,
        scope
      });
    };

    const toggleArrayElement = function(ar, item) {
      const index = ar.indexOf(item);
      !~index ? ar.push(item) : ar.splice(index, 1);
    };

    const checkBrowser = function() {
      let brwsr = '';
      const c = navigator.userAgent.search('Chrome');
      const s = navigator.userAgent.search('Safari');
      const f = navigator.userAgent.search('Firefox');
      const m8 = navigator.userAgent.search('MSIE 8.0');
      const m9 = navigator.userAgent.search('MSIE 9.0');
      if (c > -1) {
        brwsr = 'Chrome';
      } else if (s > -1) {
        brwsr = 'Safari';
      } else if (f > -1) {
        brwsr = 'Firefox';
      } else if (m9 > -1) {
        brwsr = 'IE_9_0';
      } else if (m8 > -1) {
        brwsr = 'IE_8_0';
      }
      return brwsr;
    };

    const noServerInfoModal = function(status) {
      if (isDataNotAvailable(status) === 'noServerAvailable') {
        const labels = {
          title: 'admin.modals.infoMsgTitle',
          message: 'analytics.labels.noServerAvailable'
        };
        infoModal(labels);
      }
    };

    const redirectToErrorPage = function() {
      window.location = `${window.location.origin}/50x.html`;
    };

    const redirectTo404Page = function() {
      window.location = `${window.location.origin}/404.html`;
    };
    /**
     * Returns filters for getList requests
     * @param page
     * @param ipp
     * @param sortOrder
     * @returns {{filterOffset: number, filterItemsNumber: *, sortOrder: *}}
     */
    const getPaginationFilters = function getPaginationFilters(
      page,
      ipp,
      sortOrder
    ) {
      return {
        filterOffset: (page - 1) * ipp,
        filterItemsNumber: ipp,
        sortOrder
      };
    };

    /**
     * Fills given list until it reaches proper length with empty value
     * @param list
     * @param length
     * @param emptyValue
     * @returns {Array.<T>|string}
     */
    const fillListToLength = function fillListToLength(
      list,
      length,
      emptyValue
    ) {
      if (!angular.isArray(list)) {
        throw new Error(`list should be an array, got ${typeof list} instead`);
      }
      const emptyArray = [];

      for (let i = 0; i < length - list.length; i++) {
        emptyArray[i] = emptyValue;
      }
      return list.concat(emptyArray);
    };

    const getIconClass = function(type) {
      return `__${type.toLowerCase()}`;
    };

    const getOSIcon = function(type) {
      let icon = getIconClass(type);
      switch (icon) {
        case '__ios':
        case '__mac_os_x':
          icon = '__mac';
          break;
        case '__windows_phone':
          icon = '__windows';
          break;
        case '__unknown':
          icon = '__other';
          break;
        default:
          break;
      }
      return `${icon} __scaleOSIcon`;
    };

    /**
     * Returns raw text length from html markup
     *
     * @param {string} htmlString - HTML string
     * @returns {integer} - String length
     */
    const getRawTextLength = function getRawTextLength(htmlString) {
      const div = window.$('<div />');
      div.html(htmlString);
      return div.text().length;
    };

    /**
     * Preloads image by its src
     *
     * @param src
     * @returns {*}
     */
    function preloadImage(src) {
      const dfd = $q.defer();
      const img = document.createElement('img');
      img.src = src;
      img.onload = dfd.resolve;
      img.onerror = dfd.reject;
      return dfd.promise;
    }

    /**
     * Async filters the array. Filter function should resolve with truthy or falsy value
     *
     * @param array
     * @param filter
     * @returns {*}
     */
    function filterAsync(array, filter) {
      return $q
        .all(
          array.map(function(entry) {
            return filter(entry);
          })
        )
        .then(function(bits) {
          return array.filter(function() {
            return bits.shift();
          });
        });
    }

    /* eslint-disable */
    /**
     * Shallowly copies arrays or objects
     * @param obj
     * @returns {*}
     */
    function shallowCopy(obj) {
      if (!obj || typeof obj !== 'object') return obj;

      let copy;

      if (window._.isArray(obj)) {
        const len = obj.length;
        copy = Array(len);
        for (var i = 0; i < len; i++) {
          copy[i] = shallowCopy(obj[i]);
        }
      } else {
        const keys = Object.keys(obj);
        copy = {};

        for (var i = 0, l = keys.length; i < l; i++) {
          const key = keys[i];
          copy[key] = obj[key];
        }
      }
      return copy;
    }
    /* eslint-enable */

    /**
     * Returns value of nested property of the object by given path
     * @param obj
     * @param path
     * @returns {*}
     */
    function getProp(obj, path) {
      const paths = path.split('.');
      let current = obj;
      let i;

      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
          return undefined;
        }
        current = current[paths[i]];
      }
      return current;
    }

    // Fill list with empty strings
    const fillListWithEmptyStrings = function(data, ipp) {
      if (data.length && data.length !== ipp) {
        // eslint-disable-next-line no-param-reassign
        data = data.concat(new Array(ipp - data.length));
      }
      return data;
    };

    const timestampToDuration = function(duration) {
      const tempTime = window.moment.duration(duration);
      return {
        seconds: tempTime.seconds(),
        minutes: tempTime.minutes(),
        hours: tempTime.hours(),
        days: tempTime.days(),
        months: tempTime.months(),
        years: tempTime.years()
      };
    };

    const toCamelCase = str =>
      str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
          return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, '');

    return {
      readFileAsDataURL,
      confirmationModal,
      cancelConfirmModal,
      errorModal,
      addStringModal,
      addTimeframeModal,
      noServerInfoModal,
      infoModal,
      htmlEscape,
      getVersionInfo,
      searchInProps,
      searchByString,
      unwatches,
      mapCampaignRequest,
      makeFilter,
      getPage,
      equals,
      cbAfter,
      getTruthyKeys,
      promiseQueue,
      watcherCounter,

      listModal,
      filterBy,
      toggleArrayElement,
      getPeriodTimestamps,
      validationPatterns,

      isServerNotAvailable,
      isDataNotAvailable,

      checkBrowser,
      redirectToErrorPage,
      redirectTo404Page,
      getPaginationFilters,
      fillListToLength,
      getIconClass,
      getOSIcon,
      getRawTextLength,
      preloadImage,
      filterAsync,
      shallowCopy,
      getProp,

      fillListWithEmptyStrings,
      timestampToDuration,
      toCamelCase
    };
  });
