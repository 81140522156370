/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').component('widgetEditorMenuItem', {
  templateUrl: 'app/sections/widgets/editor/common/widgetEditorMenuItem.html',
  controller: widgetEditorMenuItemController,
  transclude: true,
  require: {
    menu: '^^widgetEditorMenu',
    parent: '?^^widgetEditorMenuItem'
  },
  bindings: {
    fixedBgColor: '<',
    title: '@',
    id: '@',
    errorMsg: '='
  }
});

function widgetEditorMenuItemController() {
  const ctrl = this;

  ctrl.isActive = function() {
    return (
      ctrl.menu.selectedItem &&
      ctrl.menu.selectedItem.lastIndexOf(ctrl.fullPath) === 0
    );
  };

  ctrl.$onInit = function() {
    ctrl.fullPath = ctrl.parent
      ? `${ctrl.parent.fullPath}.${ctrl.id}`
      : ctrl.id;
  };
}
