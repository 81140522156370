/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const { moment } = window;

angular
  .module('wcm.admin')
  .controller('monitoringCollectionListCtrl', function(
    $scope,
    $state,
    $location,
    $q,
    $timeout,
    $modal,
    $filter,
    breadcrumbs,
    navigationPanel,
    monitoringCollectionFactory,
    helpers
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isBusy: true,
      noDataAvailable: false,
      currentPage: 1,
      itemsTotal: 0,
      itemsPerPage: 10,
      itemsOnPageValues: [10, 25, 50, 100],
      collectionActions: ['merge', 'archive', 'unarchive', 'delete'],
      selectedCollections: [],
      collections: [],
      filteredItems: [],
      sortParam: 'creationDate',
      sortOrder: -1,
      notifyDisabled: 0,
      filters: {
        startPeriod: moment()
          .startOf('day')
          .subtract(30, 'day'),
        endPeriod: moment().startOf('day'),
        searchString: '',
        collectionType: '',
        collectionStatus: 'Active'
      }
    });

    let debounceGetList;

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      status: ['Active', 'Archive'],
      minPeriod: moment($scope.currentUser.systemCreationDate).startOf('day'),
      availableMinDate: $scope.currentUser.systemCreationDate,
      analyticsPeriodLimit: $scope.currentUser.analyticsPeriodLimit
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getPage(items, ipp, page) {
        state.filteredItems = helpers.getPage(items, ipp, page);
      },
      onFiltersChange(state) {
        $timeout.cancel(debounceGetList);
        debounceGetList = $timeout(function() {
          if (!isValidTimeFilters(state.filters)) return false;
          // eslint-disable-next-line no-param-reassign
          state.noDataAvailable = false;
          // eslint-disable-next-line no-param-reassign
          state.isBusy = true;
          return (
            monitoringCollectionFactory
              .collectionGetList(state.filters)
              .then(
                function(data) {
                  // eslint-disable-next-line no-param-reassign
                  state.itemsTotal = data.collections.length;
                  // eslint-disable-next-line no-param-reassign
                  state.collections = handlers.sortData(
                    data.collections,
                    state.sortOrder
                  );
                  $timeout(function() {
                    handlers.getPage(
                      state.collections,
                      state.itemsPerPage,
                      state.currentPage
                    );
                  });
                },
                function(res) {
                  // eslint-disable-next-line no-param-reassign
                  state.noDataAvailable = helpers.isDataNotAvailable(
                    res.status
                  );
                }
              )
              // eslint-disable-next-line no-param-reassign
              .finally(function() {
                state.isBusy = false;
              })
          );
        }, 250);
      },
      actionPerform(collection, action, multi) {
        const actionLabel = multi ? `multi${action}` : action;
        helpers
          .confirmationModal(
            `admin.monitoring.collections.titles.${actionLabel}`,
            `admin.monitoring.collections.messages.${actionLabel}`,
            collection ? collection.name : true
          )
          .result.then(function() {
            state.isBusy = true;
            monitoringCollectionFactory
              .collectionsPerformAction(
                multi ? state.selectedCollections : [collection.id],
                action
              )
              .then(
                function() {
                  return true;
                },
                function(res) {
                  $scope.showErrorModal(res.status);
                }
              )
              .finally(init);
          });
      },
      mergePerform() {
        const scope = $scope.$new();
        $modal
          .open({
            templateUrl:
              'app/admin/monitoring/collections/collectionsMergeModal.html',
            windowClass: 'add-string_modal',
            controller($scope, $modalInstance) {
              $scope.forms = {};
              $scope.state = {
                collectionName: '',
                isArchive: false,
                isSubmitted: false
              };

              $scope.submit = function() {
                state.isBusy = true;

                monitoringCollectionFactory
                  .collectionsPerformAction(
                    state.selectedCollections,
                    'merge',
                    {
                      collectionNameNew: $scope.state.collectionName,
                      isArchive: $scope.state.isArchive
                    }
                  )
                  .then(
                    function() {
                      $modalInstance.close();
                    },
                    function(res) {
                      if (res.status === 409) {
                        const labels = {
                          title:
                            'admin.monitoring.collections.titles.collectionNameConflict',
                          message:
                            'admin.monitoring.collections.messages.collectionNameConflict'
                        };
                        helpers.infoModal(labels).result.finally(function() {
                          $scope.isBusy = false;
                        });
                      } else if ($scope.showErrorModal(res.status)) {
                        $modalInstance.close();
                      }
                    }
                  );
              };
            },
            scope
          })
          .result.finally(init);
      },
      notifyPerform(collection) {
        const scopeList = $scope.$new();
        scopeList.notifications = angular.copy(collection.notifications);

        $modal
          .open({
            templateUrl:
              'app/admin/monitoring/collections/collectionsNotifyListModal.html',
            windowClass: 'add-string_modal',
            controller($scope, $modalInstance) {
              $scope.state = {
                submitted: false
              };
              $scope.forms = {};
              $scope.add = function() {
                const scopeAdd = $scope.$new();
                scopeAdd.currentNotifications = $scope.notifications;
                $modal
                  .open({
                    templateUrl:
                      'app/admin/monitoring/collections/collectionsNotifyAddModal.html',
                    windowClass: 'add-string_modal',
                    controller($scope, $modalInstance) {
                      $scope.state = {
                        submitted: false
                      };
                      $scope.forms = {};
                      $scope.state.notification = '';
                      $scope.state.notifyType = 'email';
                      $scope.submit = function() {
                        $modalInstance.close($scope.state.notification);
                      };
                    },
                    scope: scopeAdd
                  })
                  .result.then(function(str) {
                    $scope.notifications = window._.union(
                      $scope.notifications,
                      [str]
                    );
                  });
              };

              $scope.getNotificationType = function(value) {
                // eslint-disable-next-line no-useless-escape
                const emailRegex = /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                return emailRegex.test(value) ? 'email' : 'phone';
              };

              $scope.removeNotify = function(index) {
                const text = {
                  title: 'admin.monitoring.collections.titles.deleteNotify',
                  message: 'admin.monitoring.collections.messages.deleteNotify',
                  param: $scope.notifications[index]
                };
                cancelConfirmModal(text).then(function() {
                  $scope.notifications.splice(index, 1);
                });
              };

              $scope.submit = function() {
                $modalInstance.close($scope.notifications);
              };
            },
            scope: scopeList
          })
          .result.then(function(notifications) {
            state.notifyDisabled = collection.id;
            monitoringCollectionFactory
              .collectionAddNotifications({
                collectionId: collection.id,
                notifications
              })
              .then(
                function() {
                  // eslint-disable-next-line no-param-reassign
                  collection.notifications = notifications;
                },
                function(res) {
                  $scope.showErrorModal(res.status);
                }
              )
              .finally(function() {
                state.notifyDisabled = 0;
                init();
              });
          });
      },
      setSortOrder() {
        state.sortOrder = state.sortOrder === 1 ? -1 : 1;
        handlers.getPage(
          handlers.sortData(state.collections, state.sortOrder),
          state.itemsPerPage,
          state.currentPage
        );
      },
      sortData(data, sort) {
        return data.sort(function(a, b) {
          return a[state.sortParam] === b[state.sortParam]
            ? 0
            : +(a[state.sortParam] > b[state.sortParam]) * sort || -1 * sort;
        });
      },
      getSortOrder() {
        return state.sortOrder === 1;
      },
      selectCollection(id) {
        helpers.toggleArrayElement(state.selectedCollections, id);
      },
      selectAll() {
        state.selectedCollections = state.collections.map(function(item) {
          return item.id;
        });
      },
      selectPage() {
        const dataToAdd = state.filteredItems
          .map(function(item) {
            return !~state.selectedCollections.indexOf(item.id)
              ? item.id
              : null;
          })
          .filter(function(item) {
            return item;
          });
        state.selectedCollections = state.selectedCollections.concat(dataToAdd);
      },
      selectOnlyCurrentPage() {
        state.selectedCollections = state.filteredItems.map(i => i.id);
      },
      unselectAll() {
        state.selectedCollections = [];
      },
      isCurrentPageSelected() {
        const { selectedCollections, filteredItems } = state;
        return (
          filteredItems.length &&
          filteredItems.every(
            collection => !!~selectedCollections.indexOf(collection.id)
          )
        );
      },
      getSelectedCollectionsCount() {
        const { selectedCollections } = state;
        return selectedCollections.length;
      },
      areAllCollectionsSelected() {
        const { selectedCollections, itemsTotal } = state;
        return itemsTotal > 0 && selectedCollections.length === itemsTotal;
      },
      handleSelectAllCheckbox() {
        // if all set is selected -> uncheck all set
        if (handlers.areAllCollectionsSelected()) {
          handlers.unselectAll();
        } else if (handlers.isCurrentPageSelected()) {
          // if all page is selected -> uncheck current page
          const { filteredItems } = state;
          state.selectedCollections = window._.difference(
            state.selectedCollections,
            filteredItems.map(i => i.id)
          );
        } else {
          // if neither all set nor all page is selected -> select currentPage
          handlers.selectPage();
        }
      }
    });

    function init() {
      state.noDataAvailable = false;

      state.filters.collectionType = $location
        .path()
        .split('/')
        .pop();
      state.selectedCollections = [];

      breadcrumbs.set({
        path: 'monitoring>collectionsList'.split('>').map(name => ({ name }))
      });
      navigationPanel.setTabs([
        {
          name: 'filteredCollections',
          sref: 'monitoring.collections.filteredList'
        },
        {
          name: 'manualCollections',
          sref: 'monitoring.collections.manualList'
        }
      ]);

      handlers.onFiltersChange(state);
    }

    init();

    $scope.$on('$stateChangeStart', function() {
      $timeout.cancel(debounceGetList);
    });

    // Helpers >>>
    function cancelConfirmModal(text) {
      let i18n;
      if (!text.param) {
        i18n = $filter('i18n');
        // eslint-disable-next-line no-param-reassign
        text.title = i18n(text.title);
        // eslint-disable-next-line no-param-reassign
        text.message = i18n(text.message);
      }
      return helpers.confirmationModal(text.title, text.message, text.param)
        .result;
    }
    function isValidTimeFilters(timeFilters) {
      const minDate = moment(options.availableMinDate).startOf('day');
      return (
        timeFilters.startPeriod <= timeFilters.endPeriod &&
        timeFilters.startPeriod >= minDate &&
        timeFilters.endPeriod >= minDate
      );
    }
    // / Helpers <<<

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => $scope.state.filters.collectionType === 'filtered',
        clickHandler: () => {
          $state.go('monitoring.collections.create', {
            type: 'filtered',
            id: 'new'
          });
        }
      }
    ]);
  });
