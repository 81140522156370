/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.errorHandling')
  .factory('errorHandlingInterceptor', function(
    $q,
    $injector,
    $rootScope,
    ERROR_HANDLING_CONFIG
  ) {
    const errorModals = []; // array contains requests for which error modals are currently opened
    const errorCounters = {
      analytics: 0,
      reporting: 0,
      monitoring: 0
    };

    const responseError = function responseError(rejection) {
      let serverMessage;
      const skipErrors = angular.copy(ERROR_HANDLING_CONFIG.skipErrors);
      if (
        rejection.config.customConfig &&
        rejection.config.customConfig.skipErrors
      ) {
        angular.extend(skipErrors, rejection.config.customConfig.skipErrors);
      }

      const stateParams = $injector.get('$stateParams');
      const isRejectByCounters =
        !stateParams.debug &&
        (errorCounters.analytics > 0 ||
          errorCounters.reporting > 0 ||
          errorCounters.monitoring > 0);

      if (
        ~skipErrors.indexOf(rejection.status) ||
        ~errorModals.indexOf(rejection.config.url) ||
        isRejectByCounters
      ) {
        return $q.reject(rejection);
      }

      if (rejection.status === 403) {
        return window.location.replace(ERROR_HANDLING_CONFIG.errorPageUrl);
      }

      // >>>> Debug for BE <<<<
      if (rejection.status === 544) {
        serverMessage = customErrorMessageWithRejectionData(
          rejection,
          'ADM_0544'
        );
      }
      // ///

      const i18n = $injector.get('i18nFactory');
      const modal = $injector.get('$modal');
      const scope = $rootScope.$new();

      try {
        serverMessage = serverMessage ||
          rejection.data.serverMessage || { key: '', params: [] };
      } catch (e) {
        serverMessage = { key: '', params: [] };
      }
      i18n
        .translateResponse(serverMessage.key, serverMessage.params)
        .then(function(message) {
          scope.message =
            typeof serverMessage === 'string' ? serverMessage : message;
        });

      // add url from rejected request to prevent multiple modals to be opened for
      // the same rejection
      errorModals.push(rejection.config.url);

      modal
        .open({
          templateUrl: 'app/core/views/errorModal.html',
          windowClass: 'error-modal',
          scope
        })
        .result.finally(function() {
          errorModals.splice(errorModals.indexOf(rejection.config.url), 1);
        });

      return $q.reject(rejection);
    };

    function customErrorMessageWithRejectionData(rejection, key) {
      console.log(`>>>>>> Response data on ${rejection.status} <<<<<<`);
      console.log(rejection.data);
      console.log('----------------------------------');
      return { key, params: [] };
    }

    return {
      responseError,
      customErrorMessageWithRejectionData,
      errorCounters
    };
  });
