/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.core')
  .factory('modificationHistory', function(
    requestFactory,
    MODIFICATION_HISTORY_CONFIG,
    $sce,
    $filter,
    localStorage
  ) {
    const config = MODIFICATION_HISTORY_CONFIG;

    const serverOffset = getServerOffset();

    const get = function(type, id, extraParams) {
      return requestFactory
        .post(
          config.servlet,
          config.action,
          window._.extend(
            {},
            {
              objectType: type,
              objectId: id
            },
            extraParams
          )
        )
        .then(function(res) {
          return res.data && res.data.items ? res.data.items.reverse() : [];
        })
        .then(function(items) {
          return mapResponseToViewModel(items);
        });
    };

    function mapResponseToViewModel(items) {
      return items.map(function(item) {
        const res = {
          author: item.userId,
          date: item.timestamp,
          actions: divideActions(item.details)
        };

        return res;
      });
    }

    function divideActions(actions) {
      const res = {
        added: [],
        changed: [],
        removed: []
      };

      return actions.reduce(function(acc, action) {
        const normAction = normalizeAction(action);

        if (
          isTruthyValue(normAction.newValue) &&
          isTruthyValue(normAction.oldValue)
        ) {
          acc.changed.push(formatAction(normAction));
        }
        if (
          isTruthyValue(normAction.newValue) &&
          !isTruthyValue(normAction.oldValue)
        ) {
          acc.added.push(formatAction(normAction));
        }
        if (
          !isTruthyValue(normAction.newValue) &&
          isTruthyValue(normAction.oldValue)
        ) {
          acc.removed.push(formatAction(normAction));
        }
        return acc;
      }, res);
    }

    function normalizeAction(action) {
      return {
        field: action.fieldName,
        newValue: parseValue(action.newValue),
        oldValue: parseValue(action.oldValue)
      };
    }

    function formatAction(action) {
      return window._.extend({}, action, {
        field: formatField(action.field),
        newValue: $sce.trustAsHtml(formatValue(action.newValue).toString()),
        oldValue: $sce.trustAsHtml(formatValue(action.oldValue).toString())
      });
    }

    function parseValue(value) {
      let val;
      // backend stores data inconsistently (as json strings or as objects/arrays)
      // so we try to parse string first
      try {
        val = JSON.parse(value);
      } catch (ex) {
        val = value;
      }

      return val;
    }

    // e.g. Fri Jul 07 15:12:13 GMT+03:00 2017
    const javaDateReg = /\w{3}\s\w{3}\s\d{2}\s\d\d:\d\d:\d\d\sGMT/gi;

    function formatValue(value) {
      if (typeof value === 'boolean') {
        return value ? 'on' : 'off';
      }

      if (value === null) {
        return '""';
      }

      // handle base64 images
      if (typeof value === 'string' && value.indexOf('data:image') === 0) {
        const img = document.createElement('img');
        img.style.maxWidth = '100%';
        img.style.height = 'auto';
        img.src = value;
        return img.outerHTML;
      }

      if (typeof value === 'string' && javaDateReg.test(value)) {
        return printDate(Date.parse(value));
      }

      if (angular.isObject(value) && !angular.isArray(value)) {
        return printObject(value);
      }

      if (angular.isArray(value)) {
        return value
          .map(function(item) {
            return formatValue(item);
          })
          .join(angular.isObject(value[0]) ? '<div><hr/></div>' : ', ');
      }

      if (isLikeATimestamp(value)) {
        return printDate(value);
      }

      return value;
    }

    function formatField(field) {
      try {
        if (field === 'img_00') {
          return 'background image';
        }

        if (field.indexOf('img_0') === 0) {
          return 'foreground image';
        }
      } catch (ex) {
        return field;
      }
      return field;
    }

    function printDate(timestamp) {
      const dateFilter = $filter('date');
      return `${dateFilter(
        timestamp,
        'dd.MM.yyyy HH:mm:ss'
      )} GMT ${serverOffset}`;
    }

    function printObject(obj) {
      return Object.keys(obj).reduce(function(acc, curr) {
        return `${acc}<div>${curr}: ${formatValue(obj[curr])}</div>`;
      }, '');
    }

    function isLikeATimestamp(value) {
      const int = parseInt(value, 10);
      if (!int) return false;
      const date = new Date(int);
      const year = date.getFullYear();
      return year >= 2000 && year <= 3000;
    }

    function getServerOffset() {
      const dateFilter = $filter('date');
      const serverOffset = parseInt(localStorage.get('timeZone'), 10);
      return (
        (serverOffset > 0 ? '+' : '-') +
        dateFilter(Math.abs(serverOffset), 'HH:mm', 'GMT')
      );
    }

    function isTruthyValue(value) {
      if (angular.isArray(value) && value.length === 0) {
        return false;
      }

      if (angular.isObject(value) && Object.keys(value).length === 0) {
        return false;
      }

      // for cases when value is 0 and thus becomes falsy
      if (angular.isNumber(value)) {
        return true;
      }

      return !!value;
    }

    return {
      get,
      getServerOffset,
      mapResponseToViewModel
    };
  });
