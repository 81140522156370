/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').component('widgetEditorMenu', {
  templateUrl: 'app/sections/widgets/editor/common/widgetEditorMenu.html',
  controller: widgetEditorMenuController,
  bindings: {
    selectedItem: '<',
    onItemSelected: '&'
  },
  transclude: true
});

function widgetEditorMenuController() {
  const ctrl = this;

  ctrl.close = function(event) {
    const selectedItemPath = ctrl.selectedItem.split('.');

    ctrl.onItemSelected({
      selectedItem: selectedItemPath.slice(0, -1).join('.')
    });
    event.stopPropagation();
  };

  ctrl.$onInit = function() {};
}
