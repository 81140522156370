/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.core')
  .directive('settingsBlockTabTable', function() {
    return {
      restrict: 'AE',
      scope: true,
      replace: true,
      transclude: true,
      templateUrl: 'app/core/views/settingsBlockTabTable.html',
      link(scope, el, attr, ctrl, transclude) {
        scope.tableName = attr.name;
        scope.enableAddBtn = !!attr.onAddBtnClick;

        const needScrollbar = attr.needScrollbar;
        scope.buildScrollbar = function() {
          return needScrollbar ? 'perfect-scrollbar' : '';
        };

        scope.getNumber = function(num) {
          return new Array(num);
        };

        transclude(scope, function(clone) {
          el.find('[tab-table-content-transclude]').append(clone);
        });

        el.find('.add-more-btn').on('click', function() {
          scope.$eval(attr.onAddBtnClick);
        });
      }
    };
  });
