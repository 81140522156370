/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.login')
  .controller('signInCtrl', function(
    $scope,
    $state,
    $interval,
    $location,
    auth,
    APP_CONFIG
  ) {
    let intervalPromise;

    const stopTimeout = function() {
      if (angular.isDefined(intervalPromise)) {
        $interval.cancel(intervalPromise);
        intervalPromise = undefined;
      }
    };

    const processTimeoutValidation = function(timeout) {
      stopTimeout();
      $scope.timeout = timeout;
      $scope.loginForm.password.$setValidity('timeoutValidation', false);
      $scope.loginForm.login.$setValidity('timeoutValidation', false);
      intervalPromise = $interval(function() {
        const newTimeout = $scope.timeout - 1000;
        if (newTimeout > 0) {
          $scope.timeout = newTimeout;
        } else {
          stopTimeout();
          $scope.loginForm.password.$setValidity('timeoutValidation', true);
          $scope.loginForm.login.$setValidity('timeoutValidation', true);
        }
      }, 1000);
    };

    const clearForm = () => {
      $scope.loginForm.$setValidity();
      $scope.loginForm.$setPristine();
      $scope.loginForm.$setUntouched();
      $scope.loginForm.password.$setValidity('timeoutValidation', true);
      $scope.loginForm.login.$setValidity('timeoutValidation', true);
      $scope.loginForm.password.$setValidity('serverValidation', true);
      $scope.loginForm.login.$setValidity('serverValidation', true);
    };

    $scope.$watchCollection(
      'credentials',
      () => {
        clearForm();
      },
      true
    );

    $scope.login = function login(credentials) {
      $scope.loginForm.$setSubmitted();
      if (!credentials.userId || !credentials.password) {
        return;
      }
      auth
        .login(credentials)
        .then(function() {
          const redirect = $state.params.redirect;
          return redirect
            ? $location.url(redirect)
            : $state.go(APP_CONFIG.defaultState);
        })
        .catch(function(res) {
          $scope.loginForm.password.$setValidity('timeoutValidation', true);
          $scope.loginForm.password.$setValidity('serverValidation', true);
          $scope.loginForm.login.$setValidity('timeoutValidation', true);
          $scope.loginForm.login.$setValidity('serverValidation', true);
          if (res.status === 495) {
            processTimeoutValidation(res.data.serverMessage);
          } else {
            $scope.loginForm.password.$setValidity('serverValidation', false);
            $scope.loginForm.login.$setValidity('serverValidation', false);
          }
        });
    };
  });
