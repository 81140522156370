const { moment, angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateSiteVisitorsCtrl', function(
    $scope,
    $state,
    $controller,
    analyticsRequestsFactory,
    analyticsFactory2,
    sitesFactory,
    REPORTING_CONFIG,
    navigationPanel,
    ANALYTICS_CONFIG2
  ) {
    const A_CONFIG = ANALYTICS_CONFIG2;
    const state = $scope.state;
    const options = $scope.options;
    let handlers = $scope.handlers;

    angular.extend($scope.options, {
      blocks: A_CONFIG.blocks.visitors
    });

    angular.extend($scope.state, {
      siteId: 0
    });

    // eslint-disable-next-line no-multi-assign
    handlers = $scope.handlers = angular.extend({}, handlers, {
      save() {
        $scope.$parent.handlers.save(state);
      },
      getAvailableValues() {
        return $scope.$parent.handlers.getAvailableValues().then(function() {
          state.sortType = 'last_visit';
          state.sortDirection = 'desc';
          state.isBusy = false;
        });
      },
      getSiteData() {
        return sitesFactory.getData(state.siteId);
      },
      onSelectSite() {
        return handlers.getSiteData().then(function(site) {
          const start = site
            ? moment(site.creationDate).startOf('day')
            : state.filters.endPeriod;
          handlers.setBeginPeriodDates(start);
        });
      }
    });

    $scope.init = function() {
      $scope.$parent.init();
      return analyticsRequestsFactory
        .getAllSites('sites', state.permissionType)
        .then(
          function(data) {
            options.items = data.sites;
            handlers.getAvailableValues();
          },
          function() {
            state.noWas = true;
          }
        );
    };
    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.generate',
        isDisabled: () => $scope.state.noWas || $scope.state.isBusy,
        isShown: () => true,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.reportForm.$valid) {
            $scope.handlers.save();
          }
        }
      },
      {
        title: () => 'reporting.buttons.cancel',
        isDisabled: () => $scope.state.isBusy,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);
  });
