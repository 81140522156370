import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'core/theme';
import { BreadcrumbsProvider } from 'ui/Breadcrumbs';
import { SystemInfoProvider } from 'core/systemInfo';
import { HashRouter as Router } from 'react-router-dom';

const Spinner = () => 'loading';

const Root = ({ children }) => {
  return (
    <ThemeProvider>
      <SystemInfoProvider>
        <BreadcrumbsProvider>
          <Router>
            <Suspense fallback={<Spinner />}>{children}</Suspense>
          </Router>
        </BreadcrumbsProvider>
      </SystemInfoProvider>
    </ThemeProvider>
  );
};

Root.propTypes = {
  children: PropTypes.node
};

export const withRoot = Component => props => {
  return (
    <Root>
      <Component {...props} />
    </Root>
  );
};
