/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('pieChart', function(analyticsFactory2, $timeout, $filter) {
    return {
      require: ['analyticsChart2', '^analyticsBlock2'],
      restrict: 'A',
      replace: true,
      scope: {
        chartId: '@',
        chartColorPattern: '@',
        chartData: '=?',
        chartIsOnlyPercents: '=',
        chartDataViewType: '@',
        isNeedLocalizeTitles: '@',
        isNeedHandleNoData: '@'
      },
      templateUrl: 'app/core/analytics2/chart.html',
      link(scope, el, attrs, ctrls) {
        const analyticsBlockCtrl = ctrls[1];
        const i18n = $filter('i18n');
        const FAKE_DATA_COLOR = '#ECEBF1';
        let currentChartData = {};

        const buildChart = function() {
          currentChartData = angular.copy(scope.chartData);

          const total = currentChartData.options.total;

          // set grey color to Other
          if (scope.chartId === 'pieChartBySites') {
            currentChartData.data.color = function(color, d) {
              return (d.id && d.id.match(/^\d+\.\sOther$/g)) ||
                (typeof d === 'string' && d.match(/^\d+\.\sOther$/g))
                ? FAKE_DATA_COLOR
                : color;
            };
          }

          scope.chart = window.c3.generate(
            angular.extend({}, analyticsFactory2.defaultChartConfig, {
              bindto: `#${scope.chartId}`,
              data: currentChartData.data,
              tooltip: {
                contents(d, defaultTitleFormat, defaultValueFormat, color) {
                  // Use default rendering with different title
                  const MARKER = 'DOT_REPLACEMENT';
                  const dotReplacedTranslate = i18n(
                    `DAConstants.${d[0].name.replace(/\./g, MARKER)}`,
                    true
                  );
                  // eslint-disable-next-line no-param-reassign
                  d[0].name = dotReplacedTranslate.replace(
                    new RegExp(MARKER, 'g'),
                    '.'
                  );
                  return this.getTooltipContent(
                    d,
                    defaultTitleFormat,
                    defaultValueFormat,
                    color
                  );
                },
                format: {
                  value(value, ratio, id) {
                    let secondValue;
                    if (scope.chartDataViewType !== 'alreadyPercents') {
                      secondValue = total > 0 ? value / total : 1;
                      secondValue = ` | ${window.d3.format('.2%')(
                        secondValue
                      )}`;
                    }
                    // eslint-disable-next-line no-nested-ternary
                    return (
                      (id === 'ctr' || scope.chartIsOnlyPercents
                        ? `${(value * 100).toFixed(2)}%`
                        : total > 0
                        ? value
                        : 0) + (secondValue || '')
                    );
                  }
                }
              },
              color: {
                pattern:
                  total > 0
                    ? analyticsFactory2.defaultColorPatterns[
                        scope.chartColorPattern || 0
                      ]
                    : [FAKE_DATA_COLOR]
              }
            })
          );
          analyticsBlockCtrl.registerChart(scope.chartId, scope.chart);
        };

        // Render chart
        const renderChart = function(val, old) {
          if (val) {
            if (old) {
              buildChart();
            } else {
              scope.$evalAsync(buildChart);
            }
          }
        };

        scope.$watchCollection('chartData.data.columns', renderChart);
        scope.$on(
          '$destroy',
          analyticsBlockCtrl.unregisterChart.bind(null, scope.chartId)
        );
      }
    };
  });
