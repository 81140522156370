/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

// scroll offset in pixels to treat element as visible before it actually is
const SCROLL_OFFSET = 50;

function isScrolledIntoView(el) {
  const boundingRect = el.getBoundingClientRect();
  return (
    boundingRect.bottom + SCROLL_OFFSET >= 0 &&
    boundingRect.top - SCROLL_OFFSET <= window.innerHeight
  );
}

angular
  .module('wcm.analytics2')
  .directive('analyticsPollBlock', function() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        pending: '=?',
        noDataAvailability: '=?',
        title: '@',
        onRemove: '&?',
        isRemovable: '@',
        onVisibilityChange: '&?',
        headerDisabled: '=',
        pollData: '=?'
      },
      templateUrl: 'app/core/analytics2/analyticsPollBlock.html',
      controller($scope) {
        const charts = {};
        this.registerChart = function(chartId, chartObj) {
          charts[chartId] = chartObj;
        };
        this.unregisterChart = function(chartId) {
          charts[chartId] = null;
        };
        this.getChartObj = function(chartId) {
          return charts[chartId];
        };

        $scope.removeQuery = function(index) {
          delete $scope.queriesData[index];
          if (
            $scope.queriesData.every(function(v) {
              return !v;
            })
          ) {
            $scope.onRemove();
          }
        };
      },
      link(scope, el) {
        el.attr('id', scope.title); // set id of block

        let pollDataAmount = 1;
        scope.queriesData = ['pending'];

        let lastVisibilityStatus = isScrolledIntoView(el[0]);
        scope.onVisibilityChange({ visibility: lastVisibilityStatus });

        const scrollHandler = window._.debounce(function scrollHandler() {
          const newVisibilityStatus = isScrolledIntoView(el[0]);
          if (newVisibilityStatus !== lastVisibilityStatus) {
            scope.onVisibilityChange({ visibility: newVisibilityStatus });
          }
          lastVisibilityStatus = newVisibilityStatus;
          return true;
        }, 200);

        const predefineQueryBlocks = function(val, old) {
          if ((val && val !== old) || (val && !scope.pending)) {
            pollDataAmount = val;
            scope.queriesData = angular.copy(scope.pollData);
          } else {
            scope.queriesData = [];
            for (let i = 0; i < pollDataAmount; i++) {
              scope.queriesData[i] = `pending_${i}`;
            }
          }
        };

        scope.$on('analytics:scroll', scrollHandler);
        scope.$on('$destroy', function() {
          scope.onVisibilityChange({ visibility: false });
        });
        scope.$watch('pollData.length', predefineQueryBlocks);
      }
    };
  });
