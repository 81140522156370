/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.http')
  .factory('requestFactory', function(
    $http,
    $rootScope,
    $log,
    conversionFactory,
    HTTP_CONFIG,
    APP_CONFIG
  ) {
    const isMockShouldBeUsed = function(servletName, action) {
      // check for global mock switch
      if (!APP_CONFIG.useBackendMocks) {
        return false;
      }
      // check for global pattern
      if (APP_CONFIG.requestsToMock === '*') {
        return true;
      }
      // check for specific servlets of servlet.action
      return (
        APP_CONFIG.requestsToMock.indexOf(servletName) > -1 ||
        APP_CONFIG.requestsToMock.indexOf(`${servletName}.${action}`) > -1
      );
    };

    const buildUrlString = function(servletName, action) {
      let url = '';
      // prepend url with mock server address if we're in "mock mode" and "servletsToMock"
      // value equals current servletName
      if (isMockShouldBeUsed(servletName, action)) {
        url += `${APP_CONFIG.mockServer}/`;
        $log.warn(
          `Request to "${servletName}" servlet with action "${action}" will be mocked`
        );
      } else {
        url += APP_CONFIG.apiBase;
      }
      url += `${servletName}?action=${action}`;
      return url;
    };

    const buildUrlStringForGet = function(servletName, stringParams) {
      let url = '';
      // prepend url with mock server address if we're in "mock mode" and "servletsToMock"
      // value equals current servletName
      if (isMockShouldBeUsed(servletName)) {
        url += `${APP_CONFIG.mockServer}/`;
        $log.warn(
          `Request to "${servletName}" servlet with string params "${stringParams}" will be mocked`
        );
      } else {
        url += APP_CONFIG.apiBase;
      }
      url += `${servletName}?${stringParams}`;
      return url;
    };

    const broadcastDataReceivedEvent = function() {
      $rootScope.$broadcast('requestFactory:dataReceived');
    };

    const get = function get(url, params, headers, config) {
      return $http({
        url: buildUrlStringForGet(url, params),
        method: 'GET',
        headers,
        customConfig: config
      });
    };

    const post = function post(
      servletName,
      action,
      requestData,
      headers = { 'Content-Type': 'application/json' },
      config
    ) {
      return $http({
        url: buildUrlString(servletName, action),
        method: 'POST',
        headers,
        data: requestData,
        customConfig: config
      })
        .success(broadcastDataReceivedEvent)
        .error(broadcastDataReceivedEvent);
    };

    return {
      get,
      post
    };
  });
