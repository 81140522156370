/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('generalInfoValue', function() {
    return {
      require: ['?analyticsChart2', '?^analyticsBlock2'],
      restrict: 'A',
      replace: true,
      transclude: true,
      scope: {
        key: '@',
        value: '=?',
        inPercentage: '@',
        toFixed: '=',
        affectedByFilters: '=',
        hasTooltip: '@',
        multiValue: '=?',
        tooltipKey: '@'
      },
      templateUrl(tElement, tAttr) {
        return angular.isDefined(tAttr.multiValue)
          ? 'app/core/analytics2/generalInfoMultiValue.html'
          : 'app/core/analytics2/generalInfoValue.html';
      },
      link(scope, el, attrs, ctrl, transclude) {
        scope.isNumber = function() {
          return typeof scope.value === 'number';
        };
        scope.isString = function() {
          return typeof scope.value === 'string';
        };
        scope.isFunction = function() {
          return typeof scope.value === 'function';
        };

        transclude(function(clone) {
          angular.forEach(clone, function(cloneEl) {
            if (cloneEl.attributes) {
              const destinationId = cloneEl.attributes['transclude-to'].value;
              const destination = el.find(
                `[transclude-from="${destinationId}"]`
              );
              if (destination.length) {
                destination.append(cloneEl);
              } else {
                cloneEl.remove();
              }
            }
          });
        });
      }
    };
  });
