/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;
angular
  .module('wcm.sites')
  .controller('siteVisitorsCtrl', function(
    $q,
    $scope,
    $state,
    $timeout,
    analyticsFactory2,
    siteVisitorsFactory,
    sharedRequestsFactory,
    navigationPanel,
    dropdown,
    helpers
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isBusy: true,
      siteId: $state.params.id,
      currentPage: 1,
      itemsPerPage: 10,
      noDataAvailable: false,
      itemsOnPageValues: [10, 25, 50, 100],
      sortOrder: 'last_visit:desc',
      exportType: 'visitorsSite',
      exportAnalytics: false,
      isSiteVisitors: true,
      terminateType: 'visitorsSite',
      filters: {
        startPeriod: moment()
          .startOf('day')
          .subtract(30, 'day'),
        endPeriod: moment().startOf('day'),
        startTimeFrame: 0,
        endTimeFrame: 24 * 60 * 60 * 1000,
        daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
        top: ''
      },
      visitors: []
    });

    let debounceGetList;

    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        state.isBusy = !!queue.length;
      }, 100);
    });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      analyticsPeriodLimit: $scope.currentUser.analyticsPeriodLimit,
      minPeriod: null,
      maxPeriod: null
    });

    const reinitMinMaxPeriod = function() {
      // set available Min Date
      options.availableMinDate = moment($scope.currentUser.systemCreationDate)
        .startOf('day')
        .valueOf();
      // set default Min Period
      options.minPeriod = moment($scope.currentUser.systemCreationDate);

      // calculate Min Period
      options.minPeriod = analyticsFactory2.getAnalyticsMinPeriod(
        moment(options.minPeriod).startOf('day'),
        moment(state.filters.endPeriod).startOf('day'),
        options.analyticsPeriodLimit
      );
      // calculate Max Period
      options.maxPeriod = state.filters.endPeriod;
    };

    reinitMinMaxPeriod();
    state.filters.startPeriod = options.minPeriod;

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getPage: busyImpactor(function() {
        if (!isValidTimeFilters(state.filters)) return false;
        return siteVisitorsFactory.getList(state).then(
          function(data) {
            state.itemsTotal = data.itemsTotal;
            state.isFiltersChanged = false;
            state.visitors = data.visitors;
          },
          function(res) {
            state.noDataAvailable = helpers.isDataNotAvailable(res.status);
            state.visitors = [];
            state.itemsTotal = 0;
            return $q.reject('no data available');
          }
        );
      }),
      onFiltersChange() {
        $timeout.cancel(debounceGetList);
        debounceGetList = $timeout(function() {
          state.currentPage = 1;
          reinitMinMaxPeriod();
          handlers.getPage();
        }, 500);
      },
      getFilterLabel: dropdown.getMultiSelectLabel,
      isAllFiltersSelected(blockName) {
        return (
          options[blockName] &&
          options[blockName].length &&
          options[blockName].length === state.filters[blockName].length
        );
      },
      filtersSelectAll(blockName, disalbeRequest) {
        if (handlers.isAllFiltersSelected(blockName)) {
          state.filters[blockName] = [];
        } else {
          state.filters = angular.extend(
            {},
            state.filters,
            window._.pick(angular.copy(options), blockName)
          );
        }
        if (!disalbeRequest) {
          handlers.onFiltersChange(state);
        }
      },
      setSortOrder(sortOrder) {
        state.sortOrder = sortOrder;
        handlers.getPage(
          state.itemsPerPage,
          (state.currentPage = 1),
          state.sortOrder
        );
      },
      getSortOrder() {
        return state.sortOrder;
      },
      getDeviceClass(device) {
        return helpers.getIconClass(device);
      },
      getOSClass(device) {
        return helpers.getOSIcon(device);
      },
      getExportOptions() {
        const exportOptions = [];
        state.exportAnalytics = true;
        const allBlocks = [
          'name',
          'lastVisit',
          'macAddress',
          'email',
          'birthDate',
          'gender',
          'phoneNumber',
          'visitsQuantity',
          'device',
          'OS',
          'socialNetworks'
        ];
        allBlocks.forEach(function(item) {
          exportOptions.push({
            key: item,
            name: `sites.exportLabels.${item}`,
            isDisabled: false,
            value: true
          });
        });
        return exportOptions;
      },
      hasPermission(type) {
        switch (type) {
          case 'export':
            return (
              $scope.site && $scope.site.permissions.exportAnalyticsVisitorList
            );
          default:
            return false;
        }
      }
    });

    function init() {
      state.noDataAvailable = false;
      navigationPanel.setActionButtons([]);
      $scope.setBreadcrumbs('details', $scope.site.id, $scope.site.name);
      siteVisitorsFactory
        .getAvailableValues()
        .then(function(data) {
          angular.extend(options, angular.copy(data));
          state.filters = angular.extend(
            {},
            state.filters,
            window._.omit(data, 'tops')
          );
        })
        .then(handlers.onFiltersChange);
    }

    $scope.$watch('site', function(val) {
      if (val) {
        reinitMinMaxPeriod();
        init();
      }
    });

    $scope.$on('$stateChangeStart', function() {
      sharedRequestsFactory.terminateDA(
        sharedRequestsFactory.terminateTypes[state.terminateType]
      );
      $timeout.cancel(debounceGetList);
    });

    // >>>> Helpers <<<<
    // Check if time filters are valid
    function isValidTimeFilters(timeFilters) {
      return analyticsFactory2.isValidTimeFilters(
        timeFilters,
        options.analyticsPeriodLimit,
        options.availableMinDate
      );
    }
    // >>>><<<<
  });
