const { angular } = window;

angular.module('wcm.core').directive('contenteditable', function() {
  return {
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      function read() {
        ngModel.$setViewValue(element.html());
      }

      function parse(value) {
        if (value) {
          return value
            .replace(/<\/div>/g, '\n')
            .replace(/<br>/g, '\n')
            .replace(/<div>/g, '');
        }
        return value;
      }
      ngModel.$parsers.push(parse);

      // eslint-disable-next-line no-param-reassign
      ngModel.$render = function() {
        element.html(ngModel.$viewValue || '');
      };

      element.bind('blur keyup change', function() {
        scope.$apply(read);
      });
    }
  };
});
