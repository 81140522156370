const { angular } = window;

angular.module('wcm.components').component('contactPerson', {
  templateUrl: 'app/core/components/contacts/contactPerson.html',
  bindings: {
    contact: '<',
    isCollapsed: '<',
    editMode: '<',
    onRemove: '&'
  },
  controller($scope, helpers) {
    'ngInject';

    const ctrl = this;

    ctrl.removePerson = function(event) {
      event.stopPropagation();
      helpers
        .confirmationModal(
          'components.contacts.labels.removeContactPersonTitle',
          'components.contacts.labels.removeMessage',
          true
        )
        .result.then(function() {
          ctrl.onRemove();
        });
    };
  }
});
