/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.helpers').directive('keypressDisable', function() {
  return {
    link(scope, element, attrs) {
      const keypressHandler = function(event) {
        event.preventDefault();
        event.stopPropagation();
      };

      attrs.$observe('keypressDisable', function(val) {
        if (val) {
          element
            .on('keypress', keypressHandler)
            .on('keydown', keypressHandler);
        } else {
          element
            .off('keypress', keypressHandler)
            .off('keydown', keypressHandler);
        }
      });
    }
  };
});
