/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.admin').directive('visitorImagesSlider', function() {
  return {
    restrict: 'AE',
    replace: true,
    scope: {
      images: '='
    },
    controller($scope) {
      $scope.imgIndex = 0;
      $scope.nextSlideActive = $scope.images.length > 2;
      $scope.prevSlideActive = false;
      $scope.imagesToShow = [];
      if ($scope.images.length < 4) {
        $scope.imagesToShow = angular.copy($scope.images);
      } else {
        $scope.imagesToShow = $scope.images.slice(0, 3);
      }

      $scope.handlers = {};

      $scope.handlers.nextSlide = function() {
        $scope.imgIndex += 1;
        if (
          $scope.imagesToShow.indexOf($scope.images[$scope.imgIndex - 1]) === 2
        ) {
          $scope.imagesToShow.shift();
          $scope.imagesToShow.push($scope.images[$scope.imgIndex]);
        }
      };

      $scope.handlers.prevSlide = function() {
        $scope.imgIndex -= 1;
        if (
          $scope.imagesToShow.indexOf($scope.images[$scope.imgIndex + 1]) === 0
        ) {
          $scope.imagesToShow.unshift($scope.images[$scope.imgIndex]);
          $scope.imagesToShow.pop();
        }
      };

      $scope.handlers.isActiveControl = function(arrow) {
        if (arrow === 'prev')
          return $scope.imgIndex !== 0 && $scope.images.length > 2;
        if (arrow === 'next')
          return (
            $scope.imgIndex !== $scope.images.length - 1 &&
            $scope.images.length > 2
          );
        return undefined;
      };

      $scope.handlers.clickPreview = function(src) {
        $scope.imgIndex = $scope.images.indexOf(src);
      };
    },
    templateUrl: 'app/admin/monitoring/visitors/visitorImagesSlider.html'
  };
});
