/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.geoLocation')
  .directive('wcmGeoMarkerPopup', function() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl(tEl, tAttrs) {
        if (tAttrs.popupType === 'analyticsGeneral') {
          return 'app/core/geo_location/geoMarkerPopup4.html';
        } else if (tAttrs.popupType === 'campaignSites') {
          return 'app/core/geo_location/geoMarkerPopup2.html';
        }
        return 'app/core/geo_location/geoMarkerPopup.html';
      }
    };
  });
