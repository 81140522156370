const { angular } = window;

angular
  .module('wcm.components')
  .controller('wcmMenuCtrl', function($scope, $state, $timeout) {
    const menuItems = [];
    let activeItem = null;

    this.addMenuItem = function(item) {
      menuItems.push(item);
    };
    const activeStateChecker = function() {
      angular.forEach(menuItems, function(item) {
        // eslint-disable-next-line no-param-reassign, no-multi-assign
        item.isActive = item.isOpened = false;
        // eslint-disable-next-line no-cond-assign
        if (
          angular.isDefined(item.activeOn) &&
          // eslint-disable-next-line no-param-reassign
          (item.isActive = $state.includes(item.activeOn))
        ) {
          activeItem = item;
        }
      });
      if (!!activeItem && !!activeItem.parentItem) {
        activeItem.parentItem.isActive = true;
      }
    };

    $scope.$on('$stateChangeSuccess', function() {
      $scope.$applyAsync(activeStateChecker);
    });
    $timeout(activeStateChecker); // run checker after menu is built
  })

  .controller('wcmMenuItemCtrl', function($scope, $state) {
    $scope.subItems = [];
    $scope.navigate = function(state) {
      if (angular.isDefined(state)) {
        $state.go(state);
      }
      $scope.isOpened = !$scope.isOpened;
    };
    // $scope.navigateLink = function (state) {
    //  if (angular.isDefined(state)) {
    //    //console.log(state);
    //    if(state != 'section.admin')
    //      return $state.href(state, {}, {absolute: true});
    //    else
    //      return '/#/';
    //  }
    // };

    this.addMenuItem = function(item) {
      $scope.subItems.push(item);
      return $scope;
    };
  })

  .directive('wcmMenu', function() {
    return {
      controller: 'wcmMenuCtrl',
      replace: true,
      transclude: true,
      template: '<ul class="sidebar_nav" ng-transclude></ul>'
    };
  })

  .directive('wcmMenuItem', function() {
    return {
      controller: 'wcmMenuItemCtrl',
      scope: {
        text: '@',
        activeOn: '@',
        sref: '@'
      },
      replace: true,
      transclude: true,
      require: ['?^^wcmMenuItem', '^^wcmMenu'],
      templateUrl: 'app/core/components/mainMenu/menuItem.html',
      link(scope, el, attrs, ctrls) {
        if (ctrls[0]) {
          scope.parentItem = ctrls[0].addMenuItem(scope);
        }
        ctrls[1].addMenuItem(scope);
      }
    };
  });
