/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.admin')
  .controller('systemSettingsCtrl', function(
    $scope,
    $rootScope,
    $timeout,
    $http,
    $state,
    $modal,
    helpers,
    i18nFactory,
    navigationPanel,
    breadcrumbs,
    systemSettingsFactory,
    session,
    themeFactory
  ) {
    const theme = themeFactory.getTheme();
    $scope.focused = {};
    breadcrumbs.set({
      path: [{ name: 'admin' }, { name: 'settings' }]
    });

    navigationPanel.setTabs([]);

    $scope.defaultCampaigns = ['A', 'B', 'AB'];

    const restartModalController = function($scope) {
      const checkForServerRestart = function() {
        $http
          .get('index.html')
          .success(function() {
            $scope.$close();
          })
          .error(function() {
            $timeout(checkForServerRestart, 5 * 1000);
          });
      };
      $timeout(checkForServerRestart, $scope.restartDuration);
    };

    $scope.settings = {};
    $scope.session = session;

    $scope.options = {
      systemNameFontOptions: [
        '10px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '22px',
        '24px',
        '26px',
        '28px',
        '30px',
        '32px'
      ],
      clientHeaderFonts: [
        'Open Sans Light',
        'Open Sans Regular',
        'Open Sans Semibold'
      ],
      systemEmailSettingsPorts: [25, 587, 465],
      systemEmailSettingsEncryptionTypes: ['none', 'SSL/TLS', 'STARTTLS'],
      systemEmailSettingsAuth: ['plain', 'login']
    };

    const getSettings = function() {
      // we wrap this code into timeout, because of strange behaviour of ng-animate that causes
      // ng-invalid class to
      // be present wih ng-valid on page refresh
      // TODO: consider to fix this weird fucking shit
      $scope.setBusy(true);
      $timeout(function() {
        systemSettingsFactory
          .getList()
          .success(function(data) {
            Object.keys(data).forEach(key => {
              // eslint-disable-next-line no-param-reassign
              data.authSettings[key] = `${
                data.authSettings[key]
              }?${+$scope.cacheParam}`;
            });
            $scope.settings = data;
            $scope.settingsCancel = angular.copy($scope.settings);
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      }, 200);
    };

    const save = function(settings) {
      $scope.setBusy(true);
      systemSettingsFactory
        .saveList(settings)
        .success(function() {
          $scope.editMode = false;
          $scope.settingsCancel = angular.copy(settings);
        })
        .finally(function() {
          $scope.setBusy(false);
          $scope.invalidateCache();
        });
    };

    $scope.submit = function(settings) {
      $scope.setSubmitted();
      if ($scope.sectionForm.$invalid) {
        return true;
      }
      $scope.setBusy(true);

      if ($scope.sectionForm.systemEmailSettingsForm.$dirty) {
        systemSettingsFactory
          .sendTestEmail(settings)
          .success(function() {
            save(settings);
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      } else {
        save(settings);
      }
      return undefined;
    };

    $scope.cancel = function() {
      cancelConfirmModal().then(function() {
        $scope.editMode = false;
        $scope.currentDictionary = angular.copy(
          $scope.currentDictionaryCancel
        );
      });
    };

    function cancelConfirmModal() {
      const messagePath = 'admin.settings.modals.cancelConfirmation.texts.edit';
      return helpers.confirmationModal('', messagePath, true).result;
    }

    $scope.$on('$stateChangeStart', function(event, toState, toParams) {
      if ($scope.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          $state.go(toState, toParams);
        });
      }
    });

    $scope.restartServer = function() {
      systemSettingsFactory.restartServer().success(function(data) {
        // alert(data.serverMessage);
        const modalScope = $scope.$new();
        i18nFactory
          .translateResponse(data.serverMessage.key, data.serverMessage.params)
          .then(function(message) {
            modalScope.message = message;
            modalScope.restartDuration = data.restartDuration;
          });
        $modal
          .open({
            scope: modalScope,
            templateUrl: 'restartModal',
            windowClass: 'info-modal',
            controller: restartModalController
          })
          .result.then(function() {
            session.destroy();
            $state.go('login.signIn');
          });
      });
    };

    $scope.getSystemLogo = function() {
      const systemLogo =
        $scope.$eval('settings.systemSettings.systemLogoImage') || '';
      return systemLogo.indexOf('data') === 0
        ? systemLogo
        : `${systemLogo}?${$scope.cacheParam}`;
    };

    $scope.$watchCollection('settings.systemSettings', function(val) {
      if (val) {
        angular.copy(val, theme);
      }
    });

    getSettings();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => !$scope.editMode,
        clickHandler: () => {
          $scope.editMode = true;
        }
      },
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.submit($scope.settings);
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);
  });
