/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */

const { angular } = window;

angular
  .module('wcm.admin')
  .controller('localizationCtrl', function(
    $scope,
    $state,
    $timeout,
    localizationFactory,
    $modal,
    helpers,
    breadcrumbs,
    navigationPanel,
    i18nFactory
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isDataLoaded: false
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      uncheckOther: function uncheckOther(list, prop, locale) {
        if (!list || !list.length) return;
        list.forEach(function(item) {
          if (item.id !== locale.id) {
            // eslint-disable-next-line no-param-reassign
            item[prop] = false;
          }
        });
      },
      cancel() {
        cancelConfirmModal().then(function() {
          $scope.setSubmitted(false);
          $scope.state.editMode = false;
          handlers.init(state.localizationType);
        });
      }
    });

    function init() {
      navigationPanel.setTabs([
        { name: 'termsAndConditions', sref: 'system.localization.terms' },
        { name: 'clientLocalization', sref: 'system.localization.client' },
        { name: 'systemLocalization', sref: 'system.localization.system' }
      ]);

      breadcrumbs.set({
        path: [{ name: 'admin' }, { name: 'localizations' }]
      });

      $scope.$on('$stateChangeStart', function(event, toState, toParams) {
        if ($scope.state.editMode) {
          event.preventDefault();
          cancelConfirmModal().then(function() {
            $scope.state.editMode = false;
            $state.go(toState, toParams);
          });
        }
      });

      function localizationSubControllerInit() {
        state.localizationType = $state.current.name.split('.').pop();
        $timeout(function() {
          handlers.init(state.localizationType);
        });
      }

      $scope.$on('$stateChangeSuccess', localizationSubControllerInit);
      localizationSubControllerInit();
    }

    function cancelConfirmModal() {
      const message = i18nFactory.translate(
        `admin.localization.modals.cancelConfirmation.texts.${state.localizationType}`
      );
      return helpers.confirmationModal('', message).result;
    }

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => !$scope.state.editMode,
        clickHandler: () => {
          $scope.state.editMode = true;
        }
      },
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.state.editMode,
        clickHandler: () => {
          $scope.handlers.save();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.state.editMode,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);

    init();
  });
