/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.settings').directive('settingsBlock', function() {
  return {
    restrict: 'AE',
    scope: true,
    replace: true,
    controller: 'settingsBlockCtrl',
    transclude: true,
    templateUrl: 'app/admin/settings/settingsBlock.html',
    link(scope, el, attr, ctrl, transclude) {
      scope.name = attr.name;
      transclude(scope, function(clone) {
        el.find('[settings-block-transclude]').append(clone);
      });
    }
  };
});
