const { angular } = window;

angular.module('wcm.components').directive('wcmModal', function() {
  return {
    restrict: 'E',
    templateUrl: 'app/core/components/modal/wcmModal.html',
    transclude: {
      title: 'modalTitle',
      body: 'modalBody',
      buttons: 'modalButtons'
    }
  };
});
