const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateGeneralCtrl', function(
    $scope,
    $state,
    $q,
    $controller,
    reportingFactory,
    analyticsRequestsFactory,
    analyticsFactory2,
    navigationPanel,
    ANALYTICS_CONFIG2
  ) {
    $controller('reportingCreateCtrl', { $scope });

    const A_CONFIG = ANALYTICS_CONFIG2;

    $scope.options = angular.extend($scope.options, {
      blocks: A_CONFIG.blocks.general,
      minPeriod: $scope.currentUser.systemCreationDate
    });
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      type: 'general'
    }));

    $scope.handlers = angular.extend($scope.handlers, {
      save() {
        const manageBlocks = analyticsRequestsFactory.getBlocksData();
        state.isBusy = true;
        reportingFactory
          .createGeneralReport(state.report, state.filters, manageBlocks)
          .then(
            function() {
              state.editMode = false;
              $state.go(state.callbackRoute);
            },
            function() {
              state.noWas = true;
            }
          )
          .finally(function() {
            state.isBusy = false;
          });
      }
    });
    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.generate',
        isDisabled: () => $scope.state.noWas || $scope.state.isBusy,
        isShown: () => true,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.reportForm.$valid) {
            $scope.handlers.save();
          }
        }
      },
      {
        title: () => 'reporting.buttons.cancel',
        isDisabled: () => $scope.state.isBusy,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);
  });
