/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const { moment } = window;

angular
  .module('wcm.campaigns')
  .controller('campaignVisitorsCtrl', function(
    $q,
    $scope,
    $state,
    $timeout,
    analyticsFactory2,
    campaignVisitorsFactory,
    sharedRequestsFactory,
    dropdown,
    breadcrumbs,
    helpers,
    ANALYTICS_CONFIG2
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isBusy: true,
      campaignId: $state.params.id,
      currentPage: 1,
      itemsPerPage: 10,
      noDataAvailable: false,
      itemsOnPageValues: [10, 25, 50, 100],
      sortOrder: 'last_visit:desc',
      exportType: 'viewersCampaign',
      exportAnalytics: false,
      isSiteVisitors: true,
      terminateType: 'visitorsCampaign',
      filters: {
        startPeriod: moment()
          .startOf('day')
          .subtract(30, 'day'),
        endPeriod: moment().startOf('day'),
        startTimeFrame: 0,
        endTimeFrame: 24 * 60 * 60 * 1000,
        daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
        top: '',
        interactionType: 'viewedAndClicked'
      },
      visitors: []
    });

    let debounceGetList;

    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        state.isBusy = !!queue.length;
      }, 100);
    });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      analyticsPeriodLimit: $scope.currentUser.analyticsPeriodLimit,
      minPeriod: null,
      maxPeriod: null
    });

    const reinitMinMaxPeriod = function() {
      // set available Min Date
      options.availableMinDate = window
        .moment($scope.currentUser.systemCreationDate)
        .startOf('day')
        .valueOf();
      // set default Min Period
      options.minPeriod = window.moment($scope.currentUser.systemCreationDate);

      // calculate Min Period
      options.minPeriod = analyticsFactory2.getAnalyticsMinPeriod(
        moment(options.minPeriod).startOf('day'),
        moment(state.filters.endPeriod).startOf('day'),
        options.analyticsPeriodLimit
      );
      // calculate Max Period
      options.maxPeriod = state.filters.endPeriod;
    };

    reinitMinMaxPeriod();
    state.filters.startPeriod = options.minPeriod;

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getPage: busyImpactor(function() {
        if (!isValidTimeFilters(state.filters)) return false;
        return campaignVisitorsFactory.getList(state).then(
          function(data) {
            state.itemsTotal = data.itemsTotal;
            state.isFiltersChanged = false;
            state.visitors = data.visitors;
          },
          function(res) {
            state.noDataAvailable = helpers.isDataNotAvailable(res.status);
            state.visitors = [];
            state.itemsTotal = 0;
            return $q.reject('no data available');
          }
        );
      }),
      onFiltersChange() {
        $timeout.cancel(debounceGetList);
        debounceGetList = $timeout(function() {
          state.currentPage = 1;
          reinitMinMaxPeriod();
          handlers.getPage();
        }, 500);
      },
      getFilterLabel: dropdown.getMultiSelectLabel,
      isAllFiltersSelected(blockName) {
        return (
          options[blockName] &&
          options[blockName].length &&
          options[blockName].length === state.filters[blockName].length
        );
      },
      filtersSelectAll(blockName, disalbeRequest) {
        if (handlers.isAllFiltersSelected(blockName)) {
          state.filters[blockName] = [];
        } else {
          state.filters = angular.extend(
            {},
            state.filters,
            window._.pick(angular.copy(options), blockName)
          );
        }
        if (!disalbeRequest) {
          handlers.onFiltersChange(state);
        }
      },
      setSortOrder(sortOrder) {
        state.sortOrder = sortOrder;
        handlers.getPage(
          state.itemsPerPage,
          (state.currentPage = 1),
          state.sortOrder
        );
      },
      getSortOrder() {
        return state.sortOrder;
      },
      getDeviceClass(device) {
        return helpers.getIconClass(device);
      },
      getOSClass(os) {
        return helpers.getOSIcon(os);
      },
      getExportOptions() {
        const exportOptions = [];
        state.exportAnalytics = true;
        const allBlocks = ANALYTICS_CONFIG2.blocks.viewers;
        allBlocks.forEach(function(item) {
          exportOptions.push({
            key: item,
            name: `sites.exportLabels.${item}`,
            isDisabled: false,
            value: true
          });
        });
        return exportOptions;
      },
      hasPermission(type) {
        switch (type) {
          case 'export':
            return (
              $scope.campaign &&
              $scope.campaign.permissions.exportAnalyticsVisitorList
            );
          default:
            return false;
        }
      }
    });

    function init() {
      state.noDataAvailable = false;
      breadcrumbs.set({
        path: [
          { name: 'campaigns' },
          { name: 'list' },
          {
            name:
              $scope.campaign.type.toLowerCase() === 'partner'
                ? 'classA'
                : 'classB',
            sref: `section.campaigns.${$scope.campaign.type.toLowerCase()}List`
          },
          { name: 'view' }
        ],
        itemName: `${$scope.campaign.id}. ${$scope.campaign.name}`
      });
      campaignVisitorsFactory
        .getAvailableValues()
        .then(function(data) {
          angular.extend(options, angular.copy(data));
          state.filters = angular.extend(
            {},
            state.filters,
            window._.omit(data, 'tops')
          );
          state.filters.startPeriod = (options.startPeriod <
          state.filters.startPeriod
            ? options.startPeriod
            : state.filters.startPeriod
          ).startOf('day');
        })
        .then(handlers.onFiltersChange);
    }

    $scope.$watch('campaign', function(val) {
      if (val) {
        reinitMinMaxPeriod();
        init();
      }
    });

    $scope.$on('$stateChangeStart', function() {
      sharedRequestsFactory.terminateDA(
        sharedRequestsFactory.terminateTypes[state.terminateType]
      );
      $timeout.cancel(debounceGetList);
    });

    // >>>> Helpers <<<<
    // Check if time filters are valid
    function isValidTimeFilters(timeFilters) {
      return analyticsFactory2.isValidTimeFilters(
        timeFilters,
        options.analyticsPeriodLimit,
        options.availableMinDate
      );
    }
    // >>>><<<<
  });
