/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;
angular
  .module('wcm.admin')
  .controller('abstractMonitoringCtrl', function(
    $scope,
    $state,
    $stateParams,
    $location,
    $q,
    $timeout,
    $modal,
    monitoringFactory,
    navigationPanel,
    monitoringCollectionFactory,
    helpers
  ) {
    const options = {
      filtersForCollectionList: {
        startPeriod: moment($scope.currentUser.systemCreationDate),
        endPeriod: moment().startOf('day'),
        searchString: '',
        collectionStatus: 'Active',
        collectionType: 'manual'
      }
    };

    $scope.setTabs = navigationPanel.setTabs;

    $scope.rootHandlers = {
      isVisitorChecked(state, macAddress) {
        return (
          (state.selectedVisitors.indexOf(macAddress) !== -1 &&
            !state.isSelectAllVisitors) ||
          (state.selectedVisitors.indexOf(macAddress) === -1 &&
            state.isSelectAllVisitors)
        );
      },
      isCurrentPageSelected(state) {
        const { visitors } = state;
        return (
          visitors.length &&
          visitors.every(visitor =>
            $scope.rootHandlers.isVisitorChecked(state, visitor.macAddress)
          )
        );
      },
      selectVisitor(state, visitorId) {
        helpers.toggleArrayElement(state.selectedVisitors, visitorId);
      },
      selectAllVisitors(state) {
        // eslint-disable-next-line no-param-reassign
        state.isSelectAllVisitors = true;
        // eslint-disable-next-line no-param-reassign
        state.selectedVisitors = [];
      },
      selectPageVisitors(state) {
        // eslint-disable-next-line no-param-reassign
        state.isSelectAllVisitors = false;
        const dataToAdd = state.visitorsRealData
          .map(function(item) {
            return !~state.selectedVisitors.indexOf(item.macAddress)
              ? item.macAddress
              : null;
          })
          .filter(function(item) {
            return item;
          });
        // eslint-disable-next-line no-param-reassign
        state.selectedVisitors = state.selectedVisitors.concat(dataToAdd);
      },
      unselectAllVisitors(state) {
        // eslint-disable-next-line no-param-reassign
        state.isSelectAllVisitors = false;
        // eslint-disable-next-line no-param-reassign
        state.selectedVisitors = [];
      },
      getSelectedVisitorsCount(state) {
        const { isSelectAllVisitors, itemsTotal, selectedVisitors } = state;
        return isSelectAllVisitors
          ? itemsTotal - selectedVisitors.length
          : selectedVisitors.length;
      },
      areAllVisitorsSelected(state) {
        const { itemsTotal } = state;
        return (
          itemsTotal > 0 &&
          $scope.rootHandlers.getSelectedVisitorsCount(state) === itemsTotal
        );
      },
      handleSelectAllCheckbox(state) {
        const handlers = $scope.rootHandlers;
        // if all set is selected -> uncheck all set
        if (handlers.areAllVisitorsSelected(state)) {
          handlers.unselectAllVisitors(state);
        } else if (handlers.isCurrentPageSelected(state)) {
          // if all page is selected -> uncheck current page
          // eslint-disable-next-line no-param-reassign
          state.selectedVisitors = window._.difference(
            state.selectedVisitors,
            state.visitors.map(i => i.macAddress)
          );
          // eslint-disable-next-line no-param-reassign
          state.isSelectAllVisitors = false;
        } else {
          // if neither all set nor all page is selected -> select currentPage
          handlers.selectPageVisitors(state);
        }
      },
      getSortOrder(state) {
        return state.sortOrder;
      },
      getDeviceClass(state, device) {
        return helpers.getIconClass(device);
      },
      getOSClass(state, device) {
        return helpers.getOSIcon(device);
      },
      noServerRedirect(status) {
        if (helpers.isDataNotAvailable(status) === 'noServerAvailable') {
          $state.go('monitoring.visitors.list');
        }
      },
      addToNewCollection(state, callback) {
        // eslint-disable-next-line no-param-reassign
        state.isBusyCollection = true;

        const scope = $scope.$new(true);
        scope.state = {};
        scope.forms = {};
        scope.labels = {
          title: 'admin.monitoring.collection.addModalTitle',
          fieldLabel: 'admin.monitoring.collection.stringLabel'
        };
        $modal
          .open({
            scope,
            openedClass: 'isEdit',
            windowClass: 'add-string_modal',
            templateUrl: 'app/core/views/stringModal.html',
            controller($scope, $modalInstance) {
              $scope.state.isSubmit = false;

              $scope.submit = function() {
                $scope.state.isSubmit = true;

                const requestData = {
                  collectionName: $scope.state.newStr.name,
                  visitors: state.selectedVisitors,
                  isAllVisitors: state.isSelectAllVisitors
                };
                monitoringCollectionFactory
                  .addToNewCollection(requestData, state)
                  .then(
                    function() {
                      if (callback) {
                        callback(requestData.collectionName);
                      }
                      $modalInstance.close();
                    },
                    function(res) {
                      if (res.status === 409) {
                        const labels = {
                          title:
                            'admin.monitoring.collections.titles.collectionNameConflict',
                          message:
                            'admin.monitoring.collections.messages.collectionNameConflict'
                        };
                        helpers.infoModal(labels).result.finally(function() {
                          $scope.state.isSubmit = false;
                        });
                      } else if (showErrorModal(res.status)) {
                        $modalInstance.close();
                      }
                    }
                  )
                  .finally(function() {
                    $scope.state.isSubmit = false;
                  });
              };
            }
          })
          .result.finally(function() {
            // eslint-disable-next-line no-param-reassign
            state.isBusyCollection = false;
          });
      },
      addToCollection(state, callback, isSingleVisitor) {
        // eslint-disable-next-line no-param-reassign
        state.isBusyCollection = true;
        const scope = $scope.$new(true);
        scope.visitors = state.selectedVisitors;

        monitoringCollectionFactory
          .collectionGetList(options.filtersForCollectionList)
          .then(
            function(data) {
              const options = {
                title: 'listModalCollection',
                searchParam: 'name'
              };

              const confirmationProps = {
                text: {
                  title:
                    'admin.monitoring.collections.titles.confirmAddToCollection',
                  message: `admin.monitoring.collections.messages.${
                    isSingleVisitor
                      ? 'confirmAddToCollectionSingle'
                      : 'confirmAddToCollectionMulti'
                  }`
                }
              };

              helpers
                .listModal(
                  data.collections,
                  [],
                  'name',
                  options,
                  confirmationProps
                )
                .result.then(
                  function(checkedData) {
                    const selected = helpers.filterBy(checkedData, 'id');
                    if (!selected.length) {
                      // eslint-disable-next-line no-param-reassign
                      state.isBusyCollection = false;
                      helpers.infoModal({
                        title: 'admin.modals.infoMsgTitle',
                        message:
                          'admin.monitoring.collections.messages.atLeastOne'
                      });
                      return false;
                    }

                    const requestData = {
                      visitors: state.selectedVisitors,
                      isAllVisitors: state.isSelectAllVisitors,
                      collections: window._.uniq(selected)
                    };

                    monitoringCollectionFactory
                      .addToCollections(requestData, state)
                      .then(
                        function() {
                          if (callback) callback(requestData.collections);
                        },
                        function(res) {
                          showErrorModal(res.status);
                        }
                      )
                      .finally(function() {
                        // eslint-disable-next-line no-param-reassign
                        state.isBusyCollection = false;
                      });
                    return undefined;
                  },
                  function() {
                    // eslint-disable-next-line no-param-reassign
                    state.isBusyCollection = false;
                  }
                );
            },
            function(res) {
              $scope.showErrorModal(res.status);
              // eslint-disable-next-line no-param-reassign
              state.isBusyCollection = false;
            }
          );
      }
    };

    function showErrorModal(status) {
      if (status >= 502 && status <= 504) {
        helpers.noServerInfoModal(status);
        return true;
      } else if (status === 244) {
        const labels = {
          title: 'admin.modals.infoMsgTitle',
          message: 'analytics.labels.noDataYetAvailable'
        };
        helpers.infoModal(labels);
        return true;
      }
      return false;
    }

    $scope.showErrorModal = showErrorModal;
  });
