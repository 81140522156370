/* eslint-disable import/no-extraneous-dependencies */
import 'i18n';
const modules = require.context('', true, /\.module\.js$/);
const configs = require.context('', true, /\.config\.js$/);
const filters = require.context('', true, /\.filters?\.js$/);
const controllers = require.context('', true, /\.controller\.js$/);
const directives = require.context('', true, /\.directive\.js$/);
const components = require.context('', true, /\.component\.js$/);
const factories = require.context('', true, /\.factory\.js$/);

const legacyStyles = require.context('assets/styles/legacy', true, /\.scss$/);
const styles = require.context('assets/styles/blocks', true, /\.scss$/);
const templates = require.context(
  'ngtemplate-loader?relativeTo=/src/&prefix=app/!html-loader!./',
  true,
  /\.html$/
);

// load legacy dependencies
window.$ = window.jQuery = require('jquery');
window.angular = require('angular');
window._ = require('underscore');
window.moment = require('moment/moment');
window.pluralize = require('pluralize');
window.saveAs = require('file-saver').saveAs;
window.html2canvas = require('html2canvas');
window.d3 = require('d3');
window.c3 = require('c3');

require('perfect-scrollbar/jquery')(window.$);
require('angular-ui-router');
require('angular-ui-validate');
require('angular-sanitize');
require('angular-bootstrap-colorpicker');
require('angular-bootstrap-colorpicker/css/colorpicker.css');
require('angular-messages');
require('checklist-model');
require('angular-datepicker/dist/angular-datepicker.js');
require('angular-datepicker/dist/angular-datepicker.css');
require('angular-content-editable');
require('angular-ui-slider');
require('jquery-ui-dist/jquery-ui.js');
require('jquery-ui-dist/jquery-ui.css');
require('c3/c3.css');
require('ngreact');

// eslint-disable-next-line import/no-unresolved
require('assets/styles/main.scss');

// load legacy modules
[
  legacyStyles,
  modules,
  configs,
  filters,
  controllers,
  directives,
  components,
  factories,
  styles,
  templates
].forEach(importAll);

// eslint-disable-next-line import/no-unresolved
require('app.routes.js');

function importAll(r) {
  r.keys().forEach(r);
}
