/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

/**
 * Directive provides config and remove buttons for arbitrary widget item
 */
angular
  .module('wcm.widgets')
  .directive('widgetEditorItemControls', function(
    $document,
    $compile,
    $parse,
    $position
  ) {
    const template =
      '<widget-editor-item-controls-popup></widget-editor-item-controls-popup>';

    return {
      restrict: 'A',
      compile() {
        const linker = $compile(template);
        return function link(scope, el, attr) {
          const showOnGetter = $parse(attr.widgetEditorItemControlsShow);
          let positionWatcherUnreg;
          let tooltip;
          let popupScope;

          scope.$watch(
            function() {
              return !!showOnGetter(scope);
            },
            function(newVal) {
              return newVal ? show() : hide();
            }
          );

          /**
           * Creates and shows tooltip with buttons. Sets up watching for the
           * host element position
           * in order to reposition itself
           */
          function show() {
            if (tooltip) return false;
            popupScope = scope.$new();
            popupScope.isRemovable = scope.$eval(
              attr.widgetEditorItemRemovable
            );
            popupScope.isConfigurable = scope.$eval(
              attr.widgetEditorItemConfigurable
            );

            popupScope.onRemove = function() {
              hide();
              scope.$eval(attr.widgetEditorItemOnRemove);
            };

            popupScope.onConfigure = function() {
              scope.$eval(attr.widgetEditorItemOnConfigure);
            };

            tooltip = linker(popupScope, function(el) {
              $document.find('body').append(el);
            });

            position();
            positionWatcherUnreg = scope.$watch(
              getElementPosition,
              position,
              true
            );
            return undefined;
          }

          /**
           * Hides and destroys tooltip with buttons
           *
           * @returns {boolean}
           */
          function hide() {
            if (!tooltip) return false;
            tooltip.remove();
            popupScope.$destroy();
            positionWatcherUnreg();
            tooltip = undefined;
            return undefined;
          }

          /**
           * Positions tooltip with buttons to fit right side of the host element
           *
           */
          function position() {
            if (!tooltip) return false;
            const coords = $position.positionElements(
              el,
              tooltip,
              'right',
              true
            );
            coords.top += 'px';
            coords.left += 'px';
            tooltip.css(coords);
            return undefined;
          }

          /**
           * Returns top end left position params of the host element, relatively to viewport
           *
           * @returns {*}
           */
          function getElementPosition() {
            if (!tooltip) return false;
            const boundingClientRect = el[0].getBoundingClientRect();
            return {
              top: boundingClientRect.top,
              left: boundingClientRect.left
            };
          }

          el.on('$destroy', hide);
        };
      }
    };
  })

  .directive('widgetEditorItemControlsPopup', function() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl:
        'app/sections/widgets/editor/common/widgetEditorItemControlsPopup.html'
    };
  });
