/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.eyedropper')
  .directive('eyedropperCnt', function(
    $timeout,
    eyedropperService,
    EYEDROPPER_CONFIG
  ) {
    return {
      scope: true,
      link(scope, el) {
        let elOffset;
        let _isActive;
        let colorMonitor;
        let unWatchColorChange = angular.noop;

        const onNeedUpdate = function(cb) {
          window.html2canvas(el[0], { onrendered: cb });
        };

        const document2canvasCoords = function(evt) {
          return {
            x: evt.pageX - elOffset.left,
            y: evt.pageY - elOffset.top
          };
        };
        const init = function() {
          elOffset = el.offset();

          const api = eyedropperService.registerEyedropper({
            update: onNeedUpdate
          });

          colorMonitor = window
            .$('<div>')
            .addClass(EYEDROPPER_CONFIG.colorMonitorClass)
            .appendTo(document.body);
          el.addClass(EYEDROPPER_CONFIG.eyedropperClass);

          el.on('mousemove.eyedropper', function(evt) {
            api.onCoordinatesChanged(document2canvasCoords(evt));
            if (_isActive) {
              colorMonitor.css({
                top: evt.pageY,
                left: evt.pageX
              });
            }
          });

          el.on('click.eyedropper', function(evt) {
            scope.$apply(function() {
              api.onColorChosen(document2canvasCoords(evt));
            });
          });

          el.on('mouseleave.eyedropper', function() {
            colorMonitor.removeClass(EYEDROPPER_CONFIG.activeClass);
          });

          el.on('mouseenter.eyedropper', function() {
            colorMonitor.toggleClass(
              EYEDROPPER_CONFIG.activeClass,
              eyedropperService.isActive()
            );
          });

          scope.$watch(eyedropperService.isActive, function(isActive) {
            _isActive = isActive;
            el.add(colorMonitor).toggleClass(
              EYEDROPPER_CONFIG.activeClass,
              isActive
            );
          });

          unWatchColorChange = eyedropperService.onColorChange(function(color) {
            if (_isActive) {
              colorMonitor.css('backgroundColor', color);
            }
          });
        };

        el.on('$destroy', function() {
          eyedropperService.unregisterEyedropper();
          unWatchColorChange();
          window.$(`.${EYEDROPPER_CONFIG.colorMonitorClass}`).remove();
        });

        $timeout(init, 100);
      }
    };
  });
