const { angular } = window;

angular
  .module('wcm.reporting', [])
  .run(function($state, hardlinks, reportingFactory, REPORTING_CONFIG) {
    hardlinks.registerHardlinkHandler(
      function(state, params) {
        return (
          state.name === 'hardlink.view' ||
          params.hash ||
          (~state.name.indexOf('reporting') &&
            params.id &&
            Number.isNaN(+params.id))
        );
      },
      function(state, params) {
        // Get data from here if current route is not one of 'hardlinksStates' already
        if (
          !~window._.values(REPORTING_CONFIG.hardlinksStates).indexOf(
            $state.current.name
          )
        ) {
          reportingFactory
            .getReportByHardlink(params.hash || params.id)
            .then(function(res) {
              // eslint-disable-next-line no-param-reassign
              reportingFactory.hardlinkReport = res;
              const redirectState =
                REPORTING_CONFIG.hardlinksStates[res.data.reportType];
              $state.go(redirectState, { id: params.hash });
            });
        }
      }
    );
  });
