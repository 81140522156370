/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCtrl', function(
    $q,
    $scope,
    $state,
    $stateParams,
    $rootScope,
    reportingFactory,
    APP_CONFIG,
    REPORTING_CONFIG
  ) {
    const user = $rootScope.currentUser;
    const permissionTypes = REPORTING_CONFIG.permissionTypes;

    $scope.hasPermission = function(type, reportType) {
      if (reportType === 'monitoring') return true;
      const _reportType = permissionTypes[reportType];
      switch (type) {
        case 'view':
          return (
            user &&
            user.userPermissions &&
            user.userPermissions.reporting[_reportType].view
          );
        case 'create':
          return (
            user &&
            user.userPermissions &&
            user.userPermissions.reporting[_reportType].create
          );
        default:
          return false;
      }
    };

    $scope.closeReport = function(closeUrl) {
      $state.go(closeUrl);
    };
  });
