const { angular } = window;

angular
  .module('wcm.analytics2')
  .factory('wasRequestsEmptyDataInterceptor', function(
    $q,
    errorHelpers,
    MONITORING_CONFIG,
    REPORTING_CONFIG,
    ANALYTICS_PRESETS_CONFIG,
    ANALYTICS_REQUESTS_CONFIG
  ) {
    const configs = [
      ANALYTICS_PRESETS_CONFIG,
      ANALYTICS_REQUESTS_CONFIG,
      REPORTING_CONFIG,
      MONITORING_CONFIG
    ];

    const excludedActions = [
      REPORTING_CONFIG.reportsGetList,
      MONITORING_CONFIG.getOptionsSitesAll // also used for campaign edit. Fixes WCM-7744
    ];

    const map = configs.reduce(
      function(acc, config) {
        Object.keys(config).forEach(function(keyName) {
          const value = config[keyName];
          if (~keyName.toLowerCase().indexOf('servlet')) {
            // eslint-disable-next-line no-param-reassign
            acc.servlets = acc.servlets.concat([value]);
          } else if (
            !angular.isObject(value) &&
            !~excludedActions.indexOf(value)
          ) {
            // eslint-disable-next-line no-param-reassign
            acc.actions = acc.actions.concat([value]);
          }
        });
        return acc;
      },
      { actions: [], servlets: [] }
    );

    const response = function(response) {
      const requestParse = errorHelpers.getServletAndActionFromUrl(
        response.config.url
      );
      const checkedStatus =
        response.status === 244 ||
        response.status === 204 ||
        response.status === 245 ||
        (response.status >= 300 && response.status <= 399);

      return checkedStatus &&
        ~map.servlets.indexOf(requestParse.servlet) &&
        ~map.actions.indexOf(requestParse.action)
        ? $q.reject(response)
        : response;
    };

    const request = function(config) {
      if (errorHelpers.isContainAction(config.url, map.actions)) {
        // eslint-disable-next-line no-param-reassign
        config.customConfig = config.customConfig || { skipErrors: [] };
        // eslint-disable-next-line no-param-reassign
        config.customConfig.skipErrors = ANALYTICS_REQUESTS_CONFIG.skipErrorsCodes.concat(
          config.customConfig.skipErrors || []
        );
      }
      return config;
    };

    return {
      request,
      response
    };
  });
