/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.errorHandling', [])
  .constant('ERROR_HANDLING_CONFIG', {
    // skip for analytics 'data not yet available' and 'session not found' error notification
    skipErrors: [244, 245, 204, 496, -1],
    errorPageUrl: '/50x.html'
  })
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('wasRequestsErrorHandlingInterceptor');
    $httpProvider.interceptors.push('errorHandlingInterceptor');
  });
