/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('analyticsChartWeather', function() {
    return {
      restrict: 'E',
      replace: true,
      controller($scope) {
        $scope.weatherTypes = require.context(
          'assets/images/weather-types',
          false,
          /\.png$/
        );
      },
      scope: {
        chartWeather: '=?'
      },
      templateUrl: 'app/core/analytics2/weather.html'
    };
  });
