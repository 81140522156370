const { angular } = window;

angular.module('wcm.core').component('manageBlockCheckboxTable', {
  templateUrl: 'app/core/components/manageBlock/manageBlockCheckboxTable.html',
  controller: 'manageBlockCheckboxTableController',
  bindings: {
    isValid: '<',
    items: '<',
    onSelectionChange: '&'
  }
});
