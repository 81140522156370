/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.widgets')
  .directive('approvedWidgetTooltip', function($tooltip) {
    return $tooltip(
      'approvedWidgetTooltip',
      'approvedWidgetTooltip',
      'mouseenter'
    );
  })
  .directive('approvedWidgetTooltipPopup', function() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        title: '@',
        content: '=',
        placement: '@',
        animation: '&',
        isOpen: '&',
        $close: '&'
      },
      templateUrl: 'app/sections/widgets/approvedWidgetTooltip.html',
      link(scope, el) {
        const $cnt = el.find('.approved-widget_cnt');
        const cntHeight = 242;
        const offset = 25;

        const moveHandler = function(e) {
          $cnt.css({
            top: e.pageY - cntHeight - offset,
            left: e.pageX + offset
          });
        };
        window.$(window).mousemove(moveHandler);

        scope.$on('$destroy', function() {
          window.$(window).off('mousemove', moveHandler);
        });

        scope.getBackgroundColor = function() {
          return ~['static_dynamic', 'slider'].indexOf(
            scope.content.contentType
          )
            ? scope.content.backgroundColor
            : 'transparent';
        };
      }
    };
  });
