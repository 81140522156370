/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('systemLogsCtrl', function(
    $scope,
    helpers,
    logsFactory,
    breadcrumbs
  ) {
    // $scope.logsTypes = ["wcm_server_startup","wcm_client_fail", "wcm_admin", "wcm_statistic"];
    $scope.logsTypes = [];

    $scope.state = {
      logsType: '',
      logEntries: [],
      noContent: false,
      noServer: false
    };

    breadcrumbs.set({
      path: [{ name: 'admin' }, { name: 'systemLogs' }]
    });

    $scope.getLogs = function(type) {
      $scope.state.logsType = type || $scope.state.logsType;
      $scope.setBusy(true);
      $scope.state.noServer = false;
      $scope.state.logEntries = [];
      logsFactory
        .logsGetList($scope.state.logsType)
        .then(
          function(res) {
            $scope.state.logEntries = res.data.logs || [];
          },
          function(res) {
            if (helpers.isDataNotAvailable(res.status)) {
              $scope.state.noServer = true;
            }
          }
        )
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.getLogsTypes = function() {
      $scope.setBusy(true);
      logsFactory
        .getAvailableLogsTypes()
        .then(function(res) {
          $scope.logsTypes = res.data.logsTypes;
          $scope.getLogs($scope.logsTypes[0]);
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    function init() {
      $scope.getLogsTypes();
    }

    init();
  });
