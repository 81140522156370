/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.entities')
  .controller('entitiesCtrl', function(
    $scope,
    $modal,
    $state,
    $q,
    helpers,
    entitiesFactory,
    modificationHistory,
    conversionFactory,
    contactPersonaeFactory,
    navigationPanel,
    breadcrumbs,
    countriesFactory,
    i18nFactory,
    sharedRequestsFactory
  ) {
    let entityCancel = {};
    const entityType =
      $state.params.type === 'classB' ? 'advertiser' : 'partner';
    const listState = `section.entities.${entityType}List`;
    const id = +$state.params.id || 0;
    $scope.setTabs = navigationPanel.setTabs;
    $scope.state = {
      canCreate: true
    };
    $scope.submitted = false;
    $scope.validation = {};
    $scope.focused = {};
    $scope.editMode = false;
    $scope.showSlidedownMH = false;
    $scope.showSlidedownBlock = false;
    $scope.showSlidedownBlock2 = false;
    $scope.options = {
      countries: [],
      cities: []
    };

    $scope.setBreadcrumbs = function(mode, itemName) {
      const path = [
        {
          name: 'entities'
        },
        {
          name: 'list'
        },
        {
          name: entityType === 'partner' ? 'classA' : 'classB',
          sref: `section.entities.${entityType}List`
        }
      ];
      path.push({ name: mode });

      breadcrumbs.set({
        path,
        itemName
      });
    };

    countriesFactory.getCountries().then(function(data) {
      angular.copy(data, $scope.options.countries);
    });

    $scope.$watch('entity.country', function(newVal, oldVal) {
      if (!newVal) {
        return false;
      }
      if (oldVal) {
        $scope.entity.city = '';
      }

      $scope.options.cities = [];
      countriesFactory.getCitiesForCountry(newVal).then(function(data) {
        angular.copy(data, $scope.options.cities);
      });
      return undefined;
    });

    // default entity
    $scope.entity = {
      id: 0,
      name: '',
      type: entityType,
      address: '',
      country: '',
      city: '',
      zipCode: '',
      contactPersonae: []
    };

    if (entityType === 'advertiser') $scope.entity.agencyName = '';

    /** * getAvailableValues method
     * get lists of available values for entity
     * ** */
    const getAvailableValues = function getAvailableValues() {
      $scope.setBusy(true);
      const pData = contactPersonaeFactory
        .getList(entityType, id)
        .success(function(data) {
          $scope.entity.contactPersonae = data.contactPersonae;
        });
      modificationHistory.get(entityType, `${id}`).then(function(history) {
        $scope.history = history;
      });
      $q.all([pData])
        .then(function() {
          entityCancel = angular.copy($scope.entity);
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.addContact = function() {
      contactPersonaeFactory
        .openAddContactModal()
        .then(contact => $scope.entity.contactPersonae.push(contact));
    };

    $scope.editContact = function(contact) {
      const contactCopy = angular.copy(contact);
      contactPersonaeFactory
        .openEditContactModal(contactCopy)
        .then(editedContact => {
          const contacts = $scope.entity.contactPersonae;
          contacts[contacts.indexOf(contact)] = editedContact;
        });
    };

    $scope.removeContact = function(index) {
      const contacts = $scope.entity.contactPersonae;
      contacts.splice(index, 1);
    };

    /** * getEntityData method
     * receive and proceed data from server
     * ** */
    const getData = function getData() {
      if (id !== 0) {
        $scope.setBusy(true);
        entitiesFactory
          .getData(id, entityType)
          .success(function(data) {
            $scope.entity = data.entity;
            $scope.entity.contactPersonae = [];
            $scope.setBreadcrumbs(
              'view',
              `${$scope.entity.id}. ${$scope.entity.name}`
            );
          })
          .success(getAvailableValues)
          .error(function() {
            $state.go('section.entities.advertiserList');
          })
          .finally(function() {
            $scope.setBusy(false);
          });

        $scope.setTabs([{ name: 'entities.details', isActive: 'true' }]);
      } else {
        $scope.setBusy(true);
        sharedRequestsFactory
          .canCreate(entityType, 'entityFalse')
          .then(function() {
            $scope.setBusy(false);
            $scope.editMode = true;
            $scope.setBreadcrumbs('create');
            $scope.setTabs([{ name: 'entities.create', isActive: 'true' }]);
          });
      }
    };

    const cancelConfirmModal = function() {
      const message = i18nFactory.translate(
        `entities.modals.cancelConfirmation.texts.${
          id === 0 ? 'create' : 'edit'
        }`
      );
      return helpers.confirmationModal('', message).result;
    };

    /** * cancel button handler
     * for both edit and view modes
     * ** */
    $scope.cancel = function cancel() {
      if ($scope.editMode && id) {
        cancelConfirmModal().then(function() {
          $scope.entity = angular.copy(entityCancel);
          $scope.editMode = false;
          $scope.showSlidedownMH = false;
          $scope.showSlidedownBlock = false;
          $scope.showSlidedownBlock2 = false;
          $scope.setBreadcrumbs(
            'view',
            `${$scope.entity.id}. ${$scope.entity.name}`
          );
          $scope.setTabs([{ name: 'entities.details', isActive: 'true' }]);
        });
      } else {
        $state.go(listState);
      }
    };

    $scope.$on('$stateChangeStart', function(event, toState) {
      if ($scope.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          $state.go(toState);
        });
      }
    });

    /** * edit button handler
     *
     * ** */
    $scope.edit = function edit() {
      $scope.editMode = true;
      getAvailableValues();
      $scope.setBreadcrumbs(
        'modify',
        `${$scope.entity.id}. ${$scope.entity.name}`
      );
      $scope.setTabs([{ name: 'entities.modify', isActive: 'true' }]);
    };

    /** * save button handler
     *
     * ** */
    $scope.save = function save() {
      $scope.submitted = true;
      if ($scope.entityForm.$invalid) {
        return true;
      }
      $scope.setBusy(true);
      return entitiesFactory
        .saveData($scope.entity)
        .success(function() {
          $scope.editMode = false;
          $state.go(listState);
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    getData();

    navigationPanel.setActionButtons([
      {
        title: () => 'entities.buttons.edit',
        isDisabled: () => false,
        isShown: () =>
          !$scope.editMode && $scope.entity && $scope.entity.isEditable,
        clickHandler: () => {
          $scope.edit();
        }
      },
      {
        title: () => 'entities.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.save();
        }
      },
      {
        title: () => `entities.buttons.${$scope.editMode ? 'cancel' : 'close'}`,
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);
  });
