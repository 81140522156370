const { angular } = window;

angular
  .module('wcm.components')

  .controller('PaginationController', [
    '$scope',
    '$attrs',
    '$parse',
    function($scope, $attrs, $parse) {
      const self = this;
      let ngModelCtrl = { $setViewValue: angular.noop }; // nullModelCtrl
      const setNumPages = $attrs.numPages
        ? $parse($attrs.numPages).assign
        : angular.noop;

      this.init = function(ngModelCtrl_, config) {
        ngModelCtrl = ngModelCtrl_;
        this.config = config;

        ngModelCtrl.$render = function() {
          self.render();
        };

        if ($attrs.itemsPerPage) {
          $scope.$parent.$watch($parse($attrs.itemsPerPage), function(value) {
            self.itemsPerPage = parseInt(value, 10);
            $scope.totalPages = self.calculateTotalPages();
          });
        } else {
          this.itemsPerPage = config.itemsPerPage;
        }
      };

      this.calculateTotalPages = function() {
        const totalPages =
          this.itemsPerPage < 1
            ? 1
            : Math.ceil($scope.totalItems / this.itemsPerPage);
        return Math.max(totalPages || 0, 1);
      };

      this.render = function() {
        $scope.page = parseInt(ngModelCtrl.$viewValue, 10) || 1;
      };

      $scope.selectPage = function(page) {
        if ($scope.page !== page && page > 0 && page <= $scope.totalPages) {
          ngModelCtrl.$setViewValue(page);
          ngModelCtrl.$render();
        }
      };

      $scope.getText = function(key) {
        return $scope[`${key}Text`] || self.config[`${key}Text`];
      };
      $scope.noPrevious = function() {
        return $scope.page === 1;
      };
      $scope.noNext = function() {
        return $scope.page === $scope.totalPages;
      };

      $scope.$watch('totalItems', function() {
        $scope.totalPages = self.calculateTotalPages();
      });

      $scope.$watch('totalPages', function(value) {
        setNumPages($scope.$parent, value); // Readonly variable

        if ($scope.page > value) {
          $scope.selectPage(value);
        } else {
          ngModelCtrl.$render();
        }
      });
    }
  ])

  .constant('paginationConfig', {
    itemsPerPage: 10,
    boundaryLinks: false,
    directionLinks: true,
    firstText: 'First',
    previousText: 'Previous',
    nextText: 'Next',
    lastText: 'Last',
    rotate: true
  })

  .directive('pagination', [
    '$parse',
    'paginationConfig',
    function($parse, paginationConfig) {
      return {
        restrict: 'EA',
        scope: {
          totalItems: '=',
          firstText: '@',
          previousText: '@',
          nextText: '@',
          lastText: '@'
        },
        require: ['pagination', '?ngModel'],
        controller: 'PaginationController',
        templateUrl: 'app/core/components/pagination/pagination.html',
        replace: true,
        link(scope, element, attrs, ctrls) {
          const paginationCtrl = ctrls[0];
          const ngModelCtrl = ctrls[1];

          if (!ngModelCtrl) {
            return; // do nothing if no ng-model
          }

          // Setup configuration parameters
          let maxSize = angular.isDefined(attrs.maxSize)
            ? scope.$parent.$eval(attrs.maxSize)
            : paginationConfig.maxSize;
          const rotate = angular.isDefined(attrs.rotate)
            ? scope.$parent.$eval(attrs.rotate)
            : paginationConfig.rotate;
          scope.boundaryLinks = angular.isDefined(attrs.boundaryLinks)
            ? scope.$parent.$eval(attrs.boundaryLinks)
            : paginationConfig.boundaryLinks;
          scope.directionLinks = angular.isDefined(attrs.directionLinks)
            ? scope.$parent.$eval(attrs.directionLinks)
            : paginationConfig.directionLinks;

          paginationCtrl.init(ngModelCtrl, paginationConfig);

          if (attrs.maxSize) {
            scope.$parent.$watch($parse(attrs.maxSize), function(value) {
              maxSize = parseInt(value, 10);
              paginationCtrl.render();
            });
          }

          // Create page object used in template
          function makePage(number, text, isActive) {
            return {
              number,
              text,
              active: isActive
            };
          }

          function getPages(currentPage, totalPages) {
            const pages = [];

            // Default page limits
            let startPage = 1;
            let endPage = totalPages;
            const isMaxSized =
              angular.isDefined(maxSize) && maxSize < totalPages;

            // recompute if maxSize
            if (isMaxSized) {
              if (rotate) {
                // Current page is displayed in the middle of the visible ones
                startPage = Math.max(currentPage - Math.floor(maxSize / 2), 1);
                // eslint-disable-next-line no-mixed-operators
                endPage = startPage + maxSize - 1;

                // Adjust if limit is exceeded
                if (endPage > totalPages) {
                  endPage = totalPages;
                  // eslint-disable-next-line no-mixed-operators
                  startPage = endPage - maxSize + 1;
                }
              } else {
                // Visible pages are paginated with maxSize
                startPage =
                  (Math.ceil(currentPage / maxSize) - 1) * maxSize + 1;

                // Adjust last page if limit is exceeded
                // eslint-disable-next-line no-mixed-operators
                endPage = Math.min(startPage + maxSize - 1, totalPages);
              }
            }

            // Add page number links
            for (let number = startPage; number <= endPage; number++) {
              const page = makePage(number, number, number === currentPage);
              pages.push(page);
            }

            return pages;
          }

          scope.nextGroup = function() {
            const nextValue = scope.page + maxSize;
            scope.selectPage(Math.min(nextValue, scope.totalPages));
          };

          scope.previousGroup = function() {
            const nextValue = scope.page - maxSize;
            scope.selectPage(Math.max(nextValue, 1));
          };

          const originalRender = paginationCtrl.render;
          paginationCtrl.render = function() {
            originalRender();
            if (scope.page > 0 && scope.page <= scope.totalPages) {
              scope.pages = getPages(scope.page, scope.totalPages);
            }
          };
        }
      };
    }
  ]);
