/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('licenseFactory', function(requestFactory) {
    const modalShown = false;
    const getLicense = function() {
      return requestFactory
        .post('system', 'getLicense', {}, {}, { skipErrors: [488, 499] })
        .then(function(res) {
          return res.data && res.data.license ? res.data.license : {};
        })
        .then(function(license) {
          // eslint-disable-next-line no-param-reassign
          license.analytic = license.analytic || {};
          // eslint-disable-next-line no-param-reassign
          license.report = license.report || {};

          // eslint-disable-next-line no-param-reassign
          license.analytic = mapLicenseBlock(license.analytic);
          // eslint-disable-next-line no-param-reassign
          license.availableWidgetsTypes = mapWidgetTypes(
            license.availableWidgetsTypes
          );

          return license;
        });
    };

    const saveLicense = function(licenseKey) {
      return requestFactory.post('system', 'saveLicense', { licenseKey });
    };

    function mapLicenseBlock(block) {
      // map arrays to hashes with boolean key-value pairs
      return Object.keys(block).reduce(function(acc, curr) {
        const values = block[curr];
        // eslint-disable-next-line no-param-reassign
        acc[curr] = values.reduce(function(accum, value) {
          // eslint-disable-next-line no-param-reassign
          accum[value] = true;
          return accum;
        }, {});
        return acc;
      }, {});
    }

    function mapWidgetTypes(widgetTypes) {
      const map = {
        'static / dynamic': 'staticDynamic'
      };
      return widgetTypes.map(function(type) {
        const lCasedType = type.toLowerCase();
        return map[lCasedType] ? map[lCasedType] : lCasedType;
      });
    }

    return {
      getLicense,
      saveLicense,
      modalShown
    };
  });
