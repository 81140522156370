/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.admin')
  .controller('macExceptionsListCtrl', function(
    $scope,
    $modal,
    macExceptionsFactory,
    filtersFactory,
    breadcrumbs,
    navigationPanel,
    modificationHistory,
    i18nFactory,
    helpers
  ) {
    breadcrumbs.set({
      path: [
        {
          name: 'admin'
        },
        {
          name: 'macExceptions'
        }
      ]
    });
    navigationPanel.setTabs([]);

    let sortOption;
    let appliedFilters;
    $scope.filters = filtersFactory.initFilters(
      {
        label: 'Sort',
        options: {
          'date:desc': 'date:desc',
          'date:asc': 'date:asc'
        },
        type: 'sort'
      },
      {
        label: 'SiteId',
        isHidden: true,
        options: {
          0: 0
        }
      }
    );

    $scope.history = {};

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(data) {
      $scope.totalItems = data.itemsTotal;
    };

    $scope.onListUpdate = function onListUpdate(sortOpt, filters) {
      sortOption = sortOpt;
      appliedFilters = filters;
      $scope.getPage();
    };

    $scope.getPage = function getPage() {
      macExceptionsFactory
        .getList(
          appliedFilters,
          $scope.currentPage,
          $scope.itemsOnPage,
          sortOption
        )
        .then(function(macExceptions) {
          $scope.macList = macExceptions;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.addMacException = function() {
      macExceptionsFactory.openMacExceptionModal(0, 'add', {}).then(function() {
        $scope.applyFilters(true);
      });
    };

    $scope.viewMacException = function(mac) {
      modificationHistory
        .get('MacException', mac.id)
        .then(function(history) {
          return macExceptionsFactory.openMacExceptionModal(
            0,
            'view',
            window._.extend({}, mac, { history })
          );
        })
        .then(function() {
          $scope.applyFilters(true);
        });
    };

    $scope.goToView = function(id, title) {
      macExceptionsFactory.getData(id, title, 0).then(function(mac) {
        $scope.viewMacException({
          id,
          details: mac.details,
          macAddress: title
        });
      });
    };

    $scope.deleteMacException = function(mac) {
      helpers
        .confirmationModal(
          '',
          'admin.macExceptions.modals.deleteMac.texts.prompt',
          mac
        )
        .result.then(function() {
          macExceptionsFactory.macExceptionDelete(mac, 0).then(function() {
            $scope.applyFilters(true);
          });
        });
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => false,
        isShown: () => true,
        clickHandler: () => {
          $scope.addMacException();
        }
      }
    ]);
  });
