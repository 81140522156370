/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const config = {
  widgetsServlet: 'widgets',
  getList: 'widgetGetList',
  moveToArchive: 'widgetMoveToArchive',
  recoverFromArchive: 'widgetRetrieveFromArchive',
  remove: 'widgetRemove',
  approve: 'widgetPerformAction',
  widgetGetData: 'widgetGetData',
  disapprove: 'widgetPerformAction',
  getWidgetsForCampaign: 'widgetGetForCampaign',
  getListForCampaign: 'widgetGetListForCampaign',
  applyFiltersForCampaign: 'widgetApplyFiltersForCampaign',
  uploadServlet: 'srvupload',
  widgetSavePreview: 'widgetSavePreview',
  widgetSave: 'widgetSave',
  widgetSaveCustom: 'widgetSaveCustom',
  widgetGetEditData: 'widgetGetEditData',
  cancelEditMode: 'widgetCancelEdit',

  clientI18nPath: '/client/client_locales',
  currentSchemaVersion: 1,

  imagesCompressQuality: 0.9,

  defaultWidgetStyles: {
    backgroundColor: '#acacac',
    btn: {
      normal: {
        color: { hex: '#ffffff', a: '100' },
        borderColor: { hex: '#ff0000', a: '0' },
        backgroundColor: { hex: '#ff0000', a: '100' },
        borderRadius: '4px',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textTransform: 'none'
      },
      hover: {
        color: { hex: '#ffffff', a: '100' },
        borderColor: { hex: '#ff0000', a: '0' },
        backgroundColor: { hex: '#ff0000', a: '70' },
        borderRadius: '4px',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textTransform: 'none'
      }
    },
    progressbar: {
      wrapper: {
        borderColor: { hex: '#000000', a: '100' }
      },
      bar: {
        backgroundColor: { hex: '#ff0000', a: '100' }
      },
      track: {
        backgroundColor: { hex: '#000000', a: '100' }
      }
    },
    terms: {
      color: { hex: '#ffffff', a: '100' },
      borderColor: { hex: '#000000', a: '0' },
      backgroundColor: { hex: '#000000', a: '100' },
      borderRadius: '4px',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      textTransform: 'none'
    },
    dots: {
      normal: {
        backgroundColor: { hex: '#ffffff', a: '100' },
        borderColor: { hex: '#000000', a: '0' }
      },
      active: {
        backgroundColor: { hex: '#ff0000', a: '100' }
      }
    },
    poll: {
      block: {
        backgroundColor: { hex: '#000000', a: '50' },
        borderColor: { hex: '#000000', a: '0' },
        borderRadius: '5px'
      },
      header: {
        textAlign: 'left'
      },
      question: {
        backgroundColor: { hex: '#000000', a: '0' },
        borderColor: { hex: '#000000', a: '0' },
        borderRadius: '0px'
      },
      answers: {
        backgroundColor: { hex: '#000000', a: '0' },
        borderColor: { hex: '#000000', a: '0' },
        borderRadius: '0px'
      },
      checkboxItemsStyle: {
        container: {
          backgroundColor: { hex: '#000000', a: '50' },
          borderColor: { hex: '#000000', a: '0' },
          borderRadius: '0px'
        },
        label: {
          color: { hex: '#ffffff', a: '100' },
          fontFamily: 'Open Sans',
          fontSize: '20px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textDecoration: 'none',
          textTransform: 'none'
        }
      },
      inputItemsStyle: {
        container: {
          backgroundColor: { hex: '#000000', a: '50' },
          borderColor: { hex: '#000000', a: '0' },
          borderRadius: '0px'
        },
        legend: {
          color: { hex: '#ffffff', a: '100' },
          fontFamily: 'Open Sans',
          fontSize: '20px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textDecoration: 'none',
          textTransform: 'none'
        },
        input: {
          backgroundColor: { hex: '#000000', a: '100' },
          borderColor: { hex: '#000000', a: '0' },
          borderRadius: '0px',
          color: { hex: '#ffffff', a: '50' },
          fontFamily: 'Open Sans',
          fontSize: '13px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          textDecoration: 'none',
          textTransform: 'none'
        }
      }
    }
  }
};

config.widgetTemplates = {
  base: {
    schemaVersion: 1,
    buttons: [],
    elements: {
      header: true
    },
    progressbar: {
      timeout: 3,
      autostart: true,
      style: window.$.extend(true, {}, config.defaultWidgetStyles.progressbar)
    },
    terms: {
      style: window.$.extend(true, {}, config.defaultWidgetStyles.terms)
    },
    backgroundType: 'image',
    backgroundColor: config.defaultWidgetStyles.backgroundColor,
    foregroundImages: [],
    backgroundImage: '',
    meta: {
      id: 0,
      name: '',
      files: {}
    }
  },

  static_dynamic: {
    widgetType: 'static'
  },
  custom: {
    widgetType: 'custom'
  },
  video: {
    widgetType: 'video',
    media: {
      muted: false
    }
  },
  slider: {
    widgetType: 'slider',
    progressbar: {
      autoSlideTime: 2
    },
    slider: {
      dots: {
        style: {
          normal: window.$.extend(
            true,
            {},
            config.defaultWidgetStyles.dots.normal
          ),
          active: window.$.extend(
            true,
            {},
            config.defaultWidgetStyles.dots.active
          )
        }
      }
    },
    meta: {
      files: {
        slides: [{}, {}]
      },
      smallPlaceForSlides: [{}, {}]
    }
  },
  poll: {
    widgetType: 'poll',
    progressbar: {
      timeout: 0
    },
    poll: {
      style: window.$.extend(true, {}, config.defaultWidgetStyles.poll.block),
      exclusiveCheckboxes: true,
      isAnswerRequired: true,
      header: {
        style: window.$.extend(true, {}, config.defaultWidgetStyles.poll.header)
      },
      question: {
        style: window.$.extend(
          true,
          {},
          config.defaultWidgetStyles.poll.question
        ),
        i18n: {}
      },
      answers: {
        style: window.$.extend(
          true,
          {},
          config.defaultWidgetStyles.poll.answers
        ),
        options: []
      }
    },
    meta: {
      isPollEditable: true,
      checkboxItemsStyle: window.$.extend(
        true,
        {},
        config.defaultWidgetStyles.poll.checkboxItemsStyle
      ),
      inputItemsStyle: window.$.extend(
        true,
        {},
        config.defaultWidgetStyles.poll.inputItemsStyle
      )
    }
  }
};

angular.module('wcm.widgets').constant('WIDGETS_CONFIG', config);
