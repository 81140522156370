const { angular } = window;

angular
  .module('wcm.core')
  .controller('hardlinkCtrl', function(
    $scope,
    reportingFactory,
    REPORTING_CONFIG,
    $rootScope,
    $modal,
    $state
  ) {
    $scope.isBusy = true;
    if ($state.params.hash) {
      reportingFactory
        .getReportByHardlink($state.params.hash)
        .then(function(res) {
          const redirectState =
            REPORTING_CONFIG.hardlinksStates[res.data.reportType];
          $scope.isBusy = false;
          $rootScope.hardlinkReport = res;
          $state.go(redirectState, { id: $state.params.hash });
        });
    }
  });
