/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.helpers')
  .factory('sharedRequestsFactory', function(
    $q,
    $state,
    $rootScope,
    requestFactory,
    helpers,
    SHARED_CONFIG,
    APP_CONFIG
  ) {
    const terminateTypes = {
      general: 'analyticsGeneral',
      sites: 'analyticsSitesWide',
      campaigns: 'analyticsCampaignsWide',
      users: 'users',
      analyticsSite: 'analyticsSiteSpecific',
      analyticsCampaign: 'analyticsCampaignSpecific',
      visitors: 'visitorsList',
      visitorsSite: 'siteVisitorsList',
      visitorsCampaign: 'campaignVisitorsList',
      visitorsCollection: 'collectionVisitorsList'
    };

    const skipErrors = [204, 209, 210, 244, 245, 400, 404, 502, 504];

    const reportsTerminateTypes = {
      lists: {
        systemWideGeneral: 'reportingGeneralList',
        systemWideCampaigns: 'reportingCampaignsList',
        systemWideSites: 'reportingSitesList',
        campaignSpecificAnalytics: 'reportingCampaignSpecificList',
        siteSpecificAnalytics: 'reportingSiteSpecificlList',
        campaignSpecificViewers: 'reportingCampaignViewersList',
        siteSpecificVisitors: 'reportingSiteVisitorsList',
        monitoring: 'reportingMonitoringList'
      },
      createView: {
        systemWideGeneral: 'reportingGeneral',
        systemWideCampaigns: 'reportingCampaigns',
        systemWideSites: 'reportingSites',
        campaignSpecificAnalytics: 'reportingCampaignSpecific',
        siteSpecificAnalytics: 'reportingSiteSpecific',
        campaignSpecificViewers: 'reportingCampaignViewers',
        siteSpecificVisitors: 'reportingSiteVisitors',
        monitoring: 'reportingMonitoring'
      }
    };

    const showInfoModal = function(param) {
      const labels = {
        title: 'admin.modals.noCreatePermissions.title',
        message: 'admin.modals.noCreatePermissions.msg',
        param: `admin.modals.noCreatePermissions.${param}`
      };
      helpers.infoModal(labels);
      $state.go(APP_CONFIG.defaultState);
    };

    const canCreate = function(type, param) {
      return requestFactory
        .post(
          SHARED_CONFIG.commonServlet,
          SHARED_CONFIG.canCreate,
          { type },
          {},
          { skipErrors: [403] }
        )
        .then(
          function(res) {
            if (!res.data.canCreate) {
              showInfoModal();
              return $q.reject(res);
            }
            return undefined;
          },
          function(res) {
            showInfoModal(param);
            return $q.reject(res);
          }
        );
    };

    const terminateDA = function(type) {
      const sendData = {
        uniquePageId: $rootScope.uniqueBrowserTabToken
      };
      if (type) {
        sendData.terminateType = type;
      }
      return requestFactory.post(
        SHARED_CONFIG.terminateServlet,
        SHARED_CONFIG.requestsDA,
        sendData,
        {},
        { skipErrors }
      );
    };

    // >>> DEPRECATED! <<<
    // var addTerminatedParamsToSendData = function(state, type) {
    //   if(type) { state.terminateType = terminateTypes[type]; }
    //   return state;
    // };

    return {
      canCreate,
      terminateDA,
      terminateTypes,
      reportsTerminateTypes
      // addTerminatedParamsToSendData: addTerminatedParamsToSendData
    };
  });
