/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.labels').directive('wcmLabels', function() {
  return {
    restrict: 'AE',
    replace: true,
    scope: {
      availableLabels: '=',
      availableTags: '=?',
      currentLabels: '=?',
      tagValidation: '=?',
      editMode: '=',
      title: '@',
      titleRemove: '@',
      titleAdd: '@',
      labelsTitle: '@',
      modalMessageRemove: '@',
      modalMessageAdd: '@',
      modalMessageDuplicate: '@',
      modalMessageTagDuplicate: '@',
      name: '@'
    },
    controller: 'labelsCtrl',
    templateUrl: 'app/core/labels/labels.html'
  };
});
