/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.campaigns')
  .directive('campaignPrognosisTooltip', function($tooltip) {
    return $tooltip(
      'campaignPrognosisTooltip',
      'campaignPrognosisTooltip',
      'mouseenter'
    );
  })
  .directive('campaignPrognosisTooltipPopup', function() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        title: '@',
        content: '=',
        placement: '@',
        animation: '&',
        isOpen: '&'
      },
      templateUrl: 'app/sections/campaigns/campaignPrognosisTooltipPopup.html'
    };
  });
