/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.widgets')
  .factory('widgetEditorCopyPasteFactory', function($rootScope, localStorage) {
    let copiedStyle;
    const storageKey = 'widgetStyle';

    init();

    function copy(style) {
      copiedStyle = localStorage.set(
        storageKey,
        window.$.extend(true, {}, style)
      );
    }

    function paste(dest) {
      const isSourceComplex = isComplexStyle(copiedStyle);
      const isDestComplex = isComplexStyle(dest);
      let flatSource;

      // simple -> simple
      if (!isDestComplex && !isSourceComplex) {
        return simpleStylesCopy(copiedStyle, dest);
      }

      // complex -> complex
      if (isSourceComplex && isDestComplex) {
        if (areStylesCompatible(dest, copiedStyle)) {
          return simpleStylesCopy(copiedStyle, dest);
        }
        flatSource = toFlatStyle(copiedStyle);
        copyToComplexStyle(flatSource, dest);
      }

      // complex -> simple
      if (isSourceComplex && !isDestComplex) {
        flatSource = toFlatStyle(copiedStyle);
        return simpleStylesCopy(flatSource, dest);
      }

      // simple -> complex
      if (!isSourceComplex && isDestComplex) {
        return copyToComplexStyle(copiedStyle, dest);
      }
      return undefined;
    }

    function simpleStylesCopy(source, dest) {
      const destKeys = Object.keys(dest);
      window.$.extend(true, dest, window._.pick(source, destKeys));
    }

    function isStyleCopied() {
      return !!copiedStyle;
    }

    function toFlatStyle(complexStyle) {
      if (isProgressStyle(complexStyle)) {
        return {
          borderColor: window.$.extend(
            true,
            {},
            complexStyle.wrapper.borderColor
          ),
          backgroundColor: window.$.extend(
            true,
            {},
            complexStyle.track.backgroundColor
          ),
          color: window.$.extend(true, {}, complexStyle.bar.backgroundColor)
        };
      }
      if (isPollCheckboxStyle(complexStyle)) {
        return window.$.extend(
          true,
          {},
          complexStyle.container,
          complexStyle.label
        );
      }
      if (isPollInputStyle(complexStyle)) {
        return window.$.extend(
          true,
          {},
          complexStyle.container,
          complexStyle.legend
        );
      }
      if (isSliderDotStyle(complexStyle)) {
        return {
          borderColor: window.$.extend(
            true,
            {},
            complexStyle.normal.borderColor
          ),
          backgroundColor: window.$.extend(
            true,
            {},
            complexStyle.normal.backgroundColor
          ),
          color: window.$.extend(true, {}, complexStyle.active.backgroundColor)
        };
      }
      return undefined;
    }

    function copyToComplexStyle(simpleSource, dest) {
      if (isProgressStyle(dest)) {
        safeExtend(dest.wrapper.borderColor, simpleSource.borderColor);
        safeExtend(dest.track.backgroundColor, simpleSource.backgroundColor);
        safeExtend(dest.bar.backgroundColor, simpleSource.color);
      }

      if (isPollCheckboxStyle(dest)) {
        safeExtend(dest.container.borderColor, simpleSource.borderColor);
        safeExtend(
          dest.container.backgroundColor,
          simpleSource.backgroundColor
        );
        safeExtend(dest.label.color, simpleSource.color);
        // eslint-disable-next-line no-param-reassign
        dest.container.borderRadius =
          simpleSource.borderRadius || dest.container.borderRadius;
        copyFontStyles(dest, simpleSource, 'label');
      }

      if (isPollInputStyle(dest)) {
        safeExtend(dest.container.borderColor, simpleSource.borderColor);
        safeExtend(
          dest.container.backgroundColor,
          simpleSource.backgroundColor
        );
        safeExtend(dest.legend.color, simpleSource.color);
        // eslint-disable-next-line no-param-reassign
        dest.container.borderRadius =
          simpleSource.borderRadius || dest.container.borderRadius;
        copyFontStyles(dest, simpleSource, 'legend');
      }

      if (isSliderDotStyle(dest)) {
        safeExtend(dest.normal.borderColor, simpleSource.borderColor);
        safeExtend(dest.normal.backgroundColor, simpleSource.backgroundColor);
        safeExtend(dest.active.backgroundColor, simpleSource.color);
      }

      function safeExtend(dest, source) {
        return window.$.extend(true, dest, source);
      }

      function copyFontStyles(dest, source, propName) {
        /* eslint-disable no-param-reassign */
        dest[propName].fontFamily =
          source.fontFamily || dest[propName].fontFamily;
        dest[propName].fontSize = source.fontSize || dest[propName].fontSize;
        dest[propName].fontWeight =
          source.fontWeight || dest[propName].fontWeight;
        dest[propName].fontStyle = source.fontStyle || dest[propName].fontStyle;
        dest[propName].textTransform =
          source.textTransform || dest[propName].textTransform;
        dest[propName].textDecoration =
          source.textDecoration || dest[propName].textDecoration;
        /* eslint-enable no-param-reassign */
      }
    }

    function isProgressStyle(style) {
      return !!~Object.keys(style).indexOf('track');
    }

    function isPollInputStyle(style) {
      return !!~Object.keys(style).indexOf('input');
    }

    function isPollCheckboxStyle(style) {
      return !!~Object.keys(style).indexOf('label');
    }

    function isSliderDotStyle(style) {
      return !!~Object.keys(style).indexOf('active');
    }

    function isComplexStyle(style) {
      if (!angular.isObject(style)) return false;
      const keys = Object.keys(style);
      for (let i = 0; i < keys.length; i++) {
        if (angular.isObject(style[keys[i]]) && !style[keys[i]].hex) {
          return true;
        }
      }
      return false;
    }

    function areStylesCompatible(style1, style2) {
      const style1Keys = Object.keys(style1);
      const style2Keys = Object.keys(style2);

      return arraysEqual(style1Keys, style2Keys);
    }

    function arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }
      for (let i = arr1.length; i--; ) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }

      return true;
    }

    function syncWithLocalstorage() {
      const styleFromStorage = localStorage.get(storageKey);
      if (styleFromStorage) {
        copiedStyle = styleFromStorage;
      }
    }

    function init() {
      window.$(window).on('storage', syncWithLocalstorage);
      syncWithLocalstorage();
    }

    return {
      copy,
      paste,
      isStyleCopied
    };
  });
