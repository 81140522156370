/**
 * The following features are still outstanding: popup delay, animation as a
 * function, placement as a function, inside, support for more triggers than
 * just mouse enter/leave, html popovers, and selector delegatation.
 */
const { angular } = window;

angular
  .module('wcm.components')

  .directive('popoverPopup', function() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        title: '@',
        content: '=',
        placement: '@',
        animation: '&',
        isOpen: '&',
        $close: '&'
      },
      templateUrl: 'app/core/components/popover/popover.html'
    };
  })

  .directive('popover', [
    '$tooltip',
    function($tooltip) {
      return $tooltip('popover', 'popover', 'click');
    }
  ]);
