/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.analytics2').constant('ANALYTICS_CONFIG2', {
  daysOfWeek: [
    { id: 1, title: 'MON' },
    { id: 2, title: 'TUE' },
    { id: 3, title: 'WED' },
    { id: 4, title: 'THU' },
    { id: 5, title: 'FRI' },
    { id: 6, title: 'SAT' },
    { id: 7, title: 'SUN' }
  ],
  monthesOfYear: [
    { id: 1, title: 'JAN' },
    { id: 2, title: 'FEB' },
    { id: 3, title: 'MAR' },
    { id: 4, title: 'APR' },
    { id: 5, title: 'MAY' },
    { id: 6, title: 'JUN' },
    { id: 7, title: 'JUL' },
    { id: 8, title: 'AUG' },
    { id: 9, title: 'SEP' },
    { id: 10, title: 'OCT' },
    { id: 11, title: 'NOV' },
    { id: 12, title: 'DEC' }
  ],
  axisXName: 'x',
  blocks: {
    general: [
      'generalInfoSystem',
      'campaignsTimeline',
      'dataByTraffic',
      'amountOfCampaigns',
      'amountOfWidgets',
      'amountOfSitesAndEntities',
      'amountOfViewsAndVisitors',
      'quantityOfRegisteredUsers',
      'dataByTags',
      'vacantSites',
      'allSystemSites'
    ],
    campaigns: [
      'generalInfoCampaigns',
      'dataByComparisonCampaigns',
      'dataByDays',
      'dataBySites',
      'dataByCitiesAndCountries',
      'dataByDevicesCampaigns',
      'dataByDemographicInfoCampaigns',
      'dataByHoursCampaigns',
      'dataByWeekCampaigns',
      'dataByMonthsCampaigns',
      'dataByYearsCampaigns'
    ],
    analyticsCampaign: [
      'generalInfoCampaignsSpecific',
      'dataByDaysAndSites',
      'dataByNumberOfViews',
      'dataBySites',
      'dataBySiteTags',
      'dataByCitiesAndCountries',
      'dataByViewersPortrait',
      'dataByDevicesCampaigns',
      'dataByDemographicInfoCampaigns',
      'dataByHoursCampaigns',
      'dataByWeekCampaigns',
      'dataByMonthsCampaigns',
      'dataByYearsCampaigns',
      'dataByCurrentWidgets'
    ],
    sites: [
      'generalInfoSitesAll',
      'dataByComparisonSites',
      'dataByVisitors',
      'dataByTrafficUsage',
      'dataByNumberOfVisits',
      'dataByVisitorsAgeAndGender',
      'dataByNewAndReturningVisitors',
      'dataByNotReturningVisitors',
      'dataByCampaigns',
      'dataByCitiesAndCountriesSites',
      'dataByDevicesSites',
      'dataByDemographicInfoSites',
      'dataByHoursSites',
      'dataByWeekSites',
      'dataByMonthsSites',
      'dataByYearsSites'
    ],
    analyticsSite: [
      'generalInfoSitesSpecAll',
      'generalInfoSitesFiltered',
      'dataByVisitorsWithWeather',
      'dataByTrafficUsage',
      'dataByNumberOfVisits',
      'dataByOtherVisitedSites',
      'dataByVisitorsAgeAndGender',
      'dataByNewAndReturningVisitors',
      'dataByNotReturningVisitors',
      'dataByCampaignsViews',
      'dataByCampaigns',
      'dataByCitiesAndCountriesSites',
      'dataByDevicesSites',
      'dataByDemographicInfoSites',
      'dataByHoursSites',
      'dataByWeekSites',
      'dataByMonthsSites',
      'dataByYearsSites'
    ],
    visitors: [
      'name',
      'lastVisit',
      'macAddress_onlyExcel',
      'email',
      'birthDate',
      'gender',
      'phoneNumber',
      'visitsQuantity',
      'device_OS',
      'socialNetworks'
    ],
    monitoring: [
      'name',
      'lastVisit',
      'macAddress',
      'email',
      'birthDate',
      'gender',
      'phoneNumber',
      'visitsQuantity',
      'device_OS',
      'socialNetworks'
    ],
    viewers: [
      'name',
      'lastInteraction',
      'macAddress_onlyExcel',
      'email',
      'birthDate',
      'gender',
      'phoneNumber',
      'interactionsQuantity',
      'interactionSites',
      'visitedSites_onlyExcel',
      'clickedSites_onlyExcel',
      'device_OS',
      'socialNetworks'
    ]
  }
});
