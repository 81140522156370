/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.widgets')
  .directive('sliderWidgetEditorHelper', function(
    $window,
    $rootScope,
    $timeout
  ) {
    return {
      link(scope, el) {
        scope.fontCoef = 0.69;
        scope.fontCoefTerms = 0.4;

        // var $bannerCnt = el.find('.widget-editor_banner-cnt'),
        let $editorCnt = el.find('.widget-editor_content-w');
        let $bottomElsCnt = el.find('.widget-editor_bottom-elements-cnt');
        const $contentWrapper = el.find(
          '.widget-editor_banner-cnt-adjust_slider'
        );
        let $slideWrapper = el.find('.widget-editor_slide-wrapper');
        let $slideSmallWrapper = el.find('.widget-editor_slide-small-wrapper');
        let $slideCnt = el.find('.widget-editor_slide-cnt');
        let $slideSmallCnt = el.find('.widget-editor_slide-small-cnt');
        // const $slideControlCnt = el.find('.widget-editor_slide-control-cnt');
        // const $slideControlPoint = el.find('.widget-editor_slide-control-point');
        const $btnsCnt = el.find('.widget-editor_btns-cnt');
        const $btnsTermsCnt = el.find('.widget-editor_terms');

        const reInitVars = function() {
          // $bannerCnt = el.find('.widget-editor_banner-cnt');
          $editorCnt = el.find('.widget-editor_bg-cnt');
          $bottomElsCnt = el.find('.widget-editor_bottom-elements-cnt');
          // $contentWrapper = el.find('.widget-editor_content-w');
          $slideWrapper = el.find('.widget-editor_slide-wrapper');
          $slideSmallWrapper = el.find('.widget-editor_slide-small-wrapper');
          $slideCnt = el.find('.widget-editor_slide-cnt');
          $slideSmallCnt = el.find('.widget-editor_slide-small-cnt');
        };

        // eslint-disable-next-line no-multi-assign
        const resetEditorLayout = (scope.resetEditorLayout = function() {
          reInitVars();
          const editorCntHeight = $editorCnt.height();
          const editorCntWidth = $editorCnt.width();
          const contentWrapperHeight = editorCntHeight * 0.81;
          const contentWrapperWidth = contentWrapperHeight * 0.6;

          const bannerCntHeight = contentWrapperHeight * 0.84;
          const bottomElsCntHeight = contentWrapperHeight - bannerCntHeight;

          let slideCntHeight = bannerCntHeight * 0.8;
          let slideCntWidth = contentWrapperWidth * 0.8;
          if (slideCntWidth / editorCntWidth > 0.2) {
            slideCntWidth = editorCntWidth * 0.2;
            slideCntHeight = slideCntWidth * 1.4;
          }

          $contentWrapper.height(contentWrapperHeight);
          $contentWrapper.width(contentWrapperWidth);

          // $bannerCnt.outerHeight(bannerCntHeight);
          $bottomElsCnt.outerHeight(bottomElsCntHeight);

          $slideCnt.outerHeight(slideCntHeight);
          $slideCnt.outerWidth(slideCntWidth);
          $slideCnt.css({ marginLeft: '20px', display: 'inline-block' });

          // const slideWrapperWidth = (slideCntWidth * $slideCnt.length)
          //   + (20 * ($slideCnt.length - 1))
          //   + 6;
          $slideWrapper.css({ position: 'absolute' });
          // $slideWrapper.width(slideWrapperWidth);
          $slideWrapper.css({
            transform: 'translateX(-50%) translateY(-50%)',
            left: '50%',
            top: '50%'
          });

          const slideSmallCntHeight = slideCntHeight * 0.3;
          const slideSmallCntWidth = slideCntWidth * 0.3;
          $slideSmallCnt.outerHeight(slideSmallCntHeight);
          $slideSmallCnt.outerWidth(slideSmallCntWidth);
          $slideSmallCnt.css({ marginLeft: '20px', display: 'inline-block' });

          const slideSmallWrapperWidth =
            slideSmallCntWidth * $slideSmallCnt.length +
            20 * ($slideSmallCnt.length - 1) +
            6;
          $slideSmallWrapper.width(slideSmallWrapperWidth);
          // $slideSmallWrapper.css({
          // position: 'absolute', left: `${slideWrapperWidth + 20}px`,
          // top: `${slideCntHeight / 2}px`,
          // transform: 'translateY(-50%)'
          // });
          $slideSmallWrapper.css({
            position: 'absolute',
            top: `${slideCntHeight / 2}px`,
            transform: 'translateY(-50%)'
          });

          $timeout(function() {
            $btnsCnt
              .find('.btn')
              .css({ lineHeight: `${$btnsCnt.height() - 4}px` });
            $btnsTermsCnt
              .find('.btn')
              .css({ lineHeight: `${$btnsTermsCnt.height() - 2}px` });
            scope.$evalAsync(function() {
              scope.fontCoef =
                (bannerCntHeight / 1520) * 3 < 1
                  ? (bannerCntHeight / 1520) * 3
                  : 1;
            });
            scope.$evalAsync(function() {
              scope.fontCoefTerms =
                ((bannerCntHeight / 1520) * 3 < 0.8
                  ? (bannerCntHeight / 1520) * 3
                  : 0.8) - 0.1;
            });
          }, 10);
        });

        window
          .$($window)
          .on('resize.staticWidgetEditor', function() {
            resetEditorLayout();
          })
          .resize();

        scope.$watch(() => $editorCnt.height(), resetEditorLayout);

        scope.$on('$destroy', function() {
          window.$($window).off('.staticWidgetEditor');
        });
      }
    };
  });
