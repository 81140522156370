/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.helpers')
  .directive('mediaDelete', function($window) {
    return {
      restrict: 'A',
      scope: {
        mediaSize: '@',
        display: '@'
      },
      link(scope, elem) {
        angular.element($window).on('resize.mediaDelete', function() {
          if ($window.innerWidth < +scope.mediaSize) {
            elem.css({ display: 'none' });
          } else {
            elem.css({ display: scope.display });
          }
        });

        scope.$on('$destroy', function() {
          angular.element($window).off('.mediaDelete');
        });
      }
    };
  });
