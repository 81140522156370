const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingViewCampaignSpecificCtrl', function(
    $q,
    $scope,
    $state,
    $rootScope,
    $controller,
    reportingFactory,
    analyticsFactory2,
    analyticsRequestsFactory,
    analyticsPresetsFactory
  ) {
    $controller('reportingViewAnalyticsCtrl', { $scope });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      blocks: analyticsFactory2.exportPresets.general,
      sites: []
    }));
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      campaignWidgetsType: null,
      handlersType: 'analyticsCampaign',
      itemNameLabel: 'campaignName',
      manageBlocks: {
        sitesFilter: []
      }
    }));
    state.blocksState = angular.copy(
      analyticsPresetsFactory.getSystemPreset(state.handlersType).data
        .analyticBlocks
    );

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      prepareDataForBlock(name, data) {
        let filters = null;
        let handler;
        if (name === 'dataByCitiesAndCountriesSiteLocation') {
          handler = 'getDataByCitiesAndCountries';
          filters = { dataFrom: 'siteLocation' };
        } else if (name === 'dataByCitiesAndCountriesSocialNetworks') {
          handler = 'getDataByCitiesAndCountries';
          filters = { dataFrom: 'socialNetworks' };
          state.chartsData.dataByCitiesAndCountriesSocialNetworks = data;
        } else if (name === 'dataByDaysAndSitesAggregated') {
          handler = 'getDataByDaysAndSites';
          filters = { aggregated: true };
          state.chartsData.dataByDaysAndSitesAggregated = data;
        } else if (name === 'dataByDaysAndSitesNotAggregated') {
          handler = 'getDataByDaysAndSites';
          filters = { aggregated: false };
        } else if (
          name === 'generalInfoCampaigns' &&
          state.campaignWidgetsType === 'poll'
        ) {
          handler = 'getPollInfo';
        } else {
          handler = handlers.getRequestHandlerByBlockName(name);
        }
        return handlers
          .getDataForBlock(handler, filters, name, data)
          .then(function(data) {
            if (name === 'dataByDaysAndSitesAggregated') {
              state.chartsData.dataByDaysAndSites = data;
            } else if (name === 'dataByCitiesAndCountriesSocialNetworks') {
              state.chartsData.dataByCitiesAndCountries = data;
            }
          });
      },
      prepareChartsData(report) {
        $scope.handlers.prepareChartsDataDefault(report);
        state.manageBlocks.sitesFilter = options.sites.map(function(site) {
          return site.name;
        });
      },
      onAggregatedChanged() {
        let filters;
        let data;
        state.chartsData.dataByDaysAndSites = state.blocksState
          .dataByDaysAndSites.aggregated
          ? angular.copy(state.chartsData.dataByDaysAndSitesAggregated)
          : angular.copy(
              state.chartsData.dataByDaysAndSitesNotAggregated
            );
        // eslint-disable-next-line prefer-const
        filters = {
          aggregated: state.blocksState.dataByDaysAndSites.aggregated
        };
        // eslint-disable-next-line prefer-const
        data = state.chartsData.dataByDaysAndSites.requested;
        handlers.getDataForBlock(
          'getDataByDaysAndSites',
          filters,
          'dataByDaysAndSites',
          data
        );
      },
      onChangeNotAggregatedFilter() {
        let filters;
        let handler;
        let data;
        state.blocksState.dataByDaysAndSites.currentFilter = this.setCurrentFilter(
          state.blocksState.dataByDaysAndSites
        );
        // eslint-disable-next-line prefer-const
        filters = { aggregated: false };
        // eslint-disable-next-line prefer-const
        data = state.chartsData.dataByDaysAndSitesNotAggregated.requested;
        // eslint-disable-next-line prefer-const
        handler = handlers.getRequestHandlerByBlockName('dataByDaysAndSites');
        handlers.getDataForBlock(handler, filters, 'dataByDaysAndSites', data);
      },
      refreshCurrentReport() {
        return $scope.handlers.refreshReport(state).then(function() {
          $scope.init();
        });
      },
      init: function init() {
        const noId = $scope.parentInit();
        if (!noId) {
          return false;
        }

        if ($rootScope.isHardlink) {
          return handlers
            .getReportByHardlink($state.params.id)
            .then(function(res) {
              // data node persists only if we have info for poll widgets
              state.campaignWidgetsType =
                res.data.report &&
                res.data.report.getGeneralInfo &&
                res.data.report.getGeneralInfo.data
                  ? 'poll'
                  : 'not_poll';

              return res;
            }, handlers.showNoWas);
        }

        if (!handlers.hasViewPermission()) {
          return false;
        }
        if (!$rootScope.isHardlink) {
          return reportingFactory
            .getCampaignSpecificReport(state)
            .then(function(res) {
              options.sites = res.data.settings.siteData;
              // data node persists only if we have info for poll widgets
              state.campaignWidgetsType =
                res.data.report &&
                res.data.report.getGeneralInfo &&
                res.data.report.getGeneralInfo.data
                  ? 'poll'
                  : 'not_poll';

              return res;
            }, handlers.showNoWas);
        }
        return undefined;
      }
    }));
  });
