const { moment, angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingListCtrl', function(
    $scope,
    $state,
    $stateParams,
    $location,
    $q,
    $timeout,
    $modal,
    $http,
    reportingFactory,
    sharedRequestsFactory,
    helpers,
    navigationPanel,
    breadcrumbs,
    REPORTING_CONFIG,
    APP_CONFIG
  ) {
    const config = REPORTING_CONFIG;
    const routeParams = $location
      .path()
      .split('/')
      .reverse();
    const user = $scope.currentUser;
    const permissionTypes = REPORTING_CONFIG.permissionTypes;

    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      currentPage: 1,
      filters: {
        endPeriod: moment().startOf('day'),
        startPeriod: moment()
          .startOf('day')
          .subtract(1, 'months'),
        searchString: ''
      },
      isAllReports: false,
      isBusy: true,
      itemsPerPage: 10,
      itemsTotal: 0,
      listType: reportingFactory.getReportsType(routeParams),
      noDataAvailable: false,
      reportLink: reportingFactory.getReportLink(routeParams),
      reports: [],
      searchDrodownValues: [],
      searchSources: ['id', 'name'],
      selectedItems: [],
      sortOrder: 'created_on:desc',
      noAnalyticsPermissions: ~['campaigns', 'sites'].indexOf(routeParams[1])
        ? !$scope.currentUser.userPermissions.reporting[routeParams[1]]
            .viewAnalytics
        : false
    });

    $scope.options = {
      actions: config.performActions,
      itemsOnPageValues: config.itemsOnPageValues,
      minPeriod: moment($scope.currentUser.systemCreationDate)
        .startOf('day')
        .valueOf()
    };

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      onFiltersChange: window._.debounce(init, 250),

      canCreate: $scope.hasPermission('create', state.listType),

      getList(searchMode) {
        return reportingFactory
          .reportsGetList(state, searchMode)
          .then(
            function(data) {
              state.itemsTotal = data.itemsTotal;
              state.reports = data.reports;
              return data;
            },
            function(res) {
              if (res.status === -1) return $q.reject(res);
              state.itemsTotal = 0;
              state.noDataAvailable = helpers.isDataNotAvailable(res.status);
              return undefined;
            }
          )
          .then(function() {
            handlers.getList.timeoutId = setTimeout(
              handlers.getList.bind(null, searchMode),
              config.refreshListTimeout
            );
          })
          .finally(function() {
            state.isBusy = false;
          });
      },

      actionPerform(item, action, isMultiAction) {
        const actionLabel = isMultiAction ? `${action}Multi` : action;
        return helpers
          .confirmationModal(
            `reporting.titles.${actionLabel}`,
            `reporting.messages.${actionLabel}`,
            item ? item.name : true
          )
          .result.then(function() {
            state.isBusy = true;
            reportingFactory
              .reportsPerformAction(
                state,
                isMultiAction ? state.selectedItems : [item.id],
                action
              )
              .then(
                function() {
                  return true;
                },
                function(res) {
                  $scope.showErrorModal(res.status);
                }
              )
              .finally(function() {
                handlers.getPage();
              });
          });
      },

      togglePublic(report) {
        const action = report.isPublic ? 'set_private' : 'set_public';
        return helpers
          .confirmationModal(
            `reporting.titles.${action}`,
            `reporting.messages.${action}`,
            report ? report.name : true
          )
          .result.then(function() {
            // eslint-disable-next-line no-param-reassign
            report.isActionsAllowed = false;
            reportingFactory
              .reportsPerformAction(state, [report.id], action)
              .then(
                function() {
                  // eslint-disable-next-line no-param-reassign
                  report.isPublic = !report.isPublic;
                  return true;
                },
                function(res) {
                  $scope.showErrorModal(res.status);
                }
              )
              .finally(function() {
                // eslint-disable-next-line no-param-reassign
                report.isActionsAllowed = true;
              });
          });
      },

      isReportChecked(id) {
        return (
          (state.selectedItems.indexOf(id) !== -1 && !state.isAllReports) ||
          (state.selectedItems.indexOf(id) === -1 && state.isAllReports)
        );
      },
      isCurrentPageSelected() {
        const { reports } = state;
        return (
          reports.length &&
          reports.every(report => handlers.isReportChecked(report.id))
        );
      },

      setSortOrder(sortValue) {
        state.sortOrder = sortValue;
        handlers.getPage();
      },

      getSortOrder(sortValue) {
        return sortValue === state.sortOrder;
      },

      selectReport(id) {
        helpers.toggleArrayElement(state.selectedItems, id);
      },

      selectAll() {
        state.isAllReports = true;
        state.selectedItems = [];
      },

      deselectAll() {
        state.isAllReports = false;
        state.selectedItems = [];
      },

      selectPage() {
        state.isAllReports = false;
        const dataToAdd = state.reports
          .map(function(item) {
            return !~state.selectedItems.indexOf(item.id) ? item.id : null;
          })
          .filter(function(item) {
            return item;
          });
        state.selectedItems = state.selectedItems.concat(dataToAdd);
      },

      getSelectedReportsCount() {
        const { isAllReports, itemsTotal, selectedItems } = state;
        return isAllReports
          ? itemsTotal - selectedItems.length
          : selectedItems.length;
      },

      handleSelectAllCheckbox() {
        // if all set is selected -> uncheck all set
        if (handlers.areAllReportsSelected()) {
          handlers.deselectAll();
        } else if (handlers.isCurrentPageSelected()) {
          // if all page is selected -> uncheck current page
          // eslint-disable-next-line no-param-reassign
          state.selectedItems = window._.difference(
            state.selectedItems,
            state.reports.map(i => i.id)
          );
          // eslint-disable-next-line no-param-reassign
          state.isAllReports = false;
        } else {
          // if neither all set nor all page is selected -> select currentPage
          handlers.selectPage();
        }
      },

      areAllReportsSelected() {
        const { itemsTotal } = state;
        return (
          itemsTotal > 0 &&
          handlers.getSelectedReportsCount(state) === itemsTotal
        );
      },

      getPage(searchMode) {
        state.noDataAvailable = false;
        state.isBusy = true;
        clearTimeout(handlers.getList.timeoutId);
        clearPollingRequests();
        handlers.getList(searchMode);
      },

      setTabs() {
        const tabs = config.tabs.lists[state.listType];
        const availableTabs = tabs.filter(function(tab) {
          return reportingFactory.hasPermissionsForType(
            $scope.currentUser,
            tab.name
          );
        });
        navigationPanel.setTabs(availableTabs);
        // Go to the first available tab, if we don't have access to current one or go to default
        if (state.listType === 'monitoring') {
          // do nothing
        } else if (!availableTabs.length) {
          $state.go(APP_CONFIG.defaultState);
        } else if (
          !window._.find(availableTabs, function(tab) {
            return tab.current === true;
          })
        ) {
          $state.go(availableTabs[availableTabs.length - 1].sref);
        }
        breadcrumbs.set({
          path: config.breadcrumbs[state.listType]
            .split('>')
            .map(name => ({ name }))
        });
      },

      getCreateLink() {
        return config.createLinks[state.listType];
      },

      onChangeSearchString(searchStr, filterType) {
        state.filters.searchString = searchStr;
        if (filterType.indexOf('getDropdown') > -1) {
          reportingFactory.searchInList(state).then(function(res) {
            state.searchDrodownValues = res.data.searchDropDown;
          });
        }
        if (filterType.indexOf('updateList') > -1) {
          const searchParam = filterType.split(':').pop();
          const searchMode = ~state.searchSources.indexOf(searchParam)
            ? searchParam
            : '';
          handlers.getPage(searchMode);
        }
      },

      goToView(id) {
        $state.go(reportingFactory.getReportRoute(routeParams), { id });
      },

      canDeleteReports(items) {
        return state.reports
          .map(function(item) {
            return ~items.indexOf(item.id) && item.isActionsAllowed
              ? item.id
              : null;
          })
          .some(function(item) {
            return item;
          });
      },
      /**
       * Shows whether to enable create report button
       * @return {boolean}
       */
      isCreateButtonEnabled() {
        const permissionType = permissionTypes[state.listType];
        const permissions =
          ((user.userPermissions || {}).reporting || {})[permissionType] || {};
        const result = permissions.viewAnalytics;
        return angular.isDefined(result) ? result : true;
      }
    });

    init();

    $scope.$on('$stateChangeStart', function() {
      clearTimeout(handlers.getList.timeoutId);
      sharedRequestsFactory.terminateDA(
        sharedRequestsFactory.reportsTerminateTypes.lists[state.listType]
      );
    });

    // Init function
    function init() {
      if (!isValidTimeFilters(state.filters)) return false;
      state.selectedItems = [];
      state.listType = reportingFactory.getReportsType(routeParams);
      state.currentPage = 1;
      handlers.setTabs();
      if (!hasViewPermission()) {
        return false;
      }
      return handlers.getPage();
    }

    // Has view permission
    function hasViewPermission() {
      return $scope.hasPermission('view', state.listType);
    }

    // >>>> Helpers <<<<
    // Check if time filters are valid
    function isValidTimeFilters(timeFilters) {
      return timeFilters.startPeriod <= timeFilters.endPeriod;
    }
    // >>>><<<<

    function clearPollingRequests() {
      $http.pendingRequests
        .filter(function(req) {
          return new RegExp(config.reportsGetList).test(req.url);
        })
        .forEach(function(req) {
          req.cancel && req.cancel.resolve('terminated');
        });
    }

    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.createReport',
        isDisabled: () => !$scope.handlers.isCreateButtonEnabled(),
        isShown: () => $scope.handlers.canCreate,
        clickHandler: () => {
          $state.go($scope.handlers.getCreateLink());
        }
      }
    ]);
  });
