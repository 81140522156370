/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm')
  .config(function($stateProvider, $urlRouterProvider, APP_CONFIG) {
    $urlRouterProvider.when('', '/campaigns');
    $urlRouterProvider.otherwise(function($injector) {
      const $state = $injector.get('$state');
      $state.go(APP_CONFIG.defaultState);
    });
    $urlRouterProvider.when(
      '/admin/monitoring/:id/places',
      '/admin/monitoring/:id/places/list'
    );

    $stateProvider
      .state('login', {
        template: '<react-login-page/>',
        abstract: true,
        controller: 'loginCtrl'
      })
      .state('login.signIn', {
        url: '/login?redirect',
        templateUrl: 'app/login/signIn.html',
        controller: 'signInCtrl'
      })
      .state('login.forgotPassword', {
        url: '/forgotPassword',
        templateUrl: 'app/login/forgotPassword.html',
        controller: 'forgotPasswordCtrl'
      })
      .state('login.resetPassword', {
        url: '/resetPassword?token',
        templateUrl: 'app/login/resetPassword.html',
        controller: 'resetPasswordCtrl'
      })
      .state('changePassword', {
        url: '/changePassword',
        templateUrl: 'app/login/changePassword.html',
        controller: 'changePasswordCtrl'
      })
      .state('profile', {
        url: '/profile',
        templateUrl: 'app/admin/profile/profileSection.html',
        controller: 'profileCtrl'
      })

      // / Hardlinks
      .state('hardlink', {
        abstract: true,
        url: '/hardlink'
      })
      .state('hardlink.view', {
        url: '/:hash'
      })

      // Sections
      .state('section', {
        abstract: true,
        template: '<div wcm-section></div>'
      })

      // campaigns
      .state('section.campaigns', {
        abstract: true,
        url: '/campaigns?debug',
        template: '<ui-view></ui-view>'
      })
      .state('section.campaigns.advertiserList', {
        url: '/classB?Status&Sort&State&SearchQuery&SearchStringMode&pg&ipp',
        templateUrl: 'app/sections/campaigns/list.html',
        controller: 'campaignsListCtrl',
        reloadOnSearch: false,
        data: {
          searchSources: ['id', 'name']
        }
      })
      .state('section.campaigns.partnerList', {
        url: '/classA?Status&Sort&State&SearchQuery&SearchStringMode&pg&ipp',
        templateUrl: 'app/sections/campaigns/list.html',
        controller: 'campaignsListCtrl',
        reloadOnSearch: false,
        data: {
          searchSources: ['id', 'name']
        }
      })
      .state('section.campaigns.create', {
        url: '/:type/create',
        templateUrl: 'app/sections/campaigns/view.html',
        controller: 'campaignsViewCtrl'
      })
      .state('section.campaigns.view', {
        abstract: true,
        url: '/:id',
        controller: 'campaignCtrl',
        template: '<ui-view></ui-view>'
      })
      .state('section.campaigns.view.details', {
        url: '/details',
        templateUrl: 'app/sections/campaigns/view.html',
        controller: 'campaignsViewCtrl'
      })
      .state('section.campaigns.view.analyticsCampaign', {
        url: '/analyticsCampaign',
        templateUrl: 'app/sections/campaigns/analytics.html',
        controller: 'analyticsSectionCtrl'
      })
      .state('section.campaigns.view.visitors', {
        url: '/viewers',
        templateUrl: 'app/sections/campaigns/campaignVisitors.html',
        controller: 'campaignVisitorsCtrl'
      })

      // sites

      .state('section.sites', {
        abstract: true,
        url: '/sites?debug',
        template: '<ui-view></ui-view>'
      })
      .state('section.sites.sitesList', {
        url: '/sites?Status&Sort&Partner&SearchQuery&SearchStringMode&pg&ipp',
        templateUrl: 'app/sections/sites/list.html',
        controller: 'sitesListCtrl',
        reloadOnSearch: false,
        data: {
          searchSources: ['id', 'name']
        }
      })
      .state('section.sites.create', {
        abstract: true,
        url: '/create',
        controller: 'siteCtrl',
        template: '<ui-view></ui-view>'
      })
      .state('section.sites.create.details', {
        url: '/details',
        templateUrl: 'app/sections/sites/details.html',
        controller: 'siteViewEditCtrl'
      })
      .state('section.sites.create.overrides', {
        url: '/system-overrides',
        templateUrl: 'app/sections/sites/systemOverrides.html',
        controller: 'siteSystemOverridesCtrl'
      })
      .state('section.sites.view', {
        abstract: true,
        url: '/:id',
        controller: 'siteCtrl',
        template: '<ui-view></ui-view>'
      })
      .state('section.sites.view.details', {
        url: '/details',
        templateUrl: 'app/sections/sites/details.html',
        controller: 'siteViewEditCtrl'
      })
      .state('section.sites.view.overrides', {
        url: '/system-overrides',
        templateUrl: 'app/sections/sites/systemOverrides.html',
        controller: 'siteSystemOverridesCtrl'
      })
      .state('section.sites.view.macExceptions', {
        url: '/macExceptions',
        templateUrl: 'app/sections/sites/macExceptions.html',
        controller: 'siteMacExceptionsCtrl'
      })
      .state('section.sites.view.analyticsSite', {
        url: '/analyticsSite',
        templateUrl: 'app/sections/sites/analytics.html',
        controller: 'analyticsSectionCtrl'
      })
      .state('section.sites.view.visitors', {
        url: '/visitors',
        templateUrl: 'app/sections/sites/visitors.html',
        controller: 'siteVisitorsCtrl'
      })

      // entities

      .state('section.entities', {
        abstract: true,
        url: '/entities',
        template: '<ui-view></ui-view>'
      })
      .state('section.entities.advertiserList', {
        url: '/classB?Status&Sort&Agency&SearchQuery&SearchStringMode&pg&ipp',
        templateUrl: 'app/sections/entities/list.html',
        controller: 'entitiesListCtrl',
        reloadOnSearch: false
      })
      .state('section.entities.partnerList', {
        url: '/classA?Status&Sort&Agency&SearchQuery&SearchStringMode&pg&ipp',
        templateUrl: 'app/sections/entities/list.html',
        controller: 'entitiesListCtrl',
        reloadOnSearch: false
      })
      .state('section.entities.create', {
        url: '/:type/create',
        templateUrl: 'app/sections/entities/view.html',
        controller: 'entitiesCtrl'
      })
      .state('section.entities.view', {
        url: '/:type/:id',
        templateUrl: 'app/sections/entities/view.html',
        controller: 'entitiesCtrl'
      })

      // widgets

      .state('section.widgets', {
        abstract: true,
        url: '/widgets',
        template: '<ui-view></ui-view>'
      })
      .state('section.widgets.widgetsList', {
        url:
          '/list?Status&Sort&State&ContentType&SearchQuery&SearchStringMode&pg&ipp',
        templateUrl: 'app/sections/widgets/list.html',
        controller: 'widgetsListCtrl',
        reloadOnSearch: false,
        data: {
          searchSources: ['id', 'name']
        }
      })

      // widgets create

      .state('section.widgets.create', {
        url: '/create',
        abstract: true,
        template: '<ui-view/>'
      })
      .state('section.widgets.create.static_dynamic', {
        url: '/static-dynamic',
        template: '<static-widget-editor>'
      })
      .state('section.widgets.create.slider', {
        url: '/slider',
        template: '<slider-widget-editor>'
      })
      .state('section.widgets.create.video', {
        url: '/video',
        template: '<video-widget-editor>'
      })
      .state('section.widgets.create.custom', {
        url: '/custom',
        template: '<custom-widget-editor>'
      })
      .state('section.widgets.create.poll', {
        url: '/poll',
        template: '<poll-widget-editor>'
      })

      // widgets edit

      .state('section.widgets.edit', {
        url: '/edit/:id',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('section.widgets.edit.static_dynamic', {
        url: '/static-dynamic',
        template: '<static-widget-editor>'
      })
      .state('section.widgets.edit.slider', {
        url: '/slider',
        template: '<slider-widget-editor>'
      })
      .state('section.widgets.edit.video', {
        url: '/video',
        template: '<video-widget-editor>'
      })
      .state('section.widgets.edit.custom', {
        url: '/custom',
        template: '<custom-widget-editor>'
      })
      .state('section.widgets.edit.poll', {
        url: '/poll',
        template: '<poll-widget-editor>'
      })

      // widgets preview

      .state('section.widgets.preview', {
        url: '/preview/:id/:lang',
        abstract: true,
        templateUrl: 'app/sections/widgets/view.html',
        controller: 'widgetsViewCtrl'
      })
      .state('section.widgets.preview.default', {
        url: '',
        templateUrl: 'app/sections/widgets/view-device.html'
      })
      .state('section.widgets.preview.device', {
        url: '/:device',
        templateUrl: 'app/sections/widgets/view-device.html'
      })

      // widgets view

      .state('section.widgets.view', {
        url: '/:id',
        abstract: true,
        templateUrl: 'app/sections/widgets/view.html',
        controller: 'widgetsViewCtrl'
      })
      .state('section.widgets.view.default', {
        url: '',
        templateUrl: 'app/sections/widgets/view-device.html'
      })
      .state('section.widgets.view.device', {
        url: '/:device',
        templateUrl: 'app/sections/widgets/view-device.html'
      })

      // admin

      .state('section.admin', {
        abstract: true,
        url: '/admin',
        template: '<ui-view></ui-view>',
        data: {
          requiredRole: ['administrator', 'ghost']
        }
      })
      .state('section.admin.users', {
        abstract: true,
        template: '<ui-view admin-section></ui-view>'
      })
      .state('section.admin.users.userList', {
        url: '/users?Status&Group&Sort&SearchQuery&SearchStringMode&pg&ipp',
        templateUrl: 'app/admin/users/userList.html',
        controller: 'usersListCtrl',
        data: {
          filtersApplyPrefix: 'user'
        },
        reloadOnSearch: false
      })
      .state('section.admin.users.action', {
        abstract: true,
        controller: 'userCtrl',
        templateUrl: 'app/admin/adminTabsSection.html'
      })
      .state('section.admin.users.action.new', {
        url: '/users/new',
        controller: 'userDetailsCtrl',
        templateUrl: 'app/admin/users/user.html'
      })
      .state('section.admin.users.action.view', {
        url: '/users/:id',
        controller: 'userDetailsCtrl',
        templateUrl: 'app/admin/users/user.html'
      })
      .state('section.admin.users.action.permissions', {
        url: '/users/:id/permissions',
        templateUrl: 'app/admin/users/user-permissions.html',
        controller: 'userPermissionsCtrl'
      })
      // client and content manager user with no permissions
      .state('noPermissions', {
        url: '/noPermissions',
        templateUrl: 'app/core/views/noPermissions.html',
        controller: 'noPermissions'
      })
      .state('section.admin.macExceptions', {
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('section.admin.macExceptions.macExceptionsList', {
        url: '/macExceptions',
        templateUrl: 'app/admin/macExceptions/macList.html',
        controller: 'macExceptionsListCtrl',
        data: {
          filtersApplyPrefix: 'macException',
          servlet: 'macexception'
        }
      })
      .state('section.admin.clientManagement', {
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('section.admin.clientManagement.clientList', {
        url: '/clientManagement',
        templateUrl: 'app/admin/clientManagement/clientList.html',
        controller: 'clientListCtrl',
        data: {
          filtersApplyPrefix: 'visitor'
        }
      })
      // .state('section.admin.serverLog', {
      //  url: '/log',
      //  templateUrl: 'app/sections/admin/serverLog.html',
      //  controller: 'serverLogCtrl',
      //  data: {
      //    noFilters: true
      //  }
      // })

      // system
      .state('system', {
        abstract: true,
        url: '/admin',
        templateUrl: 'app/admin/adminSection.html',
        data: {
          requiredRole: ['administrator', 'ghost']
        }
      })
      .state('system.license', {
        url: '/license',
        templateUrl: 'app/admin/license/license.html',
        controller: 'licenseCtrl',
        data: {
          availableInDemoMode: true
        }
      })
      .state('system.settings', {
        url: '/settings',
        templateUrl: 'app/admin/system_settings/settings.html',
        controller: 'systemSettingsCtrl',
        data: {
          availableInDemoMode: true,
          requiredRole: ['administrator', 'ghost']
        }
      })
      .state('system.dictionaries', {
        url: '/dictionaries',
        templateUrl: 'app/admin/dictionaries/dictionaries.html',
        controller: 'dictionariesCtrl'
      })
      .state('system.localization', {
        url: '/localization',
        abstract: true,
        resolve: {
          i18n: [
            'i18nFactory',
            function(i18nFactory) {
              return i18nFactory.init();
            }
          ]
        },
        templateUrl: 'app/admin/localization/localization.html',
        controller: 'localizationCtrl'
      })
      .state('system.localization.system', {
        url: '/system',
        templateUrl: 'app/admin/localization/localizationSettings.html',
        controller: 'localizationSettingsCtrl'
      })
      .state('system.localization.client', {
        url: '/client',
        templateUrl: 'app/admin/localization/localizationSettings.html',
        controller: 'localizationSettingsCtrl'
      })
      .state('system.localization.terms', {
        url: '/terms',
        templateUrl: 'app/admin/localization/terms.html',
        controller: 'termsCtrl'
      })
      .state('system.vendorSettings', {
        url: '/vendorSettings',
        templateUrl: 'app/admin/vendorSettings/vendorSettings.html',
        controller: 'vendorSettingsCtrl'
      })
      .state('system.logs', {
        abstract: true,
        url: '/logs',
        template: '<ui-view></ui-view>',
        controller: 'logsCtrl'
      })
      .state('system.logs.clientLogs', {
        url: '/clientLogs',
        templateUrl: 'app/admin/logs/clientLogs.html',
        controller: 'clientLogsCtrl'
      })
      .state('system.logs.systemLogs', {
        url: '/systemLogs',
        templateUrl: 'app/admin/logs/systemLogs.html',
        controller: 'systemLogsCtrl'
      })

      .state('monitoring', {
        abstract: true,
        url: '/monitoring',
        controller: 'abstractMonitoringCtrl',
        templateUrl: 'app/admin/adminSection.html',
        data: {
          authFn(user) {
            return user.userId === 'Admin' || user.userRole === 'GHOST';
          }
        }
      })

      .state('monitoring.collections', {
        abstract: true,
        url: '/collections',
        template: '<ui-view></ui-view>'
      })
      .state('monitoring.collections.default', {
        url: '',
        templateUrl: 'app/admin/monitoring/collections/collectionList.html',
        controller: 'monitoringCollectionListCtrl'
      })
      .state('monitoring.collections.manualList', {
        url: '/manual',
        templateUrl: 'app/admin/monitoring/collections/collectionList.html',
        controller: 'monitoringCollectionListCtrl'
      })
      .state('monitoring.collections.filteredList', {
        url: '/filtered',
        templateUrl: 'app/admin/monitoring/collections/collectionList.html',
        controller: 'monitoringCollectionListCtrl'
      })
      .state('monitoring.collections.view', {
        url: '/:type/:id',
        templateUrl: 'app/admin/monitoring/collections/collectionView.html',
        controller: 'monitoringCollectionCtrl'
      })
      .state('monitoring.collections.create', {
        url: '/:type/:id/create',
        templateUrl: 'app/admin/monitoring/collections/collectionProps.html',
        controller: 'monitoringCollectionPropsCtrl'
      })
      .state('monitoring.collections.props', {
        url: '/:type/:id/props',
        templateUrl: 'app/admin/monitoring/collections/collectionProps.html',
        controller: 'monitoringCollectionPropsCtrl'
      })

      .state('monitoring.visitors', {
        abstract: true,
        url: '/visitors',
        template: '<ui-view></ui-view>'
      })
      .state('monitoring.visitors.list', {
        url: '/list',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorsList.html',
        controller: 'monitoringVisitorsListCtrl'
      })
      .state('monitoring.visitors.view', {
        abstract: true,
        url: '/:id?reportId',
        controller: 'monitoringVisitorCtrl',
        templateUrl: 'app/admin/monitoring/visitors/monitoringVisitor.html'
      })
      .state('monitoring.visitors.view.general', {
        url: '',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorGeneral.html',
        controller: 'monitoringVisitorGeneralCtrl'
      })
      .state('monitoring.visitors.view.trends', {
        url: '/trends',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorTrends.html',
        controller: 'monitoringVisitorTrendsCtrl'
      })
      .state('monitoring.visitors.view.interests', {
        url: '/interests',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorInterests.html',
        controller: 'monitoringVisitorInterestsCtrl'
      })
      .state('monitoring.visitors.view.places', {
        abstract: true,
        url: '/places',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorPlaces.html',
        controller: 'monitoringVisitorPlacesCtrl'
      })
      .state('monitoring.visitors.view.places.default', {
        url: '',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorPlacesList.html'
      })
      .state('monitoring.visitors.view.places.list', {
        url: '/list',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorPlacesList.html'
      })
      .state('monitoring.visitors.view.places.map', {
        url: '/map',
        templateUrl:
          'app/admin/monitoring/visitors/monitoringVisitorPlacesMap.html'
      })

      // / ANALYTICS
      .state('analytics', {
        abstract: true,
        url: '/analytics?debug',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/core/analytics2/analyticsSection.html'
      })
      .state('analytics.campaigns', {
        url: '/campaigns',
        controller: 'campaignsSystemAnalyticsCtrl',
        templateUrl: 'app/systemAnalytics/campaigns.html'
      })
      .state('analytics.sites', {
        url: '/sites',
        controller: 'sitesSystemAnalyticsCtrl',
        templateUrl: 'app/systemAnalytics/sites.html'
      })

      // / REPORTING
      .state('reporting', {
        abstract: true,
        url: '/reporting?debug',
        controller: 'reportingCtrl',
        templateUrl: 'app/admin/adminSection.html'
      })

      // Reporting system-wide analytics
      .state('reporting.systemWide', {
        abstract: true,
        url: '/system-wide',
        template: '<ui-view></ui-view>'
      })

      // Reporting system-wide general
      .state('reporting.systemWide.general', {
        abstract: true,
        url: '/general',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.systemWide.general.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/general/list.html'
      })
      .state('reporting.systemWide.general.create', {
        url: '/create',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/general/create.html'
      })
      .state('reporting.systemWide.general.view', {
        url: '/:id',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/general/view.html'
      })

      // Reporting system-wide campaigns
      .state('reporting.systemWide.campaigns', {
        abstract: true,
        url: '/campaigns',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.systemWide.campaigns.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/campaigns/list.html'
      })
      .state('reporting.systemWide.campaigns.create', {
        url: '/create',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/campaigns/create.html'
      })
      .state('reporting.systemWide.campaigns.view', {
        url: '/:id',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/campaigns/view.html'
      })

      // Reporting system-wide sites
      .state('reporting.systemWide.sites', {
        abstract: true,
        url: '/sites',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.systemWide.sites.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/sites/list.html'
      })
      .state('reporting.systemWide.sites.create', {
        url: '/create',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/sites/create.html'
      })
      .state('reporting.systemWide.sites.view', {
        url: '/:id',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/sites/view.html'
      })

      // Reporting campaign-specific (analytics, viewers)
      .state('reporting.campaignSpecific', {
        abstract: true,
        controller: 'reportingCtrl',
        url: '/campaign-specific',
        template: '<ui-view></ui-view>'
      })

      // Reporting campaign-specific analytics
      .state('reporting.campaignSpecific.analytics', {
        abstract: true,
        url: '/analytics',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.campaignSpecific.analytics.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/campaign_specific/listAnalytics.html'
      })
      .state('reporting.campaignSpecific.analytics.create', {
        url: '/create',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/campaign_specific/createAnalytics.html'
      })
      .state('reporting.campaignSpecific.analytics.view', {
        url: '/:id',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/campaign_specific/viewAnalytics.html'
      })

      // Reporting campaign-specific viewers
      .state('reporting.campaignSpecific.viewers', {
        abstract: true,
        controller: 'reportingCtrl',
        url: '/viewers',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.campaignSpecific.viewers.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/campaign_specific/listViewers.html'
      })
      .state('reporting.campaignSpecific.viewers.create', {
        url: '/create',
        controller: 'reportingCreateViewersVisitorsCtrl',
        templateUrl: 'app/reporting/campaign_specific/createViewers.html'
      })
      .state('reporting.campaignSpecific.viewers.view', {
        url: '/:id',
        controller: 'reportingViewViewersVisitorsCtrl',
        templateUrl: 'app/reporting/campaign_specific/viewViewers.html'
      })

      // Reporting site-specific (analytics, visitors)
      .state('reporting.siteSpecific', {
        abstract: true,
        controller: 'reportingCtrl',
        url: '/site-specific',
        template: '<ui-view></ui-view>'
      })

      // Reporting site-specific analytics
      .state('reporting.siteSpecific.analytics', {
        abstract: true,
        url: '/analytics',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.siteSpecific.analytics.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/site_specific/listAnalytics.html'
      })
      .state('reporting.siteSpecific.analytics.create', {
        url: '/create',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/site_specific/createAnalytics.html'
      })
      .state('reporting.siteSpecific.analytics.view', {
        url: '/:id',
        controller: 'analyticsSectionCtrl',
        templateUrl: 'app/reporting/site_specific/viewAnalytics.html'
      })

      // Reporting site-specific visitors
      .state('reporting.siteSpecific.visitors', {
        abstract: true,
        controller: 'reportingCtrl',
        url: '/visitors',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.siteSpecific.visitors.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/site_specific/listVisitors.html'
      })
      .state('reporting.siteSpecific.visitors.create', {
        url: '/create',
        controller: 'reportingCreateViewersVisitorsCtrl',
        templateUrl: 'app/reporting/site_specific/createVisitors.html'
      })
      .state('reporting.siteSpecific.visitors.view', {
        url: '/:id',
        controller: 'reportingViewViewersVisitorsCtrl',
        templateUrl: 'app/reporting/site_specific/viewVisitors.html'
      })

      // Reporting monitoring visitors
      .state('reporting.monitoring', {
        abstract: true,
        controller: 'reportingCtrl',
        data: {
          authFn(user) {
            return user.userId === 'Admin' || user.userRole === 'GHOST';
          }
        },
        url: '/monitoring?debug',
        template: '<ui-view></ui-view>'
      })
      .state('reporting.monitoring.list', {
        url: '/list',
        controller: 'reportingListCtrl',
        templateUrl: 'app/reporting/monitoring/list.html'
      })
      .state('reporting.monitoring.create', {
        url: '/create',
        controller: 'reportingCreateViewersVisitorsCtrl',
        templateUrl: 'app/reporting/monitoring/create.html'
      })
      .state('reporting.monitoring.view', {
        url: '/:id',
        controller: 'reportingViewViewersVisitorsCtrl',
        templateUrl: 'app/reporting/monitoring/view.html'
      });
  });
