const { angular } = window;

angular
  .module('wcm.helpers')
  .directive('wcmOnScroll', function($window) {
    return {
      restrict: 'A',
      link(scope, el, attrs) {
        $window.addEventListener('scroll', scrollHandler, true);
        scope.$on('$destroy', () =>
          $window.removeEventListener('scroll', scrollHandler, true)
        );

        function scrollHandler(e) {
          scope.$eval(attrs.wcmOnScroll, { $event: e });
        }
      }
    };
  });
