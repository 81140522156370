import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ValidationMessage from './ValidationMessage';
import * as Login from './login.data';
import { ValidationError } from 'core/api';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={Login.forgotPasswordFormInitialValues}
      validationSchema={Login.forgotPasswordFormSchema}
      onSubmit={(values, { setSubmitting, setFieldError, setStatus }) => {
        Login.forgotPassword(values)
          .then(() => {
            setStatus({ successfullySubmitted: true });
          })
          .catch(err => {
            if (err instanceof ValidationError) {
              setFieldError('userId', t('login.restorePasswordError'));
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched, status, dirty, submitCount, isSubmitting }) => (
        <Form data-hook-id="form_forgotPassword">
          <div className="authorizing__inputs-group">
            <Field
              type="email"
              name="userId"
              id="email"
              className={classNames(
                'authorizing__input',
                'authorizing__input_email',
                {
                  authorizing__input_error: errors.userId && touched.userId
                }
              )}
              data-hook-id="t_email"
              placeholder={t('login.emailPlaceholder')}
              autoFocus
            />
            <div className="authorizing__msg-cnt">
              {errors.userId && (
                <ValidationMessage
                  message={t(errors.userId)}
                  hook="str_validation"
                />
              )}
              {status && status.successfullySubmitted && (
                <div
                  className="authorizing__success-msg"
                  data-hook-id="str_successMsg"
                >
                  {t('login.restorePasswordSuccess')}
                </div>
              )}
            </div>
          </div>
          <div
            className="authorizing__notes"
            data-hook-id="str_forgot-password-description"
          >
            {t('login.forgotDescription')}
          </div>
          <div className="authorizing__controls-group">
            <button
              className="button button_confirm authorizing__button"
              disabled={!dirty || isSubmitting}
              type="submit"
              data-hook-id="b_restore"
            >
              {t('login.restorePasswordButtonLabel')}
            </button>
            <Link
              className="button button_reject authorizing__button"
              to="/signIn"
              data-hook-id="a_cancel"
            >
              {t('login.cancelButtonLabel')}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
