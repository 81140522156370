/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const L = require('leaflet');

angular
  .module('wcm.geoLocation')
  .directive('wcmGeoMapSearchRadius', function(
    GEO_LOCATION_CONFIG,
    geoLocationFactory,
    $timeout
  ) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        geoMapClass: '@', // css class for map
        geoMapUpdateOnChange: '=?', // When var was change - update map without reloading (only clear and set new data)
        geoMapIsBusy: '=?', // is data for map preparing (loading) at that moment
        geoMapSearchInProgress: '=?', // Use when we need find coords by address (show that searching by geoCoords in progress now)
        geoMapSearchLocation: '=?' // Marker radius location
      },
      transclude: true,
      templateUrl: 'app/core/geo_location/mapSearchRadius.html',
      link(scope, el, attrs, ctrl, transclude) {
        // Tranclude
        transclude(scope, function(clone) {
          el.find('[geo-map-transclude]').append(clone);
        });

        // Create MapID -----
        const MAP_ID = 'wcm-geo-map-search';
        const geoCenter = geoLocationFactory.mapCenter;
        const geoMapEl = el.find('[geo-map]');
        geoMapEl.attr('id', MAP_ID);
        geoMapEl.addClass(scope.geoMapClass);

        // Init vars -----
        scope.markers = [];
        scope.markersLength = 0;
        const markerIcons = geoLocationFactory.markerIcons;
        const defaultRadius = GEO_LOCATION_CONFIG.defaultRadius;
        let map;
        let radiusMarker;
        let radiusCircle;
        let markersGroup;
        let preventClick = false;
        let clickTimer = 0;
        const clickDelay = 200;

        // Init scope vars -----
        scope.dataLoading = false;
        scope.noDataCoords = false;

        // Set map center for multi markers
        const setMapCenter = function() {
          map.fitBounds(markersGroup.getBounds());
          $timeout(function() {
            scope.dataLoading = false;
          }, 250);
        };
        const debouncedSetMapCenter = window._.debounce(setMapCenter, 1000);

        // Map handlers
        // eslint-disable-next-line no-multi-assign
        const handlers = (scope.handlers = {
          whichIcon(type) {
            if (!type || type === 'default') {
              return markerIcons.default;
            } else if (type === 'inactive') {
              return markerIcons.defaultInactive;
            } else if (type === 'active') {
              return markerIcons.defaultActive;
            }
            return undefined;
          },

          addRadiusMarkerCircle(e, marker) {
            removeOtherMarkers();
            // eslint-disable-next-line no-nested-ternary
            scope.geoMapSearchLocation.coords = e
              ? e.latlng
              : Array.isArray(marker)
              ? { lat: marker[0], lng: marker[1] }
              : handlers.getMarkerCoords(marker);
            scope.noDataCoords = false;
            window._.defer(function() {
              scope.$apply();
            }); // map radius coords
            radiusMarker = L.marker(
              [
                scope.geoMapSearchLocation.coords.lat,
                scope.geoMapSearchLocation.coords.lng
              ],
              { draggable: 'true' }
            );
            radiusMarker.setIcon(markerIcons.radius);
            handlers.dragRadiusMarkerCircle(radiusMarker);
            radiusCircle = L.circle(
              [
                scope.geoMapSearchLocation.coords.lat,
                scope.geoMapSearchLocation.coords.lng
              ],
              (scope.geoMapSearchLocation.radius || defaultRadius) * 1000,
              {
                weight: 1,
                color: '#57b6fa',
                fillColor: '#57b6fa',
                fillOpacity: 0.2
              }
            );
            map.addLayer(radiusMarker).addLayer(radiusCircle);
            setMapCenterOnMarker(scope.geoMapSearchLocation);
            return radiusMarker;
          },

          // Set marker drag event
          dragRadiusMarkerCircle(marker) {
            marker
              .on('dragstart', function() {
                map.removeLayer(radiusCircle);
              })
              .on('dragend', function(e) {
                scope.geoMapSearchLocation.coords = e.target.getLatLng();
                radiusCircle = L.circle(
                  [
                    scope.geoMapSearchLocation.coords.lat,
                    scope.geoMapSearchLocation.coords.lng
                  ],
                  scope.geoMapSearchLocation.radius * 1000,
                  {
                    weight: 1,
                    color: '#57b6fa',
                    fillColor: '#57b6fa',
                    fillOpacity: 0.2
                  }
                );
                map.addLayer(radiusCircle);
                setMapCenterOnMarker(scope.geoMapSearchLocation);
              });
            return marker;
          },

          addressToNumbers(address) {
            if (!address) return false;
            let lat;
            let lng;
            const coords = address.split(' ');
            if (coords.length !== 2) return false;
            lat = +coords[0].trim(); // eslint-disable-line prefer-const
            lng = +coords[1].trim(); // eslint-disable-line prefer-const
            if (!lat || !lng || Number.isNaN(lat) || Number.isNaN(lng)) {
              return false;
            }
            return [lat, lng];
          },

          getMarkerCoords(marker) {
            if (marker.lat && marker.lon) {
              return { lat: marker.lat, lng: marker.lon };
            }
            return marker.getLatLng();
          },

          updateMap() {
            $timeout(function() {
              map.invalidateSize();
            }, 500);
          }
        });

        // Create markers from MapObjects and add it on map -----
        const addWrongMarkerOnMap = function(site) {
          let coords;
          let marker;
          let icon;

          // Set coords array
          if (typeof site === 'object' && site.lat && site.lon) {
            coords = [+site.lat, +site.lon];
            // eslint-disable-next-line no-param-reassign
            site = geoLocationFactory.setSiteInfoByAddress(site);
          }

          // If no coords -> return null
          if (!coords) return null;

          // Set marker default icon
          // eslint-disable-next-line prefer-const
          icon = angular.copy(markerIcons.default);

          // eslint-disable-next-line prefer-const
          marker = L.marker(coords, { icon, isActive: false, data: site });
          marker.addTo(markersGroup);

          debouncedSetMapCenter();

          return marker;
        };

        // Add markers on map when we need only single marker -----
        // FIXME: This function mutates parameter marker. Make it to be pure
        const addMarkerOnMapForSingleData = function(marker, index) {
          let icon;

          if (scope.markers.length > 1) {
            marker = addWrongMarkerOnMap(marker, index); // eslint-disable-line no-param-reassign
            icon = markerIcons.errorInactive;
            marker.options.draggable = false; // eslint-disable-line no-param-reassign
            addClickListener(marker, removeOtherMarkers);
          } else if (scope.markers.length === 1) {
            icon = markerIcons.radius;
            // eslint-disable-next-line no-param-reassign
            marker = handlers.addRadiusMarkerCircle(null, marker);
          }
          marker.setIcon(icon);
          return marker;
        };

        // Set marker click event
        function addClickListener(marker, action) {
          marker.on('click', function(e) {
            if (!action) {
              if (e.target.getPopup() && !e.target.getPopup()._isOpen) {
                e.target.setIcon(scope.handlers.whichIcon('default'));
              } else {
                e.target.setIcon(scope.handlers.whichIcon('active'));
              }
            } else {
              action(marker);
            }
          });
          return marker;
        }

        // Remove all markers except current
        function removeOtherMarkers(marker) {
          scope.markers = [];
          markersGroup.clearLayers();
          if (radiusMarker && radiusCircle) {
            map.removeLayer(radiusMarker).removeLayer(radiusCircle);
            radiusMarker = null;
            radiusCircle = null;
          }
          if (marker) {
            handlers.addRadiusMarkerCircle(null, marker);
          }
        }

        // Set map center for one marker
        function setMapCenterOnMarker(marker) {
          let zoom = 3;
          const radius = Number.isNaN(+marker.radius)
            ? defaultRadius
            : marker.radius;
          if (radius <= 0.5) {
            zoom = 15;
          } else if (radius <= 2) {
            zoom = 13;
          } else if (radius <= 5) {
            zoom = 12;
          } else if (radius <= 15) {
            zoom = 10;
          } else if (radius <= 200) {
            zoom = 8;
          }
          map.setView(marker.coords, zoom);
          $timeout(function() {
            scope.dataLoading = false;
          }, 250);
        }

        // Main Init function -----
        const init = function(mapList) {
          removeOtherMarkers();
          scope.dataLoading = true;
          scope.noDataCoords = false;
          scope.markers = mapList;
          scope.markers = scope.markers.map(addMarkerOnMapForSingleData);
          if (!scope.markers.length) {
            scope.dataLoading = false;
            scope.noDataCoords = true;
          }
        };

        // Init map -----
        map = L.map(MAP_ID, {
          zoomControl: false,
          center: geoCenter, // geo center of Europe
          zoom: 3,
          doubleClickZoom: true
        });

        L.control
          .zoom({
            position: 'bottomright'
          })
          .addTo(map);

        L.tileLayer(
          'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoianNoZXBhcmQiLCJhIjoiY2lvdjdlaHZlMDA1eHc5a2plMzJ5MjNreCJ9.4PeBqnAssuDVn2vC0lD5kA',
          {
            attribution:
              'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
            maxZoom: 18,
            minZoom: 3,
            id: 'mapbox.streets'
          }
        ).addTo(map);

        map
          .on('click', function(event) {
            clickTimer = setTimeout(function() {
              if (!preventClick) {
                handlers.addRadiusMarkerCircle(event);
              }
              preventClick = false;
            }, clickDelay);
          })
          .on('dblclick', function() {
            clearTimeout(clickTimer);
            preventClick = true;
            map.doubleClickZoom.enable();
          })
          .on('dragend', function() {})
          .on('popupopen', function() {})
          .on('popupclose', function() {});

        handlers.updateMap();

        // Create marker layers Group -----
        markersGroup = new L.FeatureGroup().addTo(map);

        // Find markers by geo_location searching (on address or radius changes)
        scope.$watch(
          function() {
            return {
              address: scope.geoMapSearchLocation.address,
              radius: scope.geoMapSearchLocation.radius
            };
          },
          function(newValue, oldValue) {
            if (
              newValue.address &&
              newValue.radius &&
              newValue.address !== oldValue.address
            ) {
              const addressIsCoords = handlers.addressToNumbers(
                newValue.address
              );
              if (addressIsCoords && addressIsCoords.length === 2) {
                handlers.addRadiusMarkerCircle(null, addressIsCoords);
              } else {
                geoLocationFactory
                  .getCoordsByAddress('', newValue.address)
                  .then(function(data) {
                    init(data || []);
                    scope.geoMapSearchInProgress = false;
                  });
              }
            } else if (newValue.radius && radiusCircle) {
              radiusCircle.setRadius(newValue.radius * 1000);
              setMapCenterOnMarker(scope.geoMapSearchLocation);
            }
          },
          true
        );
      }
    };
  });
