/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.campaigns')
  .factory('campaignVisitorsFactory', function(
    $q,
    $rootScope,
    helpers,
    requestFactory,
    sharedRequestsFactory,
    ANALYTICS_REQUESTS_CONFIG,
    CAMPAIGNS_CONFIG
  ) {
    const config = CAMPAIGNS_CONFIG;
    const INTERACTION_SITES_LIMIT = 19;
    const TOPS_OPTIONS = [10, 50, 100];
    const MILISECONDS_IN_MINUTE = 60000;

    const mapStateToRequest = function(state) {
      const filters = state.filters || {};
      const data = {
        filterByCampaignId: +state.campaignId || 0,
        filterByPeriod: [filters.startPeriod, filters.endPeriod].map(function(
          moment
        ) {
          const _moment = angular.copy(moment);
          // cast to utc midnight
          return _moment
            .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
            .startOf('day')
            .valueOf();
        }),
        filterByTimeframe: [
          filters.startTimeFrame || 0,
          filters.endTimeFrame || 0
        ],
        filterByDays: filters.daysOfWeek || [],
        filterBySearchString: filters.searchString || '',
        filterByGender: filters.genders || [],
        filterByAge: filters.ages || [],
        filterByDeviceType: filters.deviceTypes || [],
        filterByOs: filters.OS || [],
        filterByTop: +filters.top || 0,
        filterByInteractionType: filters.interactionType || '',
        sortOrder: state.sortOrder
      };
      return data;
    };

    const getAvailableValues = function getAvailableValues() {
      return requestFactory
        .post(config.campaignsServlet, config.campaignGetAvailableValues, {
          type: ''
        })
        .then(function(res) {
          return res.data ? res.data : {};
        })
        .then(function(values) {
          const interactionTypes = values.interactionTypes;
          const _values = values.visitors;
          return {
            ages: _values.age,
            genders: _values.gender,
            deviceTypes: _values.deviceType,
            OS: _values.os,
            tops: TOPS_OPTIONS,
            interactionTypes
          };
        });
    };

    // var applyFilters = function applyFilters(state) {
    //   return requestFactory
    //       .post(
    //         config.campaignVisitorsServlet,
    //         config.campaignVisitorsApplyFilters,
    //         mapStateToRequest(state),
    //         {},
    //         {skipErrors: [502, 504, 204, 244]}
    //       )
    //       .then(function(res) {
    //         if (res.status === 204 || res.status === 244) {
    //           return $q.reject(res)
    //         }
    //         state.noDataAvailable = helpers.isDataNotAvailable(res.status);
    //         return res.data && res.data.itemsTotal ? res.data.itemsTotal : 0
    //       }, function(res) {
    //         return $q.reject(res);
    //       })
    // };

    const getList = function getList(state) {
      const offset = (state.currentPage - 1) * state.itemsPerPage;
      let filters = {
        sortOrder: state.sortOrder,
        filterOffset: offset,
        filterItemsNumber: state.itemsPerPage,
        terminateType: sharedRequestsFactory.terminateTypes[state.terminateType]
      };
      filters = angular.extend(filters, mapStateToRequest(state));
      return requestFactory
        .post(
          config.campaignVisitorsServlet,
          config.campaignVisitorsGetList,
          filters
        )
        .then(
          function(res) {
            if (
              res.status === 204 ||
              res.status === 244 ||
              !res.data.visitors ||
              !res.data.itemsTotal
            ) {
              return $q.reject(res);
            }
            return res.data || {};
          },
          function(res) {
            return $q.reject(res);
          }
        )
        .then(
          function(data) {
            // set interactionSites data for view
            // eslint-disable-next-line no-param-reassign
            data.visitors = prepareInteractionSites(data.visitors);

            // if empty set or full set -- return this set, or add empty entries to have full set
            // eslint-disable-next-line no-param-reassign
            data.visitors = helpers.fillListWithEmptyStrings(
              data.visitors,
              state.itemsPerPage
            );
            return data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    function prepareInteractionSites(visitors) {
      return visitors[0] && Array.isArray(visitors[0].interactionSites)
        ? visitors.map(function(visitor) {
            // eslint-disable-next-line no-param-reassign
            visitor.interactionSitesMore =
              visitor.interactionSites.length - INTERACTION_SITES_LIMIT;
            // eslint-disable-next-line no-param-reassign
            visitor.interactionSitesTooltipData = visitor.interactionSites
              .slice(0, INTERACTION_SITES_LIMIT)
              .join(', ');
            return visitor;
          })
        : visitors;
    }

    return {
      getAvailableValues,
      mapStateToRequest,
      prepareInteractionSites,
      getList
    };
  });
