/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.sections')
  .factory('sectionsFactory', function(SECTIONS_CONFIG, helpers) {
    const config = SECTIONS_CONFIG;

    function authorizeRoute(userObj, route) {
      // allow any section for admins and ghosts
      if (~['administrator', 'ghost'].indexOf(userObj.userRole.toLowerCase()))
        return true;
      /*
       * Get the most specific auth route to check
       * i.e. section.campaigns.advertiserList vs section.campaign.
       * More specific route appears after less specific one in SECTIONS_CONFIG.authRoutes array
       * so we check the last found route
       */
      const targetAuthRoute = config.authRoutes
        .map(function(authRoute) {
          return route.indexOf(authRoute) === 0 ? authRoute : undefined;
        })
        .filter(function(item) {
          return !!item;
        })
        .slice(-1)[0];

      if (!targetAuthRoute) return false;

      const targetSectionName = targetAuthRoute
        .split('.')
        .slice(1)
        .join('.');

      return hasPermissionForSection(userObj, targetSectionName);
    }

    function getFallbackRoute(userObj, requestedRoute) {
      const routePath = requestedRoute.split('.');
      const rootSection = routePath[1];

      const filtered = config.authRoutes.filter(function(item, idx, array) {
        const nextEl = array[idx + 1];
        return nextEl ? !~nextEl.indexOf(`${item}.`) : true;
      });

      const sorted = [];

      // sort auth routes so routes relative to target route will be considered as more suitable
      filtered.forEach(function(item) {
        if (~item.indexOf(rootSection)) {
          sorted.unshift(item);
        } else {
          sorted.push(item);
        }
      });

      let allowedSection = window._.find(sorted, function(section) {
        return hasPermissionForSection(
          userObj,
          section
            .split('.')
            .slice(1)
            .join('.')
        );
      });

      allowedSection = isListView(allowedSection)
        ? allowedSection
        : getListStateForSection(allowedSection);
      return allowedSection || false;
    }

    function hasPermissionForSection(user, sectionPath) {
      if (!user) return false;

      const sections = helpers.getProp(
        user,
        'userPermissions.sectionsAvailability'
      );

      if (!sections) return false;

      const sectionInfo = helpers.getProp(sections, sectionPath);

      if (!sectionInfo) return false;

      return angular.isObject(sectionInfo)
        ? Object.keys(sectionInfo).some(function(subSection) {
            return !!sectionInfo[subSection];
          })
        : sectionInfo;
    }

    function isListView(route) {
      return /List$/.test(route);
    }

    function getListStateForSection(sectionName) {
      switch (sectionName) {
        case 'section.widgets':
          return 'section.widgets.widgetsList';
        case 'section.sites':
          return 'section.sites.sitesList';
        default:
          return '';
      }
    }

    return {
      authorizeRoute,
      getFallbackRoute,
      hasPermissionForSection
    };
  });
