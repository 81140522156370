/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.storage')
  .factory('localStorage', function($window, $log) {
    const get = function get(key) {
      try {
        return angular.fromJson($window.localStorage.getItem(key));
      } catch (ex) {
        return $log.error(ex);
      }
    };

    const set = function set(key, value) {
      $window.localStorage.setItem(key, JSON.stringify(value));
      return value;
    };

    const remove = function remove(key) {
      $window.localStorage.removeItem(key);
    };

    const clear = function clear() {
      $window.localStorage.clear();
    };

    const removeByMask = function removeByMask(mask) {
      for (let i = 0; i < $window.localStorage.length; i++) {
        const key = $window.localStorage.key(i);
        if (`${key}`.indexOf(mask) === 0) {
          remove(key);
        }
      }
    };

    return {
      get,
      set,
      clear,
      remove,
      removeByMask
    };
  });
