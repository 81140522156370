/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const portraitIcons = require.context(
  'assets/images/portrait-icons',
  false,
  /\.png$/
);
const { moment } = window;

angular
  .module('wcm.campaigns')
  .controller('campaignAnalyticsCtrl', function(
    $q,
    $scope,
    $state,
    $rootScope,
    $filter,
    $modal,
    analyticsFactory2,
    analyticsRequestsFactory,
    sitesFactory,
    widgetsFactory,
    navigationPanel,
    breadcrumbs,
    APP_CONFIG,
    CONSTANTS_ICONS
  ) {
    const state = $scope.state;
    const options = $scope.options;
    const handlers = $scope.handlers;
    const user = $scope.currentUser;
    $scope.state.loadedImages = {};

    options.printTitle = 'Campaign-specific analytics';
    options.analyticsPeriodLimit = user.analyticsPeriodLimit;

    const setManageBlocksConfig = function() {
      state.manageFilterSettings.sitesFilter = [
        {
          title: 'classAEntities',
          name: 'entity',
          values: state.manageBlocks.sitesFilter.entities,
          trackBy: 'id'
        },
        {
          title: 'tagsSites',
          name: 'tags',
          values: state.manageBlocks.sitesFilter.tags,
          trackBy: 'name',
          filterTypes: ['tagsClasses', 'tagsTypes']
        }
      ];
    };

    handlers.isVisible = function(blockName) {
      return state.blocksState && state.blocksState[blockName];
    };

    handlers.getIconClass = function(item) {
      return CONSTANTS_ICONS[item];
    };

    handlers.getPortraitIcon = type => portraitIcons(`./${type}`);

    handlers.changePortraitsFilter = function(blockName) {
      // eslint-disable-next-line no-multi-assign
      const curFilter = (state.blocksState[
        blockName
      ].currentFilter = handlers.setCurrentFilter(
        state.blocksState[blockName]
      ));
      state.chartsData[blockName].view = analyticsFactory2.preparePortraitsData(
        state.chartsData[blockName].requested.data,
        curFilter,
        state.blocksState[blockName].hideUnknown,
        state.chartsData[blockName].requested.unknownIndex
      );
      handlers.onPresetAltered();
    };

    handlers.getAvailableValues = function(analyticsType) {
      state.analyticsType =
        analyticsType || $state.current.name.split('.').pop();

      const prepareData = function prepareData(
        array,
        typeName,
        excelType,
        type
      ) {
        return array.map(function(item) {
          // eslint-disable-next-line no-param-reassign
          item.className = typeName;
          // add missing type property for site tags
          // eslint-disable-next-line no-param-reassign
          if (type) item.type = typeName;
          // eslint-disable-next-line no-param-reassign
          if (excelType) item.excelType = excelType;
          return item;
        });
      };

      if (!options.availableValues) {
        options.availableValues = {};
      }

      const sitesPromise = analyticsRequestsFactory
        .getAllSitesForCampaign(
          state.analyticsType,
          state.filters.campaignIds,
          state.permissionType
        )
        .then(
          function(data) {
            state.noWas = false;
            options.availableValues.sites = prepareData(data.sites, 'sites');
            options.availableValues.sites.forEach(function(item) {
              // eslint-disable-next-line no-param-reassign
              item.link = `#/sites/${item.id}/details`;
            });
          },
          function() {
            state.noWas = true;
          }
        );

      const entitiesPromise = analyticsRequestsFactory
        .getAllEntitiesForCampaign(
          state.analyticsType,
          state.filters.campaignIds
        )
        .then(
          function(data) {
            state.noWas = false;
            options.availableValues.partners = prepareData(
              data.partners,
              'entities',
              'entities'
            );
          },
          function() {
            state.noWas = true;
          }
        );

      const sitesTagsPromise = analyticsRequestsFactory
        .getTagsForSitesForCampaign(
          state.analyticsType,
          state.filters.campaignIds
        )
        .then(
          function(data) {
            state.noWas = false;
            options.availableValues.sitesTags = prepareData(
              data.tagsTypes,
              'tagsTypes',
              'tags',
              true
            ).concat(
              prepareData(data.tagsClasses, 'tagsClasses', 'tags', true)
            );
          },
          function() {
            state.noWas = true;
          }
        );

      return $q.all([sitesTagsPromise, entitiesPromise, sitesPromise]);
    };

    handlers.onValuesSelected = function(
      blockName,
      availableValues,
      checkedIds,
      disableRequests
    ) {
      switch (blockName) {
        case 'sitesFilter':
          state.manageBlocks.sitesFilter.sites = availableValues;
          state.manageBlocks.sitesFilter.checkedItems = checkedIds;
          state.filters.siteIds = handlers.filterBy(
            state.manageBlocks.sitesFilter.sites,
            'id'
          );
          if (!disableRequests) handlers.onFiltersChange();
          break;
        default:
          console.log('not specified manage block name');
          break;
      }
    };

    handlers.initManageBlocks = function(manageBlockFilters) {
      state.isBusy = false;

      if (state.noWas) return false;

      const sitesFilter = {
        sites: angular.copy(options.availableValues.sites),
        entities: angular.copy(options.availableValues.partners),
        tags: angular.copy(options.availableValues.sitesTags),
        checkedItems: manageBlockFilters
          ? manageBlockFilters.sitesFilter.checkedItems
          : []
      };

      if (!manageBlockFilters) {
        sitesFilter.sites.forEach(function(site) {
          // eslint-disable-next-line no-param-reassign
          site.checked = true;
        });
      } else {
        sitesFilter.sites.forEach(function(site) {
          if (~manageBlockFilters.sitesFilter.checkedItems.indexOf(site.id)) {
            // eslint-disable-next-line no-param-reassign
            site.checked = true;
          }
        });
      }

      state.filters.siteIds = handlers.filterBy(sitesFilter.sites, 'id');
      state.filters.campaignIds = [+$state.params.id];

      handlers.registerManageBlock('sitesFilter', sitesFilter);

      setManageBlocksConfig();
      return undefined;
    };

    handlers.hasPermission = function(type) {
      switch (type) {
        case 'export':
          return $scope.campaign && $scope.campaign.permissions.exportAnalytics;
        default:
          return false;
      }
    };

    // Set 'true' for widgetId when widget image loaded
    handlers.widgetDataLoaded = function(widgetId) {
      $scope.state.loadedImages[widgetId] = true;
    };

    handlers.reinitMinMaxPeriod = function() {
      // set available Min Date - according to systemCreationDate
      options.availableMinDate = moment($scope.currentUser.systemCreationDate)
        .startOf('day')
        .valueOf();

      // endPeriod - according to campaign state and availability of workingDates
      // only for campaign specific case
      options.endPeriod =
        $scope.campaign.state === 'Finished'
          ? moment(
              $scope.campaign.endDate !== -1 || $scope.campaign.endDate !== 0
                ? $scope.campaign.modifiedDate
                : $scope.campaign.workingDates.slice(-1).pop()
            ).startOf('day')
          : null;

      // maxPeriod - will be set as endPeriod after press "Select max"
      options.maxPeriod = options.endPeriod || state.filters.endPeriod;
      // set available Min Date
      options.availableMinDate = window
        .moment($scope.currentUser.systemCreationDate)
        .startOf('day')
        .valueOf();
      // set default Min Period
      options.minPeriod = window.moment($scope.currentUser.systemCreationDate);

      // calculate Min Period
      options.minPeriod = analyticsFactory2.getAnalyticsMinPeriod(
        moment(options.minPeriod).startOf('day'),
        moment(state.filters.endPeriod).startOf('day'),
        options.analyticsPeriodLimit
      );
      options.startPeriod = options.minPeriod;
    };

    handlers.isDataBySitesShowMoreBtnDisabled = function() {
      const blockStateDataBySites = state.blocksState.dataBySites;
      const chartsDataBySites = state.chartsData.dataBySites;
      // eslint-disable-next-line max-len
      return (
        (blockStateDataBySites.toggleFilters.zeroData &&
          chartsDataBySites.requested.data[0][
            blockStateDataBySites.currentFilter
          ].data.columns.length <= 10) ||
        // eslint-disable-next-line max-len
        (!blockStateDataBySites.toggleFilters.zeroData &&
          chartsDataBySites.requested.data[0][
            blockStateDataBySites.currentFilter
          ].data.columns.filter(function(column) {
            return column[1];
          }).length <= 10)
      );
    };

    handlers.isDataBySitesCollapseBtn = function() {
      return (
        $scope.chartsOptions.dataBySites.visibleEntries > 10 &&
        // eslint-disable-next-line max-len
        ((state.blocksState.dataBySites.toggleFilters.zeroData &&
          state.chartsData.dataBySites.requested.data[0][
            state.blocksState.dataBySites.currentFilter
          ].data.columns.length <
            $scope.chartsOptions.dataBySites.visibleEntries) ||
          // eslint-disable-next-line max-len
          (!state.blocksState.dataBySites.toggleFilters.zeroData &&
            state.chartsData.dataBySites.requested.data[0][
              state.blocksState.dataBySites.currentFilter
            ].data.columns.filter(function(column) {
              return column[1];
              // FIXME: @uvarov, wtf?
            }).length <= 10)) // < $scope.chartsOptions.dataBySites.visibleEntries)
      );
    };

    // Init analytics
    //
    $scope.init = function() {
      state.filters.campaignIds = [+$state.params.id];
      state.itemName = $scope.campaign.name;
      handlers.resetManageBlocks();
      handlers.reinitMinMaxPeriod();
      breadcrumbs.set({
        path: [
          { name: 'campaigns' },
          { name: 'list' },
          {
            name:
              $scope.campaign.type.toLowerCase() === 'partner'
                ? 'classA'
                : 'classB',
            sref: `section.campaigns.${$scope.campaign.type.toLowerCase()}List`
          },
          { name: 'view' }
        ],
        itemName: `${$scope.campaign.id}. ${$scope.campaign.name}`
      });
      widgetsFactory
        .getWidgetsForCampaign(+$state.params.id)
        .then(function(widgets) {
          state.campaignWidgetsType = widgets[0].contentType;
          widgets.forEach(function(widget) {
            $scope.state.loadedImages[widget.id] = false;
          });
          handlers.getAvailableValues().then(function() {
            $scope.$parent.init(options);
            handlers.initManageBlocks();
          });
        });
    };
    $scope.downloadSelectedSites = function() {
      const sites = {};
      state.manageBlocks.sitesFilter.sites.forEach(item => {
        if (item.checked) {
          sites[item.id] = true;
        }
      });
      sitesFactory.downloadSitesListForCampaign(sites);
    };

    // Check files from file
    $scope.uploadSitesForSelect = function(type, file) {
      sitesFactory.uploadSitesListForCampaign(type, file).then(
        function(res) {
          if (!res.siteIds) {
            return;
          }
          let newItem;
          state.manageBlocks.sitesFilter.sites = state.manageBlocks.sitesFilter.sites.map(
            function(item) {
              newItem = angular.copy(item);
              if (~res.siteIds.indexOf(item.id)) {
                newItem.checked = true;
              } else {
                newItem.checked = false;
              }
              return newItem;
            }
          );
          state.filters.siteIds = handlers.filterBy(
            state.manageBlocks.sitesFilter.sites,
            'id'
          );
        },
        function(res) {
          if (res === 'wrongExe') {
            const labels = {
              message: 'campaigns.modals.xls.errorXlsMsg',
              title: 'campaigns.modals.xls.errorXlsTitle'
            };
            infoModal(labels);
          }
        }
      );
    };

    function infoModal(labels) {
      const i18n = $filter('i18n');
      const scope = $scope.$new();
      scope.msg = i18n(labels.message);
      scope.title = i18n(labels.title);
      $modal.open({
        templateUrl: 'app/core/views/infoErrorGlobal.html',
        windowClass: 'wcm-msg',
        scope
      });
    }

    // Init when get data by campaign
    //
    $scope.$watch('campaign', function(val) {
      if (val) {
        $scope.init();
      }
    });
  });
