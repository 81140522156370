/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.entities')
  .factory('entitiesFactory', function(
    $rootScope,
    requestFactory,
    ENTITIES_CONFIG,
    helpers
  ) {
    const getOwnersList = function(filterByType) {
      return requestFactory.post(
        ENTITIES_CONFIG.entitiesServlet,
        ENTITIES_CONFIG.entityGetOwnersList,
        { filterByType }
      );
    };

    const getList = function(filters, page, ipp, sortOrder) {
      const requestData = angular.extend(
        {},
        filters,
        helpers.getPaginationFilters(page, ipp, sortOrder)
      );
      return requestFactory
        .post(
          ENTITIES_CONFIG.entitiesServlet,
          ENTITIES_CONFIG.getList,
          requestData
        )
        .then(function(res) {
          return res.data && res.data.entities ? res.data.entities : [];
        })
        .then(function(entities) {
          $rootScope.$broadcast('entities:list-updated', entities);
          return entities;
        });
    };

    const getData = function(id, type) {
      return requestFactory.post(
        ENTITIES_CONFIG.entitiesServlet,
        ENTITIES_CONFIG.getData,
        { id, type }
      );
    };

    const saveData = function(entity) {
      return requestFactory.post(
        ENTITIES_CONFIG.entitiesServlet,
        ENTITIES_CONFIG.saveData,
        entity
      );
    };

    const moveToArchive = function(id, type) {
      return requestFactory.post(
        ENTITIES_CONFIG.entitiesServlet,
        ENTITIES_CONFIG.moveToArchive,
        { id: +id, type }
      );
    };

    const recoverFromArchive = function(id, type) {
      return requestFactory.post(
        ENTITIES_CONFIG.entitiesServlet,
        ENTITIES_CONFIG.recoverFromArchive,
        { id: +id, type }
      );
    };

    const remove = function(id, type) {
      return requestFactory.post(
        ENTITIES_CONFIG.entitiesServlet,
        ENTITIES_CONFIG.remove,
        { id: +id, type }
      );
    };

    const getAgencies = function() {
      return requestFactory.post(
        ENTITIES_CONFIG.entitiesServlet,
        ENTITIES_CONFIG.getAgencies,
        {}
      );
    };

    return {
      getOwnersList,
      getList,
      getData,
      saveData,
      moveToArchive,
      recoverFromArchive,
      remove,
      getAgencies
    };
  });
