/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

angular
  .module('wcm.analytics2')
  .directive('daysOfWeekSelect', function(analyticsFactory2) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        isDisabledDays: '='
      },
      require: '?ngModel',
      templateUrl: 'app/core/analytics2/daysOfWeekSelect.html',
      link(scope, el, attrs, ngCtrl) {
        scope.options = {
          daysOfWeekValues: analyticsFactory2.getDaysOfWeekValues()
        };

        scope.state = {
          days: []
        };

        scope.sync = function() {
          ngCtrl.$setViewValue(
            angular.copy(scope.options.daysOfWeekValues)
          );
        };

        scope.areAllDaysSelected = function(days) {
          return scope.selectedDaysCount(days) === 7;
        };

        scope.selectedDaysCount = function(days) {
          return angular.isArray(days)
            ? scope.getSelectedDays(days).length
            : 0;
        };

        scope.getSelectedDays = function(days) {
          return days.filter(isDaySelected);
        };

        scope.selectAllDays = function(days) {
          // eslint-disable-next-line no-param-reassign
          days.forEach(function(day) {
            day.selected = true;
          });
          scope.sync();
        };

        let prevViewValue = [];
        scope.$watchCollection(
          function() {
            return ngCtrl.$modelValue;
          },
          function(val, prevVal) {
            if (val && prevVal) {
              const newViewValue = formatter(val);
              if (!arraysEqual(prevViewValue, newViewValue)) {
                // eslint-disable-next-line no-param-reassign,no-multi-assign
                ngCtrl.$viewValue = prevViewValue = newViewValue;
                ngCtrl.$render();
              }
            }
          }
        );

        // eslint-disable-next-line no-param-reassign
        ngCtrl.$render = function() {
          scope.state.days =
            ngCtrl.$viewValue || scope.options.daysOfWeekValues;
        };

        function isDaySelected(day) {
          return !!day.selected;
        }

        const parser = function(viewValue) {
          return viewValue.filter(isDaySelected).map(function(val) {
            return val.id;
          });
        };

        function formatter(modelValue) {
          if (!modelValue) return undefined;
          return scope.options.daysOfWeekValues.map(function(day) {
            // eslint-disable-next-line no-param-reassign
            day.selected = !!~modelValue.indexOf(day.id);
            return day;
          });
        }

        ngCtrl.$parsers.push(parser);
        ngCtrl.$formatters.push(formatter);
      }
    };
  });
