const { angular } = window;

angular.module('wcm.errorHandling').factory('errorHelpers', function() {
  const requestRegex = /.*\/(\w+)\?action=(\w+)$/;

  // Check is current action relate to analytics
  const isContainAction = function(url, requests) {
    const requestParse = requestRegex.exec(url);
    return (
      requestParse && requestParse[2] && ~requests.indexOf(requestParse[2])
    );
  };

  const getServletAndActionFromUrl = function(url) {
    const requestParse = requestRegex.exec(url);
    return {
      servlet: requestParse && requestParse[1],
      action: requestParse && requestParse[2]
    };
  };

  return {
    isContainAction,
    getServletAndActionFromUrl
  };
});
