/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const { location } = window;

angular
  .module('wcm', [
    'wcm.core',
    'wcm.config',
    'ngSanitize',
    'wcm.login',
    'wcm.sections',
    'wcm.admin',
    'wcm.settings',
    'wcm.tags',
    'wcm.labels',
    'match',
    'ckeditor',
    'datePicker',
    'wcm.reporting',
    'wcm.debug',
    'wcm.noPermissions',
    'react'
  ])
  .run(function(
    $rootScope,
    $state,
    $window,
    $location,
    $injector,
    APP_CONFIG,
    AUTH_EVENTS,
    ERROR_HANDLING_CONFIG,
    $timeout,
    $q,
    $http,
    auth,
    i18nFactory,
    session,
    helpers,
    hardlinks
  ) {
    // $rootScope.authChecked = false;
    let bypassStateChangeCheck = false;

    i18nFactory
      .init()
      .then(function(i18n) {
        $rootScope.appReady = true;
        $rootScope.i18n = i18n;
      })
      .catch(function() {
        helpers.redirectToErrorPage();
      });

    [AUTH_EVENTS.loginSuccess, AUTH_EVENTS.sessionRestored].forEach(function(
      event
    ) {
      $rootScope.$on(event, function() {
        i18nFactory.updateLocalizationState().then(function() {
          i18nFactory.setPreferredLocaleFor('system');
        });
      });
    });

    [AUTH_EVENTS.sessionNotFound, AUTH_EVENTS.logoutSuccess].forEach(function(
      event
    ) {
      $rootScope.$on(event, function() {
        i18nFactory.updateLocalizationState().then(function() {
          i18nFactory.setPreferredLocaleFor('login');
        });
      });
    });

    $rootScope.$on('$stateChangeStart', function(
      event,
      next,
      toParams,
      fromState,
      fromParams
    ) {
      // early exit if need to bypass state change check
      if (bypassStateChangeCheck) return true;

      // allow login states anyway
      if (next.name.indexOf('login') === 0) {
        return true;
      }

      // if next state is Hardlink -> allow redirect by hardlink
      const isHardlink = hardlinks.isHardlinkState(next, toParams);
      if (isHardlink) {
        return true;
      }

      // prevent state transition if we dont have user yet
      if (!$rootScope.currentUser) {
        event.preventDefault();
        auth.isAuthenticated().then(
          function() {
            $state.go(next, toParams);
          },
          function() {
            // substr remove leading # symbol.
            // cant use $location.url() here, since it returns previous state of url string
            $state.go('login.signIn', { redirect: location.hash.substr(1) });
          }
        );
        return true;
      }

      // disable restricted state transitions in demo mode
      if (session.isDemoMode()) {
        if (!!next.data && next.data.availableInDemoMode) {
          return true;
        }
        event.preventDefault();
        $state.go('system.license');
        return true;
      }

      // authorize state transition if required in routes
      if (!!next.data && next.data.requiredRole) {
        if (!auth.authorize(next.data.requiredRole)) {
          location.replace(ERROR_HANDLING_CONFIG.errorPageUrl);
        }
      }

      // custom authorization function
      if (!!next.data && next.data.authFn) {
        if (!next.data.authFn($rootScope.currentUser)) {
          event.preventDefault();
          // eslint-disable-next-line no-param-reassign
          next =
            fromState && !fromState.abstract
              ? fromState
              : APP_CONFIG.defaultState;
          // eslint-disable-next-line no-param-reassign
          toParams = fromParams || {};
          $state.go(next, toParams);
        }
      }

      /**
       * Delegate further authorization to responsible module
       * Module's factory must implement `authorizeRoute` and `getFallbackRoute`
       */
      const factory = $injector.get(mapRouteToModuleFactoryName(next.name));
      if (!isFactorySuitableForAuth(factory)) {
        throw new Error(
          `${mapRouteToModuleFactoryName(
            next.name
          )} doesn't implement required authorization functions`
        );
      }
      if (!factory.authorizeRoute($rootScope.currentUser, next.name)) {
        event.preventDefault();
        const routeRoot = next.name.split('.')[0];
        const possibleFallback = APP_CONFIG.modulesFallbackOrder
          .map(function(moduleName) {
            const factory = $injector.get(
              mapRouteToModuleFactoryName(moduleName)
            );
            if (!isFactorySuitableForAuth(factory)) {
              throw new Error(
                `${mapRouteToModuleFactoryName(
                  next.name
                )} doesn't implement required authorization functions`
              );
            }
            return factory.getFallbackRoute($rootScope.currentUser, next.name);
          })
          .filter(function(item) {
            return !!item;
          })
          .sort(function(a, b) {
            if (~a.indexOf(routeRoot)) return -1;
            if (~b.indexOf(routeRoot)) return 1;
            return 0;
          });
        if (possibleFallback[0]) {
          $state.go(possibleFallback[0]);
        } else {
          bypassStateChangeCheck = true;
          $state.go('noPermissions');
        }
      }
      return undefined;
    });

    function isFactorySuitableForAuth(factory) {
      return (
        angular.isFunction(factory.authorizeRoute) &&
        angular.isFunction(factory.getFallbackRoute)
      );
    }

    // Terminate pending requests
    //
    $rootScope.$on('$stateChangeStart', function() {
      const pendingRequests = $http.pendingRequests.filter(function(pending) {
        return pending.method === 'POST';
      });
      // if(pendingRequests.length > APP_CONFIG.maxRequestsPerTab) {
      pendingRequests.forEach(function(pending) {
        if (pending.cancel && !~pending.url.indexOf('terminate')) {
          pending.cancel.resolve('Cancel!');
        }
      });
      // }
    });

    $rootScope.$on('$stateChangeSuccess', function() {
      bypassStateChangeCheck = false;
    });

    function mapRouteToModuleFactoryName(route) {
      const routePath = route.split('.');
      switch (routePath[0]) {
        case 'section':
          return 'sectionsFactory';
        case 'analytics':
          return 'analyticsFactory2';
        case 'reporting':
          return 'reportingFactory';
        case 'system':
          return 'systemFactory';
        case 'monitoring':
          return 'systemFactory';
        case 'profile':
          return 'profileFactory';
        default:
          throw new Error(`There is no mapping to factory for route: ${route}`);
      }
    }
  });
