const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateViewersVisitorsCtrl', function(
    $scope,
    $state,
    $controller,
    reportingFactory,
    campaignVisitorsFactory,
    dropdown,
    REPORTING_CONFIG
  ) {
    $scope.inReporting = true;

    $controller('reportingCreateCtrl', { $scope });

    const config = REPORTING_CONFIG;
    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      blocks: [],
      items: [],
      itemsOnPageValues: config.itemsOnPageValues,
      sortDirections: config.sortDirections,
      sortTypes: config.sortTypes
    }));
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      currentItem: {},
      sortDirection: options.sortDirections[0],
      sortType: options.sortTypes[0],
      sortOrder: `${options.sortTypes[0]}:${options.sortDirections[0]}`,
      type: $scope.state.listType,
      permissionType: 'viewAnalyticsVisitorList'
    }));

    angular.extend($scope.state.filters, {
      itemsPerPage: ''
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      getFilterLabel: dropdown.getMultiSelectLabel,
      save() {
        state.isBusy = true;
        state.sortOrder = `${state.sortType}:${state.sortDirection}`;
        return reportingFactory
          .createViewersVisitorsReport(state)
          .then(function() {
            state.editMode = false;
            $state.go(state.callbackRoute);
          })
          .finally(function() {
            state.isBusy = false;
          });
      },
      getAvailableValues() {
        return campaignVisitorsFactory
          .getAvailableValues()
          .then(function(data) {
            state.isBusy = false;
            $scope.options = angular.extend(
              $scope.options,
              angular.copy(data)
            );
            $scope.state.filters = angular.extend(
              {},
              $scope.state.filters,
              window._.omit(data, 'tops')
            );
            state.filters.itemsPerPage = options.itemsOnPageValues[0];
          });
      }
    }));

    $scope.init = function() {
      handlers.setTabs();
      state.report.blockList = angular.copy(options.blocks);
    };
  });
