/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.sites').constant('SITES_CONFIG', {
  sitesServlet: 'sites',
  uploadSitesServlet: 'cms/uploadSitesListForCampaign',
  analyticsSiteServlet: 'analyticsSite',
  getList: 'siteGetList',
  getData: 'siteGetData',
  save: 'siteSave',
  getAvailableValues: 'siteGetAvailableValues',
  moveToArchive: 'siteMoveToArchive',
  recoverFromArchive: 'siteRecoverFromArchive',
  siteGetListForCampaign: 'siteGetListForCampaign',
  getSitesListSimple: 'getSitesListSimple',
  getSitesListByGeoFilter: 'getSitesListByGeoFilter',
  getSitesLoading: 'getSitesLoading',
  getSettingsOverrides: 'siteGetSettingsOverrides',
  saveSettingsOverrides: 'siteSaveSettingsOverrides',
  removeSite: 'siteRemove',
  checkMacDuplication: 'siteCheckDuplicate',
  getAllSites: 'getAllSites',
  getDataByTags: 'getDataByTags',
  downloadSitesListForCampaign: 'downloadSitesListForCampaign',

  siteVisitorsServlet: 'analyticsVisitor',
  // siteVisitorsApplyFilters: 'visitorApplyFilters',
  siteVisitorsGetList: 'visitorGetList'
});
