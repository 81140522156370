/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.helpers').directive('wcmFocused', function($parse) {
  return {
    link(scope, element, attrs) {
      let boolSetter = $parse(attrs.wcmFocused).assign;
      attrs.$observe('wcmFocused', function(val) {
        boolSetter = $parse(val).assign;
      });
      element
        .focus(function() {
          scope.$apply(function() {
            boolSetter(scope, true);
          });
        })
        .blur(function() {
          scope.$apply(function() {
            boolSetter(scope, false);
          });
        });
    }
  };
});
