/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.sites')
  .directive('macDuplicationValidation', function($q, $parse, sitesFactory) {
    return {
      require: 'ngModel',
      link(scope, el, attrs, ngModel) {
        const errorMessageSetter = $parse(
          attrs.macDuplicationValidationErrorData
        ).assign;
        // eslint-disable-next-line no-param-reassign
        ngModel.$asyncValidators.macDuplication = function(
          modelValue,
          viewValue
        ) {
          const promise = sitesFactory.checkMacDuplication(
            attrs.macDuplicationValidation,
            viewValue
          );
          promise.catch(function(data) {
            errorMessageSetter(scope, data);
          });
          return promise;
        };
      }
    };
  });
