export default class ValidationError extends Error {
  constructor(rej, data = {}, ...props) {
    super(...props);
    this.name = 'Validation Error';
    this.status = rej.response.status;
    this.data = data;
    this.serverMessage = rej.response.data.serverMessage;
    this.response = rej.response;
  }
}
