/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.auth', ['wcm.http', 'wcm.storage'])
  .constant('AUTH_CONFIG', {
    sessionParams: [
      'userId',
      'analyticsPeriodLimit',
      'sessionId',
      'sessionTimeout',
      'timeZone',
      'avatarImage',
      'userRole',
      'launchMode',
      'localeId',
      'userPermissions',
      'systemCreationDate'
    ],
    loginServlet: 'login',
    loginAction: 'login',
    logoutAction: 'userLogout',
    forgotPasswordAction: 'passwordForgot',
    resetPasswordAction: 'passwordReset',
    changePassword: 'passwordChange'
  })
  .constant('AUTH_EVENTS', {
    loginSuccess: 'auth:login-success',
    loginFailed: 'auth:login-failed',
    logoutSuccess: 'auth:logout-success',
    sessionRestored: 'auth:session-restored',
    sessionNotFound: 'auth:session-not-found',
    logoutFailed: 'auth:logout-failed',
    sessionTimeout: 'auth:session-timeout',
    notAuthenticated: 'auth:not-authenticated',
    notAuthorized: 'auth:not-authorized',
    sessionDestroyed: 'auth:session-destroyed',
    sessionCreated: 'auth:session-created'
  })
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('authInterceptor');
  });
