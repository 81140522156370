/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.helpers').directive('autoGrow', function() {
  return {
    restrict: 'A',
    link(scope, el) {
      // Auto grow element
      const autoGrow = function() {
        el[0].style.height = '5px'; // eslint-disable-line no-param-reassign
        el[0].style.height = `${el[0].scrollHeight}px`; // eslint-disable-line no-param-reassign
      };

      scope.$watch(
        function() {
          return el[0].value.length;
        },
        function() {
          autoGrow();
        },
        true
      );

      // el.on('mousewheel DOMMouseScroll', function(event, delta) {
      //  event.stopPropagation();
      //  //event.preventDefault();
      // });

      autoGrow();
    }
  };
});
