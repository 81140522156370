/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.searchBar')
  .controller('searchBarCtrl', function($scope, $sce) {
    $scope.showResult = false;
    $scope.selectedItem = undefined;
    $scope.$watch('searchQuery', function(newVal) {
      if (!!newVal && newVal.length > 0) {
        $scope.onChange({
          searchQuery: $scope.searchQuery,
          updateType: 'search:getDropdown',
          filterName: 'SearchQuery'
        });
      }
    });
    $scope.$watch('focused', function(newVal) {
      if (!!newVal && $scope.searchQuery.length > 0) {
        $scope.onChange({
          searchQuery: $scope.searchQuery,
          updateType: 'search:getDropdown',
          filterName: 'SearchQuery'
        });
      }
    });
    $scope.$watch('dropDownItems', function() {
      $scope.selectedItem = undefined;
      if (!angular.isDefined($scope.dropDownItems)) {
        return true;
      }
      if ($scope.searchQuery.length > 0) {
        $scope.showResult = true;
      }
      return undefined;
    });

    $scope.isNumeric = function(value) {
      return !Number.isNaN(parseFloat(value)) && Number.isFinite(value);
    };

    $scope.highlight = function(text, search, isId) {
      if (!search) {
        return $sce.trustAsHtml(text);
      }
      return $sce.trustAsHtml(
        text.replace(
          new RegExp((isId ? '^' : '') + search, 'gi'),
          '<b class="search-bar_highlight">$&</b>'
        )
      );
    };

    $scope.updateView = function(source) {
      let updateType = 'search:updateList';
      if (source) {
        updateType = `${updateType}:${source}`;
      }
      $scope.onChange({
        searchQuery: $scope.searchQuery,
        updateType,
        filterName: 'SearchQuery'
      });
      // $scope.searchQuery = "";
      $scope.showResult = false;
    };

    $scope.$on('$stateChangeStart', function() {
      $scope.searchQuery = '';
    });
  });
