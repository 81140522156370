const { angular } = window;

angular
  .module('wcm.core')
  .factory('manageBlockFactory', function($rootScope, $modal, helpers) {
    function getFilteredIds(filterSteps, valuesType) {
      function getIdsToCheckForStep(filterSets) {
        const idsToCheck = filterSets.reduce(function(acc, set) {
          const values = set.values;
          const ids = values.reduce(function(acc, value) {
            return acc.concat(value[valuesType]);
          }, []);
          return acc.concat(ids);
        }, []);
        return window._.unique(idsToCheck);
      }

      return window._.intersection.apply(
        null,
        filterSteps.map(getIdsToCheckForStep)
      );
    }

    function collapseValues(checkedItems, filterSets, valuesType) {
      let collapsedItems = [];
      let itemsToRemove = [];

      const checkedIds = checkedItems.map(function(item) {
        return item.id;
      });

      filterSets.forEach(function(filterSet) {
        filterSet.values.forEach(function(filterItem) {
          const filterElements = filterItem[valuesType];
          if (
            filterElements &&
            filterElements.length &&
            window._.intersection(checkedIds, filterElements).length ===
              filterElements.length
          ) {
            itemsToRemove = itemsToRemove.concat(filterElements);
            collapsedItems = collapsedItems.concat({
              name: filterItem.name,
              checked: true,
              className: filterItem.className
            });
          }
        });
      });

      return collapsedItems.concat(
        checkedItems.filter(function(item) {
          return !~itemsToRemove.indexOf(item.id);
        })
      );
    }

    function openFiltersModal(
      availableValues,
      filterSteps,
      filters,
      valuesType,
      title
    ) {
      const scope = $rootScope.$new(true);
      scope.availableValues = helpers.shallowCopy(availableValues);
      scope.filterSteps = filterSteps;
      scope.filters = filters;
      scope.valuesType = valuesType;
      scope.title = title;

      return $modal.open({
        scope,
        openedClass: 'isEdit',
        windowClass: 'modal_manage-block',
        templateUrl:
          'app/core/components/manageBlock/manageBlockFiltersModal.html',

        controller($scope, $modalInstance) {
          $scope.onValuesChange = function(availableValues) {
            scope.availableValues = availableValues;
          };

          $scope.save = function() {
            $scope.submitted = true;
            $modalInstance.close(scope.availableValues);
          };

          $scope.cancel = function() {
            $scope.submitted = false;
            $modalInstance.dismiss();
          };
        }
      });
    }

    function indexOfStepWithFiltersOfType(filterSteps, type) {
      return window._.findIndex(filterSteps, function(step) {
        return step.some(function(set) {
          return set.name === type && set.values.length;
        });
      });
    }

    // parse legacy filters data sets
    function prepareFilters(filterSets) {
      let resultSets = [];
      filterSets.forEach(function(filterSet) {
        if (!filterSet.filterTypes) {
          resultSets = resultSets.concat([filterSet]);
          return true;
        }
        const newSets = {};

        if (filterSet.values.length) {
          filterSet.values.forEach(function(value) {
            if (!newSets[value.type]) {
              newSets[value.type] = createFilterSet(
                filterSet.name,
                value.type,
                filterSet.trackBy
              );
            }
            newSets[value.type].values.push(value);
          });
        } else {
          filterSet.filterTypes.forEach(function(filterTitle) {
            newSets[filterTitle] = createFilterSet(
              filterSet.name,
              filterTitle,
              filterSet.trackBy
            );
          });
        }

        resultSets = resultSets.concat(
          Object.keys(newSets).reduce(function(acc, key) {
            acc.push(newSets[key]);
            return acc;
          }, [])
        );
        return false;
      });

      return resultSets;
    }

    function createFilterSet(name, title, trackBy, values) {
      return {
        name,
        title,
        trackBy,
        values: values || []
      };
    }

    function limitTo(limit, entries) {
      return {
        entries: entries.slice(0, limit),
        extra: Math.max(entries.length - limit, 0)
      };
    }

    return {
      openFiltersModal,
      getFilteredIds,
      collapseValues,
      limitTo,
      prepareFilters,
      indexOfStepWithFiltersOfType
    };
  });
