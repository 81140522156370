/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('serverLogCtrl', function(
    $scope,
    $location,
    $timeout,
    $anchorScroll,
    requestFactory
  ) {
    $scope.getLogData = function() {
      // we don't use factory, because this is temporary tab
      requestFactory.post('common', 'getLogData', {}).success(function(data) {
        $scope.serverLog = data.logEntries;

        $location.hash('bottom');
        setTimeout(function() {
          $anchorScroll();
        }, 100);
      });
    };

    $scope.logClass = function(item) {
      if (item.indexOf('ERROR') > -1) {
        return 'server-log_error';
      } else if (item.indexOf('WARNING') > -1) {
        return 'server-log_warning';
      } else if (item.indexOf('INFO') > -1) {
        return 'server-log_info';
      }
      return '';
    };

    $scope.onPanelActivated = function() {
      $scope.getLogData();
    };
  });
