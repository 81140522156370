/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.widgets').factory('widgetMigration_1', function() {
  const migrationHelpers = {
    progressbar(widgetMarkup) {
      return window._.extend({}, widgetMarkup.progressbar, {
        style: {
          wrapper: {
            borderColor: withFallbackStyle(
              'progressWrapper',
              parseRgbaFromString(widgetMarkup.styles['progressbar-wrapper'])
            )
          },
          track: styleString2Object(widgetMarkup.styles['progressbar-track']),
          bar: styleString2Object(widgetMarkup.styles.progressbar)
        }
      });
    },

    normalizeI18n(localization) {
      return Object.keys(localization).reduce(function(acc, langId) {
        // eslint-disable-next-line no-param-reassign
        acc[langId.toLowerCase()] = localization[langId].reduce(function(
          i18n,
          curObj
        ) {
          // eslint-disable-next-line no-param-reassign
          i18n[curObj.title] = curObj.value;
          return i18n;
        },
        {});
        return acc;
      }, {});
    },

    foregroundImages(images) {
      return Object.keys(images)
        .filter(function(imgIdx) {
          return imgIdx !== 'img_00';
        })
        .sort()
        .map(function(imgIdx) {
          return images[imgIdx];
        });
    },

    elements(widgetMarkup) {
      return ['progressBar', 'header', 'terms'].reduce(function(acc, elem) {
        // eslint-disable-next-line no-param-reassign
        acc[elem] = !!~widgetMarkup.elements.indexOf(elem.toLowerCase());
        return acc;
      }, {});
    },

    buttons(i18n, widgetMarkup) {
      return widgetMarkup.buttons.map(function(button) {
        return {
          type: button.type || 'next',
          link: button.redirect,
          i18n: Object.keys(i18n).reduce(function(acc, langId) {
            // eslint-disable-next-line no-param-reassign
            acc[langId.toLowerCase()] = i18n[langId][button.id];
            return acc;
          }, {}),
          style: {
            normal: withFallbackStyle(
              'button',
              fixBorderProps(styleString2Object(widgetMarkup.styles[button.id]))
            ),
            hover: withFallbackStyle(
              'button',
              fixBorderProps(styleString2Object(widgetMarkup.hover[button.id]))
            )
          }
        };
      });
    },

    dots(widgetMarkup) {
      return {
        normal: withFallbackStyle(
          'dot',
          styleString2Object(widgetMarkup.styles.slideControls)
        ),
        active: withFallbackStyle(
          'dot',
          styleString2Object(widgetMarkup.active.slideControls)
        )
      };
    }
  };

  function migrate(widget) {
    const widgetType =
      widget.contentType === 'static_dynamic' ? 'static' : widget.contentType;

    if (widgetType === 'custom') {
      return window.$.extend(true, {}, widget, {
        widgetData: {
          client: {
            widgetType
          }
        }
      });
    }

    const widgetData = widget.widgetData;
    const oldWidgetMarkup = widgetData.client;
    const normalizedI18n = migrationHelpers.normalizeI18n(
      oldWidgetMarkup.localization
    );
    const migratedMarkup = {
      schemaVersion: 1,
      backgroundType: 'image',
      widgetType,
      title: oldWidgetMarkup.title,
      progressbar: migrationHelpers.progressbar(oldWidgetMarkup),
      backgroundImage: oldWidgetMarkup.images.img_00,
      foregroundImages: migrationHelpers.foregroundImages(
        oldWidgetMarkup.images
      ),
      elements: migrationHelpers.elements(oldWidgetMarkup),
      terms: {
        style: withFallbackStyle(
          'terms',
          styleString2Object(oldWidgetMarkup.styles.terms)
        )
      },
      buttons: migrationHelpers.buttons(normalizedI18n, oldWidgetMarkup)
    };

    if (migratedMarkup.widgetType === 'slider') {
      migratedMarkup.slider = {
        dots: {
          style: migrationHelpers.dots(oldWidgetMarkup)
        }
      };
    }

    return window._.extend({}, widget, {
      widgetData: {
        client: migratedMarkup,
        files: widgetData.files,
        images: widgetData.images
      }
    });
  }

  function parseRgbaFromString(str) {
    if (!angular.isString(str)) return '';
    const re = /(rgba\(.*\))/gi;
    return str.match(re)[0];
  }

  function fixBorderProps(styleObj) {
    let borderColor;
    try {
      borderColor = parseRgbaFromString(styleObj.border);
    } catch (ex) {
      console.warn('cannot parse border color prop');
    }
    return window._.extend(window._.omit(styleObj, 'border'), { borderColor });
  }

  function withFallbackStyle(objectType, styleObj) {
    const implicitStyles = {
      progressWrapper: {
        borderColor: 'rgba(0,0,0,0)'
      },
      terms: {
        borderRadius: '3px'
      },
      button: {
        borderRadius: '5px'
      },
      dot: {
        borderRadius: '10px'
      }
    };
    return window._.extend({}, implicitStyles[objectType], styleObj);
  }

  function styleString2Object(styleString) {
    const regex = /([\w-]*)\s*:\s*([^;]*)/g;
    let match;
    const properties = {};
    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(styleString)))
      properties[cleanPropertyName(match[1])] = match[2];
    return properties;
  }

  function cleanPropertyName(name) {
    // turn things like 'align-items' into 'alignItems'
    return name.replace(/(-.)/g, function(v) {
      return v[1].toUpperCase();
    });
  }

  return {
    migrate
  };
});
