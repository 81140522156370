/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.export')
  .factory('exportFactory', function(
    $http,
    $timeout,
    $rootScope,
    $location,
    helpers,
    localStorage,
    EXPORT_CONFIG,
    requestFactory,
    conversionFactory,
    analyticsFactory2,
    sharedRequestsFactory,
    analyticsRequestsFactory
  ) {
    // <------ Object of export servlet/action params ------->
    const exportTypes = {
      analyticsSite: {
        servlet: 'analyticsSiteServlet',
        action: 'siteSpecific'
      },
      analyticsCampaign: {
        servlet: 'analyticsCampaignServlet',
        action: 'campaignSpecific'
      },
      sites: { servlet: 'analyticsSiteServlet', action: 'sites' },
      campaigns: { servlet: 'analyticsCampaignServlet', action: 'campaigns' },
      general: { servlet: 'analyticsSystemServlet', action: 'general' },
      visitorsSite: { servlet: 'analyticsVisitor', action: 'visitors' },
      viewersCampaign: { servlet: 'analyticsVisitor', action: 'visitors' },
      reporting: { servlet: 'reportingServlet', action: 'reporting' }
    };

    // <------ Data for maping export params for backend ------->
    const exportParamsMap = {
      generalInfoSitesAll: 'generalInfo',
      generalInfoSitesSpecAll: 'generalInfo',
      generalInfoSitesFiltered: 'generalInfoFiltered',
      generalInfoCampaigns: 'generalInfo',
      generalInfoCampaignsSpecific: 'generalInfo',
      generalInfoSystem: 'generalInfo',
      dataByVisitorsWithWeather: 'dataByVisitors',
      dataByComparisonSites: 'dataByComparison',
      dataByComparisonCampaigns: 'dataByComparison',
      dataByDevicesSites: 'dataByDevices',
      dataByDemographicInfoSites: 'dataByDemographicInfo',
      dataByHoursSites: 'dataByHours',
      dataByWeekSites: 'dataByWeek',
      dataByMonthsSites: 'dataByMonths',
      dataByYearsSites: 'dataByYears',
      dataByCitiesAndCountriesSites: 'dataByCitiesAndCountries',
      dataByDevicesCampaigns: 'dataByDevices',
      dataByDemographicInfoCampaigns: 'dataByDemographicInfo',
      dataByHoursCampaigns: 'dataByHours',
      dataByWeekCampaigns: 'dataByWeek',
      dataByMonthsCampaigns: 'dataByMonths',
      dataByYearsCampaigns: 'dataByYears',
      campaignsTimeline: 'dataTimeline',
      interactionsQuantity: 'visitsQuantity',
      lastInteraction: 'lastVisit',
      dataByTags: ['dataByTagsSites', 'dataByTagsCampaigns'],
      amountOfSitesAndEntities: ['amountOfSites', 'amountOfEntities'],
      device_OS: ['device', 'OS'],
      macAddress_onlyExcel: 'macAddress',
      clickedSites_onlyExcel: 'clickedSites',
      visitedSites_onlyExcel: 'visitedSites'
    };

    const alwaysDisabledBlocks = {
      xls: ['campaignsTimeline'],
      pdf: []
    };

    // Prepare filters for export request
    const filtersHandlers = {
      forVisitors(filters, options, type, ids) {
        const f = analyticsRequestsFactory.mapFiltersToRequest(filters);
        const itemIds = ids.map(function(item) {
          return +item;
        });
        f.parameters = { data: prepareExportParams(options) };
        f.ages = filters.ages;
        f.genders = filters.genders;
        f.OS = filters.OS;
        f.top = +filters.top || 0;
        f.deviceTypes = filters.deviceTypes;
        f.searchString = filters.searchString || '';
        if (type === 'viewersCampaign') {
          f.campaignIds = itemIds;
          f.interactionType = filters.interactionType;
        } else {
          f.siteIds = itemIds;
        }
        f.uniquePageId = $rootScope.uniqueBrowserTabToken;
        return f;
      },
      forAnalytics(filters, options, type, blockState) {
        const f = {};
        options.forEach(function(item) {
          if (
            item.isDisabled ||
            item.isAlwaysDisabled ||
            !item.value ||
            item.key === 'campaigns' ||
            item.key === 'sites'
          ) {
            return null;
          }
          if (Array.isArray(exportParamsMap[item.key])) {
            exportParamsMap[item.key].forEach(function(param) {
              const exportParam = `get${conversionFactory.capitalize(param)}`;
              f[exportParam] = analyticsRequestsFactory.prepareRequestParams(
                filters,
                type,
                exportParam,
                blockState[item.key]
              );
            });
          } else {
            const exportParam = `get${conversionFactory.capitalize(
              exportParamsMap[item.key] || item.key
            )}`;
            f[exportParam] = analyticsRequestsFactory.prepareRequestParams(
              filters,
              type,
              exportParam,
              blockState[item.key]
            );
          }
          return undefined;
        });
        f.filters = angular.copy(
          analyticsRequestsFactory.mapFiltersToRequest(filters)
        );
        return f;
      },
      forReporting(filters, options) {
        const f = {
          id: filters.reportId,
          blockList: [],
          terminateType:
            sharedRequestsFactory.reportsTerminateTypes.createView[
              filters.listType
            ]
        };
        options.forEach(function(item) {
          if (item.isDisabled || !item.value) return null;
          if (Array.isArray(exportParamsMap[item.key])) {
            exportParamsMap[item.key].forEach(function(param) {
              const exportParam = `get${conversionFactory.capitalize(param)}`;
              f.blockList.push(exportParam);
            });
          } else {
            const exportParam = `get${conversionFactory.capitalize(
              exportParamsMap[item.key] || item.key
            )}`;
            f.blockList.push(exportParam);
          }
          return undefined;
        });
        return f;
      }
    };

    // Map names of block for backend.
    // Even if one block has two or more requests
    function returnFinalParam(data, result) {
      const res = result || [];
      data.forEach(function(item) {
        if (!Array.isArray(item)) {
          res.push(item);
        } else {
          returnFinalParam(item, res);
        }
      });
      return res;
    }

    function prepareExportParams(params, isCicle) {
      const final = params
        .map(function(item) {
          if (item.value || typeof item === 'string') {
            // check if value of item is true or if item is string
            if (Array.isArray(exportParamsMap[item.key || item])) {
              return prepareExportParams(
                exportParamsMap[item.key || item],
                true
              );
            }
            return `get${conversionFactory.capitalize(
              exportParamsMap[item.key || item] || item.key || item
            )}`;
          }
          return undefined;
        })
        .filter(function(n) {
          return angular.isDefined(n);
        });
      if (!isCicle) {
        return returnFinalParam(final);
      }
      return final;
    }

    // Get export file request
    const getExportExcel = function(filters, options, type, ids, blocksState) {
      const request = exportTypes[type];
      const f =
        type === 'visitorsSite' || type === 'viewersCampaign'
          ? filtersHandlers.forVisitors(filters, options, type, ids)
          : filtersHandlers.forAnalytics(filters, options, type, blocksState);

      return requestFactory
        .post(
          EXPORT_CONFIG[request.servlet],
          EXPORT_CONFIG[request.action],
          f,
          {},
          { skipErrors: [502, 504] }
        )
        .then(
          function(res) {
            if (res.data.file) {
              window.open(res.data.file, '_blank');
            } else {
              // no design
            }
          },
          function(res) {
            helpers.noServerInfoModal(res.status);
          }
        );
    };

    const getExportXls = function(filters, options, type, ids, blocksState) {
      const request = exportTypes[type];
      const f =
        type === 'visitors' // eslint-disable-line no-nested-ternary
          ? filtersHandlers.forVisitors(filters, options, type, ids)
          : type === 'analytics'
          ? filtersHandlers.forAnalytics(filters, options, type, blocksState)
          : filtersHandlers.forReporting(filters, options);

      return requestFactory
        .post(
          EXPORT_CONFIG[request.servlet],
          EXPORT_CONFIG[request.action],
          f,
          {},
          { skipErrors: [502, 504] }
        )
        .then(
          function(res) {
            if (res.data.file) {
              window.open(res.data.file, '_blank');
            }
          },
          function(res) {
            helpers.noServerInfoModal(res.status);
          }
        );
    };

    return {
      getExportExcel,
      getExportXls,
      alwaysDisabledBlocks,
      prepareExportParams
    };
  });
