/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').component('widgetEditorSliderControls', {
  templateUrl:
    'app/sections/widgets/editor/sliderWidget/widgetEditorSliderControls.html',
  controller: widgetEditorSliderControlsController,
  bindings: {
    normalStyle: '<',
    activeStyle: '<',
    isActive: '<',
    isHighlighted: '<'
  }
});

function widgetEditorSliderControlsController(widgetsFactory) {
  const ctrl = this;
  ctrl.getStyle = function() {
    let reactStyle = ctrl.normalStyle;
    if (ctrl.isActive) {
      reactStyle = window._.extend({}, ctrl.normalStyle, ctrl.activeStyle);
    }
    if (!angular.isObject(reactStyle)) return reactStyle;
    return widgetsFactory.convertReactStylesToAngular(reactStyle);
  };
}
