const { angular } = window;

angular
  .module('wcm.components')

  .constant('dropdownConfig', {
    openClass: 'dropdown_open'
  })

  .service('dropdownService', [
    '$document',
    function($document) {
      let openScope = null;

      this.open = function(dropdownScope) {
        if (!openScope) {
          $document.bind('click', closeDropdown);
          $document.bind('keydown', escapeKeyBind);
        }

        if (openScope && openScope !== dropdownScope) {
          openScope.isOpen = false;
        }

        openScope = dropdownScope;
      };

      this.close = function(dropdownScope) {
        if (openScope === dropdownScope) {
          openScope = null;
          $document.unbind('click', closeDropdown);
          $document.unbind('keydown', escapeKeyBind);
        }
      };

      function closeDropdown(evt) {
        // This method may still be called during the same mouse event that
        // unbound this event handler. So check openScope before proceeding.
        if (!openScope) {
          return;
        }

        const toggleElement = openScope.getToggleElement();
        if (evt && toggleElement && toggleElement[0].contains(evt.target)) {
          return;
        }

        openScope.$apply(function() {
          openScope.isOpen = false;
        });
      }

      function escapeKeyBind(evt) {
        if (evt.which === 27) {
          openScope.focusToggleElement();
          closeDropdown();
        }
      }
    }
  ])

  .controller('DropdownController', [
    '$scope',
    '$attrs',
    '$parse',
    'dropdownConfig',
    'dropdownService',
    '$animate',
    function(
      $scope,
      $attrs,
      $parse,
      dropdownConfig,
      dropdownService,
      $animate
    ) {
      const self = this;
      const scope = $scope.$new(); // create a child scope so we are not polluting original one
      const openClass = dropdownConfig.openClass;
      const toggleInvoker = $attrs.onToggle
        ? $parse($attrs.onToggle)
        : angular.noop;
      let getIsOpen;
      let setIsOpen = angular.noop;

      scope.isOpen = false;

      this.init = function(element) {
        self.$element = element;

        if ($attrs.isOpen) {
          getIsOpen = $parse($attrs.isOpen);
          setIsOpen = getIsOpen.assign;

          $scope.$watch(getIsOpen, function(value) {
            scope.isOpen = !!value;
          });
        }
      };

      this.toggle = function(open) {
        scope.isOpen = arguments.length ? !!open : !scope.isOpen;
        return scope.isOpen;
      };

      // Allow other directives to watch status
      this.isOpen = function() {
        return scope.isOpen;
      };

      scope.getToggleElement = function() {
        return self.toggleElement;
      };

      scope.focusToggleElement = function() {
        if (self.toggleElement) {
          self.toggleElement[0].focus();
        }
      };

      scope.$watch('isOpen', function(isOpen, wasOpen) {
        $animate[isOpen === true ? 'addClass' : 'removeClass'](
          self.$element,
          openClass
        );

        if (isOpen === true) {
          scope.focusToggleElement();
          dropdownService.open(scope);
        } else {
          dropdownService.close(scope);
        }

        setIsOpen($scope, isOpen);
        if (angular.isDefined(isOpen) && isOpen !== wasOpen) {
          toggleInvoker($scope, { open: !!isOpen });
        }
      });

      $scope.$on('$locationChangeSuccess', function() {
        scope.isOpen = false;
      });

      $scope.$on('$destroy', function() {
        scope.$destroy();
      });
    }
  ])

  .directive('dropdown', function() {
    return {
      controller: 'DropdownController',
      link(scope, element, attrs, dropdownCtrl) {
        dropdownCtrl.init(element);
      }
    };
  })

  .directive('dropdownToggle', function() {
    return {
      require: '?^dropdown',
      link(scope, element, attrs, dropdownCtrl) {
        if (!dropdownCtrl) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        dropdownCtrl.toggleElement = element;

        const toggleDropdown = function(event) {
          event.preventDefault();

          if (!element.hasClass('disabled') && !attrs.disabled) {
            scope.$apply(function() {
              dropdownCtrl.toggle();
            });
          }
        };

        element.bind('click', toggleDropdown);

        // WAI-ARIA
        element.attr({ 'aria-haspopup': true, 'aria-expanded': false });
        scope.$watch(dropdownCtrl.isOpen, function(isOpen) {
          element.attr('aria-expanded', !!isOpen);
        });

        scope.$on('$destroy', function() {
          element.unbind('click', toggleDropdown);
        });
      }
    };
  })

  .directive('dropdownSettings', function() {
    return {
      require: 'ngModel',
      link(scope, element, attrs, ngModel) {
        const placeholder = attrs.placeholder ? attrs.placeholder : '';
        // eslint-disable-next-line no-param-reassign
        ngModel.$render = function() {
          element
            .find('button')
            .find('.dropdown_value')
            .html(ngModel.$viewValue ? ngModel.$viewValue : placeholder);
        };

        element.on('click', 'li', function() {
          if (window.$(this).find('.__no-items').length) {
            return true;
          }
          const newValue = window
            .$(this)
            .find('span')
            .html();
          ngModel.$setDirty(true);
          ngModel.$setViewValue(newValue);
          ngModel.$render();
          return true;
        });
      }
    };
  });
