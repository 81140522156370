/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('licenseCtrl', function(
    $scope,
    licenseFactory,
    $modal,
    $timeout,
    $state,
    i18nFactory,
    navigationPanel,
    breadcrumbs,
    helpers
  ) {
    $scope.log = [];
    $scope.response = {};
    $scope.license = {};
    $scope.focused = {};
    breadcrumbs.set({ path: ['admin', 'license'].map(name => ({ name })) });
    navigationPanel.setTabs([]);
    $scope.state = {
      editMode: false
    };

    const init = function() {
      $scope.setBusy(true);
      licenseFactory
        .getLicense()
        .then(function(license) {
          $scope.sectionForm.$setPristine(true);
          $scope.license = license;
          if ($scope.license.applied === false && !licenseFactory.modalShown) {
            // eslint-disable-next-line no-param-reassign
            licenseFactory.modalShown = true;
            showSuccessModal().then(function() {});
          }
        })
        .catch(function(res) {
          if (res.status !== 488) {
            return true;
          }
          $scope.license = {
            licenseKey: res.data.license_key
          };
          const serverMessage = res.data.serverMessage || {
            key: '',
            params: []
          };
          const scope = $scope.$new();
          return i18nFactory
            .translateResponse(serverMessage.key, serverMessage.params)
            .then(function(message) {
              scope.message = message;
              $modal.open({
                templateUrl: 'app/core/views/errorModal.html',
                windowClass: 'error-modal',
                scope
              });
            });
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.submit = function() {
      $scope.setSubmitted(true);
      if ($scope.sectionForm.$invalid) {
        return false;
      }
      $scope.setBusy(true);

      return licenseFactory
        .saveLicense($scope.license.licenseKey)
        .error(init)
        .success(function() {
          // eslint-disable-next-line no-param-reassign
          licenseFactory.modalShown = false;
          init();
          $scope.state.editMode = false;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    const cancelConfirmModal = function() {
      const title = i18nFactory.translate(
        'admin.license.modals.cancelConfirmation.title'
      );
      const message = i18nFactory.translate(
        'admin.license.modals.cancelConfirmation.texts.edit'
      );
      return helpers.confirmationModal(title, message).result;
    };

    $scope.cancel = function cancel() {
      cancelConfirmModal().then(function() {
        init();
        $scope.setSubmitted(false);
        $scope.state.editMode = false;
      });
    };

    $scope.$on('$stateChangeStart', function(event, toState) {
      if ($scope.state.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.state.editMode = false;
          $state.go(toState);
        });
      }
    });

    function showSuccessModal() {
      const modalInstance = $modal.open({
        templateUrl: 'licenseModal',
        windowClass: 'info-modal'
      });
      return modalInstance.result;
    }

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => !$scope.state.editMode,
        clickHandler: () => {
          $scope.state.editMode = true;
        }
      },
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.state.editMode,
        clickHandler: () => {
          $scope.submit();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.state.editMode,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);

    init();
  });
