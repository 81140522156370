/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.campaigns')
  .controller('campaignsListCtrl', function(
    $scope,
    $state,
    $modal,
    $filter,
    $location,
    $q,
    $timeout,
    auth,
    navigationPanel,
    breadcrumbs,
    filtersFactory,
    campaignsFactory,
    conversionFactory,
    i18nFactory,
    helpers
  ) {
    const campaignType = $state.current.name.split('.').pop();
    const availableTabs = [
      { name: 'campaigns.partner', sref: 'section.campaigns.partnerList' },
      { name: 'campaigns.advertiser', sref: 'section.campaigns.advertiserList' }
    ];
    const allowedTabs = availableTabs.filter(function(tab) {
      return auth.hasPermissionsForSection(
        $scope.currentUser,
        tab.sref
          .split('.')
          .slice(1)
          .join('.')
      );
    });

    navigationPanel.setTabs(allowedTabs);
    breadcrumbs.set({
      path: [
        {
          name: 'campaigns'
        },
        {
          name: 'list'
        },
        {
          name: campaignType === 'partnerList' ? 'classA' : 'classB',
          sref: `section.campaigns.${campaignType}`
        }
      ]
    });

    let sortOption;
    let appliedFilters;
    $scope.filters = filtersFactory.initFilters(
      {
        label: 'Status',
        options: {
          Active: 'Active',
          Archive: 'Archive'
        }
      },
      {
        label: 'Sort',
        options: {
          'date:desc': 'date:desc',
          'date:asc': 'date:asc',
          'alphabetically:asc': 'alphabetically:asc',
          'alphabetically:desc': 'alphabetically:desc'
        },
        type: 'sort'
      }
    );

    const statesFilters = {
      active: filtersFactory.initFilters({
        label: 'State',
        options: {
          all: '',
          running: 'running',
          stopped: 'stopped',
          scheduled: 'scheduled',
          finished: 'finished',
          draft: 'draft'
        }
      })[0],

      archive: filtersFactory.initFilters({
        label: 'State',
        options: {
          all: '',
          stopped: 'stopped',
          finished: 'finished',
          draft: 'draft'
        }
      })[0]
    };

    $scope.filters[2] = statesFilters.active;

    $scope.onFilterUpdate = function onFilterUpdate(
      filterValue,
      filterType,
      filterName
    ) {
      if (filterName === 'Status') {
        $scope.filters[2] = statesFilters[
          filterValue.toLowerCase()
        ].activeOption(0);
        $location.search('State', $scope.filters[2].activeOption().value);
      }
    };

    $scope.goToView = function(id) {
      $state.go('section.campaigns.view.details', { id });
    };

    $scope.campaignHealth = [
      '__status-1',
      '__status-2',
      '__status-3',
      '__status-4',
      '__status-5'
    ];
    $scope.campaignState = {
      'always running': '__always-running',
      running: '__running',
      draft: '__draft',
      stopped: '__stopped',
      'stopped (tr)': '__stopped',
      scheduled: '__scheduled',
      finished: '__finished'
    };

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(
      data,
      requestPayload
    ) {
      $scope.totalItems = data.itemsTotal;
      $scope.canCreate = data.canCreate;
      $scope.archiveState = requestPayload.filterByStatus === 'Active';
    };

    $scope.onListUpdate = function onListUpdate(sortOpt, filters) {
      if (filters.filterByType !== $scope.currentTabName) return false;
      sortOption = sortOpt;
      appliedFilters = filters;
      $scope.getPage();
      return undefined;
    };

    $scope.action = function(id, actionType, campaignItem) {
      if (actionType !== 'disabled') {
        helpers
          .confirmationModal(
            '',
            `campaigns.modals.${actionType}.texts.${actionType}`,
            campaignItem.name,
            null,
            { className: 'campaign_modal_confirm' }
          )
          .result.then(function() {
            campaignsFactory
              .campaignPerformAction(id, actionType)
              .success(function() {
                $scope.applyFilters(true);
              });
          });
      }
    };

    $scope.getPage = function getPage() {
      campaignsFactory
        .getList(
          appliedFilters,
          $scope.currentPage,
          $scope.itemsOnPage,
          sortOption
        )
        .then(function(campaigns) {
          $scope.campaignsList = campaigns;
          $scope.campaignsList.forEach(function(item, i) {
            if (item.workingDates && item.workingDates.length > 0) {
              $scope.campaignsList[i].workingDates = item.workingDates
                .map(function(date) {
                  return $filter('formatDate')(date);
                })
                .join(', ');
            } else {
              $scope.campaignsList[i].workingDates = '';
            }
          });
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };
    $scope.remove = function archiveAction(id, name) {
      const scope = $scope.$new(true);
      scope.id = id;
      scope.name = name;

      helpers
        .confirmationModal(
          '',
          'campaigns.modals.remove.texts.remove',
          name,
          null,
          { className: 'campaign_modal_confirm' }
        )
        .result.then(function() {
          campaignsFactory.campaignRemove(id).success(function() {
            $scope.applyFilters(true);
          });
        });
    };
    $scope.archiveAction = function(id, name) {
      const status = $scope.filters[0].activeOption();
      const archiveAction =
        status.value === 'Active'
          ? campaignsFactory.moveToArchive
          : campaignsFactory.recoverFromArchive;
      helpers
        .confirmationModal(
          '',
          `campaigns.modals.archive.texts.${
            status.value === 'Active' ? 'moveToArchive' : 'recoverFromArchive'
          }`,
          name,
          null,
          { className: 'campaign_modal_confirm' }
        )
        .result.then(function() {
          archiveAction(id).success(function() {
            $scope.applyFilters(true);
          });
        });
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => !$scope.archiveState,
        isShown: () => $scope.canCreate,
        clickHandler: () => {
          $state.go('section.campaigns.create', {
            type: campaignType === 'partnerList' ? 'ClassA' : 'ClassB'
          });
        }
      }
    ]);
  });
