/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .filter('timeFrame', function() {
    return function(input) {
      if (typeof input !== 'number') return input;
      const date = new Date(input);
      const days = date.getUTCDate();
      const hours = 24 * (days - 1) + date.getUTCHours();
      const minutes = date.getUTCMinutes();
      return `${twoDigits(hours)} : ${twoDigits(minutes)}`;
    };
  })
  .filter('timeHack', function() {
    return function(input) {
      if (input === '24 : 00') {
        return '23 : 59';
      }

      return input;
    };
  });

function twoDigits(value) {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
}
