/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.widgets').component('widgetEditorCopyPaste', {
  templateUrl: 'app/sections/widgets/editor/common/widgetEditorCopyPaste.html',
  controller: widgetEditorCopyPasteController,
  bindings: {
    src: '<'
  }
});

function widgetEditorCopyPasteController(widgetEditorCopyPasteFactory) {
  const ctrl = this;
  const copyPaste = widgetEditorCopyPasteFactory;

  ctrl.copy = function() {
    copyPaste.copy(ctrl.src);
  };

  ctrl.paste = function() {
    copyPaste.paste(ctrl.src);
  };

  ctrl.isStyleCopied = copyPaste.isStyleCopied;
}
