/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const { pluralize } = window;

angular
  .module('wcm')
  .controller('sectionCtrl', function(
    $scope,
    $state,
    $q,
    requestFactory,
    session,
    $location
  ) {
    let unwatch;
    let paginationInit;
    $scope.search = {
      value: '',
      mode: ''
    };
    this.registerTab = function(tab) {
      $scope.tabs = [];
      $scope.search.value = '';
      // eslint-disable-next-line no-param-reassign
      tab.applyFilters = applyFilters;
      $scope.currentTabScope = tab;
      $scope.currentSectionName = $state.current.name.split('.')[1];

      $scope.currentTabName = $state.current.name
        .split('.')[2]
        .replace('List', '');
      // eslint-disable-next-line no-param-reassign
      tab.init = tab.init || angular.noop;
      // eslint-disable-next-line no-param-reassign,no-prototype-builtins
      tab.onFilterUpdate = tab.hasOwnProperty('onFilterUpdate')
        ? tab.onFilterUpdate
        : angular.noop;
      $q.when(tab.init($scope)).then(function() {
        if (!$state.current.data || !$state.current.data.noFilters) {
          syncFiltersWithQueryParams(tab.filters, $state.params);
          $scope.search.value = $state.params.SearchQuery || '';
          $scope.search.mode = $state.params.SearchStringMode || '';
          $scope.search.searchSources =
            $state.current.data && $state.current.data.searchSources
              ? $state.current.data.searchSources
              : ['name'];
          if ($state.params.pg && $state.params.ipp) {
            paginationInit = { pg: $state.params.pg, ipp: $state.params.ipp };
          }

          unwatch = $scope.$watch(
            function() {
              return {
                itemsOnPage: tab.itemsOnPage,
                currentPage: tab.currentPage
              };
            },
            function(newVal, oldVal) {
              if (!oldVal) return;
              $location.search('pg', newVal.currentPage);
              $location.search('ipp', newVal.itemsOnPage);
              // helpers.redirectOopsPage();
            },
            true
          );
          applyFilters(true);
        }
      });
    };

    this.unregisterTab = function() {
      $scope.currentTabScope = null;
      unwatch();
    };

    $scope.onGo = function(id, title) {
      if (!$scope.currentTabScope.goToView) {
        throw new Error(
          'goToView function is not implemented in current list controller'
        );
      }
      $scope.currentTabScope.goToView(id, title);
    };

    $scope.onFilterUpdate = function onFilterUpdate(
      filterValue,
      filterType,
      filterName
    ) {
      $location.search(filterName, filterValue);
      $q.when(
        $scope.currentTabScope.onFilterUpdate(
          filterValue,
          filterType,
          filterName
        )
      ).then(function() {
        if (
          filterType === 'sort' &&
          angular.isDefined($scope.currentTabScope.onListUpdate)
        ) {
          const requestData = getFiltersForRequest(true);
          $scope.currentTabScope.onListUpdate(
            requestData.sortOption,
            requestData.filters
          );
          return true;
        }
        if (filterType === 'filter') {
          applyFilters(true);
          return true;
        }
        if (filterType.indexOf('search') > -1) {
          const components = filterType.split(':');
          $scope.search.mode = components[2];
          $location.search('SearchStringMode', components[2]);
          // if (components[1] === 'updateList') {
          //   $scope.search.mode = components[2];
          //   $location.search('SearchStringMode', components[2]);
          // }
          applyFilters(components[1] === 'updateList');
          return true;
        }
        return undefined;
      });
    };

    function syncFiltersWithQueryParams(filters, params) {
      angular.forEach(filters, function(filter) {
        const value = params[filter.label];
        if (!value) return;
        angular.forEach(filter.options, function(opt, idx) {
          if (opt.value === value) {
            filter.activeOption(idx);
          }
        });
      });
    }

    function initPaginationSync(tabScope, totalItems) {
      if (!paginationInit) return;
      if (
        paginationInit.pg <= Math.ceil(totalItems / paginationInit.ipp) &&
        ~tabScope.itemsOnPageValues.indexOf(+paginationInit.ipp)
      ) {
        // eslint-disable-next-line no-param-reassign
        tabScope.currentPage = +paginationInit.pg;
        // eslint-disable-next-line no-param-reassign
        tabScope.itemsOnPage = +paginationInit.ipp;
      } else {
        console.warn('invalid pagination data, reset to default');
      }
    }

    function getFiltersForRequest(shouldUpdateList) {
      const result = {};
      const filters = {
        filterByType: $scope.currentTabName,
        filterBySearchString: $scope.search.value || '',
        searchStringMode: $scope.search.mode || '',
        updateList: !!shouldUpdateList
      };
      angular.forEach($scope.currentTabScope.filters, function(filter) {
        if (filter.type === 'filter') {
          filters[`filterBy${filter.label}`] = filter.activeOption().value;
        }
        if (filter.type === 'sort') {
          result.sortOption = filter.activeOption().value;
        }
      });
      result.filters = filters;
      return result;
    }

    function applyFilters(updateList) {
      const applyFiltersPrefix =
        $state.current.data && $state.current.data.filtersApplyPrefix
          ? $state.current.data.filtersApplyPrefix
          : pluralize($scope.currentSectionName, 1);
      const servlet =
        $state.current.data && $state.current.data.servlet
          ? $state.current.data.servlet
          : $scope.currentSectionName;
      const requestPayload = getFiltersForRequest(updateList);

      if (updateList) {
        $scope.setBusy(true);
      }
      requestFactory
        .post(
          servlet,
          `${applyFiltersPrefix}ApplyFilters`,
          requestPayload.filters
        )
        .success(function(data) {
          $scope.dropDownItems = data.searchDropDown;
          $scope.totalItems = data.itemsTotal;
          if (paginationInit) {
            initPaginationSync($scope.currentTabScope, data.itemsTotal);
            paginationInit = undefined;
          }
          if (
            updateList &&
            angular.isDefined(
              $scope.currentTabScope.onAfterFiltersApplied
            )
          ) {
            $scope.currentTabScope.onAfterFiltersApplied(
              data,
              requestPayload.filters
            );
          }
          if (
            updateList &&
            angular.isDefined($scope.currentTabScope.onListUpdate)
          ) {
            $scope.currentTabScope.onListUpdate(
              requestPayload.sortOption,
              requestPayload.filters
            );
          }
        });
    }
  });
