/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2.presets')
  .factory('analyticsPresetsFactory', function(
    requestFactory,
    $q,
    $rootScope,
    helpers,
    $modal,
    session,
    ANALYTICS_PRESETS_CONFIG,
    ANALYTICS_REQUESTS_CONFIG
  ) {
    const config = ANALYTICS_PRESETS_CONFIG;

    const presetPrepareHandler = function(filters) {
      const f = {};
      Object.keys(filters).forEach(function(name) {
        f[name] = {};
        if (name === 'campaignsFilter' || name === 'sitesFilter') {
          f[name].checkedItems = filters[name].checkedItems;
        } else if (
          name === 'dataByComparisonCampaigns' ||
          name === 'dataByComparisonSites'
        ) {
          f[name].firstValues = {
            checkedItems: filters[name].firstValues.checkedItems
          };
          f[name].secondValues = {
            checkedItems: filters[name].secondValues.checkedItems
          };
        } else if (name === 'amountOfViewsAndVisitors') {
          f[name].checkedSites = filters[name].checkedSites;
          f[name].checkedCities = filters[name].checkedCities;
        }
      });
      return f;
    };

    const getSystemPreset = function getSystemPreset(analyticsType) {
      return angular.extend({}, config.defaultPresets[analyticsType], {
        createdAt: session.currentUser().systemCreationDate
      });
    };

    const getPresets = function getPresets(analyticsType) {
      return requestFactory
        .post(
          config.presetsServlet,
          config.getPresetList,
          { type: analyticsType },
          {},
          { skipErrors: ANALYTICS_REQUESTS_CONFIG.skipErrors }
        )
        .then(
          function(res) {
            return res.data && res.data.presets ? res.data.presets : [];
          },
          function(res) {
            return $q.reject(res);
          }
        )
        .then(function(presets) {
          return presets.sort(function(a, b) {
            if (a.isDefault) {
              return -1;
            }
            if (b.isDefault) {
              return 1;
            }
            return 0;
          });
        })
        .then(function(presets) {
          const systemDefaultPreset = getSystemPreset(analyticsType);
          // if there are no default presets in list then system default preset
          // is default for current user
          if (!presets.length || !presets[0].isDefault) {
            systemDefaultPreset.isDefault = true;
          }
          return [systemDefaultPreset].concat(presets);
        });
    };

    const getDefaultPreset = function getDefaultPreset(analyticsType) {
      return requestFactory
        .post(
          config.presetsServlet,
          config.getDefaultPreset,
          { type: analyticsType },
          {},
          { skipErrors: ANALYTICS_REQUESTS_CONFIG.skipErrors }
        )
        .then(
          function(res) {
            return res.data && res.data.preset
              ? res.data.preset
              : getSystemPreset(analyticsType);
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };

    const getPreset = function getPreset(analyticsType, presetName) {
      return requestFactory
        .post(config.presetsServlet, config.getPreset, {
          type: analyticsType,
          name: presetName
        })
        .then(function(res) {
          return res.data && res.data.preset
            ? res.data.preset
            : getSystemPreset(analyticsType);
        });
    };

    const saveDefaultPreset = function saveDefaultPreset(
      analyticsType,
      presetName
    ) {
      return requestFactory.post(
        config.presetsServlet,
        config.saveDefaultPreset,
        { type: analyticsType, name: presetName }
      );
    };

    const savePreset = function savePreset(
      analyticsType,
      presetName,
      presetData,
      filters,
      manageBlockFilters
    ) {
      const f = angular.copy(filters);
      delete f.campaignIds;
      delete f.siteIds;

      const mf = presetPrepareHandler(manageBlockFilters);

      return requestFactory.post(config.presetsServlet, config.savePreset, {
        preset: {
          type: analyticsType,
          name: presetName,
          data: {
            analyticBlocks: presetData,
            filters: f,
            manageBlockFilters: mf
          }
        }
      });
    };

    const removePreset = function removePreset(analyticsType, presetName) {
      return requestFactory.post(config.presetsServlet, config.removePreset, {
        type: analyticsType,
        name: presetName
      });
    };

    const openPresetManager = function openPresetManager(presets) {
      const scope = $rootScope.$new(true);
      scope.presets = presets;
      return $modal.open({
        windowClass: 'modal_presets',
        templateUrl: 'app/core/analytics2/presetManagerModal.html',
        scope,
        controller($scope, $modalInstance) {
          const presetFilterFn = function(searchString, preset) {
            if (!searchString) return true;
            if (!preset.name) return false;
            return ~preset.name
              .toLowerCase()
              .indexOf(searchString.toLowerCase());
          };

          const filterPresets = function(presetList, searchQuery) {
            return presetList.filter(presetFilterFn.bind(null, searchQuery));
          };

          // eslint-disable-next-line no-multi-assign
          const state = ($scope.state = {
            selectedPreset: null,
            currentPage: 1,
            itemsPerPage: 20,
            itemsOnPageValues: [20, 40, 60, 80],
            searchQuery: '',
            filteredItems: filterPresets($scope.presets)
          });

          // eslint-disable-next-line no-multi-assign
          const handlers = ($scope.handlers = {
            onSearchQueryChange(searchQuery) {
              state.filteredItems = filterPresets($scope.presets, searchQuery);
            },
            getPage(items, ipp, page) {
              state.itemsPage = helpers.getPage(items, ipp, page);
            },
            onFilteredItemsChange(items) {
              handlers.getPage(
                items,
                state.itemsPerPage,
                (state.currentPage = 1)
              );
            },
            selectPreset(preset) {
              state.selectedPreset = preset;
            },
            setDefaultPreset(preset) {
              const name = preset.isRemovable ? preset.name : '';
              saveDefaultPreset(preset.type, name).then(function() {
                // eslint-disable-next-line no-param-reassign
                $scope.presets.forEach(function(preset) {
                  preset.isDefault = false;
                });
                // eslint-disable-next-line no-param-reassign
                preset.isDefault = true;
              });
            },
            deletePreset(preset) {
              helpers
                .confirmationModal(
                  '',
                  'components.analyticsPresets.labels.deleteConfirmation',
                  preset.name
                )
                .result.then(removePreset.bind(null, preset.type, preset.name))
                .then(function() {
                  const presets = $scope.presets;
                  const index = presets.indexOf(preset);
                  $scope.presets = presets
                    .slice(0, index)
                    .concat(presets.slice(index + 1));
                  // filter presets again to update pagination info
                  handlers.onSearchQueryChange(state.searchQuery);
                  if (preset.isDefault) {
                    // set default preset to system default one, if it was deleted
                    presets.filter(function(preset) {
                      return !preset.isRemovable;
                    })[0].isDefault = true;
                  }
                });
            },
            loadPreset(preset) {
              if (!preset) return;
              $modalInstance.close(preset);
            }
          });

          $scope.$watch('state.filteredItems', handlers.onFilteredItemsChange);
        }
      });
    };

    const openSaveDialog = function openSaveDialog(presets) {
      const scope = $rootScope.$new(true);
      scope.presets = presets;
      return $modal.open({
        windowClass: 'analytics-presets_save-modal',
        templateUrl: 'app/core/analytics2/savePresetModal.html',
        scope,
        controller($scope, $modalInstance) {
          $scope.forms = {};
          $scope.state = {
            name: ''
          };

          $scope.save = function(name) {
            const samePreset = window._.find(presets, function(preset) {
              return preset.name === name;
            });
            if (samePreset) {
              if (!samePreset.isRemovable) {
                helpers.errorModal(
                  '',
                  'components.analyticsPresets.labels.defaultPresetSaveError',
                  true
                );
              } else {
                helpers
                  .confirmationModal(
                    '',
                    'components.analyticsPresets.labels.replaceConfirmation',
                    name
                  )
                  .result.then($modalInstance.close.bind(null, name));
              }
            } else {
              $modalInstance.close(name);
            }
          };
        }
      });
    };

    return {
      getPresets,
      getDefaultPreset,
      getSystemPreset,
      getPreset,
      openPresetManager,
      saveDefaultPreset,
      savePreset,
      removePreset,
      openSaveDialog
    };
  });
