const { angular } = window;

angular
  .module('wcm.core')
  .controller('manageBlockController', function($timeout, manageBlockFactory) {
    const ctrl = this;

    ctrl.openFiltersModal = function() {
      manageBlockFactory
        .openFiltersModal(
          ctrl.availableValues,
          ctrl.filterSteps,
          ctrl.filters,
          ctrl.valuesType,
          ctrl.title
        )
        .result.then(function(availableValues) {
          ctrl.availableValues = availableValues;
          setViewValues();
          ctrl.onValuesSelected({
            availableValues,
            checkedIds: availableValues.filter(isChecked).map(function(value) {
              return value.id || value[ctrl.idProp];
            })
          });
        });
    };

    ctrl.$onInit = function() {
      ctrl.filterSteps = [];
    };

    ctrl.$onChanges = function(changesObj) {
      if (!ctrl.availableValues) {
        ctrl.availableValues = [];
      }
      if (!ctrl.filters) {
        ctrl.filters = [];
      }
      ctrl.filters = manageBlockFactory.prepareFilters(ctrl.filters);
      setViewValues();

      if (changesObj.isExport && ctrl.isExport) {
        setItemsForPrint();
      }
    };

    function setViewValues() {
      let items;

      if (ctrl.shouldCollapse && ctrl.filters && ctrl.valuesType) {
        items = manageBlockFactory.collapseValues(
          ctrl.availableValues.filter(isChecked),
          ctrl.filters,
          ctrl.valuesType
        );
      } else {
        items = ctrl.availableValues.filter(isChecked);
      }

      if (ctrl.limitItems) {
        const limitedItems = manageBlockFactory.limitTo(ctrl.limitItems, items);
        ctrl.items = limitedItems.entries;
        ctrl.extraItems = limitedItems.extra;
      } else {
        ctrl.items = items;
        ctrl.extraItems = 0;
      }
    }

    function isChecked(item) {
      return !!item.checked;
    }

    function setItemsForPrint() {
      let itemsForPrint = '';
      ctrl.availableValues.filter(isChecked).forEach(function(item) {
        itemsForPrint += `<p>\
                <span class="text-view ${
                  item.className ? `text-view_${item.className}` : ''
                }">\
                    <span>${item.name}</span>\
                </span>\
              </p>`;
      });
      $timeout(function() {
        window.$('#itemsForPrint').html(itemsForPrint);
      }, 0);
    }
  });
