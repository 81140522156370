/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.widgets')
  .directive('widgetEditorEyedropperInput', function(
    $parse,
    eyedropperService
  ) {
    return {
      restrict: 'A',
      require: '?ngModel',
      link(scope, el, attr) {
        const eyedropperAssigner = $parse(attr.ngModel).assign;
        let colorChangeWatchOff;
        let activeWatchOff;

        el.click(activate);

        function activate() {
          eyedropperService.update().then(function() {
            eyedropperService.toggleActive(true);
            colorChangeWatchOff = eyedropperService.onColorChange(function(
              color
            ) {
              scope.$applyAsync(function() {
                eyedropperAssigner(scope, color);
              });
            });
            activeWatchOff = scope.$watch(eyedropperService.isActive, function(
              newVal
            ) {
              if (!newVal) {
                deactivate();
              }
            });
          });
        }

        scope.$on('colorpicker-closed', deactivate);

        function deactivate() {
          if (colorChangeWatchOff) colorChangeWatchOff();
          if (activeWatchOff) activeWatchOff();
          scope.$applyAsync(function() {
            eyedropperService.toggleActive(false);
          });
        }
      }
    };
  });
