/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2015 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.core').factory('navigationPanel', function() {
  let panel;
  const queue = {};

  function register(panelScope) {
    panel = panelScope;
    if (queue.buttons) {
      setActionButtons(queue.buttons);
    }
    if (queue.tabs) {
      setTabs(queue.tabs);
    }
  }

  function unregister() {
    panel = undefined;
  }

  function setActionButtons(buttons) {
    if (!panel || !panel.setActionButtons) {
      queue.buttons = buttons;
    } else {
      panel.setActionButtons(buttons);
      queue.buttons = undefined;
    }
  }

  function setTabs(tabs) {
    if (!panel) {
      queue.tabs = tabs;
    } else {
      panel.setTabs(angular.isArray(tabs) ? tabs.reverse() : []);
      queue.tabs = undefined;
    }
  }

  return {
    register,
    unregister,
    setActionButtons,
    setTabs
  };
});
