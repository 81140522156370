const { angular } = window;

angular
  .module('wcm.admin')
  .controller('profileCtrl', function($scope, $controller, navigationPanel) {
    $controller('userCtrl', { $scope });
    $scope.setTabs = angular.noop;
    $scope.setBusy = angular.noop;

    $scope.setActionButtons = function(buttons) {
      $scope.actionButtons = buttons;
    };

    $controller('userDetailsCtrl', { $scope });
    navigationPanel.register($scope);
    $scope.$on('$destroy', function() {
      navigationPanel.unregister();
    });
  });
