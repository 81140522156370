const { angular } = window;

angular
  .module('wcm.core')

  /**
   * A set of utility methods that can be use to retrieve position of DOM elements.
   * It is meant to be used where we need to absolute-position DOM elements in
   * relation to other, existing elements (this is the case for tooltips, popovers,
   * typeahead suggestions etc.).
   */
  .factory('$position', [
    '$document',
    '$window',
    function($document, $window) {
      function getStyle(el, cssprop) {
        if (el.currentStyle) {
          // IE
          return el.currentStyle[cssprop];
        } else if ($window.getComputedStyle) {
          return $window.getComputedStyle(el)[cssprop];
        }
        // finally try and get inline style
        return el.style[cssprop];
      }

      /**
       * Checks if a given element is statically positioned
       * @param element - raw DOM element
       */
      function isStaticPositioned(element) {
        return (getStyle(element, 'position') || 'static') === 'static';
      }

      /**
       * returns the closest, non-statically positioned parentOffset of a given element
       * @param element
       */
      const parentOffsetEl = function(element) {
        const docDomEl = $document[0];
        let offsetParent = element.offsetParent || docDomEl;
        while (
          offsetParent &&
          offsetParent !== docDomEl &&
          isStaticPositioned(offsetParent)
        ) {
          offsetParent = offsetParent.offsetParent;
        }
        return offsetParent || docDomEl;
      };

      return {
        /**
         * Provides read-only equivalent of jQuery's position function:
         * http://api.jquery.com/position/
         */
        position(element) {
          const elBCR = this.offset(element);
          let offsetParentBCR = { top: 0, left: 0 };
          const offsetParentEl = parentOffsetEl(element[0]);
          if (offsetParentEl !== $document[0]) {
            offsetParentBCR = this.offset(
              angular.element(offsetParentEl)
            );
            offsetParentBCR.top +=
              offsetParentEl.clientTop - offsetParentEl.scrollTop;
            offsetParentBCR.left +=
              offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
          }

          const boundingClientRect = element[0].getBoundingClientRect();
          return {
            width: boundingClientRect.width || element.prop('offsetWidth'),
            height: boundingClientRect.height || element.prop('offsetHeight'),
            top: elBCR.top - offsetParentBCR.top,
            left: elBCR.left - offsetParentBCR.left
          };
        },

        /**
         * Provides read-only equivalent of jQuery's offset function:
         * http://api.jquery.com/offset/
         */
        offset(element) {
          const boundingClientRect = element[0].getBoundingClientRect();
          return {
            width: boundingClientRect.width || element.prop('offsetWidth'),
            height: boundingClientRect.height || element.prop('offsetHeight'),
            top:
              boundingClientRect.top +
              ($window.pageYOffset || $document[0].documentElement.scrollTop),
            left:
              boundingClientRect.left +
              ($window.pageXOffset || $document[0].documentElement.scrollLeft)
          };
        },

        /**
         * Provides coordinates for the targetEl in relation to hostEl
         */
        positionElements(hostEl, targetEl, positionStr, appendToBody) {
          const positionStrParts = positionStr.split('-');
          const pos0 = positionStrParts[0];
          const pos1 = positionStrParts[1] || 'center';

          const hostElPos = appendToBody
            ? this.offset(hostEl)
            : this.position(hostEl);
          const targetElWidth = targetEl.prop('offsetWidth');
          const targetElHeight = targetEl.prop('offsetHeight');
          let targetElPos;

          const shiftWidth = {
            center() {
              return hostElPos.left + hostElPos.width / 2 - targetElWidth / 2;
            },
            left() {
              return hostElPos.left;
            },
            right() {
              return hostElPos.left + hostElPos.width;
            }
          };

          const shiftHeight = {
            center() {
              return hostElPos.top + hostElPos.height / 2 - targetElHeight / 2;
            },
            top() {
              return hostElPos.top;
            },
            bottom() {
              return hostElPos.top + hostElPos.height;
            }
          };

          switch (pos0) {
            case 'right':
              targetElPos = {
                top: shiftHeight[pos1](),
                left: shiftWidth[pos0]()
              };
              break;
            case 'left':
              targetElPos = {
                top: shiftHeight[pos1](),
                left: hostElPos.left - targetElWidth
              };
              break;
            case 'bottom':
              targetElPos = {
                top: shiftHeight[pos0](),
                left: shiftWidth[pos1]()
              };
              break;
            default:
              targetElPos = {
                top: hostElPos.top - targetElHeight,
                left: shiftWidth[pos1]()
              };
              break;
          }

          return targetElPos;
        }
      };
    }
  ]);
