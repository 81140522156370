const { angular } = window;

angular.module('wcm.components').directive('perfectScrollbar', [
  '$parse',
  '$window',
  function($parse, $window) {
    const psOptions = [
      'wheelSpeed',
      'wheelPropagation',
      'minScrollbarLength',
      'useBothWheelAxes',
      'useKeyboard',
      'suppressScrollX',
      'suppressScrollY',
      'scrollXMarginOffset',
      'scrollYMarginOffset',
      'includePadding' // , 'onScroll', 'scrollDown'
    ];

    return {
      restrict: 'EA',
      transclude: true,
      template: '<div><div ng-transclude></div></div>',
      replace: true,
      link($scope, $elem, $attr) {
        const jqWindow = angular.element($window);
        const options = {};

        for (let i = 0, l = psOptions.length; i < l; i++) {
          const opt = psOptions[i];
          if ($attr[opt] !== undefined) {
            options[opt] = $parse($attr[opt])();
          }
        }

        $scope.$evalAsync(function() {
          $elem.perfectScrollbar(options);
          const onScrollHandler = $parse($attr.onScroll);
          $elem.scroll(function() {
            const scrollTop = $elem.scrollTop();
            const scrollHeight = $elem.prop('scrollHeight') - $elem.height();
            $scope.$apply(function() {
              onScrollHandler($scope, {
                scrollTop,
                scrollHeight
              });
            });
          });
        });

        // watch for content size dynamically changes
        $scope.$watch(
          function() {
            return $elem.prop('scrollHeight');
          },
          function(newValue) {
            if (newValue) {
              update('contentSizeChange');
            }
          }
        );

        function update(event) {
          $scope.$evalAsync(function() {
            if ($attr.scrollDown === 'true' && event !== 'mouseenter') {
              setTimeout(function() {
                window.$($elem).scrollTop(window.$($elem).prop('scrollHeight'));
              }, 100);
            }
            $elem.perfectScrollbar('update');
          });
        }

        // This is necessary when you don't watch anything with the scrollbar
        $elem.bind('mouseenter', update.bind(null, 'mouseenter'));

        // Possible future improvement - check the type here and use
        // the appropriate watch for non-arrays
        if ($attr.refreshOnChange) {
          $scope.$watchCollection($attr.refreshOnChange, function() {
            update();
          });
        }

        // this is from a pull request - I am not totally sure what
        // the original issue is but seems harmless
        if ($attr.refreshOnResize) {
          jqWindow.on('resize', update);
        }

        $elem.bind('$destroy', function() {
          jqWindow.off('resize', update);
          $elem.perfectScrollbar('destroy');
        });
      }
    };
  }
]);
