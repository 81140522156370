/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.sites')
  .controller('siteCtrl', function(
    $scope,
    $state,
    $q,
    sitesFactory,
    breadcrumbs,
    navigationPanel,
    APP_CONFIG
  ) {
    const id = +$state.params.id;
    let siteDfd = $q.defer();

    const isAnyPermissions = function(currentState, permissions) {
      if (currentState === 'analyticsSite') {
        return permissions.viewAnalytics;
      } else if (currentState === 'visitors') {
        return permissions.viewAnalyticsVisitorList;
      }
      return undefined;
    };

    const isTabPermissionsDenied = function(permissions) {
      let currentState = $state.current.name.split('.');
      currentState = currentState[currentState.length - 1];
      return (
        (!isAnyPermissions(currentState, permissions) &&
          currentState === 'analyticsSite') ||
        (!isAnyPermissions(currentState, permissions) &&
          currentState === 'visitors')
      );
    };

    $scope.setBreadcrumbs = function(state, siteId, siteName) {
      breadcrumbs.set({
        path: [{ name: 'sites' }, { name: 'list' }, { name: state }],
        itemName:
          state === 'details' || state === 'modify'
            ? `${siteId}. ${siteName}`
            : undefined
      });
    };

    $scope.invalidateSiteData = function() {
      siteDfd = sitesFactory
        .getData(id)
        .then(_site => {
          // eslint-disable-next-line no-multi-assign
          const site = ($scope.site = _site);
          const tabs = [
            { name: 'sites.macs', sref: 'section.sites.view.macExceptions' },
            { name: 'sites.overrides', sref: 'section.sites.view.overrides' },
            { name: 'sites.details', sref: 'section.sites.view.details' }
          ];

          if (site.permissions.viewAnalytics) {
            tabs.unshift({
              name: 'sites.analytics',
              sref: 'section.sites.view.analyticsSite'
            });
          }

          if (site.permissions.viewAnalyticsVisitorList) {
            tabs.unshift({
              name: 'sites.visitors',
              sref: 'section.sites.view.visitors'
            });
          }

          navigationPanel.setTabs(tabs);
          // $scope.setBreadcrumbs("sites>details", site.id + '. ' + site.name);

          let currentState = $state.current.name.split('.');
          currentState = currentState[currentState.length - 1];
          if (
            (!isAnyPermissions(currentState, site.permissions) &&
              currentState === 'analyticsSite') ||
            (!isAnyPermissions(currentState, site.permissions) &&
              currentState === 'visitors')
          ) {
            $state.go(APP_CONFIG.defaultState);
            return false;
          }
          return _site;
        })
        .catch(rej => {
          $state.go('section.sites.sitesList');
          return $q.reject(rej);
        });

      return siteDfd;
    };

    $scope.siteDataReady = function() {
      return siteDfd;
    };

    if (id) {
      $scope.invalidateSiteData();
    }

    // Redirect if no permissions on this tab
    $scope.$on('$stateChangeSuccess', function() {
      if ($scope.site && isTabPermissionsDenied($scope.site.permissions)) {
        $state.go(APP_CONFIG.defaultState);
        return false;
      }
      return undefined;
    });

    // optimistic set tabs before response arrives
    navigationPanel.setTabs([
      {
        name: 'sites.details',
        sref: id ? 'section.sites.view.details' : 'section.sites.create.details'
      }
    ]);
  });
