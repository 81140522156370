/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.admin')
  .controller('monitoringVisitorGeneralCtrl', function(
    $scope,
    $modal,
    $filter,
    $rootScope,
    $state,
    helpers,
    monitoringFactory,
    navigationPanel,
    monitoringCollectionFactory,
    countriesFactory
  ) {
    let exportFlag = false;
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      isEditMode: false,
      isBusy: true,
      noDataYetAvailable: false,
      isSelectAllVisitors: false,
      shouldBeOverride: [],
      selectedVisitors: [],
      id: $state.params.id
    });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      countries: [],
      cities: [],
      gender: ['GENDER_MALE', 'GENDER_FEMALE', 'Unknown']
    });

    countriesFactory.getCountries().then(function(data) {
      angular.copy(data, $scope.options.countries);
    });

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      export() {
        exportFlag = true;
        return monitoringFactory
          .exportList(state, 'exportVisitorsGeneral')
          .finally(() => {
            exportFlag = false;
          });
      },
      save() {
        $scope.setSubmitted();
        if ($scope.sectionForm.$invalid) {
          return true;
        }

        $scope.setBusy(true);
        return monitoringFactory
          .saveVisitorDetails($scope.visitor, state)
          .then(
            function() {
              state.shouldBeOverride = [];
              $scope.sectionForm.$setPristine();
              $scope.state.isEditMode = false;
              monitoringFactory
                .getVisitorModificationHistory(state.id)
                .then(function(items) {
                  $scope.history = items;
                });
            },
            function() {}
          )
          .finally(function() {
            $scope.setBusy(false);
          });
      },
      cancel() {
        $scope.setSubmitted(false);
        $scope.state.isEditMode = false;
        init(); // load actual visitor info again to populate model with previous data
      },
      addOccupation(occupations) {
        const stateModal = {};
        const labels = {
          title: 'admin.monitoring.occupation.addModalTitle',
          fieldLabel: 'admin.monitoring.occupation.stringLabel'
        };
        helpers
          .addStringModal(stateModal, labels)
          .result.then(function(data) {
            if (!~occupations.indexOf(data.name)) {
              occupations.push(data.name);
              handlers.addToOverrides('occupation');
              $scope.sectionForm.$setDirty();
            } else {
              const labelsInfo = {
                title: 'admin.monitoring.occupation.duplicationTitle',
                message: 'admin.monitoring.occupation.stringDuplicationMessage'
              };
              helpers.infoModal(labelsInfo);
            }
          })
          .finally(function() {
            state.isBusy = false;
          });
      },
      removeOccupation(string) {
        helpers
          .confirmationModal(
            '',
            'admin.monitoring.occupation.removeConfirmation',
            true
          )
          .result.then(function() {
            $scope.visitor.occupation = $scope.visitor.occupation.filter(
              function(occupation) {
                return occupation !== string;
              }
            );
            handlers.addToOverrides('occupation');
            $scope.sectionForm.$setDirty();
          });
      },
      addToOverrides: window._.throttle(function(key) {
        state.shouldBeOverride = state.shouldBeOverride
          .concat(angular.isArray(key) ? key : [key]) // key may be single item or array of items
          // check for uniqueness
          .filter(function(item, idx, array) {
            return array.indexOf(item) === idx;
          });
      }, 500),
      // handlers.addToOverrides = helpers.addToArrayIfNotExist(state.shouldBeOverride);
      getDeviceOSString(device, os) {
        const i18n = $filter('i18n');
        const na = i18n('sites.labels.n/a');
        return `${device ? i18n(`DAConstants.${device}`) : na} / ${os || na}`;
      }
    });

    const callbacks = {
      addToNewCollection(data) {
        if (!$scope.visitor.collections) {
          $scope.visitor.collections = [];
        }
        if (!~$scope.visitor.collections.indexOf(data)) {
          $scope.visitor.collections.push(data);
        }
      },
      addToCollection() {
        init();
      }
    };

    // Send current state to each rootHandler function
    const fromRootHandlers = ['addToNewCollection', 'addToCollection'];
    fromRootHandlers.forEach(function(key) {
      $scope.handlers[key] = function() {
        return $scope.rootHandlers[key](state, callbacks[key], true);
      };
    });
    $scope.handlers.noServerRedirect = $scope.rootHandlers.noServerRedirect;

    // Watches >>>
    $scope.$watch('visitor.country', function(newVal, oldVal) {
      if (!newVal) {
        return false;
      }
      if (oldVal) {
        $scope.visitor.city = '';
      }
      options.cities = [];
      return countriesFactory.getCitiesForCountry(newVal).then(function(data) {
        angular.copy(data, options.cities);
      });
    });

    $scope.$on('$stateChangeStart', function(event, toState, toParams) {
      if ($scope.state.isEditMode && toState.name !== 'login.signIn') {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.state.isEditMode = false;
          $state.go(toState, toParams);
        });
      }
    });
    // / Watches <<<

    function init() {
      state.noDataYetAvailable = false;
      state.isEditMode = false;
      state.isBusy = true;
      state.shouldBeOverride = [];

      monitoringFactory
        .getVisitorDetails(state.id)
        .then(function(data) {
          const images = data.visitor.images;
          return helpers
            .filterAsync(images, function(img) {
              return helpers
                .preloadImage(img)
                .then(function() {
                  return true;
                })
                .catch(function() {
                  return false;
                });
            })
            .then(function(filteredImages) {
              // eslint-disable-next-line no-param-reassign
              data.visitor.images = filteredImages;
              return data;
            });
        })
        .then(
          function(data) {
            $scope.visitor = data.visitor;
            state.selectedVisitors = [data.visitor.macAddress];
            options.activeImage = data.visitor.images[0];
          },
          function(res) {
            // >>> DEPRECATED! <<<
            // $scope.showErrorModal(res.status);
            if (res.status >= 0) {
              $state.go('monitoring.visitors.list');
            }
          }
        )
        .finally(function() {
          state.isBusy = false;
        });

      monitoringFactory
        .getVisitorModificationHistory(state.id)
        .then(function(data) {
          $scope.history = data;
        });
    }

    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => $scope.sectionForm.$pristine,
        type: () => 'confirm',
        isShown: () => $scope.state.isEditMode,
        clickHandler: () => {
          $scope.handlers.save();
        }
      },
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => $scope.state.isEditMode,
        // type: () => '',
        isShown: () => !$scope.state.isEditMode,
        clickHandler: () => {
          $scope.state.isEditMode = true;
        }
      },
      {
        title: () => 'admin.buttons.export',
        isBusy: () => exportFlag,
        isDisabled: () => state.isEditMode,
        type: () => 'action-1',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.export();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.state.isEditMode,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      },
      {
        title: () => 'admin.buttons.close',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => !$scope.state.isEditMode,
        clickHandler: () => {
          $scope.close();
        }
      }
    ]);

    // Helpers >>>
    function cancelConfirmModal() {
      const i18n = $filter('i18n');
      const title = i18n(
        'admin.userManagement.modals.cancelConfirmation.title'
      );
      const message = i18n('admin.monitoring.cancelConfirmation');
      return helpers.confirmationModal(title, message).result;
    }
    // / Helpers <<<
  });
