/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.helpers').directive('fileUpload', function() {
  return {
    template:
      '<label data-hook-id="str_buttonLabel">{{buttonText}}<input type="file"></label>',
    replace: true,
    scope: {
      buttonText: '@',
      onFileChosen: '&'
    },
    link(scope, element) {
      element.find('input').on('change', function(event) {
        if (event.originalEvent.target.files[0]) {
          scope.onFileChosen({ file: event.originalEvent.target.files[0] });
          window.$(this).val('');
          scope.$apply();
        }
      });
    }
  };
});
