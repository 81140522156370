/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.campaigns')
  .controller('linkWidgetCtrl', function(
    $scope,
    $modal,
    widgetsFactory,
    $timeout
  ) {
    $scope.linkedWidgets = [];
    $scope.activeWidget = undefined;

    const init = function() {
      if ($scope.campaignId) {
        widgetsFactory
          .getWidgetsForCampaign($scope.campaignId)
          .then(function(widgets) {
            $scope.linkedWidgets = widgets;
            $scope.activeWidget =
              $scope.linkedWidgets.length > 0 ? $scope.linkedWidgets[0] : {};
          })
          .catch(function() {
            $scope.addWidget();
          });
      } else {
        // if its a new campaign
        $scope.addWidget();
      }
    };

    $scope.setCurrentAuthChain = function(chain) {
      $scope.currentAuthChain = chain;
      if ($scope.getFirstWidgetType() === 'poll') {
        return;
      }
      $scope.linkedWidgets = [];
      $scope.addWidget();
      $scope.resetSliderPosition();
    };

    $scope.setActiveWidget = function(widget) {
      $scope.activeWidget = widget;
    };

    $scope.addWidget = function() {
      $scope.linkedWidgets.push({ isNew: true });
      $scope.activeWidget =
        $scope.linkedWidgets[$scope.linkedWidgets.length - 1];
      $timeout(function() {
        $scope.slide('right');
      });
    };

    $scope.removeWidget = function(index) {
      $scope.linkedWidgets.splice(index, 1);
      $scope.activeWidget = $scope.linkedWidgets[0]
        ? $scope.linkedWidgets[0]
        : {};
      $scope.resetSliderPosition();
    };

    $scope.openWidgetsModal = function() {
      const scope = $scope.$new();
      scope.widgetIndex = $scope.linkedWidgets.indexOf($scope.activeWidget);
      scope.campaignState = $scope.campaignState;
      scope.chainId = $scope.currentAuthChain.chainId;
      scope.campaignId = $scope.campaignId;
      scope.linkedWidgetsIds = $scope.linkedWidgets
        .map(function(widget) {
          return widget.id;
        })
        .filter(function(id) {
          return !!id;
        });
      scope.firstWidgetType = $scope.getFirstWidgetType();
      $modal
        .open({
          templateUrl: 'app/sections/campaigns/widgetsModal.html',
          windowClass: 'modal_link-widget',
          controller: 'widgetsModalCtrl',
          scope
        })
        .result.then(function(data) {
          if (data.reset) {
            $scope.linkedWidgets = [{}];
            $scope.activeWidget = $scope.linkedWidgets[0];
            $timeout($scope.resetSliderPosition);
          }
          angular.extend($scope.activeWidget, data.widget);
        });
    };

    $scope.getFirstWidgetType = function() {
      if (!$scope.linkedWidgets.length) return '';
      return $scope.linkedWidgets[0].contentType
        ? $scope.linkedWidgets[0].contentType
        : '';
    };

    $scope.isWidgetChangeable = function() {
      if ($scope.getFirstWidgetType() !== 'poll') return true;
      if ($scope.campaignState === 'draft') return true;
      if (!$scope.activeWidget.contentType) return true;
      return false;
    };

    $scope.isWidgetRemovable = function() {
      if ($scope.getFirstWidgetType() !== 'poll') return true;
      if ($scope.campaignState === 'draft') return true;
      return false;
    };

    $scope.$watch(
      function() {
        if ($scope.linkedWidgets) {
          return $scope.linkedWidgets.length;
        }

        return false;
      },
      function(newVal, oldVal) {
        // add new empty widget, if the last one was removed
        if (oldVal > 0 && newVal === 0) {
          $scope.addWidget();
        }
      }
    );

    $scope.$watch('isEditMode', function(newVal, oldVal) {
      // re-init component on exit from edit mode
      if (!newVal && oldVal) {
        init();
      }
    });

    init();
  });
