/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.campaigns')
  .controller('widgetsModalCtrl', function(
    $scope,
    widgetsFactory,
    helpers,
    $timeout
  ) {
    $scope.state = {
      currentPage: 1,
      itemsOnPage: 4,
      itemsOnPageValues: [4, 16, 32, 64, 128],
      searchQuery: '',
      appliedSearchQuery: '',
      searchStringMode: ''
    };

    $scope.applyFilters = function(searchString, filterType) {
      if (filterType.indexOf('search') > -1) {
        const components = filterType.split(':');
        $scope.state.searchStringMode = components[2] || '';
      }
      widgetsFactory
        .applyFiltersForCampaign(
          searchString,
          $scope.state.searchStringMode,
          $scope.chainId,
          $scope.campaignId,
          $scope.campaignType,
          $scope.widgetIndex === 0 && $scope.campaignState === 'draft'
            ? ''
            : $scope.firstWidgetType,
          $scope.widgetIndex,
          $scope.linkedWidgetsIds,
          filterType === 'updateList'
        )
        .success(function(data) {
          $scope.searhDropdown = data.searchDropDown;
          if (filterType.indexOf('updateList') > -1) {
            $scope.totalItems = data.itemsTotal;
            $scope.state.appliedSearchQuery = $scope.state.searchQuery;
            $timeout($scope.getPage);
          } else {
            $scope.totalSearchItems = data.itemsTotal;
          }
        });
    };

    $scope.updateSearch = function(title) {
      $scope.state.searchQuery = title;
      $scope.applyFilters(title, 'updateList');
    };

    $scope.getPage = function() {
      const filters = {
        filterBySearchString: $scope.state.appliedSearchQuery,
        filterByType: $scope.campaignType,
        filterByFirstWidgetType:
          $scope.widgetIndex === 0 && $scope.campaignState === 'draft'
            ? ''
            : $scope.firstWidgetType,
        widgetOrder: $scope.widgetIndex,
        linkedWidgetsIds: $scope.linkedWidgetsIds,
        chainId: $scope.chainId,
        campaignId: +$scope.campaignId,
        updateList: true,
        searchStringMode: ''
      };

      widgetsFactory
        .getListForCampaign(
          filters,
          $scope.state.currentPage,
          $scope.state.itemsOnPage
        )
        .then(function(widgets) {
          $scope.widgetsList = widgets;
        });
    };

    $scope.selectWidget = function(widget) {
      const output = {
        widget,
        reset: false
      };
      if (
        $scope.widgetIndex !== 0 ||
        $scope.firstWidgetType === '' ||
        $scope.campaignState !== 'draft'
      ) {
        return $scope.$close(output);
      }
      if (
        $scope.firstWidgetType !== widget.contentType &&
        ($scope.firstWidgetType === 'poll' || widget.contentType === 'poll')
      ) {
        helpers
          .confirmationModal(
            '',
            'Your actions will reset all other linked widgets.'
          )
          .result.then(function() {
            output.reset = true;
            $scope.$close(output);
          });
      } else {
        $scope.$close(output);
      }
      return undefined;
    };

    $scope.applyFilters('', 'updateList');
  });
