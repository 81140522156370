/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.eyedropper')
  .factory('eyedropperService', function($timeout, $q) {
    let _eyedropper = null;
    let _isActive = false;
    const colorChangeCbs = window.$.Callbacks();
    const colorSelectCbs = window.$.Callbacks();

    const register = function(eyedropper) {
      _eyedropper = eyedropper;
      return {
        onCoordinatesChanged,
        onColorChosen
      };
    };

    const unregister = function() {
      _eyedropper = null;
    };

    const update = function() {
      const dfd = $q.defer();
      if (_eyedropper) {
        _eyedropper.update(function(canvas) {
          _eyedropper.canvas = canvas;
          _eyedropper.ctx = canvas.getContext('2d');
          dfd.resolve();
        });
      } else {
        dfd.reject();
      }
      return dfd.promise;
    };

    const rgbToHex = function(r, g, b) {
      if (r > 255 || g > 255 || b > 255) {
        throw new Error('Invalid color component');
      }
      // eslint-disable-next-line no-bitwise
      return ((r << 16) | (g << 8) | b).toString(16);
    };

    const getHexColorByCoord = function(x, y) {
      const data = _eyedropper.ctx.getImageData(x, y, 1, 1).data;
      return `#${`000000${rgbToHex(data[0], data[1], data[2])}`.slice(-6)}`;
    };

    function onCoordinatesChanged(e) {
      if (!_eyedropper || !_eyedropper.canvas || !_isActive) return;
      colorChangeCbs.fire(getHexColorByCoord(e.x, e.y));
    }

    function onColorChosen(e) {
      if (!_eyedropper || !_eyedropper.canvas || !_isActive) return;
      toggleActive(false);
      colorSelectCbs.fire(getHexColorByCoord(e.x, e.y));
    }

    function toggleActive(val) {
      _isActive = angular.isDefined(val) ? !!val : !_isActive;
    }

    const isActive = function() {
      return _isActive;
    };

    return {
      registerEyedropper: register,
      unregisterEyedropper: unregister,
      toggleActive,
      isActive,
      update,
      onColorChange(cb) {
        colorChangeCbs.add(cb);
        return function() {
          colorChangeCbs.remove(cb);
        };
      },
      onColorSelect(cb) {
        colorSelectCbs.add(cb);
        return function() {
          colorSelectCbs.remove(cb);
        };
      }
    };
  });
