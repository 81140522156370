/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.analytics2').constant('ANALYTICS_REQUESTS_CONFIG', {
  analyticsCampaignServlet: 'analyticsCampaign',
  campaignsServlet: 'campaigns',
  sitesServlet: 'sites',
  widgetsServlet: 'widgets',
  entitiesServlet: 'entities',
  entitiesWasServlet: 'entities_was',
  analyticsSiteServlet: 'analyticsSite',
  analyticsSystemServlet: 'analyticsSystem',
  analyticsVisitorServlet: 'analyticsVisitor',
  getGeneralInfo: 'getGeneralInfo',
  getDataByVisitors: 'getDataByVisitors',
  getDataByTrafficUsage: 'getDataByTrafficUsage',
  getDataByNumberOfVisits: 'getDataByNumberOfVisits',
  getDataByVisitorsAgeAndGender: 'getDataByVisitorsAgeAndGender',
  getDataByNewAndReturningVisitors: 'getDataByNewAndReturningVisitors',
  getDataByNotReturningVisitors: 'getDataByNotReturningVisitors',
  getDataByCampaignsViews: 'getDataByCampaignsViews',
  getDataByCampaigns: 'getDataByCampaigns',
  getDataByDevices: 'getDataByDevices',
  getDataByDemographicInfo: 'getDataByDemographicInfo',
  getDataByHours: 'getDataByHours',
  getDataByWeek: 'getDataByWeek',
  getDataByMonths: 'getDataByMonths',
  getDataByYears: 'getDataByYears',
  getDataByComparison: 'getDataByComparison',
  getVacantSites: 'getVacantSites',
  getCampaignsTimeline: 'getDataTimeline',
  getDataByDays: 'getDataByDaysNew',
  getDataByDaysAndSites: 'getDataByDaysAndSites',
  getDataBySites: 'getDataBySitesNew',
  getDataByDevicesNew: 'getDataByDevicesNew',
  getDataBySiteTags: 'getDataBySiteTags',
  getDataByCitiesAndCountries: 'getDataByCitiesAndCountries',
  getDataByNumberOfViews: 'getDataByNumberOfViews',
  getDataByTraffic: 'getDataByTraffic',
  getAmountOfViewsAndVisitors: 'getAmountOfViewsAndVisitors',
  getAmountOfWidgets: 'getAmountOfWidgets',
  getAmountOfCampaigns: 'getAmountOfCampaigns',
  getAmountOfSites: 'getAmountOfSites',
  getAmountOfEntities: 'getAmountOfEntities',
  getDataByTags: 'getDataByTags',
  getDataByTagsForCampaign: 'getDataByTagsForCampaign',
  getAllSites: 'getAllSites',
  getAllSitesForCampaign: 'getAllSitesForCampaign',
  getAllEntities: 'getAllEntitiesPermissions',
  getAllEntitiesForCampaign: 'getAllEntitiesPermissionsForCampaign',
  getAllCampaigns: 'getAllCampaigns',
  getAmountOfVisitors: 'getAmountOfVisitors',
  getDataForSitesGeneralInfo: 'getDataForSitesGeneralInfo',
  getDataByViewersPortrait: 'getDataByViewersPortrait',
  getDataByCurrentWidgets: 'getDataByCurrentWidgets',
  getPollInfo: 'getPollInfo',
  getQuantityOfRegisteredUsers: 'getQuantityOfRegisteredUsers',
  getDataByOtherVisitedSites: 'getDataByOtherVisitedSites',
  getAllSystemSites: 'getAllSystemSites',

  skipErrorsCodes: [0, -1, 204, 244, 245].concat(window._.range(300, 400, 1))
});
