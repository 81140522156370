/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.campaigns')
  .controller('campaignsViewCtrl', function(
    $scope,
    $modal,
    $state,
    $q,
    $sce,
    $window,
    $filter,
    helpers,
    $timeout,
    $parse,
    navigationPanel,
    breadcrumbs,
    localStorage,
    campaignsFactory,
    entitiesFactory,
    sitesFactory,
    conversionFactory,
    modificationHistory,
    i18nFactory,
    sharedRequestsFactory
  ) {
    const KM_BY_DEGREE = 111.111;
    let listState;
    const campaignCancel = {};
    const id = $state.params.id;
    let unregisterLivePrognosis;
    let unregisterSiteLoading;
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      canCreate: true,
      toggleMap: false
    });

    $scope.domainLink = window.location.origin;

    const updateMapMarkers = function() {
      state.toggleMap = !state.toggleMap; // toggle map markers
    };

    $scope.createState = $state.current.name.indexOf('.create') !== -1;
    $scope.Object = Object; // Set js Object in angular var

    $scope.lastGeoSteps = [];

    // Condition: edit mode or not
    if ($scope.createState) {
      $scope.additional = {};
      $scope.campaignType =
        $state.params.type.toLowerCase() === 'classb'
          ? 'advertiser'
          : 'partner';
      listState = `section.campaigns.${$scope.campaignType}List`;

      breadcrumbs.set({
        path: [
          { name: 'campaigns' },
          { name: 'list' },
          {
            name: $scope.campaignType === 'partner' ? 'classA' : 'classB',
            sref: `section.campaigns.${$scope.campaignType}List`
          },
          { name: 'create' }
        ]
      });

      navigationPanel.setTabs([
        // {name: 'campaigns.analytics', sref: "section.campaigns.analytics", isDisabled: true},
        { name: 'campaigns.details', sref: 'section.campaigns.create' }
      ]);
    } else {
      $scope.editMode = false;
      $scope.demoGenarated = true;
      $scope.demoLink = '';
      $scope.demoCampaign = '';
      $scope.exportCampaign = false;
      // $scope.setTabs([
      //   // {name: 'campaigns.analytics', sref: "section.campaigns.analytics", go: {id: +id}},
      //   {name: 'campaigns.details', sref: "section.campaigns.view", go: {id: +id}}
      // ]);
      $scope.campaignHealth = [
        '__status-1',
        '__status-2',
        '__status-3',
        '__status-4',
        '__status-5'
      ];
    }

    // State variables
    $scope.campaignState = {
      'always running': '__always-running',
      running: '__running',
      draft: '__draft',
      stopped: '__stopped',
      stoppedreserved: '__stopped',
      scheduled: '__scheduled',
      finished: '__finished'
    };

    // Msg site loading and live prognosis
    $scope.naDataHandlerFlag = false;
    const cancelModalMsg = function() {
      $scope.naDataHandlerFlag = true;
    };
    $scope.naDataHandler = function(param) {
      if (!$scope.naDataHandlerFlag && param) {
        const scope = $scope.$new();
        scope.cancelModalMsg = cancelModalMsg;
        scope.msg = 'naHandlerMSG';
        scope.title = 'infoMsgTitle';
        $modal.open({
          templateUrl: 'app/core/views/infoModal.html',
          windowClass: 'wcm-msg',
          scope
        });
      }
    };

    // Options variables
    $scope.focused = {};
    $scope.submitted = false;
    $scope.validators = campaignsFactory.customValidators;
    $scope.validation = {};
    $scope.options = campaignsFactory.getOptions();
    $scope.options.makeInfinityFlag = false;
    $scope.options.changeLaunchDateFlag = false;
    $scope.options.changeEndDateFlag = false;
    $scope.options.defaultCheckedSites = {};
    $scope.options.manualyCheckedSites = {};
    $scope.options.multiSelect = {
      sCity: [],
      sCountry: [],
      sEntity: [],
      city: [],
      country: [],
      occupation: [],
      interests: [],
      campaigns: [],
      sites: [],
      tags: []
    };

    // Default campaign variable
    $scope.campaign = {
      id: 0,
      isDefault: false,
      name: '',
      type: $scope.campaignType,
      targetUniqueViews: 0,
      targetReach: 0,
      launchDate: 0,
      endDate: 0,
      workingDates: [],
      calendarMode: 'period',
      timeFrameHours: [],
      timeFrameDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      sites: {},
      siteSpecific: false,
      exclusive: 0,
      deliveryLimitPerMAC: 0,
      tags: [],
      labels: [],
      widgets: [],
      chainId: 0,
      externalCounters: {
        adriver: {
          viewLink: '',
          clickLink: ''
        }
      },
      geoFilter: {
        city: [],
        country: [],
        steps: [],
        numberOfSessions: { enable: false, from: 0, to: 0 },
        coords: []
      },
      targeting: {
        device: {
          deviceType: [],
          priceGroup: [],
          os: [],
          model: [],
          cellOperator: []
        },
        profile: {
          gender: [],
          age: [],
          maritalStatus: [],
          numberOfKids: [],
          incomeLevel: [],
          country: [],
          city: [],
          profileName: [],
          occupation: [],
          interests: [],
          includeUnknown: false
        },
        retargeting: {
          campaignInteractionType: '',
          sites: [],
          campaigns: [],
          campaignWithTagInteractionType: '',
          tags: []
        }
      }
    };

    $scope.sitesFilter = [
      {
        title: 'classAEntities',
        name: 'entity',
        values: [],
        trackBy: 'id'
      },
      {
        title: 'tagsClasses',
        name: 'tags',
        values: [],
        trackBy: 'name'
      },
      {
        title: 'tagsTypes',
        name: 'tags',
        values: [],
        trackBy: 'name'
      }
    ];

    $scope.filterSteps = [];
    // Search variables
    $scope.siteSearchFilter = {
      name: '',
      partnerId: ''
    };

    // Default checkboxes flags
    $scope.flags = {
      allDays: false,
      allDeviceTypes: false,
      allPriceGroups: false,
      allOs: false,
      allGenders: false,
      allAge: false,
      allMaritalStatus: false,
      allNumberOfKids: false,
      allIncomeLevel: false
    };

    // Create campaign tabs
    $scope.tabsBlock = [
      'geoLocationTab',
      'deviceTab',
      'profileTab',
      'retargetingTab'
    ];

    // / ---------------------------------

    // Live-prognosis variables -----------
    $scope.lpState = {
      data: campaignsFactory.composeLivePrognosisData($scope.campaign, {}),
      isFetching: false
    };
    function livePrognosisDataHandler(data) {
      $scope.lpState.data = campaignsFactory.composeLivePrognosisData(
        $scope.campaign,
        data
      );
      $scope.lpState.data.sites = $scope.campaign.sites;
    }

    // eslint-disable-next-line prefer-const
    unregisterLivePrognosis = campaignsFactory.initLivePrognosis($scope, {
      debounceTimeout: 1000,
      refreshEvent: 'livePrognosis:refresh',
      onDataReceived: livePrognosisDataHandler,
      onRequestStart() {
        if (
          ($scope.campaignForm &&
            $scope.campaignForm.campaignEndDate &&
            $scope.campaignForm.campaignEndDate.$invalid &&
            $scope.editMode) ||
          !updateTimeFrameHours()
        ) {
          return true;
        }
        $scope.lpState.isFetching = true;
        return undefined;
      },
      onRequestFinish() {
        $scope.lpState.isFetching = false;
      }
    });

    $scope.refreshLivePrognosis = function() {
      $scope.$emit('livePrognosis:refresh');
    };
    // / ----------------------------------

    // Site-loading variables -------------
    function siteLoadingDataHandler(data) {
      if ($scope.editMode && data.sites) {
        $scope.$evalAsync(function() {
          angular.forEach(data.sites, function(site) {
            // eslint-disable-next-line no-param-reassign
            site.className = $scope.getSiteColor(site.currentLoad);
          });
          $scope.options.availableSites = data.sites;
          $scope.options.partners = [];
          let tempArr = [];
          data.sites.forEach(function(item) {
            if (tempArr.indexOf(item.partnerId) === -1) {
              tempArr.push(item.partnerId);
              $scope.options.partners.push({
                ownerId: item.partnerId,
                ownerName: item.ownerName
              });
            }
          });
          tempArr = [];
          $scope.naDataHandler(data.sites[0].currentLoad === -1);
        });
      }
    }

    // eslint-disable-next-line prefer-const
    unregisterSiteLoading = sitesFactory.initSiteLoading($scope, {
      debounceTimeout: 1000,
      refreshEvent: 'siteLoading:refresh',
      onDataReceived: siteLoadingDataHandler,
      onRequestStart() {
        if (
          ($scope.campaignForm &&
            $scope.campaignForm.campaignEndDate &&
            $scope.campaignForm.campaignEndDate.$invalid) ||
          !updateTimeFrameHours()
        ) {
          return true;
        }
        return undefined;
      },
      onRequestFinish() {}
    });
    $scope.refreshSiteLoading = function(param) {
      $scope.$emit('siteLoading:refresh', param);
    };
    // / ----------------------------------

    // Init Main Data ------------------
    function init() {
      $scope.setBusy(true);
      if ($scope.createState) {
        $scope.showSlidedownBlock = true;
        $scope.showSlidedownBlock2 = true;
        $scope.showSlidedownTags = true;
        sharedRequestsFactory
          .canCreate(`${$scope.campaignType}Campaign`, 'campaignFalse')
          .then(function() {
            sitesFactory
              .getSitesListSimple({ campaignId: $scope.campaign.id })
              .then(function(res) {
                $scope.editMode = true;
                $scope.options.availableSites = res.data.sites;
                $scope.options.multiSelect.sites = [];
                $scope.$evalAsync(function() {
                  $scope.options.availableSites.forEach(function(site) {
                    if (site.isEditable)
                      $scope.options.multiSelect.sites.push(site);
                    // eslint-disable-next-line no-param-reassign
                    site.currentLoad = -1;
                  });
                });
                getAvailableValues();
              });
          });
      } else {
        getCampaignData();
      }
    }

    init();
    // / --------------------------------

    /** * CAMPAIGN VIEW PART ** */

    /** * getCampaignData method
     * receive and proceed data from server
     * ** */
    function getCampaignData() {
      $scope.setBusy(true);
      const cData = campaignsFactory
        .campaignGetData(id)
        .success(function(data) {
          angular.extend($scope.campaign, data.campaign.general);
          if (data.campaign.targeting) {
            angular.extend(
              $scope.campaign.targeting.device,
              data.campaign.targeting.device
            );
            angular.extend(
              $scope.campaign.targeting.profile,
              data.campaign.targeting.profile
            );
            angular.extend(
              $scope.campaign.targeting.retargeting,
              data.campaign.targeting.retargeting
            );
          }

          $scope.campaign.calendarMode =
            $scope.campaign.calendarMode || 'period';

          $scope.campaign.chainId = data.campaign.authChain.chainId;
          $scope.analyticsPermissions = data.campaign.analyticsPermissions;

          breadcrumbs.set({
            path: [
              { name: 'campaigns' },
              { name: 'list' },
              {
                name:
                  $scope.campaign.type.toLowerCase() === 'partner'
                    ? 'classA'
                    : 'classB',
                sref: `section.campaigns.${$scope.campaign.type.toLowerCase()}List`
              },
              { name: 'view' }
            ],
            itemName: `${$scope.campaign.id}. ${$scope.campaign.name}`
          });

          $scope.status = data.campaign.status;
          $scope.additional = data.campaign.additional;
          $scope.authChain = data.campaign.authChain;
          $scope.options.launchDate = data.campaign.general.launchDate;
          $scope.options.endDate = data.campaign.general.endDate;
          listState = `section.campaigns.${$scope.campaign.type.toLowerCase()}List`;

          if ($scope.options.launchDate < 0) {
            const lD = new Date(); // current date
            $scope.options.launchDate = lD.setMinutes(lD.getMinutes() + 20); // current + 20 minutes
          }

          if ($scope.options.endDate <= 0) {
            if ($scope.options.endDate === 0) {
              $scope.options.makeInfinityFlag = true;
            }
            const eD = new Date();
            eD.setMonth(eD.getMonth() + 3); // current date + 3 months
            // current date + 3 months + 20 minutes
            $scope.options.endDate = eD.setMinutes(eD.getMinutes() + 20);
          }

          $scope.options.timeFrameHours = [];
          let i = 0;
          while (i < data.campaign.general.timeFrameHours.length) {
            $scope.options.timeFrameHours.push({
              startTime: conversionFactory.convertTime(
                data.campaign.general.timeFrameHours[i]
              ),
              endTime: conversionFactory.convertTime(
                data.campaign.general.timeFrameHours[i + 1]
              )
            });
            i += 2;
          }

          const campaignDemoType =
            data.campaign.general.type === 'Advertiser'
              ? 'Partner'
              : 'Advertiser';
          if ($scope.authChain.hasPartner && $scope.authChain.hasAdvertiser) {
            campaignsFactory
              .getCampaignsForDemo(campaignDemoType)
              .success(function(data) {
                $scope.options.campaignsForDemo = data.campaigns;
              });
          } else {
            $scope.getDemoLink();
          }
        })
        .success(getAvailableValues)
        .error(function() {
          $state.go('section.campaigns.advertiserList');
        });

      const sData = sitesFactory
        .siteGetListForCampaign(id)
        .success(function(data) {
          $scope.mailToLinks = data.mailToLinks;
          if (data.sites) {
            data.sites.forEach(function(site) {
              $scope.campaign.sites[site.id] = true;
              $scope.options.defaultCheckedSites[site.id] = true;
              $scope.options.manualyCheckedSites[site.id] = true;
            });
          }
          $scope.options.availableSites = data.sites;
        });
      const mData = modificationHistory
        .get('Campaign', id)
        .then(function(history) {
          $scope.history = history;
        });

      return $q
        .all([cData, sData, mData])
        .then(function() {
          campaignCancel.general = angular.copy($scope.campaign);
          campaignCancel.additional = angular.copy($scope.additional);
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    }

    $scope.setStatus = function(items, showError) {
      let status = 'empty';
      if (showError) {
        return 'error';
      }
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const v in items) {
        if (angular.isArray(items[v])) {
          status = $scope.setStatus(items[v]);
          if (status !== 'empty') break;
        } else if (items[v]) return 'not-empty';
      }
      return status;
    };

    /** * getDemoLink method
     * requests demo link for current campaign
     * ** */
    $scope.getDemoLink = function getDemoLink(id = 0, name) {
      $scope.demoGenarated = false;
      let partnerId;
      let advertiserId;

      $scope.demoCampaign = name;
      if ($scope.campaign.type === 'Advertiser') {
        partnerId = id;
        advertiserId = $scope.campaign.id;
      } else if ($scope.campaign.type === 'Partner') {
        advertiserId = id;
        partnerId = $scope.campaign.id;
      }
      campaignsFactory
        .generateDemoLink($scope.authChain.chainId, partnerId, advertiserId)
        .success(function(data) {
          $scope.demoLink = data.demoLink;
          $scope.demoGenarated = true;
        });
    };

    // Mail to links
    $scope.mailTo = function() {
      // eslint-disable-next-line no-param-reassign
      if ($scope.mailToLinks[0]) {
        $window.location = `mailto:${$scope.mailToLinks.join(',')}`;
      } else {
        return false;
      }
      return undefined;
    };

    /** * END CAMPAIGN VIEW PART ** */

    /** * getAvailableValues method
     * get lists of available values for campaign
     * ** */
    function getAvailableValues() {
      $scope.setBusy(true);

      let eData;
      if ($scope.editMode) {
        eData = entitiesFactory
          .getOwnersList($scope.campaign.type)
          .success(function(data) {
            $scope.options.owners = data.owners || [];
            // add current campaign owner if it doesn't available for current user
            if (
              $scope.campaign.ownerId &&
              !window._.find($scope.options.owners, function(item) {
                return item.id === $scope.campaign.ownerId;
              })
            ) {
              $scope.options.owners.unshift({
                id: $scope.campaign.ownerId,
                name: $scope.additional.ownerName
              });
            }
          });

        updateTimeFrameDays();
        if (Number.isNaN(parseInt($scope.campaign.targetUniqueViews, 10))) {
          return;
        }

        // Watch Days Period changes
        $scope.$watch(
          function() {
            return {
              makeInfinityFlag: $scope.options.makeInfinityFlag,
              launchDate: $scope.options.launchDate,
              endDate: $scope.options.endDate
            };
          },
          function(val, old) {
            if (val.launchDate !== old.launchDate)
              $scope.options.changeLaunchDateFlag = true;
            if (val.endDate !== old.endDate)
              $scope.options.changeEndDateFlag = true;
            if (val.makeInfinityFlag !== old.makeInfinityFlag) {
              updateTimeFrameDays();
            }
          },
          true
        );
      }

      const prepareData = function prepareData(array, typeName) {
        return array.map(function(item) {
          // eslint-disable-next-line no-param-reassign
          item.className = typeName;
          return item;
        });
      };

      const cData = campaignsFactory
        .campaignGetAvailableValues($scope.campaign.type)
        .success(function(data) {
          $scope.availableAuthChains = data.authChains;
          if (!$scope.authChain) {
            $scope.authChain =
              $scope.availableAuthChains.length > 0
                ? $scope.availableAuthChains[0]
                : {};
          }
          $scope.options.os = data.os;
          $scope.options.priceGroup = data.priceGroup;
          $scope.options.deviceType = data.deviceType;
          $scope.options.priceGroup = data.priceGroup;
          $scope.options.gender = data.gender;
          $scope.options.age = data.age;
          $scope.options.maritalStatus = data.maritalStatus;
          $scope.options.numberOfKids = data.numberOfKids;
          $scope.options.incomeLevel = data.incomeLevel;
          $scope.options.interactionTypes = data.interactionTypes;
          $scope.options.numberOfSessions = data.numberOfSessions;
          $scope.options.tags = data.tags;
          $scope.options.labels = data.labels;
          $scope.options.tagsLabels = data.tags.concat(data.labels);

          // Set default "checked" states for some groups
          if ($scope.createState) {
            angular.extend($scope.campaign.targeting.device, {
              deviceType: angular.copy($scope.options.deviceType),
              priceGroup: angular.copy($scope.options.priceGroup),
              os: angular.copy($scope.options.os)
            });
          }
        });

      const sTags = sitesFactory
        .getAvailableValues($scope.campaign.type)
        .success(function(data) {
          let tags = data.tagsClasses.concat(data.tagsTypes);
          tags = tags.filter(function(item, pos) {
            return tags.indexOf(item) === pos;
          });
          $scope.options.multiSelect.tags = tags;
          $scope.options.multiSelect.sCity = data.city || [];
          $scope.options.multiSelect.sCountry = data.country || [];
          $scope.sitesFilter[1].values = prepareData(
            data.tagsClasses.map(item => ({ name: item })),
            'tagsClasses'
          );
          $scope.sitesFilter[2].values = prepareData(
            data.tagsTypes.map(item => ({ name: item })),
            'tagsTypes'
          );
        });

      const epData = entitiesFactory
        .getOwnersList('Partner')
        .success(function(data) {
          $scope.options.multiSelect.sEntity = data.owners;
          $scope.sitesFilter[0].values = prepareData(data.owners, 'entities');
        });

      updateTimeFrameHours();

      $q.all([eData, cData, sTags, epData]).finally(function() {
        if ($scope.editMode && !$scope.createState) {
          $scope.refreshSiteLoading(true);
        }
        $scope.setBusy(false);
      });
    }

    $scope.$watch('options.availableSites', function() {
      $scope.filterSites();
    });
    $scope.$watch('options.filteredSites', function() {
      $scope.getSitesPage();
    });
    $scope.$watch('options.sitesPage', function() {
      $scope.updateSelectInfo();
    });

    $scope.filterSites = function filterSites(partnerId, searchString) {
      const lowerCasedSearchString = searchString
        ? searchString.toLowerCase()
        : '';
      $scope.options.filteredSites = window._.filter(
        $scope.options.availableSites,
        function(site) {
          return (
            (partnerId ? site.partnerId === partnerId : true) &&
            (searchString
              ? helpers.searchInProps(
                  ['name', 'country', 'city', 'address'],
                  site,
                  lowerCasedSearchString
                ).length
              : true) &&
            filterByCoords($scope.campaign.geoFilter.coords, site.coords)
          );
        }
      );
      $scope.options.filteredSitesIsEditable = [];
      setOnlyIsEditable(
        $scope.options.filteredSites,
        $scope.options.filteredSitesIsEditable
      );
    };

    $scope.highlight = function(text, search) {
      if (!search) {
        return $sce.trustAsHtml(text);
      }
      return $sce.trustAsHtml(
        text.replace(new RegExp(search, 'gi'), '<b class="t-bold">$&</b>')
      );
    };

    $scope.getSiteTitle = function(site) {
      const addresses = [site.country, site.city, site.address]
        .filter(function(a) {
          return a;
        })
        .join(', ');
      return addresses ? `${site.name} (${addresses})` : site.name;
    };

    // Return items with isEditable param only
    function setOnlyIsEditable(sites, sitesIsEditable) {
      $scope.$evalAsync(function() {
        sites.forEach(function(site) {
          if (site.isEditable) sitesIsEditable.push(site);
        });
      });
    }

    $scope.getSitesPage = function getSitesPage() {
      const filteredSites = $scope.options.filteredSites || [];
      const currentPage = $scope.options.sitesCurrentPage || 1;
      const startIndex = (currentPage - 1) * $scope.options.sitesItemsPerPage;
      $scope.options.sitesPage = filteredSites.slice(
        startIndex,
        startIndex + $scope.options.sitesItemsPerPage
      );
      $scope.options.sitesPageIsEditable = [];
      setOnlyIsEditable(
        $scope.options.sitesPage,
        $scope.options.sitesPageIsEditable
      );
    };

    $scope.selectMethods = {
      getSelectedSites(sitesSet) {
        return sitesSet.filter(function(site) {
          return $scope.campaign.sites[site.id];
        });
      },
      updateSelectInfo(
        currentPageSet,
        filteredSet,
        currentPageSetIsEditable,
        filteredSetIsEditable
      ) {
        const selectedSites = this.getSelectedSites(filteredSet);
        const selectedSitesOnPage = this.getSelectedSites(currentPageSet);
        return {
          selectedSites,
          selectedSitesOnPage,
          isAllSetSelected:
            selectedSites.length === filteredSet.length ||
            (selectedSites.length === filteredSetIsEditable.length &&
              selectedSites.length !== 0),
          isAllPageSelected:
            (selectedSitesOnPage.length === currentPageSet.length &&
              currentPageSet.length) ||
            (selectedSitesOnPage.length === currentPageSetIsEditable.length &&
              selectedSitesOnPage.length !== 0)
        };
      },
      uncheckOtherPages(filteredSet, pagination) {
        // $scope.options.manualyCheckedSites = {};
        const affectedSet = filteredSet
          .slice(
            0,
            (pagination.sitesCurrentPage - 1) * pagination.sitesItemsPerPage
          )
          .concat(
            filteredSet.slice(
              pagination.sitesCurrentPage * pagination.sitesItemsPerPage
            )
          );

        angular.forEach(affectedSet, function(site) {
          $scope.campaign.sites[site.id] = false;
          delete $scope.options.manualyCheckedSites[site.id];
        });
        $scope.updateSelectInfo();
      },
      checkAllInSet(sitesSet, newState) {
        angular.forEach(sitesSet, function(site) {
          if (site.isEditable) {
            $scope.campaign.sites[site.id] = newState;
            if (newState === true) {
              $scope.options.manualyCheckedSites[site.id] = true;
            } else {
              delete $scope.options.manualyCheckedSites[site.id];
            }
          }
        });
        $scope.updateSelectInfo();
      },
      handleSelectAllCheckbox(selectInfo, currentPageSet, filteredSet) {
        // if all set is selected -> uncheck all set
        if (selectInfo.isAllSetSelected) {
          this.checkAllInSet(filteredSet, false);
        } else if (selectInfo.isAllPageSelected) {
          // if whole page is selected -> uncheck current page
          this.checkAllInSet(currentPageSet, false);
        } else if (!selectInfo.isAllPageSelected) {
          // if neither all set nor all page is selected -> select currentPage
          this.checkAllInSet(currentPageSet, true);
        }
        $scope.updateSelectInfo();
      },
      selectSitesAndUpdateMapMarkers(siteId) {
        // check all sites in current sitesPage array (which used for map markers also)
        $scope.options.sitesPage.forEach(function(item, i) {
          if (siteId) {
            if (item.id === siteId) {
              $scope.options.sitesPage[i].checked = !$scope.options.sitesPage[i]
                .checked;
            }
          } else {
            $scope.options.sitesPage[i].checked =
              $scope.campaign.sites[item.id] || false;
          }
        });
        // update map at the next step
        $timeout(updateMapMarkers, 0);
      }
    };

    angular.forEach($scope.selectMethods, function(method, methodName) {
      $scope.selectMethods[methodName] = method.bind($scope.selectMethods);
    });

    $scope.updateSelectInfo = function(siteId) {
      $scope.updateMap(siteId);
      if (siteId) {
        if ($scope.options.manualyCheckedSites[siteId]) {
          delete $scope.options.manualyCheckedSites[siteId];
        } else {
          $scope.options.manualyCheckedSites[siteId] = true;
        }
      }
      $scope.options.siteSelectInfo = $scope.selectMethods.updateSelectInfo(
        $scope.options.sitesPage,
        $scope.options.filteredSites,
        $scope.options.sitesPageIsEditable,
        $scope.options.filteredSitesIsEditable
      );
    };

    $scope.isAllSitesChecked = function() {
      return !$scope.options.filteredSites.some(function(site) {
        return !$scope.campaign.sites[site.id];
      });
    };

    $scope.checkAllSites = function() {
      const currentStatus = $scope.isAllSitesChecked();
      angular.forEach($scope.options.filteredSites, function(site) {
        $scope.campaign.sites[site.id] = !currentStatus;
      });
    };

    // Check/uncheck marker of site directly from POPUP
    $scope.toggleSiteOnMap = function(siteId) {
      $scope.campaign.sites[siteId] = !$scope.campaign.sites[siteId];
      $scope.updateSelectInfo(siteId);
    };

    // Select site/sites for map (in editMode) and update map markers
    $scope.updateMap = function(siteId) {
      if ($scope.editMode) {
        $scope.selectMethods.selectSitesAndUpdateMapMarkers(siteId);
      } else {
        updateMapMarkers();
      }
    };

    // Filter sites by coords
    function filterByCoords(coords, siteCoords) {
      let bool = false;
      if (!coords.length) {
        bool = true;
      } else {
        coords.forEach(function(coordsItem) {
          if (siteCoords && siteCoords.latitude && !bool) {
            bool =
              siteCoords.latitude <= coordsItem.coordsWithRadius[1].latitude &&
              siteCoords.latitude >= coordsItem.coordsWithRadius[0].latitude &&
              siteCoords.longitude <=
                coordsItem.coordsWithRadius[1].longitude &&
              siteCoords.longitude >= coordsItem.coordsWithRadius[0].longitude;
          } else if (!siteCoords || !siteCoords.latitude) {
            bool = false;
          }
        });
      }
      return bool;
    }

    // Prepare TimeFrames Days and Hours ----
    function timeFrameValidate(index) {
      if (!index) {
        return $scope.options.timeFrameHours.every(function(timeframe) {
          return timeframe.startTime < timeframe.endTime;
        });
      }
      return (
        $scope.options.timeFrameHours[index].startTime <
        $scope.options.timeFrameHours[index].endTime
      );
    }
    function updateTimeFrameDays() {
      if (typeof $scope.options.launchDate !== 'number') {
        $scope.campaign.launchDate = Date.parse($scope.options.launchDate);
      } else {
        $scope.campaign.launchDate = $scope.options.launchDate;
      }
      if ($scope.options.makeInfinityFlag) {
        $scope.campaign.endDate = 0;
      } else if (typeof $scope.options.endDate !== 'number') {
        $scope.campaign.endDate = Date.parse($scope.options.endDate);
      } else {
        $scope.campaign.endDate = $scope.options.endDate;
      }
    }
    function updateTimeFrameHours() {
      if (!timeFrameValidate()) {
        return false;
      }
      $scope.campaign.timeFrameHours = [];
      for (let j = 0; j < $scope.options.timeFrameHours.length; j++) {
        $scope.campaign.timeFrameHours.push(
          conversionFactory.convertTime(
            $scope.options.timeFrameHours[j].startTime
          )
        );
        $scope.campaign.timeFrameHours.push(
          conversionFactory.convertTime(
            $scope.options.timeFrameHours[j].endTime
          )
        );
      }
      return true;
    }

    $scope.setTimeFrameValue = function setTimeFrameValue(
      index,
      option,
      value
    ) {
      $scope.options.timeFrameHours[index][option] = value;
      updateTimeFrameHours();
    };

    $scope.timeFrameValidate = timeFrameValidate;
    $scope.object = {};
    $scope.$on('hidePicker', function() {
      updateTimeFrameDays();
    });
    // / ------------------------------------

    /** * prepareSave method
     * prepare campaign data before save ** */
    const prepareSave = function() {
      updateTimeFrameDays();
      if (!updateTimeFrameHours()) {
        return false;
      }

      if ($scope.campaign.calendarMode !== 'period') {
        $scope.campaign.launchDate = -1;
        $scope.campaign.endDate = -1;
      } else {
        $scope.campaign.workingDates = [];
      }

      $scope.campaign.widgets = [];
      for (let i = 0; i < $scope.widgets.length; i++) {
        if ($scope.widgets[i].id) {
          $scope.campaign.widgets.push($scope.widgets[i].id);
        }
      }

      if (
        $scope.campaign.targeting.retargeting.campaignInteractionType === ''
      ) {
        $scope.campaign.targeting.retargeting.campaignInteractionType =
          'viewed';
      }

      if (
        $scope.campaign.targeting.retargeting.campaignWithTagInteractionType ===
        ''
      ) {
        $scope.campaign.targeting.retargeting.campaignWithTagInteractionType =
          'viewed';
      }

      if ($scope.options.makeInfinityFlag) {
        $scope.campaign.endDate = 0;
      }

      const campaign = angular.copy($scope.campaign);
      campaign.chainId = $scope.authChain.chainId;

      return campaign;
    };

    /** * isChecked
     * ** */
    $scope.isChecked = function isChecked(item, property) {
      if ($scope.campaign[property]) {
        return $scope.campaign[property].indexOf(item) !== -1;
      }
      return false;
    };

    /** * deleteItem handler for click on input with type checkbox
     * invokes when value should be deleted from array ** */
    $scope.deleteItem = function deleteItem(item, property) {
      if ($scope.campaign[property]) {
        const index = $scope.campaign[property].indexOf(item);
        if (index !== -1) {
          $scope.campaign[property].splice(index, 1);
        } else {
          $scope.campaign[property].push(item);
        }
      }
    };

    $scope.isAllChecked = function(type, option) {
      let result = true;
      if ($scope.$eval(`campaign.${type}`) && $scope.options[option]) {
        result = !$scope.options[option].some(function(el) {
          return $scope.$eval(`campaign.${type}`).indexOf(el) === -1;
        });
      }
      return result;
    };
    /** * checkAll method for checkboxes with value 'all'
     *
     * ** */
    $scope.checkAll = function(allFlag, type, option) {
      const model = $parse(`campaign.${type}`);
      if ($scope.flags[allFlag]) {
        model.assign($scope, angular.copy($scope.options[option]));
      } else {
        model.assign($scope, []);
      }
    };

    $scope.validateSites = function() {
      return window._.some($scope.campaign.sites, function(value) {
        return value;
      });
    };

    $scope.setCalendarMode = function(type) {
      $scope.campaign.calendarMode = type;
    };

    const cancelConfirmModal = function() {
      const title = i18nFactory.translate(
        'campaigns.modals.cancelConfirmation.title'
      );
      const message = i18nFactory.translate(
        `campaigns.modals.cancelConfirmation.texts.${
          $scope.createState ? 'create' : 'edit'
        }`
      );
      return helpers.confirmationModal(title, message).result;
    };

    /** * cancel button handler
     * for both edit and view modes ** */
    $scope.cancel = function cancel() {
      if ($scope.createState) {
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          $state.go(listState);
        });
      } else if ($scope.editMode) {
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          getCampaignData().then(() => {
            $scope.showSlidedownBlock = false;
            $scope.showSlidedownBlock2 = false;
            $scope.showSlidedownTags = false;
            $scope.options.makeInfinityFlag = !$scope.campaign.endDate;
            $scope.options.changeLaunchDateFlag = false;
            $scope.options.changeEndDateFlag = false;
            $scope.campaign.geoFilter.coords = [];
          });

          breadcrumbs.set({
            path: [
              { name: 'campaigns' },
              { name: 'list' },
              {
                name: $scope.campaignType === 'partner' ? 'classA' : 'classB',
                sref: `section.campaigns.${$scope.campaign.type.toLowerCase()}List`
              },
              { name: 'create' }
            ]
          });
        });
      } else {
        $state.go(listState);
      }
    };

    $scope.$on('$stateChangeStart', function(event, toState, toParams) {
      if ($scope.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          $state.go(toState, toParams);
        });
      }
    });

    $scope.$on('$destroy', function() {
      unregisterLivePrognosis();
      unregisterSiteLoading();
    });

    /** * Edit button handler
     * ** */
    $scope.edit = function edit() {
      $scope.editMode = true;
      $scope.showSlidedownBlock = true;
      $scope.showSlidedownBlock2 = true;
      $scope.showSlidedownTags = true;
      sitesFactory
        .getSitesListSimple({ campaignId: $scope.campaign.id })
        .success(function(data) {
          $scope.options.availableSites = data.sites;
          $scope.options.multiSelect.sites = [];
          $scope.$evalAsync(function() {
            $scope.options.availableSites.forEach(function(site) {
              if (site.isEditable) $scope.options.multiSelect.sites.push(site);
              // eslint-disable-next-line no-param-reassign
              site.currentLoad = -1;
            });
          });
          getAvailableValues();
        });

      breadcrumbs.set({
        path: [
          { name: 'campaigns' },
          { name: 'list' },
          {
            name:
              $scope.campaign.type.toLowerCase() === 'partner'
                ? 'classA'
                : 'classB',
            sref: `section.campaigns.${$scope.campaignType}List`
          },
          { name: 'modify' }
        ],
        itemName: `${$scope.campaign.id}. ${$scope.campaign.name}`
      });
    };

    /** * Export button handler
     * ** */
    $scope.export = function() {
      const scope = $scope.$new();
      scope.forms = {};
      scope.state = {
        submitted: false
      };
      scope.id = $scope.campaign.id;
      $scope.exportCampaign = true;

      const modal = $modal.open({
        templateUrl: 'app/core/views/exportModal.html',
        windowClass: 'export_modal',
        controller($scope, $modalInstance, $http) {
          $scope.exportOptions = [
            {
              name: 'campaigns.labels.info',
              value: true,
              options: [
                { name: 'campaigns.labels.name', value: true },
                { name: 'campaigns.labels.entity', value: true },
                { name: 'campaigns.labels.period', value: true },
                { name: 'campaigns.labels.daysOfWeek', value: true },
                { name: 'campaigns.labels.hoursOfDay', value: true },
                { name: 'campaigns.labels.created', value: true },
                { name: 'campaigns.labels.limitForUniqueMac', value: true },
                { name: 'campaigns.labels.uniqueViews', value: true },
                { name: 'campaigns.labels.exclusivity', value: true },
                { name: 'campaigns.labels.tags', value: true },
                { name: 'campaigns.labels.labels', value: true }
              ]
            },
            { name: 'campaigns.labels.linkedWidgets', value: true },
            {
              name: 'campaigns.labels.targeting.tabLabels.geoLocationTab',
              value: true
            },
            {
              name: 'campaigns.labels.targeting.tabLabels.deviceTab',
              value: true
            },
            {
              name: 'campaigns.labels.targeting.tabLabels.profileTab',
              value: true
            },
            {
              name: 'campaigns.labels.targeting.tabLabels.retargetingTab',
              value: true
            },
            {
              name: 'campaigns.labels.livePrognosis.livePrognosisHeader',
              value: true
            }
          ];

          $scope.fileFormat = 'xls';

          $scope.optionsFilesFormats = ['xls'];

          const setParams = function(options) {
            return options
              .map(function(item) {
                if (item.options) return setParams(item.options);
                return +item.value;
              })
              .join('');
          };

          $scope.selectExport = function(ar, parentAr) {
            if (ar.options) {
              if (ar.value) {
                ar.options.forEach(function(o) {
                  // eslint-disable-next-line no-param-reassign
                  o.value = true;
                });
              } else {
                ar.options.forEach(function(o) {
                  // eslint-disable-next-line no-param-reassign
                  o.value = false;
                });
              }
            } else if (!ar.options && parentAr && parentAr.options) {
              // eslint-disable-next-line no-param-reassign
              parentAr.value = !ar.some(function(o) {
                return o.value === false;
              });
            }
          };

          $scope.ok = function() {
            const params = setParams($scope.exportOptions);
            const i18nFilter = $filter('i18n');
            if (params.search('1') !== -1) {
              $http
                .get(
                  `${
                    window.location.origin
                  }/campaignexcel?id=${id}&params=${params}`
                )
                .then(function(res) {
                  if (res.data.file) {
                    window.open(res.data.file, '_blank');
                  } else {
                    // no design
                  }
                });
              modal.close();
            } else {
              scope.cancelModalMsg = cancelModalMsg;
              scope.message = i18nFilter('campaigns.modals.titles.exportError');
              scope.title = 'exportData';
              $modal.open({
                templateUrl: 'app/core/views/errorModal.html',
                windowClass: 'wcm-msg',
                scope
              });
            }
          };
        },

        scope
      });

      modal.result.finally(function() {
        $scope.exportCampaign = false;
      });
    };

    /** * save button handler
     * ** */
    $scope.saveDraft = function saveDraft() {
      $scope.submitted = true;
      if ($scope.campaignForm.$invalid) {
        return false;
      }

      if (areWidgetTypesConsistent($scope.widgets)) {
        doSave();
      } else {
        helpers.infoModal({
          message: 'campaigns.modals.widgetsConsistencyModal.text',
          title: 'campaigns.modals.widgetsConsistencyModal.title'
        });
      }

      function doSave() {
        const campaign = prepareSave();
        if (!campaign) {
          return false;
        }

        $scope.setBusy(true);
        campaignsFactory
          .campaignSave(campaign)
          .success(function() {
            $scope.editMode = false;
            $state.go(listState);
          })
          .finally(function() {
            $scope.setBusy(false);
          });
        return undefined;
      }
      return undefined;
    };

    /**
     * Checks whether all widgets have type 'poll' or no poll widgets present at all
     *
     * @param widgets
     * @returns {boolean}
     */
    function areWidgetTypesConsistent(widgets) {
      const widgetTypes = widgets.map(function(widget) {
        return widget.contentType;
      });
      const isPollPresent = !!~widgetTypes.indexOf('poll');
      if (!isPollPresent) return true;
      return widgetTypes.every(function(type) {
        return type === 'poll' || !type;
      });
    }

    $scope.getSiteColor = function getSiteColor(load) {
      if (load === 100) return 'load10';
      if (load === -1) return 'load11';
      if (load < 10) return 'load0';
      return `load${`${load}`.charAt(0)}`;
    };

    $scope.getProgressColor = function getProgressColor(progress) {
      return `progress_${Math.round(progress / 10)}`;
    };

    $scope.iconClassName = function(name) {
      return campaignsFactory.iconClassName(name);
    };

    $scope.$watch('siteSearchFilter.partnerId', function() {
      $scope.siteSearchFilter.name = '';
    });

    $scope.$watch(
      function() {
        return {
          city: $scope.campaign.geoFilter.city.length,
          coords: $scope.campaign.geoFilter.coords.length,
          country: $scope.campaign.geoFilter.country.length,
          numberOfSessionsEnable:
            $scope.campaign.geoFilter.numberOfSessions.enable,
          numberOfSessionsFrom: $scope.campaign.geoFilter.numberOfSessions.from,
          numberOfSessionsTo: $scope.campaign.geoFilter.numberOfSessions.to
        };
      },
      function() {
        if ($scope.editMode) {
          $scope.onStepsChange($scope.lastGeoSteps);
        }
      },
      true
    );

    // MODALS ---------------------- >

    $scope.addByMarkerRadius = function() {
      const scope = $scope.$new();
      scope.forms = {};
      // scope.coordinates = $scope.$eval(variable);
      $modal
        .open({
          templateUrl: 'app/core/geo_location/modalSearchRadiusPopup.html',
          windowClass: 'modal_geo-location',
          controller($scope, $modalInstance) {
            // eslint-disable-next-line no-multi-assign
            const state = ($scope.state = {
              submitted: false,
              addressSearchInProgress: false,
              location: {
                address: '',
                radius: 5, // default
                coords: {}
              }
            });
            $scope.ok = function() {
              $scope.state.submitted = true;
              $modalInstance.close(state);
            };
            $scope.cancel = function() {
              $scope.state.submitted = false;
              $modalInstance.close();
            };
          },
          scope
        })
        .result.then(function(res) {
          let coordinates;
          let radiusInCoords;
          if (res.location.coords && res.location.radius) {
            radiusInCoords = res.location.radius / KM_BY_DEGREE; // to get radius in degrees
            coordinates = [
              {
                latitude: res.location.coords.lat - radiusInCoords,
                longitude: res.location.coords.lng - radiusInCoords
              },
              {
                latitude: res.location.coords.lat + radiusInCoords,
                longitude: res.location.coords.lng + radiusInCoords
              }
            ];
          }
          $scope.campaign.geoFilter.coords.push({
            title:
              (res.location.address ||
                `${res.location.coords.lat.toFixed(
                  4
                )}, ${res.location.coords.lng.toFixed(4)}`) +
              (res.location.radius ? ` + ${res.location.radius}km` : ''),
            coords: res.location.coords,
            coordsWithRadius: coordinates
          });
          $scope.filterSites(
            $scope.siteSearchFilter.partnerId,
            $scope.siteSearchFilter.name
          );
        });
    };

    // Add one working date
    $scope.addWorkingDate = function() {
      const stateModal = {};
      const handlersModal = {};
      stateModal.startPeriod = window.moment().startOf('day');
      stateModal.isSingleDate = true;
      stateModal.dates = $scope.campaign.workingDates;
      handlersModal.validators = {
        alreadyAdded(dates, date) {
          const d = helpers.getPeriodTimestamps([date]);
          return !~dates.indexOf(d[0]);
        },
        toSmall(date, curDate) {
          return date >= curDate;
        },
        startEnd() {
          return true;
        }
      };

      const labels = {
        title: 'campaigns.modals.titles.addDayToCalendar',
        str: 'campaigns.labels.selectDay'
      };
      helpers
        .addTimeframeModal(stateModal, handlersModal, labels)
        .result.then(function(timeframe) {
          $scope.campaign.workingDates.push(timeframe);
        });
    };

    // Remove date
    $scope.removeDate = function(variable, str, title) {
      const needRemoveName = $filter('formatDate')(str);
      helpers
        .confirmationModal(
          title || '',
          'campaigns.modals.remove.texts.day',
          needRemoveName,
          null,
          { className: 'campaign_modal_confirm' }
        )
        .result.then(function() {
          const index = variable.indexOf(str);
          variable.splice(index, 1);
        });
    };

    // Add time period
    $scope.addTimePeriod = function() {
      const scope = $scope.$new();
      $modal
        .open({
          templateUrl: 'app/core/views/addTimeFrameModal.html',
          windowClass: 'modal_add-time-frame',
          controller($scope, $modalInstance) {
            $scope.state = {
              timeframe: {
                startTime: $scope.options.timeFrames[0],
                endTime:
                  $scope.options.timeFrames[
                    $scope.options.timeFrames.length - 1
                  ]
              },
              submitted: false
            };

            $scope.forms = {};

            $scope.handlers = {};

            $scope.submit = function() {
              $scope.state.submitted = true;
              if ($scope.forms.timeFrameForm.$invalid) {
                return false;
              }
              $modalInstance.close($scope.state.timeframe);
              return undefined;
            };
          },

          scope
        })
        .result.then(timeFrame => {
          $scope.options.timeFrameHours.push(timeFrame);
          updateTimeFrameHours();
        });
    };

    // Add some string
    $scope.addString = function(variable, title) {
      const scope = $scope.$new();
      scope.labels = $scope.$eval(variable) || [];
      scope.title = title;
      $modal.open({
        templateUrl: 'app/core/views/addStringModal.html',
        windowClass: 'add-tag_modal',
        controller($scope, $modalInstance, conversionFactory) {
          $scope.state = {
            submitted: false
          };

          $scope.forms = {};

          $scope.newStr = {
            name: ''
          };

          $scope.capitalize = function(title) {
            return conversionFactory.capitalize(title);
          };

          $scope.handlers = {};

          $scope.ok = function() {
            $scope.state.submitted = true;
            if ($scope.labels.indexOf($scope.newStr.name) === -1) {
              scope.labels.push($scope.newStr.name);
              $modalInstance.close($scope.newStr.name);
            } else {
              $modalInstance.close($scope.newStr.name);
            }
          };

          $scope.cancel = function() {
            $scope.state.submitted = false;
          };

          $scope.selectTag = function(labelName) {
            $scope.isInputFocused = false;
            $scope.newStr.name = labelName;
          };

          $scope.focus = function() {
            $scope.isInputFocused = true;
          };
        },

        scope
      });
    };

    $scope.removeString = function(variable, str, title) {
      let needRemove;
      if (title === 'occupation' || title === 'interests') {
        needRemove = $filter('occupationInterest')(str);
      } else if (typeof str === 'object') {
        needRemove = str.name;
      } else {
        needRemove = str;
      }
      helpers
        .confirmationModal(
          '',
          'campaigns.modals.remove.texts.string',
          needRemove,
          null,
          { className: 'campaign_modal_confirm' }
        )
        .result.then(function() {
          const index = variable.indexOf(str);
          variable.splice(index, 1);
        });
    };

    $scope.removeCoordinates = function(variable, coords) {
      helpers
        .confirmationModal(
          'campaigns.modals.titles.deletingCoords',
          'campaigns.modals.remove.texts.coords',
          true
        )
        .result.then(function() {
          const index = variable.indexOf(coords);
          variable.splice(index, 1);
          $scope.filterSites(
            $scope.siteSearchFilter.partnerId,
            $scope.siteSearchFilter.name
          );
        });
    };

    // Download file with selected sites
    $scope.downloadSelectedSites = function() {
      sitesFactory.downloadSitesListForCampaign($scope.campaign.sites);
    };

    // Check files from file
    $scope.uploadSitesForSelect = function(type, file) {
      sitesFactory.uploadSitesListForCampaign(type, file).then(
        function(res) {
          if (!res.siteIds) {
            return;
          }
          $scope.options.filteredSites.forEach(function(item) {
            if (~res.siteIds.indexOf(item.id)) {
              $scope.campaign.sites[item.id] = true;
            }
          });
          $scope.updateSelectInfo();
        },
        function(res) {
          if (res === 'wrongExe') {
            const labels = {
              message: 'campaigns.modals.xls.errorXlsMsg',
              title: 'campaigns.modals.xls.errorXlsTitle'
            };
            infoModal(labels);
          }
        }
      );
    };

    $scope.onStepsChange = function(steps) {
      let campaignData;
      $scope.lastGeoSteps = steps;
      if (steps.length === 0) {
        if (
          !$scope.campaign.geoFilter.country.length &&
          !$scope.campaign.geoFilter.city.length
        ) {
          $scope.campaign.sites = angular.copy(
            $scope.options.manualyCheckedSites
          );
          $scope.updateSelectInfo();
        } else {
          campaignData = angular.copy($scope.campaign);
          sitesFactory
            .getSitesListByGeoFilter(campaignData)
            .success(function(data) {
              $scope.campaign.sites = angular.copy(
                $scope.options.manualyCheckedSites
              );
              $scope.$evalAsync(function() {
                if (data.sites[0]) {
                  data.sites.forEach(function(site) {
                    $scope.campaign.sites[site.id] = true;
                  });
                }
                $scope.updateSelectInfo();
              });
            });
        }
      } else {
        campaignData = angular.copy($scope.campaign);
        steps.forEach((step, index) => {
          campaignData.geoFilter.steps[index] = {};
          campaignData.geoFilter.steps[index].entity = [];
          campaignData.geoFilter.steps[index].tags = [];

          step.entries.forEach(item => {
            if (item.className === 'entities') {
              campaignData.geoFilter.steps[index].entity.push({
                name: item.name,
                id: item.id
              });
            } else {
              campaignData.geoFilter.steps[index].tags.push(item.name);
            }
          });
        });
        sitesFactory
          .getSitesListByGeoFilter(campaignData)
          .success(function(data) {
            $scope.campaign.sites = angular.copy(
              $scope.options.manualyCheckedSites
            );
            $scope.$evalAsync(function() {
              if (data.sites[0]) {
                data.sites.forEach(function(site) {
                  $scope.campaign.sites[site.id] = true;
                });
              }
              $scope.updateSelectInfo();
            });
          });
      }
    };

    $scope.modalSelect = function(
      variable,
      allItems,
      title,
      searchProps,
      isNotRequest
    ) {
      const scope = $scope.$new();
      scope.content = $scope.$eval(variable);
      scope.oldContent = angular.copy(scope.content);

      const model = $parse(allItems);
      scope.allItems = model($scope);

      scope.title = title;
      scope.searchProps = searchProps;
      scope.modalDataBusy = false;

      const request = `campaignGet${conversionFactory.capitalize(title)}`;

      if (!model($scope)[0] && !isNotRequest) {
        scope.modalDataBusy = true;
        campaignsFactory[request]
          .call(null, $scope.campaign.type, $scope.campaign.id)
          .then(function(res) {
            model.assign($scope, res.data.items);
            scope.allItems = model($scope);
          })
          .finally(function() {
            scope.modalDataBusy = false;
          });
      }

      $modal.open({
        templateUrl: 'app/core/views/addMultiListModal.html',
        windowClass: 'modal_multilist',
        controller($scope, $modalInstance, conversionFactory) {
          // eslint-disable-next-line no-multi-assign
          const state = ($scope.state = {
            itemsCurrentPage: 1,
            itemsPerPage: 40,
            itemsOnPageValues: [40, 80, 120]
          });

          $scope.itemSearchFilter = {
            name: ''
          };

          $scope.ok = function() {
            $scope.submitted = true;
            $modalInstance.close();
          };

          $scope.cancel = function() {
            $scope.submitted = false;
            scope.content.splice(0, scope.content.length);
            for (let i = 0; i < scope.oldContent.length; i++) {
              scope.content.push(scope.oldContent[i]);
            }
            $modalInstance.dismiss();
          };

          $scope.capitalize = function(title) {
            return conversionFactory.capitalize(title);
          };

          $scope.selectTag = function(tagName) {
            $scope.isInputFocused = false;
            $scope.newStr.name = tagName;
          };

          $scope.focus = function() {
            $scope.isInputFocused = true;
          };

          $scope.findInArray = function(array, item, key) {
            let find = -1;
            if (array[0] && typeof array[0] === 'object') {
              for (let i = 0; i < array.length; i++) {
                if (array[i][key] === item[key]) {
                  find = i;
                  break;
                }
              }
            } else {
              find = array.indexOf(item);
            }
            return find;
          };

          $scope.filterItems = function filterItems(searchString) {
            const props = scope.searchProps || [];
            const lowerCasedSearchString = searchString
              ? searchString.toLowerCase()
              : '';
            $scope.filteredItems = window._.filter($scope.allItems, function(
              item
            ) {
              return searchString
                ? helpers.searchByString(item, lowerCasedSearchString, props)
                    .length
                : true;
            });
          };

          $scope.getItemsPage = function getItemsPage() {
            const filteredItems = $scope.filteredItems || [];
            const currentPage = state.itemsCurrentPage || 1;
            const startIndex = (currentPage - 1) * state.itemsPerPage;
            $scope.itemsPage = filteredItems.slice(
              startIndex,
              startIndex + state.itemsPerPage
            );
          };

          $scope.updateSelectInfo = function(item) {
            if (item) {
              const index = $scope.findInArray(scope.content, item, 'id');
              if (index === -1) scope.content.push(item);
              else {
                scope.content.splice(index, 1);
              }
            }
            $scope.itemSelectInfo = $scope.selectMethods.updateSelectInfo(
              $scope.itemsPage,
              $scope.filteredItems
            );
          };

          $scope.selectMethods = {
            getSelectedItems(items) {
              return items.filter(function(item) {
                return $scope.findInArray(scope.content, item, 'id') !== -1;
              });
            },
            updateSelectInfo(currentPageSet, filteredSet) {
              const selectedItems = this.getSelectedItems(filteredSet);
              const selectedItemsOnPage = this.getSelectedItems(currentPageSet);
              return {
                selectedItems,
                selectedItemsOnPage,
                isAllSetSelected: selectedItems.length === filteredSet.length,
                isAllPageSelected:
                  selectedItemsOnPage.length === currentPageSet.length &&
                  currentPageSet.length
              };
            },
            uncheckOtherPages(filteredSet) {
              const beginIndex =
                (state.itemsCurrentPage - 1) * state.itemsPerPage;
              const endIndex = state.itemsCurrentPage * state.itemsPerPage;
              const affectedSet = filteredSet.slice(beginIndex, endIndex);
              scope.content.splice(0, scope.content.length);
              angular.forEach(affectedSet, function(item) {
                scope.content.push(item);
              });
              $scope.updateSelectInfo();
            },
            checkAllInSet(itemsSet, newState) {
              angular.forEach(itemsSet, function(item) {
                const index = $scope.findInArray(scope.content, item, 'id');
                if (index === -1 && newState) {
                  scope.content.push(item);
                } else if (!newState) {
                  scope.content.splice(index, 1);
                }
              });
              $scope.updateSelectInfo();
            },
            handleSelectAllCheckbox(selectInfo, currentPageSet, filteredSet) {
              // if all set is selected -> uncheck all set
              if (selectInfo.isAllSetSelected) {
                this.checkAllInSet(filteredSet, false);
              } else if (selectInfo.isAllPageSelected) {
                // if all page is selected -> uncheck current page
                this.checkAllInSet(currentPageSet, false);
              } else if (!selectInfo.isAllPageSelected) {
                // if neither all set nor all page is selected -> select currentPage
                this.checkAllInSet(currentPageSet, true);
              }
              $scope.updateSelectInfo();
            }
          };

          $scope.$watch('allItems', function() {
            $scope.filterItems();
          });
          $scope.$watch('filteredItems', function() {
            $scope.getItemsPage();
          });
          $scope.$watch('itemsPage', function() {
            $scope.updateSelectInfo();
          });
        },

        scope
      });
    };

    function infoModal(labels) {
      const i18n = $filter('i18n');
      const scope = $scope.$new();
      scope.msg = i18n(labels.message);
      scope.title = i18n(labels.title);
      $modal.open({
        templateUrl: 'app/core/views/infoErrorGlobal.html',
        windowClass: 'wcm-msg',
        scope
      });
    }

    navigationPanel.setActionButtons([
      {
        title: () => 'campaigns.buttons.edit',
        isDisabled: () => false,
        isShown: () =>
          !$scope.editMode && $scope.status && $scope.status.isEditable,
        clickHandler: () => {
          $scope.edit();
        }
      },
      {
        title: () => 'campaigns.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.saveDraft();
        }
      },
      {
        title: () => 'campaigns.buttons.export',
        isDisabled: () => false,
        isBusy: () => $scope.exportCampaign,
        type: () => 'action-1',
        isShown: () => !$scope.editMode,
        clickHandler: () => {
          $scope.export();
        }
      },
      {
        title: () =>
          `campaigns.buttons.${$scope.editMode ? 'cancel' : 'close'}`,
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);

    // / MODALS < ----------------------
  });
