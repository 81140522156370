/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;

angular
  .module('wcm.analytics2')
  .factory('analyticsRequestsFactory', function(
    $q,
    $timeout,
    $filter,
    $rootScope,
    requestFactory,
    analyticsFactory2,
    sharedRequestsFactory,
    analyticsPresetsFactory,
    helpers,
    ANALYTICS_REQUESTS_CONFIG,
    ANALYTICS_CONFIG2
  ) {
    let blocksData = {};
    const i18n = $filter('i18n');
    const MILISECONDS_IN_MINUTE = 60000;

    const resetManageBlocksData = function() {
      blocksData = {
        amountOfViewsAndVisitors: {
          sites: [],
          cities: []
        },
        dataByComparisonSites: {
          values1: [],
          values2: []
        },
        dataByComparisonCampaigns: {
          values1: [],
          values2: []
        }
      };
    };
    resetManageBlocksData();

    const exportParams = {
      ids: [
        'getCampaigns',
        'getSites',
        'getDataByMonths',
        'getDataByYears',
        'getGeneralInfo',
        'getDataByNotReturningVisitors',
        'getDataByViewersPortrait',
        'getDataByCurrentWidgets'
      ],
      filters: [
        'getGeneralInfoFiltered',
        'getCampaignsTimeline',
        'getDataByTraffic',
        'getAmountOfViewsAndVisitors',
        'getDataByComparison',
        'getDataByDays',
        'getDataBySites',
        'getDataByDevices',
        'getDataByDemographicInfo',
        'getDataByHours',
        'getDataByWeek',
        'getDataByDaysAndSites',
        'getDataByVisitors',
        'getDataByTrafficUsage',
        'getDataByNumberOfVisits',
        'getDataByOtherVisitedSites',
        'getDataByVisitorsAgeAndGender',
        'getDataByNewAndReturningVisitors',
        'getDataByCampaignsViews',
        'getDataByCampaigns',
        'getDataBySiteTags',
        'getDataByCitiesAndCountries',
        'getDataByCitiesAndCountriesSites',
        'getDataByNumberOfViews',
        'getAmountOfSitesAndEntities',
        'getAmountOfWidgets',
        'getAmountOfCampaigns'
      ],
      intervals: [
        'getDataByTraffic',
        'getDataByComparison',
        'getDataByDays',
        'getDataByDaysAndSites',
        'getDataByVisitors',
        'getDataByTrafficUsage',
        'getDataByNewAndReturningVisitors'
      ]
    };

    // Set fake data to align chart by center
    const setFakeDataByYears = function(viewData) {
      if (
        viewData.data.columns[0].length > 0 &&
        viewData.data.columns[0].length <= 2
      ) {
        viewData.data.columns.forEach(function(item, i) {
          viewData.data.columns[i].splice(1, 0, null);
          viewData.data.columns[i].push(null);
        });
        viewData.options.ticksX.unshift('');
        viewData.options.ticksX.push('');
      }
      return viewData;
    };

    // Data Helpers
    //
    const mapFiltersToRequest = function(filters) {
      const filterObj = {
        period: [filters.startPeriod, filters.endPeriod].map(function(m) {
          const date = angular.copy(m);
          // cast to utc midnight
          return moment(date)
            .utc($rootScope.clientTimezoneOffset / MILISECONDS_IN_MINUTE)
            .startOf('day')
            .valueOf();
        }),
        timeframe: [filters.startTimeFrame, filters.endTimeFrame],
        days: filters.daysOfWeek
      };

      if (filters.sortOrder) filterObj.sortOrder = filters.sortOrder;

      if (Array.isArray(filters.siteIds)) filterObj.siteIds = filters.siteIds;
      if (Array.isArray(filters.campaignIds))
        filterObj.campaignIds = filters.campaignIds;

      return filterObj;
    };
    const getBlocksData = function() {
      return blocksData;
    };
    const setManageBlocksData = function(blockName, data) {
      blocksData[blockName] = data;
    };

    // Set filters params
    //
    const prepareRequestParams = function(
      filters,
      analyticsType,
      blockName,
      options
    ) {
      let f = {};

      // default
      f.terminateType = sharedRequestsFactory.terminateTypes[analyticsType];
      f.uniquePageId = $rootScope.uniqueBrowserTabToken;

      // only ids
      if (~exportParams.ids.indexOf(blockName)) {
        if (Array.isArray(filters.siteIds)) f.siteIds = filters.siteIds;
        if (Array.isArray(filters.campaignIds))
          f.campaignIds = filters.campaignIds;
      }

      // with filters
      if (~exportParams.filters.indexOf(blockName)) {
        f = angular.extend(f, mapFiltersToRequest(filters));
      }

      // with intervals
      if (~exportParams.intervals.indexOf(blockName)) {
        f.intervalsCount = 12;
      }

      // Custom filters which need for some blocks
      if (blockName === 'getAmountOfViewsAndVisitors') {
        f = angular.extend(f, {
          sites: blocksData.amountOfViewsAndVisitors.sites,
          cities: blocksData.amountOfViewsAndVisitors.cities,
          collapsedValues: {
            sites: blocksData.amountOfViewsAndVisitors.collapsedValues.sites
          }
        });
      } else if (
        blockName === 'getDataByComparison' &&
        analyticsType === 'campaigns'
      ) {
        f = angular.extend(f, {
          values1: blocksData.dataByComparisonCampaigns.values1,
          values2: blocksData.dataByComparisonCampaigns.values2,
          collapsedValues: blocksData.dataByComparisonCampaigns.collapsedValues
        });
      } else if (
        blockName === 'getDataByComparison' &&
        analyticsType === 'sites'
      ) {
        f = angular.extend(f, {
          values1: blocksData.dataByComparisonSites.values1,
          values2: blocksData.dataByComparisonSites.values2,
          collapsedValues: blocksData.dataByComparisonSites.collapsedValues
        });
      } else if (
        (blockName === 'getGeneralInfo' || blockName === 'getPollInfo') &&
        (analyticsType === 'general' ||
          analyticsType === 'campaigns' ||
          analyticsType === 'analyticsCampaign')
      ) {
        // ---> Difficult implementation of General Info because of mapping request name for BE
        f = angular.extend(f, mapFiltersToRequest(filters));
      } else if (blockName === 'getGeneralInfo' && analyticsType === 'sites') {
        f = angular.extend(f, mapFiltersToRequest(filters));
        f.isSystemWide = true;
      } else if (
        blockName === 'getGeneralInfoFiltered' ||
        (blockName === 'getGeneralInfo' && analyticsType === 'analyticsSite')
      ) {
        f.isSystemWide = false;
      } else if (
        blockName === 'getDataByCitiesAndCountries' ||
        blockName === 'getDataByCitiesAndCountriesSites'
      ) {
        f.dataFrom = options.dataFrom;
        if (!f.dataFrom) f.dataFrom = options.requestFilter;
      } else if (
        blockName === 'getDataByVisitors' &&
        analyticsType === 'analyticsSite'
      ) {
        f.needWeather = true;
      } else if (blockName === 'getDataByDaysAndSites') {
        f.aggregated = options.aggregated;
        if (!f.aggregated) f.filterType = options.currentFilter;
      } else if (blockName === 'getDataByCurrentWidgets') {
        delete f.siteIds;
      } else if (blockName === 'getSites') {
        f = { siteIds: f.siteIds };
      } else if (blockName === 'getCampaigns') {
        f = { campaignIds: f.campaignIds };
      }

      return f;
    };

    // Get Available values
    //
    const getAllEntities = function getAllEntities(analyticsType) {
      if (!analyticsType) return $q.reject();
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.entitiesWasServlet,
          ANALYTICS_REQUESTS_CONFIG.getAllEntities,
          { terminateType: sharedRequestsFactory.terminateTypes[analyticsType] }
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getAllEntitiesForCampaign = function getAllEntitiesForCampaign(
      analyticsType,
      campaignIds
    ) {
      if (!analyticsType || !campaignIds) return $q.reject();
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.entitiesWasServlet,
          ANALYTICS_REQUESTS_CONFIG.getAllEntitiesForCampaign,
          {
            terminateType: sharedRequestsFactory.terminateTypes[analyticsType],
            campaignIds
          }
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getAllSites = function getAllSites(analyticsType, permissionType) {
      if (!analyticsType) return $q.reject();
      const requestData = {
        terminateType: sharedRequestsFactory.terminateTypes[analyticsType],
        permissionType
      };
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getAllSites,
          requestData
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getAllSitesForCampaign = function getAllSitesForCampaign(
      analyticsType,
      campaignIds,
      permissionType
    ) {
      if (!analyticsType || !campaignIds) return $q.reject();
      const requestData = {
        terminateType: sharedRequestsFactory.terminateTypes[analyticsType],
        campaignIds,
        permissionType
      };
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getAllSitesForCampaign,
          requestData
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getAllCampaigns = function getAllCampaigns(
      analyticsType,
      permissionType
    ) {
      if (!analyticsType) return $q.reject();
      const requestData = {
        terminateType: sharedRequestsFactory.terminateTypes[analyticsType],
        permissionType
      };
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getAllCampaigns,
          requestData
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getTagsForSites = function getTagsForSites(analyticsType) {
      if (!analyticsType) return $q.reject();
      const filters = prepareRequestParams(null, analyticsType, {});
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByTags,
          filters
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getTagsForSitesForCampaign = function getTagsForSitesForCampaign(
      analyticsType,
      campaignIds
    ) {
      if (!analyticsType || !campaignIds) return $q.reject();
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByTagsForCampaign,
          {
            terminateType: sharedRequestsFactory.terminateTypes[analyticsType],
            campaignIds
          }
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    const getTagsForCampaigns = function getTagsForCampaigns(analyticsType) {
      if (!analyticsType) return $q.reject();
      const filters = prepareRequestParams(null, analyticsType, {});
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByTags,
          filters
        )
        .then(
          function(res) {
            return res.data;
          },
          function(res) {
            return $q.reject(res);
          }
        );
    };
    // >>>>> General analytics requests <<<<<
    //
    const getCampaignsTimeline = function(
      filters,
      blocksOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForViewStep1(res) {
        return res.data.data ? res.data.data : [];
      }
      function prepareForViewStep2(data) {
        return data.map(function(campaign) {
          return campaign.state === 'Always running'
            ? angular.extend({}, campaign, { state: 'running' })
            : campaign;
        });
      }
      function prepareForViewStep3(data) {
        const filters = blocksOptions.filters || [];
        const campaignTypes = helpers.getTruthyKeys(filters[0]);
        const campaignStates = helpers.getTruthyKeys(filters[1]);
        return {
          requested: data,
          view: analyticsFactory2.filterTimelineData(
            data,
            campaignTypes,
            campaignStates,
            filters[0].default
          )
        };
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReport: true })
          .then(prepareForViewStep1)
          .then(prepareForViewStep2)
          .then(prepareForViewStep3);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getCampaignsTimeline'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getCampaignsTimeline,
          filters
        )
        .then(prepareForViewStep1)
        .then(prepareForViewStep2)
        .then(prepareForViewStep3);
    };

    const getGeneralInfoSystem = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        return { requested: res.data, view: res.data };
      }

      if (reportData)
        return $q
          .resolve({ data: reportData, isReport: true })
          .then(prepareForView);

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getGeneralInfo');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getGeneralInfo,
          filters
        )
        .then(prepareForView);
    };

    const getDataByTraffic = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#CDCADF', '#7DA6EC', '#EA94AF'];
        options.xAxis = {
          type: 'category',
          categories: analyticsFactory2.setLineBarChartTicksFormat(
            res.data.options.ticksX
          )
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.dataTypes = { total: 'area' };
        options.globalType = 'line';
        options.legend = blockOptions.legend;

        options.tooltip = {
          contents(d, defaultTitleFormat, defaultValueFormat, color) {
            // Use default rendering with different title
            // eslint-disable-next-line no-param-reassign
            d = d.map(function(item) {
              // eslint-disable-next-line no-param-reassign
              item.name = i18n(`analytics.filters.${item.name}`);
              return item;
            });
            return this.getTooltipContent(
              d,
              defaultTitleFormat,
              defaultValueFormat,
              color
            );
          },
          format: {
            value(value, ratio, id, point) {
              const currentMax = final.requested.data.columns[0][point + 1];
              return id === 'ctr' && value > 0
                ? window.d3.format('.2%')(value)
                : window.d3.format('.0f')(value) +
                    (id !== 'total' && currentMax !== 0
                      ? ` | ${window.d3.format('.2%')(value / currentMax)}`
                      : '');
            }
          }
        };
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByTraffic'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByTraffic,
          filters
        )
        .then(prepareForView);
    };

    const getAmountOfCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        final.view = final.requested[blockOptions.currentFilter];
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getAmountOfCampaigns'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getAmountOfCampaigns,
          filters
        )
        .then(prepareForView);
    };

    const getAmountOfViewsAndVisitors = function(
      filters,
      data,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        return { requested: res.data, view: res.data };
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getAmountOfViewsAndVisitors'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getAmountOfViewsAndVisitors,
          filters
        )
        .then(prepareForView);
    };

    const getAmountOfWidgets = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        final.view = final.requested[blockOptions.currentFilter];
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getAmountOfWidgets'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getAmountOfWidgets,
          filters
        )
        .then(prepareForView);
    };

    const getAmountOfSitesAndEntities = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(data) {
        const final = { requested: {}, view: {} };
        // eslint-disable-next-line no-restricted-syntax,guard-for-in, no-unused-vars
        for (const i in data[0]) {
          final.requested[i] = angular.extend({}, data[0][i], data[1][i]);
        }
        final.view = final.requested[blockOptions.currentFilter];
        return final;
      }

      if (reportData) return $q.resolve(reportData).then(prepareForView);

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getAmountOfSitesAndEntities'
      );
      const sData = requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getAmountOfSites,
          filters
        )
        .then(function(res) {
          return res.data;
        });
      const eData = requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.entitiesWasServlet,
          ANALYTICS_REQUESTS_CONFIG.getAmountOfEntities,
          filters
        )
        .then(function(res) {
          return res.data;
        });
      return $q.all([sData, eData]).then(prepareForView);
    };

    const getQuantityOfRegisteredUsers = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        // eslint-disable-next-line no-param-reassign
        res.data.cms = res.data.cms || 0;
        // eslint-disable-next-line no-param-reassign
        res.data.clients = res.data.clients || 0;
        return { requested: res.data, view: res.data };
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getQuantityOfRegisteredUsers'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getQuantityOfRegisteredUsers,
          filters
        )
        .then(prepareForView);
    };

    const getDataByTags = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(data) {
        // eslint-disable-next-line no-param-reassign
        blockOptions.extraData = {
          itemsPerPage: 50,
          itemsPerPageValues: [50],
          currentPage: 1,
          searchQuery: ''
        };
        const requested = {
          campaignTags: data[1].tags || data[0].tags || [],
          siteClasses: data[0].tagsClasses || data[1].tagsClasses || [],
          siteTypes: data[0].tagsTypes || data[1].tagsTypes || []
        };
        const counts = {
          campaignTags: requested.campaignTags.length,
          siteClasses: requested.siteClasses.length,
          siteTypes: requested.siteTypes.length
        };
        return {
          requested,
          view: requested[blockOptions.currentFilter],
          counts,
          itemsPage: helpers.getPage(
            requested[blockOptions.currentFilter],
            blockOptions.extraData.itemsPerPage,
            blockOptions.extraData.currentPage
          )
        };
      }

      if (reportData) return $q.resolve(reportData).then(prepareForView);

      const sData = getTagsForSites(analyticsType);
      const cData = getTagsForCampaigns(analyticsType);
      return $q.all([sData, cData]).then(prepareForView);
    };

    const getVacantSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        // eslint-disable-next-line no-param-reassign
        blockOptions.extraData = {
          itemsPerPage: 50,
          itemsPerPageValues: [50, 75, 100],
          currentPage: 1,
          searchQuery: ''
        };
        return {
          requested: res.data.data,
          view: res.data.data,
          itemsPage: helpers.getPage(
            res.data.data,
            blockOptions.extraData.itemsPerPage,
            blockOptions.extraData.currentPage
          )
        };
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getVacantSites');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getVacantSites,
          filters
        )
        .then(prepareForView);
    };

    const getAllSystemSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        res.data.data.forEach(function(item) {
          // eslint-disable-next-line no-param-reassign
          item.className = item.trafficLight;
        });
        const final = { requested: res.data.data, view: {} };
        final.view = analyticsFactory2.prepareMapData(
          final.requested,
          blockOptions.legend
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getAllSystemSites'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSystemServlet,
          ANALYTICS_REQUESTS_CONFIG.getAllSystemSites,
          filters
        )
        .then(prepareForView);
    };

    // >>>>> Campaigns analytics requests <<<<<
    //
    const getGeneralInfoCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      // Support Campaigns General Info block for old Presets ----->
      if (!blockOptions.currentFilter_ctr) {
        // eslint-disable-next-line no-param-reassign
        blockOptions.filters = [
          {
            views: true,
            uniqueViews: false
          }
        ];
        // eslint-disable-next-line no-param-reassign
        blockOptions.currentFilter_ctr = 'views';
      }
      // <-----------------------------

      function prepareForView(res) {
        const final = { requested: res.data, view: res.data };
        final.view.ctr =
          final.requested[`${blockOptions.currentFilter_ctr}CTR`];
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getGeneralInfo');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getGeneralInfo,
          filters
        )
        .then(prepareForView);
    };

    const getPollInfo = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        return { requested: res.data, view: res.data.data };
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getGeneralInfo');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getPollInfo,
          filters
        )
        .then(prepareForView);
    };

    const getDataByComparisonCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = analyticsFactory2.setMultiLinesChartOptions(
          res.data.views,
          blockOptions.currentFilter === 'ctr',
          null,
          'area',
          true
        );
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options,
          blockOptions.currentFilter
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByComparison'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByComparison,
          filters
        )
        .then(prepareForView);
    };

    const getDataByDays = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#A8A7B6', '#6AA0EE', '#9F76AB', '#EF769D'];
        options.xAxis = {
          type: 'category',
          categories: analyticsFactory2.setLineBarChartTicksFormat(
            res.data.options.ticksX
          )
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.y2Axis = {
          show: true,
          tick: { format: window.d3.format('.2%'), count: 5 }
        };
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataByDays');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByDays,
          filters
        )
        .then(prepareForView);
    };

    const getDataByDaysAndSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        let options = {};
        if (filters.aggregated) {
          options.colorPattern = ['#A8A7B6', '#6AA0EE', '#9F76AB', '#EF769D'];
          options.xAxis = {
            type: 'category',
            categories: analyticsFactory2.setLineBarChartTicksFormat(
              res.data.options.ticksX
            )
          };
          options.yAxis = {
            tick: { format: window.d3.format('.0f'), count: 5 }
          };
          options.y2Axis = {
            show: true,
            tick: { format: window.d3.format('.2%'), count: 5 }
          };
          options.legend = blockOptions.legend;
          options.aggregated = true;
          final.view = analyticsFactory2.prepareLineData(
            final.requested,
            options
          );
        } else {
          options = analyticsFactory2.setMultiLinesChartOptions(
            res.data[blockOptions.currentFilter],
            blockOptions.currentFilter === 'ctr',
            1
          );
          options.aggregated = false;
          final.view = analyticsFactory2.prepareLineData(
            final.requested,
            options,
            blockOptions.currentFilter
          );
        }
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByDaysAndSites',
        blockOptions
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByDaysAndSites,
          filters
        )
        .then(prepareForView);
    };

    const getDataBySites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        // prepare data with max value
        // eslint-disable-next-line no-param-reassign
        res.data = analyticsFactory2.preparePieWithMaxValue(res.data, true);
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = final.requested.data[0][blockOptions.currentFilter];

        const blockOptionsDefault = analyticsPresetsFactory.getSystemPreset(
          analyticsType
        ).data.analyticBlocks.dataBySites;
        if (!blockOptions.toggleFilters) {
          // eslint-disable-next-line no-param-reassign
          blockOptions.toggleFilters = blockOptionsDefault.toggleFilters;
        }
        if (!blockOptions.sortingFilters || !blockOptions.currentSortFilter) {
          // eslint-disable-next-line no-param-reassign
          blockOptions.sortingFilters = blockOptionsDefault.sortingFilters;
          // eslint-disable-next-line no-param-reassign
          blockOptions.currentSortFilter =
            blockOptionsDefault.currentSortFilter;
        }

        final.view = analyticsFactory2.preparePieByToggles(
          final.view,
          'dataBySites',
          blockOptions.toggleFilters,
          blockOptions.currentFilter !== 'views'
            ? final.requested.data[0].views
            : null
        );

        final.view = analyticsFactory2.preparePieBySorting(
          final.view,
          'dataBySites',
          blockOptions.currentSortFilter
        );

        final.view = analyticsFactory2.preparePieData(
          final.view,
          'dataBySites'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataBySites');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataBySites,
          filters
        )
        .then(prepareForView);
    };

    const getDataByDevicesCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: analyticsFactory2.pieDataProcessing(res.data),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested[blockOptions.currentFilter]
        );
        return final;
      }

      if (reportData)
        return $q
          .resolve({ data: reportData, isReport: true })
          .then(prepareForView);

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByDevices'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByDevicesNew,
          filters
        )
        .then(prepareForView);
    };

    const getDataByDemographicInfoCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: analyticsFactory2.pieDataProcessing(res.data),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested[blockOptions.currentFilter]
        );
        return final;
      }

      if (reportData)
        return $q
          .resolve({ data: reportData, isReport: true })
          .then(prepareForView);

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByDemographicInfo'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByDemographicInfo,
          filters
        )
        .then(prepareForView);
    };

    const getDataByHoursCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#A8A7B6', '#6AA0EE', '#9F76AB', '#EF769D'];
        options.xAxis = {
          type: 'category',
          categories: res.data.options.ticksX
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.y2Axis = {
          show: true,
          tick: { format: window.d3.format('.2%'), count: 5 }
        };
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataByHours');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByHours,
          filters
        )
        .then(prepareForView);
    };

    const getDataByWeekCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#A8A7B6', '#6AA0EE', '#9F76AB', '#EF769D'];
        options.categories = ANALYTICS_CONFIG2.daysOfWeek.map(function(item) {
          return [item.title];
        });
        options.xAxis = {
          type: 'category',
          categories: options.categories.map(function(item) {
            return i18n(`analytics.ticks.${item[0]}`);
          })
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.y2Axis = {
          show: true,
          tick: { format: window.d3.format('.2%'), count: 5 }
        };
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataByWeek');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByWeek,
          filters
        )
        .then(prepareForView);
    };

    const getDataByMonthsCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#A8A7B6', '#6AA0EE', '#9F76AB', '#EF769D'];
        options.categories = ANALYTICS_CONFIG2.monthesOfYear.map(function(
          item
        ) {
          return [item.title];
        });
        options.xAxis = {
          type: 'category',
          categories: options.categories.map(function(item) {
            return i18n(`analytics.ticks.${item[0]}`);
          })
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.y2Axis = {
          show: true,
          tick: { format: window.d3.format('.2%'), count: 5 }
        };
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      const requestFilters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByMonths'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByMonths,
          requestFilters
        )
        .then(prepareForView);
    };

    const getDataByYearsCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: res.data };

        // Set fake data to align chart by center
        final.view = setFakeDataByYears(final.view);

        const options = {};
        options.colorPattern = ['#A8A7B6', '#6AA0EE', '#9F76AB', '#EF769D'];
        options.xAxis = {
          type: 'category',
          categories: res.data.options.ticksX
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.y2Axis = {
          show: true,
          tick: { format: window.d3.format('.0%'), count: 5 }
        };
        options.rotated = false;
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      const requestFilters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByYears'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByYears,
          requestFilters
        )
        .then(prepareForView);
    };

    const getDataBySiteTags = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested.data[0][blockOptions.currentFilter],
          'dataBySiteTags'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataBySiteTags'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataBySiteTags,
          filters
        )
        .then(prepareForView);
    };

    const getDataByCitiesAndCountries = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested.data[0][blockOptions.currentFilter],
          'dataByCitiesAndCountries'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByCitiesAndCountries',
        blockOptions
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByCitiesAndCountries,
          filters
        )
        .then(prepareForView);
    };

    const getDataByNumberOfViews = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        // prepare data with max value
        // eslint-disable-next-line no-param-reassign
        res.data = analyticsFactory2.preparePieWithMaxValue(res.data);
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested.data[0][blockOptions.currentFilter],
          'dataByNumberOfViews'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByNumberOfViews'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByNumberOfViews,
          filters
        )
        .then(prepareForView);
    };

    const getDataByViewersPortrait = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        final.view = analyticsFactory2.preparePortraitsData(
          final.requested.data,
          blockOptions.currentFilter,
          blockOptions.hideUnknown,
          final.requested.unknownIndex
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByViewersPortrait'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByViewersPortrait,
          filters
        )
        .then(prepareForView);
    };

    const getDataByCurrentWidgets = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: res.data.widgets };
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByCurrentWidgets'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsCampaignServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByCurrentWidgets,
          filters
        )
        .then(prepareForView);
    };

    // Sites requests
    //
    const getGeneralInfoSitesAll = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        return { requested: res.data, view: res.data };
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getGeneralInfo');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getGeneralInfo,
          filters
        )
        .then(prepareForView);
    };

    const getGeneralInfoSitesSpecAll = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        final.view.views = final.requested.views;
        final.view.visitors = final.requested.visitors;
        final.view.averageVisitorsPerDay =
          final.requested.averageVisitorsPerDay;
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getGeneralInfo');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getGeneralInfo,
          filters
        )
        .then(prepareForView);
    };

    const getGeneralInfoSitesFiltered = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const view = res.data;
        const spentTime = helpers.timestampToDuration(
          view.averageSpentTimeOnSite
        );
        view.averageSpentTimeOnSite = i18n.bind(
          null,
          'analytics.labels.hoursMinutes',
          spentTime.hours,
          spentTime.minutes
        );
        return { requested: res.data, view };
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getGeneralInfoFiltered'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getGeneralInfo,
          filters
        )
        .then(prepareForView);
    };

    const getDataByComparisonSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = analyticsFactory2.setMultiLinesChartOptions(
          res.data.views,
          blockOptions.currentFilter === 'ctr',
          null,
          'area'
        );
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options,
          blockOptions.currentFilter
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByComparison'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByComparison,
          filters
        )
        .then(prepareForView);
    };

    const getDataByVisitors = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#F39AB6', '#8BADE1'];
        options.xAxis = {
          type: 'category',
          categories: analyticsFactory2.setLineBarChartTicksFormat(
            res.data.options.ticksX
          )
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByVisitors'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByVisitors,
          filters
        )
        .then(prepareForView);
    };

    const getDataByVisitorsWithWeather = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#F39AB6', '#8BADE1'];
        options.xAxis = {
          type: 'category',
          categories: analyticsFactory2.setLineBarChartTicksFormat(
            res.data.options.ticksX
          )
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.grid = {
          y: {
            show: true,
            count: 5
          },
          x: {
            show: true
          }
        };
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      const requestData = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByVisitors'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByVisitors,
          requestData
        )
        .then(prepareForView);
    };

    const getDataByTrafficUsage = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = [
          '#CDCADF',
          '#EA94AF',
          '#7DA6EC',
          '#DCCF96',
          '#98E1B2'
        ];
        options.xAxis = {
          type: 'category',
          categories: analyticsFactory2.setLineBarChartTicksFormat(
            res.data.options.ticksX
          )
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.dataTypes = { summary: 'area' };
        options.globalType = 'line';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByTrafficUsage'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByTrafficUsage,
          filters
        )
        .then(prepareForView);
    };

    const getDataByNumberOfVisits = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested.data[0],
          'dataByNumberOfVisits'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByNumberOfVisits'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByNumberOfVisits,
          filters
        )
        .then(prepareForView);
    };

    const getDataByOtherVisitedSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested.data[0],
          'dataByOtherVisitedSites'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByOtherVisitedSites'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByOtherVisitedSites,
          filters
        )
        .then(prepareForView);
    };

    const getDataByVisitorsAgeAndGender = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        const i18nFilter = $filter('i18n');
        options.colorPattern = ['#ED9AB4', '#A6C4FC', '#CCCADA'];
        options.xAxis = {
          type: 'category',
          categories: res.data.options.ticksX.map(function(item) {
            return i18nFilter(`DAConstants.${item}`);
          })
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByVisitorsAgeAndGender'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByVisitorsAgeAndGender,
          filters
        )
        .then(prepareForView);
    };

    const getDataByNewAndReturningVisitors = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#7DA6EC', '#ED93AE'];
        options.xAxis = {
          type: 'category',
          categories: analyticsFactory2.setLineBarChartTicksFormat(
            res.data.options.ticksX
          )
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByNewAndReturningVisitors'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByNewAndReturningVisitors,
          filters
        )
        .then(prepareForView);
    };

    const getDataByNotReturningVisitors = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#A6C4FC'];
        options.categories = analyticsFactory2.setTicksLastSixMonths(
          res.data.options.ticksX
        );
        options.xAxis = {
          type: 'category',
          categories: options.categories.map(function(item) {
            return `${i18n(`analytics.ticks.${item[0]}`)}, ${item[1]}`;
          })
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.globalType = 'bar';
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByNotReturningVisitors'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByNotReturningVisitors,
          filters
        )
        .then(prepareForView);
    };

    const getDataByCampaignsViews = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        final.view = final.requested[blockOptions.currentFilter];
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByCampaignsViews'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByCampaignsViews,
          filters
        )
        .then(prepareForView);
    };

    const getDataByCampaigns = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested.data[0][blockOptions.currentFilter],
          'dataByCampaigns'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByCampaigns'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByCampaigns,
          filters
        )
        .then(prepareForView);
    };

    const getDataByCitiesAndCountriesSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: !res.isReporting
            ? res.data
            : analyticsFactory2.pieDataProcessing(res.data, true),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(
          final.requested.data[0][blockOptions.currentFilter],
          'dataByCitiesAndCountriesSites'
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByCitiesAndCountriesSites',
        blockOptions
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByCitiesAndCountries,
          filters
        )
        .then(prepareForView);
    };

    const getDataByDevicesSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: analyticsFactory2.pieDataProcessing(res.data),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(final.requested);
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByDevices'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByDevices,
          filters
        )
        .then(prepareForView);
    };

    const getDataByDemographicInfoSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = {
          requested: analyticsFactory2.pieDataProcessing(res.data),
          view: {}
        };
        final.view = analyticsFactory2.preparePieData(final.requested);
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(
        filters,
        analyticsType,
        'getDataByDemographicInfo'
      );
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByDemographicInfo,
          filters
        )
        .then(prepareForView);
    };

    const getDataByHoursSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#ED9AB4', '#A6C4FC'];
        options.xAxis = {
          type: 'category',
          categories: res.data.options.ticksX
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataByHours');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByHours,
          filters
        )
        .then(prepareForView);
    };

    const getDataByWeekSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#ED9AB4', '#A6C4FC'];
        options.categories = ANALYTICS_CONFIG2.daysOfWeek.map(function(item) {
          return [item.title];
        });
        options.xAxis = {
          type: 'category',
          categories: options.categories.map(function(item) {
            return i18n(`analytics.ticks.${item[0]}`);
          })
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataByWeek');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByWeek,
          filters
        )
        .then(prepareForView);
    };

    const getDataByMonthsSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: {} };
        const options = {};
        options.colorPattern = ['#ED9AB4', '#A6C4FC'];
        options.categories = ANALYTICS_CONFIG2.monthesOfYear.map(function(
          item
        ) {
          return [item.title];
        });
        options.xAxis = {
          type: 'category',
          categories: options.categories.map(function(item) {
            return i18n(`analytics.ticks.${item[0]}`);
          })
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataByMonths');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByMonths,
          filters
        )
        .then(prepareForView);
    };

    const getDataByYearsSites = function(
      filters,
      blockOptions,
      analyticsType,
      chartOptions,
      reportData
    ) {
      function prepareForView(res) {
        const final = { requested: res.data, view: res.data };

        // Set fake data to align chart by center
        final.view = setFakeDataByYears(final.view);

        const options = {};
        options.colorPattern = ['#ED9AB4', '#A6C4FC'];
        options.xAxis = {
          type: 'category',
          categories: res.data.options.ticksX
        };
        options.yAxis = {
          tick: { format: window.d3.format('.0f'), count: 5 }
        };
        options.rotated = false;
        options.globalType = 'bar';
        options.legend = blockOptions.legend;
        final.view = analyticsFactory2.prepareLineData(
          final.requested,
          options
        );
        return final;
      }

      if (reportData) {
        return $q
          .resolve({ data: reportData, isReporting: true })
          .then(prepareForView);
      }

      // eslint-disable-next-line no-param-reassign
      filters = prepareRequestParams(filters, analyticsType, 'getDataByYears');
      return requestFactory
        .post(
          ANALYTICS_REQUESTS_CONFIG.analyticsSiteServlet,
          ANALYTICS_REQUESTS_CONFIG.getDataByYears,
          filters
        )
        .then(prepareForView);
    };

    const getAvailableValues = function(analyticsType, permissionType) {
      const availableValues = {
        campaigns: null,
        sites: null,
        sitesCities: null,
        partners: null,
        advertisers: null,
        sitesTags: null,
        campaignsTags: null
      };

      const prepareData = function prepareData(
        array,
        typeName,
        excelType,
        type
      ) {
        return array.map(function(item) {
          // eslint-disable-next-line no-param-reassign
          item.className = typeName;
          // add missing type property for site tags
          // eslint-disable-next-line no-param-reassign
          if (type) item.type = typeName;
          // eslint-disable-next-line no-param-reassign
          if (excelType) item.excelType = excelType;
          return item;
        });
      };

      const campaignsPromise = getAllCampaigns(
        analyticsType,
        permissionType
      ).then(
        function(data) {
          availableValues.campaigns = prepareData(data.campaigns, 'campaigns');
          availableValues.campaigns.forEach(function(item) {
            // eslint-disable-next-line no-param-reassign
            item.link = `#/campaigns/${item.id}/details`;
          });
        },
        function() {
          return $q.reject(campaignsPromise);
        }
      );

      const sitesPromise = getAllSites(analyticsType, permissionType).then(
        function(data) {
          availableValues.sites = prepareData(data.sites, 'sites');
          availableValues.sites.forEach(function(item) {
            // eslint-disable-next-line no-param-reassign
            item.link = `#/sites/${item.id}/details`;
          });
          availableValues.sitesCities = prepareData(
            data.sites
              .reduce(function(prev, curr) {
                if (curr.city && prev.indexOf(curr.city) === -1)
                  prev.push(curr.city);
                return prev;
              }, [])
              .map(function(city) {
                return { name: city };
              }),
            'cities'
          );
        },
        function() {
          return $q.reject(sitesPromise);
        }
      );

      const entitiesPromise = getAllEntities(analyticsType).then(
        function(data) {
          availableValues.partners = prepareData(
            data.partners,
            'entities',
            'entities'
          );
          availableValues.advertisers = prepareData(
            data.advertisers,
            'entities',
            'entities'
          );
        },
        function() {
          return $q.reject(entitiesPromise);
        }
      );

      const sitesTagsPromise = getTagsForSites(analyticsType).then(
        function(data) {
          availableValues.sitesTags = prepareData(
            data.tagsTypes,
            'tagsTypes',
            'tags',
            true
          ).concat(prepareData(data.tagsClasses, 'tagsClasses', 'tags', true));
        },
        function() {
          return $q.reject(sitesTagsPromise);
        }
      );

      const campaignsTagsPromise = getTagsForCampaigns(analyticsType).then(
        function(data) {
          availableValues.campaignsTags = prepareData(
            data.tags,
            'campaignsTags',
            'tags'
          );
          availableValues.campaignsLabels = prepareData(
            data.labels,
            'campaignsLabels',
            'labels'
          );
        },
        function() {
          return $q.reject(campaignsTagsPromise);
        }
      );

      return $q
        .all([
          campaignsPromise,
          sitesPromise,
          entitiesPromise,
          sitesTagsPromise,
          campaignsTagsPromise
        ])
        .then(
          function() {
            return availableValues;
          },
          function() {
            return $q.reject();
          }
        );
    };

    // Returning data
    return {
      getGeneralInfoSitesAll,
      getGeneralInfoSitesSpecAll,
      getGeneralInfoSitesFiltered,
      getDataByComparisonSites,
      getDataByVisitors,
      getDataByVisitorsWithWeather,
      getDataByTrafficUsage,
      getDataByNumberOfVisits,
      getDataByOtherVisitedSites,
      getDataByVisitorsAgeAndGender,
      getDataByNewAndReturningVisitors,
      getDataByNotReturningVisitors,
      getDataByCampaignsViews,
      getDataByCampaigns,
      getDataByCitiesAndCountriesSites,
      getDataByDevicesSites,
      getDataByDemographicInfoSites,
      getDataByHoursSites,
      getDataByWeekSites,
      getDataByMonthsSites,
      getDataByYearsSites,
      getVacantSites,

      getGeneralInfoCampaigns,
      getPollInfo,
      getDataByComparisonCampaigns,
      getDataByDays,
      getDataBySites,
      getDataByDevicesCampaigns,
      getDataByDemographicInfoCampaigns,
      getDataByHoursCampaigns,
      getDataByWeekCampaigns,
      getDataByMonthsCampaigns,
      getDataByYearsCampaigns,
      getDataByDaysAndSites,
      getDataBySiteTags,
      getDataByCitiesAndCountries,
      getDataByNumberOfViews,
      getDataByViewersPortrait,
      getDataByCurrentWidgets,

      getCampaignsTimeline,
      getGeneralInfoSystem,
      getDataByTraffic,
      getAmountOfViewsAndVisitors,
      getAmountOfCampaigns,
      getAmountOfWidgets,
      getAmountOfSitesAndEntities,
      getQuantityOfRegisteredUsers,
      getDataByTags,
      getAllSystemSites,

      getAllCampaigns,
      getAllSites,
      getAllSitesForCampaign,
      getAllEntities,
      getAllEntitiesForCampaign,
      getTagsForCampaigns,
      getTagsForSites,
      getTagsForSitesForCampaign,

      setManageBlocksData,
      resetManageBlocksData,
      getBlocksData,
      mapFiltersToRequest,

      prepareRequestParams,
      getAvailableValues
    };
  });
