/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm').factory('filtersFactory', function() {
  let filters = [];
  function Filter(params) {
    this.label = params.label;
    this.options = [];
    this.activeIdx = params.activeIdx || 0;
    this.type = params.type || 'filter'; // possible values: filter or sort
    this.searchable = !!params.searchable;
    this.isHidden = params.isHidden;

    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const key in params.options) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.options.hasOwnProperty(key)) {
        this.options.push({
          label: key,
          value: params.options[key]
        });
      }
    }
  }

  Filter.prototype = {
    activeOption(idx) {
      if (angular.isNumber(idx)) {
        this.activeIdx = idx;
        return this;
      }
      return this.options[this.activeIdx];
    },

    addOption(label, value) {
      this.options.push({ label, value });
    }
  };

  return {
    initFilters(options) {
      // eslint-disable-next-line prefer-rest-params
      const filtersInitOpts = angular.isArray(options)
        ? options
        : [].slice.call(arguments);

      filters = filtersInitOpts.map(function(initObj) {
        return new Filter(initObj);
      });
      return filters;
    },
    getFilters() {
      return filters;
    }
  };
});
