/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.export').constant('EXPORT_CONFIG', {
  campaignServlet: 'campaignexcel',
  analyticsCampaignServlet: 'analyticsCampaign',
  analyticsSiteServlet: 'analyticsSite',
  analyticsSystemServlet: 'analyticsSystem',
  reportingServlet: 'reporting',
  analyticsVisitor: 'analyticsVisitor',
  siteSpecific: 'exportSiteSpecific',
  campaignSpecific: 'exportCampaignSpecific',
  sites: 'exportSystemWide',
  campaigns: 'exportCampaigns',
  general: 'exportGeneral',
  visitors: 'exportVisitors',
  reporting: 'exportReport',

  fileXls: 'xls',
  filePdf: 'pdf',
  analyticsTypes: [
    'general',
    'sites',
    'campaigns',
    'analyticsCampaign',
    'analyticsSite'
  ],
  visitorsTypes: ['visitorsSite', 'viewersCampaign'],
  reportingTypes: ['reporting']
});
