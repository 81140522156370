import React from 'react';
import { Route } from 'react-router-dom';
import SignIn from './SignIn';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import { useTheme } from 'core/theme';
import { withRoot } from 'core/components/Root';
import styles from './LoginPage.module.css';
import { useSystemInfo } from 'core/systemInfo';
import LanguageSelector from 'ui/LanguageSelector';
import { useTranslation } from 'react-i18next';
import ngDeps from 'ngDeps';

export const LoginPage = () => {
  const {
    $rootScope: {
      i18n: { availableLocales }
    },
    i18n: { setCurrentLocale }
  } = ngDeps;
  const { theme } = useTheme();
  const systemInfo = useSystemInfo();
  const { i18n } = useTranslation();
  const currentLocale = availableLocales.find(
    locale => locale.id === i18n.language
  );

  return (
    <div className={styles.container}>
      <div className="layout__login-container">
        <div className="layout__body-centered">
          <div className="authorizing t-center">
            <div className="authorizing__system-version">
              <span data-hook-id="str_systemVersion">
                {systemInfo.buildName}
              </span>
            </div>
            <LanguageSelector
              className={styles.languageSelector}
              locales={availableLocales}
              selectedLocale={currentLocale}
              onSelectedLocaleChanged={locale =>
                setCurrentLocale(locale.id, 'login')
              }
            />
            <img
              className="authorizing__logo"
              alt="system logo"
              src="/static/theme/admin/images/systemLogoImage.png"
            />
            <h1 className="authorizing__title" data-hook-id="systemName">
              {theme.systemName}
            </h1>
            <div>
              <Route path="/login" component={SignIn} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/resetPassword" component={ResetPassword} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginPage.propTypes = {};

export default withRoot(LoginPage);
