import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Menu.module.css';
import MenuItem from './MenuItem';
import { useNgDeps } from 'ngDeps';
import { withRouter } from 'react-router';

const Menu = ({ currentUser, location }) => {
  const {
    ngDeps: {
      applicationScope: {
        hasPermissionsForSection,
        hasPermissionsForAnalytics,
        hasPermissionsForReports
      }
    }
  } = useNgDeps();
  const [openedItems, setOpenedItems] = useState([]);
  const allItems = [
    {
      id: 'campaigns',
      name: 'sidebar.mainMenu.menuItems.campaigns',
      path: '/campaigns/list',
      activePath: '/campaigns/class',
      subItems: [],
      hasPermission: () => hasPermissionsForSection('campaigns')
    },
    {
      id: 'widgets',
      name: 'sidebar.mainMenu.menuItems.widgets',
      path: '/widgets/list',
      activePath: '/widgets/',
      subItems: [],
      hasPermission: () => hasPermissionsForSection('widgets')
    },
    {
      id: 'sites',
      name: 'sidebar.mainMenu.menuItems.sites',
      path: '/sites/sites',
      activePath: '/sites/',
      subItems: [],
      hasPermission: () => hasPermissionsForSection('sites')
    },
    {
      id: 'entities',
      name: 'sidebar.mainMenu.menuItems.entities',
      path: '/entities/classB',
      activePath: '/entities/',
      subItems: [],
      hasPermission: () => hasPermissionsForSection('entities')
    },
    {
      id: 'analytics',
      name: 'sidebar.mainMenu.menuItems.analytics',
      path: '/analytics/campaigns',
      activePath: '/analytics/',
      subItems: [],
      hasPermission: hasPermissionsForAnalytics
    },
    {
      id: 'admin',
      name: 'sidebar.mainMenu.menuItems.admin',
      path: null,
      activePath: '/admin/',
      hasPermission: () =>
        currentUser.userRole === 'GHOST' ||
        currentUser.userRole === 'ADMINISTRATOR',
      subItems: [
        {
          id: 'settings',
          name: 'sidebar.mainMenu.menuItems.settings',
          path: '/admin/settings',
          activePath: '/admin/settings'
        },
        {
          id: 'dictionaries',
          name: 'sidebar.mainMenu.menuItems.dictionaries',
          path: '/admin/dictionaries',
          activePath: '/admin/dictionaries'
        },
        {
          id: 'localization',
          name: 'sidebar.mainMenu.menuItems.localization',
          path: '/admin/localization/system',
          activePath: '/admin/localization'
        },
        {
          id: 'users',
          name: 'sidebar.mainMenu.menuItems.users',
          path: '/admin/users',
          activePath: '/admin/users'
        },
        {
          id: 'macExceptions',
          name: 'sidebar.mainMenu.menuItems.macExceptions',
          path: '/admin/macExceptions',
          activePath: '/admin/macExceptions'
        },
        {
          id: 'license',
          name: 'sidebar.mainMenu.menuItems.license',
          path: '/admin/license',
          activePath: '/admin/license'
        },
        {
          id: 'clientManagement',
          name: 'sidebar.mainMenu.menuItems.clientManagement',
          path: '/admin/clientManagement',
          activePath: '/admin/clientManagement'
        },
        {
          id: 'vendorSettings',
          name: 'sidebar.mainMenu.menuItems.vendorSettings',
          path: '/admin/vendorSettings',
          activePath: '/admin/vendorSettings'
        },
        {
          id: 'logs',
          name: 'sidebar.mainMenu.menuItems.logs',
          path: '/admin/logs/systemLogs',
          activePath: '/admin/logs'
        }
      ]
    },
    {
      id: 'reports',
      name: 'sidebar.mainMenu.menuItems.reports',
      path: null,
      activePath: '/reporting/',
      hasPermission: hasPermissionsForReports,
      subItems: [
        {
          id: 'reportsSystemWide',
          name: 'sidebar.mainMenu.menuItems.reportsSystemWide',
          path: '/reporting/system-wide/general/list',
          activePath: '/reporting/system-wide',
          hasPermission: () => hasPermissionsForReports('systemWide')
        },
        {
          id: 'reportsCampaignSpecific',
          name: 'sidebar.mainMenu.menuItems.reportsCampaignSpecific',
          path: '/reporting/campaign-specific/analytics/list',
          activePath: '/reporting/campaign-specific',
          hasPermission: () => hasPermissionsForReports('campaignSpecificMenu')
        },
        {
          id: 'reportsSiteSpecific',
          name: 'sidebar.mainMenu.menuItems.reportsSiteSpecific',
          path: '/reporting/site-specific/analytics/list',
          activePath: '/reporting/site-specific',
          hasPermission: () => hasPermissionsForReports('siteSpecificMenu')
        },
        {
          id: 'reportsMonitoring',
          name: 'sidebar.mainMenu.menuItems.reportsMonitoring',
          path: '/reporting/monitoring/list',
          activePath: '/reporting/monitoring',
          hasPermission: () =>
            currentUser.userId === 'Admin' || currentUser.userRole === 'GHOST'
        }
      ]
    },
    {
      id: 'monitoring',
      name: 'sidebar.mainMenu.menuItems.monitoring',
      path: null,
      activePath: '/monitoring/',
      hasPermission: () =>
        currentUser.userId === 'Admin' || currentUser.userRole === 'GHOST',
      subItems: [
        {
          id: 'collections',
          name: 'sidebar.mainMenu.menuItems.collections',
          path: '/monitoring/collections/manual',
          activePath: '/monitoring/collections'
        },
        {
          id: 'visitorsList',
          name: 'sidebar.mainMenu.menuItems.visitorsList',
          path: '/monitoring/visitors/list',
          activePath: '/monitoring/visitors'
        }
      ]
    }
  ];

  const checkPermission = item =>
    item.hasPermission ? item.hasPermission() : true;
  let menuItems;

  const calcMenuItems = () => {
    let items;
    if (currentUser.launchMode === 'demo') {
      items = [
        {
          id: 'admin',
          name: 'sidebar.mainMenu.menuItems.admin',
          path: null,
          activePath: '/admin/',
          subItems: [
            {
              id: 'license',
              name: 'sidebar.mainMenu.menuItems.license',
              path: '/admin/license',
              activePath: '/admin/license'
            }
          ]
        }
      ];
    } else {
      items = allItems.filter(item => {
        if (item.subItems && item.subItems.length > 0) {
          item.subItems = item.subItems.filter(checkPermission);
        }
        return checkPermission(item);
      });
    }
    return items;
  };

  menuItems = useMemo(calcMenuItems, [currentUser]);

  return (
    <ul className={styles.menu}>
      {menuItems.map((item, index) => (
        <li key={index}>
          <MenuItem
            openedItems={openedItems}
            location={location}
            setOpenedItems={setOpenedItems}
            item={item}
          />
        </li>
      ))}
    </ul>
  );
};

Menu.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(Menu);
