/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.core')
  .controller('manageBlockFiltersController', function(
    $scope,
    $modal,
    helpers,
    manageBlockFactory,
    MANAGE_BLOCK_CONFIG
  ) {
    const ctrl = this;
    const config = MANAGE_BLOCK_CONFIG;

    ctrl.removeStep = function(idx) {
      helpers
        .confirmationModal(
          '',
          'analytics.manageBlock.validation.removeStep',
          true
        )
        .result.then(function() {
          ctrl.filterSteps.splice(idx, 1);
          setViewValues();
          onFiltersChange();
        });
    };

    ctrl.editStep = function(idx) {
      const checkedFiltersIds = ctrl.filterSteps[idx].map(function(filterSet) {
        return filterSet.values.map(function(value) {
          return value[filterSet.trackBy];
        });
      });
      const filters = ctrl.filters.map(function(filterSet, idx) {
        const checkedIds = checkedFiltersIds[idx];
        return window._.extend({}, filterSet, {
          values: filterSet.values.map(function(value) {
            return window._.extend({}, value, {
              checked: !!~checkedIds.indexOf(value[filterSet.trackBy])
            });
          })
        });
      });
      const indexOfStepWithEntity = manageBlockFactory.indexOfStepWithFiltersOfType(
        ctrl.filterSteps,
        'entity'
      );

      openStepModal(
        filters,
        false,
        ~indexOfStepWithEntity && indexOfStepWithEntity !== idx
      ).result.then(function(res) {
        ctrl.filterSteps[idx] = res;
        setViewValues();
        onFiltersChange();
      });
    };

    ctrl.addStep = function() {
      const filters = ctrl.filters.map(function(filterSet) {
        return window._.extend({}, filterSet, {
          values: helpers.shallowCopy(filterSet.values)
        });
      });

      const indexOfStepWithEntity = manageBlockFactory.indexOfStepWithFiltersOfType(
        ctrl.filterSteps,
        'entity'
      );
      openStepModal(filters, true, ~indexOfStepWithEntity).result.then(function(
        res
      ) {
        ctrl.filterSteps.push(res);
        setViewValues();
        onFiltersChange();
      });
    };

    ctrl.$onInit = function() {
      setViewValues();
    };

    function setViewValues() {
      ctrl.steps = ctrl.filterSteps.map(function(filterStepSet) {
        return manageBlockFactory.limitTo(
          config.stepItemsLimit,
          filterStepSet.reduce(function(acc, curr) {
            return acc.concat(
              curr.values.map(function(value) {
                return window._.extend(value, { type: curr.type });
              })
            );
          }, [])
        );
      });
    }

    function onFiltersChange() {
      const idsToCheck = manageBlockFactory.getFilteredIds(
        ctrl.filterSteps,
        ctrl.valuesType
      );
      ctrl.onValuesChange({
        availableValues: getUpdatedEntries(
          ctrl.availableValues,
          idsToCheck,
          true
        )
      });
    }

    function openStepModal(filters, isNewStep, isEntityDisabled) {
      const scope = $scope.$new(true);
      scope.filters = filters;
      scope.isNewStep = isNewStep;
      scope.isEntityDisabled = isEntityDisabled;

      return $modal.open({
        scope,
        windowClass: 'modal_manage-block',
        templateUrl:
          'app/core/components/manageBlock/manageBlockStepModal.html',
        controller($scope, $modalInstance) {
          'ngInject';

          init();

          $scope.onSelectionChange = function() {
            $scope.state.isValid = validateFilters(
              getCheckedFilters($scope.filters)
            );
          };

          $scope.save = function() {
            $scope.state.submitted = true;

            const checkedFilters = getCheckedFilters($scope.filters);

            $scope.state.isValid = validateFilters(checkedFilters);

            if (!$scope.state.isValid) {
              return false;
            }

            return $modalInstance.close(checkedFilters);
          };

          $scope.cancel = function() {
            $modalInstance.dismiss();
          };

          function init() {
            $scope.state = {
              isValid: false,
              submitted: false
            };
            // selectedFilterIdx: 0
            const indexOfEntity = window._.findIndex($scope.filters, function(
              filter
            ) {
              return filter.name === 'entity';
            });
            const indexOfFirstNotEntity = window._.findIndex(
              $scope.filters,
              function(filter) {
                return filter.name !== 'entity';
              }
            );
            $scope.state.selectedFilterIdx =
              !$scope.isEntityDisabled ||
              indexOfEntity !== 0 ||
              $scope.filters.length === 1
                ? 0
                : indexOfFirstNotEntity;
          }

          function getCheckedFilters(filters) {
            return filters.map(function(filtersSet) {
              return window._.extend({}, filtersSet, {
                values: filtersSet.values.filter(function(item) {
                  return item.checked;
                })
              });
            });
          }

          function validateFilters(filterSets) {
            return filterSets.some(function(set) {
              return set.values.some(function(item) {
                return item.checked;
              });
            });
          }
        }
      });
    }

    function getUpdatedEntries(entries, idsToCheck, unselectAll) {
      return entries.map(function(entry) {
        const copy = window._.extend({}, entry);
        if (unselectAll) {
          copy.checked = false;
        }
        if (~idsToCheck.indexOf(copy.id)) {
          copy.checked = true;
        }
        return copy;
      });
    }
  });
