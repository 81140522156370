const { angular } = window;

angular
  .module('wcm.core')
  .factory('wasRequestsAddParamsInterceptor', function(
    $q,
    $rootScope,
    errorHelpers,
    ANALYTICS_PRESETS_CONFIG,
    ANALYTICS_REQUESTS_CONFIG,
    MONITORING_CONFIG,
    REPORTING_CONFIG
  ) {
    const excludedRequests = [
      'campaignGetAllCities',
      'campaignGetOccupation',
      'campaignGetInterests'
    ];
    const additionRequests = ['campaignGetLivePrognosis'];
    const requests = window._.values(ANALYTICS_PRESETS_CONFIG)
      .concat(window._.values(ANALYTICS_REQUESTS_CONFIG))
      .concat(window._.values(MONITORING_CONFIG))
      .concat(window._.values(REPORTING_CONFIG))
      .concat(additionRequests)
      .filter(function(request) {
        return (
          typeof request === 'string' && !~excludedRequests.indexOf(request)
        );
      });

    const request = function(config) {
      // eslint-disable-next-line no-param-reassign
      if (!config.params) {
        config.params = {};
      }
      if (
        config.method === 'POST' &&
        errorHelpers.isContainAction(config.url, requests)
      ) {
        // eslint-disable-next-line no-param-reassign
        config.data.uniquePageId = $rootScope.uniqueBrowserTabToken;
        // Set cancel pending for analytics/presets/monitoring/reporting requests
        if (!config.timeout) {
          // eslint-disable-next-line no-param-reassign
          config.cancel = $q.defer();
          // eslint-disable-next-line no-param-reassign
          config.timeout = config.cancel.promise;
        }
        return config || $q.when(config);
      }
      return config;
    };

    return {
      request
    };
  });
