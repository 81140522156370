/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('usersListCtrl', function(
    $scope,
    $modal,
    $state,
    breadcrumbs,
    navigationPanel,
    usersFactory,
    filtersFactory,
    helpers
  ) {
    breadcrumbs.set({
      path: [
        {
          name: 'admin'
        },
        {
          name: 'users'
        }
      ]
    });
    $scope.setTabs([]);

    let sortOption;
    let appliedFilters;
    $scope.filters = filtersFactory.initFilters(
      {
        label: 'Status',
        options: {
          Active: 'Active',
          Archive: 'Archive'
        }
      },
      {
        label: 'Group',
        options: {
          All: 'All',
          Admins: 'Admins',
          CMS: 'CMS',
          Clients: 'Clients'
        }
      },
      {
        label: 'Sort',
        options: {
          'date:desc': 'date:desc',
          'date:asc': 'date:asc',
          'alphabetically:asc': 'alphabetically:asc',
          'alphabetically:desc': 'alphabetically:desc'
        },
        type: 'sort'
      }
    );

    $scope.init = function init() {
      const currentUser = $scope.currentUser;
      // Add ghosts group to filter for ghosts and super admin user
      if (currentUser.userRole === 'GHOST' || currentUser.userId === 'Admin') {
        $scope.filters[1].addOption('Ghosts', 'Ghosts');
      }
    };

    $scope.goToView = function(id) {
      $state.go('section.admin.users.action.view', { id });
    };

    $scope.getPage = function getPage() {
      usersFactory
        .getList(
          appliedFilters,
          $scope.currentPage,
          $scope.itemsOnPage,
          sortOption
        )
        .then(function(users) {
          $scope.userList = users;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.onListUpdate = function onListUpdate(sortOpt, filters) {
      sortOption = sortOpt;
      appliedFilters = filters;
      $scope.getPage();
    };

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(
      data,
      requestPayload
    ) {
      $scope.totalItems = data.itemsTotal;
      $scope.archiveState = requestPayload.filterByStatus === 'Active';
    };

    $scope.performAction = function(action, userId) {
      helpers
        .confirmationModal(
          '',
          `admin.modals.userActions.texts.${action}`,
          userId
        )
        .result.then(function() {
          usersFactory[action](userId).success(function() {
            $scope.applyFilters(true);
          });
        });
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => !$scope.archiveState,
        isShown: () => true,
        clickHandler: () => {
          $state.go('section.admin.users.action.new');
        }
      }
    ]);
  });
