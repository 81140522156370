const { angular } = window;

angular
  .module('wcm.reporting')
  .filter('remainingTime', function($filter) {
    const i18n = $filter('i18n');
    function remainingTimeFilter(time) {
      let result = '';
      if (!angular.isDefined(time)) return time;
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      if (days) {
        result += `${i18n('analytics.labels.days', [days])} `;
      }
      if (hours || days) {
        result += i18n('analytics.labels.hoursMinutes', [hours, minutes]);
      } else {
        result = i18n('analytics.labels.minutesSeconds', [minutes, seconds]);
      }
      return result;
    }
    remainingTimeFilter.$stateful = true;
    return remainingTimeFilter;
  });
