/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('userPermissionsCtrl', function(
    $q,
    $scope,
    $modal,
    $timeout,
    $state,
    usersFactory,
    manageBlockFactory,
    analyticsRequestsFactory,
    helpers
  ) {
    $scope.currentUserId = $state.params.id;
    $scope.selected = {
      item: undefined
    };
    $scope.currentTab = '';
    $scope.noWas = true;
    $scope.permissions = {
      permissions: []
    };
    $scope.pagination = {
      filteredPermissions: [],
      permissionsPage: [],
      currentPage: 1,
      itemsPerPage: 10,
      itemsPerPageValues: [10, 20, 40, 80]
    };

    const filterSteps = [];

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      filterValues: {},
      alreadyChecked: [],
      analyticsParams: [
        'createReport',
        'createReportVisitorList',
        'viewReport',
        'viewReportVisitorList',
        'exportAnalytics',
        'exportAnalyticsVisitorList',
        'viewAnalytics',
        'viewAnalyticsVisitorList'
      ],
      newAnalyticsParams: [
        'newView',
        'newModify',
        'newDelete',
        'newViewAnalytics',
        'newExportAnalytics',
        'newViewAnalyticsVisitorList',
        'newExportAnalyticsVisitorList'
      ]
    });

    // eslint-disable-next-line no-multi-assign
    const filters = ($scope.filters = {});
    let valuesType = '';
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {
      manageFilterSettings: {}
    });

    const setManageBlocksConfig = function(filters) {
      if (valuesType === 'sites') {
        state.manageFilterSettings.itemsFilter = [
          {
            title: 'classAEntities',
            name: 'entity',
            values: filters.entities,
            trackBy: 'id'
          },
          {
            title: 'tagsClasses',
            name: 'tags',
            values: filters.tagsClasses,
            trackBy: 'name'
          },
          {
            title: 'tagsTypes',
            name: 'tags',
            values: filters.tagsTypes,
            trackBy: 'name'
          }
        ];
      } else {
        state.manageFilterSettings.itemsFilter = [
          {
            title: 'entities',
            name: 'entity',
            values: filters.entities,
            trackBy: 'id',
            filterTypes: ['Partner', 'Advertiser']
          },
          {
            title: 'tagsCampaigns',
            name: 'tags',
            values: filters.tags,
            trackBy: 'name'
          },
          {
            title: 'labelsCampaigns',
            name: 'labels',
            values: filters.labels,
            trackBy: 'name'
          }
        ];
      }
    };

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      getAvailableValues() {
        const entitiesPromise = analyticsRequestsFactory
          .getAllEntities(valuesType)
          .then(
            function(data) {
              options.filterValues.partners = prepareData(
                data.partners,
                'entities',
                'entities'
              );
              options.filterValues.advertisers = prepareData(
                data.advertisers,
                'entities',
                'entities'
              );
              return true;
            },
            function() {
              return false;
            }
          );

        const sitesTagsPromise = analyticsRequestsFactory
          .getTagsForSites(valuesType)
          .then(
            function(data) {
              options.filterValues.tagsTypes = prepareData(
                data.tagsTypes,
                'tagsTypes',
                true
              );
              options.filterValues.tagsClasses = prepareData(
                data.tagsClasses,
                'tagsClasses',
                true
              );
              return true;
            },
            function() {
              return false;
            }
          );

        const campaignsTagsPromise = analyticsRequestsFactory
          .getTagsForCampaigns(valuesType)
          .then(
            function(data) {
              options.filterValues.campaignsTags = prepareData(
                data.tags,
                'campaignsTags'
              );
              options.filterValues.campaignsLabels = prepareData(
                data.labels,
                'campaignsLabels'
              );
              return true;
            },
            function() {
              return false;
            }
          );
        return $q.all([
          campaignsTagsPromise,
          sitesTagsPromise,
          entitiesPromise
        ]);
      },

      filterBy(items, type) {
        return items.reduce(function(prev, curr) {
          if (curr.checked) prev.push(curr[type]);
          return prev;
        }, []);
      },

      filterItems(items) {
        $scope.permissions = angular.extend({}, $scope.permissions, {
          permissions: items
        });
      }
    });

    function prepareData(array, typeName, isDifTypes) {
      return array.map(function(item) {
        // eslint-disable-next-line no-param-reassign
        item.className = typeName;
        if (typeName === 'campaigns' || typeName === 'sites') {
          // eslint-disable-next-line no-param-reassign
          item.name = item.title;
          // eslint-disable-next-line no-param-reassign
          item.checked = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          item.checked = false;
        }
        // add missing type property for site tags
        // eslint-disable-next-line no-param-reassign
        if (isDifTypes) item.type = typeName;
        return item;
      });
    }
    function getUserPermissions(tabName) {
      if (tabName === 'systemAnalytics') {
        usersFactory
          .getUserSystemWideAnalyticsPermissions($scope.currentUserId)
          .then(function(permissions) {
            $scope.permissions = permissions;
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      } else if (tabName === 'reports') {
        usersFactory
          .getUserReportsPermissions($scope.currentUserId)
          .then(function(permissions) {
            $scope.permissions = permissions;
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      } else {
        usersFactory
          .getUserPermissions($scope.currentUserId, $scope.currentTab, '')
          .then(function(data) {
            $scope.permissions = data;

            if (
              $scope.currentTab === 'partnerCampaign' ||
              $scope.currentTab === 'advertiserCampaign'
            ) {
              valuesType = 'campaigns';
              $scope.permissions.permissions = prepareData(
                $scope.permissions.permissions,
                valuesType
              );
            } else if ($scope.currentTab === 'site') {
              valuesType = 'sites';
              $scope.permissions.permissions = prepareData(
                $scope.permissions.permissions,
                valuesType
              );
            } else {
              valuesType = $scope.currentTab;
            }

            if (
              !options.filterValues.partners &&
              !options.filterValues.advertisers &&
              !options.filterValues.campaignsTags &&
              !options.filterValues.campaignsLabels &&
              !options.filterValues.sitesTags
            ) {
              handlers.getAvailableValues().then(function(res) {
                $scope.noWas = res.some(function(i) {
                  return !i;
                });
              });
            }
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      }
    }
    $scope.activateTab = function(tabName) {
      if ($scope.uiState.editMode) {
        cancelConfirmModal().then(function() {
          $scope.uiState.editMode = false;
          $scope.permissionsForm.$setPristine(true);
          $scope.activateTab(tabName);
        });
      }

      $scope.search.query = '';
      $scope.submitted = false;
      $scope.currentTab = tabName;
      $scope.pagination.currentPage = 1;
      $scope.setBusy(true);
      getUserPermissions(tabName);

      return undefined;
    };

    $scope.$watch('permissions', function() {
      $scope.filterPermissions('');
    });
    $scope.$watch('pagination.filteredPermissions', function() {
      $scope.getPermissionsPage();
    });

    $scope.manage = function() {
      const itemsFilter = {
        items: angular.copy($scope.permissions.permissions),
        entities: angular.copy(
          valuesType === 'sites'
            ? options.filterValues.partners
            : options.filterValues.partners.concat(
                options.filterValues.advertisers
              )
        )
      };
      if (valuesType === 'campaigns') {
        itemsFilter.tags = angular.copy(
          options.filterValues.campaignsTags
        );
        itemsFilter.labels = angular.copy(
          options.filterValues.campaignsLabels
        );
      } else {
        itemsFilter.tagsTypes = angular.copy(
          options.filterValues.tagsTypes
        );
        itemsFilter.tagsClasses = angular.copy(
          options.filterValues.tagsClasses
        );
      }
      filters.itemIds = handlers.filterBy(itemsFilter.items, 'id');
      setManageBlocksConfig(itemsFilter);
      const title = $scope.currentTab === 'site' ? 'sites' : $scope.currentTab;

      manageBlockFactory
        .openFiltersModal(
          $scope.permissions.permissions,
          filterSteps,
          state.manageFilterSettings.itemsFilter,
          valuesType,
          title
        )
        .result.then(handlers.filterItems);
    };

    $scope.copyPermissions = function() {
      usersFactory.copyPermissions($scope.currentUserId);
    };

    $scope.pastePermissions = function() {
      helpers
        .confirmationModal(
          'admin.userManagement.modals.pasteConfirmation.title',
          'admin.userManagement.modals.pasteConfirmation.texts.confirmationText',
          true
        )
        .result.then(function() {
          usersFactory.pastePermissions($scope.currentUserId).then(function() {
            getUserPermissions($scope.currentTab);
          });
        });
    };

    $scope.isManageAvailable = function(tab) {
      return !!~['partnerCampaign', 'advertiserCampaign', 'site'].indexOf(tab);
    };

    $scope.isAnalyticsSettingsPanelAvailable = function(tab) {
      return !!~['partnerCampaign', 'advertiserCampaign', 'site'].indexOf(tab);
    };

    $scope.isNewAnalyticsSettingsPanelAvailable = function(tab) {
      return !!~['partner', 'advertiser'].indexOf(tab);
    };

    $scope.getCopiedPermissions = usersFactory.getCopiedPermissions;

    $scope.filterPermissions = function(searchFilter = '') {
      try {
        const normalizedSearchFilter = searchFilter.toLocaleLowerCase();
        $scope.pagination.filteredPermissions = $scope.permissions.permissions
          .filter(function(permission) {
            return valuesType === 'campaigns' || valuesType === 'sites'
              ? permission.checked
              : true;
          })
          .filter(function(permission) {
            return searchFilter
              ? ~permission.title.toLowerCase().indexOf(normalizedSearchFilter)
              : true;
          });
      } catch (ex) {
        // swallow an error
      }
    };

    $scope.getPermissionsPage = function() {
      $scope.selected.item = undefined;
      const filteredPermissions = $scope.pagination.filteredPermissions || [];
      const currentPage = $scope.pagination.currentPage || 1;
      const startIndex = (currentPage - 1) * $scope.pagination.itemsPerPage;
      $scope.pagination.permissionsPage = filteredPermissions.slice(
        startIndex,
        startIndex + $scope.pagination.itemsPerPage
      );
    };

    $scope.selectMethods = {
      getSelectedItems(type, array) {
        return array.filter(function(item) {
          return item[type];
        });
      },
      changePermissionsForSet(type, permissionsSet, newState) {
        angular.forEach(permissionsSet, function(item) {
          $scope.changePermission(type, item, newState);
        });
        updateSelectInfo();
        $scope.permissionsForm.$setDirty();
      },
      isUncheckPossible(type, permissionsSet) {
        if (type === 'delete') return true;
        return type === 'modify'
          ? !this.getSelectedItems('delete', permissionsSet).length
          : !this.getSelectedItems('modify', permissionsSet).length;
      },

      uncheckOtherPages(type, filteredSet, pagination) {
        const affectedSet = filteredSet
          .slice(0, (pagination.currentPage - 1) * pagination.itemsPerPage)
          .concat(
            filteredSet.slice(pagination.currentPage * pagination.itemsPerPage)
          );
        if (!this.isUncheckPossible(type, affectedSet)) return false;

        return this.changePermissionsForSet(type, affectedSet, false);
      },

      handleSelectAllCheckbox(type, selectInfo, currentPageSet, filteredSet) {
        // if all set is selected -> uncheck all set
        if (
          selectInfo[type].isAllSetSelected &&
          this.isUncheckPossible(type, filteredSet)
        ) {
          this.changePermissionsForSet(type, filteredSet, false);
        } else if (
          selectInfo[type].isAllPageSelected &&
          this.isUncheckPossible(type, currentPageSet)
        ) {
          // if all page is selected -> uncheck current page
          this.changePermissionsForSet(type, currentPageSet, false);
        } else if (!selectInfo[type].isAllPageSelected) {
          // if neither all set nor all page is selected -> select currentPage
          this.changePermissionsForSet(type, currentPageSet, true);
        }
      },

      getSelectInfo(currentPageSet, filteredSet) {
        return ['view', 'modify', 'delete'].reduce(
          function(acc, type) {
            const selectedItems = this.getSelectedItems(type, filteredSet);
            const selectedItemsOnPage = this.getSelectedItems(
              type,
              currentPageSet
            );
            const res = {};
            res[type] = {
              selectedItems,
              selectedItemsOnPage,
              isAllPageSelected:
                selectedItemsOnPage.length === currentPageSet.length,
              isAllSetSelected: selectedItems.length === filteredSet.length
            };
            return angular.extend({}, acc, res);
          }.bind(this),
          {}
        );
      }
    };

    angular.forEach($scope.selectMethods, function(method, methodName) {
      $scope.selectMethods[methodName] = method.bind($scope.selectMethods);
    });

    function updateSelectInfo() {
      $scope.selectInfo = $scope.selectMethods.getSelectInfo(
        $scope.pagination.permissionsPage,
        $scope.pagination.filteredPermissions
      );
    }

    $scope.$watch('pagination.permissionsPage', updateSelectInfo);

    $scope.init = function() {
      usersFactory.getUser($scope.currentUserId).then(user => {
        $scope.state.user = user;
        $scope.setBreadcrumbs(false, false, user.userId);
        $scope.setTabs(user, false);
      });
      $scope.activateTab('partnerCampaign');
    };

    $scope.actionHandlers.cancel = function() {
      if ($scope.uiState.editMode) {
        cancelConfirmModal().then(function() {
          $scope.uiState.editMode = false;
          $scope.permissionsForm.$setPristine(true);
          $scope.activateTab($scope.currentTab);
        });
      } else {
        $state.go('section.admin.users.userList');
      }
    };

    $scope.actionHandlers.save = function() {
      return $scope.submit();
    };

    function cancelConfirmModal() {
      return helpers.confirmationModal(
        '',
        'admin.userManagement.modals.cancelConfirmation.texts.edit',
        true
      ).result;
    }

    $scope.$on('$stateChangeStart', function(event, toState) {
      if ($scope.uiState.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.uiState.editMode = false;
          $state.go(toState);
        });
      }
    });

    $scope.submit = function() {
      if ($scope.permissionsForm.$invalid) {
        return true;
      }
      $scope.setBusy(true);
      if ($scope.currentTab === 'systemAnalytics') {
        return usersFactory
          .saveUserSystemWideAnalyticsPermissions(
            $scope.currentUserId,
            $scope.permissions
          )
          .then(function() {
            $scope.uiState.editMode = false;
            $scope.permissionsForm.$setPristine(true);
            $scope.activateTab($scope.currentTab);
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      } else if ($scope.currentTab === 'reports') {
        return usersFactory
          .saveUserReportsPermissions($scope.currentUserId, $scope.permissions)
          .then(function() {
            $scope.uiState.editMode = false;
            $scope.permissionsForm.$setPristine(true);
            $scope.activateTab($scope.currentTab);
          })
          .finally(function() {
            $scope.setBusy(false);
          });
      }
      return usersFactory
        .saveUserPermissions(
          $scope.currentUserId,
          $scope.currentTab,
          $scope.permissions
        )
        .success(function() {
          $scope.uiState.editMode = false;
          $scope.permissionsForm.$setPristine(true);
          $scope.activateTab($scope.currentTab);
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.selectItem = function(item) {
      $scope.selected.item = item;
    };

    $scope.changeAllNewPermission = function(type) {
      const selectedItem = $scope.selected.item;
      const currentTypeState = selectedItem[type];
      if (type === 'newDelete' && currentTypeState) {
        // eslint-disable-next-line no-multi-assign
        selectedItem.newView = selectedItem.newModify = currentTypeState;
      }
      if (type === 'newModify' && currentTypeState) {
        selectedItem.newView = currentTypeState;
      }
    };

    $scope.changePermission = function(type, item, newState) {
      const isBatchChange = angular.isDefined(newState);
      if (isBatchChange) {
        // eslint-disable-next-line no-param-reassign
        item[type] = newState;
      }

      if (type === 'delete' && item.delete) {
        // eslint-disable-next-line no-param-reassign,no-multi-assign
        item.view = item.modify = item.delete;
      }

      if (type === 'modify' && item.modify) {
        // eslint-disable-next-line no-param-reassign
        item.view = item.modify;
      }

      // newState is used only for batch changing, so don't proceed if it's defined
      if (
        item.view === false &&
        $scope.isAnalyticsSettingsPanelAvailable($scope.currentTab)
      ) {
        angular.forEach(options.analyticsParams, function(permission) {
          // eslint-disable-next-line no-param-reassign
          item[permission] = false;
        });
      }

      // disable only new items
      if (
        !isBatchChange &&
        item.view === false &&
        $scope.isNewAnalyticsSettingsPanelAvailable($scope.currentTab)
      ) {
        angular.forEach(options.newAnalyticsParams, function(
          permission
        ) {
          // eslint-disable-next-line no-param-reassign
          item[permission] = false;
        });
      }

      // enable view/export analytics and viewers list after view is checked
      if (
        item.view &&
        $scope.isAnalyticsSettingsPanelAvailable($scope.currentTab)
      ) {
        angular.forEach(options.analyticsParams, function(permission) {
          // eslint-disable-next-line no-param-reassign
          item[permission] = true;
        });
      }

      if (!isBatchChange) {
        updateSelectInfo();
      }
    };

    $scope.changeAnalyticsPermissionMulti = function(types, items, values) {
      items.forEach(function(permission, i) {
        $scope.changeAnalyticsPermission(
          types[i],
          permission,
          values ? values[i] : null
        );
      });
    };

    $scope.changeAnalyticsPermission = function(type, item, value) {
      if (value) {
        // eslint-disable-next-line no-param-reassign
        item[type] = value;
        return false;
      }

      const newVal = item[type];
      if (!newVal) return true;

      const map = {
        exportAnalytics: 'viewAnalytics',
        createReport: 'viewReport',
        exportAnalyticsVisitorList: 'viewAnalyticsVisitorList',
        createReportVisitorList: 'viewReportVisitorList',
        create: 'view',
        export: 'view',
        view: 'view',
        newExportAnalytics: 'newViewAnalytics',
        newExportAnalyticsVisitorList: 'newViewAnalyticsVisitorList'
      };

      // eslint-disable-next-line no-param-reassign
      item[map[type]] = true;
      return true;
    };

    $scope.getPartialPath = function(sectionName) {
      const base = 'app/admin/users/';
      switch (sectionName) {
        case 'systemAnalytics':
          return `${base}systemAnalytics.partial.html`;
        case 'reports':
          return `${base}reports.partial.html`;
        default:
          return `${base}permissionList.partial.html`;
      }
    };

    $scope.$watch('uiState.editMode', function(val) {
      if ($scope.state.user && $scope.state.user.userId) {
        $scope.setTabs($scope.state.user, val);
      }
    });

    $scope.init();
  });
