/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.campaigns')
  .directive('livePrognosis', function(conversionFactory) {
    return {
      replace: true,
      restrict: 'E',
      scope: {
        data: '=?',
        editMode: '=?',
        campaignState: '@',
        campaignStates: '=?',
        isBusy: '=?',
        onRefresh: '&'
      },
      templateUrl: 'app/sections/campaigns/livePrognosis.html',
      link(scope, el) {
        const $scrollContainer = el.find('.live-prognosis_scroll-cnt');
        const $livePrognosisW = el.find('.live-prognosis_w');
        const $scrollableContainer = $scrollContainer.children().first();
        const footerOffset = 15;

        scope.currentSites = [];

        scope.$watch('campaignState', function(val) {
          scope.campaignStateText = conversionFactory.capitalize(val);
        });

        window.$(window).on('resize.livePrognosis', sizeHandler);
        sizeHandler();

        el.on('$destroy', function() {
          window.$(window).off('.livePrognosis');
        });

        scope.state = {
          isCollapsed: true,
          showFooter: false
        };

        scope.$watch('state.isCollapsed', function(val) {
          if (!val) {
            $scrollContainer.perfectScrollbar();
          } else {
            $scrollContainer.perfectScrollbar('destroy').scrollTop(0);
          }
        });

        scope.$watch(
          function() {
            return (
              $scrollableContainer.height() >
              $livePrognosisW.height() - footerOffset
            );
          },
          function(val) {
            scope.state.showFooter = val;
          }
        );

        function sizeHandler() {
          const width = el.parent().width();
          const height = el.closest('.admin-section_scroll').height();
          el.width(width);
          el.height(height);
        }
      }
    };
  });
