/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const { moment } = window;

angular
  .module('wcm.analytics2')
  .factory('analyticsFactory2', function(
    $rootScope,
    $modal,
    $filter,
    helpers,
    ANALYTICS_CONFIG2
  ) {
    // CONSTANTS
    const MILISECONDS_IN_DAY = 86400000;
    const SHOW_MORE_DELTA = 10;
    const DEFAULT_CHART_STEP = 4;
    const DEFAULT_CHART_MAX = 38;
    const DEFAULT_CTR_STEP = 0.04;
    const DEFAULT_CTR_MAX = 0.38;
    const FAKE_DATA_IDS = ['Unknown', 'Other'];
    const DEFAULT_TIME_FILTERS = {
      startPeriod: moment()
        .startOf('day')
        .subtract(30, 'day'),
      endPeriod: moment().startOf('day'),
      startTimeFrame: 0,
      endTimeFrame: 24 * 60 * 60 * 1000,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
      allTime: false,
      selectMax: false
    };

    // Get timeframe values for period/days/hours filters
    const getTimeFrameValues = function getTimeFrameValues() {
      const step = 2 * 60 * 60 * 1000;
      const endTime = 24 * 60 * 60 * 1000;
      const timeframes = [];
      for (let time = 0; time <= endTime; time += step) {
        timeframes.push(time);
      }
      return timeframes;
    };
    const getDaysOfWeekValues = function getDaysOfWeekValues() {
      return angular.copy(ANALYTICS_CONFIG2.daysOfWeek);
    };

    // Some options of charts
    const chartsOptions = {
      dataByCampaigns: {
        visibleEntries: 15
      },
      dataByNumberOfVisits: {
        visibleEntries: 15
      },
      dataByOtherVisitedSites: {
        visibleEntries: 10
      },
      dataByNumberOfViews: {
        visibleEntries: 10
      },
      dataBySites: {
        visibleEntries: 10,
        sortOrder: 'desc'
      },
      dataBySiteTags: {
        visibleEntries: 10
      },
      dataByCitiesAndCountries: {
        visibleEntries: 10
      },
      dataByCitiesAndCountriesSites: {
        visibleEntries: 10
      },
      byInterestsSites: {
        visibleEntries: 20
      },
      byOccupationsSites: {
        visibleEntries: 20
      },
      byInterestsCampaigns: {
        visibleEntries: 20
      },
      byOccupationsCampaigns: {
        visibleEntries: 20
      },
      monitoring_visitorInterests: {
        visibleEntries: 15
      }
    };
    const chartsOptionsDefault = angular.copy(chartsOptions);

    // Color pattern
    const colorPatterns = [
      [
        '#C1BED1',
        '#938CAB',
        '#7A7299',
        '#5D557F',
        '#473F65',
        '#342D4F',
        '#342D4F',
        '#342D4F',
        '#342D4F'
      ],
      [
        '#B08AFF',
        '#9DEFFF',
        '#9BFFD5',
        '#FDF77C',
        '#F89859',
        '#FA6682',
        '#FE848F',
        '#16F912',
        '#AC39FF',
        '#5CAB09',
        '#A39602',
        '#DB89EB',
        '#0D4ED1',
        '#BFBA4E',
        '#F6E2FA'
      ],
      ['#F39AB6', '#8BADE1']
    ];

    // Addtitional part of name for some blocks
    const additionalNames = {
      'analyticsCampaign.generalInfoCampaigns': 'Specific'
    };

    // DRAFT STUFF!
    //
    // var generateGradientPattern = function(count, color1, color2) {
    // C1BED1 12697297
    // C0BDD0 12631504
    // BFBCD0 12565712
    // BFBCCF 12565711

    // C1BED1 - 193 - 190 - 209
    // C0BDD0 - 192 - 189 - 208
    // BFBCD0 - 191 - 188 - 208
    // BFBCCF - 191 - 188 - 207

    // yourNumber = parseInt(hexString, 16);
    // var hexString1 = color1.substr(1).toString(16);
    // var ar = [];
    // var intColor1 = parseInt(color1.substr(1), 16);
    // color1(substr(2))
    // ar.push(color1);
    // for(var i = 0; i < count; i++) {
    //   ar.push('#'+(parseInt(ar[i].substr(1), 16) - 30000).toString(16));
    // }
    // ar.push(color2);
    // console.log(ar);
    // return ar;
    // };

    // All blocks for export
    const exportPresets = {
      general: [
        'generalInfoSystem',
        'campaignsTimeline',
        'dataByTraffic',
        'amountOfCampaigns',
        'amountOfWidgets',
        'amountOfSitesAndEntities',
        'amountOfViewsAndVisitors',
        'quantityOfRegisteredUsers',
        'dataByTags',
        'vacantSites',
        'allSystemSites'
      ],
      campaigns: [
        'generalInfoCampaigns',
        'dataByComparisonCampaigns',
        'dataByDays',
        'dataBySites',
        'dataByCitiesAndCountries',
        'dataByDevicesCampaigns',
        'dataByDemographicInfoCampaigns',
        'dataByHoursCampaigns',
        'dataByWeekCampaigns',
        'campaigns'
      ],
      analyticsCampaign: [
        'generalInfoCampaigns',
        'dataByDaysAndSites',
        'dataByNumberOfViews',
        'dataBySites',
        'dataBySiteTags',
        'dataByCitiesAndCountries',
        'dataByViewersPortrait',
        'dataByDevicesCampaigns',
        'dataByDemographicInfoCampaigns',
        'dataByHoursCampaigns',
        'dataByWeekCampaigns',
        'dataByCurrentWidgets',
        'sites'
      ],
      sites: [
        'generalInfoSitesAll',
        'dataByComparisonSites',
        'dataByTrafficUsage',
        'dataByNumberOfVisits',
        'dataByVisitorsAgeAndGender',
        'dataByNewAndReturningVisitors',
        'dataByCampaigns',
        'dataByCitiesAndCountriesSites',
        'dataByDevicesSites',
        'dataByDemographicInfoSites',
        'dataByHoursSites',
        'dataByWeekSites',
        'dataByMonthsSites',
        'dataByYearsSites',
        'sites'
      ],
      analyticsSite: [
        'generalInfoSitesSpecAll',
        'generalInfoSitesFiltered',
        'dataByVisitorsWithWeather',
        'dataByTrafficUsage',
        'dataByNumberOfVisits',
        'dataByOtherVisitedSites',
        'dataByVisitorsAgeAndGender',
        'dataByNewAndReturningVisitors',
        'dataByNotReturningVisitors',
        'dataByCampaignsViews',
        'dataByCampaigns',
        'dataByCitiesAndCountriesSites',
        'dataByDevicesSites',
        'dataByDemographicInfoSites',
        'dataByHoursSites',
        'dataByWeekSites',
        'dataByMonthsSites',
        'dataByYearsSites'
      ],
      visitors: [
        'name',
        'lastVisit',
        'email',
        'birthDate',
        'gender',
        'phoneNumber',
        'visitsQuantity',
        'device',
        'OS',
        'socialNetworks'
      ],
      monitoring: [
        'name',
        'lastVisit',
        'macAddress',
        'email',
        'birthDate',
        'gender',
        'phoneNumber',
        'visitsQuantity',
        'device',
        'OS',
        'socialNetworks'
      ],
      viewers: [
        'name',
        'lastVisit',
        'macAddress',
        'email',
        'birthDate',
        'gender',
        'phoneNumber',
        'interactionsQuantity',
        'interactionSites',
        'device',
        'OS',
        'socialNetworks'
      ]
    };

    /* Config of chart
         bindto - #id of div
         data.type - type of graphs
         data.types - type of each graph
         legend.show - show legend of graph
         pie.label.show - set default 'show labels value' for pie chart
         color.pattern - set default color pattern
        */
    const chartConfig = {
      bindto: '[someChartId]',
      size: {
        height: 300
      },
      data: {
        columns: [],
        type: 'line'
      },
      pie: {
        label: {
          show: false
        }
      },
      color: {
        pattern: colorPatterns[0]
      },
      legend: {
        show: false
      }
    };

    // Workers
    const dataWorkers = {
      // Get New part of requested Data after click on showMore btn
      showMoreHandler(item, blockChartName, collapseValue) {
        chartsOptions[blockChartName].visibleEntries =
          collapseValue ||
          chartsOptions[blockChartName].visibleEntries + SHOW_MORE_DELTA;
        if (
          item.data.columns.length >
          chartsOptions[blockChartName].visibleEntries
        ) {
          // eslint-disable-next-line no-param-reassign
          item.data.columns = item.data.columns.splice(
            0,
            chartsOptions[blockChartName].visibleEntries
          );
        }
        return item;
      },

      // Cut requested data by showMore value
      cutDataHandler(item, blockChartName) {
        if (
          item.options.partData &&
          chartsOptions[blockChartName] &&
          item.data.columns.length >
            chartsOptions[blockChartName].visibleEntries
        ) {
          // eslint-disable-next-line no-param-reassign
          item.data.columns = item.data.columns.splice(
            0,
            chartsOptions[blockChartName].visibleEntries
          );
        }
        return item;
      },

      // Remove from result data items with empty (0) values
      slicePieZeroData(requestData) {
        const columns = requestData.data.columns.filter(function(item) {
          return item[1] > 0;
        });
        return {
          data: {
            columns,
            maxValue: requestData.data.maxValue,
            type: requestData.data.type
          },
          options: requestData.options
        };
      },

      // Remove from result data items with empty (0) values in dataBy
      slicePieByZeroViewsData(requestData, dataSliceBy) {
        const sliceData = { names: [], ids: [] };
        dataSliceBy.data.columns
          .filter(function(item) {
            return item[1] > 0;
          })
          .forEach(function(item) {
            sliceData.names.push(item[0].split(/^\d+\.\s/g)[1]);
            sliceData.ids.push(item[2]);
          });

        const columns = requestData.data.columns.filter(function(item) {
          return (
            ~sliceData.names.indexOf(item[0].split(/^\d+\.\s/g)[1]) &&
            ~sliceData.ids.indexOf(item[2])
          );
        });

        return {
          data: {
            columns,
            maxValue: requestData.data.maxValue,
            type: requestData.data.type
          },
          options: requestData.options
        };
      },

      // ---> filterParseString is DEPRECATED! <---
      filterParseString(pie, blockName) {
        const filter = $filter('parseDBStrings');
        pie.data.columns.forEach(function(item, i) {
          // eslint-disable-next-line no-param-reassign
          pie.data.columns[i][0] = filter(
            item[0],
            ~['byIncomeLevel'].indexOf(blockName) ? '$' : ''
          );
        });
        return pie;
      },

      //
      filterLocalize(obj) {
        const filter = $filter('i18n');
        obj.data.columns.forEach(function(item, i) {
          // eslint-disable-next-line no-param-reassign
          obj.data.columns[i][0] = filter(`DAConstants.${item[0]}`);
        });
        return obj;
      },

      //
      firstNumberComparator(a, b) {
        const numA = +a[0].toLowerCase().split(/\./g)[0];
        const numB = +b[0].toLowerCase().split(/\./g)[0];
        // eslint-disable-next-line no-nested-ternary
        return numA < numB ? 1 : numA > numB ? -1 : 0;
      },

      //
      quantityComparator(a, b) {
        // eslint-disable-next-line no-nested-ternary
        return a[1] > b[1]
          ? 1
          : a[1] < b[1]
          ? -1
          : dataWorkers.firstNumberComparator(a, b);
      },

      siteNameComparator(a, b) {
        const nameA = a[0].toLowerCase().split(/^\d+\.\s/g)[1];
        const nameB = b[0].toLowerCase().split(/^\d+\.\s/g)[1];
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        return dataWorkers.firstNumberComparator(a, b);
      }
    };

    // Set options for Comparison and Multi lines
    const setMultiLinesChartOptions = function(
      data,
      isPercents,
      colorIndex,
      lineType,
      hasTraslation
    ) {
      const options = {};
      const i18n = $filter('i18n');
      options.colorPattern = colorPatterns[colorIndex] || colorPatterns[2];
      options.globalType = lineType || 'line';
      options.xAxis = {
        type: 'category',
        categories: setLineBarChartTicksFormat(data.options.ticksX)
      };
      options.tooltip = isPercents
        ? {
            contents(d, defaultTitleFormat, defaultValueFormat, color) {
              // Use default rendering with different title
              if (hasTraslation) {
                // eslint-disable-next-line no-param-reassign
                d = d.map(function(item) {
                  // eslint-disable-next-line no-param-reassign
                  item.name = i18n(`analytics.filters.${item.name}`);
                  return item;
                });
              }
              return this.getTooltipContent(
                d,
                defaultTitleFormat,
                defaultValueFormat,
                color
              );
            },
            format: {
              value(value) {
                return value > 0
                  ? window.d3.format('.2%')(value)
                  : window.d3.format('.0f')(value);
              }
            }
          }
        : null;
      options.yAxis = {
        tick: {
          format: isPercents
            ? window.d3.format('.2%')
            : window.d3.format('.0f'),
          count: 5
        }
      };
      return options;
    };

    // Get Array of keys of object
    const getKeys = function(data) {
      return data ? Object.keys(data) : [];
    };

    // Format ticks for chart to show them with year
    const setTicksLastSixMonths = function(ticks) {
      const year = moment(new Date()).year();
      return ticks.map(function(item) {
        return [
          ANALYTICS_CONFIG2.monthesOfYear.filter(function(m) {
            return m.id === item;
          })[0].title,
          item > ticks[ticks.length - 1] ? year - 1 : year
        ];
      });
    };

    // Format ticks for lineBarChart Category
    function setLineBarChartTicksFormat(ticks) {
      return ticks.map(function(item) {
        return (
          window.d3.time.format.utc('%d.%m.%Y')(new Date(item[0])) +
          (item[1]
            ? ` - ${window.d3.time.format.utc('%d.%m.%Y')(new Date(item[1]))}`
            : '')
        );
      });
    }

    // Function for get data for Pie Charts by clicking on showMore btn.
    // With and without filters
    const getShowMoreMultiPies = function(
      requestData,
      blockName,
      collapseValue
    ) {
      // Calaculate @parse to understand if it need name parse/localize
      // var parse = (!!~needLocalizeBlockTitles.indexOf(blockName)) ? 'filterLocalize' : null;
      const needCutIndex =
        requestData.data.length === 1
          ? 0
          : requestData.data
              .map(function(item) {
                return item.name;
              })
              .indexOf(blockName);
      let needCutItem = angular.copy(requestData.data[needCutIndex]);
      needCutItem = dataWorkers.showMoreHandler(
        needCutItem,
        blockName,
        collapseValue
      );
      // needCutItem = parse ? dataWorkers[parse](needCutItem) : needCutItem;
      return { index: needCutIndex, item: needCutItem };
    };
    const getShowMoreSinglePie = function(
      requestData,
      blockName,
      collapseValue
    ) {
      // Calaculate @parse to understand if it need name parse/localize
      // var parse = (!!~needLocalizeBlockTitles.indexOf(blockName)) ? 'filterLocalize' : null;
      let needCutItem = angular.copy(requestData);
      needCutItem = dataWorkers.showMoreHandler(
        needCutItem,
        blockName,
        collapseValue
      );
      // needCutItem = parse ? dataWorkers[parse](needCutItem) : needCutItem;
      return needCutItem;
    };

    // >>>>> Prepare Data for Pie chart <<<<<
    const setDefaultPieData = function(columns, isDefault) {
      // default columns
      if (isDefault) {
        return [['Other', 1]];
      }
      // default data for Other or Unknown

      return columns
        .map(function(item) {
          if (~FAKE_DATA_IDS.indexOf(item[0])) {
            // eslint-disable-next-line no-param-reassign
            item[1] = 1;
          }
          return item;
        })
        .sort(function(a, b) {
          return b[1] - a[1];
        });
    };

    const pieDataProcessing = function(data, isDefault) {
      let requestData = angular.copy(data);
      // If Object and obj.columns -> set new columns data
      if (typeof requestData === 'object' && requestData.columns) {
        requestData.columns = setDefaultPieData(requestData.columns, isDefault);
      } else if (
        typeof requestData === 'object' &&
        requestData.data &&
        requestData.options
      ) {
        // If Object and obj.data -> countinue data executing
        requestData.data =
          requestData.options.total > 0
            ? requestData.data
            : pieDataProcessing(requestData.data, isDefault);
      } else if (typeof requestData === 'object' && requestData.data) {
        requestData.data = pieDataProcessing(requestData.data, isDefault);
      } else if (typeof requestData === 'object' && !requestData.data) {
        // If Object and NO obj.data -> try to get columns or countinue data executing
        const keys = Object.keys(requestData);
        keys.forEach(function(key) {
          requestData[key] = pieDataProcessing(requestData[key], isDefault);
        });
      } else if (Array.isArray(requestData)) {
        // If Array -> try to get columns or countinue data executing
        requestData = requestData.map(function(obj) {
          return pieDataProcessing(obj, isDefault);
        });
      }
      return requestData;
    };
    // With and without filters
    const preparePieSingleItem = function(requestData, blockName, parse) {
      let data = angular.copy(requestData);
      data = dataWorkers.cutDataHandler(data, blockName);
      // data = preparePieDefaultData(data, blockName);
      // parse = parse || (!!~needLocalizeBlockTitles.indexOf(blockName)) ? 'filterLocalize' : null;
      return parse ? dataWorkers[parse](data, blockName) : data;
    };
    const preparePieMultiItems = function(requestData, blockName, parse) {
      const initData = angular.copy(requestData);
      initData.data = initData.data.map(function(item) {
        return preparePieSingleItem(item, blockName || item.name, parse);
      });
      return initData;
    };
    const preparePieData = function(requestData, blockName, parse) {
      return requestData.data.length
        ? preparePieMultiItems(requestData, blockName, parse)
        : preparePieSingleItem(requestData, blockName, parse);
    };
    const preparePieByToggles = function(
      requestData,
      blockName,
      toggles,
      dataToSliceBy
    ) {
      let data = angular.copy(requestData);
      const dataBy = angular.copy(dataToSliceBy);
      if (toggles.zeroData === false && !dataToSliceBy) {
        data = dataWorkers.slicePieZeroData(data, blockName);
      } else if (toggles.zeroData === false && dataToSliceBy) {
        data = dataWorkers.slicePieByZeroViewsData(data, dataBy, blockName);
      }
      return data;
    };
    const preparePieBySorting = function(data, blockName, sortBy) {
      switch (sortBy) {
        case 'quantity:asc':
          // eslint-disable-next-line no-param-reassign
          data.data.columns = data.data.columns.sort(
            dataWorkers.quantityComparator
          );
          break;
        case 'quantity:desc':
          // eslint-disable-next-line no-param-reassign
          data.data.columns = data.data.columns.sort(function(a, b) {
            return dataWorkers.quantityComparator(a, b) * -1;
          });
          break;
        case 'name:asc': // sort string ascending
          // eslint-disable-next-line no-param-reassign
          data.data.columns = data.data.columns.sort(
            dataWorkers.siteNameComparator
          );
          break;
        case 'name:desc': // sort string descending
          // eslint-disable-next-line no-param-reassign
          data.data.columns = data.data.columns.sort(function(a, b) {
            return dataWorkers.siteNameComparator(a, b) * -1;
          });
          break;
        default:
          // eslint-disable-next-line no-param-reassign
          data.data.columns = data.data.columns.sort(
            dataWorkers.quantityComparator
          );
      }
      return data;
    };
    const preparePieWithMaxValue = function(requested, siteNameNeed) {
      const maxValue = {};
      Object.keys(requested.data[0]).forEach(function(pieKey) {
        requested.data[0][pieKey].data.columns.forEach(function(item, key) {
          if (!maxValue[pieKey] || maxValue[pieKey] < item[1]) {
            maxValue[pieKey] = item[1];
          }
          if (siteNameNeed) {
            // eslint-disable-next-line no-param-reassign
            requested.data[0][pieKey].data.columns[key][0] = `${key + 1}. ${
              item[0]
            }`;
          } // set serial numbers
        });
        // eslint-disable-next-line no-param-reassign
        requested.data[0][pieKey].data.maxValue = maxValue[pieKey];
      });
      return requested;
    };

    const prepareMaxY = function(value, filter) {
      const step = filter === 'ctr' ? DEFAULT_CTR_STEP : DEFAULT_CHART_STEP;
      let delta = step;
      if (value === step) {
        delta = 2 * value;
      } else if (value > step && value % step === 0) {
        delta = value + step;
      } else if (value > step && value % step !== 0) {
        delta = step * Math.floor(value / step) + step;
      }
      return delta;
    };

    // Prepare Data for Line chart
    const prepareLineData = function(requestData, options, filter) {
      const d = filter
        ? angular.copy(requestData[filter])
        : angular.copy(requestData);
      const defaultMax = filter === 'ctr' ? DEFAULT_CTR_MAX : DEFAULT_CHART_MAX;
      const yAxis =
        d.options.maxY > defaultMax || (filter === 'ctr' && d.options.maxY > 0)
          ? { min: 0, padding: { top: 20, bottom: 0 } }
          : {
              min: 0,
              max: prepareMaxY(d.options.maxY, filter),
              padding: { top: 0, bottom: 0 }
            };
      d.data.type = options.globalType || 'area';
      d.data.labels = {
        format(v) {
          // eslint-disable-next-line no-nested-ternary
          const d =
            v === null
              ? ''
              : v % 1 !== 0
              ? window.d3.format('.2%')(v)
              : window.d3.format('.0f')(v);
          return d;
        },
        show: true
      };
      d.data.types = options.dataTypes || d.data.types || {};
      d.options.colorPattern = options.colorPattern;
      d.options.filter = filter;
      d.options.aggregated = options.aggregated;
      d.options.tooltip = options.tooltip;
      d.options.categories = options.categories || false;
      d.options.yAxis = angular.extend(options.yAxis || {}, yAxis);
      d.options.xAxis = options.xAxis;
      d.options.y2Axis = angular.extend(options.y2Axis || {}, {
        min: 0,
        padding: { top: 20, bottom: 0 }
      });
      d.options.rotated = options.rotated;
      d.options.grid = options.grid || false;
      d.options.legend = options.legend;
      return d;
    };

    // Prepare data for Portraits block with filters
    const preparePortraitsData = function(
      requestedData,
      topNumber,
      hideUnknown,
      unknownIndex
    ) {
      const result = hideUnknown
        ? requestedData.filter(function(item, i) {
            return i !== unknownIndex;
          })
        : requestedData;
      return result.slice(0, +topNumber);
    };

    // Prepare data for Map block with legend
    const prepareMapData = function(requestedData, legend) {
      const currentLegend = Object.keys(legend).filter(function(key) {
        return legend[key] === true;
      });
      return currentLegend.length < 3
        ? requestedData.filter(function(item) {
            return ~currentLegend.indexOf(item.trafficLight);
          })
        : requestedData;
    };

    // Change filter in block
    const setCurrentFilter = function(data) {
      if (!data.filters) return false;
      return data.filters
        .map(function(item) {
          return Object.keys(item).filter(function(key) {
            return item[key] === true;
          })[0];
        })
        .join('__');
    };

    // Set Regions for bar charts
    const setBarRegions = function(ticks) {
      const regions = [];
      ticks.forEach(function(item, i) {
        if (item) {
          const end = 0.3 + i;
          const start = i - 0.3;
          regions.push({
            axis: 'x',
            end,
            start,
            class: 'regionX'
          });
        }
      });
      return regions;
    };

    // timeline filtering
    const isCampaignHasType = function(campaign, type) {
      // eslint-disable-next-line no-param-reassign
      type = type.toLowerCase();
      return campaign.type.toLowerCase() === type;
    };

    const isCampaignInState = function(campaign, state) {
      // eslint-disable-next-line no-param-reassign
      state = state.toLowerCase();
      return campaign.state.toLowerCase() === state;
    };

    const isDefaultCampaign = function(campaign) {
      return campaign.isDefault;
    };

    const filterTimelineData = function(
      campaignsList,
      campaignTypes,
      campaignStates,
      showDefault
    ) {
      return campaignsList
        .filter(function(campaign) {
          return campaignStates.some(isCampaignInState.bind(null, campaign));
        })
        .filter(function(campaign) {
          if (showDefault && isDefaultCampaign(campaign)) return true;
          return (
            campaignTypes.some(isCampaignHasType.bind(null, campaign)) &&
            !isDefaultCampaign(campaign)
          );
        });
    };

    const listModal = function listModal(
      title,
      allItems,
      filters,
      filterTypes,
      collapseType
    ) {
      const scope = $rootScope.$new(true);
      scope.title = title;
      scope.allItems = allItems;
      scope.filters = filters;
      scope.collapseType = collapseType;

      if (filterTypes) scope.filterTypes = filterTypes;

      return $modal.open({
        scope,
        openedClass: 'isEdit',
        windowClass: 'modal-select manage-modal',
        templateUrl: '/app/core/analytics2/analyticsListModal.html',

        controller($scope, $modalInstance, conversionFactory) {
          if ($scope.filterTypes) {
            $scope.typeFilter = {
              type: $scope.filterTypes[0]
            };
          }

          const sortItems = function(arr) {
            const checkedItems = [];
            const uncheckedItems = [];
            const alphSort = function(a, b) {
              return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            };

            arr.forEach(function(i) {
              return i.checked ? checkedItems.push(i) : uncheckedItems.push(i);
            });

            const res = checkedItems
              .sort(alphSort)
              .concat(uncheckedItems.sort(alphSort));

            return res;
          };

          const getFilteredItems = function(items, searchQuery) {
            // eslint-disable-next-line no-param-reassign
            items = filterBySearchQuery(items, searchQuery);

            if ($scope.typeFilter) {
              // eslint-disable-next-line no-param-reassign
              items = items.filter(function(item) {
                return item.type === $scope.typeFilter.type;
              });
            }

            return sortItems(items);
          };
          const searchQueryFilterFn = function itemsFilterFn(
            searchString,
            item
          ) {
            if (!searchString) return true;
            return ~item.name.toLowerCase().indexOf(searchString.toLowerCase());
          };

          function filterBySearchQuery(items, searchQuery) {
            return items.filter(searchQueryFilterFn.bind(null, searchQuery));
          }

          const getCheckedItems = function getNames(items, trackBy) {
            const filter = {};
            items.forEach(function(item) {
              if (item.checked) {
                filter[item[trackBy]] = item;
              }
            });
            return filter;
          };

          const onFiltersChanged = function(filter) {
            if (!filters) return [];
            // eslint-disable-next-line no-param-reassign
            filter = filter.filter(function(item) {
              return item.checked;
            });
            let itemsForCheck = [];
            filter.forEach(function(item) {
              itemsForCheck = itemsForCheck.concat(item[$scope.collapseType]);
            });
            return window._.uniq(itemsForCheck);
          };

          // eslint-disable-next-line no-multi-assign
          const state = ($scope.state = {
            selectedPreset: null,
            currentPage: 1,
            itemsPerPage: 20,
            itemsOnPageValues: [20, 40, 60, 80],
            searchQuery: '',
            filteredItems: getFilteredItems($scope.allItems, '')
          });

          const isAllChecked = function(values) {
            if (!values.length) return false;
            return values.every(function(item) {
              return item.checked;
            });
          };

          $scope.selectInfo = {
            pageSelected: false,
            allSelected: false,
            selectedItems: getCheckedItems($scope.allItems)
          };

          // eslint-disable-next-line no-multi-assign
          const handlers = ($scope.handlers = {
            onSearchQueryChange(searchQuery) {
              state.filteredItems = getFilteredItems(
                $scope.allItems,
                searchQuery
              );
            },
            getPage(items, ipp, page) {
              state.itemsPage = helpers.getPage(items, ipp, page);
            },
            onFilteredItemsChange(items) {
              handlers.getPage(
                items,
                state.itemsPerPage,
                (state.currentPage = 1)
              );
              handlers.updateSelectInfo();
            },
            onValuesSelected(values) {
              if (!values) return;
              const itemsForCheck = onFiltersChanged(values);
              $scope.allItems.forEach(function(item) {
                // eslint-disable-next-line no-param-reassign
                if (itemsForCheck.indexOf(item.id) !== -1) item.checked = true;
              });
              state.filteredItems = sortItems($scope.allItems, '');
              handlers.updateSelectInfo();
            },
            selectCheckbox() {
              if ($scope.selectInfo.allSelected) {
                return handlers.changeItemsState(state.filteredItems, false);
              }
              return $scope.selectInfo.pageSelected
                ? handlers.changeItemsState(state.itemsPage, false)
                : handlers.changeItemsState(state.itemsPage, true);
            },
            selectPage() {
              handlers.changeItemsState(state.filteredItems, false);
              handlers.changeItemsState(state.itemsPage, true);
            },
            changeItemsState(values, bool) {
              values.forEach(function(item) {
                // eslint-disable-next-line no-param-reassign
                item.checked = bool;
              });
              handlers.updateSelectInfo();
            },
            updateSelectInfo() {
              $scope.selectInfo.allSelected = isAllChecked(state.filteredItems);
              $scope.selectInfo.pageSelected = $scope.selectInfo.allSelected
                ? true
                : isAllChecked(state.itemsPage);
              $scope.selectInfo.selectedItems = state.filteredItems.filter(
                function(item) {
                  return item.checked;
                }
              );
            },
            filterByType() {
              state.filteredItems = getFilteredItems(
                $scope.allItems,
                state.searchQuery
              );
            }
          });

          $scope.save = function() {
            $scope.submitted = true;
            $modalInstance.close();
          };

          $scope.cancel = function() {
            $scope.submitted = false;
            $modalInstance.dismiss();
          };

          $scope.capitalize = function(title) {
            return conversionFactory.capitalize(title);
          };
          $scope.$watch('state.filteredItems', handlers.onFilteredItemsChange);
        }
      });
    };

    const normalizeAnalyticsBlockName = function normalizeAnalyticsBlockName(
      blockName
    ) {
      return blockName.replace(/\s/g, '').toLowerCase();
    };

    const mapSectionNameToPermissionsBlock = function(sectionName) {
      const map = {
        analyticsSite: 'site'
      };
      return map[sectionName] ? map[sectionName] : sectionName;
    };

    const hasPermissions = function hasPermissions(
      userObj,
      analyticsType,
      blockName
    ) {
      if (!userObj.userPermissions || !userObj.userPermissions.analytics) {
        throw new Error('Corrupted user object provided');
      }
      const normalizedPermissions = userObj.userPermissions.analytics[
        mapSectionNameToPermissionsBlock(analyticsType)
      ].map(normalizeAnalyticsBlockName);
      return ~normalizedPermissions.indexOf(blockName.toLowerCase());
    };

    const hasPermissionsForType = function(userObj, analyticsType) {
      if (!userObj.userPermissions || !userObj.userPermissions.analytics) {
        throw new Error('Corrupted user object provided');
      }
      // >>>> DEPRECATED! <<<<
      return userObj.userPermissions.analytics[
        mapSectionNameToPermissionsBlock(analyticsType)
      ].view;
    };

    // Clean string from html tags and text inside them
    const cleanString = function(str) {
      return str.replace(/(<([^>]+)>).*(<([^>]+)>)/gi, '');
    };

    // Reset chart options to default values
    const resetChartsOptions = function() {
      // eslint-disable-next-line no-unused-vars
      for (const property in chartsOptions) {
        // eslint-disable-next-line no-prototype-builtins
        if (chartsOptions.hasOwnProperty(property)) {
          chartsOptions[property] = angular.copy(
            chartsOptionsDefault[property]
          );
        }
      }
    };

    // Is any permissions on analytics present
    const isAnyPermissions = function(userObj, type) {
      if (!userObj.userPermissions || !userObj.userPermissions.analytics) {
        throw new Error('Corrupted user object provided');
      }
      if (!type) {
        return ['campaigns', 'sites'].some(function(key) {
          return userObj.userPermissions.analytics[key].view;
        });
      }
      return userObj.userPermissions.analytics[type].view;
    };

    // Check smallest date according to min period date, current date and limit
    const getAnalyticsMinPeriod = function(
      minDate,
      fromDate,
      limit,
      selectMax = false
    ) {
      const dateToCheck = moment(fromDate)
        .startOf('day')
        .valueOf();
      const limitDate =
        limit < 30
          ? moment(dateToCheck)
              .subtract(limit, 'days')
              .startOf('day')
          : moment(dateToCheck)
              .subtract(selectMax ? limit : 30, 'days')
              .startOf('day');
      return limitDate < minDate ? minDate : limitDate;
    };

    // Validation of time filters
    const isValidTimeFilters = function(timeFilters, limit, availableMinDate) {
      const minDate = moment(availableMinDate).startOf('day');
      return (
        timeFilters.startPeriod <= timeFilters.endPeriod &&
        (!timeFilters.startTimeFrame ||
          !timeFilters.endTimeFrame ||
          timeFilters.startTimeFrame < timeFilters.endTimeFrame) &&
        (!limit ||
          Math.floor(
            (timeFilters.endPeriod - timeFilters.startPeriod) /
              MILISECONDS_IN_DAY
          ) <= limit) &&
        timeFilters.startPeriod >= minDate &&
        timeFilters.endPeriod >= minDate
      );
    };

    /**
     *
     *
     * @param {object} val - new filters object
     * @param {object} old - old filters object
     * @param {boolean} isAllTimeAffected - flag that shows should we count that filters were
     *   changed after allTime checkbox was toggled
     * @returns {boolean} - return 'true' if time filters changed
     */
    const isTimeFiltersChanged = function(val, old, isAllTimeAffected) {
      return (
        (val &&
          old &&
          (moment(val.startPeriod).valueOf() !==
            moment(old.startPeriod).valueOf() ||
            moment(val.endPeriod).valueOf() !==
              moment(old.endPeriod).valueOf() ||
            val.startTimeFrame !== old.startTimeFrame ||
            val.endTimeFrame !== old.endTimeFrame ||
            (val.daysOfWeek &&
              old.daysOfWeek &&
              !window._.isEqual(
                val.daysOfWeek.sort(),
                old.daysOfWeek.sort()
              )) ||
            val.interactionType !== old.interactionType)) ||
        (isAllTimeAffected && val.allTime !== old.allTime)
      );
    };

    function authorizeRoute(userObj, route) {
      // allow any section for admins and ghosts
      if (~['administrator', 'ghost'].indexOf(userObj.userRole.toLowerCase()))
        return true;
      const routePath = route.split('.');
      if (routePath[0] !== 'analytics') return false;
      return (
        userObj &&
        userObj.userPermissions &&
        userObj.userPermissions.analytics &&
        userObj.userPermissions.analytics[routePath[1]].view
      );
    }

    function getFallbackRoute(userObj) {
      return ['analytics.campaigns', 'analytics.sites'].filter(function(
        section
      ) {
        return authorizeRoute(userObj, section);
      })[0];
    }

    return {
      getTimeFrameValues,
      getDaysOfWeekValues,
      defaultChartConfig: chartConfig,
      defaultColorPatterns: colorPatterns,
      getShowMoreMultiPies,
      getShowMoreSinglePie,
      chartsOptions,
      chartsOptionsDefault,
      pieDataProcessing,
      preparePieWithMaxValue,
      preparePieData,
      preparePieByToggles,
      preparePieBySorting,
      prepareLineData,
      preparePortraitsData,
      prepareMapData,
      setCurrentFilter,
      setBarRegions,
      setTicksLastSixMonths,
      getKeys,
      filterTimelineData,
      setLineBarChartTicksFormat,
      listModal,
      hasPermissions,
      hasPermissionsForType,
      normalizeAnalyticsBlockName,
      setMultiLinesChartOptions,
      exportPresets,
      cleanString,
      resetChartsOptions,
      isAnyPermissions,
      additionalNames,
      defaultTimeFilters: DEFAULT_TIME_FILTERS,
      getAnalyticsMinPeriod,
      isValidTimeFilters,
      isTimeFiltersChanged,
      authorizeRoute,
      getFallbackRoute
    };
  });
