const { angular } = window;

angular.module('wcm.core').component('manageBlockViewOnly', {
  templateUrl: 'app/core/components/manageBlock/manageBlock.html',
  controller: 'manageBlockViewOnlyController',
  bindings: {
    title: '@',
    valuesType: '<',
    titleLineColor: '<',
    availableValues: '<',
    limitItems: '<',
    isExport: '<'
  }
});
