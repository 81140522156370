/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.widgets')
  .directive('staticWidgetEditorHelper', function($window, $timeout) {
    return {
      link(scope, el) {
        scope.fontCoef = 0.69;
        scope.fontCoefTerms = 0.4;

        // const $bannerCnt = el.find('.widget-editor_banner-cnt');
        const $editorCnt = el.find('.widget-editor_content-w');
        // const $bottomElsCnt = el.find('.widget-editor_bottom-elements-cnt');
        // const $btnsCnt = el.find('.widget-editor_btns-cnt');
        // const $btnsTermsCnt = el.find('.widget-editor_terms');
        const $contentWrapper = el.find('.widget-editor_banner-cnt-adjust');

        // eslint-disable-next-line no-multi-assign
        const resetEditorLayout = (scope.resetEditorLayout = function() {
          const editorCntHeight = $editorCnt.height();
          const contentWrapperHeight = editorCntHeight * 0.81;
          const contentWrapperWidth = contentWrapperHeight * 0.6;
          const bannerCntHeight = contentWrapperHeight * 0.84;
          // var bottomElsCntHeight = contentWrapperHeight - bannerCntHeight;
          $contentWrapper.height(contentWrapperHeight);
          $contentWrapper.width(contentWrapperWidth);
          // $bannerCnt.outerHeight(bannerCntHeight);
          // $bottomElsCnt.outerHeight(bottomElsCntHeight);

          $timeout(function() {
            // $btnsCnt.find(".btn").css({lineHeight: $btnsCnt.height() - 4 + 'px'});
            // $btnsTermsCnt.find(".btn").css({lineHeight: $btnsTermsCnt.height() - 2 + 'px'});
            scope.$evalAsync(function() {
              scope.fontCoef =
                (bannerCntHeight / 1520) * 3 < 1
                  ? (bannerCntHeight / 1520) * 3
                  : 1;
            });
            scope.$evalAsync(function() {
              scope.fontCoefTerms =
                ((bannerCntHeight / 1520) * 3 < 0.8
                  ? (bannerCntHeight / 1520) * 3
                  : 0.8) - 0.1;
            });
          }, 10);
        });

        window
          .$($window)
          .on('resize.staticWidgetEditor', function() {
            resetEditorLayout();
          })
          .resize();

        scope.$watch(() => $editorCnt.height(), resetEditorLayout);

        scope.$on('$destroy', function() {
          window.$($window).off('.staticWidgetEditor');
        });
      }
    };
  });
