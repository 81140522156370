const { moment, angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingCreateSiteSpecificCtrl', function(
    $scope,
    $state,
    $q,
    $controller,
    reportingFactory,
    analyticsRequestsFactory,
    sitesFactory,
    analyticsFactory2,
    navigationPanel,
    ANALYTICS_CONFIG2
  ) {
    $controller('reportingCreateCtrl', { $scope });

    const A_CONFIG = ANALYTICS_CONFIG2;

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      blocks: A_CONFIG.blocks.analyticsSite,
      sites: []
    }));
    options.blocks = options.blocks.slice(1); // remove generalInfoSitesSpecAll block

    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      type: 'analyticsSite',
      currentSiteId: 0
    }));
    state.filters.siteIds = [+state.currentSiteId];

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      save() {
        const manageBlocks = analyticsRequestsFactory.getBlocksData();
        state.isBusy = true;
        reportingFactory
          .createSiteSpecificReport(state.report, state.filters, manageBlocks)
          .then(function() {
            state.editMode = false;
            $state.go(state.callbackRoute);
          })
          .finally(function() {
            state.isBusy = false;
          });
      },
      getSiteData() {
        return sitesFactory.getData(state.currentSiteId);
      },
      selectCurrentSite() {
        state.filters.siteIds = [+state.currentSiteId]; // Set current site id
        handlers.getSiteData().then(function(site) {
          const start = site
            ? moment(site.creationDate).startOf('day')
            : state.filters.endPeriod;
          handlers.setBeginPeriodDates(start);
        });
      }
    }));

    $scope.init = function() {
      handlers.setTabs();
      state.report.blockList = angular.copy(options.blocks);
      analyticsRequestsFactory
        .getAllSites('sites', state.permissionType)
        .then(
          function(res) {
            options.sites = res.sites;
            state.isBusy = false;
          },
          function() {
            state.noWas = true;
          }
        )
        .finally(function() {
          state.isBusy = false;
        });
    };
    navigationPanel.setActionButtons([
      {
        title: () => 'reporting.buttons.generate',
        isDisabled: () => $scope.state.noWas || $scope.state.isBusy,
        isShown: () => true,
        clickHandler: () => {
          $scope.submitted = true;
          if ($scope.reportForm.$valid) {
            $scope.handlers.save();
          }
        }
      },
      {
        title: () => 'reporting.buttons.cancel',
        isDisabled: () => $scope.state.isBusy,
        type: () => 'reject',
        isShown: () => true,
        clickHandler: () => {
          $scope.handlers.cancel();
        }
      }
    ]);
  });
