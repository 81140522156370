/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm').directive('wcmFilter', function() {
  const controller = function($scope, $state) {
    $scope.sectionName = $state.current.name.match(/\.(\w+)/i)[1];

    const findIdByValue = function(value) {
      let idx = 0;
      angular.forEach($scope.filterItem.options, function(
        option,
        index
      ) {
        if (option.value === value) idx = index;
      });
      return idx;
    };

    $scope.activeOptionGetterSetter = function(value) {
      if (angular.isDefined(value)) {
        $scope.filterItem.activeOption(findIdByValue(value));
        $scope.onChange({
          filterName: $scope.filterItem.label,
          filterValue: value,
          filterType: $scope.filterItem.type
        });
      }
      return $scope.filterItem.activeOption().value;
    };
  };

  return {
    restrict: 'E',
    replace: true,
    scope: {
      filterItem: '=',
      onChange: '&'
    },
    controller,
    templateUrl: 'app/sections/filter.html'
  };
});
