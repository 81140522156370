/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.i18n', []).constant('I18N_CONFIG', {
  loginLocaleKey: 'loginLocaleId',
  systemLocaleKey: 'localeId',
  translationsPath: '/admin_locales',
  generalSection: 'general',
  responsesSection: 'errorMessages',
  fallbackLocale: 'en-us',
  iconsPath: '/static/images/langs/',
  clientIconsPath: '/client/client_locales/',
  helpers: true
});
