/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

const localeImages = require.context('assets/images/lang', false, /\.png$/);

angular
  .module('wcm.admin')
  .factory('localizationFactory', function(
    requestFactory,
    LOCALIZATION_CONFIG,
    I18N_CONFIG
  ) {
    const config = LOCALIZATION_CONFIG;

    const setIsAvailableForDefault = function(localizations) {
      return localizations.map(function(item) {
        // eslint-disable-next-line no-param-reassign
        item.isAvailable = item.isDefault || item.isAvailable;
        return item;
      });
    };

    const getClientLocalizations = function getClientLocalizations() {
      return requestFactory
        .post(config.localizationServlet, config.getClientLocalizations, {})
        .then(function(res) {
          return res.data.clientLocalizations || [];
        })
        .then(function(locales) {
          return locales.map(function(locale) {
            return angular.extend({}, locale, {
              icon: `${I18N_CONFIG.clientIconsPath + locale.id}/${
                locale.id
              }.png`
            });
          });
        });
    };

    const getSystemLocalizations = function getSystemLocalizations() {
      return requestFactory
        .post(config.localizationServlet, config.getSystemLocalizations, {})
        .then(function(res) {
          return res.data.systemLocalizations || [];
        })
        .then(function(locales) {
          return locales.map(function(locale) {
            return angular.extend({}, locale, {
              icon: localeImages(`./${locale.id}.png`)
            });
          });
        });
    };

    const saveClientLocalizations = function saveClientLocalizations(
      localizations
    ) {
      // eslint-disable-next-line no-param-reassign
      localizations = setIsAvailableForDefault(localizations);
      return requestFactory.post(
        config.localizationServlet,
        config.saveClientLocalizations,
        {
          clientLocalizations: localizations
        }
      );
    };

    const saveSystemLocalizations = function saveSystemLocalizations(
      localizations
    ) {
      // eslint-disable-next-line no-param-reassign
      localizations = setIsAvailableForDefault(localizations);
      return requestFactory.post(
        config.localizationServlet,
        config.saveSystemLocalizations,
        {
          systemLocalizations: localizations
        }
      );
    };

    const getTermsForLocale = function getTermsForLocale(localeId) {
      return requestFactory
        .post(config.localizationServlet, config.getTerms, { id: localeId })
        .then(function(res) {
          return res.data.terms || '';
        });
    };

    const saveTermsForLocale = function saveTermsForLocale(localeId, terms) {
      return requestFactory
        .post(config.localizationServlet, config.saveTerms, {
          id: localeId,
          terms
        })
        .then(function(res) {
          return res.data.terms || '';
        });
    };

    return {
      getClientLocalizations,
      getSystemLocalizations,
      saveClientLocalizations,
      saveSystemLocalizations,
      getTermsForLocale,
      saveTermsForLocale
    };
  });
