/* eslint-disable no-param-reassign,no-return-assign */
/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.admin')
  .controller('dictionariesCtrl', function(
    $scope,
    dictionariesFactory,
    helpers,
    navigationPanel,
    breadcrumbs,
    $state
  ) {
    $scope.currentLocale = {};
    $scope.defaultName = 'DefaultDictionary';
    breadcrumbs.set({
      path: [{ name: 'admin' }, { name: 'dictionaries' }]
    });
    navigationPanel.setTabs([]);

    $scope.getDictionaryLocaleKey = name =>
      `admin.dictionaries.dictionaryForm.${helpers.toCamelCase(name)}`;

    const init = function init() {
      $scope.setBusy(true);
      dictionariesFactory
        .getDictionaries()
        .then(function(dictionaries) {
          $scope.dictionaries = dictionaries;
          $scope.currentDictionary = dictionaries[0];
          $scope.currentDictionaryCancel = angular.copy(
            $scope.currentDictionary
          );
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.submit = function() {
      $scope.setBusy(true);
      dictionariesFactory
        .saveDictionary({ dictionary: $scope.currentDictionary })
        .success(function() {
          $scope.editMode = false;
          $scope.currentDictionaryCancel = angular.copy(
            $scope.currentDictionary
          );
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.cancel = function() {
      cancelConfirmModal().then(function() {
        $scope.editMode = false;
        $scope.currentDictionary = angular.copy(
          $scope.currentDictionaryCancel
        );
      });
    };

    function cancelConfirmModal() {
      const messagePath =
        'admin.dictionaries.modals.cancelConfirmation.texts.edit';
      return helpers.confirmationModal('', messagePath, true).result;
    }

    $scope.$on('$stateChangeStart', function(event, toState, toParams) {
      if ($scope.editMode) {
        event.preventDefault();
        cancelConfirmModal().then(function() {
          $scope.editMode = false;
          $state.go(toState, toParams);
        });
      }
    });

    $scope.setCurrentDictionary = function setCurrentDictionary(index) {
      if ($scope.dictionaries[index].name !== $scope.currentDictionary.name) {
        $scope.currentDictionary = $scope.dictionaries[index];
        $scope.currentDictionaryCancel = angular.copy(
          $scope.currentDictionary
        );
      }
    };

    init();

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => !$scope.editMode,
        clickHandler: () => {
          $scope.editMode = true;
        }
      },
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => $scope.sectionForm.$invalid,
        type: () => 'confirm',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.submit();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.editMode,
        clickHandler: () => {
          $scope.cancel();
        }
      }
    ]);
  });
