/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2.presets')
  .constant('ANALYTICS_PRESETS_CONFIG', {
    presetsServlet: 'analyticsPreset',
    getPresetList: 'getPresetList',
    getPreset: 'getPreset',
    getDefaultPreset: 'getDefaultPreset',
    saveDefaultPreset: 'setDefaultPreset',
    removePreset: 'removePreset',
    savePreset: 'savePreset',
    defaultPresets: {
      sites: {
        name: 'Default Preset',
        type: 'sites',
        isDefault: false,
        isRemovable: false,
        data: {
          analyticBlocks: {
            generalInfoSitesAll: {
              show: true,
              showNumbers: null
            },
            dataByVisitors: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByComparisonSites: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  visitors: true,
                  views: false
                }
              ],
              currentFilter: 'visitors'
            },
            dataByTrafficUsage: {
              show: true,
              showNumbers: false,
              legend: {
                summary: true,
                classACampaigns: true,
                classBCampaigns: true,
                classADefault: true,
                classBDefault: true
              }
            },
            dataByNumberOfVisits: {
              show: true,
              showNumbers: false
            },
            dataByVisitorsAgeAndGender: {
              show: true,
              showNumbers: false,
              legend: {
                male: true,
                female: true,
                unknown: true
              }
            },
            dataByNewAndReturningVisitors: {
              show: true,
              showNumbers: false,
              legend: {
                newComers: true,
                returnedVisitors: true
              }
            },
            dataByNotReturningVisitors: {
              show: true,
              showNumbers: false
            },
            dataByCampaigns: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  classA: true,
                  classB: false
                },
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'classA__views'
            },
            dataByCitiesAndCountriesSites: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  cities: true,
                  countries: false
                }
              ],
              dropdownFilters: [['socialNetworks', 'siteLocation']],
              currentFilter: 'cities',
              requestFilter: 'socialNetworks'
            },
            dataByDevicesSites: {
              show: true,
              showNumbers: false
            },
            dataByDemographicInfoSites: {
              show: true,
              showNumbers: false
            },
            dataByHoursSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByWeekSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByMonthsSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByYearsSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            }
          }
        }
      },
      analyticsSite: {
        name: 'Default Preset',
        type: 'analyticsSite',
        isDefault: false,
        isRemovable: false,
        data: {
          analyticBlocks: {
            generalInfoSitesSpecAll: {
              show: true,
              showNumbers: null
            },
            generalInfoSitesFiltered: {
              show: true,
              showNumbers: null
            },
            dataByVisitorsWithWeather: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByTrafficUsage: {
              show: true,
              showNumbers: false,
              legend: {
                summary: true,
                classACampaigns: true,
                classBCampaigns: true,
                classADefault: true,
                classBDefault: true
              }
            },
            dataByNumberOfVisits: {
              show: true,
              showNumbers: false
            },
            dataByOtherVisitedSites: {
              show: true,
              showNumbers: false
            },
            dataByVisitorsAgeAndGender: {
              show: true,
              showNumbers: false,
              legend: {
                male: true,
                female: true,
                unknown: true
              }
            },
            dataByNewAndReturningVisitors: {
              show: true,
              showNumbers: false,
              legend: {
                newComers: true,
                returnedVisitors: true
              }
            },
            dataByNotReturningVisitors: {
              show: true,
              showNumbers: false
            },
            dataByCampaignsViews: {
              show: true,
              showNumbers: null,
              filters: [
                {
                  views: true,
                  uniqueViews: false
                }
              ],
              currentFilter: 'views'
            },
            dataByCampaigns: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  classA: true,
                  classB: false
                },
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'classA__views'
            },
            dataByCitiesAndCountriesSites: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  cities: true,
                  countries: false
                }
              ],
              dropdownFilters: [['socialNetworks', 'siteLocation']],
              currentFilter: 'cities',
              requestFilter: 'socialNetworks'
            },
            dataByDevicesSites: {
              show: true,
              showNumbers: false
            },
            dataByDemographicInfoSites: {
              show: true,
              showNumbers: false
            },
            dataByHoursSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByWeekSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByMonthsSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            },
            dataByYearsSites: {
              show: true,
              showNumbers: false,
              legend: {
                visitors: true,
                views: true
              }
            }
          }
        }
      },
      campaigns: {
        name: 'Default Preset',
        type: 'campaigns',
        isDefault: false,
        isRemovable: false,
        data: {
          analyticBlocks: {
            generalInfoCampaigns: {
              show: true,
              showNumbers: null,
              filters: [
                {
                  views: true,
                  uniqueViews: false
                }
              ],
              currentFilter_ctr: 'views'
            },
            dataByDays: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByComparisonCampaigns: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'views'
            },
            dataBySites: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              sortingFilters: [
                'quantity:asc',
                'quantity:desc',
                'name:asc',
                'name:desc'
              ],
              toggleFilters: {
                zeroData: true
              },
              currentFilter: 'views',
              currentSortFilter: 'quantity:desc'
            },
            dataByCitiesAndCountries: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  cities: true,
                  countries: false
                },
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              dropdownFilters: [['socialNetworks', 'siteLocation']],
              currentFilter: 'cities__views',
              requestFilter: 'socialNetworks'
            },
            dataByDevicesCampaigns: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'views'
            },
            dataByDemographicInfoCampaigns: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'views'
            },
            dataByHoursCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByWeekCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByMonthsCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByYearsCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            }
          }
        }
      },
      analyticsCampaign: {
        name: 'Default Preset',
        type: 'analyticsCampaign',
        isDefault: false,
        isRemovable: false,
        data: {
          analyticBlocks: {
            generalInfoCampaigns: {
              show: true,
              showNumbers: null,
              filters: [
                {
                  views: true,
                  uniqueViews: false
                }
              ],
              currentFilter_ctr: 'views'
            },
            dataByDaysAndSites: {
              show: true,
              showNumbers: false,
              aggregated: true,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              },
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'views'
            },
            dataByNumberOfViews: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  totalViews: true,
                  viewedOnly: false,
                  clickedOnly: false
                }
              ],
              currentFilter: 'totalViews'
            },
            dataBySites: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              sortingFilters: [
                'quantity:asc',
                'quantity:desc',
                'name:asc',
                'name:desc'
              ],
              toggleFilters: {
                zeroData: true
              },
              currentFilter: 'views',
              currentSortFilter: 'quantity:desc'
            },
            dataBySiteTags: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  tagsTypes: true,
                  tagsClasses: false
                },
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'tagsTypes__views'
            },
            dataByCitiesAndCountries: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  cities: true,
                  countries: false
                },
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              dropdownFilters: [['socialNetworks', 'siteLocation']],
              currentFilter: 'cities__views',
              requestFilter: 'socialNetworks'
            },
            dataByDevicesCampaigns: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'views'
            },
            dataByDemographicInfoCampaigns: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  views: true,
                  uniqueViews: false,
                  clicks: false,
                  ctr: false
                }
              ],
              currentFilter: 'views'
            },
            dataByHoursCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByWeekCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByMonthsCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByYearsCampaigns: {
              show: true,
              showNumbers: false,
              legend: {
                views: true,
                uniqueViews: true,
                clicks: true,
                ctr: true
              }
            },
            dataByViewersPortrait: {
              show: true,
              showNumbers: false,
              filters: [
                {
                  3: false,
                  6: false,
                  9: true
                }
              ],
              hideUnknown: false,
              currentFilter: '9'
            },
            dataByCurrentWidgets: {
              show: true,
              showNumbers: null
            }
          }
        }
      },
      general: {
        name: 'Default Preset',
        type: 'general',
        isDefault: false,
        isRemovable: false,
        data: {
          analyticBlocks: {
            campaignsTimeline: {
              filters: [
                {
                  partner: true,
                  advertiser: true,
                  default: true
                },
                {
                  running: true,
                  stopped: true,
                  finished: true,
                  scheduled: true
                }
              ]
            },
            dataByTraffic: {
              show: true,
              showNumbers: false,
              legend: {
                total: true,
                used: true,
                leftover: true
              }
            },
            amountOfViewsAndVisitors: {
              show: true,
              showNumbers: null
            },
            generalInfoSystem: {
              show: true,
              showNumbers: null
            },
            amountOfCampaigns: {
              show: true,
              showNumbers: null,
              filters: [
                {
                  deleted: false,
                  active: true,
                  archived: false
                }
              ],
              currentFilter: 'active'
            },
            amountOfWidgets: {
              show: true,
              showNumbers: null,
              filters: [
                {
                  deleted: false,
                  active: true,
                  archived: false
                }
              ],
              currentFilter: 'active'
            },
            amountOfSitesAndEntities: {
              show: true,
              showNumbers: null,
              filters: [
                {
                  deleted: false,
                  active: true,
                  archived: false
                }
              ],
              currentFilter: 'active'
            },
            quantityOfRegisteredUsers: {
              show: true,
              showNumbers: null
            },
            dataByTags: {
              show: true,
              showNumbers: null,
              filters: [
                {
                  campaignTags: true,
                  siteClasses: false,
                  siteTypes: false
                }
              ],
              currentFilter: 'campaignTags'
            },
            vacantSites: {
              show: true,
              showNumbers: false
            },
            allSystemSites: {
              show: true,
              showNumbers: null,
              legend: {
                green: true,
                yellow: true,
                red: true
              }
            }
          }
        }
      }
    }
  });
