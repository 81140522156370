/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.helpers')
  .directive('imageUpload', function(helpers) {
    return {
      require: ['imageUpload', 'ngModel'],
      template:
        '<file-upload data-button-text="{{\'components.imageUpload.buttonCaption\' | i18n}}" data-on-file-chosen="onFileChosen(file)"></file-upload>',
      scope: {
        image: '=?'
      },
      controller() {
        this.file = undefined;
      },
      link(scope, element, attrs, ctrls) {
        const imageUploadCtrl = ctrls[0];
        const ngModelCtrl = ctrls[1];

        // here's dirty hack to copy class names from root element
        // to the label, to fix styling
        const classNames = element.attr('class');
        element.attr('class', '');
        element.find('label').attr('class', classNames);

        scope.onFileChosen = function(file) {
          imageUploadCtrl.file = file;
          helpers.readFileAsDataURL(file).then(function(event) {
            scope.image = event.target.result;
            ngModelCtrl.$setViewValue(event.target.result);
          });
        };
      }
    };
  })
  .directive('supportedExtensions', function() {
    return {
      require: ['ngModel', 'imageUpload'],
      link(scope, element, attrs, ctrls) {
        const ngModelCtrl = ctrls[0];
        const imageUploadCtrl = ctrls[1];
        const supportedExtensions = attrs.supportedExtensions.split(',');
        ngModelCtrl.$validators.extension = function() {
          if (ngModelCtrl.$pristine) {
            return true; // image comes from server -- already validated
          }
          const fileType = imageUploadCtrl.file
            ? imageUploadCtrl.file.type.split('/')[1]
            : '';
          return supportedExtensions.indexOf(fileType) > -1;
        };
      }
    };
  })
  .directive('supportedDimensions', function($q) {
    return {
      require: 'ngModel',
      link(scope, element, attrs, ngModel) {
        const supportedDimensions = attrs.supportedDimensions.split('x');
        const supportedWidth = +supportedDimensions[0];
        const supportedHeight = +supportedDimensions[1];
        // eslint-disable-next-line no-param-reassign
        ngModel.$asyncValidators.dimension = function(modelValue, viewValue) {
          const value = modelValue || viewValue;
          const dfd = $q.defer();
          if (value.indexOf('data') !== 0) {
            dfd.resolve(); // image comes from server -- already validated
          } else {
            const img = new Image();
            img.src = value;
            img.onload = function() {
              if (
                this.width !== supportedWidth ||
                this.height !== supportedHeight
              ) {
                dfd.reject();
              } else {
                dfd.resolve();
              }
            };
          }
          return dfd.promise;
        };
      }
    };
  });
