/* eslint-disable no-use-before-define */
/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const { moment } = window;

angular
  .module('wcm.admin')
  .controller('analyticsSectionCtrl', function(
    $q,
    $scope,
    $state,
    $timeout,
    helpers,
    analyticsPresetsFactory,
    analyticsRequestsFactory,
    sharedRequestsFactory,
    analyticsFactory2,
    conversionFactory
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = $scope.state || {});
    const user = $scope.currentUser;
    let watchImageLoading;

    state.filters = {};
    state.manageBlocks = {};
    state.manageFilterSettings = {};
    state.noWas = false;
    state.isBusy = true;
    state.isErrorInPreset = false;
    state.analyticsType = $state.current.name.split('.').pop();
    state.permissionType = 'viewAnalytics';

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = {
      terminateTypes: sharedRequestsFactory.terminateTypes,
      colorPatterns: analyticsFactory2.defaultColorPatterns,
      analyticsPeriodLimit: user.analyticsPeriodLimit,
      availableMinDate: user.systemCreationDate,
      minPeriod: null,
      maxPeriod: null
    });

    // eslint-disable-next-line no-multi-assign
    const chartsOptions = ($scope.chartsOptions =
      analyticsFactory2.chartsOptions);

    $scope.getKeys = analyticsFactory2.getKeys;

    const filterFn = function(searchString, item) {
      if (!searchString) return true;
      if (!item) return false;
      return ~(item.name || item)
        .toLowerCase()
        .indexOf(searchString.toLowerCase());
    };

    const waitImageLoading = function(callback) {
      watchImageLoading = $scope.$watchCollection(
        'state.loadedImages',
        function(val) {
          if (
            Object.keys(val).every(function(key) {
              return val[key];
            })
          ) {
            $timeout(callback, 2000);
            $scope.state.loadedImages = {};
            watchImageLoading();
          }
        }
      );
    };

    const resolveAllPromises = function(promises, callback) {
      let childPromises = [];
      $q.all(promises).then(function(res) {
        res.forEach(function(item) {
          if (item && item.promises) {
            childPromises = childPromises.concat(item.promises);
          }
        });
        // eslint-disable-next-line no-nested-ternary
        return childPromises.length > 0
          ? resolveAllPromises(childPromises, callback)
          : state.loadedImages
          ? waitImageLoading(callback)
          : $timeout(callback, 2000);
      });
    };

    $scope.getDataByAllBlocks = function(callback, options) {
      const blocks = Object.keys(state.blocksState)
        .filter(function(item) {
          // eslint-disable-next-line no-use-before-define
          return !!handlers.isVisible(item) && !!~options.indexOf(item);
        })
        .map(function(item) {
          if (
            item === 'generalInfoCampaigns' &&
            state.campaignWidgetsType === 'poll'
          ) {
            // eslint-disable-next-line no-param-reassign
            item = 'pollInfo';
          }
          return performRequestForBlock(item);
        });

      const promises = blocks.map(function(item) {
        return item.promise.catch(function(res) {
          return res;
        });
      });

      blocks.map(setChartData);
      resolveAllPromises(promises, callback);
    };

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = {
      filterBy(items, type) {
        return items.reduce(function(prev, curr) {
          if (curr.checked) prev.push(curr[type]);
          return prev;
        }, []);
      },

      getCollapseItems: function getCollapseItems(type, filter, values) {
        const collapsed = {};
        let itemsToRemove = [];
        const valuesIds = values.reduce(function(prev, curr) {
          if (curr.checked) prev.push(curr.id);
          return prev;
        }, []);

        let filterItems = [];

        filter.forEach(function(val) {
          filterItems = filterItems.concat(val.values);
        });

        filterItems.forEach(function(item) {
          if (
            item[type].length &&
            window._.intersection(valuesIds, item[type]).length ===
              item[type].length
          ) {
            if (collapsed[item.excelType]) {
              collapsed[item.excelType].push(item.name);
            } else {
              collapsed[item.excelType] = [item.name];
            }
            itemsToRemove = itemsToRemove.concat(item[type]);
          }
        });
        collapsed[type] = values.reduce(function(prev, curr) {
          if (itemsToRemove.indexOf(curr.id) === -1 && curr.checked)
            prev.push(curr.name);
          return prev;
        }, []);

        return collapsed;
      },

      setCurrentPreset(preset) {
        state.isPresetChanged = false;
        state.currentPreset = preset;

        // Set time filters from Preset
        state.filters = preset.data.filters
          ? angular.extend({}, state.filters, preset.data.filters)
          : state.filters;
        if (
          !preset.data.filters &&
          (state.analyticsType === 'analyticsCampaign' ||
            state.analyticsType === 'analyticsSite')
        ) {
          state.filters.startPeriod =
            options.startPeriod || state.filters.startPeriod;
          state.filters.endPeriod =
            options.endPeriod || state.filters.endPeriod;
        }
        if (preset.data.filters) {
          state.filters.startPeriod = moment(state.filters.startPeriod);
          state.filters.endPeriod = moment(state.filters.endPeriod);
        }

        // Set manage block filters from Preset
        handlers.resetManageBlocks();
        handlers.initManageBlocks(preset.data.manageBlockFilters);

        // hack to update current visible blocks
        state.blocksState = {};
        $timeout(function() {
          state.blocksState = angular.copy(preset.data.analyticBlocks);
        }, 0);
      },

      onPresetChange(preset) {
        state.currentPreset = preset; // optimistically set new preset
        state.chartsData = {}; // set chartsData to empty {}
        state.visibleAnalyticBlocks = [];
        analyticsPresetsFactory
          .getPreset(state.analyticsType, preset.name)
          .then(handlers.setCurrentPreset);
      },

      onPresetManage() {
        analyticsPresetsFactory
          .openPresetManager(options.presets)
          .result.then(handlers.onPresetChange)
          .finally(function() {
            analyticsPresetsFactory
              .getPresets(state.analyticsType)
              .then(function(presets) {
                options.presets = presets;
              });
          });
      },

      savePreset(name) {
        analyticsPresetsFactory
          .savePreset(
            state.analyticsType,
            name,
            state.blocksState,
            state.filters,
            state.manageBlocks
          )
          .then(function() {
            analyticsPresetsFactory
              .getPresets(state.analyticsType)
              .then(function(presets) {
                options.presets = presets;
                state.currentPreset = options.presets.filter(function(item) {
                  return item.name === name;
                })[0];
                state.isPresetChanged = false;
              });
          });
      },

      onPresetReset() {
        handlers.onPresetChange(state.currentPreset);
      },

      onRemoveBlock(blockName) {
        delete state.blocksState[blockName];
        debouncedScroll(); // pretend user scrolled view to update visible blocks
        handlers.onPresetAltered();
        let requestName;
        if (
          blockName === 'generalInfoSitesFiltered' ||
          blockName === 'generalInfoSitesAll'
        ) {
          requestName = getRequestHandlerByBlockName('generalInfoFiltered');
        } else if (blockName === 'generalInfoSitesSpecAll') {
          requestName = getRequestHandlerByBlockName('generalInfo');
        } else {
          requestName = getRequestHandlerByBlockName(blockName).split(
            /(Campaigns|Sites)/
          )[0];
          if (
            requestName.match(/By$/) ||
            requestName.match(/And$/) ||
            requestName.match(/Visited$/) ||
            requestName.match(/Filtered$/)
          ) {
            requestName = getRequestHandlerByBlockName(blockName);
          }
        }
        sharedRequestsFactory.terminateDA(requestName);
      },

      onVisibilityChange(blockName, visibilityStatus) {
        state.visibleAnalyticBlocks = visibilityStatus
          ? state.visibleAnalyticBlocks.concat([blockName])
          : state.visibleAnalyticBlocks.filter(function(block) {
              return block !== blockName;
            });
        return (
          isValidTimeFilters(state.filters) &&
          getDataForBlocks(state.visibleAnalyticBlocks)
        );
      },

      // When time filters changed -> get new data for blocks
      onFiltersChange() {
        if (!isValidTimeFilters(state.filters)) {
          return false;
        }
        switch (state.analyticsType) {
          case 'sites':
            delete state.filters.campaignIds;
            break;
          case 'campaigns':
            delete state.filters.siteIds;
            break;
          case 'general':
            delete state.filters.campaignIds;
            delete state.filters.siteIds;
            break;
          default:
            break;
        }
        state.chartsData = {};
        state.noDataYetAvailable = false;
        state.noServerAvailable = false;
        state.isPresetChanged = true;
        handlers.reinitMinMaxPeriod();
        if (typeof state.visibleAnalyticBlocks === 'undefined') {
          state.visibleAnalyticBlocks = [];
        }
        return getDataForBlocks(state.visibleAnalyticBlocks);
      },

      onScroll() {
        $scope.$broadcast('analytics:scroll');
      },

      // Change legend of line chart
      onChartLegendChange(blockName, title, value) {
        state.blocksState[blockName].legend[title] = value;
        handlers.onPresetAltered();
      },

      // Change legend of line map
      onMapLegendChange(blockName, title, value) {
        state.blocksState[blockName].legend[title] = !value;
        state.chartsData[blockName].view = analyticsFactory2.prepareMapData(
          state.chartsData[blockName].requested,
          state.blocksState[blockName].legend
        );
        handlers.onPresetAltered();
      },

      // Change line chart filter with resend data
      onChartFilterChangeWithResend(blockName) {
        state.blocksState[blockName].currentFilter = this.setCurrentFilter(
          state.blocksState[blockName]
        );
        this.resendRequest(blockName);
        handlers.onPresetAltered();
      },
      getPage(blockName, ipp, page) {
        state.chartsData[blockName].itemsPage = helpers.getPage(
          state.chartsData[blockName].view,
          ipp,
          page
        );
      },
      setCurrentFilter: analyticsFactory2.setCurrentFilter,

      // Get pie data for future slicing
      getPieDataForSlice(blockName) {
        switch (blockName) {
          case 'dataBySites':
            return state.chartsData[blockName].requested.data[0].views;
          default:
            return null;
        }
      },

      // Show more pie handler acts for MULTI pie chart block
      // with filters and without filters block
      showMoreMultiPies(blockName, chartName, collapseValue) {
        const curFilter = state.blocksState[blockName].currentFilter;
        const cutData = analyticsFactory2.getShowMoreMultiPies(
          curFilter
            ? state.chartsData[blockName].requested[curFilter]
            : state.chartsData[blockName].requested,
          chartName,
          collapseValue
        );
        state.chartsData[blockName].view.data[cutData.index] = cutData.item;
      },

      // Show more pie handler acts for SINGLE pie chart block
      // with filters and without filters block
      showMoreSinglePie(blockName, collapseValue) {
        const curFilter = state.blocksState[blockName].currentFilter;
        let data = curFilter
          ? state.chartsData[blockName].requested.data[0][curFilter]
          : state.chartsData[blockName].requested.data[0];

        if (state.blocksState[blockName].toggleFilters) {
          data = analyticsFactory2.preparePieByToggles(
            data,
            blockName,
            state.blocksState[blockName].toggleFilters,
            curFilter !== 'views' ? this.getPieDataForSlice(blockName) : null
          );
        }

        if (state.blocksState[blockName].sortingFilters) {
          data = analyticsFactory2.preparePieBySorting(
            data,
            'dataBySites',
            state.blocksState[blockName].currentSortFilter
          );
        }

        state.chartsData[
          blockName
        ].view = analyticsFactory2.getShowMoreSinglePie(
          data,
          blockName,
          collapseValue
        );
      },

      // Change pie filter acts for BOTH
      // MULTI pie chart block and SINGLE pie chart block
      changePieFilter(blockName, isMultiCharts) {
        handlers.onPresetAltered();
        if (
          chartsOptions[blockName] &&
          angular.isDefined(chartsOptions[blockName].visibleEntries)
        ) {
          chartsOptions[blockName].visibleEntries =
            analyticsFactory2.chartsOptionsDefault[blockName].visibleEntries;
        }
        // eslint-disable-next-line no-multi-assign
        const curFilter = (state.blocksState[
          blockName
        ].currentFilter = this.setCurrentFilter(state.blocksState[blockName]));

        // return if no data in system
        if (typeof state.chartsData[blockName].requested === 'string') {
          return;
        }

        let data =
          state.chartsData[blockName].requested[curFilter] ||
          state.chartsData[blockName].requested.data[0][curFilter];

        if (state.blocksState[blockName].toggleFilters) {
          data = analyticsFactory2.preparePieByToggles(
            data,
            blockName,
            state.blocksState[blockName].toggleFilters,
            curFilter !== 'views' ? this.getPieDataForSlice(blockName) : null
          );
        }

        if (state.blocksState[blockName].sortingFilters) {
          data = analyticsFactory2.preparePieBySorting(
            data,
            blockName,
            state.blocksState[blockName].currentSortFilter
          );
        }

        state.chartsData[blockName].view = analyticsFactory2.preparePieData(
          data,
          !isMultiCharts ? blockName : null
        );
      },

      // Change filter acts for NOT CHART blocks with Pagination
      changePaginationBlockFilter(blockName, searchQuery) {
        // eslint-disable-next-line no-multi-assign
        const curFilter = (state.blocksState[
          blockName
        ].currentFilter = this.setCurrentFilter(state.blocksState[blockName]));
        if (searchQuery === '')
          state.blocksState[blockName].extraData.searchQuery = '';
        state.chartsData[blockName].view = (curFilter
          ? state.chartsData[blockName].requested[curFilter]
          : state.chartsData[blockName].requested
        ).filter(filterFn.bind(null, searchQuery));
        state.chartsData[blockName].itemsPage = helpers.getPage(
          state.chartsData[blockName].view,
          state.blocksState[blockName].extraData.itemsPerPage,
          (state.blocksState[blockName].extraData.currentPage = 1)
        );
      },

      // Change filter acts for NOT CHART blocks
      changeSimpleFilter(blockName) {
        // eslint-disable-next-line no-multi-assign
        const curFilter = (state.blocksState[
          blockName
        ].currentFilter = this.setCurrentFilter(state.blocksState[blockName]));
        state.chartsData[blockName].view =
          state.chartsData[blockName].requested[curFilter];
        handlers.onPresetAltered();
      },

      // Change filter of Comparison block
      changeComparisonFilter(blockName) {
        state.blocksState[blockName].currentFilter = this.setCurrentFilter(
          state.blocksState[blockName]
        );
        if (typeof state.chartsData[blockName].requested === 'string')
          return false;

        const options = analyticsFactory2.setMultiLinesChartOptions(
          state.chartsData[blockName].requested.views,
          state.blocksState[blockName].currentFilter === 'ctr',
          null,
          'area',
          true
        );
        state.chartsData[blockName].view = analyticsFactory2.prepareLineData(
          state.chartsData[blockName].requested,
          options,
          state.blocksState[blockName].currentFilter
        );
        return handlers.onPresetAltered();
      },

      // Change filter of General Info Value block
      changeGeneralValueFilter(blockName, filterName) {
        // eslint-disable-next-line no-multi-assign
        let curFilter = (state.blocksState[blockName][
          `currentFilter_${filterName}`
        ] = this.setCurrentFilter(state.blocksState[blockName]));
        curFilter = `${curFilter}CTR`;
        state.chartsData[blockName].view[filterName] =
          state.chartsData[blockName].requested[curFilter];
        handlers.onPresetAltered();
      },

      setAvailableValues(availableValues) {
        options.availableValues = availableValues;
      },

      isPending(blockName) {
        return (
          !state.chartsData[blockName] ||
          !state.chartsData[blockName].requested ||
          state.chartsData[blockName].requested === 'pending'
        );
      },

      isVisible(blockName) {
        return state.blocksState && state.blocksState[blockName];
      },

      dataAvailabilityForBlock(blockName) {
        return state.chartsData[blockName] &&
          typeof state.chartsData[blockName].requested === 'string'
          ? state.chartsData[blockName].requested
          : '';
      },

      getAvailableValues(analyticsType, permissionType) {
        state.analyticsType =
          analyticsType || $state.current.name.split('.').pop();
        state.permissionType = permissionType || state.permissionType;
        return analyticsRequestsFactory
          .getAvailableValues(state.analyticsType, state.permissionType)
          .then(
            function(data) {
              state.noWas = false;
              options.availableValues = data;
            },
            function() {
              state.noWas = true;
            }
          );
      },

      // this function should be called whenever action that must be treated as one
      // that changes preset happened
      onPresetAltered() {
        state.isPresetChanged = true;
      },

      registerManageBlock(name, data) {
        if (state.manageBlocks[name]) return;
        state.manageBlocks[name] = data;
      },

      resetManageBlocks() {
        state.manageBlocks = {};
        analyticsRequestsFactory.resetManageBlocksData();
      },

      getExportOptions() {
        const exportOptions = [];
        const allBlocks = analyticsFactory2.exportPresets[state.analyticsType];
        const currentBlocks = Object.keys(state.blocksState);
        const alwaysPrintedBlocks = ['sites', 'campaigns'];
        allBlocks.forEach(function(item) {
          let localizeKey = item;
          localizeKey +=
            analyticsFactory2.additionalNames[
              `${state.analyticsType}.${item}`
            ] || '';
          exportOptions.push({
            key:
              item === 'generalInfoCampaigns' &&
              state.campaignWidgetsType === 'poll'
                ? 'pollInfo'
                : item,
            name: `analytics.exportBlockTitles.${localizeKey}`,
            isDisabled:
              !~alwaysPrintedBlocks.indexOf(item) &&
              (!~currentBlocks.indexOf(item) || !handlers.isVisible(item)),
            isAlwaysDisabled: false,
            isAlwaysHidden: ~alwaysPrintedBlocks.indexOf(item),
            value:
              !!~alwaysPrintedBlocks.indexOf(item) ||
              (!!~currentBlocks.indexOf(item) && !!handlers.isVisible(item))
          });
        });
        return exportOptions;
      },

      // Get data by single block
      resendRequest(blockName) {
        setChartData(performRequestForBlock(blockName));
      },

      // Set Empty data to block after request per block
      setEmptyDataForBlock(status, blockName) {
        state.chartsData[blockName] = {
          requested: helpers.isDataNotAvailable(status)
        };
        switch (state.chartsData[blockName].requested) {
          case 'noDataYetAvailable':
            state.noDataYetAvailable = true;
            break;
          case 'noServerAvailable':
            state.noServerAvailable = true;
            break;
          default:
            break;
        }
      },

      hasPermission(type) {
        switch (type) {
          case 'export':
            return (
              user &&
              user.userPermissions &&
              user.userPermissions.analytics[state.analyticsType].export
            );
          default:
            return false;
        }
      },

      reinitMinMaxPeriod() {
        // calculate Min Period
        options.minPeriod = analyticsFactory2.getAnalyticsMinPeriod(
          moment(user.systemCreationDate).startOf('day'),
          moment(state.filters.endPeriod).startOf('day'),
          options.analyticsPeriodLimit
        );
        // calculate Max Period
        options.maxPeriod = state.filters.endPeriod;
      }
    });

    // Scroll debounce for improve requests logic
    const debouncedScroll = window._.debounce(handlers.onScroll, 500);

    function init(opt) {
      analyticsFactory2.resetChartsOptions();
      const dfds = [];
      let startPeriod;
      let endPeriod;
      state.analyticsType = $state.current.name.split('.').pop();
      state.visibleAnalyticBlocks = [];
      state.chartsData = {};

      // Set startPeriod
      if (opt && opt.startPeriod) {
        startPeriod = (opt.startPeriod < moment()
          ? opt.startPeriod
          : moment()
        ).startOf('day');
        options.startPeriod = opt.startPeriod;
      } else {
        startPeriod = Math.max(
          moment()
            .startOf('day')
            .subtract(30, 'day')
            .valueOf(),
          user.systemCreationDate
        );
        startPeriod = moment(startPeriod).startOf('day');
      }

      // Set endPeriod
      // eslint-disable-next-line prefer-const
      endPeriod =
        opt && opt.endPeriod ? opt.endPeriod : moment().startOf('day');

      // Set default time filters
      state.filters = angular.extend(state.filters, {
        startPeriod,
        endPeriod,
        startTimeFrame: 0,
        endTimeFrame: 24 * 60 * 60 * 1000,
        daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
        allTime: false,
        selectMax: false
      });
      state.manageFilterSettings = {};

      handlers.reinitMinMaxPeriod();

      dfds.push(
        analyticsPresetsFactory
          .getPresets(state.analyticsType)
          .then(
            function(presets) {
              options.presets = presets;
            },
            function(res) {
              setNoWas(res.status);
            }
          )
          .finally(function() {
            state.isBusy = false;
          })
      );

      state.systemDefaultPreset = analyticsPresetsFactory.getSystemPreset(
        state.analyticsType
      );
      dfds.push(
        analyticsPresetsFactory
          .getDefaultPreset(state.analyticsType)
          .then(handlers.setCurrentPreset, function(res) {
            setNoWas(res.status);
          })
      );

      return $q.all(dfds);
    }
    $scope.init = init;

    function getRequestHandlerByBlockName(blockName) {
      return `get${conversionFactory.capitalize(blockName)}`;
    }

    function shouldRequestDataForBlock(blockName) {
      return (
        !state.chartsData[blockName] || !state.chartsData[blockName].requested
      );
    }

    function setChartData(blockData) {
      blockData.promise
        .then(function(res) {
          state.chartsData[blockData.name] = res;
        })
        .catch(function(res) {
          handlers.setEmptyDataForBlock(res.status, blockData.name);
        });
    }

    function performRequestForBlock(blockName) {
      // if(state.noDataYetAvailable) {
      //   return { name: blockName, promise: $q.reject({status: 244}) };
      // }
      const handler =
        analyticsRequestsFactory[getRequestHandlerByBlockName(blockName)];
      if (!handler) {
        throw new Error(`Request handler for ${blockName} is not specified`);
      }
      state.chartsData[blockName] = state.chartsData[blockName] || {};
      state.chartsData[blockName].requested = 'pending';
      return {
        name: blockName,
        promise: handler(
          state.filters,
          state.blocksState[blockName],
          state.analyticsType,
          chartsOptions[blockName]
        )
      };
    }

    function requestDataForBlocks(blocksList) {
      return blocksList
        .filter(shouldRequestDataForBlock)
        .map(performRequestForBlock);
    }

    function isValidTimeFilters(timeFilters) {
      return analyticsFactory2.isValidTimeFilters(
        timeFilters,
        options.analyticsPeriodLimit,
        options.availableMinDate
      );
    }

    function getDataForBlocks(blocksList) {
      return requestDataForBlocks(blocksList).map(setChartData);
    }

    function setNoWas(status) {
      if (
        ~['noServerAvailable', 'noDataYetAvailable', 'noDataInSystem'].indexOf(
          helpers.isDataNotAvailable(status)
        )
      ) {
        state.noWas = true;
      }
    }

    $scope.$on('$stateChangeStart', function() {
      sharedRequestsFactory.terminateDA(
        options.terminateTypes[state.analyticsType]
      );
      delete state.filters.campaignIds;
      delete state.filters.siteIds;
    });
  });
