/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.campaigns').filter('occupationInterest', function() {
  return function(input) {
    if (typeof input !== 'string' || !~input.indexOf('_')) return input;

    const prefixes = ['JOB', 'INTEREST', 'LIFESTYLE'];

    let chunks = input.split('_');
    if (
      prefixes.some(function(prefix) {
        return prefix === chunks[0];
      })
    ) {
      chunks = chunks.slice(1);
    }

    const string = chunks.join(' ').toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
});
