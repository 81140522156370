/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .factory('usersFactory', function(
    $q,
    $rootScope,
    requestFactory,
    ADMIN_CONFIG,
    helpers
  ) {
    const permissionsBlocks = [
      'partnerCampaign',
      'advertiserCampaign',
      'widget',
      'site',
      'partner',
      'advertiser',
      'systemAnalytics',
      'reports'
    ];

    let copiedPermissions;

    const getList = function(filters, page, ipp, sortOrder) {
      const requestData = angular.extend(
        {},
        filters,
        helpers.getPaginationFilters(page, ipp, sortOrder)
      );
      return requestFactory
        .post(ADMIN_CONFIG.usersServlet, ADMIN_CONFIG.usersGetList, requestData)
        .then(function(res) {
          return res.data && res.data.users ? res.data.users : [];
        })
        .then(function(users) {
          return users.map(function(user, idx) {
            // eslint-disable-next-line no-param-reassign
            user.id = (page - 1) * ipp + idx + 1;
            return user;
          });
        })
        .then(function(users) {
          $rootScope.$broadcast('users:list-updated', users);
          return users;
        });
    };

    const getUser = function(userId) {
      return (
        requestFactory
          .post(ADMIN_CONFIG.usersServlet, ADMIN_CONFIG.userGetData, { userId })
          // eslint-disable-next-line arrow-body-style
          .then(res => {
            return res && res.data && res.data.user ? res.data.user : {};
          })
      );
    };

    const getUserPermissions = function(userId, entityType, searchQuery) {
      return requestFactory
        .post(ADMIN_CONFIG.usersServlet, ADMIN_CONFIG.userGetPermissions, {
          type: entityType,
          userId,
          searchQuery
        })
        .then(function(res) {
          return res.data ? res.data : {};
        });
    };

    const getUserSystemWideAnalyticsPermissions = function(userId) {
      return requestFactory
        .post(
          ADMIN_CONFIG.usersServlet,
          ADMIN_CONFIG.userGetSystemWideAnalyticsPermissions,
          {
            userId
          }
        )
        .then(function(res) {
          return res.data && res.data.systemWideAnalytics
            ? res.data.systemWideAnalytics
            : {};
        });
    };

    const getUserReportsPermissions = function(userId) {
      return requestFactory
        .post(
          ADMIN_CONFIG.usersServlet,
          ADMIN_CONFIG.userGetReportsPermissions,
          {
            userId
          }
        )
        .then(function(res) {
          return res.data && res.data.systemWideReports
            ? res.data.systemWideReports
            : {};
        });
    };

    const saveUserPermissions = function(userId, type, permissionsSet) {
      const payload = angular.extend(
        { userId, type },
        { viewNew: false, modifyNew: false, deleteNew: false },
        permissionsSet
      );
      return requestFactory.post(
        ADMIN_CONFIG.usersServlet,
        ADMIN_CONFIG.userSavePermissions,
        payload
      );
    };

    const saveUserSystemWideAnalyticsPermissions = function(
      userId,
      permissions
    ) {
      return requestFactory.post(
        ADMIN_CONFIG.usersServlet,
        ADMIN_CONFIG.userSaveSystemWideAnalyticsPermissions,
        {
          userId,
          systemWideAnalytics: permissions
        }
      );
    };

    const saveUserReportsPermissions = function(userId, permissions) {
      return requestFactory.post(
        ADMIN_CONFIG.usersServlet,
        ADMIN_CONFIG.userSaveReportsPermissions,
        {
          userId,
          systemWideReports: permissions
        }
      );
    };

    const saveUser = function(user) {
      return requestFactory.post(
        ADMIN_CONFIG.usersServlet,
        ADMIN_CONFIG.userSave,
        { user }
      );
    };

    const moveToArchive = function(userId) {
      return requestFactory.post(
        ADMIN_CONFIG.usersServlet,
        ADMIN_CONFIG.userMoveToArchive,
        { userId }
      );
    };

    const recoverFromArchive = function(userId) {
      return requestFactory.post(
        ADMIN_CONFIG.usersServlet,
        ADMIN_CONFIG.userRecoverFromArchive,
        { userId }
      );
    };

    const remove = function(userId) {
      return requestFactory.post(
        ADMIN_CONFIG.usersServlet,
        ADMIN_CONFIG.userRemove,
        { userId }
      );
    };

    const copyPermissions = function(userId) {
      const dfds = permissionsBlocks.map(function(block) {
        switch (block) {
          case 'systemAnalytics':
            return getUserSystemWideAnalyticsPermissions(userId);
          case 'reports':
            return getUserReportsPermissions(userId);
          default:
            return getUserPermissions(userId, block, '');
        }
      });

      return $q.all(dfds).then(function(data) {
        copiedPermissions = data;
        return data;
      });
    };

    const pastePermissions = function(userId) {
      const dfds = permissionsBlocks.map(function(block, idx) {
        switch (block) {
          case 'systemAnalytics':
            return saveUserSystemWideAnalyticsPermissions(
              userId,
              copiedPermissions[idx]
            );
          case 'reports':
            return saveUserReportsPermissions(userId, copiedPermissions[idx]);
          default:
            return saveUserPermissions(userId, block, copiedPermissions[idx]);
        }
      });

      return $q.all(dfds);
    };

    const getCopiedPermissions = function() {
      return copiedPermissions;
    };

    return {
      getList,
      getUser,
      saveUser,
      getUserPermissions,
      saveUserPermissions,
      moveToArchive,
      recoverFromArchive,
      remove,
      copyPermissions,
      pastePermissions,
      getCopiedPermissions,
      getUserSystemWideAnalyticsPermissions,
      saveUserSystemWideAnalyticsPermissions,
      getUserReportsPermissions,
      saveUserReportsPermissions
    };
  });
