const { angular } = window;

angular.module('wcm.components').component('actionButtons', {
  templateUrl: 'app/core/components/actionButtons/actionButtons.html',
  controller() {
    this.getClasses = button => {
      const classes = [];
      if (button.type && button.type()) {
        classes.push(`button_${button.type()}`);
      }
      if (button.isBusy && button.isBusy()) {
        classes.push('button_busy');
      }
      return classes.join(' ');
    };
  },
  bindings: {
    buttons: '<'
  }
});
