/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.helpers')
  .directive('disableBackspaceDefault', function() {
    return function(scope, element) {
      element.on('keydown', function(event) {
        if (event.keyCode === 8) {
          const d = event.srcElement || event.target;
          let doPrevent = false;
          if (
            d.hasAttribute('contenteditable') ||
            (d.tagName.toUpperCase() === 'INPUT' &&
              (d.type.toUpperCase() === 'TEXT' ||
                d.type.toUpperCase() === 'PASSWORD' ||
                d.type.toUpperCase() === 'FILE' ||
                d.type.toUpperCase() === 'SEARCH' ||
                d.type.toUpperCase() === 'EMAIL' ||
                d.type.toUpperCase() === 'NUMBER' ||
                d.type.toUpperCase() === 'DATE' ||
                d.type.toUpperCase() === 'URL')) ||
            d.tagName.toUpperCase() === 'TEXTAREA'
          ) {
            doPrevent = d.readOnly || d.disabled;
          } else {
            doPrevent = true;
          }

          if (doPrevent) {
            event.preventDefault();
          }
        }
      });
    };
  });
