/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.helpers')
  .filter('reverse', function() {
    return function(items) {
      return items ? items.slice().reverse() : items;
    };
  })
  .filter('capitalize', function(conversionFactory) {
    return function(input) {
      return conversionFactory.capitalize(input);
    };
  })
  .filter('state2classes', function() {
    return function(input) {
      return `__${input}`.split('.').join(' __');
    };
  })
  .filter('formatDate', function(conversionFactory) {
    return function(timestamp) {
      return conversionFactory.formatDate(timestamp);
    };
  })
  .filter('convertTime', function(conversionFactory) {
    return function(timestamp) {
      return conversionFactory.convertTime(timestamp);
    };
  })
  .filter('formatTime', function(conversionFactory) {
    return function(timestamp) {
      return conversionFactory.formatTime(timestamp);
    };
  })
  .filter('timeZone', function(conversionFactory) {
    return function(timestamp) {
      return conversionFactory.getTimeZone(timestamp);
    };
  })
  .filter('markerReplacer', function() {
    return function(input, marker, replacement) {
      return input.replace(new RegExp(marker, 'g'), replacement);
    };
  })
  .filter('doubleToDegree', function(conversionFactory) {
    return function(value) {
      return conversionFactory.doubleToDegree(value);
    };
  })
  .filter('parseDBStrings', function() {
    const parseFunc = function(input, specSign = '') {
      if (typeof input !== 'string') return input;

      if (!~input.indexOf('_'))
        return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();

      const prefixes = [
        'AGE',
        'JOB',
        'APPEARANCE',
        'FAMILY',
        'GENDER',
        'KIDS',
        'INCOME'
      ];

      let chunks = input.split('_');
      if (
        prefixes.some(function(prefix) {
          return prefix === chunks[0];
        })
      ) {
        chunks = chunks.slice(1);
      }

      let str = '';
      // 1. If in final str = 0 - then set str to 'None'
      // 2. If there is 'plus' word - then set '+' instead 'plus'
      // 3. If first item[0] = 0 and there is specSign (f.e. '$') -
      // then set all prevision str = '<' and set next item[1] = item[1]+1
      // (f.e. '0-99' become '<$100')
      // 4. If current item is number and next item is number too - then set '-' between this items
      // 5. If current item is not number and next is not number too - then set ' '
      // between this items
      chunks.forEach(function(item, i) {
        // eslint-disable-line consistent-return
        if (item.toLowerCase() === 'plus') {
          str += '+';
          return false;
        }
        str +=
          specSign +
          (specSign && str === '<'
            ? +item.toLowerCase() + 1
            : item.toLowerCase());

        if (
          // eslint-disable-next-line no-self-compare
          +item === +item &&
          typeof +item === 'number' &&
          chunks[i + 1] &&
          // eslint-disable-next-line no-self-compare
          +chunks[i + 1] === +chunks[i + 1] &&
          typeof +chunks[i + 1] === 'number'
        ) {
          if (+item === 0 && specSign) str = '<';
          else str += '-';
        } else {
          str += ' ';
        }
      });
      if (+str === 0) str = 'None';
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return function(inputs, separator) {
      if (!Array.isArray(inputs)) return parseFunc(inputs, separator);

      return inputs.map(function(input) {
        return parseFunc(input, separator);
      });
    };
  })
  .filter('toD3Percents', function() {
    return function(input) {
      return window.d3.format('.2%')(input);
    };
  })
  .filter('daysOfWeek', function(ANALYTICS_CONFIG2) {
    return function(input) {
      return input.map(function(item) {
        return ANALYTICS_CONFIG2.daysOfWeek.filter(
          day => day.id === item
        )[0].title;
      });
    };
  })
  /**
   * Formats input value to have leading zero if it is less than 10. Useful for date output.
   *
   * @example
   * 1 => 01, 11 => 11
   */
  .filter('twoDigitsDate', function() {
    return function(input) {
      const value = parseInt(input, 10);
      if (value < 10) {
        return `0${value}`;
      }
      return value ? value.toString() : value;
    };
  })
  .filter('dateWithGMT', function(conversionFactory) {
    return function(timestamp) {
      return conversionFactory.dateWithGMT(timestamp);
    };
  })
  .filter('vendorsRemap', function() {
    const vendorsMap = {
      ERTELECOM: 'CUSTOM ADAPTER 1',
      INTERTELECOM: 'CUSTOM ADAPTER 2',
      TENET: 'CUSTOM ADAPTER 3',
      SOLA: 'CUSTOM ADAPTER 4',
      UMS: 'CUSTOM ADAPTER 5'
    };

    return function(input) {
      return vendorsMap[input] || input;
    };
  })
  .filter('passwordAsterisk', function() {
    return function(password) {
      return password ? password.replace(/./g, '*') : '';
    };
  });
