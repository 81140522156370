import { getRequestInstance, buildUrl, ValidationError } from 'core/api';

const api = getRequestInstance();

export const signIn = async ({ userId, password }) => {
  try {
    const res = await api.post(buildUrl('login', 'login'), {
      userId,
      password
    });
    return { userInfo: res.data };
  } catch (rej) {
    if (rej.response) {
      if (rej.response.status === 403) {
        throw new ValidationError(rej);
      }
      if (rej.response.status === 495) {
        throw new ValidationError(rej, {
          cooldownTimer: rej.response.data.serverMessage
        });
      }
    }
    throw rej;
  }
};

export const forgotPassword = async ({ userId }) => {
  try {
    const res = await api.post(buildUrl('login', 'passwordForgot'), {
      userId
    });
    return { userInfo: res.data };
  } catch (rej) {
    if (rej.response) {
      if (rej.response.status === 403) {
        throw new ValidationError(rej);
      }
    }
    throw rej;
  }
};

export const resetPassword = async ({ password, token }) => {
  return await api.post(
    buildUrl('login', 'passwordReset'),
    {
      newPassword: password
    },
    {
      headers: {
        sessionId: token
      }
    }
  );
};
