/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular.module('wcm.widgets').component('widgetEditorAnswer', {
  templateUrl: 'app/sections/widgets/editor/pollWidget/widgetEditorAnswer.html',
  controller: widgetEditorAnswerController,
  bindings: {
    answer: '<',
    checkboxItemsStyle: '<',
    inputItemsStyle: '<',
    fontScale: '<',
    localeId: '@',
    defaultLocaleId: '@'
  }
});

function widgetEditorAnswerController(widgetsFactory) {
  const ctrl = this;

  ctrl.getAngularStyle = function(reactStyle) {
    if (!angular.isObject(reactStyle)) return reactStyle;
    const fontSize = parseInt(reactStyle.fontSize, 10);
    const scaledFontSize = fontSize * ctrl.fontScale;
    const updatedReactScale = window._.extend({}, reactStyle, {
      fontSize: scaledFontSize
    });

    return widgetsFactory.convertReactStylesToAngular(updatedReactScale);
  };

  ctrl.getText = function(item) {
    return (
      ctrl.answer[item].i18n[ctrl.localeId] ||
      ctrl.answer[item].i18n[ctrl.defaultLocaleId]
    );
  };
}
