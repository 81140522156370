import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useNgDeps } from 'ngDeps';
import PropTypes from 'prop-types';

const BreadcrumbsContext = createContext([{}]);

const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState();
  const { injectNgDeps } = useNgDeps();

  useEffect(() => {
    injectNgDeps({ breadcrumbs: { setBreadcrumbs } });
    return () => {
      injectNgDeps({ breadcrumbs: { setBreadcrumbs: null } });
    };
  }, [injectNgDeps]);

  const value = useMemo(() => {
    return [breadcrumbs, setBreadcrumbs];
  }, [breadcrumbs, setBreadcrumbs]);

  return (
    <BreadcrumbsContext.Provider value={value}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node
};

function useBreadcrumbs() {
  return useContext(BreadcrumbsContext);
}

export { BreadcrumbsProvider, useBreadcrumbs };
