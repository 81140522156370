/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.admin')
  .controller('userCtrl', function(
    $scope,
    $q,
    $modal,
    $state,
    usersFactory,
    breadcrumbs,
    navigationPanel
  ) {
    $scope.uiState = {};
    $scope.actionHandlers = {};

    $scope.setTabs = function(user, editMode) {
      const isAdmin = user.userRole === 'admins' || user.userRole === 'ghosts';
      navigationPanel.setTabs([
        {
          name: 'users.permissions',
          sref: 'section.admin.users.action.permissions',
          go: { id: user.userId },
          isDisabled: isAdmin || editMode
        },
        {
          name: 'users.details',
          sref: 'section.admin.users.action.view',
          go: { id: user.userId },
          isDisabled: editMode
        }
      ]);
    };

    $scope.setBreadcrumbs = function(isProfile, isCreateMode, itemName) {
      const path = [];
      path.push({ name: isProfile ? 'accountSettings' : 'admin' });
      path.push({
        // eslint-disable-next-line no-nested-ternary
        name: isCreateMode
          ? 'userCreate'
          : isProfile
          ? 'userProfile'
          : 'userDetails'
      });
      breadcrumbs.set({ path, itemName });
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'admin.buttons.edit',
        isDisabled: () => false,
        // type: () => '',
        isShown: () => !$scope.uiState.editMode,
        clickHandler: () => {
          $scope.uiState.editMode = true;
        }
      },
      {
        title: () => 'admin.buttons.save',
        isDisabled: () => false,
        type: () => 'confirm',
        isShown: () => $scope.uiState.editMode,
        clickHandler: () => {
          $scope.actionHandlers.save();
        }
      },
      {
        title: () => 'admin.buttons.cancel',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => $scope.uiState.editMode,
        clickHandler: () => {
          $scope.actionHandlers.cancel();
        }
      },
      {
        title: () => 'admin.buttons.close',
        isDisabled: () => false,
        type: () => 'reject',
        isShown: () => !$scope.uiState.editMode,
        clickHandler: () => {
          $scope.actionHandlers.cancel();
        }
      }
    ]);
  });
