/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.helpers')
  .factory('conversionFactory', function(localStorage) {
    const factory = {};

    /**
     * Dates Methods
     *
     */
    factory.getTime = function(isCleanTime) {
      const currentDate = new Date();
      const currentTime = currentDate.getTime();
      const offset = !isCleanTime
        ? parseInt(localStorage.get('serverTimeZone'), 10)
        : 0;
      return parseInt(currentTime + offset, 10);
    };

    factory.convertTime = function(time) {
      let convertedTime;
      let hours;
      let minutes;
      if (typeof time === 'number') {
        // if time should be converted to human representation of timestamp
        hours = Math.floor(time / 3600000);
        minutes = (time - 3600000 * hours) / 60000;
        // add 0 if hour less than 10 , example : 2 ->"02",5 -> "05"
        if (hours < 10) {
          hours = `0${hours}`;
        }
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        convertedTime = `${hours}:${minutes}`;
      } else {
        // if time should be converted to timestamp
        const _time = time.split(':');
        hours = parseInt(_time[0], 10);
        minutes = parseInt(_time[1], 10);
        convertedTime = hours * 3600000 + minutes * 60000;
      }
      return convertedTime;
    };

    factory.capitalize = function(input) {
      // eslint-disable-next-line prefer-template
      const _input = input + ''; // toString
      return _input.substring(0, 1).toUpperCase() + _input.substring(1);
    };

    factory.getTimeZone = function(timeStamp) {
      const currentTimeZone = parseInt(timeStamp, 10) / 60 / 60 / 1000;
      const currentTimeZoneSymbol = currentTimeZone > 0 ? '+' : '-';
      return currentTimeZoneSymbol + currentTimeZone;
    };

    factory.doubleToDegree = function(value) {
      const degree = Number(value);
      const rawMinute = Math.abs((value % 1) * 60);
      const minute = Number(rawMinute);
      const second = Number(Math.round((rawMinute % 1) * 60));
      return `${degree.toFixed(0)}°${minute.toFixed(0)}′${second.toFixed(0)}″`;
    };

    factory.formatDate = function(timestamp) {
      if (typeof +timestamp !== 'number') return false;
      const _timestamp = timestamp ? parseInt(timestamp, 10) : 0;
      const parseDate = new Date(parseInt(_timestamp, 10));
      let D = parseDate.getDate();
      D = D < 10 ? `0${D}` : D;
      let M = parseDate.getMonth() + 1;
      M = M < 10 ? `0${M}` : M;
      const Y = parseDate.getFullYear();

      return `${D}.${M}.${Y}`;
    };

    factory.formatTime = function(timestamp) {
      const _timestamp = timestamp ? parseInt(timestamp, 10) : 0;
      const parseDate = new Date(parseInt(_timestamp, 10));
      const hh =
        parseDate.getHours() < 10
          ? `0${parseDate.getHours()}`
          : parseDate.getHours();
      const mm =
        parseDate.getMinutes() < 10
          ? `0${parseDate.getMinutes()}`
          : parseDate.getMinutes();

      return `${hh}:${mm}`;
    };

    factory.dateWithGMT = function(timestamp) {
      if (!timestamp) return '';
      const date = window.moment(timestamp);
      let offset = localStorage.get('timeZone') / 3600000;
      if (offset >= 0) {
        offset = offset.toString().length < 2 ? `+0${offset}` : `+${offset}`;
      } else {
        offset =
          (-1 * offset).toString().length < 2 ? `-0${offset * -1}` : offset;
      }
      return date.format('DD.MM.YYYY HH:mm [GMT:]') + offset;
    };

    return factory;
  });
