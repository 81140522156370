/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.login')
  .controller('forgotPasswordCtrl', function($scope, $state, auth) {
    $scope.restorePassword = function restorePassword(credentials) {
      $scope.blockUI = true;
      auth
        .forgotPassword(credentials.email)
        .success(function() {
          $scope.passwordRestored = true;
        })
        .error(function(data, status) {
          if (status === 403) {
            $scope.forgotPasswordForm.email.$setValidity(
              'serverValidation',
              false
            );
          }
        })
        .finally(function() {
          $scope.blockUI = false;
        });
    };
  });
