const { angular } = window;

angular
  .module('wcm.core')

  .directive('collapse', [
    '$transition',
    function($transition) {
      return {
        link(scope, element, attrs) {
          let initialAnimSkip = true;
          let currentTransition;

          function doTransition(change) {
            const newTransition = $transition(element, change);
            if (currentTransition) {
              currentTransition.cancel();
            }
            currentTransition = newTransition;
            newTransition.then(newTransitionDone, newTransitionDone);
            return newTransition;

            function newTransitionDone() {
              // Make sure it's this transition, otherwise, leave it alone.
              if (currentTransition === newTransition) {
                currentTransition = undefined;
              }
            }
          }

          function expand() {
            if (initialAnimSkip) {
              initialAnimSkip = false;
              expandDone();
            } else {
              element.removeClass('collapse').addClass('collapsing');
              doTransition({ height: `${element[0].scrollHeight}px` }).then(
                expandDone
              );
            }
          }

          function expandDone() {
            element.removeClass('collapsing');
            element.addClass('collapse in');
            // element.css({height: 'auto'}); remove it to workaround http://youtrack.dev.whirl.sg/issue/WCM_PROJ-2063
            // strange Safari behaviour.
          }

          function collapse() {
            if (initialAnimSkip) {
              initialAnimSkip = false;
              collapseDone();
              element.css({ height: 0 });
            } else {
              // CSS transitions don't work with height: auto, so we have to manually change
              // the height to a specific value
              element.css({ height: `${element[0].scrollHeight}px` });
              // trigger reflow so a browser realizes that height was updated from auto to
              // a specific value
              // eslint-disable-next-line no-unused-vars
              const x = element[0].offsetWidth;

              element.removeClass('collapse in').addClass('collapsing');

              doTransition({ height: 0 }).then(collapseDone);
            }
          }

          function collapseDone() {
            element.removeClass('collapsing');
            element.addClass('collapse');
          }

          scope.$watch(attrs.collapse, function(shouldCollapse) {
            if (shouldCollapse) {
              collapse();
            } else {
              expand();
            }
          });
        }
      };
    }
  ]);
