/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
const contentTypeImages = require.context(
  'assets/images/widgets',
  false,
  /\.png$/
);

angular
  .module('wcm.widgets')
  .directive('widgetContentView', function($q) {
    function loadImages(images) {
      const dfds = [];

      angular.forEach(images, function(image) {
        const img = new Image();
        const dfd = $q.defer();
        img.src = image;
        img.onload = dfd.resolve;
        img.onerror = dfd.reject;
        dfds.push(dfd.promise);
      });

      return $q.all(dfds);
    }
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        widgetItem: '=',
        fallbackBackground: '@',
        loadImageCallback: '&'
      },
      templateUrl: 'app/sections/widgets/widgetContentView.html',
      link(scope) {
        scope.getWidgetPlaceholderImage = function(contentType) {
          return contentType
            ? contentTypeImages(`./wdg-big-bg-${contentType}.png`)
            : '';
        };

        scope.$watch(
          'widgetItem',
          function(newVal) {
            if (!newVal) {
              return true;
            }
            scope.resourcesLoaded = false;
            if (!newVal.contentImage || !newVal.backgroundImage) {
              scope.resourcesLoaded = true;
              if (scope.loadImageCallback) {
                scope.loadImageCallback({ widgetId: scope.widgetItem.id });
              }
            } else {
              loadImages([newVal.contentImage, newVal.backgroundImage]).finally(
                function() {
                  scope.resourcesLoaded = true;
                  if (scope.loadImageCallback) {
                    scope.loadImageCallback({ widgetId: scope.widgetItem.id });
                  }
                }
              );
            }
            return undefined;
          },
          true
        );

        scope.getBackgroundColor = function() {
          return ~['static_dynamic', 'slider'].indexOf(
            scope.widgetItem.contentType
          ) && scope.resourcesLoaded
            ? scope.widgetItem.backgroundColor
            : 'transparent';
        };
      }
    };
  });
