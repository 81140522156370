/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
import { injectNgDeps } from 'ngDeps';

const { angular } = window;
angular
  .module('wcm.core')
  .controller('ApplicationCtrl', function(
    $timeout,
    $scope,
    $rootScope,
    requestFactory,
    $http,
    $log,
    $state,
    $modal,
    auth,
    hardlinks,
    conversionFactory,
    themeFactory,
    session,
    i18nFactory,
    helpers
  ) {
    $scope.userAgent = navigator.userAgent.toLowerCase();
    $scope.isSupport = isSupport();
    $scope.$log = $log;
    $scope.$state = $state;
    // Items quantity which displayed in list per page (exclusive widget's list)
    $scope.itemsOnPage = $state.params.ipp || 15;
    $scope.currentPage = $state.params.pg || 1;
    $scope.itemsOnPageValues = [15, 30, 45, 60, 90];
    $scope.totalItems = 0;
    $scope.cacheParam = Math.random();
    $scope.systemInfo = {};
    $scope.isDefined = angular.isDefined;

    $rootScope.clientTimezoneOffset = window.moment().utcOffset() * 60000;

    window.$('body').addClass(`__${helpers.checkBrowser().toLowerCase()}`);

    $scope.logout = function logout() {
      $modal
        .open({
          templateUrl: 'app/core/views/logoutModal.html'
        })
        .result.then(function() {
          auth.logout().success(function() {
            $state.go('login.signIn');
          });
        });
    };
    $scope.theme = themeFactory.getTheme();

    // Graceful degradation for browsers except Chrome
    $scope.isChrome = function(type) {
      return type && $scope.userAgent.indexOf('chrome') > -1 ? type : null;
    };

    $scope.setLocale = function(localeId) {
      i18nFactory.setCurrentLocale(localeId, 'system').then(function(locale) {
        $rootScope.currentUser = session.setSessionParam('localeId', locale.id);
      });
    };

    $scope.invalidateCache = function() {
      $scope.cacheParam = Math.random();
    };

    // Check if current browser is supporting
    function isSupport() {
      return (
        $scope.userAgent.indexOf('chrome') > -1 ||
        $scope.userAgent.indexOf('firefox') > -1 ||
        $scope.userAgent.indexOf('safari') > -1 ||
        $scope.userAgent.indexOf('rv:11') > -1 ||
        $scope.userAgent.indexOf('edge') > -1
      );
    }

    // get versionInfo
    helpers.getVersionInfo().then(function(data) {
      $scope.systemInfo.systemVersion = data;
    });

    $scope.hasPermissionsForAnalytics = auth.hasPermissionsForAnalytics;

    $scope.hasPermissionsForReports = auth.hasPermissionsForReports;

    $scope.hasPermissionsForSection = function(sectionName) {
      return auth.hasPermissionsForSection($scope.currentUser, sectionName);
    };

    $scope.versionClickHandler = event => {
      const scope = $scope.$new(true);
      scope.buildNumber = process.env.REACT_APP_BUILD_NUMBER || 'dev';
      scope.buildUrl = process.env.REACT_APP_BUILD_URL || 'http://bash.im';
      scope.buildTimestamp = process.env.REACT_APP_BUILD_TIMESTAMP || '-';
      scope.branch = process.env.REACT_APP_CMS_FE_BR || '-';

      if (event.shiftKey && event.altKey) {
        $modal.open({
          scope,
          templateUrl: 'app/core/views/buildInfoModal.html'
        });
      }
    };

    $scope.$on('$stateChangeSuccess', function(event, toState, toParams) {
      const isHardlink = hardlinks.isHardlinkState(toState, toParams);
      $rootScope.isHardlink = isHardlink;
      if (isHardlink) {
        $rootScope.currentUser = $rootScope.currentUser || {};
        hardlinks.handleHardlink(toState, toParams);
      }
    });
    window.$scope = $scope;
    injectNgDeps({ $rootScope, applicationScope: $scope });
  });
