const { angular } = window;

angular.module('wcm.core').component('manageBlock', {
  templateUrl: 'app/core/components/manageBlock/manageBlock.html',
  controller: 'manageBlockController',
  bindings: {
    title: '@',
    idProp: '@',
    valuesType: '<',
    visibleItems: '<',
    availableValues: '<',
    filters: '<',
    limitItems: '<',
    shouldCollapse: '<',
    links: '<',
    columnsMode: '<',
    titleLineColor: '<',
    isExport: '<',
    onValuesSelected: '&',
    uploadXls: '<',
    downloadCallback: '<',
    uploadCallback: '<'
  }
});
