/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').directive('widgetEditorCaret', function() {
  return {
    restrict: 'EA',
    replace: true,
    template: [
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11px" height="7px" viewBox="0 0 12 7">' +
        '<path d="M11,1.4L5.5,7L0,1.4L0.5,0.875L5.5,6L10.5,0.875L11,1.4z"></path>',
      '</svg>'
    ].join('\n')
  };
});
