/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;
angular
  .module('wcm.sites')
  .controller('sitesListCtrl', function(
    $scope,
    $modal,
    $state,
    breadcrumbs,
    navigationPanel,
    filtersFactory,
    entitiesFactory,
    sitesFactory,
    helpers
  ) {
    breadcrumbs.set({
      path: [
        {
          name: 'sites'
        },
        {
          name: 'list'
        }
      ]
    });
    $scope.setTabs([]);
    let sortOption;
    let appliedFilters;
    $scope.filters = filtersFactory.initFilters(
      {
        label: 'Status',
        options: {
          Active: 'Active',
          Archive: 'Archive'
        }
      },
      {
        label: 'Sort',
        options: {
          'date:desc': 'date:desc',
          'date:asc': 'date:asc',
          'alphabetically:asc': 'alphabetically:asc',
          'alphabetically:desc': 'alphabetically:desc'
        },
        type: 'sort'
      },
      {
        label: 'Partner',
        searchable: true,
        options: {
          All: 0
        }
      },
      {
        label: 'TrafficLight',
        options: {
          all: 'all',
          red: 'red',
          yellow: 'yellow',
          green: 'green'
        }
      },
      {
        label: 'ContentDelivery',
        options: {
          all: 'all',
          on: 'on',
          off: 'off'
        }
      }
    );

    $scope.init = function init() {
      $scope.setBusy(true);
      return entitiesFactory
        .getOwnersList('Partner')
        .success(function(data) {
          angular.forEach(data.owners, function(option) {
            $scope.filters[2].addOption(option.name, option.id);
          });
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.goToView = function(id) {
      $state.go('section.sites.view.details', { id });
    };

    $scope.onAfterFiltersApplied = function onAfterFiltersApplied(
      data,
      requestPayload
    ) {
      $scope.totalItems = data.itemsTotal;
      $scope.canCreate = data.canCreate;
      $scope.archiveState = requestPayload.filterByStatus === 'Active';
    };

    $scope.onListUpdate = function onListUpdate(sortOpt, filters) {
      if (filters.filterByType !== $scope.currentTabName) return false;
      sortOption = sortOpt;
      appliedFilters = filters;
      return $scope.getPage();
    };

    $scope.getPage = function getPage() {
      sitesFactory
        .getList(
          appliedFilters,
          $scope.currentPage,
          $scope.itemsOnPage,
          sortOption
        )
        .then(function(sites) {
          $scope.sitesList = sites;
        })
        .finally(function() {
          $scope.setBusy(false);
        });
    };

    $scope.performAction = function(action, site) {
      let strings;

      if (site.isSingleForCampaign && action !== 'recoverFromArchive') {
        strings = [site.name, site.assignedCampaigns.join(' & ')];
        helpers.errorModal(
          'sites.modals.actions.titles.moveToArchive',
          'sites.modals.actions.texts.cantArchiveSite',
          true,
          strings
        );
        return true;
      }

      return helpers
        .confirmationModal(
          `sites.modals.actions.titles.${action}`,
          `sites.modals.actions.texts.${action}`,
          site.name
        )
        .result.then(function() {
          sitesFactory[action](site.id).then(function() {
            $scope.applyFilters(true);
          });
        });
    };

    $scope.remove = function(site) {
      if (!site.isRemovable) return false; // early exit for non-removable sites
      let action;
      let strings;
      let className;

      if (site.assignedCampaigns.length) {
        action = 'removeWithStates';
        strings = [site.name, site.assignedCampaigns.join(' & ')];
        className = 'site_modal_remove_states';
      } else if (!site.assignedCampaigns.length) {
        action = 'removeSite';
        strings = site.name;
      }

      return helpers
        .confirmationModal(
          'sites.modals.actions.titles.removeSite',
          `sites.modals.actions.texts.${action}`,
          strings,
          null,
          { className }
        )
        .result.then(function() {
          return sitesFactory.removeSite(site.id);
        })
        .then(function() {
          return $scope.applyFilters(true);
        })
        .catch(function(rejection) {
          if (rejection && rejection.status && rejection.status === 409) {
            strings = [site.name, site.assignedCampaigns.join(' & ')];
            helpers.errorModal(
              'sites.modals.actions.titles.removeSite',
              'sites.modals.actions.texts.cantRemoveSite',
              true,
              strings
            );
          }
        });
    };

    navigationPanel.setActionButtons([
      {
        title: () => 'global.addNewItemButton',
        isDisabled: () => !$scope.archiveState,
        isShown: () => $scope.canCreate,
        clickHandler: () => {
          $state.go('section.sites.create.details');
        }
      }
    ]);
  });
