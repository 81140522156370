/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.widgets').component('widgetAnswerEditor', {
  templateUrl: 'app/sections/widgets/editor/pollWidget/widgetAnswerEditor.html',
  controller: widgetAnswerEditorController,
  bindings: {
    answer: '=',
    availableLocales: '<',
    defaultLocale: '<'
  }
});

function widgetAnswerEditorController() {
  const ctrl = this;

  ctrl.handleAnswerType = function() {
    ctrl.answer.type =
      ctrl.fakedAnswerType === 'checkbox' ? 'checkbox' : ctrl.fakedInputType;
  };
  ctrl.isDisabledLangPicker = function() {
    if (ctrl.defaultLocale.id !== ctrl.selectedLocale.id) return false;
    return ctrl.fakedAnswerType === 'input'
      ? !ctrl.answer.legend.i18n[ctrl.defaultLocale.id]
      : !ctrl.answer.label.i18n[ctrl.defaultLocale.id];
  };
  ctrl.selectLocale = function(locale) {
    ctrl.selectedLocale = locale;
  };

  ctrl.$onInit = function() {
    ctrl.selectedLocale = ctrl.defaultLocale;
    ctrl.fakedAnswerType =
      ctrl.answer.type === 'checkbox' ? 'checkbox' : 'input';
    ctrl.fakedInputType =
      ctrl.answer.type === 'checkbox' ? 'text' : ctrl.answer.type;
  };
}
