/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.analytics2')
  .directive('analyticsManageBlock', function(analyticsFactory2) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        title: '@',
        linkTo: '=',
        filterTypes: '=',
        visibleItems: '@',
        availableValues: '=',
        filters: '=',
        collapseType: '@',
        lineColor: '@',
        onValuesSelected: '&',
        isExport: '=?'
      },
      templateUrl: 'app/core/analytics2/analyticsManageBlock.html',
      link(scope, el) {
        el.attr('id', scope.title); // set id of block
      },
      controller($scope, $timeout) {
        if (!$scope.visibleItems) {
          $scope.visibleItems = 0;
        }

        $scope.colorPatterns = analyticsFactory2.defaultColorPatterns[2];

        const isEmptySelection = function(values) {
          return !values.some(function(item) {
            return item.checked;
          });
        };

        const getCheckedIds = function(items) {
          return items.reduce(function(checkedIds, item) {
            if (item.checked) checkedIds.push(item.id);
            return checkedIds;
          }, []);
        };

        const collapseSelectedItems = function() {
          $scope.itemsToShow = $scope.availableValues.filter(function(item) {
            return item.checked;
          });

          if (!$scope.collapseType || $scope.linkTo) return;

          const checkedIds = getCheckedIds($scope.availableValues);
          let itemsToRemove = [];
          const collapsedItems = [];

          $scope.filters.forEach(function(item) {
            item.values.forEach(function(value) {
              if (
                value[$scope.collapseType] &&
                value[$scope.collapseType].length &&
                window._.intersection(checkedIds, value[$scope.collapseType])
                  .length === value[$scope.collapseType].length
              ) {
                itemsToRemove = itemsToRemove.concat(
                  value[$scope.collapseType]
                );
                collapsedItems.push({
                  name: value.name,
                  checked: true,
                  className: value.className
                });
              }
            });
          });

          itemsToRemove = window._.uniq(itemsToRemove);
          $scope.itemsToShow = $scope.itemsToShow.filter(function(item) {
            return itemsToRemove.indexOf(item.id) === -1;
          });
          $scope.itemsToShow = collapsedItems.concat($scope.itemsToShow);
        };

        $scope.noValuesSelected = true;
        $scope.selectedItems = [];
        $scope.overflowItemsCount = 0;

        $scope.manageValues = function manageValues() {
          const cancelData = angular.copy($scope.availableValues);
          const cancelDataFilters = angular.copy($scope.filters);

          analyticsFactory2
            .listModal(
              $scope.title,
              $scope.availableValues,
              $scope.filters,
              $scope.filterTypes,
              $scope.collapseType
            )
            .result.then(
              function() {
                $scope.onValuesSelected();
                $scope.noValuesSelected = isEmptySelection(
                  $scope.availableValues
                );
                collapseSelectedItems();
              },
              function() {
                $scope.availableValues = angular.copy(cancelData);
                $scope.filters = angular.copy(cancelDataFilters);
                collapseSelectedItems();
              }
            );
        };

        $scope.removeItem = function removeItem(item) {
          // eslint-disable-next-line no-param-reassign
          item.checked = false;
        };

        $scope.getSelectedItems = function() {
          if (!$scope.availableValues) return undefined;

          if (!$scope.itemsToShow) collapseSelectedItems();

          $scope.selectedItems = $scope.itemsToShow.filter(function(item) {
            return item.checked;
          });
          $scope.overflowItemsCount =
            $scope.selectedItems.length - $scope.visibleItems;
          return $scope.visibleItems && $scope.isExport === undefined
            ? $scope.selectedItems.slice(0, $scope.visibleItems)
            : $scope.selectedItems;
        };

        $scope.setItemsForPrint = function() {
          let itemsForPrint = '';
          $scope.getSelectedItems().forEach(function(item) {
            itemsForPrint += `<span class="picked-item-wrapper">\
                    <span class="picked-item __sites" ng-class="__ ${
                      item.className
                    }">\
                        <span>${item.name}</span>\
                    </span>\
                  </span>`;
          });
          $timeout(function() {
            window.$('#itemsForPrint').html(itemsForPrint);
          }, 0);
        };

        $scope.$watch('isExport', function(newValue) {
          if (newValue) {
            $scope.setItemsForPrint();
          }
        });
      }
    };
  });
