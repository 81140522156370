/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular.module('wcm.campaigns').factory('imageCompressor', function($q) {
  function compress(imgSrc, quality, outputMIME) {
    const dfd = $q.defer();
    let mimeType = 'image/jpeg';
    if (typeof outputMIME !== 'undefined') {
      mimeType = outputMIME;
    }

    const img = new Image();
    img.src = imgSrc;
    img.onerror = dfd.reject;

    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const compressedImgDataUrl = canvas.toDataURL(mimeType, quality);
      dataUrlToBlob(compressedImgDataUrl, dfd.resolve, mimeType);
    };

    return dfd.promise;
  }

  function dataUrlToBlob(dataUrl, callback, type) {
    const binStr = atob(dataUrl.split(',')[1]);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    callback(new Blob([arr], { type }));
  }

  return {
    compress
  };
});
