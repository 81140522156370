const { angular } = window;

angular
  .module('wcm.reporting')
  .controller('reportingViewAnalyticsCtrl', function(
    $scope,
    $state,
    $q,
    $location,
    $rootScope,
    $timeout,
    $controller,
    analyticsFactory2,
    reportingFactory,
    reportingExport,
    analyticsRequestsFactory,
    conversionFactory,
    navigationPanel,
    breadcrumbs,
    helpers,
    REPORTING_CONFIG,
    EXPORT_CONFIG
  ) {
    $scope.inReporting = true;

    const config = REPORTING_CONFIG;
    const routeParams = $location
      .path()
      .split('/')
      .reverse();
    const listType = reportingFactory.getReportsType(routeParams);

    // >>>> Inheriting from certain Analytics ctrl <<<<
    const ctrlInherit = config.controllersViewMap[listType];
    $controller(ctrlInherit, { $scope });

    // eslint-disable-next-line no-multi-assign
    const options = ($scope.options = angular.extend($scope.options, {
      blocks: analyticsFactory2.exportPresets.general
    }));
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = angular.extend($scope.state, {
      callbackRoute: config.callbackRoutes[listType],
      chartsData: {},
      exportAlwaysDisabledBlocks: config.exportAlwaysDisabledBlocksAnalytics,
      exportFlag: false,
      exportModalTitle: 'components.export.labels.exportAnalyticsTitle',
      exportPermissionParams: [EXPORT_CONFIG.filePdf],
      exportPreconditionParams: [],
      exportType: config.exportType,
      filters: {},
      handlersType: '',
      hardlinkTitle: config.hardlinkTitles[listType],
      isBusy: true,
      isExportProcess: true,
      isReady: false,
      itemName: null,
      listType,
      report: null,
      reportBlocks: [],
      reportId: +routeParams[0],
      routeParams,
      settings: {},
      terminateType: listType
    }));

    // eslint-disable-next-line no-multi-assign
    const handlers = ($scope.handlers = angular.extend($scope.handlers, {
      setTabs() {
        navigationPanel.setTabs(config.tabs.views[state.listType]);
        breadcrumbs.set({
          path: `${config.breadcrumbs[state.listType]}>view`
            .split('>')
            .map(name => ({ name })),
          itemName: state.settings.name || ''
        });
      },

      cancel() {
        const labels = {
          title: 'reporting.titles.cancelConfirmationCreate',
          message: 'reporting.messages.cancelConfirmationCreate'
        };
        helpers.cancelConfirmModal(labels).then(function() {
          $scope.closeReport(state.callbackRoute);
        });
      },

      isVisible(blockName) {
        return !!state.chartsData[blockName];
      },

      onVisibilityChange() {
        return true;
      },

      setFilters(filters) {
        return angular.extend({}, state.filters, {
          startPeriod: filters.period[0],
          endPeriod: filters.period[1],
          startTimeFrame: filters.timeframe[0],
          endTimeFrame: filters.timeframe[1],
          allTime: filters.isAllTime,
          daysOfWeek: filters.days
        });
      },

      setProgress(progressData) {
        return reportingFactory.prepareReportsProgress(
          angular.extend({}, state.settings, {
            creationDate: progressData.creationDate,
            completedOn: progressData.completedOn,
            refreshDate: progressData.refreshDate,
            remainingTime: progressData.remainingTime,
            progress: progressData.progress,
            reportStatus: progressData.status
          })
        );
      },

      getDataForBlock(handler, filters, name, data) {
        return analyticsRequestsFactory[handler](
          filters,
          state.blocksState[reportingFactory.getBlocksStateName(name)],
          state.handlersType,
          null,
          data
        ).then(function(finalData) {
          state.chartsData[name] = finalData;
          return finalData;
        });
      },

      prepareChartsDataDefault(report) {
        const blocks = {};
        Object.keys(report).forEach(function(name) {
          const blockName = config.blockMapOnReportView[listType][name];
          if (blocks[blockName] && !Array.isArray(blocks[blockName])) {
            blocks[blockName] = [blocks[blockName], report[name]];
          } else if (blocks[blockName] && Array.isArray(blocks[blockName])) {
            blocks[blockName].push(report[name]);
          } else {
            blocks[blockName] = report[name];
          }
        });

        state.reportBlocks = Object.keys(blocks);

        // Prepare data for each block -> use timeout with diff time to reduce a load
        // on browser page
        state.reportBlocks.forEach(function(name, i) {
          $timeout(function() {
            handlers.prepareDataForBlock(name, blocks[name]);
          }, i * 200);
        });
      },

      prepareDataForBlock(name, data, filters = null) {
        const handler = handlers.getRequestHandlerByBlockName(name);
        return handlers.getDataForBlock(handler, filters, name, data);
      },

      prepareChartsData(report) {
        handlers.prepareChartsDataDefault(report);
      },

      getRequestHandlerByBlockName(blockName) {
        return `get${conversionFactory.capitalize(blockName)}`;
      },

      exportReport() {
        return reportingFactory.exportReport(state);
      },
      refreshReport(refreshState) {
        state.isBusy = true;
        state.exportPermissionParams = [];
        state.exportPreconditionParams = [];
        return reportingFactory.refreshReport(refreshState);
      },

      setExportOptions() {
        const exportOptions = [];
        const allBlocks = state.settings.blockList.map(function(name) {
          return config.blockMapOnReportView[listType][name];
        });
        allBlocks.push('campaigns');
        allBlocks.push('sites');

        if (state.campaignWidgetsType === 'poll') {
          allBlocks.push('pollInfo');
        }

        const alwaysPrintedBlocks = ['sites', 'campaigns'];
        allBlocks.forEach(function(item) {
          const block = remapExportOptions(item);
          if (!block) {
            return false;
          }
          exportOptions.push({
            key: block,
            name: `analytics.exportBlockTitles.${block +
              (analyticsFactory2.additionalNames[
                `${state.analyticsType}.${block}`
              ] || '')}`,
            isDisabled: false,
            isAlwaysDisabled: false,
            isAlwaysHidden: ~alwaysPrintedBlocks.indexOf(item),
            value: true
          });
          return undefined;
        });
        return window._.uniq(exportOptions, 'key');
      },

      onChangeDataFrom() {
        state.chartsData.dataByCitiesAndCountries =
          state.blocksState.dataByCitiesAndCountries.requestFilter ===
          'siteLocation'
            ? angular.copy(
                state.chartsData.dataByCitiesAndCountriesSiteLocation
              )
            : angular.copy(
                state.chartsData.dataByCitiesAndCountriesSocialNetworks
              );
        handlers.changePieFilter('dataByCitiesAndCountries');
      },

      onChangeDataFromSites() {
        state.chartsData.dataByCitiesAndCountriesSites =
          state.blocksState.dataByCitiesAndCountriesSites.requestFilter ===
          'siteLocation'
            ? angular.copy(
                state.chartsData.dataByCitiesAndCountriesSitesSiteLocation
              )
            : angular.copy(
                state.chartsData.dataByCitiesAndCountriesSitesSocialNetworks
              );
        handlers.changePieFilter('dataByCitiesAndCountriesSites');
      },

      onInit(res) {
        state.report = res.data.report;
        state.settings = res.data.settings;

        handlers.setTabs();

        state.isReady = res.status === 200;

        state.itemName = res.data.siteName || res.data.campaignName || null;
        state.filters = handlers.setFilters(state.settings);

        if (res.data.progress >= 0) {
          state.settings = handlers.setProgress(res.data);
        }

        if (state.settings.isExcel) {
          state.exportPermissionParams.unshift(EXPORT_CONFIG.fileXls);
        }

        if (state.isReady) {
          handlers.prepareChartsData(state.report);
        }
        return state;
      },

      showNoWas(state) {
        // eslint-disable-next-line no-param-reassign
        state.noWas = true;
        return state;
      },

      disableButtons() {
        return !state.isReady;
      },

      isExportButton() {
        return true;
      },

      getReportByHardlink(id, params) {
        if (
          !reportingFactory.hardlinkReport ||
          reportingFactory.hardlinkReport.status !== 200 ||
          reportingFactory.hardlinkReport.data.id !== id
        ) {
          return reportingFactory
            .getReportByHardlink(id, params)
            .then(function(res) {
              // eslint-disable-next-line no-param-reassign
              reportingFactory.hardlinkReport = res;
              // eslint-disable-next-line no-param-reassign
              reportingFactory.hardlinkReport.data.id = id;
              options.campaigns =
                reportingFactory.hardlinkReport.data.settings.campaignData;
              options.sites =
                reportingFactory.hardlinkReport.data.settings.siteData;
              return reportingFactory.hardlinkReport;
            })
            .finally(function() {
              $timeout(() => {
                state.isBusy = false;
              }, 200);
            });
        }
        options.campaigns =
          reportingFactory.hardlinkReport.data.settings.campaignData;
        options.sites = reportingFactory.hardlinkReport.data.settings.siteData;
        return reportingFactory.hardlinkReport;
      },

      hasViewPermission
    }));

    $scope.parentInit = function() {
      handlers.setTabs();
      return !!$state.params.id;
    };

    $scope.init = function() {
      navigationPanel.setActionButtons([
        {
          title: () => 'reporting.buttons.refresh',
          isDisabled: () => handlers.disableButtons(),
          isShown: () =>
            !state.isBusy &&
            !state.noWas &&
            state.listType !== 'systemWideGeneral' &&
            state.report,
          clickHandler: () => {
            handlers.refreshCurrentReport();
          }
        },
        {
          title: () => 'reporting.buttons.export',
          type: () => 'action-1',
          isBusy: () => state.exportFlag,
          isDisabled: () => handlers.disableButtons(),
          isShown: () =>
            !state.isBusy &&
            !state.noWas &&
            state.report &&
            handlers.isExportButton(),
          clickHandler: () => {
            state.exportFlag = true;

            reportingExport
              .export({
                exportOptions: handlers.setExportOptions(),
                exportType: state.exportType,
                exportAlwaysDisabledBlocks: state.exportAlwaysDisabledBlocks,
                exportBlocksState: state.exportBlocksState,
                exportFilters: state,
                permissionParams: state.exportPermissionParams,
                title: state.exportModalTitle,
                preconditionParams: state.exportPreconditionParams
              })
              .finally(() => {
                state.exportFlag = false;
              });
          }
        },
        {
          title: () => 'reporting.buttons.close',
          type: () => 'reject',
          isDisabled: () => false,
          isShown: () => true,
          clickHandler: () => {
            $scope.closeReport(state.callbackRoute);
          }
        }
      ]);
      return $q
        .when(handlers.init())
        .then(handlers.onInit, handlers.showNoWas)
        .then(function(state) {
          if (
            state &&
            state.settings &&
            angular.isDefined(state.settings.reportStatus) &&
            state.settings.reportStatus !== 'complete'
          ) {
            $scope.init.timeoutId = setTimeout(
              $scope.init,
              config.refreshViewTimeout
            );
          }
          return state;
        })
        .finally(function() {
          $timeout(() => {
            state.isBusy = false;
          }, 200);
          return state;
        });
    };

    // Redirect if no permissions on this tab
    $scope.$on('$stateChangeSuccess', function() {
      if (!hasViewPermission()) {
        $scope.closeReport(state.callbackRoute);
        return false;
      }
      return undefined;
    });

    $scope.$on('$stateChangeStart', function() {
      clearTimeout($scope.init.timeoutId);
    });

    // Has view permission
    function hasViewPermission() {
      if (Number.isNaN(+$state.params.id)) return true; // check for hardlinks
      return $scope.hasPermission('view', state.listType);
    }

    // Remap some export options for some analytics blocks
    function remapExportOptions(item) {
      switch (item) {
        case 'dataByDaysAndSitesNotAggregated':
        case 'dataByCitiesAndCountriesSitesSocialNetworks':
        case 'dataByCitiesAndCountriesSocialNetworks':
          return undefined;
        case 'dataByDaysAndSitesAggregated':
          return 'dataByDaysAndSites';
        case 'dataByCitiesAndCountriesSitesSiteLocation':
        case 'dataByCitiesAndCountriesSiteLocation':
          return 'dataByCitiesAndCountries';
        default:
          return item;
      }
    }
  });
