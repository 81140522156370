/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('localizationSettingsCtrl', function(
    $scope,
    $state,
    $timeout,
    localizationFactory,
    $modal,
    helpers,
    i18nFactory
  ) {
    const state = $scope.state;
    const handlers = $scope.handlers;

    const busyImpactor = helpers.promiseQueue(function(queue) {
      $timeout(function() {
        $scope.setBusy(!!queue.length);
      }, 100);
    });
    handlers.init = busyImpactor(function init(localizationType) {
      const getLocalesMethodName =
        localizationType === 'system'
          ? 'getSystemLocalizations'
          : 'getClientLocalizations';

      return localizationFactory[getLocalesMethodName]().then(function(
        locales
      ) {
        state.locales = locales;
        state.isDataLoaded = true;
      });
    });

    handlers.save = busyImpactor(function() {
      return save(state.localizationType, state.locales);
    });

    function save(localizationType, localizations) {
      const saveLocalesMethodName =
        localizationType === 'system'
          ? 'saveSystemLocalizations'
          : 'saveClientLocalizations';

      return localizationFactory[saveLocalesMethodName](localizations).then(
        function() {
          i18nFactory.updateLocalizationState();
          $scope.state.editMode = false;
        }
      );
    }
  });
