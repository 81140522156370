/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

function deselectCurrent() {
  const selection = document.getSelection();
  if (!selection.rangeCount) {
    return function() {};
  }
  let active = document.activeElement;

  const ranges = [];
  for (let i = 0; i < selection.rangeCount; i++) {
    ranges.push(selection.getRangeAt(i));
  }

  switch (
    active.tagName.toUpperCase() // .toUpperCase handles XHTML
  ) {
    case 'INPUT':
    case 'TEXTAREA':
      active.blur();
      break;

    default:
      active = null;
      break;
  }

  selection.removeAllRanges();
  return function() {
    if (selection.type === 'Caret') {
      selection.removeAllRanges();
    }

    if (!selection.rangeCount) {
      ranges.forEach(function(range) {
        selection.addRange(range);
      });
    }

    if (active) {
      active.focus();
    }
  };
}

function copy(text) {
  let reselectPrevious;
  let selection;
  let range;
  let mark;
  try {
    reselectPrevious = deselectCurrent();

    range = document.createRange();
    selection = document.getSelection();

    mark = document.createElement('mark');
    mark.innerHTML = text;
    document.body.appendChild(mark);

    range.selectNode(mark);
    selection.addRange(range);

    const successful = document.execCommand('copy');
    if (!successful) {
      throw new Error('copy command was unsuccessful');
    }
  } catch (err) {
    console.error('unable to copy, trying IE specific stuff');
    try {
      window.clipboardData.setData('text', text);
    } catch (err) {
      console.error('unable to copy, falling back to prompt');
      const key = ~navigator.userAgent.indexOf('Macintosh') ? '⌘' : 'Ctrl';
      window.prompt(`Copy to clipboard: ${key}+C, Enter`, text);
    }
  } finally {
    if (selection) {
      if (typeof selection.removeRange === 'function') {
        selection.removeRange(range);
      } else {
        selection.removeAllRanges();
      }
    }

    if (mark) {
      document.body.removeChild(mark);
    }
    reselectPrevious();
  }
}

angular.module('wcm.helpers').directive('copyToClipboard', function() {
  return {
    link(scope, element, attrs) {
      element.on('click', function() {
        const text = attrs.copyToClipboard;
        copy(text);
      });
    }
  };
});
